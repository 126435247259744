export const getSurvey = /* GraphQL */ `
	query GetSurvey($id: ID!) {
		getSurvey(id: $id) {
			id
			title
			date
			items {
				id
				title
				description
				location
				order
				date
				user {
					id
					lastName
					firstName
					role
					profilePicture
					signature
				}
				media {
					id
					order
					key
				}
			}
			project {
				id
				title
				isViewable
				team {
					isSuperAdmin
					isAdmin
					isActive
					isGeneral
					isReadOnly
					projectRole {
						id
						name
					}
					teamMember {
						id
					}
				}
				client {
					name
				}
				location {
					id
					name
				}
				workspace {
					isViewable
					id
					title
					location {
						id
						name
					}
					team {
						projectRole {
							id
							name
						}
						isSuperAdmin
						isAdmin
						isActive
						isGeneral
						isReadOnly
						teamMember {
							id
						}
					}
					client {
						name
					}
				}
			}
		}
	}
`;

export const createSurveyItem = /* GraphQL */ `
	mutation CreateSurveyItem($input: CreateSurveyItemInput!) {
		createSurveyItem(input: $input) {
			id
			survey {
				id
				title
				date
				items {
					id
					title
					description
					location
					order
					date
					user {
						id
						lastName
						firstName
						role
						profilePicture
						signature
					}
					media {
						id
						order
						key
					}
				}
				project {
					id
					title
					isViewable
					team {
						isSuperAdmin
						isAdmin
						isActive
						isGeneral
						isReadOnly
						projectRole {
							id
							name
						}
						teamMember {
							id
						}
					}
					client {
						name
					}
					workspace {
						isViewable
						id
						title
						team {
							projectRole {
								id
								name
							}
							isSuperAdmin
							isAdmin
							isActive
							isGeneral
							isReadOnly
							teamMember {
								id
							}
						}
						client {
							name
						}
					}
				}
			}
		}
	}
`;

export const updateSurveyItem = /* GraphQL */ `
	mutation UpdateSurveyItem($input: UpdateSurveyItemInput!) {
		updateSurveyItem(input: $input) {
			id
			title
			description
			location
			order
			date
			user {
				id
				lastName
				firstName
				role
				profilePicture
				signature
			}
			media {
				id
				order
				key
			}
			survey {
				id
				title
				date
				project {
					id
					title
					isViewable
					team {
						isSuperAdmin
						isAdmin
						isActive
						isGeneral
						isReadOnly
						projectRole {
							id
							name
						}
						teamMember {
							id
						}
					}
					client {
						name
					}
					workspace {
						isViewable
						id
						title
						team {
							projectRole {
								id
								name
							}
							isSuperAdmin
							isAdmin
							isActive
							isGeneral
							isReadOnly
							teamMember {
								id
							}
						}
						client {
							name
						}
					}
				}
			}
		}
	}
`;

export const updateSurvey = /* GraphQL */ `
	mutation UpdateSurvey($input: UpdateSurveyInput!) {
		updateSurvey(input: $input) {
			id
			title
			date
			items {
				id
				title
				description
				location
				order
				date
				user {
					id
					lastName
					firstName
					role
					profilePicture
					signature
				}
				media {
					id
					order
					key
				}
			}
			project {
				id
				title
				isViewable
				team {
					isSuperAdmin
					isAdmin
					isActive
					isGeneral
					isReadOnly
					projectRole {
						id
						name
					}
					teamMember {
						id
					}
				}
				client {
					name
				}
				workspace {
					isViewable
					id
					title
					team {
						projectRole {
							id
							name
						}
						isSuperAdmin
						isAdmin
						isActive
						isGeneral
						isReadOnly
						teamMember {
							id
						}
					}
					client {
						name
					}
				}
			}
		}
	}
`;

export const createMedia = /* GraphQL */ `
	mutation CreateMedia($input: CreateMediaInput!) {
		createMedia(input: $input) {
			id
			type
			order
			key
			surveyItem {
				id
			}
		}
	}
`;

export const deleteMedia = /* GraphQL */ `
	mutation CreateMedia($input: DeleteMediaInput!) {
		deleteMedia(input: $input) {
			id
		}
	}
`;

export const deleteSurveyItem = /* GraphQL */ `
	mutation DeleteSurveyItem($input: DeleteSurveyItemInput!) {
		deleteSurveyItem(input: $input) {
			id
		}
	}
`;

export const getSurveyItem = /* GraphQL */ `
	query getSurveyItem($id: ID!) {
		getSurveyItem(id: $id) {
			id
			title
			description
			location
			order
			date
			user {
				id
				lastName
				firstName
				role
				profilePicture
				signature
			}
			media {
				id
				order
				key
			}
			survey {
				id
				title
				items {
					id
					title
					description
					location
					order
					date
					user {
						id
						lastName
						firstName
						role
						profilePicture
						signature
					}
					media {
						id
						order
						key
					}
				}
				project {
					id
					title
					isViewable
					team {
						isSuperAdmin
						isAdmin
						isActive
						isGeneral
						isReadOnly
						projectRole {
							id
							name
						}
						teamMember {
							id
						}
					}
					client {
						name
					}
					workspace {
						isViewable
						id
						title
						team {
							projectRole {
								id
								name
							}
							isSuperAdmin
							isAdmin
							isActive
							isGeneral
							isReadOnly
							teamMember {
								id
							}
						}
						client {
							name
						}
					}
				}
			}
		}
	}
`;
