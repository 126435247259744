import React from "react";
import PropTypes from "prop-types";

//SVG

import { Text, G } from "react-native-svg";

const Badge = ({ middleX, middleY, symbol }) => {
	return (
		<G>
			<Text
				x={middleX}
				y={middleY}
				Text-anchor="middle"
				fill="#000"
				fontSize="30"
			>
				{symbol}
			</Text>
		</G>
	);
};

Badge.propTypes = {
	middleX: PropTypes.number,
	middleY: PropTypes.number,
	symbol: PropTypes.string,
};

export default Badge;
