import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { View } from "react-native";

// Components
import { Alert } from "../components/Alert";
import Button from "../components/Button";
import { Input, Select, Switch, TextArea } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation } from "@apollo/client";
import * as sampleGQL from "../graphql/sampling";

// UTILS
import { getData, SentryLogger } from "../utils";

export const SampleSlideOver = ({
	type = "create",
	currentSample,
	currentSampleCollection = {},
	isOpen,
	closeRequest,
	onCompleted,
	defaultValues = {},
}) => {
	const [sample, setSample] = React.useState({});
	const [user, setUser] = React.useState({});
	const [sampleCollection, setSampleCollection] = React.useState({});

	React.useEffect(() => {
		if (currentSample) {
			setSample(currentSample);
		}
		if (currentSampleCollection) {
			setSampleCollection(currentSampleCollection);
		}
	}, [currentSample, currentSampleCollection]);

	React.useEffect(() => {
		const getUser = async () => {
			await getData("@storage_Key").then((user) => setUser(user));
		};
		getUser();
	}, []);

	const [createSampleOperation] = useMutation(gql(sampleGQL.createSample), {
		onCompleted: (data) => {
			onCompleted(data.createSample);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("Couldn't create sample please try again");
		},
	});

	const [updateSingleSample] = useMutation(gql(sampleGQL.updateSample), {
		onCompleted: (data) => {
			onCompleted(data.updateSample);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("Couldn't update your sample please try again");
		},
	});

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "New Sample" : "Update Sample"}
			description={
				type === "create" ? "Add a new sample" : `Update ${sample.title}`
			}
			closeRequest={() => closeRequest()}
			onSubmit={() => {
				// for (let i = 0; i < mediaToDelete.length; i++) {
				//   await deleteSampleMedia(mediaToDelete[i]);
				// }

				if (type === "create") {
					let input = sample;
					input.user = user.id;
					input.date = DateTime.now().toISO();
					input.type = sampleCollection.type;
					input.sampleCollection = sampleCollection.id;
					input.homogeneousArea = sample.homogeneousArea
						? sample.homogeneousArea
						: null;

					createSampleOperation({
						variables: {
							input: input,
						},
					});
				} else {
					if (sampleCollection.type.toUpperCase() === "MICROBIOLOGY") {
						let input = {
							id: sample.id,
							background: sample.background,
							number: sample.number,
							title: sample.title,
							description: sample.description,
							type: sampleCollection.type,
							medium: sample.medium.id,
						};
						updateSingleSample({
							variables: {
								input: input,
							},
						});
					} else {
						if (sampleCollection.type.toUpperCase() === "ASBESTOS") {
							let input = {
								id: sample.id,
								number: sample.number,
								title: sample.title,
								positive: sample.positive == true ? true : false,
								result: sample.positive == true ? sample.result : "",
								description: sample.description,
								friable: sample.friable == true ? true : false,
								homogeneousArea: sample.homogeneousArea
									? sample.homogeneousArea
									: null,
							};
							updateSingleSample({
								variables: {
									input: input,
								},
							});
						}
					}
				}
			}}
			buttonRight={type === "create" ? "Create" : "Update"}
			sample={sample}
			sampleCollection={sampleCollection}
		>
			{sampleCollection.type === "MICROBIOLOGY" && (
				<Switch
					label="Background Sample"
					description="Air and Swab samples need at least one background sample"
					value={sample.background}
					onChange={(e) => {
						setSample({
							...sample,
							background: e === true ? true : false,
						});
					}}
				/>
			)}
			<Input
				label="Number"
				type="text"
				class="w-input"
				value={sample.number}
				onChange={(e) =>
					setSample({
						...sample,
						number: e,
					})
				}
			/>
			<Input
				label="Title"
				type="text"
				class="w-input"
				value={sample.title}
				placeholder={sample.title}
				onChange={(e) =>
					setSample({
						...sample,
						title: e,
					})
				}
			/>

			{sampleCollection.type === "MICROBIOLOGY" && (
				<>
					<Select
						name="Analysis Type"
						type="text"
						class="w-input"
						value={sample.type}
						onChange={(value) => {
							setSample({
								...sample,
								type: value.value,
							});
						}}
						defaultValue={defaultValues.type}
						options={
							sampleCollection &&
							sampleCollection.lab &&
							sampleCollection.lab.types
								.filter((type) => type.collectionType === sampleCollection.type)
								.map((type) => {
									return { name: type.name, value: type.id };
								})
						}
					/>
					<Select
						name="Sample Medium"
						type="text"
						class="w-input"
						value={sample.medium}
						onChange={(value) => {
							setSample({
								...sample,
								medium: value.value,
							});
						}}
						defaultValue={defaultValues.medium}
						options={
							sampleCollection &&
							sampleCollection.lab &&
							sampleCollection.lab.mediums
								.filter(
									(medium) => medium.collectionType === sampleCollection.type
								)
								.map((medium) => {
									return { name: medium.name, value: medium.id };
								})
						}
					/>
				</>
			)}

			{sampleCollection.type === "ASBESTOS" && (
				<>
					<Select
						name={"Homogeneous Area"}
						description={"Select the area you wish to attach to this sample"}
						onChange={(value) => {
							setSample({
								...sample,
								homogeneousArea: value.value,
							});
						}}
						options={
							sampleCollection.homogeneousAreas &&
							[...sampleCollection.homogeneousAreas]
								.sort((a, b) => {
									if (a.name > b.name) return 1;
									if (a.name < b.name) return -1;
									return 0;
								})
								.map((area) => ({
									name: area.name,
									value: area.id,
								}))
						}
						defaultValue={sample.homogeneousArea && sample.homogeneousArea.name}
					/>
					<View>
						<Switch
							label="Positive"
							description="Is the sample positive?"
							value={sample.positive === true ? true : false}
							onChange={(e) => {
								setSample({
									...sample,
									positive: e === true ? true : false,
								});
							}}
						/>
						{sample.positive && (
							<TextArea
								label="Results"
								type="text"
								rows="10"
								defaultValue={sample.result}
								onChange={(e) =>
									setSample({
										...sample,
										result: e,
									})
								}
								placeholder="Enter sample results"
							/>
						)}
						<Switch
							label="Friable"
							description="Is the sample friable?"
							value={sample.friable === true ? true : false}
							onChange={(e) => {
								setSample({
									...sample,
									friable: e === true ? true : false,
								});
							}}
						/>
					</View>
				</>
			)}

			<TextArea
				label="Description"
				type="text"
				rows="10"
				defaultValue={sample.description}
				onChange={(e) => {
					setSample({
						...sample,
						description: e,
					});
				}}
				placeholder="Enter sample details"
			/>
			{/* <FileUpload
					files={files}
					onFiles={setFiles}
					server={{
						process: function (fieldName, file, metadata, load, error) {
							s3.upload(
								{
									Key: `projects/${
										sampleCollection.project.id
									}/sampleCollection/${sampleCollection.id}/${uuidv4()}`,
									Body: file,
									ContentType: file.type,
								},
								function (err, data) {
									if (err) {
										
										error("Something went wrong", err);
										Alert(err);
										return;
									}
									
									load(data.Key);
									setKeys((oldKeys) => [...oldKeys, data.Key]);
									
								}
							);
						},
					}}
					allowMultiple={true}
					name="files"
				/> */}
			{/* <Switch
					label="Add Location"
					checked={addLocation}
					onChange={() => {
						setAddLocation(!addLocation);
						getLocation();
					}}
					inputProps={{ "aria-label": "secondary checkbox" }}
				/> 
				{/* {addLocation && (
					<>
						<Text>Feature Coming Soon</Text>
					</>
				)} */}
		</SlideOver>
	);
};

SampleSlideOver.propTypes = {
	type: PropTypes.string,
	currentSample: PropTypes.object,
	currentSampleCollection: PropTypes.object,
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func,
	onCompleted: PropTypes.func,
	defaultValues: PropTypes.object,
};

export const DeleteSampleButton = ({ id, onCompleted }) => {
	const [deleteSingleSample] = useMutation(gql(deleteSample), {
		onCompleted: (data) => {
			onCompleted(data);
		},
		onError: (error) => {
			Alert(error);
		},
	});

	return (
		<Button
			text="Delete Sample"
			variant="red"
			onPress={() => {
				deleteSingleSample({
					variables: {
						input: { id: id },
					},
				});
			}}
		/>
	);
};

DeleteSampleButton.propTypes = {
	id: PropTypes.string.isRequired,
	onCompleted: PropTypes.func.isRequired,
};
