import { useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import Toast from "react-native-toast-message";
// Components
import Button from "../../components/Button";
import LoadingScreen from "../../components/LoadingScreen";
// import { addNotification } from "../../components/Notifications";
import { Alert } from "../../components/Alert";
import { Input } from "../../components/Forms";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as queries from "../../graphql/user";

// Utils

import { API_URL, storeData } from "../../utils";
import axios from "axios";

export const FinishRegistration = ({ route }) => {
	const navigation = useNavigation();
	let [error, setShowError] = React.useState(null);
	let [linkActive, setLinkActive] = React.useState(true);
	let [user, setUser] = React.useState(null);
	let [view, setView] = React.useState("password");
	let [password, setPassword] = React.useState("");
	let [code, setCode] = React.useState("");

	let { id } = route.params;

	let lowerCaseLetters = /[a-z]/g;
	let upperCaseLetters = /[A-Z]/g;
	let numbers = /[0-9]/g;
	let special = /[=+-^$*.{}()?"!@#%&/,><':;|_~`]/g;

	useQuery(gql(queries.getUser), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			if (data.getUser.isActive === false) {
				Toast.show({
					text1: "Inactive Account",
					text2:
						"Sorry your account has been deactivated. Please contact help.srpinfield.com if you think this is a mistake",
					type: "error",
					time: 5000,
				});
				return navigation.navigate("SignIn");
			} else {
				// check to see if link has expired
				setUser(data.getUser);
				if (data.getUser.linkExpiration < DateTime.now().toISO()) {
					setLinkActive(false);
				}
			}
		},
		onError: () => {
			Toast.show({
				text1: "Inactive Account",
				text2:
					"Sorry your account has been deactivated. Please contact help.srpinfield.com if you think this is a mistake",
				type: "error",
				supportText: true,
				time: 5000,
			});
		},
	});

	let [generatePasswordResetToken] = useMutation(
		gql(queries.generatePasswordResetToken),
		{
			onCompleted: (data) => {
				let { email, passwordResetToken } = data.generatePasswordResetToken;

				sendVerificationCode(email, passwordResetToken);
			},
		}
	);

	let [resetPassword] = useMutation(gql(queries.resetPassword), {
		onCompleted: (data) => {
			storeData("@storage_Key", data.resetPassword);
			navigation.navigate("App");
		},
		onError: (error) => {
			setShowError(error.message);
		},
	});

	const sendVerificationCode = (email, code) => {
		axios
			.post(`${API_URL}/emails/send-code`, { email, code })
			.then(() => {
				setView("code");
			})
			.catch(() => {
				Alert(
					"You must reset your password, but we were unable to send the code to your email. Please go to the forgot password page to reset your password ",
					"error"
				);
			});
	};

	if (!user) {
		return <LoadingScreen loadItem={"Account"} />;
	}

	if (!linkActive) {
		return (
			<View className="h-full flex items-center justify-center bg-gray-50 dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
				<View className="max-w-md w-full space-y-8 flex items-center justify-center">
					<View className="flex items-center justify-center ">
						<Image
							className="h-12 w-12"
							source={require("../../assets/srp-icon.png")}
							alt="SRP inField"
						/>
						<Text className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
							Welcome {user.firstName}!
						</Text>
					</View>
					<Text className="text-center">
						Unfortunately the link is no longer active. Our links are set to
						expire 24 hours after they've been sent. Please reach out to support
						to receive another link.
					</Text>
					<View className="mt-2">
						<Button
							onPress={() => {
								navigation.navigate("SignIn");
							}}
							text="Return to Sign In"
						/>
					</View>
				</View>
			</View>
		);
	}

	const createCredentials = async () => {
		setShowError(null);
		if (
			!(
				password.length >= 8 &&
				password.match(upperCaseLetters) &&
				password.match(lowerCaseLetters) &&
				password.match(numbers) &&
				password.match(special)
			)
		) {
			return setShowError(
				"Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
			);
		}

		try {
			generatePasswordResetToken({
				variables: {
					email: user.email,
				},
			});
		} catch (error) {
			Alert("We couldn't generate your verification token. Please try again");
		}
	};

	return (
		<View className="h-full flex items-center justify-center bg-gray-50 dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
			<View className="max-w-md w-full space-y-8 flex items-center justify-center">
				<View className="flex items-center justify-center ">
					<Image
						className="h-12 w-12"
						source={require("../../assets/srp-icon.png")}
						alt="SRP inField"
					/>
					<Text className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
						Welcome {user.firstName}!
					</Text>
				</View>

				{view === "password" ? (
					<>
						<Text className="mt-3 text-center  font-normal text-gray-900 dark:text-white">
							Welcome to SRP inField! You're almost ready to use your account.
							Please enter a password and we will send you a code to confirm
							your account. Please note your email is{" "}
							<span className="font-bold">{user.email}</span>
						</Text>
						<View className="mx-2">
							{error && <Text className="text-red-500">{error}</Text>}
						</View>
						<View className="mt-8 space-y-6 w-full">
							<Input
								label="Password"
								placeholder="Password"
								type="password"
								id="password"
								onChange={(e) => {
									setPassword(e);
								}}
								secureTextEntry
								autoCorrect={false}
								autoCapitalize="none"
								autoComplete={"password-new"}
							/>

							<View className="mt-6">
								<Button
									type="submit"
									text="Set Password"
									onPress={() => {
										createCredentials();
									}}
								/>
							</View>
						</View>
					</>
				) : (
					<>
						<Text className="mt-3 text-center  font-normal text-gray-900 dark:text-white">
							Enter your the code that was just sent to your email.
						</Text>
						<View className="w-1/2">
							{error && <Text className="text-red-500">{error}</Text>}
						</View>
						<View className="mt-8 space-y-6 w-full">
							<Input
								label="Code"
								placeholder="Auth Code"
								required
								type="text"
								value={code}
								onChange={(e) => {
									setCode(e);
								}}
							/>
							<TouchableOpacity
								onPress={() => {
									generatePasswordResetToken({
										variables: {
											email: user.email,
										},
									});
								}}
							>
								<Text className="mt-2 text-indigo-500 group-hover:text-indigo-400 dark:text-gray-100 dark:group-hover:text-indigo-50 cursor:pointer">
									Resend Authentication Code
								</Text>
							</TouchableOpacity>

							<View className="mt-6">
								<Button
									text="Verify and Sign In"
									onPress={() => {
										resetPassword({
											variables: {
												email: user.email,
												password: password,
												code: code,
											},
										});
									}}
								/>
							</View>
						</View>
					</>
				)}
			</View>
		</View>
	);
};

FinishRegistration.propTypes = {
	route: PropTypes.object,
};
