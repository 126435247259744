import AsyncStorage from "@react-native-async-storage/async-storage";
import AWS from "aws-sdk";
import Constants from "expo-constants";
import { DateTime } from "luxon";
import { Platform } from "react-native";
import * as Sentry from "sentry-expo";

export const inProduction = process.env.NODE_ENV === "production";
export const inExpo =
	Constants.manifest2 &&
	Constants.manifest2.extra &&
	Constants.manifest2.extra.expoGo &&
	Constants.manifest2.extra.expoGo.debuggerHost;
export const inBrowser = typeof document !== "undefined";

export const devAPIDomain = inExpo
	? `http://${Constants.manifest2.extra.expoGo.debuggerHost.split(":").shift()}`
	: inBrowser
	? `http://${document.location.hostname}`
	: "unknown";

export const SentryLogger = async (error) => {
	const user = await getData("@storage_Key").then((res) => {
		return res;
	});

	if (Platform.OS === "web") {
		Sentry.Browser.setUser({
			id: user?.id,
			email: user?.email,
		});
		Sentry.Browser.captureException(error);
	} else {
		Sentry.Native.setUser({
			id: user?.id,
			email: user?.email,
		});
		Sentry.Native.captureException(error);
	}
};

export const storeData = async (key, value) => {
	try {
		const jsonValue = JSON.stringify(value);
		await AsyncStorage.setItem(key, jsonValue);
		return value;
	} catch (e) {
		// saving error
	}
};

export const getData = async (key) => {
	try {
		const value = await AsyncStorage.getItem(key);
		if (value !== null) {
			// value previously stored
			return JSON.parse(value);
		} else {
			return null;
		}
	} catch (e) {
		// error reading value
		return e;
	}
};

export const projectRoles = [
	{
		name: "Project Director",
		value: "Project Director",
	},
	{
		name: "Senior Project Manager",
		value: "Senior Project Manager",
	},
	{
		name: "Project Manager",
		value: "Project Manager",
	},
	{
		name: "Assistant Project",
		value: "Assistant Project",
	},
	{
		name: "Certified Industrial Hygienist",
		value: "Certified Industrial Hygienist",
	},
	{
		name: "Senior Industrial Hygienist",
		value: "Senior Industrial Hygienist",
	},
	{
		name: "Industrial Hygienist",
		value: "Industrial Hygienist",
	},
	{
		name: "Industrial Hygiene Tech",
		value: "Industrial Hygiene Tech",
	},
	{
		name: "Data Project Manager",
		value: "Data Project Manager",
	},
	{ name: "Data Analyst", value: "Data Analyst" },
	{ name: "Senior Admin", value: "Senior Admin" },
	{ name: "Admin", value: "Admin" },
	{ name: "Clerk", value: "Clerk" },
];

export const projectStages = [
	{
		name: "Mobilization",
		value: "Mobilization",
		color: "bg-purple-600",
	},
	{
		name: "Initial Assessment",
		value: "Initial Assessment",
		color: "bg-red-600",
	},
	{
		name: "Clearance",
		value: "Clearance",
		color: "bg-yellow-600",
	},
	{
		name: "Demobilization",
		value: "Demobilization",
		color: "bg-blue-600",
	},
	{
		name: "Report Generation",
		value: "Report Generation",
		color: "bg-gray-600",
	},
	{
		name: "Billing",
		value: "Billing",
		color: "bg-pink-600",
	},
	{
		name: "Complete",
		value: "Complete",
		color: "bg-green-600",
	},
];

export const sampleTypes = [
	{
		name: "Microbiology",
		value: "MICROBIOLOGY",
		color: "pink",
	},
	{
		name: "Asbestos",
		value: "ASBESTOS",
		color: "blue",
	},
];

export const EquipmentModals = [
	{
		name: "iPhone",
		value: "iPhone",
	},
	{
		name: "iPad Pro",
		value: "iPad Pro",
	},
	{
		name: "MacBook Pro",
		value: "MacBook Pro",
	},
	{
		name: "PC",
		value: "PC",
	},
	{
		name: "FlIR",
		value: "FlIR",
	},
	{
		name: "Protimeter",
		value: "Protimeter",
	},
	{
		name: "Tramex",
		value: "Tramex",
	},
	{
		name: "Ricoh Theta",
		value: "Ricoh Theta",
	},
	{
		name: "Other",
		value: "Other",
	},
];

/**
 * handleTimeEntryChange
 * handles the change of the time entry form and converts the time to an ISO string
 * @param {string} timeEntry - the target from the time entry input
 * @returns string - the ISO formatted date
 */

export const handleTimeEntryChange = (timeEntry) => {
	// get year, date, month, hour, minute, and second

	let date = new Date(timeEntry);
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours();
	let minute = date.getMinutes();
	return DateTime.local(year, month, day, hour, minute).toISO();
};

/**
 * calculateTimeDuration
 * @param {string} timeIn - ISO 8601 String
 * @param {string} timeOut - ISO 8601 String
 * @returns {string} - formatted time difference
 */

export const calculateTimeDuration = (timeIn, timeOut) => {
	return DateTime.fromISO(timeOut, { setZone: true })
		.diff(DateTime.fromISO(timeIn, { setZone: true }))
		.toFormat("hh:mm:ss");
};

const getAPIurl = () => {
	return inProduction
		? "https://fk57a28ct3.execute-api.us-west-2.amazonaws.com/v2"
		: `${devAPIDomain}:3002/local`;
};

export const API_URL = getAPIurl();

export const awsConfig = {
	region: "us-east-1",
	accessKeyId: "AKIATO7GJDKQMIMBUMNA",
	secretAccessKey: "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",
};

AWS.config.update(awsConfig);

export const checkForDailySheetAvailability = (project, user) => {
	let sheets = project.dailyDocuments;
	let canAddNewSheet = true;
	sheets.map((sheet) => {
		if (
			sheet.user.id === user.id &&
			DateTime.fromISO(sheet.date).toFormat("yyyy-LL-dd") ===
				DateTime.now().toFormat("yyyy-LL-dd")
		) {
			canAddNewSheet = false;
		}
	});

	return canAddNewSheet;
};

export const s3 = new AWS.S3({ params: { Bucket: "srp-in-field" } });

export function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export const sendUpdateEmail = async () => {
	return;
};

export const uploadFile = async (buffer, key, fileType, encodingType) => {
	const params = {
		Bucket: "srp-in-field",
		Key: key,
		Body: buffer,
		ContentType: fileType,
		ContentEncoding: encodingType,
	};
	try {
		return await s3.upload(params).promise();
	} catch (err) {
		throw new Error(err);
	}
};

export const getSignedUrl = (link) => {
	let params = { Bucket: "srp-in-field", Key: link };
	return s3.getSignedUrl("getObject", params);
};

export const toSeconds = (s) => {
	var p = s.split(":");
	return (
		parseInt(p[0], 10) * 3600 + parseInt(p[1], 10) * 60 + parseInt(p[2], 10)
	);
};

export const sendNotificationEmail = async (notification, type) => {
	let input = {
		notification: notification,
		type: type,
	};

	await fetch(`${API_URL}/notifications/notification`, {
		method: "POST",
		body: JSON.stringify(input),
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then(() => {})
		.catch((err) => {
			SentryLogger(JSON.stringify(err, null, 2));
		});
};
