export const listClients = /* GraphQL */ `
	query ListClients(
		$filter: TableClientFilterInput
		$limit: Int
		$nextToken: String
	) {
		listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
			}
		}
	}
`;
