import React from "react";
import { useNavigation } from "@react-navigation/native";
import { Text, TouchableOpacity } from "react-native";

// Components
import { PageHeader } from "../../../../components/Header";
import LoadingScreen from "../../../../components/LoadingScreen";
import { List, ListItem } from "../../../../components/List";
import NoItems from "../../../../components/NoItems";
import User from "../../../../components/User";
import Breadcrumbs, { Crumb } from "../../../../components/Breadcrumb";

// GQL
import { gql, useLazyQuery } from "@apollo/client";
import * as userOperations from "../../../../graphql/admin/users";

//Utils
import { getData, SentryLogger } from "../../../../utils";

export function ManagerCenter() {
	const navigation = useNavigation();
	const [teamMembers, setTeamMembers] = React.useState([]);

	React.useEffect(() => {
		const userInfo = async () => {
			await getData("@storage_Key")
				.then((user) => {
					if (!user.isManager) {
						return navigation.goBack();
					}

					listUsers({
						variables: {
							limit: 100000,
							filter: {
								manager: { eq: user.id },
							},
						},
					});
				})
				.catch((err) => {
					SentryLogger(err);
				});
		};

		userInfo();
	}, []);

	const [listUsers, { loading }] = useLazyQuery(gql(userOperations.listUsers), {
		onCompleted: (data) => {
			setTeamMembers((prev) => [...prev, ...data.listUsers.items]);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	if (loading) {
		return <LoadingScreen loadItem={"Your Team"} />;
	}

	const navigateToEmployee = (employee) => {
		return navigation.navigate("Employee", {
			id: employee.id,
		});
	};

	return (
		<>
			<Breadcrumbs>
				<Crumb name="Time Clock" href="/app/time-clock" />
				<Crumb name="Manager Center" href="/app/manager-center" />
			</Breadcrumbs>
			<PageHeader title="Your Team" />

			{teamMembers && teamMembers.length ? (
				<List headers={["Name", "Role"]}>
					{teamMembers
						.sort((a, b) => {
							if (a.firstName > b.firstName) return 1;
							if (a.firstName < b.firstName) return -1;
							return 0;
						})
						.map((teamMember, index) => {
							return (
								<ListItem
									key={index}
									smallView={
										<TouchableOpacity
											onPress={() => {
												navigation.navigate("Employee", {
													id: teamMember.id,
													name: `${teamMember.firstName} ${teamMember.lastName}`,
												});
											}}
											className="w-full rounded-lg shadow-sm border-2 border-gray-200 dark:border-indigo-200 p-2"
										>
											<Text className=" font-bold text-md dark:text-white ">
												{teamMember.firstName} {teamMember.lastName}
											</Text>
											<Text className="font-medium dark:text-white">
												Role: {""}{" "}
												<Text className="text-indigo-500">
													{teamMember.role || "No Role Assigned"}
												</Text>
											</Text>
										</TouchableOpacity>
									}
									items={[
										{
											content: <User user={teamMember} />,
											onPress: () => navigateToEmployee(teamMember),
										},
										{
											content: teamMember.role,
											onPress: () => navigateToEmployee(teamMember),
										},
									]}
								/>
							);
						})}
				</List>
			) : (
				<NoItems
					surTitle="No Employees"
					title="You have no employees assigned to you"
					text="Once a team member has been assigned to you, you will see them listed here"
				/>
			)}
		</>
	);
}
