//import { listLocations } from "../../graphql/admin/projectsScreen";

export const listLocations = /* GraphQL */ `
	query ListLocations(
		$filter: TableLocationFilterInput
		$limit: Int
		$nextToken: String
	) {
		listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				lineOne
				lineTwo
				city
				state
				zip
				sageIntacct
			}
		}
	}
`;

export const createLocation = /* GraphQL */ `
	mutation CreateLocation($input: CreateLocationInput!) {
		createLocation(input: $input) {
			id
		}
	}
`;

export const updateLocation = /* GraphQL */ `
	mutation UpdateLocation($input: UpdateLocationInput!) {
		updateLocation(input: $input) {
			id
			name
			lineOne
			lineTwo
			city
			state
			zip
			sageIntacct
			timeEntry
		}
	}
`;
