import React, { useState } from "react";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import * as Linking from "expo-linking";
import { useNavigation } from "@react-navigation/native";
import { View, Text, Image } from "react-native";

// components
import { Alert } from "../../components/Alert";
import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List";
import LoadingScreen from "../../components/LoadingScreen";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import Tabs, { Tab } from "../../components/Tabs";
import User from "../../components/User";
import OptionsMenu from "../../components/OptionsMenu";

// GQL

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import * as projectOperations from "../../graphql/project";
import * as subcontractorGQL from "../../graphql/admin/subcontractors";

import { getSignedUrl, API_URL } from "../../utils";

import {
	faClock,
	faFileSignature,
	faFileSpreadsheet,
	faUsers,
} from "@fortawesome/pro-duotone-svg-icons";

//utils
import { getData, calculateTimeDuration, SentryLogger } from "../../utils";

export default function SubAdminProjects({ search }) {
	const navigation = useNavigation();
	const [projects, setProjects] = useState([]);
	const [subcontractor, setSubcontractor] = React.useState(null);

	React.useEffect(() => {
		const getUserInfo = async () => {
			await getData("@storage_Key")
				.then((userData) => {
					if (
						!userData.subAdmin ||
						!userData.subcontractor ||
						!userData.subcontractor.id
					) {
						return navigation.navigate("App");
					}

					getSubcontractor({
						variables: {
							id: userData.subcontractor.id,
						},
					});
				})
				.catch((err) => {
					SentryLogger(err);
				});
		};
		getUserInfo();
	}, []);

	const [getSubcontractor, { loading }] = useLazyQuery(
		gql(subcontractorGQL.getSubcontractor),
		{
			onCompleted: (data) => {
				setSubcontractor(data.getSubcontractor);
				setProjects([...data.getSubcontractor.projects]);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error));
			},
			fetchPolicy: "cache-and-network",
		}
	);

	const navigateToProject = (id) => {
		navigation.navigate("Subcontractor Project", { id: id });
	};

	if (loading || !subcontractor) {
		return <LoadingScreen loadItem="Projects" />;
	}

	return (
		<>
			<Breadcrumb className="mt-2">
				<Crumb
					name={`${subcontractor.name} Projects`}
					location="Subcontractor Projects"
				/>
			</Breadcrumb>
			<PageHeader
				openMenu
				title={"Projects"}
				description={"All projects your company is working on"}
			/>

			<List headers={["Title", "Sage Intacct Number", "Stage", "Start Date"]}>
				{projects &&
					projects

						.filter((_project) => {
							return _project.title.toLowerCase().includes(search);
						})

						.map((project, index) => {
							return (
								<ListItem
									key={index}
									items={[
										{
											content: project.title
												? project.title
												: "No Project Title",
											// href: `/subAdmin/project/${project.id}`,
											onPress: () => {
												navigateToProject(project.id);
											},
										},
										{
											content: project.sageIntacct
												? project.sageIntacct
												: "No Project Sage Intacct",
											// href: `/subAdmin/project/${project.id}`,
											onPress: () => {
												navigateToProject(project.id);
											},
										},
										{
											content: project.stage
												? project.stage
												: "No Project Stage",
											// href: `/subAdmin/project/${project.id}`,
											onPress: () => {
												navigateToProject(project.id);
											},
										},
										{
											content: project.startDate
												? project.startDate
												: "No Project StartDate",
											// href: `/subAdmin/project/${project.id}`,
											onPress: () => {
												navigateToProject(project.id);
											},
										},
									]}
								/>
							);
						})}
			</List>
		</>
	);
}

SubAdminProjects.propTypes = {
	search: PropTypes.string,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
};

export const SubProject = ({ route }) => {
	const { id } = route.params;
	const navigation = useNavigation();
	const tabs = [
		{
			name: "Team Members",
			icon: faUsers,
		},
		{
			name: "Time Entries",
			icon: faClock,
		},
		{
			name: "Daily Sheets",
			icon: faFileSignature,
		},
	];
	const [user, setUser] = React.useState(null);
	const [currentTab, setCurrentTab] = useState(tabs[0].name);
	const [project, setProject] = useState(null);

	React.useEffect(() => {
		const getUserInfo = async () => {
			await getData("@storage_Key")
				.then((userData) => {
					if (
						!userData.subAdmin ||
						!userData.subcontractor ||
						!userData.subcontractor.id
					) {
						return navigation.navigate("App");
					}

					setUser(userData);
				})
				.catch((err) => {
					SentryLogger(err);
				});
		};
		getUserInfo();
	}, []);

	const { loading } = useQuery(gql(projectOperations.getProject), {
		variables: { id: id },
		onCompleted: (data) => {
			setProject(data.getProject);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	if (loading || !project) return <LoadingScreen loadItem={"project"} />;

	const downloadDailySheet = (date, pdf) => {
		fetch(`${API_URL}/billing/generate-daily-sheet-workbook`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				date: date,
				pdf: pdf,
				id: id,
				subcontractor: user.subcontractor,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				if (!data.url) {
					Alert("Error generating the daily sheet", data.message);
				} else {
					Linking.openURL(data.url);
				}
			})
			.catch((err) => {
				SentryLogger(err);
			});
	};

	const downloadTimeSheet = (date, pdf) => {
		let url = pdf
			? `${API_URL}/billing/generate-time-sheet`
			: `${API_URL}/time/time-by-keys`;
		fetch(`${url}`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},

			body: JSON.stringify({
				date: pdf ? date : null,
				startDate: date,
				endDate: date,
				pdf: pdf,
				id: id,
				subcontractor: pdf ? user.subcontractor : user.subcontractor.id,
				project: pdf ? project : id,
				allowUnapproved: true,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				if (!data.url) {
					Alert("Error generating the time sheet", data.message);
				} else {
					Linking.openURL(data.url);
				}
			})
			.catch((err) => {
				SentryLogger(err);
			});
	};
	const navigateToTimeEntry = (id) => {
		navigation.navigate("User", { id: id });
	};

	return (
		<>
			<Breadcrumb>
				<Crumb name="Projects" location="Subcontractor Projects" />
				<Crumb
					// href={`/subAdmin/project/${id}`}
					name={project.title}
					location="Subcontractor Project"
					params={{
						id: project.id,
					}}
				/>
			</Breadcrumb>
			<PageHeader
				openMenu
				title={project.title}
				subtitle={project.sageIntacct}
				goBack={{
					location: "Subcontractor Projects",
				}}
			/>
			<Tabs
				tabs={tabs}
				currentTab={currentTab}
				onChange={(tab) => {
					setCurrentTab(tab.name);
				}}
			>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							icon={tab.icon}
							onPress={() => setCurrentTab(tab.name)}
							current={currentTab === tab.name}
						/>
					);
				})}
			</Tabs>

			{currentTab === "Team Members" && (
				<List headers={["Team Member", "Role"]}>
					{project.team
						.filter(
							(tm) =>
								tm.teamMember &&
								tm.teamMember.subcontractor &&
								tm.teamMember.subcontractor.id === user.subcontractor.id
						)
						.map((teamMember) => {
							return (
								<>
									<ListItem
										key={teamMember.id}
										items={[
											{
												content: <User user={teamMember.teamMember} />,
												// href: `/subAdmin/user/${teamMember.teamMember.id}`,
												onPress: () => {
													navigation.navigate("User", {
														id: teamMember.teamMember.id,
													});
												},
											},
											{
												// content: <User user={teamMember.projectRole.name} />,
												// href: `/subAdmin/user/${teamMember.teamMember.id}`,
												onPress: () => {
													navigation.navigate("Subcontractor User", {
														id: teamMember.teamMember.id,
													});
												},
											},
										]}
									/>
								</>
							);
						})}
				</List>
			)}
			{currentTab === "Time Entries" && (
				<View>
					{Array.from(
						new Set(
							project.timeEntries
								.filter(
									(timeEntry) =>
										timeEntry.user &&
										timeEntry.user.subcontractor &&
										timeEntry.user.subcontractor.id === user.subcontractor.id
								)
								.map((timeEntry) =>
									DateTime.fromISO(timeEntry.timeIn, {
										setZone: true,
									}).toISODate()
								)
						)
					).map((date) => {
						return (
							<>
								<View className="flex flex-row items-center justify-between w-full p-2">
									<Text>
										{DateTime.fromISO(date).toFormat("LLLL dd, yyyy")}
									</Text>
									<OptionsMenu
										options={[
											{
												icon: faFileSignature,
												name: "Download Time Sheet",
												onPress: () => {
													downloadTimeSheet(date, true);
												},
											},
											{
												icon: faFileSpreadsheet,
												name: "Download XLSX Sheet",
												onPress: () => {
													downloadTimeSheet(date, false);
												},
											},
										]}
									/>
								</View>

								<List
									key={date}
									headers={["User", "Time In", "Time Out", "Hours", "Approved"]}
								>
									{project.timeEntries
										.filter(
											(timeEntry) =>
												DateTime.fromISO(timeEntry.timeIn, {
													setZone: true,
												}).toISODate() === date &&
												timeEntry.user &&
												timeEntry.user.subcontractor &&
												timeEntry.user.subcontractor.id ===
													user.subcontractor.id
										)
										.map((timeEntry) => {
											return (
												<ListItem
													key={timeEntry.id}
													items={[
														{
															content: <User user={timeEntry.user} />,
															href: `/subAdmin/user/${timeEntry.user.id}`,
															onPress: () => {
																navigateToTimeEntry(timeEntry.user.id);
															},
														},
														{
															content: DateTime.fromISO(timeEntry.timeIn, {
																setZone: true,
															}).toLocaleString(DateTime.DATETIME_MED),
														},
														{
															content: DateTime.fromISO(timeEntry.timeOut, {
																setZone: true,
															}).toLocaleString(DateTime.DATETIME_MED),
														},
														{
															content: calculateTimeDuration(
																timeEntry.timeIn,
																timeEntry.timeOut
															),
														},
														{
															content: timeEntry.approved ? "Yes" : "No",
														},
													]}
												/>
											);
										})}
								</List>
							</>
						);
					})}
				</View>
			)}

			{currentTab === "Daily Sheets" && (
				<>
					<View>
						{Array.from(
							new Set(
								project.dailyDocuments
									.filter(
										(dailyDocument) =>
											dailyDocument.user &&
											dailyDocument.user.subcontractor &&
											dailyDocument.user.subcontractor.id ===
												user.subcontractor.id
									)
									.map((dailyDocument) =>
										DateTime.fromISO(dailyDocument.date, {
											setZone: true,
										}).toISODate()
									)
							)
						).map((date) => {
							return (
								<>
									<View className="flex flex-row items-center justify-between w-full p-2">
										<Text>
											{DateTime.fromISO(date).toFormat("LLLL dd, yyyy")}
										</Text>
										<OptionsMenu
											options={[
												{
													icon: faFileSignature,
													name: "Download Daily Sheet Sheet",
													onPress: () => {
														downloadDailySheet(date, true);
													},
												},
												{
													icon: faFileSpreadsheet,
													name: "Download XLSX Sheet",
													onPress: () => {
														downloadDailySheet(date, false);
													},
												},
											]}
										/>
									</View>

									<List key={date} headers={["User", "Signature"]}>
										{project.dailyDocuments
											.filter(
												(dailyDocument) =>
													DateTime.fromISO(dailyDocument.date, {
														setZone: true,
													}).toISODate() === date &&
													dailyDocument.user &&
													dailyDocument.user.subcontractor &&
													dailyDocument.user.subcontractor.id ===
														user.subcontractor.id
											)
											.map((dailyDocument, index) => {
												return (
													<ListItem
														key={index}
														items={[
															{
																content: <User user={dailyDocument.user} />,
																// href: `/subAdmin/user/${dailyDocument.user.id}`,
																onPress: () => {
																	navigation.navigate("Subcontractor User", {
																		id: dailyDocument.user.id,
																	});
																},
															},
															{
																content: (
																	<>
																		{dailyDocument.signature ? (
																			<Image
																				source={{
																					uri: getSignedUrl(
																						dailyDocument.signature
																					),
																				}}
																				alt="Signature"
																				className="h-12 w-full object-contain"
																			/>
																		) : (
																			<Text className="text-gray-900 dark:text-white">
																				No Signature
																			</Text>
																		)}
																	</>
																),
															},
														]}
													/>
												);
											})}
									</List>
								</>
							);
						})}
					</View>
				</>
			)}
		</>
	);
};

SubProject.propTypes = {
	search: PropTypes.string,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
	route: PropTypes.object,
};
