import { useNavigation } from "@react-navigation/native";
import * as Clipboard from "expo-clipboard";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { Pressable, ScrollView, Text, View } from "react-native";
import * as Animatable from "react-native-animatable";
import Accordion from "react-native-collapsible/Accordion";
import { Row, Table } from "react-native-table-component";
import Toast from "react-native-toast-message";

// components
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import DataContainer, { DataItem } from "../../../components/DataDisplay";
import { Checkbox, TextArea } from "../../../components/Forms";
import { PageHeader } from "../../../components/Header";
import OptionsMenu from "../../../components/OptionsMenu";
import User from "../../../components/User";
import Modal from "../../../components/Modal";
import { Alert } from "../../../components/Alert";

// containers
import { DailySheetSlideOver } from "../../../containers/DailySheetSlideOver";

// icons
import {
	faCalendarAlt,
	faChevronRight,
	faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

// GraphQL
import { gql, useMutation, useQuery } from "@apollo/client";
import {
	faCheck,
	faHashtag,
	faPersonCircleCheck,
	faPersonCircleQuestion,
} from "@fortawesome/pro-duotone-svg-icons";
import * as dailyDocumentGQL from "../../../graphql/dailyDocument.js";
import * as projectGQL from "../../../graphql/clerk/projects";

// Utils
import DailySheetView from "../../../components/DailySheetView";
import LoadingScreen from "../../../components/LoadingScreen";
import { API_URL, getData } from "../../../utils";

const sendDailySheetReminders = async (users, project, date) => {
	const showToast = (user) => {
		Toast.show({
			type: "success",
			text1: "Reminder Sent to " + user.fullName,
			text2:
				"We have sent the notification requesting them to sign their daily sheet 🤗",
		});
	};

	users.map((user) => {
		let formattedDate = DateTime.fromISO(date).toFormat("MMMM dd, yyyy");
		let hmtInstructions = `

		<p>Hi, ${user.fullName}</p>
		<p>Please sign your daily sheet for ${project.title} on ${formattedDate}</p>

		<p>Instructions:</p>
		<ol>
			<li>Open the SRP inField app</li>
			<li>Click Time Clock</li>
			<li>Click the previous time entries tab</li>
			<li>Select the date</li>
			<li>Select the time entry for this project</li>
			<li>In the top click the options menu (3 vertical dots)</li>
			<li>Click "Create Daily Sheet"</li>
			<li>Fill out the daily sheet</li>
			<li>Click "Save"</li>
		</ol>



	`;
		fetch(`${API_URL}/notifications/notification`, {
			method: "POST",
			body: JSON.stringify({
				notification: {
					title: "🔔 Reminder: Sign your daily sheet",
					message: hmtInstructions,
					user: user.id,
				},
				type: "system",
			}),
		})
			.then(function (response) {
				// first then()
				if (response.ok) {
					return response.text();
				}

				throw new Error("Something went wrong.");
			})
			.then(function () {
				// second then()
				showToast(user);
			});
	});
};

const DataCard = ({ name, icon, value, currentSelected = false }) => {
	return (
		<View>
			<Pressable
				className={`${
					currentSelected ? "bg-indigo-500" : "bg-white"
				} my-2 mx-2 flex-1 shadow rounded-lg  `}
			>
				<View className="p-5">
					<View className="flex-col md:items-center">
						<View className="flex-shrink-0 mb-2 md:mb-0">
							<FontAwesomeIcon
								icon={icon}
								size={24}
								color={currentSelected ? "white" : "black"}
								aria-hidden="true"
							/>
						</View>
						<View className="md:ml-5">
							<Text
								className={`text-sm font-medium ${
									currentSelected ? "text-white" : "text-gray-500"
								} `}
							>
								{name}
							</Text>
							<Text
								className={`text-lg font-medium ${
									currentSelected ? "text-white" : "text-gray-900"
								}`}
							>
								{value}
							</Text>
						</View>
					</View>
				</View>
				<View
					className={`${
						currentSelected ? "bg-indigo-50" : "bg-gray-50"
					} px-5 py-3`}
				>
					<View className="text-sm">
						<Pressable>
							<Text className="font-medium text-cyan-700 hover:text-cyan-900">
								{currentSelected ? "Currently Viewing" : "View All"}
							</Text>
						</Pressable>
					</View>
				</View>
			</Pressable>
		</View>
	);
};

DataCard.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	icon: PropTypes.object.isRequired,
	currentSelected: PropTypes.bool,
};

export const DailySheetSummary = ({
	dailySheetSummaries,
	project,
	billingPeriod,
	billingPhase,
}) => {
	if (!dailySheetSummaries) return null;
	const [activeSections, setActiveSections] = React.useState([]);
	const navigation = useNavigation();

	const _updateSections = (activeSections) => {
		setActiveSections(activeSections);
	};

	const _renderHeader = (summary, index) => {
		let date = DateTime.fromISO(summary.date).toFormat("MMMM dd, yyyy");
		let missingDailyDocuments = summary.missingDailyDocuments;
		let dailyDocumentsCount = summary.dailyDocuments.length;
		let totalDailyDocuments =
			dailyDocumentsCount + missingDailyDocuments.length;

		return (
			<Animatable.View
				duration={1200}
				transition="backgroundColor"
				className={` border-b ${
					missingDailyDocuments.length
						? " border-orange-500"
						: " bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-800"
				} ${index % 2 === 0 ? "bg-gray-50 dark:bg-gray-800" : ""}`}
			>
				<Pressable
					onPress={() => {
						navigation.navigate("DailySheetDay", {
							date: summary.date,
							projectID: project.id,
						});
					}}
					className="block hover:bg-gray-50"
				>
					<View className="flex-row items-center px-4 py-4 sm:px-6">
						<View className="min-w-0 flex-1 sm:flex-row sm:items-center sm:justify-between">
							<View className="truncate">
								<View className="flex flex-row text-sm">
									<FontAwesomeIcon icon={faCalendarAlt} aria-hidden="true" />
									<Text className="truncate font-medium text-indigo-600">
										{date}
									</Text>
								</View>
								<View className="mt-2 flex">
									<View className="flex-row items-center text-sm text-gray-500">
										<Text className="flex-shrink-0 font-normal text-gray-500">
											<Text className="font-bold">
												{dailyDocumentsCount}/{totalDailyDocuments}
											</Text>{" "}
											Daily Sheets Submitted
										</Text>
									</View>
								</View>
							</View>
							<View className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
								{summary.missingDailyDocuments.length > 0 ? (
									<Text className="font-medium py-1">
										Users Missing Daily Sheet
									</Text>
								) : null}
								<View className="flex flex-row overflow-hidden">
									{summary.missingDailyDocuments.map((user, index) => (
										<View key={index} style={index !== 0 && { marginLeft: -4 }}>
											<User user={user} photoOnly size="xs" />
										</View>
									))}
								</View>
								{summary.missingDailyDocuments.length > 0 ? (
									<Pressable
										onPress={() =>
											sendDailySheetReminders(
												summary.missingDailyDocuments,
												project,
												summary.date
											)
										}
									>
										<Text className="text-sm font-medium py-1 text-indigo-500">
											Send Reminders
										</Text>
									</Pressable>
								) : null}
							</View>
						</View>
						<View className="ml-5 flex-shrink-0">
							<FontAwesomeIcon icon={faChevronRight} aria-hidden="true" />
						</View>
					</View>
				</Pressable>
			</Animatable.View>
		);
	};

	const _renderContent = (section) => {
		return (
			<View className="m-4">
				<DataContainer>
					<DataItem
						name="Total Daily Sheets"
						value={section.dailyDocuments.length}
					/>
				</DataContainer>

				{section.missingDailyDocuments.length ? (
					<>
						<View className="mt-2 flex-row ">
							<Text>{section.perDiem} Per Diem</Text>
							<Text>{section.perDiem} Per Diem</Text>
						</View>
					</>
				) : null}
			</View>
		);
	};

	return (
		<View className="flex-1">
			<ScrollView className=" ">
				<Accordion
					sections={[...dailySheetSummaries]
						.filter((summary) => {
							if (billingPhase) {
							} else {
								if (!billingPeriod) return true;
								return (
									DateTime.fromISO(summary.date) >=
										DateTime.fromISO(billingPeriod.startDate) &&
									DateTime.fromISO(summary.date) <=
										DateTime.fromISO(billingPeriod.endDate)
								);
							}
						})
						.sort((a, b) => {
							if (billingPhase) {
								if (a.name > b.name) return -1;
								if (a.name < b.name) return 1;
								return 0;
							} else {
								if (a.date > b.date) return -1;
								if (a.date < b.date) return 1;
								return 0;
							}
						})}
					activeSections={activeSections}
					renderHeader={_renderHeader}
					renderContent={_renderContent}
					onChange={_updateSections}
				/>
			</ScrollView>
		</View>
	);
};

DailySheetSummary.propTypes = {
	dailySheetSummaries: PropTypes.array,
	project: PropTypes.object,
	billingPeriod: PropTypes.object,
	billingPhase: PropTypes.object,
};

const DailySheetOptions = [
	{
		name: "Per Diem",
		key: "perDiem",
	},
	{
		name: "Boroscope",
		key: "boroscope",
	},
	{
		name: "Fall Protection (PFP)",
		key: "fallProtection",
	},
	{
		name: "Matterport",
		key: "matterport",
	},
	{
		name: "Mobile Command Center",
		key: "mobileCommandCenter",
	},
	{
		name: "Moisture Meter",
		key: "moistureMeter",
	},
	{
		name: "Respirator (PRP)",
		key: "respirator",
	},
	{
		name: "360 Spherical Camera",
		key: "sphericalCamera",
	},
	{
		name: "FLIR",
		key: "flir",
	},
	{
		name: "Thermohygrometer",
		key: "thermohygrometer",
	},
	{
		name: "Trailer Package",
		key: "trailerPackage",
	},
	{
		name: "Vehicle",
		key: "vehicle",
	},
];

export const DailySheetDay = ({ route }) => {
	const { projectID, date, billingPeriodID, billingPhaseID } = route.params;
	const [project, setProject] = React.useState({});
	const [currentProject, setCurrentProject] = React.useState(null);
	const [dailyDocuments, setDailyDocuments] = React.useState([]);
	const [dailyDocument, setDailyDocument] = React.useState(null);
	const [dailySheetSummary, setDailySheetSummary] = React.useState(null);
	const [edit, setEdit] = React.useState(false);
	const [editedDailyDocuments, setEditedDailyDocuments] = React.useState([]);
	const [item, setItem] = React.useState(null);
	const [dailySheetUser, setDailySheetUser] = React.useState(null);
	const navigation = useNavigation();

	const [user, setUser] = React.useState(null);

	React.useEffect(() => {
		getData("@storage_Key").then((user) => setUser(user));
	}, []);

	const { refetch: refetchSummary } = useQuery(
		gql(dailyDocumentGQL.getDailyDocumentSummary),
		{
			variables: {
				project: projectID,
				date,
			},
			onCompleted: (data) => {
				setDailySheetSummary(data.getDailyDocumentSummary);
				setProject(data.getDailyDocumentSummary.project);
				setDailyDocuments(data.getDailyDocumentSummary.dailyDocuments);
			},

			fetchPolicy: "cache-and-network",
		}
	);

	useQuery(gql(projectGQL.getProject), {
		variables: {
			id: projectID,
		},
		onCompleted: (data) => {
			setCurrentProject(data.getProject);
		},

		fetchPolicy: "cache-and-network",
	});

	const [bulkUpdateDailyDocuments] = useMutation(
		gql(dailyDocumentGQL.bulkUpdateDailyDocuments),
		{
			onCompleted: (data) => {
				setEdit(false);
				setEditedDailyDocuments(() => []);
				refetchSummary();
				Toast.show({
					type: "success",
					text1: "Daily Documents Updated",
					text2: `${data.bulkUpdateDailyDocuments.length} Daily Documents have been updated`,
				});
			},
		}
	);

	const [updateDailyDocument] = useMutation(
		gql(dailyDocumentGQL.updateDailyDocument),
		{
			onCompleted: (data) => {
				setEdit(false);
				setEditedDailyDocuments(() => []);
				refetchSummary();
				Toast.show({
					type: "success",
					text1: "Daily Document Updated",
					text2: `Daily document for ${data.updateDailyDocument.user.fullName} has been updated`,
				});
			},
		}
	);

	const [deleteDailyDocument] = useMutation(
		gql(dailyDocumentGQL.deleteDailyDocument),
		{
			onCompleted: () => {
				setItem(null);
				refetchSummary();
			},
			onError: () => {
				Alert("We could not delete this Daily Sheet, please try again");
			},
		}
	);

	const getCheckedValue = (dailyDocument, option) => {
		// check the edited daily documents first
		const editedDailyDocument = editedDailyDocuments.find(
			(editedDailyDocument) => editedDailyDocument.id === dailyDocument.id
		);
		if (editedDailyDocument && editedDailyDocument[option]) {
			return editedDailyDocument[option];
		}
		if (dailyDocument[option]) {
			return dailyDocument[option];
		}

		return false;
	};

	const updatedEditedDailyDocuments = (dailyDocument, option, value) => {
		const editedDailyDocument = editedDailyDocuments.find(
			(editedDailyDocument) => editedDailyDocument.id === dailyDocument.id
		);
		if (editedDailyDocument) {
			editedDailyDocument[option] = value;
			setEditedDailyDocuments((prev) => [...prev]);
		} else {
			const newEditedDocument = {
				id: dailyDocument.id,
				[option]: value,
			};
			setEditedDailyDocuments([...editedDailyDocuments, newEditedDocument]);
		}
	};

	const getHeaderText = () => {
		const headerText = ["Name", "Activities", "Hotel", "Rental Car"];

		DailySheetOptions.forEach((option) => {
			headerText.push(option.name);
		});
		headerText.push("Options");
		return headerText;
	};

	const getFooterText = () => {
		const footerText = ["Name", "Activities", "Hotel", "Rental Car"];

		DailySheetOptions.forEach((option) => {
			footerText.push(
				dailyDocuments.reduce((total, dailyDocument) => {
					if (editedDailyDocuments.length > 0) {
						const editedDailyDocument = editedDailyDocuments.find(
							(editedDailyDocument) =>
								editedDailyDocument.id === dailyDocument.id
						);
						if (editedDailyDocument && editedDailyDocument[option.key]) {
							return total + 1;
						}
					}

					if (dailyDocument[option.key]) {
						return total + 1;
					}
					return total;
				}, 0)
			);
		});
		footerText.push("Options");

		return footerText;
	};

	const getRowData = (dailyDocument) => {
		const getActivities = () => {
			if (edit) {
				return (
					<>
						<TextArea
							defaultValue={dailyDocument.activities}
							onChange={(text) =>
								updatedEditedDailyDocuments(dailyDocument, "activities", text)
							}
						/>
					</>
				);
			}

			if (dailyDocument.activities) {
				return (
					<>
						<Text>{dailyDocument.activities}</Text>
					</>
				);
			}
			return <Text>No Activities</Text>;
		};

		const getHotel = () => {
			if (dailyDocument.hotelName && dailyDocument.hotelRoom) {
				return (
					<>
						<Text>{dailyDocument.hotel}</Text>
						<Text>{dailyDocument.hotelRoom}</Text>
					</>
				);
			}
			return <Text>No Hotel</Text>;
		};

		const getRentalCar = () => {
			if (dailyDocument.rentalCar) {
				return (
					<>
						<Text>{dailyDocument.rentalCar}</Text>
					</>
				);
			}
			return <Text>No Rental Car</Text>;
		};

		const rowData = [];
		rowData.push(
			<User user={dailyDocument.user} />,
			getActivities(),
			getHotel(),
			getRentalCar()
		);
		DailySheetOptions.forEach((option) => {
			rowData.push(
				<Checkbox
					disabled={!edit}
					checkedValue={getCheckedValue(dailyDocument, option.key)}
					onChange={(value) => {
						updatedEditedDailyDocuments(dailyDocument, option.key, value);
					}}
				/>
			);
		});
		rowData.push(
			<>
				<OptionsMenu
					options={[
						{
							name: "View",
							onPress: () => {
								navigation.navigate("Clerk Single Daily Sheet", {
									dailySheetID: dailyDocument.id,
									projectID,
									billingPeriodID,
									billingPhaseID,
									date,
								});
							},
						},
						{
							name: "Edit",
							onPress: () => {
								setItem("updateDailyDocument");
								setDailyDocument(dailyDocument);
							},
						},
						{
							name: "Copy UUID",
							onPress: () => {
								// copy the daily sheet to the clipboard
								Clipboard.setStringAsync(dailyDocument.id);
							},
						},
						{
							name: dailyDocument.locked ? "Unlock" : "Lock",
							additionalDetails: dailyDocument.locked
								? `Unlock the daily sheet so it can be edited by ${dailyDocument.user.fullName}`
								: `Lock the daily sheet so it can't be edited by ${dailyDocument.user.fullName}`,
							onPress: () => {
								// unlock the daily sheet
								updateDailyDocument({
									variables: {
										input: {
											id: dailyDocument.id,
											locked: !dailyDocument.locked,
										},
									},
								});
							},
						},
						{
							name: "Delete Daily Sheet",
							onPress: () => {
								if (user.isSuperClerk) {
									setItem("deleteDailySheet");
									setDailyDocument(dailyDocument);
								} else {
									Toast.show({
										type: "error",
										text1: "You do not have permission to delete daily sheets",
										text2: "Only Super Clerks can delete daily sheets",
									});
								}
							},
						},
					]}
				/>
			</>
		);
		return rowData;
	};

	const cards = [
		{
			name: "Total Daily Sheets",
			value:
				dailySheetSummary?.missingDailyDocuments?.length +
					dailySheetSummary?.dailyDocuments?.length || 0,

			icon: faHashtag,
		},
		{
			name: "Missing Daily Sheets",
			value: dailySheetSummary?.missingDailyDocuments.length || 0,

			icon: faPersonCircleQuestion,
		},
		{
			name: "Completed Daily Sheets",
			value: dailySheetSummary?.dailyDocuments?.length || 0,
			icon: faPersonCircleCheck,
		},
	];

	return (
		<ScrollView className="flex-1">
			<Breadcrumb baseLocation="Work In Progress">
				<Crumb name="Work In Progress" location="Work In Progress" />
				<Crumb
					name={project?.title}
					location={billingPeriodID ? "ClerkProject" : "ClerkProjectPhase"}
					params={
						billingPeriodID
							? {
									id: projectID,
									billingPeriodID,
									title: project?.title,
							  }
							: {
									id: projectID,
									billingPhaseID,
									title: project?.title,
							  }
					}
				/>
				<Crumb
					name={DateTime.fromISO(date).toFormat("MMMM dd, yyyy")}
					location={billingPeriodID ? "DailySheetDay" : "DailySheetDayPhase"}
					params={
						billingPeriodID
							? {
									id: projectID,
									billingPeriodID,
									title: project?.title,
							  }
							: {
									id: projectID,
									billingPhaseID,
									title: project?.title,
							  }
					}
				/>
			</Breadcrumb>
			<PageHeader
				goBack={{
					location: "ClerkProject",
					params: { project },
				}}
				title={DateTime.fromISO(date).toFormat("MMMM dd, yyyy")}
				description={`${project.title} Daily Sheets`}
				buttons={[
					{
						name: edit ? "Finish Editing" : "Edit Daily Sheets",
						onPress: () => {
							if (edit) {
								if (editedDailyDocuments.length === 0) {
									return setEdit(false);
								}

								bulkUpdateDailyDocuments({
									variables: {
										input: editedDailyDocuments,
									},
								});
							} else {
								setEdit(true);
							}
						},
						icon: edit ? faCheck : faPen,
					},
				]}
			/>

			<View className="mt-8">
				<View className="max-w-6xl px-4 sm:px-6">
					<Text className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
						Overview
					</Text>
					<View className="block flex-row flex-wrap w-full">
						{cards.map((card, index) => (
							<DataCard
								key={index}
								name={card.name}
								value={card.value}
								icon={card.icon}
							/>
						))}
					</View>
				</View>
			</View>
			<View className="mx-4">
				{dailySheetSummary && (
					<>
						{dailySheetSummary?.missingDailyDocuments.length > 0 ? (
							<>
								<View className="flex flex-row items-center justify-between mt-2">
									<Text className="font-medium text-lg mt-4  dark:text-white">
										Missing Daily Sheets
									</Text>
									<Button
										size="sm"
										text="Send Reminders"
										onPress={() => {
											sendDailySheetReminders(
												dailySheetSummary?.missingDailyDocuments,
												project,
												date
											);
										}}
									/>
								</View>

								<View className="shadow rounded-lg w-full p-4">
									{dailySheetSummary?.missingDailyDocuments.map(
										(user, index) => (
											<View
												className="w-full flex flex-row items-center space-between"
												key={index}
											>
												<User user={user} />
												<View>
													<Button
														size="sm"
														text="Create Daily Sheet"
														onPress={() => {
															setItem("newDailySheet");
															setDailySheetUser(user);
														}}
													/>
												</View>
											</View>
										)
									)}
								</View>
							</>
						) : null}
					</>
				)}

				<Text className="font-medium text-lg mt-4">
					Daily Sheet Information
				</Text>
				<View className="shadow rounded-lg p-4">
					<ScrollView horizontal={true}>
						<Table>
							<Row data={getHeaderText()} flexArr={[2]} />
							{dailyDocuments.map((dailyDocument, index) => (
								<Row
									flexArr={[2]}
									key={index}
									data={getRowData(dailyDocument)}
									style={{ flex: 1 }}
								/>
							))}

							<Row data={getFooterText()} flexArr={[2]} />
						</Table>
					</ScrollView>
				</View>
			</View>
			<DailySheetSlideOver
				isOpen={item === "newDailySheet"}
				onClose={() => setItem("")}
				onComplete={() => {
					setItem("");
					refetchSummary();
				}}
				suppliedUser={dailySheetUser}
				clerkingData={{
					project: project.id,
					date: date,
				}}
				clerking
				currentProject={currentProject}
				type="create"
			/>

			<DailySheetSlideOver
				isOpen={item === "updateDailyDocument"}
				onClose={() => {
					setItem("");
					setDailyDocument(null);
				}}
				onComplete={() => {
					setItem("");
					setDailyDocument(null);
					refetchSummary();
				}}
				currentDailySheet={dailyDocument}
				clerkingData={{
					project: project.id,
					date: date,
				}}
				clerking
				currentProject={currentProject}
				type="update"
			/>

			<Modal
				title="Delete Daily Sheet"
				description="Are you sure you want to delete this daily sheet? This is permanent and cannot be undone. The user will need to make a new daily sheet to replace this one."
				isOpen={item === "deleteDailySheet"}
				onClose={() => {
					setItem("");
				}}
				rightButton={{
					text: "Delete Daily Sheet",
					onPress: () => {
						deleteDailyDocument({
							variables: {
								input: {
									id: dailyDocument.id,
								},
							},
						});
					},
				}}
			/>
		</ScrollView>
	);
};

DailySheetDay.propTypes = {
	route: PropTypes.object,
};

export const SingleDailySheet = ({ route }) => {
	const { dailySheetID, projectID, billingPeriodID, billingPhaseID, date } =
		route.params;
	const [dailySheet, setDailySheet] = React.useState(null);

	useQuery(gql(dailyDocumentGQL.getDailyDocument), {
		variables: {
			id: dailySheetID,
		},
		onCompleted: (data) => {
			setDailySheet(data.getDailyDocument);
		},
	});

	if (!dailySheet) {
		return <LoadingScreen loadItem="Daily Sheet" />;
	}

	return (
		<>
			<Breadcrumb>
				<Crumb
					name="Work In Progress"
					location="Projects"
					params={{
						projectID: dailySheet?.project?.id,
					}}
				/>
				<Crumb
					name={dailySheet?.project?.title}
					location="ClerkProject"
					params={{
						projectID: dailySheet?.project?.id,
					}}
				/>
				<Crumb
					name={DateTime.fromISO(dailySheet?.date).toFormat("MMMM dd, yyyy")}
					location="DailySheetDay"
					params={{
						projectID: dailySheet?.project?.id,
						date: dailySheet?.date,
					}}
				/>
				<Crumb name="Daily Sheet" />
			</Breadcrumb>
			<PageHeader
				title={dailySheet?.user.fullName}
				description={DateTime.fromISO(dailySheet?.date).toFormat(
					"MMMM dd, yyyy"
				)}
				goBack={{
					location: "DailySheetDay",
					params: billingPeriodID
						? {
								projectID: projectID,
								billingPeriodID,
								date,
						  }
						: {
								projectID: projectID,
								billingPhaseID,
								date,
						  },
				}}
			/>
			<DailySheetView dailyDocument={dailySheet} showTimeEntryDetails={false} />
		</>
	);
};

SingleDailySheet.propTypes = {
	route: PropTypes.object,
};
