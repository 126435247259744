import * as Linking from "expo-linking";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { View } from "react-native";

// Components
import { Alert } from "../components/Alert";
import {
	Checkbox,
	FileUpload,
	Input,
	Radio,
	Select,
	TextArea,
} from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation, useQuery } from "@apollo/client";
import * as safetyGQL from "../graphql/safety/safetyQuestionnaire";
import * as userGQL from "../graphql/user";

// Utils
import Button from "../components/Button";
import { getData, SentryLogger } from "../utils";
import { uploadFile } from "../utils/files";

export const SafetyQuestionnaireSlideOver = ({
	type = "create",
	currentSafetyQuestionnaire,
	isOpen,
	closeRequest = () => {},
	onComplete,
	project,
	timeEntry,
}) => {
	const [user, setUser] = React.useState({});
	const [newSafetyQuestionnaire, setNewSafetyQuestionnaire] = React.useState({
		reportedToPM: null,
		requiredCare: null,
		occurredOnJobSite: null,
		injuries: "",
		howInjuryOccurred: "",
		wearingPPE: null,
		providedPPE: null,
		dateCreated: "",
	});
	const [injuries, setInjuries] = useState([]);
	const [extendedInjury, setExtendedInjury] = React.useState("");
	const [otherInjury, setOtherInjury] = React.useState(false);
	const [wounds, setWounds] = React.useState(false);
	const [reportedInjury, setReportedInjury] = React.useState(false);
	const [safetyUsers, setSafetyUsers] = React.useState([]);
	const [files, setFiles] = React.useState([]);

	const handleInjuryChange = (value) => {
		if (injuries.includes(value)) {
			setInjuries(injuries.filter((injury) => injury !== value));
		} else {
			setInjuries([...injuries, value]);
		}
	};
	useEffect(() => {
		if (currentSafetyQuestionnaire) {
			setNewSafetyQuestionnaire(currentSafetyQuestionnaire);
			// set the current injuries
			setInjuries(
				currentSafetyQuestionnaire.injuries
					? currentSafetyQuestionnaire.injuries.split(",")
					: []
			);
		}
	}, [currentSafetyQuestionnaire]);

	React.useEffect(() => {
		if (newSafetyQuestionnaire) {
			setNewSafetyQuestionnaire(newSafetyQuestionnaire);
		}
	}, [newSafetyQuestionnaire]);

	React.useEffect(() => {
		getData("@storage_Key").then((user) => {
			setUser(user);
		});
	}, []);

	// list users and filter out isSafetyUser
	useQuery(gql(userGQL.listUsers), {
		variables: {
			filter: {
				isSafetyUser: true,
			},
		},
		onCompleted: (data) => {
			setSafetyUsers(data.listUsers.items);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
	});

	const [bulkCreateMedia] = useMutation(gql(safetyGQL.bulkCreateMedia), {
		onCompleted: async (data) => {
			onComplete(data);
			setFiles([]);
		},
		onError: (error) => {
			Alert(
				"We could not upload the images you attached. Please keep them send later"
			);

			SentryLogger(JSON.stringify(error, null, 2));
			onComplete();
			setFiles([]);
		},
	});

	const [createSafetyQuestionnaire] = useMutation(
		gql(safetyGQL.createSafetyQuestionnaire),
		{
			onCompleted: (data) => {
				setNewSafetyQuestionnaire({
					user: user.id,
				});

				if (files.length) {
					let keys = [];
					Promise.all(
						files.map(async (file) => {
							//upload files

							let key = `safetyCenter/${data.createSafetyQuestionnaire.id}/${file.name}`;

							await uploadFile(file, key)
								.then(({ key }) => {
									keys.push({
										key: key,
										safetyQuestionnaire: data.createSafetyQuestionnaire.id,
									});
								})
								.catch((err) => {
									SentryLogger(err);
								});
						})
					).then(() => {
						bulkCreateMedia({
							variables: {
								input: keys,
							},
						});
					});
				} else {
					onComplete(data.createSafetyQuestionnaire);
				}
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);

	//update safetyQuestionnaire

	const [updateSafetyQuestionnaire] = useMutation(
		gql(safetyGQL.updateSafetyQuestionnaire),

		{
			onCompleted: (data) => {
				onComplete(data.updateSafetyQuestionnaire);
				setNewSafetyQuestionnaire(data.updateSafetyQuestionnaire);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);

	return (
		<SlideOver
			isOpen={isOpen}
			name={
				type === "create" ? "New Safety Questionnaire " : "Update Report Status"
			}
			description={
				type === "create"
					? "Please fill out the following form to create a new safety questionnaire."
					: "Update the status of the report"
			}
			closeRequest={() => closeRequest()}
			onSubmit={() => {
				if (type === "create") {
					let newDate = DateTime.now().toISO();

					createSafetyQuestionnaire({
						variables: {
							input: {
								...newSafetyQuestionnaire,
								dateCreated: newDate,
								timeEntry: timeEntry.id,
								injuries: injuries.join(","),
								extendedInjury: extendedInjury,
							},
						},
					});
				} else {
					updateSafetyQuestionnaire({
						variables: {
							input: {
								id: currentSafetyQuestionnaire.id,
								status: newSafetyQuestionnaire.status,
								reviewer: newSafetyQuestionnaire.reviewer.id,
							},
						},
					});
				}
			}}
			project={project}
			timeEntry={timeEntry}
			currentSafetyQuestionnaire={currentSafetyQuestionnaire}
			buttonRightText={type === "create" ? "Create" : "Update"}
		>
			{type === "create" ? (
				<View>
					{/* ADD CREATE QUESTIONNAIRE */}
					<Radio
						label="Do you require immediate medical care or first aid?"
						options={[
							{
								label: "Yes",
								description:
									"Call 911 if you have not already done so. We will notify your project manager and they will contact you to discuss the next steps.",
								value: true,
							},
							{
								label: "No",
								description: "Continue to next question",
								value: false,
							},
						]}
						onChange={(value) => {
							setNewSafetyQuestionnaire({
								...newSafetyQuestionnaire,
								requiredCare: value.value,
							});
							if (value.value === null) {
								Alert("You must select an option");
							}

							setReportedInjury(!value.value);
						}}
					/>
					{newSafetyQuestionnaire.requiredCare === true && (
						<Button
							text="Call 911"
							variant="red"
							onPress={() => {
								Linking.openURL("tel:911");
							}}
						/>
					)}
					{reportedInjury && (
						<Radio
							label="Have you reported this injury to the Project Manager?"
							required
							options={[
								{
									label: "Yes",
									value: true,
								},
								{
									label: "No",
									description: "Continue to next question",
									value: false,
								},
							]}
							onChange={(value) => {
								setNewSafetyQuestionnaire({
									...newSafetyQuestionnaire,
									reportedToPM: value.value,
								});

								setWounds(!value.value);
							}}
						></Radio>
					)}

					{user && user.isContractor === false && (
						<>
							{wounds && (
								<>
									<Checkbox
										label="Cuts"
										checkedValue={injuries.includes("Cut")}
										onChange={() => handleInjuryChange("Cut")}
									/>
									<Checkbox
										label="Fracture"
										checkedValue={injuries.includes("Fracture")}
										onChange={() => handleInjuryChange("Fracture")}
									/>

									<Checkbox
										label="Sprain"
										checkedValue={injuries.includes("Sprain")}
										onChange={() => handleInjuryChange("Sprain")}
									/>
									<>
										<Checkbox
											label="Other"
											checkedValue={injuries.includes("Other")}
											onChange={() => {
												setOtherInjury(!otherInjury);
											}}
										/>
										{otherInjury && (
											<Input
												label={"Please enter the type of injury"}
												onChange={(value) => {
													setExtendedInjury(value);
												}}
											/>
										)}
									</>
								</>
							)}

							{injuries.length ? (
								<>
									<TextArea
										label={"Please provide details of the injury"}
										name={"Please state the nature of your injury."}
										onChange={(value) => {
											setNewSafetyQuestionnaire({
												...newSafetyQuestionnaire,
												howInjuryOccurred: value,
											});
										}}
									/>

									<Radio
										label="Did your injury occur on the job site?"
										options={[
											{ label: "Yes", value: true },
											{
												label: "No",
												value: false,
											},
										]}
										onChange={(value) => {
											setNewSafetyQuestionnaire({
												...newSafetyQuestionnaire,
												occurredOnJobSite: value.value,
											});
										}}
									/>
								</>
							) : null}

							{newSafetyQuestionnaire.occurredOnJobSite && (
								<>
									<Radio
										label="Were you wearing PPE??"
										required
										options={[
											{ label: "Yes", value: true },
											{
												label: "No",
												value: false,
											},
										]}
										onChange={(value) => {
											setNewSafetyQuestionnaire({
												...newSafetyQuestionnaire,
												wearingPPE: value.value,
											});
										}}
									></Radio>

									<Radio
										label="Were you issued PPE?"
										options={[
											{ label: "Yes", value: true },
											{
												label: "No",
												value: false,
											},
										]}
										onChange={(value) => {
											setNewSafetyQuestionnaire({
												...newSafetyQuestionnaire,
												providedPPE: value.value,
											});
											if (value.value === null) {
												Alert("You must select an option");
											}
										}}
									/>
								</>
							)}

							<View className="mt-5 border-t border-gray-500 pt-2">
								<FileUpload
									label="Upload any photos of the injury"
									multiple={true}
									onFiles={(fileData) => {
										setFiles(fileData);
									}}
								/>
							</View>
						</>
					)}
				</View>
			) : (
				// if user if updating a safety questionnaire show this
				<View>
					<Select
						name={"Case Status"}
						defaultValue={currentSafetyQuestionnaire.status}
						onChange={(value) => {
							setNewSafetyQuestionnaire({
								...newSafetyQuestionnaire,
								status: value.value,
							});
						}}
						options={[
							{ name: "Under Investigation", value: "Under Investigation" },
							{
								name: "Completed",
								value: "Completed",
							},
						]}
					/>
					<Select
						name={"Assigned To"}
						defaultValue={
							currentSafetyQuestionnaire.reviewer?.firstName +
							" " +
							currentSafetyQuestionnaire.reviewer?.lastName
						}
						onChange={(value) => {
							setNewSafetyQuestionnaire({
								...newSafetyQuestionnaire,
								reviewer: value.value,
							});
						}}
						options={safetyUsers.map((safetyUser) => {
							return {
								name: `${safetyUser.firstName} ${safetyUser.lastName}`,
								value: safetyUser,
							};
						})}
					/>
				</View>
			)}
		</SlideOver>
	);
};
SafetyQuestionnaireSlideOver.propTypes = {
	type: PropTypes.string,
	currentSafetyQuestionnaire: PropTypes.object,
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func,
	onComplete: PropTypes.func,
	project: PropTypes.object,
	timeEntry: PropTypes.object,
};
