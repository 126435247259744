import { Table, TBody, TD, TH, THead, TR } from "@expo/html-elements";
import * as Linking from "expo-linking";
import { DateTime } from "luxon";
import { StyledComponent } from "nativewind";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React from "react";
import { Image, ScrollView, Text, View } from "react-native";
import * as Animatable from "react-native-animatable";
import Accordion from "react-native-collapsible/Accordion";

// containers
import { TimeEntrySlideover } from "../../../containers/TimeEntrySlideOver";

// components
import { Alert } from "../../../components/Alert";
import Badge from "../../../components/Badge";
import { Checkbox } from "../../../components/Forms";
import OptionsMenu from "../../../components/OptionsMenu";
import User from "../../../components/User";

// graphql
import { gql, useMutation } from "@apollo/client";
import * as timeEntryGQL from "../../../graphql/timeEntry";

// icons
import {
	faFileExcel,
	faFileInvoice,
	faPen,
	faPrint,
	faTrash,
} from "@fortawesome/pro-duotone-svg-icons";

// utils
import {
	API_URL,
	calculateTimeDuration,
	getData,
	getSignedUrl,
} from "../../../utils";

const StyledTH = ({ children }) => (
	<StyledComponent component={TH} className="text-blue-500 text-left ">
		{children}
	</StyledComponent>
);

StyledTH.propTypes = {
	children: PropTypes.node,
};

const StyledTR = ({ children }) => (
	<StyledComponent component={TR} className="py-2">
		{children}
	</StyledComponent>
);

StyledTR.propTypes = {
	children: PropTypes.node,
};
const StyledTD = ({ children }) => (
	<StyledComponent component={TD} className="px-2">
		{children}
	</StyledComponent>
);

StyledTD.propTypes = {
	children: PropTypes.node,
};

const TimeEntriesOptions = ({
	project,
	timeEntries,
	timeEntrySummary,
	date,
}) => {
	return (
		<OptionsMenu
			options={[
				{
					onPress: () => {
						fetch(`${API_URL}/billing/generate-time-sheet`, {
							method: "POST",

							body: JSON.stringify({
								project: project,
								id: project.id,
								date: timeEntrySummary.date,
								teamMembers: project.team,
							}),
						})
							.then((res) => {
								return res.json();
							})
							.then((data) => {
								// download the data.url file

								Linking.openURL(data.url);
							})
							.catch((err) => {
								Alert(
									"There was an error generating the timesheet",
									err.message
								);
							});
					},
					name: "Generate Time Sheet",
					icon: faPrint,
				},
				{
					onPress: () => {
						fetch(`${API_URL}/data/project-time-book/`, {
							method: "POST",

							body: JSON.stringify({
								id: project.id,
								date: timeEntrySummary.date,
								timeEntries: timeEntries.filter(
									(te) => DateTime.fromISO(te.timeIn).toISODate() === date
								),
								teamMembers: project.team,
							}),
						})
							.then((res) => {
								return res.json();
							})
							.then((data) => {
								Linking.openURL(data.url);
							})
							.catch((err) => {
								Alert("There was an error generating the file", err.message);
							});
					},
					name: "Generate Time Workbook",
					icon: faFileExcel,
				},
				{
					onPress: () => {
						let body = {
							id: id,
							date: date,
							timeEntries: timeEntries.filter(
								(te) => DateTime.fromISO(te.timeIn).toISODate() === date
							),
							teamMembers: project.team,
							project: project,
						};

						fetch(`${API_URL}/data/sage-time-book/`, {
							method: "POST",
							body: JSON.stringify(body),
						})
							.then((res) => {
								return res.json();
							})
							.then((data) => {
								Linking.openURL(data.url);
							})
							.catch((err) => {
								Alert(
									"There was an error generating the sage file",
									err.message
								);
							});
					},
					name: "Generate Sage File",
					icon: faFileInvoice,
				},

				{
					onPress: () => {
						let body = {
							date: date,
							project: {
								id: project.id,
								title: project.title,
							},
						};

						fetch(`${API_URL}/billing/cow-file`, {
							method: "POST",
							body: JSON.stringify(body),
						})
							.then((res) => res.json())
							.then((data) => {
								if (data.url) {
									Linking.openURL(data.url);
								} else {
									Alert(
										"There was an error generating the cow file",
										data.message
									);
								}
							})
							.catch((err) => {
								Alert(
									"There was an error generating the cow file",
									err.message
								);
							});
					},
					name: "Generate COW File",
					icon: faFileInvoice,
				},
			]}
		/>
	);
};

TimeEntriesOptions.propTypes = {
	project: PropTypes.object,
	timeEntries: PropTypes.array,
	timeEntrySummary: PropTypes.object,
	date: PropTypes.string,
};

export const TimeEntriesSummary = ({
	project,
	refetch,
	billingPeriod,
	billingPhase,
}) => {
	const [user, setUser] = React.useState(null);
	const [newItem, setNewItem] = React.useState(null);
	const [currentTimeEntry, setCurrentTimeEntry] = React.useState(null);
	const [activeSections, setActiveSections] = React.useState([]);

	React.useEffect(() => {
		const getUser = async () => {
			const user = await getData("@storage_Key");
			setUser(user);
		};
		getUser();
	}, []);

	const _updateSections = (activeSections) => {
		setActiveSections(activeSections);
	};

	const [updateTimeEntry] = useMutation(gql(timeEntryGQL.updateTimeEntry), {
		onCompleted: () => {
			setNewItem(null);
			setCurrentTimeEntry({});
			refetch();
		},
		onError: () => {
			Alert("Couldn't update time entry please try again");
		},
	});

	const [deleteTimeEntry] = useMutation(gql(timeEntryGQL.deleteTimeEntry), {
		onCompleted: () => {
			setNewItem(null);
			setCurrentTimeEntry({});
			refetch();
		},
		onError: () => {
			Alert("Couldn't delete time entry please try again");
		},
	});

	const _renderHeader = (
		{
			date,
			timeEntries,
			billableTimeEntries,
			approvedTimeEntries,
			missingTimeEntries,
		},
		index
	) => {
		return (
			<Animatable.View
				duration={500}
				transition="backgroundColor"
				className={`flex flex-row justify-between border-b items-center p-2 px-3 bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-800
				 ${index % 2 === 0 ? "bg-gray-50 dark:bg-gray-800" : ""}`}
			>
				<View className="w-full">
					<View className="flex flex-row items-center justify-between">
						<Text className="text-lg font-medium dark:text-white">
							{DateTime.fromISO(date).toFormat("MMMM dd, yyyy")}
						</Text>
						<TimeEntriesOptions
							timeEntrySummary={{ date }}
							timeEntries={timeEntries}
							project={project}
						/>
					</View>
					<View className="flex flex-row items-center mt-2">
						<Text className="text-md font-light dark:text-white mr-2">
							<Text className="text-md font-medium dark:text-white">
								{timeEntries.length}
							</Text>{" "}
							Time {pluralize("Entry", timeEntries.length)}
						</Text>

						<Text className="text-md font-light dark:text-white mr-2">
							<Text className="text-md font-medium dark:text-white">
								{billableTimeEntries} / {timeEntries.length}
							</Text>{" "}
							Time {pluralize("Entry", timeEntries.length)} Billable
						</Text>
						<Text className="text-md font-light dark:text-white mr-2">
							<Text className="text-md font-medium dark:text-white">
								{approvedTimeEntries} / {timeEntries.length}
							</Text>{" "}
							Time {pluralize("Entry", timeEntries.length)} Approved
						</Text>
						<Text className="text-md font-light dark:text-white mr-2">
							<Text className="text-md font-medium dark:text-white">
								{missingTimeEntries.length}
							</Text>{" "}
							{pluralize("Users", missingTimeEntries.length)} Missing Time{" "}
							{pluralize("Entry", missingTimeEntries.length)}
						</Text>
					</View>
				</View>
			</Animatable.View>
		);
	};

	const getTimeEntryType = (timeEntry) => {
		if (timeEntry.isMobilizing) {
			return "Mobilizing";
		}
		if (timeEntry.isDemobilizing) {
			return "Demobilizing";
		}
		if (timeEntry.isStandby) {
			return "Standby";
		}
		return "Regular";
	};

	const _renderContent = ({ date, timeEntries, missingTimeEntries }) => {
		return (
			<ScrollView horizontal>
				{timeEntries.length > 0 ? (
					<View className="m-2 p-2 shadow rounded-lg">
						<Table>
							<THead>
								<TR>
									<StyledTH>Worker</StyledTH>
									<StyledTH>Signature</StyledTH>
									<StyledTH>Time In</StyledTH>
									<StyledTH>Time Out</StyledTH>
									<StyledTH>Duration</StyledTH>
									<StyledTH>Type</StyledTH>
									<StyledTH>Billable</StyledTH>
									<StyledTH>Approved</StyledTH>
									<StyledTH>Duplications</StyledTH>
									<StyledTH></StyledTH>
								</TR>
							</THead>
							<TBody>
								{[...timeEntries]
									.sort((a, b) => {
										if (!a.user) return 1;
										if (!b.user) return -1;

										if (a.user.fullName > b.user.fullName) return 1;
										if (a.user.fullName < b.user.fullName) return -1;

										// Sort by date if the full names are the same
										if (a.timeIn > b.timeIn) return 1;
										if (a.timeIn < b.timeIn) return -1;

										return 0;
									})
									.map((timeEntry, index) => {
										return (
											<StyledTR key={`${date}-time-entry-${index}`}>
												<StyledTD className={"mx-2 px-2"}>
													<View>
														<User user={timeEntry.user} />
													</View>
												</StyledTD>

												{timeEntry.dailyDocument ? (
													<>
														{timeEntry.dailyDocument.signature ? (
															<StyledTD className={"mx-2 px-2"}>
																<Image
																	source={{
																		uri: getSignedUrl(
																			timeEntry.dailyDocument.signature
																		),
																	}}
																	style={{
																		width: 200,
																		height: 100,
																		objectFit: "contain",
																	}}
																/>
															</StyledTD>
														) : (
															<StyledTD className={"mx-2 px-2"}>
																{" "}
																No Signature
															</StyledTD>
														)}
													</>
												) : (
													<StyledTD className={"mx-2 px-2"}>
														{" "}
														No Daily Document
													</StyledTD>
												)}
												<StyledTD className={"mx-2 px-2"}>
													{DateTime.fromISO(
														timeEntry.timeInBilling || timeEntry.timeIn
													).toFormat("hh:mm a")}
													{timeEntry.timeInBilling && (
														<p className="text-xm font-thin text-gray-500">
															Billing Time Edit
														</p>
													)}
												</StyledTD>

												{timeEntry.timeOut ? (
													<StyledTD className={"mx-2 px-2"}>
														{DateTime.fromISO(
															timeEntry.timeOutBilling || timeEntry.timeOut
														).toFormat("hh:mm a")}
														{timeEntry.timeInBilling && (
															<p className="text-xm font-thin text-gray-500">
																Billing Time Edit
															</p>
														)}
													</StyledTD>
												) : (
													<StyledTD className={"mx-2 px-2"}>
														Clocked In
													</StyledTD>
												)}

												{timeEntry.timeOut ? (
													<StyledTD className={"mx-2 px-2"}>
														{calculateTimeDuration(
															timeEntry.timeInBilling || timeEntry.timeIn,
															timeEntry.timeOutBilling || timeEntry.timeOut
														)}
													</StyledTD>
												) : (
													<StyledTD className={"mx-2 px-2"}>
														Clocked In
													</StyledTD>
												)}

												<StyledTD className={"mx-2 px-2"}>
													{getTimeEntryType(timeEntry)}
												</StyledTD>

												<StyledTD className={"mx-2 px-2"}>
													<Checkbox
														onChange={(value) => {
															updateTimeEntry({
																variables: {
																	input: {
																		id: timeEntry.id,
																		projectApproved: value,
																		projectApprovedAt: DateTime.local().toISO(),
																		projectApprovedBy: user.id,
																	},
																},
															});
														}}
														checkedValue={timeEntry.projectApproved}
													/>
												</StyledTD>
												<StyledTD className={"mx-2 px-2"}>
													<Checkbox
														onChange={(value) => {
															updateTimeEntry({
																variables: {
																	input: {
																		id: timeEntry.id,
																		approved: value,
																		approvedAt: DateTime.local().toISO(),
																		approvedBy: user.id,
																	},
																},
															});
														}}
														checkedValue={timeEntry.approved}
													/>
												</StyledTD>
												<StyledTD className={"mx-2 px-2"}>
													{timeEntry.possibleDuplications.length} Possible
													Duplications
												</StyledTD>
												<StyledTD className={"mx-2 px-2"}>
													<OptionsMenu
														options={[
															{
																name: "Edit",
																icon: faPen,
																onPress: () => {
																	setCurrentTimeEntry(timeEntry);
																	setNewItem("timeEntry");
																},
															},
															{
																name: "Delete",
																icon: faTrash,
																onPress: () => {
																	deleteTimeEntry({
																		variables: {
																			input: {
																				id: timeEntry.id,
																			},
																		},
																	});
																},
															},
														]}
													/>
												</StyledTD>
											</StyledTR>
										);
									})}
							</TBody>
						</Table>
					</View>
				) : null}

				{missingTimeEntries.length > 0 ? (
					<View className="m-2 p-2 shadow rounded-lg">
						<Text className="font-medium ">Users Missing Time Entries</Text>
						<View className="flex flex-row items-center mt-2">
							{missingTimeEntries.map((user, index) => {
								return (
									<View key={`${date}-missing-user-${index}`} className="mr-2">
										<Badge text={user.fullName} user={user} />
									</View>
								);
							})}
						</View>
					</View>
				) : null}
			</ScrollView>
		);
	};

	return (
		<ScrollView className="flex-1 w-full">
			{/* Find a if there is a time entry for a given date */}

			<Accordion
				sections={project.timeEntriesSummary.filter((summary) => {
					if (billingPhase) {
						return summary.billingPhase === billingPhase.name;
					} else {
						if (!billingPeriod) return true;
						return (
							DateTime.fromISO(summary.date) >=
								DateTime.fromISO(billingPeriod.startDate) &&
							DateTime.fromISO(summary.date) <=
								DateTime.fromISO(billingPeriod.endDate)
						);
					}
				})}
				activeSections={activeSections}
				renderHeader={_renderHeader}
				renderContent={_renderContent}
				onChange={_updateSections}
			/>

			<TimeEntrySlideover
				isOpen={newItem === "timeEntry"}
				currentTimeEntry={currentTimeEntry}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				closeRequest={() => {
					setNewItem(null);
					setCurrentTimeEntry(null);
				}}
				type="update"
				clerking={true}
				currentProject={project}
			/>
		</ScrollView>
	);
};

TimeEntriesSummary.propTypes = {
	timeEntries: PropTypes.array,
	project: PropTypes.object,
	billingPeriod: PropTypes.object,
	billingPhase: PropTypes.object,
	refetch: PropTypes.func,
};
