import { useNavigation } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { ScrollView, View } from "react-native";
import Toast from "react-native-toast-message";
// Containers
import { EquipmentSlideOver } from "../../containers/EquipmentSlideOver";

// Components

import { Alert } from "../../components/Alert";
import DataContainer, { DataItem } from "../../components/DataDisplay";
import { Select } from "../../components/Forms";
import { PageHeader } from "../../components/Header.js";
import { List, ListItem } from "../../components/List.js";
import LoadingScreen from "../../components/LoadingScreen.js";
import Modal from "../../components/Modal";
import { addNotification } from "../../components/Notification";
import OptionsMenu from "../../components/OptionsMenu";
import SlideOver from "../../components/Slideover";
import Tabs, { Tab } from "../../components/Tabs";

// GQL
import { gql, useMutation, useQuery } from "@apollo/client";
import * as equipmentOperations from "../../graphql/equipment";
import * as equipmentGQL from "../../graphql/equipment.js";
import * as userGQL from "../../graphql/user.js";

// Icons
import {
	faTools,
	faCloudArrowDown,
	faEdit,
	faEye,
	faFileAlt,
	faHistory,
	faInfoCircle,
	faPencilAlt,
	faPlus,
	faTrash,
	faUserTag,
} from "@fortawesome/pro-duotone-svg-icons";

// Utils
import { getData, getSignedUrl, SentryLogger } from "../../utils";
import CardContainer, { Card } from "../../components/Card";
import { faChainBroken } from "@fortawesome/pro-thin-svg-icons";

export const Equipment = ({ search }) => {
	// let [userData, setUserData] = React.useState(
	//   JSON.parse(localStorage.getItem("user"))
	// );

	const navigation = useNavigation();
	const [users, setUsers] = React.useState([]);
	const [user, setUser] = React.useState(null);
	const [newHistory, setNewHistory] = React.useState({});
	const [locations, setLocations] = React.useState(null);
	const [equipment, setEquipment] = React.useState([]);
	const [newItem, setNewItem] = React.useState(null);
	const [currentEquipment, setCurrentEquipment] = React.useState({});
	const [newOffice, setNewOffice] = React.useState({});

	React.useEffect(() => {
		const getUser = async () => {
			await getData("@storage_Key").then((user) => setUser(user));
		};
		getUser();
	}, []);

	let newUser = user;

	const { loading, refetch } = useQuery(gql(equipmentGQL.listEquipment), {
		onCompleted: (data) => {
			setEquipment([...data.listEquipment.items]);
		},
		onError: () => {},
		fetchPolicy: "cache-and-network",
	});

	useQuery(gql(equipmentGQL.listLocations), {
		variables: {
			filter: {
				isBranchLocation: true,
			},
		},
		onCompleted: (data) => {
			setLocations([...data.listLocations.items]);
		},
		onError: () => {},
	});

	useQuery(gql(userGQL.listUsers), {
		onCompleted: (data) => {
			setUsers([...data.listUsers.items]);
		},
		onError: () => {},
	});

	const [createEquipmentHistoryOperation] = useMutation(
		gql(equipmentOperations.createEquipmentHistory),
		{
			onCompleted: () => {
				setNewItem(false);

				// if (!newHistory) {
				//   setReload(true);
				//   setNewItem(null);
				//   return;
				// }
			},
			onError: () => {
				addNotification({
					title: "Something Went Wrong",
					text: "No equipment history was created. Please contact support at help.srpinfield.com and supply error code of EH500",
					type: "error",
					supportText: true,
					time: 5000,
				});
			},
		}
	);

	const [deleteEquipment] = useMutation(gql(equipmentGQL.deleteEquipment), {
		onCompleted: () => {
			refetch();
		},
		onError: () => {},
	});

	const navigateToEquipment = (id) => {
		navigation.navigate("AdminSingleEquipment", {
			id: id,
		});
	};

	if (loading || !user) {
		return <LoadingScreen loadItem="Equipment" />;
	}

	return (
		<>
			<PageHeader
				openMenu
				title="Equipment"
				buttons={[
					{
						name: "Add Equipment",
						icon: faPlus,
						onPress: () => setNewItem("equipment"),
					},
				]}
			/>

			<List
				headers={[
					"Name",
					"Barcode",
					"Serial Number",
					"Calibration Expiration ",
					"Location",
					"User",
				]}
				usesOptions
			>
				{equipment &&
					equipment
						.filter((equipment) => {
							return (
								equipment.name.toLowerCase().includes(search) ||
								equipment.barcode.toLowerCase().includes(search) ||
								equipment.sn.toLowerCase().includes(search)
							);
						})
						.sort((a, b) => {
							if (a.barcode > b.barcode) return 1;
							if (a.barcode < b.barcode) return -1;
							return 0;
						})
						.map((eq, index) => {
							return (
								<ListItem
									key={index}
									items={[
										{
											content: eq.name,
											onPress: () => navigateToEquipment(eq.id),
										},
										{
											content: eq.barcode,
											onPress: () => navigateToEquipment(eq.id),
										},
										{
											content: eq.sn,
											onPress: () => navigateToEquipment(eq.id),
										},
										{
											content: eq.expirationDate
												? eq.expirationDate
												: "No Calibration Date",
											onPress: () => navigateToEquipment(eq.id),
										},

										{
											content:
												eq.history.length && eq.history[0].office
													? eq.history[0].office.name
													: "No Location",
											onPress: () => navigateToEquipment(eq.id),
										},
										{
											content:
												eq.history.length && eq.history[0].user
													? `${eq.history[0].user.firstName} ${eq.history[0].user.lastName}`
													: "No User",
											onPress: () => navigateToEquipment(eq.id),
										},
									]}
									options={
										<OptionsMenu
											options={[
												{
													name: "Assignment",
													icon: faUserTag,
													onPress: () => {
														setNewItem("assignEquipment");
														setNewHistory({ equipment: eq });
													},
												},
												{
													name: "Update",
													icon: faPencilAlt,
													onPress: () => {
														setNewItem("update");
														setCurrentEquipment(eq);
														setNewOffice(eq.history.length && eq.history[0]);
													},
												},
												{
													name: "Delete",
													icon: faTrash,
													onPress: () => {
														deleteEquipment({
															variables: {
																input: { id: eq.id },
															},
														});
													},
												},
											]}
										/>
									}
								/>
							);
						})}
			</List>

			<EquipmentSlideOver
				isOpen={newItem === "equipment"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				type="create"
			/>

			<EquipmentSlideOver
				isOpen={newItem === "update"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				type="update"
				currentUser={newUser}
				currentEquipment={currentEquipment}
				currentHistory={newOffice}
			/>

			<SlideOver
				name="Assign Equipment"
				description="Assign an asset to a user"
				isOpen={newItem === "assignEquipment"}
				closeRequest={() => setNewItem(null)}
				onSubmit={async (e) => {
					e.preventDefault();

					// // if currently checked out, update the checkIn date

					// if (
					// 	!newHistory.equipment.history ||
					// 	!newHistory.equipment.history[0].checkIn
					// ) {
					// 	// update checkIn

					await createEquipmentHistoryOperation({
						variables: {
							input: {
								equipment: newHistory.equipment.id,
								user: newHistory.user,
								office: newHistory.location,
								checkOut: DateTime.now().toISO(),
							},
						},
					});
					setNewItem(null);
					refetch();
				}}
			>
				<Select
					name="User"
					options={
						users
							? users
									.filter((user) => user.isTeamMember || user.isContractor)
									.sort((a, b) => {
										if (a.firstName > b.firstName) return 1;
										if (a.firstName < b.firstName) return -1;
										return 0;
									})
									.map((user) => {
										return {
											name: `${user.firstName} ${user.lastName}`,
											value: user.id,
										};
									})
							: []
					}
					onChange={(value) =>
						setNewHistory({ ...newHistory, user: value.value })
					}
				/>

				<Select
					name="Location"
					options={
						locations
							? locations.map((location) => {
									return { name: location.name, value: location.id };
							  })
							: []
					}
					onChange={(value) =>
						setNewHistory({ ...newHistory, location: value.value })
					}
				/>
			</SlideOver>
		</>
	);
};

Equipment.propTypes = {
	search: PropTypes.string,
};

const tabs = [
	{ name: "Information", onPress: "#", icon: faInfoCircle },
	{ name: "History", onPress: "#", icon: faHistory },
	{ name: "Documents", onPress: "#", icon: faFileAlt },
	{ name: "Tools", onPress: "#", icon: faTools },
];

export const SingleEquipment = ({ route }) => {
	const { id } = route.params;
	const navigation = useNavigation();
	let [currentTab, setCurrentTab] = React.useState(tabs[0].name);
	const [equipment, setEquipment] = React.useState(null);
	const [user, setUser] = React.useState(null);
	let [newItem, setNewItem] = React.useState(null);
	const [setFileURL] = React.useState(null);
	const [tools, setTools] = React.useState(null);
	const [newTools, setNewTools] = React.useState(null);
	const [currentTool, setCurrentTool] = React.useState(null);

	React.useEffect(() => {
		const getUser = async () => {
			await getData("@storage_Key").then((user) => setUser(user));
		};
		getUser();
	}, []);
	let newUser = user;

	const { loading: equipmentLoading, refetch } = useQuery(
		gql(equipmentOperations.getEquipment),
		{
			variables: {
				id: id,
			},

			onCompleted: (data) => {
				if (data.getEquipment) {
					setEquipment(data.getEquipment);
				} else {
					navigation.goBack();
					Alert("Equipment Not Found");
				}
			},
			onError: () => {
				navigation.navigate("/admin/equipment");
			},
			fetchPolicy: "cache-and-network",
		}
	);

	useQuery(gql(equipmentGQL.listEquipment), {
		onCompleted: (data) => {
			setTools([...data.listEquipment.items]);
		},

		fetchPolicy: "cache-and-network",
	});

	const [updateEquipment] = useMutation(gql(equipmentGQL.updateEquipment));

	let [deleteEquipmentLink] = useMutation(
		gql(equipmentOperations.deleteEquipmentLink),
		{
			onCompleted: (data) => {
				let params = { Bucket: "srp-in-field", Key: data.deleteEquipment.link };
				s3.deleteObject(params, (err) => {
					let equipmentDeletion;

					if (!err) {
						Alert(
							`Document titled ${equipmentDeletion.data.deleteEquipmentLink.name} Deleted`,
							"Equipment object and data has been deleted"
						);
					}
				});
				reportRefetch();
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert(
					"Uh Oh Something Went Wrong",
					"We could not delete this document. Please try again. If this continues to happen please go to help.srpinfield.com and start a chat with one of our engineers"
				);
			},
		}
	);

	const handleMutationSuccess = (type, action) => {
		Toast.show({
			type: "success",
			text1: `${type} ${action} Successfully `,
		});
		refetch();
	};

	const handleMutationError = (type, action, error) => {
		Toast.show({
			type: "error",
			text1: `Error ${action} ${type}`,
			text2: error.message,
		});
	};

	const downloadAttachment = async (link, viewOnly = false) => {
		try {
			let url = getSignedUrl(link);
			if (viewOnly) {
				return setFileURL(url);
			}
			Linking.openURL(url);
		} catch (err) {
			Alert(
				"There was an error downloading the equipment calibration document. Please try again."
			);
		}
	};

	if (equipmentLoading || !equipment) {
		return <LoadingScreen loadItem="Equipment" />;
	}

	const getButtons = () => {
		switch (currentTab) {
			case "Information":
				return [
					{
						name: "Update Equipment",
						icon: faPencilAlt,
						onPress: () => {
							setNewItem("update");
						},
					},
				];
			case "Tools":
				return [
					{
						name: "Add Tools",
						icon: faPlus,
						onPress: () => setNewItem("newTools"),
					},
					{
						name: "Create and Assign to Kit",
						// pass kitID to currentEquipment
						icon: faPlus,
						onPress: () => setNewItem("newEquipment"),
					},
				];
			default:
				break;
		}
	};

	return (
		<>
			<PageHeader
				title={equipment.name}
				buttons={getButtons()}
				goBack={{
					location: "Equipment",
				}}
			/>
			<Tabs>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							current={currentTab === tab.name ? true : false}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			{currentTab === "Information" && (
				<View className="px-2 pt-2">
					<DataContainer title="Equipment Information">
						<DataItem
							name="Name"
							value={equipment.name ? equipment.name : "No Name"}
						/>
						{equipment.isKit !== true && (
							<DataItem
								name="Additional Components"
								value={
									equipment.additionalComponents || "No Additional Components"
								}
							/>
						)}
						<DataItem
							name="Barcode"
							value={equipment.barcode ? equipment.barcode : "No Barcode"}
						/>
						<DataItem
							name="Serial Number"
							value={equipment.sn ? equipment.sn : "No Serial Number"}
						/>
						<DataItem
							name="Modal"
							value={equipment.model ? equipment.model : "No model"}
						/>
						<DataItem
							name="Location"
							value={
								equipment.history[0]
									? equipment.history[0]?.office?.name
									: "No Office"
							}
						/>
						<DataItem
							name="User"
							value={
								equipment.history[0] && equipment.history[0].user
									? `${equipment.history[0].user?.firstName} ${equipment.history[0].user?.lastName}`
									: "No user"
							}
						/>
						<DataItem
							name="Next Calibration Date"
							value={
								equipment.expirationDate
									? equipment.expirationDate
									: "No Calibration Date"
							}
						/>
					</DataContainer>
					<EquipmentSlideOver
						isOpen={newItem === "update"}
						closeRequest={() => {
							setNewItem(null);
						}}
						onComplete={() => {
							setNewItem(null);
							refetch();
						}}
						type="update"
						currentUser={newUser}
						currentEquipment={equipment}
						currentHistory={equipment.history[0]}
					/>
				</View>
			)}
			{currentTab === "History" && (
				<View className="px-2 pt-2">
					<List headers={["Location", "User", "CheckIn", "CheckOut"]}>
						{equipment &&
							equipment.history.map((history, index) => {
								return (
									<ListItem
										key={index}
										items={[
											{
												content: history.office
													? history.office.name
													: "No Location",
											},
											{
												content: history.user
													? `${history.user.firstName} ${history.user.lastName}`
													: "No User",
											},
											{
												content: history.checkIn,
											},
											{
												content: history.checkOut,
											},
										]}
									/>
								);
							})}
					</List>
				</View>
			)}
			{currentTab === "Documents" && (
				<View className="px-2 pt-2">
					<List headers={["Name", "Date"]}>
						{equipment &&
							equipment.attachments.map((attachment, index) => {
								return (
									<ListItem
										key={index}
										items={[
											{
												content: attachment.name,
											},

											{
												content: attachment.date,
											},
										]}
										options={
											<OptionsMenu
												options={[
													{
														name: "View",
														icon: faEye,
														onPress: () => {
															downloadAttachment(attachment.link, true);
														},
													},

													{
														name: "Download",
														icon: faCloudArrowDown,
														onPress: () => {
															downloadAttachment(attachment.link);
														},
													},

													{
														name: "Delete",
														icon: faTrash,
														onPress: () => {
															deleteEquipmentLink({
																variables: {
																	input: {
																		id: attachment.id,
																	},
																},
															});
														},
													},
												]}
											/>
										}
									/>
								);
							})}
					</List>
				</View>
			)}
			{currentTab === "Tools" && (
				<ScrollView>
					{equipment.isKit !== null ? (
						<CardContainer>
							{equipment &&
								equipment.tools.map((tool, index) => {
									return (
										<Card
											key={index}
											title={tool.name}
											subheading={tool.barcode}
											// content={DateTime.fromISO(
											// 	equipment.date
											// ).toLocaleString()}
											options={
												<View
													style={{
														flexDirection: "row",
														justifyContent: "flex-end",
														alignItems: "center",
													}}
												>
													<OptionsMenu
														options={[
															{
																name: "Edit",
																icon: faEdit,
																onPress: () => {
																	setNewItem("updateTool");
																	setCurrentTool(tool);
																},
															},
															{
																name: "Remove from Kit",
																icon: faChainBroken,
																onPress: () => {
																	updateEquipment({
																		variables: {
																			input: {
																				id: tool.id,
																				kit: null,
																			},
																		},
																	})
																		.then(() =>
																			handleMutationSuccess(
																				"Equipment",
																				"Detached"
																			)
																		)
																		.catch((error) =>
																			handleMutationError(
																				"Equipment",
																				"Detaching",
																				error
																			)
																		);
																},
															},
														]}
													/>
												</View>
											}
										/>
									);
								})}
						</CardContainer>
					) : (
						<Card
							title="No Tools"
							subheading="There are currenly equipment in this kit."
						/>
					)}

					<EquipmentSlideOver
						isOpen={newItem === "newEquipment"}
						closeRequest={() => {
							setNewItem(null);
						}}
						onComplete={() => {
							setNewItem(null);
						}}
						currentEquipment={{
							kit: equipment.id,
						}}
						type="create"
						nonKit={true}
					/>
					<EquipmentSlideOver
						isOpen={newItem === "updateTool"}
						closeRequest={() => {
							setNewItem(null);
						}}
						onComplete={() => {
							setNewItem(null);
							refetch();
						}}
						currentEquipment={currentTool}
						type="update"
					/>
				</ScrollView>
			)}

			<Modal
				//Selects tools from equipment that are not a kit
				title={"Tools"}
				isOpen={newItem === "newTools"}
				rightButton={{
					text: "Add",
					onPress: () => {
						updateEquipment({
							variables: {
								input: {
									id: newTools,
									kit: equipment.id,
									isKit: false,
								},
							},
						})
							.then(() => handleMutationSuccess("Equipment", "Attached"))
							.catch((error) =>
								handleMutationError("Equipment", "Attaching", error)
							);
					},
				}}
				closeRequest={() => setNewItem(null)}
			>
				<View>
					<Select
						name="Select a Tool"
						options={
							tools
								? tools
										.filter(
											(tool) => tool.isKit === null || tool.isKit === false
										)
										.map((tool) => {
											return {
												name: `${tool.name} (${tool.barcode})`,
												value: tool.id,
											};
										})
								: []
						}
						onChange={(value) => setNewTools(value.value)}
					/>
				</View>
			</Modal>
		</>
	);
};

SingleEquipment.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
	route: PropTypes.object,
};

export default Equipment;
