// Create a slide over container for creating and updating PTOAdditions for a user. Look at PTO Slide over for an example however only one additon will be made at a tome

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Toast from "react-native-toast-message";

// Components
import SlideOver from "../components/Slideover.js";
import { Input, Select } from "../components/Forms.js";

// GraphQL
import { gql, useMutation } from "@apollo/client";

export const PTOAdditionSlideOver = ({
	open,
	closeRequest,
	user,
	currentAddition,
	type,
}) => {
	const [addition, setAddition] = useState({});
	// const [additionType, setAdditionType] = useState("PTO");

	useEffect(() => {
		if (currentAddition) {
			setAddition(currentAddition);
		} else {
			setAddition({});
		}
	}, [currentAddition]);

	const change = (name, value) => {
		setAddition({ ...addition, [name]: value });
	};

	// const handleAdditionTypeChange = (type) => {
	// 	setAdditionType(type);
	// };

	// gql call to create or update addition
	const CREATE_PTO_ADDITION = gql`
		mutation CreatePTOAddition($input: CreatePTOAdditionInput!) {
			createPTOAddition(input: $input) {
				id
			}
		}
	`;

	const UPDATE_PTO_ADDITION = gql`
		mutation UpdatePTOAddition($input: UpdatePTOAdditionInput!) {
			updatePTOAddition(input: $input) {
				id
			}
		}
	`;

	// gql call to create or update addition

	const [createPTOAddition] = useMutation(CREATE_PTO_ADDITION);
	const [updatePTOAddition] = useMutation(UPDATE_PTO_ADDITION);

	// handle success and failure of gql call

	const handleSuccess = (action) => {
		Toast.show({
			text1: `PTO Addition ${action}d`,
			type: "success",
			duration: 3000,
		});
		return closeRequest();
	};

	const handleFailure = (action, error) => {
		Toast.show({
			text1: `PTO Addition ${action} failed`,
			text2: error.message,
			type: "error",
		});
	};

	return (
		<SlideOver
			name={type === "create" ? "Create PTO Addition" : "Update PTO Addition"}
			isOpen={open}
			closeRequest={closeRequest}
			onSubmit={() => {
				if (type === "create") {
					createPTOAddition({
						variables: {
							input: {
								user: user.id,
								amount: addition.amount.toString(),
								date: new Date().toISOString(),
								type: addition.type,
							},
						},
					})
						.then(handleSuccess("create"))
						.catch((error) => handleFailure("create", error));
				} else {
					updatePTOAddition({
						variables: {
							input: {
								id: addition.id,
								amount: addition.amount.toString(),
								user: user.id,
								type: addition.type,
							},
						},
					})
						.then(handleSuccess("update"))
						.catch((error) => handleFailure("update", error));
				}
			}}
		>
			<Select
				name="type"
				value={addition.type}
				onChange={(e) => change("type", e.value)}
				options={[
					{ name: "PTO", value: "PTO" },
					{ name: "Sick", value: "Sick" },
				]}
			/>
			<Input
				label="Amount"
				name="amount"
				value={addition.amount}
				onChange={(e) => change("amount", e)}
			/>
		</SlideOver>
	);
};

PTOAdditionSlideOver.propTypes = {
	open: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	currentAddition: PropTypes.object,
	type: PropTypes.string.isRequired,
};
