/* This example requires Tailwind CSS v2.0+ */

import PropTypes from "prop-types";
import React from "react";
import { Pressable, ScrollView, Text, View } from "react-native";

export default function DataContainer({
	title,
	description,
	className,
	children,
}) {
	return (
		<>
			<ScrollView>
				<View
					className={`bg-white dark:bg-gray-900 shadow overflow-hidden sm:rounded-lg ${
						className && className
					}`}
				>
					<View className="px-4">
						{title && (
							<Text className="pt-2 text-lg leading-6 font-medium text-gray-900 dark:text-white">
								{title}
							</Text>
						)}
						{description && (
							<Text className=" max-w-2xl text-sm text-gray-500">
								{description}
							</Text>
						)}
					</View>
					<View className=" px-4 py-5 sm:p-0">
						<View className="sm:divide-y sm:divide-gray-200">{children}</View>
					</View>
				</View>
			</ScrollView>
		</>
	);
}

DataContainer.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export const DataItem = ({ name, value, onPress = () => {}, info }) => {
	let getValue = () => {
		if (typeof value === "string" || typeof value === "number") {
			return (
				<Text className="mt-1 text-sm text-gray-900 dark:text-white  col-span-2">
					{value}
				</Text>
			);
		} else {
			return <View>{value}</View>;
		}
	};
	return (
		<Pressable onPress={onPress}>
			<View className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
				<Text testID="name-text" className="text-sm font-medium text-gray-500">
					{name}
				</Text>
				{getValue()}
				{info && (
					<Text className="mt-1 text-sm text-gray-500  col-span-2">{info}</Text>
				)}
			</View>
		</Pressable>
	);
};

DataItem.propTypes = {
	name: PropTypes.string,
	value: PropTypes.any,
	className: PropTypes.string,
	onPress: PropTypes.func,
	info: PropTypes.string,
};
