export const listExpenses = /* GraphQL */ `
	query ListExpenses(
		$filter: TableExpenseFilterInput
		$limit: Int
		$nextToken: String
	) {
		listExpenses(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				amount
				expenseType {
					id
					name
				}
				billingPhase {
					id
					name
					project {
						id
						title
					}
				}
				receipt
			}
			total
		}
	}
`;

export const bulkCreateExpenses = /* GraphQL */ `
	mutation BulkCreateExpenses($input: [CreateExpenseInput!]!) {
		bulkCreateExpenses(input: $input) {
			id
			project {
				id
				title
			}
			date
			amount
			user {
				id
				firstName
				lastName
			}
			expenseType {
				name
				id
			}
			billingPhase {
				id
				name
				project {
					id
					title
				}
			}
			receipt
		}
	}
`;

export const createExpense = /* GraphQL */ `
	mutation CreateExpense($input: CreateExpenseInput!) {
		createExpense(input: $input) {
			id
			project {
				id
				title
			}
			date
			amount
			user {
				id
				firstName
				lastName
			}
			expenseType {
				name
				id
			}
			billingPhase {
				id
				name
				project {
					id
					title
				}
			}
			receipt
		}
	}
`;

export const updateExpense = /* GraphQL */ `
	mutation UpdateExpense($input: UpdateExpenseInput!) {
		updateExpense(input: $input) {
			id
			project {
				id
				title
			}
			date
			amount
			user {
				id
				firstName
				lastName
			}
			expenseType {
				name
				id
			}
			billingPhase {
				id
				name
				project {
					id
					title
				}
			}
			receipt
		}
	}
`;

export const deleteExpense = /* GraphQL */ `
	mutation DeleteExpense($input: DeleteExpenseInput!) {
		deleteExpense(input: $input) {
			id
			project {
				id
				title
			}
			date
			amount
			user {
				id
				firstName
				lastName
			}
			expenseType {
				name
				id
			}
			billingPhase {
				id
				name
				project {
					id
					title
				}
			}
			receipt
		}
	}
`;
