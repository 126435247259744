import React from "react";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";

// Components
import { Alert } from "../../../components/Alert";
import LoadingScreen from "../../../components/LoadingScreen";
import User from "../../../components/User";
import { PageHeader } from "../../../components/Header";
import DataContainer, { DataItem } from "../../../components/DataDisplay";
import SlideOver from "../../../components/Slideover";
import { Input, TextArea } from "../../../components/Forms";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";

// GQL
import { gql, useMutation, useQuery } from "@apollo/client";
import { getNote, updateNote, deleteNote } from "../../../graphql/project";

// Icons
import { faTrash, faEdit } from "@fortawesome/pro-duotone-svg-icons";

// Utils
import { getData, SentryLogger } from "../../../utils";

const SingleNote = ({ route }) => {
	const { id } = route.params;
	const navigation = useNavigation();
	const [user, setUser] = React.useState({});
	const [note, setNote] = React.useState();
	const [isEditing, setIsEditing] = React.useState(false);
	const [updatedNote, setUpdatedNote] = React.useState(null);

	React.useEffect(() => {
		const userInfo = getData("@storage_Key")
			.then((user) => {
				setUser(user);
			})

			.catch((err) => {
				SentryLogger(err);
			});
		userInfo;
	}, []);

	useQuery(gql(getNote), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setNote(data.getNote);
			setUpdatedNote({
				id: data.getNote.id,
				title: data.getNote.title,
				description: data.getNote.description,
			});
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("Note does not exist");
			navigation.goBack();
		},
	});

	const [editNote] = useMutation(gql(updateNote), {
		onCompleted: (data) => {
			setNote(data.updateNote);
			setIsEditing(false);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("Couldn't update your note", "Please try again");
		},
	});

	const [deleteNoteOperation] = useMutation(gql(deleteNote), {
		onCompleted: () => {
			navigation.goBack();
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert(
				"Note Was Not Deleted Successfully",
				"The note was not deleted successfully. Please try again. If the error persist please visit help.srpinfield.com for support"
			);
		},
	});

	if (!note) {
		return <LoadingScreen loadItem={"Note"} />;
	}

	return (
		<>
			<Breadcrumb className="mt-2">
				<>
					{note.project && note.project.workspace && (
						<Crumb
							name={note.project && note.project.workspace.title}
							location="Project"
							params={{ id: note.project && note.project.workspace.id }}
						/>
					)}
				</>

				<Crumb
					name={note.project && note.project.title}
					location="Project"
					params={{ id: note.project && note.project.id }}
				/>
				<Crumb
					name={note.project && note.title}
					location="SingleNote"
					params={{ id: note.id }}
				/>
			</Breadcrumb>
			<PageHeader
				title={note.title}
				buttons={
					user.id === note.user.id
						? [
								{
									name: "Edit",
									icon: faEdit,
									onPress: () => {
										setIsEditing(true);
									},
								},
								{
									name: "Delete",
									icon: faTrash,
									onPress: () => {
										deleteNoteOperation({
											variables: {
												input: { id: note.id },
											},
										});
									},
								},
						  ]
						: []
				}
				goBack={{
					location: "Project",
					params: {
						id: note.project && note.project.id,
					},
				}}
			/>

			<DataContainer description={DateTime.fromISO(note.date).toFormat("fff")}>
				<DataItem name="Description" value={note.description} />
				<DataItem name="Created By" value={<User user={note.user} />} />
			</DataContainer>

			<SlideOver
				name="Edit Note"
				isOpen={isEditing === true}
				closeRequest={() => setIsEditing(false)}
				user={user}
				onSubmit={() => {
					editNote({
						variables: {
							input: {
								id: note.id,
								...updatedNote,
							},
						},
					});
				}}
				buttonRight={"Update"}
			>
				<Input
					label="Title"
					type="text"
					value={updatedNote && updatedNote.title}
					onChange={(e) => {
						setUpdatedNote({
							...updatedNote,
							title: e,
						});
					}}
				/>

				<TextArea
					label="Description"
					value={updatedNote && updatedNote.description}
					onChange={(e) => {
						setUpdatedNote({
							...updatedNote,
							description: e,
						});
					}}
					placeholder="Enter note details"
					rows="10"
				>
					{updatedNote && updatedNote.description}
				</TextArea>
			</SlideOver>
		</>
	);
};

SingleNote.propTypes = {
	history: PropTypes.shape({
		goBack: PropTypes.func.isRequired,
		push: PropTypes.func.isRequired,
	}),
	route: PropTypes.object,
};
export default SingleNote;
