export const createPTO = /* GraphQL */ `
	mutation CreatePTO($input: CreatePTOInput!) {
		createPTO(input: $input) {
			id
			user {
				id
				isSalary
				manager {
					id
				}
			}
			approved
			bereavementPerson
			approvedAt
			approvedBy {
				id
			}
		}
	}
`;

export const updatePTO = /* GraphQL */ `
	mutation UpdatePTO($input: UpdatePTOInput!) {
		updatePTO(input: $input) {
			id
			user {
				id
				isSalary
				manager {
					id
				}
			}
			approved
			bereavementPerson
			approvedAt
			approvedBy {
				id
			}
		}
	}
`;

export const listPTO = /* GraphQL */ `
	query ListPTOs(
		$filter: TablePTOFilterInput
		$limit: Int
		$nextToken: String
	) {
		listPTOs(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				hours
				user {
					id
					isSalary
				}
				reason
				startDate
				endDate
				bereavementPerson
				approved
				approvedAt
				approvedBy {
					id
				}
			}
			total
		}
	}
`;

export const getPTO = /* GraphQL */ `
	query GetPTO($id: ID!) {
		getPTO(id: $id) {
			id
			hours
			reason
			startDate
			endDate
			approved
			approvedAt
			approvedBy {
				id
			}
			bereavementPerson
		}
	}
`;

// export const updatePTO = /* GraphQL */ `
// 	mutation UpdatePTO($input: UpdatePTOInput!) {
// 		updatePTO(input: $input) {
// 			id
// 			hours
// 			approved
// 			approvedAt
// 			approvedBy {
// 				id
// 			}
// 		}
// 	}
// `;

export const deletePTO = /* GraphQL */ `
	mutation DeletePTO($input: DeletePTOInput!) {
		deletePTO(input: $input) {
			id
			user {
				id
				manager {
					id
				}
			}
		}
	}
`;
