import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";
//Util

import { calculateTimeDuration, SentryLogger } from "../../utils";

// Components
import { Alert } from "../../components/Alert";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import DailySheetView from "../../components/DailySheetView";
import DataContainer, { DataItem } from "../../components/DataDisplay";
import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List.js";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
import OptionsMenu from "../../components/OptionsMenu";
import Tabs, { Tab } from "../../components/Tabs";
import User from "../../components/User";

// GQL

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import * as projectOperations from "../../graphql/admin/projects";
import * as userOperations from "../../graphql/admin/users";
import * as timeEntryGQL from "../../graphql/timeEntry";

// Icons
import {
	faFileAlt,
	faHistory,
	faInfoCircle,
} from "@fortawesome/pro-duotone-svg-icons";

const tabs = [
	{ name: "Basic Information", href: "#", icon: faInfoCircle },
	{ name: "Time Entries", href: "#", icon: faHistory },
	{ name: "Projects", href: "#", icon: faFileAlt },
];

export default function SubAdminUser({ route }) {
	const id = route.params.id;
	const navigation = useNavigation();
	const [user, setUser] = React.useState(null);
	const [projectData, setProjectData] = useState([]);
	const [timeEntries, setTimeEntries] = React.useState([]);
	const [viewTimeEntry, setViewTimeEntry] = React.useState(false);
	const [currentTab, setCurrentTab] = React.useState(tabs[0].name);

	React.useEffect(() => {
		if (id) {
			getUser({
				variables: {
					recallValue: DateTime.now().toISO(),
					id: id,
				},
			})
				.then(handleSuccess)
				.catch(handleError);
		}
	}, [id]);

	const [getUser] = useLazyQuery(gql(userOperations.getUser), {
		variables: {
			id: id,
		},
		fetchPolicy: "cache-and-network",
	});

	const handleSuccess = (data) => {
		setUser(data.getUser);
	};

	const handleError = (error) => {
		SentryLogger(error);
	};

	const { loading: projectsLoading } = useQuery(
		gql(projectOperations.listProjects),
		{
			onCompleted: () => {},
			onError: (error) => {
				SentryLogger(error);
			},
			fetchPolicy: "cache-and-network",
		}
	);

	//list time entries

	useQuery(gql(timeEntryGQL.getUserProjectsAndTime), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setTimeEntries([...data.getUser.timeEntries]);
			setProjectData([...data.getUser.projects]);
		},
		onError: (error) => {
			Alert("error", "Could not retrieve user");
			SentryLogger(error);
		},
		fetchPolicy: "cache-and-network",
	});

	const navigateToProject = (id) => {
		navigation.navigate("Subcontractor Project", { id: id });
	};

	if (!user) {
		return <LoadingScreen loadItem={"User"} />;
	}
	if (projectsLoading) {
		return <LoadingScreen loadItem={"Projects"} />;
	}

	return (
		<>
			<Breadcrumb className="mt-2">
				<Crumb
					name={user.subcontractor.name}
					// href={"/subAdmin/users"}
					location="Subcontractor Users"
				/>
				<Crumb
					name={`${user.firstName} ${user.lastName}`}
					location="Subcontractor User"
					params={{ id: user.subcontractor.id }}
				/>
			</Breadcrumb>
			<PageHeader
				goBack={{
					location: "Subcontractor Users",
				}}
				title={`${user.firstName} ${user.lastName}`}
				description={user.role ? user.role : "No Role"}
			/>
			<Tabs>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							current={currentTab === tab.name}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			<>
				{currentTab === "Basic Information" && (
					<DataContainer title={"Basic Information"}>
						<DataItem
							name="Name"
							value={`${user.firstName} ${user.lastName}`}
						/>
						<DataItem name="Email" value={user.email} />
						<DataItem name="Phone" value={user.phone} />
						<DataItem name="SageIntact" value={user.sageIntacct} />
						<>
							{user.isTeamMember && (
								<>
									<DataItem name="APS Employee ID" value={user.apsEmployeeID} />
									<DataItem
										name="Manager"
										value={
											user.manager ? <User user={user.manager} /> : "No Manager"
										}
									/>
									<DataItem
										name="Office Location"
										value={`${
											user.location ? user.location.name : "No Location"
										}`}
									/>
									<DataItem name="Bio" value={user.bio} />
								</>
							)}
						</>

						<>
							{user.client && (
								<DataItem
									name="Client"
									value={user.client.name ? user.client.name : "No Client Name"}
								/>
							)}
						</>
						<>
							{user.isContractor && (
								<DataItem
									name="Subcontractor Company"
									value={
										user.subcontractor
											? user.subcontractor.name
											: "Not Assigned To A Subcontractor"
									}
								/>
							)}
						</>
						<DataItem
							name="Account Setup"
							value={user.password ? "Yes" : "No"}
						/>
						<DataItem
							name="2FA Enabled"
							value={user.mfaEnabled ? "Yes" : "No"}
						/>
						<DataItem name="UUID" value={user.id} />
					</DataContainer>
				)}
			</>
			<>
				{currentTab === "Time Entries" && (
					<View className="px-2 pt-2">
						<List
							headers={[
								"Time In",
								"Time Out",
								"Duration",
								"Project",
								"Updated By",
								"Description",
							]}
							usesOptions
						>
							{timeEntries
								.sort((a, b) => {
									if (a.timeIn > b.timeIn) return -1;
									if (a.timeIn < b.timeIn) return 1;
									return 0;
								})
								.map((timeEntry, index) => {
									return (
										<ListItem
											key={index}
											options={
												<OptionsMenu
													options={[
														{
															name: "View",
															onPress: () => {
																setViewTimeEntry(timeEntry);
															},
														},
													]}
												/>
											}
											items={[
												{
													content: DateTime.fromISO(timeEntry.timeIn).toFormat(
														"EEEE, MMMM dd, yyyy h:mm a ZZZZ"
													),
												},

												{
													content: timeEntry.timeOut
														? DateTime.fromISO(timeEntry.timeOut).toFormat(
																"EEEE, MMMM dd, yyyy h:mm a ZZZZ"
														  )
														: "Clocked In",
												},
												{
													content: timeEntry.timeOut
														? calculateTimeDuration(
																timeEntry.timeIn,
																timeEntry.timeOut
														  )
														: "Clocked In",
												},
												{
													content: timeEntry.project
														? timeEntry.project.title
														: "",
												},
												{
													content: timeEntry.updatedBy
														? `${timeEntry.updatedBy.firstName} ${timeEntry.updatedBy.lastName}`
														: "",
												},
												{
													content: timeEntry.dailyDocument
														? timeEntry.dailyDocument.activities
															? timeEntry.dailyDocument.activities
															: "No Description"
														: "No Daily Sheet",
												},
											]}
										/>
									);
								})}
						</List>
						<Modal
							isOpen={viewTimeEntry !== false}
							onCloseRequest={() => {
								setViewTimeEntry(false);
							}}
							title="Time Entry and Daily Sheet"
						>
							<DailySheetView timeEntry={viewTimeEntry} />
						</Modal>
					</View>
				)}
			</>
			<>
				{currentTab === "Projects" && (
					<List
						headers={["Title", "Sage Intacct Number", "Stage", "Start Date"]}
						usesOptions
					>
						{projectData &&
							projectData

								// .filter((_project) => {
								// 	return _project.title.toLowerCase().includes(search);
								// })

								.map((project, index) => {
									return (
										<ListItem
											key={index}
											items={[
												{
													content: project.project.title
														? project.project.title
														: "No Project Title",

													onPress: () => navigateToProject(project.id),
												},
												{
													content: project.project.sageIntacct
														? project.project.sageIntacct
														: "No Project Sage Intacct",
													onPress: () => navigateToProject(project.id),
												},
												{
													content: project.project.stage
														? project.project.stage
														: "No Project Stage",
													onPress: () => navigateToProject(project.id),
												},
												{
													content: project.project.startDate
														? project.project.startDate
														: "No Project StartDate",
													onPress: () => navigateToProject(project.id),
												},
											]}
										/>
									);
								})}
					</List>
				)}
			</>
		</>
	);
}
SubAdminUser.propTypes = {
	route: PropTypes.object,
};
