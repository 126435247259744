import { useNavigation } from "@react-navigation/native";
import React, { useState } from "react";

// Components

import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List";
import NoItems from "../../components/NoItems";
import OptionsMenu from "../../components/OptionsMenu";
import Pagination from "../../components/Pagination";

// Containers

import ProjectRoleContainer from "../../containers/ProjectRole";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import { View } from "react-native";
import * as projectRoleGQL from "../../graphql/admin/projectRole";

// Icon

import { faEdit, faPlus, faTrash } from "@fortawesome/pro-duotone-svg-icons";

export function ProjectRolesScreen({}) {
	const navigation = useNavigation();
	const [newItem, setNewItem] = useState(null);
	const [projectRoles, setProjectRoles] = useState(null);
	const [editProjectRole, setEditProjectRole] = useState({});
	const itemsPerPage = 20;
	let [currentPage, setCurrentPage] = useState(1);

	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end = projectRoles
		? currentPage * itemsPerPage > projectRoles.length
		: currentPage * itemsPerPage;

	const { refetch: projectRolesRefetch } = useQuery(
		gql(projectRoleGQL.listProjectRoles),
		{
			variables: {
				limit: 100,
			},
			onCompleted: (data) => {
				setProjectRoles([...data.listProjectRoles.items]);
			},
		}
	);

	const [deleteProjectRole] = useMutation(
		gql(projectRoleGQL.deleteProjectRole),
		{
			onCompleted: () => {
				projectRolesRefetch();
			},
		}
	);

	const navigateToProjectRole = (id) => {
		navigation.navigate("ProjectRoles", {
			id: id,
		});
	};

	return (
		<View className="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-white dark:bg-gray-900">
			{/* Page title & actions */}
			<PageHeader
				openMenu
				title="Project Roles"
				buttons={[
					{
						name: "New Project Role",
						icon: faPlus,
						onPress: () => {
							setNewItem("newProjectRole");
						},
					},
				]}
			/>

			{/* List Project Roles */}

			{projectRoles && projectRoles.length ? (
				<>
					<List
						name={"Project Roles"}
						headers={["Role", "Sage Item", "Sage Nonstandard Item", "Active"]}
					>
						{projectRoles &&
							projectRoles
								.sort((a, b) => {
									if (a.name > b.name) return 1;
									if (a.name < b.name) return -1;
									return 0;
								})
								.map((projectRole) => {
									return (
										<ListItem
											key={projectRole.id}
											usesOptions
											items={[
												{
													onPress: () => navigateToProjectRole(projectRole.id),
													content: projectRole.name,
												},
												{
													onPress: () => navigateToProjectRole(projectRole.id),
													content: projectRole.sageItem,
												},
												{
													onPress: () => navigateToProjectRole(projectRole.id),
													content: projectRole.sageOvertimeItem,
												},
												{
													onPress: () => navigateToProjectRole(projectRole.id),
													content: projectRole.active ? "Yes" : "No",
												},
											]}
											options={
												<OptionsMenu
													options={[
														{
															name: "Edit",
															icon: faEdit,

															onPress: () => {
																setEditProjectRole(projectRole);
																setNewItem("editProjectRole");
															},
														},
														{
															name: "Delete",
															icon: faTrash,
															onPress: () => {
																deleteProjectRole({
																	variables: {
																		input: { id: projectRole.id },
																	},
																});
															},
														},
													]}
												/>
											}
										/>
									);
								})}
					</List>

					<Pagination
						start={start}
						stop={end}
						total={projectRoles.length}
						nextClicked={() => setCurrentPage(currentPage + 1)}
						previousClicked={() => setCurrentPage(currentPage - 1)}
					/>
				</>
			) : (
				<NoItems
					surTitle={"No Items"}
					title="No Project Roles"
					text="You can create a project role by tapping New Project Role above"
				/>
			)}

			{/* New Project Role */}
			<ProjectRoleContainer
				isOpen={newItem === "newProjectRole"}
				onComplete={() => {
					setNewItem(null);
					projectRolesRefetch();
				}}
				closeRequest={() => {
					setNewItem(null);
				}}
				type="create"
				currentRole={{}}
			/>

			{/* Edit Project Role */}
			<ProjectRoleContainer
				isOpen={newItem === "editProjectRole"}
				onComplete={() => {
					setNewItem(null);
					setEditProjectRole({});
					projectRolesRefetch();
				}}
				closeRequest={() => {
					setNewItem(null);
				}}
				type="update"
				currentRole={{
					id: editProjectRole.id,
					name: editProjectRole.name,
					sageItem: editProjectRole.sageItem,
					sageOvertimeItem: editProjectRole.sageOvertimeItem,
				}}
			/>
		</View>
	);
}
