import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";

// Components
import LoadingScreen from "../../components/LoadingScreen";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import { PageHeader } from "../../components/Header";
import Tabs, { Tab } from "../../components/Tabs";
import DataContainer, { DataItem } from "../../components/DataDisplay";
import { List, ListItem } from "../../components/List";
import NoItems from "../../components/NoItems";

// GQL
import { gql, useLazyQuery } from "@apollo/client";
import * as userGQL from "../../graphql/user";

// Icons
import {
	faCertificate,
	faGears,
	faInfoCircle,
} from "@fortawesome/pro-duotone-svg-icons";

// Utils
import { getData, SentryLogger } from "../../utils";

const tabs = [
	{ name: "Information", href: "#", icon: faInfoCircle },
	{ name: "Certifications", href: "#", icon: faCertificate },
];

export const Profile = () => {
	const navigation = useNavigation();
	const [user, setUser] = React.useState(null);
	const [currentTab, setCurrentTab] = useState(tabs[0].name);

	React.useState(() => {
		const getUserInfo = async () => {
			await getData("@storage_Key")
				.then((res) => {
					getUser({
						variables: {
							id: res.id,
						},
					});
				})
				.catch((err) => {
					SentryLogger(err);
				});
		};
		getUserInfo();
	}, []);

	// Mutations and Queries

	const [getUser] = useLazyQuery(gql(userGQL.getProfileInfo), {
		onCompleted: (data) => {
			setUser(data.getUser);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	if (!user) {
		return <LoadingScreen loadItem="Profile" />;
	}
	return (
		<>
			<Breadcrumb>
				<Crumb name="Profile" location="Profile" />
			</Breadcrumb>
			<PageHeader
				goBack={{
					location: "Projects",
				}}
				title="Profile"
				buttons={[
					{
						name: "Settings",
						icon: faGears,
						onPress: () => {
							navigation.navigate("Settings");
						},
					},
				]}
			/>
			<Tabs currentValue={currentTab}>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							current={currentTab === tab.name ? true : false}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			{currentTab === "Information" && (
				<DataContainer>
					<DataItem name="Name" value={`${user.firstName} ${user.lastName}`} />
					<DataItem name="Phone" value={user.phone} />
					<DataItem name="Email" value={user.email} />
					<DataItem name="Bio" value={user.bio} />
					<DataItem name="SageIntacct" value={user.sageIntacct} />
				</DataContainer>
			)}
			{currentTab === "Certifications" && (
				<>
					{user.certifications && user.certifications.length ? (
						<List headers={["Certifications"]}>
							{user.certifications.map((certification, index) => {
								return (
									<ListItem
										key={index}
										items={[
											{
												href: `/app/project/certifications/${certification.id}/${certification.id}`,
												content: `${certification.name}`,
											},
										]}
									/>
								);
							})}
						</List>
					) : (
						<NoItems
							surTitle="No Certifications"
							title="Currently No Certifications"
						/>
					)}
				</>
			)}
		</>
	);
};
