export const listBillingPhases = /* GraphQL */ `
	query ListBillingPhases(
		$filter: TableBillingPhaseFilterInput
		$limit: Int
		$nextToken: String
	) {
		listBillingPhases(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				project {
					id
				}
			}
		}
	}
`;

export const createBillingPhase = /* GraphQL */ `
	mutation CreateBillingPhase($input: CreateBillingPhaseInput!) {
		createBillingPhase(input: $input) {
			id
			name
		}
	}
`;

export const updateBillingPhase = /* GraphQL */ `
	mutation UpdateBillingPhase($input: UpdateBillingPhaseInput!) {
		updateBillingPhase(input: $input) {
			id
			name
		}
	}
`;

export const deleteBillingPhase = /* GraphQL */ `
	mutation DeleteBillingPhase($input: DeleteBillingPhaseInput!) {
		deleteBillingPhase(input: $input) {
			id
		}
	}
`;

export const getBillingPhase = /* GraphQL */ `
	query GetBillingPhase($id: ID!) {
		getBillingPhase(id: $id) {
			id
			name
			project {
				id
				title
			}
			timeEntries {
				id
				timeIn
				timeOut
			}
			expenses {
				id
				date
				amount
				receipt
				expenseType {
					name
					category {
						name
					}
				}
				description
				user {
					fullName
				}
			}
			billing {
				laborDates {
					date
					total
					items {
						user {
							id
							firstName
							lastName
						}
						projectRole {
							id
							name
						}
						timeEntries {
							id
							timeIn
							timeOut
							approved
							approvedBy {
								id
								firstName
								lastName
							}
							approvedAt
							timeInBilling
							timeOutBilling
						}
						lunchDeduction
						regularHours
						otHours
						premHours
						totalHours
						regularRate
						otRate
						premRate
						mobilizationDemobRate
						mobilizationDemobHours
						standbyRate
						standbyHours
						totalCharge
					}
				}
				equipment {
					subTotal
					markup
					total
				}
				subsistence {
					subTotal
					markup
					total
				}
				rentedItems {
					subTotal
					markup
					total
				}
				purchasedSAOE {
					subTotal
					markup
					total
				}
				labAndSub {
					subTotal
					markup
					total
				}
				shipping {
					subTotal
					markup
					total
				}
				total
				totalLabor
			}
		}
	}
`;
