import { useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import React from "react";

// components
import { Alert } from "../../../components/Alert";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import { Input, Switch } from "../../../components/Forms";
import { PageHeader } from "../../../components/Header";
import { List, ListItem } from "../../../components/List";
import LoadingScreen from "../../../components/LoadingScreen.js";
import NoItems from "../../../components/NoItems";
import OptionsMenu from "../../../components/OptionsMenu";
import SlideOver from "../../../components/Slideover";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as projectGQL from "../../../graphql/project";

// Utils
import { getData, SentryLogger } from "../../../utils";

// Icons
import {
	faBriefcase,
	faMapPin,
	faPencil,
	faPlus,
} from "@fortawesome/pro-duotone-svg-icons";

// copy over getCurrentUser

export default function DryStandard({ route }) {
	const { id } = route.params;
	const navigation = useNavigation();
	const [project, setProject] = React.useState(null);
	const [user, setUser] = React.useState(null);
	const [projectUser, setProjectUser] = React.useState({});

	const [newItem, setNewItem] = React.useState(null);
	const [newDryStandard, setNewDryStandard] = React.useState({});

	React.useEffect(() => {
		const userInfo = async () => {
			await getData("@storage_Key")
				.then((user) => {
					setUser(user);
				})

				.catch((err) => {
					SentryLogger(err);
				});
		};

		userInfo();
	}, []);

	let { loading } = useQuery(gql(projectGQL.getProjectDryStandards), {
		variables: {
			id: id,
		},
		fetchPolicy: "cache-and-network",
		onCompleted: (data) => {
			setProject(data.getProject);
			const getCurrentUser = async (data) => {
				const userData = await getData("@storage_Key").then((user) => user);
				let team;
				if (data && data.workspace) {
					team = data.workspace.team;
				} else {
					team = data.team;
				}
				let projectUser = team.find(
					(tm) => tm.teamMember && tm.teamMember.id === userData.id
				);
				setProjectUser(projectUser);

				return projectUser;
			};

			//
			getCurrentUser(data.getProject);
		},
		onError: (error) => {
			Alert(
				"We cannot find this project in our system. Make sure you have the right url."
			);
			SentryLogger(JSON.stringify(error, null, 2));
			navigation.goBack();
		},
	});

	const [createDryStandardOperation] = useMutation(
		gql(projectGQL.createDryStandard),
		{
			onCompleted: (data) => {
				setNewItem(null);

				navigation.navigate("SingleDryStandard", {
					id: data.createDryStandard.id,
				});
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert("Couldn't create a Dry Standard please try again");
			},
		}
	);

	if (!project || loading) return <LoadingScreen loadItem={"Dry Standard"} />;

	return (
		<>
			<Breadcrumb className="mt-2">
				<>
					{project.workspace && (
						<Crumb
							name={project.workspace.title}
							location={"Project"}
							params={{ id: project.workspace.id }}
							//href={`/app/project/${project.workspace.id}`}
						/>
					)}
				</>
				<Crumb
					name={project.title}
					location={"Project"}
					params={{ id: project.id }}
					//href={`/app/project/${project.id}`}
				/>
				<Crumb
					name={"DryStandards"}
					location={"DryStandards"}
					params={{ id: project.id }}

					//href={`/app/project/${project.id}/dry-standards`}
				/>
			</Breadcrumb>

			<PageHeader
				title={"Dry Standards"}
				info={[
					{
						text: project.workspace
							? project.workspace.client && project.workspace.client.name
								? project.workspace.client.name
								: "No Client Name"
							: project.client && project.client.name
							? project.client.name
							: "No Client Name",
						icon: faBriefcase,
					},
					{
						text:
							project.location && project.location.name
								? project.location.name
								: "No Location Name",
						icon: faMapPin,
					},
				]}
				buttons={
					!projectUser.isReadOnly && user
						? [
								{
									name: "Add Dry Standard Group",
									icon: faPlus,
									onPress: () => setNewItem("DryStandard"),
								},
						  ]
						: []
				}
				// !projectUser.isReadOnly
				// 	? [
				//options={[]}
				goBack={{
					location: "Project",
					params: { id: project.id },
				}}
			/>

			{project.dryStandards && project.dryStandards.length ? (
				<List headers={["Title"]}>
					{project.dryStandards.map((dryStandard, index) => {
						return (
							<ListItem
								key={index}
								items={[
									{
										content: dryStandard.title,

										onPress: () => {
											navigation.navigate("SingleDryStandard", {
												id: dryStandard.id,
											});
										},
									},
								]}
							/>
						);
					})}
				</List>
			) : (
				<NoItems
					surTitle={"No Dry Standards"}
					title="Currently No Dry Standards"
					text="Create a Dry Standard by clicking create above"
				/>
			)}

			<SlideOver
				isOpen={newItem === "DryStandard"}
				name="New Dry Standard"
				description={`Add New Dry Standard to ${project.title}`}
				closeRequest={() => setNewItem(null)}
				onSubmit={() => {
					let input = {
						user: user.id,
						project: id,
						...newDryStandard,
					};

					createDryStandardOperation({
						variables: {
							input: input,
						},
					});
				}}
			>
				<Input
					label="Title"
					onChange={(e) => {
						setNewDryStandard({ ...newDryStandard, title: e });
					}}
				/>
			</SlideOver>
		</>
	);
}

DryStandard.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
	route: PropTypes.object,
};

const getCurrentUser = (project, history, user) => {
	let team = project.workspace
		? project.team.concat(project.workspace.team)
		: project.team;

	let projectUser = team.filter(
		(tm) => tm.teamMember && tm.teamMember.id === user.id
	);

	if (projectUser.length) {
		return projectUser[0];
	} else {
		history.goBack();
	}
};

export function SingleDryStandard({ history, route }) {
	const { id } = route.params;
	const user = getData("@storage_Key").then((user) => user);

	if (dryStandard) {
		getCurrentUser(dryStandard.project, history, user);
	}
	let [reload, setReload] = React.useState(false);
	let [dryStandard, setDryStandard] = React.useState(null);
	let [newItem, setNewItem] = React.useState(null);
	let [newDryStandardItem, setNewDryStandardItem] = React.useState(null);
	let [updateDryStandardItem, setUpdateDryStandardItem] = React.useState(null);
	React.useEffect(() => {
		setReload(false);
	}, [history, reload]);

	const { refetch: dryStandardRefetch } = useQuery(
		gql(projectGQL.getDryStandard),
		{
			variables: {
				id: id,
			},
			onCompleted: (data) => {
				setDryStandard(data.getDryStandard);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);
	const [createSingleDryStandard] = useMutation(
		gql(projectGQL.createDryStandardItem),
		{
			onCompleted: () => {
				setNewItem(null);
				dryStandardRefetch();
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				addNotification({
					title: "Error Creating Material",
					text: "There was an error creating the material for this dry standard group",
					supportText: true,
					type: "danger",
				});
			},
		}
	);

	const [updateSingleDryStandard] = useMutation(
		gql(projectGQL.updateDryStandardItem),
		{
			onCompleted: () => {
				setNewItem(null);
				setReload(true);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert(
					"Error Updating Material",
					"There was an error updating the material for this dry standard group"
				);
			},
		}
	);

	if (!dryStandard) return <LoadingScreen loadItem={"Dry Standard"} />;

	return (
		<>
			<Breadcrumb className="mt-2">
				<>
					{dryStandard.project.workspace && (
						<Crumb
							name={dryStandard.project.workspace.title}
							location="Project"
							params={{ id: dryStandard.project.workspace.id }}
							//href={`/app/project/${dryStandard.project.workspace.id}`}
						/>
					)}
				</>

				<Crumb
					name={dryStandard.project.title}
					location="Project"
					params={{ id: dryStandard.project.id }}
					//href={`/app/project/${dryStandard.project.id}`}
				/>
				<Crumb
					name={"DryStandards"}
					location={"DryStandards"}
					params={{ id: dryStandard.project.id }}
					//href={`/app/project/${project.id}/dry-standards`}
				/>
				<Crumb
					name={dryStandard.title}
					location={"SingleDryStandard"}
					params={{ id: dryStandard.id }}
					//href={`/app/project/${dryStandard.project.id}/dry-standards/${dryStandard.id}`}
				/>
			</Breadcrumb>
			<PageHeader
				title={`${dryStandard.title}`}
				info={[
					{
						text: dryStandard.project.workspace
							? dryStandard.project.workspace.client &&
							  dryStandard.project.workspace.client.name
								? dryStandard.project.workspace.client.name
								: "No Client Name"
							: dryStandard.project.client && dryStandard.project.client.name
							? dryStandard.project.client.name
							: "No Client Name",
						icon: faBriefcase,
					},
					{
						text:
							dryStandard.project.location && dryStandard.project.location.name
								? dryStandard.project.location.name
								: "No Location Name",
						icon: faMapPin,
					},
				]}
				buttons={[
					{
						name: "Add Material",
						icon: faPlus,
						onPress: () => {
							setNewItem("dryStandard");
						},
					},
				]}
				options={[]}
				goBack={{
					location: "DryStandards",
					params: { id: dryStandard.project.id },
				}}
			/>

			{dryStandard.items.length ? (
				<List
					headers={["Material", "Relative", "Penetrative", "Dry Only"]}
					usesOptions
				>
					{dryStandard.items.map((item, index) => {
						return (
							<ListItem
								key={index}
								items={[
									{ content: item.material },
									{ content: item.relative },
									{ content: item.penatrative },
									{ content: item.dryOnly ? "Yes" : "No" },
								]}
								options={
									<OptionsMenu
										options={[
											{
												name: "Update Material",
												icon: faPencil,
												onPress: () => {
													setUpdateDryStandardItem({ id: item.id });
													setNewItem("editMaterial");
												},
											},
										]}
									/>
								}
							/>
						);
					})}
				</List>
			) : (
				<NoItems
					surTitle={"No Materials"}
					title="Currently No Materials"
					text="Create a Dry Standard by clicking create above"
				/>
			)}

			<SlideOver
				isOpen={newItem === "dryStandard"}
				name="New Dry Standard"
				description={`Add New Dry Standard to ${dryStandard.project.title}`}
				closeRequest={() => setNewItem(false)}
				onSubmit={(e) => {
					e.preventDefault();
					newDryStandardItem.dryStandard = dryStandard.id;
					if (!newDryStandardItem.material) {
						return Alert("You must provide a Dry Standard material");
					}
					createSingleDryStandard({
						variables: {
							input: newDryStandardItem,
						},
					});
				}}
			>
				<Input
					label="Material"
					onChange={(e) => {
						setNewDryStandardItem({
							...newDryStandardItem,
							material: e,
						});
					}}
				/>
				<Input
					label="Relative Meter Dry Standard"
					onChange={(e) => {
						setNewDryStandardItem({
							...newDryStandardItem,
							relative: e,
						});
					}}
				/>
				<Input
					label="Penetrative Meter Dry Standard"
					onChange={(e) => {
						setNewDryStandardItem({
							...newDryStandardItem,
							penatrative: e,
						});
					}}
				/>
				<Switch
					label="Dry Only"
					description="Is this material a dry only material"
					onChange={(e) => {
						setNewDryStandardItem({
							...newDryStandardItem,
							dryOnly: e,
						});
					}}
				/>

				{/* Add a switch for dry only material */}
			</SlideOver>
			<SlideOver
				isOpen={newItem === "editMaterial"}
				name="Update Dry Standard"
				description={`Update Dry Standard to ${dryStandard.project.title}`}
				closeRequest={() => setNewItem(false)}
				onSubmit={(e) => {
					e.preventDefault();
					updateSingleDryStandard({
						variables: { input: updateDryStandardItem },
					});
				}}
			>
				<Input
					label="Material"
					onChange={(e) => {
						setUpdateDryStandardItem({
							...updateDryStandardItem,
							material: e,
						});
					}}
				/>
				<Input
					label="Relative Meter Dry Standard"
					onChange={(e) => {
						setUpdateDryStandardItem({
							...updateDryStandardItem,
							relative: e,
						});
					}}
				/>
				<Input
					label="Penetrative Meter Dry Standard"
					onChange={(e) => {
						setUpdateDryStandardItem({
							...updateDryStandardItem,
							penatrative: e,
						});
					}}
				/>
				<Switch
					label="Dry Only"
					description="Is this material a dry only material"
					onChange={(e) => {
						setUpdateDryStandardItem({
							...updateDryStandardItem,
							dryOnly: e,
						});
					}}
				/>
			</SlideOver>
		</>
	);
}

SingleDryStandard.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
	route: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
	}),
};
