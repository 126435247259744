import { useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import React from "react";
import Toast from "react-native-toast-message";

// Components

import { Alert } from "../../../components/Alert";
import Badge from "../../../components/Badge";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import { Checkbox, Input, Select } from "../../../components/Forms";
import { PageHeader } from "../../../components/Header";
import { List, ListItem } from "../../../components/List";
import LoadingScreen from "../../../components/LoadingScreen.js";
import NoItems from "../../../components/NoItems";
import OptionsMenu from "../../../components/OptionsMenu";
import SlideOver from "../../../components/Slideover";
import Tabs, { Tab } from "../../../components/Tabs";
import User from "../../../components/User";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import * as spaceOperations from "../../../graphql/space";

// Mapping

import { MoisturePointSlideOver } from "../../../containers/MoisturePointSlideOver";

import { Line, Path, Rect } from "./Floorplanner/shapes";

// Utils
import {
	faBriefcase,
	faLayerGroup,
	faList,
	faMapPin,
	faPencil,
	faPlus,
	faPrint,
	faThermometerThreeQuarters,
	faVectorSquare,
} from "@fortawesome/pro-duotone-svg-icons";
import { Text, View } from "react-native";
import Button from "../../../components/Button";
import { getData, SentryLogger } from "../../../utils";

const tabs = [
	{ name: "Mapping", href: "#", icon: faVectorSquare },
	{ name: "Psychometrics", href: "#", icon: faThermometerThreeQuarters },
	{ name: "Spaces", href: "#", icon: faLayerGroup },
];

export default function SpaceScreen({ route }) {
	const { id } = route.params;
	const navigation = useNavigation();
	const [user, setUser] = React.useState(null);
	const [space, setSpace] = React.useState({});
	const [newEnvironmental, setNewEnvironmental] = React.useState({});
	const [newItem, setNewItem] = React.useState(null);
	let [updateSpace, setUpdateSpace] = React.useState({});
	let [updatedEnvironmental, setUpdatedEnvironmental] = React.useState({});
	let [newSpaceData, setNewSpaceData] = React.useState({
		noAccess: false,
		isDamage: false,
		isWallDamage: false,
		isCeilingDamage: false,
		isFloorDamage: false,
		isFungalGrowth: false,
	});
	let [currentTab, setCurrentTab] = React.useState(tabs[0].name);
	let [reload, setReload] = React.useState(false);
	const [selectedSurface, setSelectedSurface] = React.useState({});

	React.useEffect(() => {
		setReload(false);
	}, [id, reload]);

	React.useEffect(() => {
		const userInfo = async () => {
			await getData("@storage_Key")
				.then((user) => {
					setUser(user);
				})

				.catch((err) => {
					SentryLogger(err);
				});
		};

		userInfo();
	}, []);

	const { refetch: getSpaceRefetch } = useQuery(gql(spaceOperations.getSpace), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setSpace(data.getSpace);

			initUpdateSpace(data.getSpace);
			setUpdateSpace({
				id: data.getSpace.id,
				title: data.getSpace.title,
				description: data.getSpace.description,
			});
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			navigation.goBack();
		},
		fetchPolicy: "cache-and-network",
	});

	const [createSpace] = useMutation(gql(spaceOperations.createSpace), {
		onCompleted: (data) => {
			setNewSpaceData({
				noAccess: false,
				isDamage: false,
				isWallDamage: false,
				isCeilingDamage: false,
				isFloorDamage: false,
			});
			setNewItem(null);
			navigation.navigate("Space", { id: data.createSpace.id });
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("There was an error creating the space");
		},
	});

	const [updateSpaceOperation] = useMutation(gql(spaceOperations.updateSpace), {
		onCompleted: (data) => {
			setSpace(data.updateSpace);
			initUpdateSpace(data.updateSpace);
			setNewItem(null);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
	});

	const initUpdateSpace = (_space) => {
		setUpdateSpace({
			id: _space.id,
			name: _space.name,
			noAccess: _space.noAccess ? _space.noAccess : false,
			isDamage: _space.isDamage ? _space.isDamage : false,
			isFungalGrowth: _space.isFungalGrowth ? _space.isFungalGrowth : false,
			isWallDamage: _space.isWallDamage ? _space.isWallDamage : false,
			isCeilingDamage: _space.isCeilingDamage ? _space.isCeilingDamage : false,
			isFloorDamage: _space.isFloorDamage ? _space.isFloorDamage : false,
		});
	};

	const [createEnvironmental] = useMutation(
		gql(spaceOperations.createEnvironmentalReading),
		{
			onCompleted: (data) => {
				setEnvironmentalReadings(data.createEnvironmentalReading);

				setNewEnvironmental({});
				setNewItem(null);
				//getSpaceRefetch();
				// setReload(true);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);

	const [updateEnvironmentalOperation] = useMutation(
		gql(spaceOperations.updateEnvironmentalReading),
		{
			onCompleted: () => {
				setUpdatedEnvironmental({});
				setNewItem(null);
				getSpaceRefetch();
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);

	const [deleteEnvironmentalOperation] = useMutation(
		gql(spaceOperations.deleteEnvironmentalReading),
		{
			onCompleted: () => {
				setReload(true);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);

	const handleButtonClick = () => {
		switch (currentTab) {
			case "Spaces":
				setNewItem("space");
				break;

			case "Psychometrics":
				setNewItem("psychometrics");
				break;
			case "Mapping":
				Toast.show({
					type: "success",
					text1: "Feature Coming Soon",
				});
				break;

			default:
				break;
		}
	};

	//** need to add !space.parent back in to getOptions */

	const getOptions = () => {
		switch (currentTab) {
			case "Spaces":
				return (
					<>
						<OptionsMenu
							options={[
								// {
								// 	name: "Generate Damage Assessment Report",
								// 	icon: faFileChartPie,
								// 	onPress: () => {
								// 		generateDamageAssessmentReport();
								// 	},
								// },

								// {
								// 	name: "Bulk Upload",
								// 	icon: faUpload,
								// 	onPress: () => {
								// 		setNewItem("bulkUpload");
								// 	},
								// },
								// {
								// 	name: "Download Space Excel Book",
								// 	icon: faFileChartPie,
								// 	onPress: () => {
								// 		generateDamageAssessmentReport();
								// 	},
								// },
								{
									name: "Edit Space",
									icon: faPencil,
									onPress: () => {
										setNewItem("editSpace");
									},
								},
							]}
						/>
					</>
				);
			case "Mapping":
				return (
					<OptionsMenu
						options={[
							{
								name: "Print Inspection Log",
								icon: faPrint,
								onPress: () => {
									printInspectionLog();
								},
							},

							{
								name: "Edit Space",
								icon: faPencil,
								onPress: () => {
									setNewItem("editSpace");
								},
							},
						]}
					/>
				);
			default:
				break;
		}
	};

	const getSpaceCrumbs = (space) => {
		if (space.parent) {
			return getSpaceCrumbs(space.parent);
		} else {
			return (
				<Crumb name={space.name} location="Space" params={{ id: space.id }} />
			);
		}
	};

	// const generateDamageAssessmentReport = () => {
	// 	addNotification({
	// 		title: "Generating Report",
	// 		text: "Generating Damage Assessment Report. This could take up to a minute. Please do not refresh the screen",
	// 		type: "success",
	// 		time: 5000,
	// 	});
	// 	fetch(
	// 		"https://fk57a28ct3.execute-api.us-west-2.amazonaws.com/v2/data/damage-assessment-report",
	// 		{
	// 			method: "POST",
	// 			mode: "cors",
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 			},
	// 			body: JSON.stringify(space),
	// 		}
	// 	)
	// 		.then((res) => {
	// 			return res.json();
	// 		})
	// 		.then((data) => {
	//

	// 			// download

	// 			let link = document.createElement("a");
	// 			link.setAttribute("target", "_blank");
	// 			link.href = data.url;
	// 			link.click();
	// 		})
	// 		.catch((err) => {
	//

	// 			// notify user of error
	// 		});
	// };

	const printInspectionLog = () => {
		fetch(`${API_URL}/mapping/map-generation`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				space: space,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				// download

				let link = document.createElement("a");
				link.setAttribute("target", "_blank");
				link.href = data.url;
				link.click();
			})
			.catch((err) => {
				SentryLogger(err);
				Alert("Error creating printable version of inspection log");
			});
	};

	if (!space) {
		return <LoadingScreen loadItem={"Space"} />;
	}

	// original floor plan dimensions

	const originalWidth = space.floorPlan
		? JSON.parse(space.floorPlan.plan).originalWidth
		: 0;
	const originalHeight = space.floorPlan
		? JSON.parse(space.floorPlan.plan).originalHeight
		: 0;

	const aspectRatio = originalWidth / originalHeight;

	const getButtonText = () => {
		switch (currentTab) {
			case "Spaces":
				return "Add Space";
			case "Psychometrics":
				return "Add Psychometrics";
			case "Mapping":
				return space.floorPlan ? "Add Data" : "Add Floor Plan";
			default:
				break;
		}
	};
	const navigateToSpace = (id) => {
		return navigation.navigate("Space", {
			id: id,
		});
	};

	return (
		<>
			<Breadcrumb className="mt-2">
				<>
					{space.project && space.project.workspace ? (
						<Crumb
							name={space.project.workspace.title}
							location="Project"
							params={{
								id: space.project.workspace.id,
							}}
						/>
					) : null}
				</>
				<Crumb
					name={space.project && space.project.title}
					location="Project"
					params={{ id: space.project && space.project.id }}
				/>
				{getSpaceCrumbs(space)}
			</Breadcrumb>
			<PageHeader
				title={space.name}
				info={[
					{
						text:
							space.project && space.project.workspace
								? space.project &&
								  space.project.workspace &&
								  space.project.workspace.client &&
								  space.project.workspace.client.name
									? space.project.workspace.client.name
									: "No Client Name"
								: space.project &&
								  space.project.client &&
								  space.project.client.name
								? space.project.client.name
								: "No Client Name",
						icon: faBriefcase,
					},
					{
						text:
							space.project &&
							space.project.location &&
							space.project.location.name
								? space.project.location.name
								: "No Location Name",
						icon: faMapPin,
					},
					{
						text:
							space && space.dryStandard
								? space && space.dryStandard.title
								: "No Dry Standard",
						icon: faList,
					},
				]}
				buttons={[
					{
						name: getButtonText(),
						icon: faPlus,
						onPress: () => {
							handleButtonClick();
						},
					},
				]}
				options={getOptions()}
				goBack={{
					location: "Project",
					params: {
						id: space.project && space.project.id,
					},
				}}
			/>
			<Tabs
				currentValue={currentTab}
				onChange={(value) => {
					setCurrentTab(value);
				}}
			>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							current={currentTab === tab.name}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			{currentTab === "Spaces" &&
				(space.spaces && space.spaces.length ? (
					<List
						title="Spaces"
						headers={[
							"Name",
							"No Access",
							"Mapped",
							"Wall Damage",
							"Ceiling Damage",
							"Floor Damage",
							"Fungal Growth",
						]}
						usesOptions
					>
						{space.spaces.map((space, index) => {
							return (
								<ListItem
									key={`space-${index}`}
									items={[
										{
											onPress: () => {
												navigateToSpace(space.id);
											},
											//href: `/app/space/${space.id}`,
											content: space.name,
										},
										{
											content: space.noAccess ? (
												<Badge text="No Access" variant="red" />
											) : (
												<Badge text="Accessed" variant="green" />
											),
										},
										{
											content: space.mapped ? (
												<Badge text="Mapped" variant="green" />
											) : (
												<Badge text="Not Mapped" variant="red" />
											),
										},
										{
											content: space.isWallDamage ? (
												<Badge text="Wall Damage" variant="red" />
											) : (
												<Badge text="No Wall Damage" variant="green" />
											),
										},
										{
											content: space.isCeilingDamage ? (
												<Badge text="Ceiling Damage" variant="red" />
											) : (
												<Badge text="No Ceiling Damage" variant="green" />
											),
										},
										{
											content: space.isFloorDamage ? (
												<Badge text="Floor Damage" variant="red" />
											) : (
												<Badge text="No Floor Damage" variant="green" />
											),
										},
										{
											content: space.isFungalGrowth ? (
												<Badge text="Fungal Growth" variant="red" />
											) : (
												<Badge text="No Fungal Growth" variant="green" />
											),
										},
									]}
									options={
										<OptionsMenu
											options={[
												{
													name: "View",
													onPress: () => {
														navigateToSpace(space.id);
													},
												},
											]}
										/>
									}
								/>
							);
						})}
					</List>
				) : (
					<NoItems
						surTitle={"No Space"}
						title="Currently No Spaces"
						text="Create a space by clicking create above"
					/>
				))}
			{currentTab === "Psychometrics" && (
				<View className=" px-2 pt-2 ">
					{space.environmentalReadings.length ? (
						<List title="Psychometrics" headers={["user"]} usesOptions>
							{space.environmentalReadings.map((er, index) => {
								return (
									<>
										<ListItem
											key={index}
											items={[
												{ content: <User user={er.user} /> },
												{
													content: DateTime.fromISO(er.date).toFormat(
														"MMMM dd, yyyy HH:MM"
													),
												},
												{ content: er.temperature },
												{ content: er.relativeHumidity },
												{ content: er.gpp },
											]}
											smallView={
												<View className=" hover:bg-gray-50 dark:hover:bg-gray-700  sm:px-6">
													<Text className="text-gray-900 dark:text-white ">
														Temp: {er.temperature} | RH: {er.relativeHumidity} |
														GPP: {er.gpp}
													</Text>

													<Text className=" text-xs text-gray-900 dark:text-white ">
														Date:{" "}
														{DateTime.fromISO(er.date).toFormat(
															"MMMM dd, yyyy HH:MM"
														)}
													</Text>
												</View>
											}
											options={
												<>
													{user.id === er.user.id && (
														<OptionsMenu
															options={[
																{
																	name: "Edit",

																	onPress: () => {
																		setUpdatedEnvironmental({
																			id: er.id,
																			temperature: er.temperature,
																			relativeHumidity: er.relativeHumidity,
																			gpp: er.gpp,
																		});
																		setNewItem("editPsych");
																		getSpaceRefetch();
																	},
																},

																{
																	name: "Delete",
																	onPress: () => {
																		deleteEnvironmentalOperation(er.id);
																		getSpaceRefetch();
																	},
																},
															]}
														/>
													)}
												</>
											}
										/>
									</>
								);
							})}
						</List>
					) : (
						<NoItems
							surTitle={"No Psychometrics"}
							title="Currently No Psychometrics"
							text="Create an psychometrics by clicking create above"
						/>
					)}
				</View>
			)}
			{currentTab === "Mapping" && (
				<>
					{space.floorPlan ? (
						<View>
							<View className="relative grid grid-cols-3 ">
								<View
									className="pl-5"
									style={{
										zIndex: 0,
										height: "100%",
										width: "100%",
										aspectRatio: aspectRatio,
									}}
								>
									<Text className="text-center font-medium pt-5">
										SRP Environmental Inspection Log
									</Text>
									<svg
										width="100%"
										viewBox={`0 0 ${originalWidth} ${originalHeight}`}
									>
										{space.surfaces.map((surface) => {
											let points = JSON.parse(surface.points);
											let moisturePoints = surface.moisturePoints;
											let color = null;
											let moistureLocationType = null;
											let previouslyDemoed = false;
											if (moisturePoints && moisturePoints.length > 0) {
												moistureLocationType =
													moisturePoints[moisturePoints.length - 1]
														.locationType;

												if (moistureLocationType === "floor") {
													color = "none";
												} else {
													color =
														moisturePoints[moisturePoints.length - 1].color;
												}

												previouslyDemoed =
													moisturePoints[moisturePoints.length - 1]
														.previouslyDemoed;
											}

											let clicked = false;
											if (
												selectedSurface &&
												selectedSurface.id === surface.id
											) {
												clicked = true;
											}

											let symbol = "";

											switch (moistureLocationType) {
												case "wall":
													symbol = "X";
													break;
												case "floor":
													symbol = "F";
													break;
												case "ceiling":
													symbol = "C";
													break;
												default:
													symbol = "x";
											}

											if (surface.type === "line") {
												if (points.length > 2) {
													let middleX =
														(points[0].x + points[points.length - 1].x) / 2;
													let middleY =
														(points[0].y + points[points.length - 1].y) / 2;
													return (
														<Line
															key={index}
															points={points}
															stroke={color ? color : "gray"}
															strokeWidth={10}
															onClick={() => {
																setSelectedSurface(surface);
															}}
															clicked={clicked}
															dashed={previouslyDemoed}
															symbol={symbol}
															middleX={middleX}
															middleY={middleY}
														/>
													);
												}
											}

											if (surface.type === "path") {
												if (points.length > 0) {
													let middleX =
														(points[0].x + points[points.length - 1].x) / 2;
													let middleY =
														(points[0].y + points[points.length - 1].y) / 2;
													return (
														<Path
															key={index}
															points={points}
															stroke={color ? color : "gray"}
															strokeWidth={10}
															clicked={clicked}
															moisturePointLocationType={moistureLocationType}
															className={`${clicked ? "animate-pulse" : ""}`}
															onClick={() => {
																setSelectedSurface(surface);
															}}
															symbol={symbol}
															middleX={middleX}
															middleY={middleY}
														/>
													);
												}
											}

											if (surface.type === "rectangle") {
												if (points.length > 0) {
													return (
														<Rect
															key={index}
															points={points}
															color={color}
															stroke={color}
															onClick={() => {
																setSelectedSurface(surface);
															}}
															className={clicked ? "animate-pulse" : ""}
															symbol={symbol}
														/>
													);
												}
											}
										})}
										{JSON.parse(space.floorPlan.plan).paths.map(
											(wall, index) => {
												if (wall.points.length > 0) {
													return (
														<Line
															key={index}
															points={wall.points}
															stroke={wall.color}
															strokeWidth={10}
														/>
													);
												}
											}
										)}
									</svg>
									<View className="">
										<View className=" text-gray-500 font-medium">
											COLOR CODE
										</View>
									</View>

									<View className="text-center flex">
										<View className="p-2 h-5 w-5 rounded bg-yellow-500"> </View>
										<Text className="pl-5 font-medium text-[10]">
											YELLOW = REMOVE CEILING
										</Text>
									</View>
									<View className=" text-center flex">
										<View className="p-2 h-5 w-5 rounded bg-purple-800"> </View>
										<Text className="pl-5 font-medium text-[10]">
											PURPLE = Remove Walls - Floor to Ceiling
										</Text>
									</View>

									<View className=" text-center flex">
										<View className="p-2 h-5 w-5 rounded bg-blue-500"> </View>
										<Text className="pl-5 font-medium text-[10]">
											BLUE = Remove Walls - Floor to 4 ft
										</Text>
									</View>

									<View className=" text-center flex">
										<View className="p-2 h-5 w-5 rounded bg-green-500"> </View>
										<Text className="pl-5 font-medium text-[10]">
											GREEN = Remove Walls - Floor to 2 ft
										</Text>
									</View>

									<View className=" text-center flex">
										<View className="p-2 h-5 w-5 rounded bg-orange-500"> </View>
										<Text className="pl-5 font-medium text-[10]">
											ORANGE = Special Instructions
										</Text>
									</View>

									<View className=" text-center flex">
										<View className="p-2 h-5 w-5 rounded bg-red-500"> </View>
										<Text className="pl-5 font-medium text-[10]">
											RED = Dry in Place
										</Text>
									</View>
								</View>
								<View className="col-span-2">
									<View>
										<Text className="text-center font-medium pt-5">
											Moisture Data
										</Text>
									</View>

									{selectedSurface ? (
										<>
											<View className="sm:block ml-2">
												<Button
													onPress={() => {
														setNewItem("moisturePoint");
													}}
													type="button"
													icon={faPlus}
													className="inline-flex items-baseline px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white dark:bg-gray-800 dark:text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
													// className={
													// 	"-ml-1 mr-2 h-5 w-5 text-gray-500 dark:text-white fas fa-plus"
													// }
													aria-hidden="true"
													text="Add Moisture Point"
												/>
											</View>
											<List
												headers={[
													"Date",
													"MC",
													"Height",
													"Material",
													"FLIR #",
													"Tech",
													"Special Instructions",
												]}
											>
												{selectedSurface.moisturePoints.map((point, index) => {
													return (
														<ListItem
															key={index}
															items={[
																{
																	content: DateTime.fromISO(
																		point.date
																	).toFormat("MM-dd-yyyy"),
																},

																{ content: point.mc },
																{ content: `${point.feet}' ${point.inches}" ` },
																{
																	content: point.material
																		? point.material.material
																		: "Unknown",
																},
																{ content: point.flir },
																{
																	content: <User user={point.user} photoOnly />,
																},
																{ content: point.specialInstructions },
															]}
														/>
													);
												})}
											</List>
										</>
									) : (
										<NoItems
											surTitle={"No Moisture Point Selected"}
											title="You must select a moisture point"
											text="Click a moisture point to see its data"
										/>
									)}
								</View>
							</View>

							<View className="mx-5 mt-2">
								<Text className="text-xs">
									* Recommendations are established on Industrial Hygiene
									practices and do not necessarily address concerns associated
									with construction complexities, compromises in structural
									integrity, hidden or concealed conditions, etc.{" "}
								</Text>
								<Text className="text-xs">
									** Should a circumstance be discovered during the remediation
									process prompting deviation from this assessment, please
									inform SRP Management so that appropriate adjustments can be
									made.{" "}
								</Text>
							</View>
						</View>
					) : (
						<NoItems
							surTitle="No Floor Plan"
							title="Must Assign A Floor Plan"
						/>
					)}
				</>
			)}
			{/* Slide Overs */}
			{/* New moisture point Over */}
			<MoisturePointSlideOver
				isOpen={newItem === "moisturePoint"}
				closeRequest={() => {
					//remove the current wall touched from the mapData

					setNewItem(null);
				}}
				type="create"
				space={space}
				surface={selectedSurface}
				user={user}
				onComplete={() => {
					//reload
					setNewItem(null);
					getSpaceRefetch();
				}}
			/>
			{/* New space slideover */}
			<SlideOver
				isOpen={newItem === "space"}
				name="New Space"
				description="Add a new space collection to project name"
				closeRequest={() => setNewItem(null)}
				onSubmit={() => {
					newSpaceData.createBy = user.id;
					newSpaceData.project = space.project.id;
					newSpaceData.projectSub = space.project.id;
					newSpaceData.parent = space.id;
					newSpaceData.date = DateTime.now().toISO();

					createSpace({
						variables: {
							input: newSpaceData,
						},
					});
					getSpaceRefetch();
				}}
			>
				<Input
					required
					name="name"
					label="Name"
					placeholder="Floor One"
					onChange={(e) =>
						setNewSpaceData({
							...newSpaceData,
							name: e,
						})
					}
				/>

				<Checkbox
					name="checkbox"
					label="No Access"
					placeholder="Floor One"
					onChange={() =>
						setNewSpaceData({
							...newSpaceData,
							noAccess: !newSpaceData.noAccess,
						})
					}
				/>

				{newSpaceData.noAccess !== true && (
					<>
						<Checkbox
							name="checkbox"
							label="Damage"
							description="Is there damage to any of the building materials"
							onChange={() => {
								setNewSpaceData({
									...newSpaceData,
									isDamage: !newSpaceData.isDamage,
								});
							}}
						/>

						<Checkbox
							name="mapped"
							label="Mapped"
							description="Is mapping complete for this space"
							onChange={() => {
								setNewSpaceData({
									...newSpaceData,
									mapped: !newSpaceData.mapped,
								});
							}}
						/>
					</>
				)}
				{newSpaceData.isDamage === true && (
					<>
						<Checkbox
							name="checkbox"
							label="Wall Damage"
							onChange={() => {
								setNewSpaceData({
									...newSpaceData,
									isWallDamage: !newSpaceData.isFungalGrowth,
								});
							}}
						/>
						<Checkbox
							name="checkbox"
							label="Ceiling Damage"
							onChange={() => {
								setNewSpaceData({
									...newSpaceData,
									isCeilingDamage: !newSpaceData.isCeilingDamage,
								});
							}}
						/>
						<Checkbox
							name="checkbox"
							label="Floor Damage"
							onChange={() => {
								setNewSpaceData({
									...newSpaceData,
									isFloorDamage: !newSpaceData.isFloorDamage,
								});
							}}
						/>
						<Checkbox
							name="checkbox"
							label="Assumed Mold Growth"
							onChange={() => {
								setNewSpaceData({
									...newSpaceData,
									isFungalGrowth: !newSpaceData.isFungalGrowth,
								});
							}}
						/>
					</>
				)}

				{/* )} */}
			</SlideOver>
			{/* New psychometrics slideover */}
			<SlideOver
				isOpen={newItem === "psychometrics"}
				name="New Psychometrics"
				description="Add a new psychometrics to space name"
				closeRequest={() => setNewItem(null)}
				onSubmit={(e) => {
					e.preventDefault();
					newEnvironmental.date = DateTime.now().toISO();
					newEnvironmental.space = space.id;
					newEnvironmental.user = user.id;

					createEnvironmental({ variables: { input: newEnvironmental } });
					getSpaceRefetch();
					setNewItem(null);
				}}
			>
				<Input
					required
					name="temp"
					label="Temperature"
					placeholder="90.0"
					type="number"
					step="any"
					onChange={(e) =>
						setNewEnvironmental({
							...newEnvironmental,
							temperature: parseFloat(e),
						})
					}
				/>

				<Input
					required
					name="Relative Humidity"
					label="Relative Humidity"
					placeholder="90"
					type="number"
					step="any"
					onChange={(e) =>
						setNewEnvironmental({
							...newEnvironmental,
							relativeHumidity: parseFloat(e),
						})
					}
				/>

				<Input
					required
					name="GPP"
					label="GPP"
					placeholder="102.21"
					type="number"
					step="any"
					onChange={(e) =>
						setNewEnvironmental({
							...newEnvironmental,
							gpp: parseFloat(e),
						})
					}
				/>
			</SlideOver>
			{/* edit space slideover */}
			<SlideOver
				isOpen={newItem === "editSpace"}
				name={`Edit ${space.name}`}
				description={
					space.parent ? "Edit this space" : "Edit this space collection"
				}
				closeRequest={() => setNewItem(null)}
				onSubmit={() => {
					updateSpaceOperation({
						variables: {
							input: updateSpace,
						},
					});
				}}
			>
				<Input
					name="name"
					label="Name"
					value={updateSpace.name || space.name}
					placeholder="Space Name"
					onChange={(e) =>
						setUpdateSpace({
							...updateSpace,
							name: e,
						})
					}
				/>

				<Select
					name="Dry Standard"
					label="Dry Standard"
					defaultValue={
						space.dryStandard
							? `${space.dryStandard && space.dryStandard.title}`
							: "Select Dry Standard"
					}
					onChange={(value) =>
						setUpdateSpace({
							...updateSpace,
							dryStandard: value.value,
						})
					}
					options={
						space &&
						space.project &&
						space.project.dryStandards.map((ds) => ({
							value: ds.id,
							name: `${ds.title}`,
						}))
					}
				/>
				<Input
					name="FLIR"
					label="FLIR"
					helper="For area information purposes"
					value={updateSpace.flir || space.flir}
					placeholder="318"
					onChange={(e) =>
						setUpdateSpace({
							...updateSpace,
							flir: e,
						})
					}
				/>
				<Input
					name="Site Audit"
					label="Site Audit"
					value={updateSpace.sap || space.sap}
					placeholder="71"
					helper="For area information purposes"
					onChange={(e) =>
						setUpdateSpace({
							...updateSpace,
							sap: e,
						})
					}
				/>
				<Input
					name="Time In"
					label="Time In"
					value={updateSpace.timeIn || space.timeIn}
					placeholder="10:20 AM"
					helper="For area mapping time keeping purposes"
					onChange={(e) =>
						setUpdateSpace({
							...updateSpace,
							timeIn: e,
						})
					}
				/>
				<Input
					name="Time Out"
					label="Time Out"
					value={updateSpace.timeOut || space.timeOut}
					placeholder="10:30 AM"
					helper="For area mapping time keeping purposes"
					onChange={(e) =>
						setUpdateSpace({
							...updateSpace,
							timeOut: e,
						})
					}
				/>
				{/*List Project Dry Standards via Select here*/}
				<>
					{space.parent ? (
						<>
							<Checkbox
								name="checkbox"
								value={updateSpace && updateSpace.noAccess}
								label="No Access"
								placeholder="Floor One"
								onChange={() =>
									setUpdateSpace({
										...updateSpace,
										noAccess: !updateSpace.noAccess,
									})
								}
							/>

							{updateSpace.noAccess !== true && (
								<>
									<Checkbox
										name="mapped"
										value={updateSpace && updateSpace.mapped}
										label="Mapped"
										description="Is mapping complete for this space"
										onChange={() => {
											setUpdateSpace({
												...updateSpace,
												mapped: !updateSpace.mapped,
											});
										}}
									/>
									<Checkbox
										name="checkbox"
										value={updateSpace && updateSpace.isDamage}
										label="Damage"
										description="Is there damage to any of the building materials"
										onChange={() => {
											setUpdateSpace({
												...updateSpace,
												isDamage: !updateSpace.isDamage,
											});
										}}
									/>

									{updateSpace.isDamage === true && (
										<>
											<Checkbox
												name="checkbox"
												value={updateSpace && updateSpace.isWallDamage}
												label="Wall Damage"
												onChange={() => {
													setUpdateSpace({
														...updateSpace,
														isWallDamage: !updateSpace.isFungalGrowth,
													});
												}}
											/>
											<Checkbox
												name="checkbox"
												value={updateSpace.isCeilingDamage}
												label="Ceiling Damage"
												onChange={() => {
													setUpdateSpace({
														...updateSpace,
														isCeilingDamage: !updateSpace.isCeilingDamage,
													});
												}}
											/>
											<Checkbox
												name="checkbox"
												value={updateSpace && updateSpace.isFloorDamage}
												label="Floor Damage"
												onChange={() => {
													setUpdateSpace({
														...updateSpace,
														isFloorDamage: !updateSpace.isFloorDamage,
													});
												}}
											/>
											<Checkbox
												name="checkbox"
												value={updateSpace && updateSpace.isFungalGrowth}
												label="Assumed Mold Growth"
												onChange={() => {
													setUpdateSpace({
														...updateSpace,
														isFungalGrowth: !updateSpace.isFungalGrowth,
													});
												}}
											/>
										</>
									)}
								</>
							)}
						</>
					) : null}
				</>
			</SlideOver>
			{/* edit psychometrics slideover */}
			<SlideOver
				isOpen={newItem === "editPsych"}
				name="Update Environmental Data"
				description="Update Environmental Data"
				closeRequest={() => {
					setNewItem(null);
					setUpdatedEnvironmental({});
				}}
				onSubmit={(e) => {
					e.preventDefault();
					updateEnvironmentalOperation({
						variables: {
							input: updatedEnvironmental,
						},
					});
				}}
			>
				<Input
					name="temp"
					label="Temperature"
					placeholder="90.0"
					type="number"
					step="any"
					value={updatedEnvironmental.temperature}
					onChange={(e) =>
						setUpdatedEnvironmental({
							...updatedEnvironmental,
							temperature: parseFloat(e),
						})
					}
				/>
				<Input
					name="Relative Humidity"
					label="Relative Humidity"
					placeholder="90"
					type="number"
					step="any"
					value={updatedEnvironmental.relativeHumidity}
					onChange={(e) =>
						setUpdatedEnvironmental({
							...updatedEnvironmental,
							relativeHumidity: parseFloat(e),
						})
					}
				/>
				<Input
					name="GPP"
					label="GPP"
					placeholder="102.21"
					type="number"
					step="any"
					value={updatedEnvironmental.gpp}
					onChange={(e) =>
						setUpdatedEnvironmental({
							...updatedEnvironmental,
							gpp: parseFloat(e),
						})
					}
				/>
			</SlideOver>
		</>
	);
}

SpaceScreen.propTypes = {
	route: PropTypes.object,
};
