import * as ImagePicker from "expo-image-picker";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import {
	Image,
	Platform,
	ScrollView,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import { useTailwind } from "tailwind-rn";

// Mapping and Location
import * as Location from "expo-location";
import uuid from "react-native-uuid";

// Components

import { Alert } from "../components/Alert";
import { Input, Switch, TextArea } from "../components/Forms";
import SlideOver from "../components/Slideover";

// Utils
import { getData, uploadFile } from "../utils";

// GQL

import { gql, useMutation } from "@apollo/client";
import * as auditGQL from "../graphql/audits";

// Icons

import {
	faCamera,
	faPhotoVideo,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

export const AuditSlideOver = ({
	type = "create",
	currentItem,
	survey,
	onClose = () => {},
	onComplete = () => {},
	isOpen,
	currentMediaUrls,
	currentMedia,
}) => {
	const tailwind = useTailwind();
	const [user, setUser] = React.useState(null);
	const [item, setItem] = React.useState({});
	const [files, setFiles] = React.useState([]);
	const [creating, setCreating] = React.useState(false);
	const [location, setLocation] = React.useState(null);
	const [addLocation, setAddLocation] = React.useState(false);
	const [mediaURLs, setMediaURLs] = React.useState([]);
	const [media, setMedia] = React.useState([]);

	React.useEffect(() => {
		setItem(currentItem || {});
		if (currentItem && currentItem.location) {
			setLocation(JSON.parse(currentItem.location));
			setAddLocation(true);
		}
	}, [currentItem]);

	React.useEffect(() => {
		setMediaURLs(currentMediaUrls || []);
	}, [currentMediaUrls]);

	React.useEffect(() => {
		setMedia(currentMedia || []);
	}, [currentMedia]);

	React.useEffect(() => {
		const getUser = async () => {
			await getData("@storage_Key").then((user) => setUser(user));
		};
		getUser();
	}, []);

	const [createSurveyItemOperation] = useMutation(
		gql(auditGQL.createSurveyItem),
		{
			onCompleted: async (data) => {
				await uploadMedia(data.createSurveyItem);
				onComplete(data);
			},
			onError: (error) => {
				JSON.parse(error, null, 2);
				Alert("Error Creating Item");
				setCreating(false);
			},
		}
	);

	const [createMediaItem] = useMutation(gql(auditGQL.createMedia));

	const [deleteMediaItem] = useMutation(gql(auditGQL.deleteMedia));

	const [updateSurveyItem] = useMutation(gql(auditGQL.updateSurveyItem), {
		onCompleted: async (data) => {
			await uploadMedia(data.updateSurveyItem);
			onComplete(data);
		},
		onError: () => {
			Alert("Error Updating Item");
			setCreating(false);
		},
	});

	const uploadMedia = async (auditItem) => {
		if (!files.length) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			const response = await fetch(files[i].uri);
			const blob = await response.blob();
			let filename = `projects/${survey.project.id}/audits/${
				survey.id
			}/${uuid.v4()}`;
			let type = files[i].type;

			try {
				uploadFile(blob, filename, type);
				createMediaItem({
					variables: {
						input: {
							surveyItem: auditItem.id,
							key: filename,
							order: i,
						},
					},
				});
			} catch (error) {
				Alert("Error Uploading Media");
			}
		}
		setCreating(false);
		cleanUp();
	};

	const pickImage = async () => {
		// No permissions request is necessary for launching the image library
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			allowsMultipleSelection: true,
			quality: 1,
		});

		if (!result.canceled) {
			setFiles([...files, ...result.selected]);
		}
	};

	const getMedia = async (camera = false) => {
		let result;
		if (camera) {
			result = await launchCamera();
		} else {
			result = await launchImageLibrary();
		}

		if (!result.didCancel) {
			setFiles((previousFiles) => [...previousFiles, ...result.assets]);
		}
	};

	const getLocation = async () => {
		let { status } = await Location.requestForegroundPermissionsAsync();
		if (status !== "granted") {
			if (Platform.OS !== "web") {
				return Alert("You must allow location services to use this feature");
			} else {
				return Alert("We need permission to access your location");
			}
		}
		let location = await Location.getCurrentPositionAsync({});

		setLocation({
			latitude: location.coords.latitude,
			longitude: location.coords.longitude,
		});
	};

	const cleanUp = async () => {
		setFiles([]);
		setItem({});
		setLocation(null);
		setAddLocation(false);
		setCreating(false);
	};

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "New Item" : "Update Item"}
			description={
				type === "create"
					? "Add a new item to audit collection"
					: "Update Item in audit collection"
			}
			closeRequest={() => {
				cleanUp();
				onClose();
			}}
			onSubmit={() => {
				if (!item.title || !item.description) {
					return Alert(
						"Missing Information",
						"Must have a title and description to create an item"
					);
				}
				setCreating(true);

				if (type === "create") {
					let input = item;

					input.survey = survey.id;
					input.date = DateTime.local().toISO();
					input.order = survey.items.length
						? survey.items[survey.items.length - 1].order + 1
						: 0;
					if (addLocation && location) {
						input.location = JSON.stringify(location);
					}
					input.user = user.id;

					createSurveyItemOperation({
						variables: {
							input: input,
						},
					});
				} else {
					updateSurveyItem({
						variables: {
							input: {
								id: item.id,
								title: item.title,
								description: item.description,
								location: location ? JSON.stringify(location) : null,
							},
						},
					});
				}
			}}
			disableButton={creating}
			buttonText={
				creating
					? type === "create"
						? "Creating Item"
						: "Updating Item"
					: "Save"
			}
			survey={item}
			surveyItem={item}
		>
			<Text
				style={tailwind("pb-1 font-medium text-gray-700 dark:text-gray-300")}
			>
				Media Upload
			</Text>
			<ScrollView horizontal className="flex px-2  ">
				<>
					{Platform.OS !== "web" && (
						<TouchableOpacity
							onPress={async () => {
								getMedia(true);
							}}
							className="h-20 w-20 border-indigo-500 border rounded flex justify-center items-center mr-2.5"
						>
							<FontAwesomeIcon icon={faCamera} size={36} color="#3b82f6" />
						</TouchableOpacity>
					)}
				</>

				<TouchableOpacity
					onPress={() => pickImage()}
					className="h-20 w-20 border-indigo-500 border rounded flex justify-center items-center mr-2.5"
				>
					<FontAwesomeIcon icon={faPhotoVideo} size={36} color="#3b82f6" />
				</TouchableOpacity>

				{mediaURLs.map((_url, i) => (
					<View
						key={i}
						style={tailwind(
							"relative h-20 w-20 border-indigo-500 border rounded mr-2.5"
						)}
					>
						<TouchableOpacity
							onPress={() => {
								Alert(
									"Are you sure you want to delete this media?",
									"This cannot be undone",
									[
										{
											text: "Cancel",
											style: "cancel",
										},
										{
											text: "Delete",
											onPress: () => {
												deleteMediaItem({
													variables: {
														input: {
															id: media[i].id,
														},
													},
												});

												// delete from media URL array

												setMediaURLs((previousURLs) => {
													return previousURLs.filter((_, j) => j !== i);
												});

												// delete from media array

												setMedia((previousMedia) => {
													return previousMedia.filter((_, j) => j !== i);
												});
											},
										},
									]
								);
							}}
							style={tailwind(
								"absolute z-10 top-0.5 right-0.5 h-5 w-5 bg-red-500 flex justify-center items-center rounded"
							)}
						>
							<FontAwesomeIcon icon={faTimes} color="white" />
						</TouchableOpacity>

						<Image
							style={tailwind("h-20 w-20  rounded")}
							source={{ uri: _url.url }}
						/>
					</View>
				))}
				{files.map((file, index) => {
					return (
						<View
							key={index}
							className="relative h-20 w-20 border-indigo-500 border rounded mr-2.5"
						>
							<TouchableOpacity
								onPress={() => {
									let newFileArray = files;
									newFileArray = newFileArray.filter((file, fileIndex) => {
										if (fileIndex !== index) {
											return file;
										}
									});
									setFiles(newFileArray);
								}}
								className="absolute z-10 top-0.5 right-0.5 h-5 w-5 bg-red-500 flex justify-center items-center rounded"
							>
								<FontAwesomeIcon icon={faTimes} color="white" />
							</TouchableOpacity>
							{(file.uri.includes("data:image") ||
								file.type.split("/").includes("image")) && (
								<Image
									className="h-20 w-20  rounded"
									source={{ uri: file.uri }}
								/>
							)}
							{file.type === "video" && <Text>Video Support Coming Soon</Text>}
						</View>
					);
				})}
			</ScrollView>

			<Input
				required
				name="Title"
				label="Title"
				placeholder="Title"
				type="default"
				value={item.title}
				onChange={(e) => setItem({ ...item, title: e })}
			/>

			<TextArea
				required
				name="Description"
				label="Description"
				placeholder="Description"
				value={item.description}
				onChange={(e) => setItem({ ...item, description: e })}
			/>
			<Switch
				label={"Add your location"}
				value={addLocation || item.location}
				onChange={() => {
					setAddLocation(!addLocation);
					getLocation(!addLocation);
				}}
			/>
			<>
				{addLocation && location && (
					<>
						{Platform.OS !== "web" && (
							<View className="w-full h-52 rounded bg-gray-500 mt-5 flex justify-center items-center">
								<Text className="text-gray-900 dark:text-white">
									Latitude: {location.latitude}
								</Text>
								<Text className="text-gray-900 dark:text-white">
									Longitude: {location.longitude}
								</Text>

								<Text className="text-gray-500 mt-2">
									Location viewing is not currently supported. We are working on
									a fix.
								</Text>
							</View>
						)}

						{Platform.OS === "web" && (
							<View className="mt-2">
								<Text className="text-gray-900 dark:text-white">
									Latitude: {location.latitude}
								</Text>
								<Text className="text-gray-900 dark:text-white">
									Longitude: {location.longitude}
								</Text>

								<Text className="text-gray-500 mt-2">
									Location viewing is not currently supported on web. Please use
									a mobile device to view your location.
								</Text>
							</View>
						)}
					</>
				)}
			</>
		</SlideOver>
	);
};

AuditSlideOver.propTypes = {
	type: PropTypes.string.isRequired,
	currentItem: PropTypes.object,
	user: PropTypes.object,
	survey: PropTypes.object,
	onClose: PropTypes.func,
	onComplete: PropTypes.func,
	onSubmit: PropTypes.func,
	isOpen: PropTypes.bool.isRequired,
	currentMediaUrls: PropTypes.array,
	currentMedia: PropTypes.array,
};
