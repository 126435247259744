import { useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { ScrollView, Text, View } from "react-native";

// components
// import { addNotification } from "../../../components/Notifications";
import { Alert } from "../../../components/Alert";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import CardContainer, { Card } from "../../../components/Card";
import { Input, Select } from "../../../components/Forms";
import { PageHeader } from "../../../components/Header";
import LoadingScreen from "../../../components/LoadingScreen";
import Modal from "../../../components/Modal";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as projectGQL from "../../../graphql/project";
import * as newReportOperations from "../../../graphql/reports";

// Utils
import { faPlus } from "@fortawesome/pro-duotone-svg-icons";
import { getData, SentryLogger } from "../../../utils";

export const NewReport = ({ route }) => {
	const { id } = route.params;
	const navigation = useNavigation();

	const [user, setUser] = React.useState();
	const [manualAuthor, setAuthor] = React.useState(null);
	const [reportTypes, setReportTypes] = React.useState([]);
	const [selectedReport, setSelectedReport] = React.useState(null);
	const [reportOptions, setReportOptions] = React.useState(null);
	const [reportResponses, setReportResponses] = React.useState({});
	const [reviewUsers, setReviewUsers] = React.useState(null);
	const [selectedUser, setSelectedUser] = React.useState(null);
	const [addressedTo, setAddressedTo] = React.useState(null);
	const [project, setProject] = React.useState({});
	const [stage, setStage] = React.useState("addressTo");
	const [newClientUser, setNewClientUser] = React.useState(false);
	const [teamMembers, setTeamMembers] = React.useState(null);
	const [labs, setLabs] = React.useState(null);
	const [labLocations, setLabLocations] = React.useState(null);
	const [reportData, setReportData] = React.useState(null);

	React.useEffect(() => {
		getData("@storage_Key")
			.then((user) => {
				setUser(user);
			})
			.catch((err) => {
				SentryLogger(err);
			});
	}, []);

	// queries

	const { loading: projectLoading } = useQuery(
		gql(newReportOperations.getProject),
		{
			variables: {
				id: id,
			},
			onCompleted: (data) => {
				setProject({ ...data.getProject });
				let project = data.getProject;
				let team = project.workspace
					? project.team.concat(project.workspace.team)
					: project.team;
				setTeamMembers(team);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert("Can not get project");
				navigation.goBack();
			},
			fetchPolicy: "network-only",
		}
	);

	const { loading: reportTypesLoading } = useQuery(
		gql(newReportOperations.listReportTypes),
		{
			onCompleted: (data) => {
				setReportTypes([...data.listReportTypes.items]);
				setReviewUsers([...data.listReportUsers.items]);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert("There was an error loading report options. Please Try again");
			},
			fetchPolicy: "cache-and-network",
		}
	);

	useQuery(gql(projectGQL.listLabs), {
		onCompleted: (data) => {
			setLabs([...data.listLabs.items]);
		},
	});

	// mutations

	const [createReportData] = useMutation(
		gql(newReportOperations.createReport),
		{
			onCompleted: (data) => {
				createReportLink({
					variables: {
						input: {
							date: DateTime.now().toISO(),
							link: reportData.key,
							name: reportData.name,
							report: data.createReport.id,
						},
					},
				});
				// Alert({
				// 	title: "Report Was Successfully Created",
				// 	text: "Almost finished we just need to finish a few more steps",
				// 	type: "success",
				// 	time: 5000,
				// });
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert(
					"Uh Oh Something Went Wrong",
					"There was an error creating the report mapping data. Please try again. If this continues to happen please go to help.srpinfield.com and start a chat with one of our engineers"
				);
				setStage("options");
			},
		}
	);

	const [createReportLink] = useMutation(
		gql(newReportOperations.createReportLink),
		{
			onCompleted: (data) => {
				// addNotification({
				// 	title: "Generating Report Link",
				// 	text: "This is the final step. We will redirect you to the report momentarily",
				// 	type: "info",
				// 	time: 5000,
				// });
				navigation.navigate("Report", {
					id: data.createReportLink.report.id,
				});
				setReportResponses({});
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert(
					"Uh Oh Something Went Wrong",
					"There was an error generating the report link. Don't worry you can request it again on the next page"
				);
			},
		}
	);

	const [createUser] = useMutation(gql(newReportOperations.createUser), {
		onCompleted: () => {
			setNewClientUser(false);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			return Alert("Could not create representative. Please try again");
		},
	});

	const getClientName = (project) => {
		if (!project) return [];
		const client = project.workspace
			? project.workspace.client
			: project.client;
		const users = client ? client.users : [];
		return users.map((user) => {
			return {
				name: `${user.firstName} ${user.lastName}`,
				id: user,
			};
		});
	};

	const createReport = async () => {
		setStage("creation");

		// addNotification({
		// 	title: "Validating Responses",
		// 	text: "Verifying that your responses are good",
		// 	type: "info",
		// 	time: 5000,
		// });

		let noResponses = reportOptions
			.filter((option) => option.required)
			.filter((option) => {
				if (
					reportResponses[option.dataName] == undefined ||
					reportResponses[option.dataName] == null
				) {
					return option;
				}
			});

		if (noResponses.length) {
			setStage("options");
			Alert(
				"Uh Oh Something Doesn't Look Right",
				"All fields with an astrix (*) are required"
			);
			return;
		}

		let currentUser = user.id;
		let team = project.workspace
			? project.team.concat(project.workspace.team)
			: project.team;

		let author = manualAuthor
			? manualAuthor
			: team.filter((user) => user.teamMember.id === currentUser)[0];

		reportResponses.author = author;
		if (selectedReport.requiresReview) {
			reportResponses.reviewer = {
				teamMember: selectedUser,
				projectRole: "Peer Reviewer",
			};
		}

		reportResponses.addressedTo = addressedTo;
		reportResponses.project = project;

		// addNotification({
		// 	title: "Creating Report",
		// 	text: "Responses look good! We are creating the report now",
		// 	type: "success",
		// 	time: 5000,
		// });

		try {
			let response = await fetch(selectedReport.endPoint, {
				method: "POST", // *GET, POST, PUT, DELETE, etc.
				mode: "cors",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(reportResponses),
			});
			let data = await response.json();

			await setReportData(data);
			createReportData({
				variables: {
					input: {
						reportType: selectedReport.id,
						responses: JSON.stringify(reportResponses),
						project: project.id,
						reviewer: selectedUser.id,
						author: manualAuthor ? manualAuthor.teamMember.id : user.id,
						addressedTo: addressedTo.id,
						date: DateTime.now().toISO(),
					},
				},
			});
		} catch (error) {
			SentryLogger(error);
			Alert(
				"There was an error creating the report",
				"Please check to see if the project has an office assigned to it and the there is a client assigned to it"
			);
			if (noResponses.length) {
				setStage("options");
				Alert(
					"Uh Oh Something Doesn't Look Right",
					"All fields with an astrix (*) are required"
				);
				return;
			}
			setStage("options");
		}
	};

	// TODO code the create client user mutation

	const setOrCreateClientUser = async () => {
		if (!("id" in addressedTo)) {
			createUser({
				variables: {
					input: {
						...addressedTo,
						client: project.workspace
							? project.workspace.client.id
							: project.client.id,
					},
				},
			});
		}

		if (selectedReport.requiresReview) {
			setStage("reviewer");
		} else {
			setStage("options");
		}
	};

	if (reportTypesLoading || projectLoading) {
		return <LoadingScreen loadItem="Report Options" />;
	}

	if (!project.client || !project.office || !project.location) {
		let missingItems = [];
		if (!project.client) {
			missingItems.push("Client");
		}
		if (!project.office) {
			missingItems.push("Office");
		}
		if (!project.location) {
			missingItems.push("Office");
		}

		return (
			<Modal
				isOpen={true}
				closeRequest={() => {
					navigation.navigate("Project", { id: project.id });
				}}
				title="Missing Project Data"
				description={`In order for your report to generate correctly you must add the following to your project: ${missingItems.join(
					", "
				)}`}
				rightButton={{
					text: "Go to Project",
					onPress: () => {
						navigation.navigate("Project", { id: project.id });
					},
				}}
			/>
		);
	}
	return (
		<>
			<Breadcrumb className="mt-2">
				{project.workspace ? (
					<>
						<Crumb
							name={project.workspace.title}
							location="Project"
							params={{ id: project.workspace.id }}
						/>
					</>
				) : (
					<>
						<Crumb
							name={project.title}
							location="Project"
							params={{ id: project.id }}
						/>
						<Crumb
							name="Report Center"
							location={"Reporting Center"}
							params={{ id: project.id }}
						/>
						<Crumb
							name="New Report"
							location={"New Report"}
							params={{ id: project.id }}
						/>
					</>
				)}
			</Breadcrumb>
			<PageHeader
				title="New Report"
				goBack={{
					location: "Project",
					params: { id: project.id },
				}}
				description={
					reportOptions
						? "Fill out the field and click create"
						: "Select a report type "
				}
			/>
			<>
				{reportOptions ? (
					<ScrollView>
						<Card
							title={selectedReport.name}
							subheading={selectedReport.description}
							className="m-2"
						>
							{stage === "addressTo" && (
								<View className="space-y-2">
									<>
										{user.isAdmin && (
											<>
												<Select
													name="Project Author"
													info="As an admin user for SRP inField you can choose a project author to generate a report on their behalf. The user must be an admin or super admin in the project"
													placeholder={"Select Project Author"}
													onChange={(value) => {
														setAuthor(value.value);
													}}
													options={teamMembers
														.filter(
															(user) =>
																user.teamMember &&
																(user.isAdmin || user.isSuperAdmin === true)
														)

														.map((user) => {
															return {
																name: `${user.teamMember.firstName} ${user.teamMember.lastName}`,
																value: user,
															};
														})}
												/>
											</>
										)}
									</>

									<>
										{!newClientUser ? (
											<Select
												name="Address Report To"
												description={`Select Representative from ${
													project.workspace
														? project.workspace.client.name
														: project.client.name
												} to address the report to`}
												onChange={(value) => {
													setAddressedTo(value.id);
												}}
												options={getClientName(project)}
											/>
										) : (
											<View className="mt-1 w-full">
												{!newClientUser && (
													<Text>
														<Text className="font-medium">
															{project.workspace
																? project.workspace.client.name
																: project.client.name}
														</Text>{" "}
														does not have any representative associated with its
														organization.
													</Text>
												)}

												<Text className="text-gray-900 dark:text-white">
													Please enter the information of the representative you
													would like to address the report to at{" "}
													<Text className="font-medium">
														{project.workspace
															? project.workspace.client.name
															: project.client.name}
													</Text>
												</Text>
												<View className="w-full">
													<View className="">
														<Input
															label="First Name"
															type="text"
															className="mr-1 w-full"
															required
															onChange={(e) => {
																setAddressedTo({
																	...addressedTo,
																	firstName: e,
																});
															}}
														/>

														<Input
															label="Last Name"
															type="text"
															className="ml-1 w-full"
															required
															onChange={(e) => {
																setAddressedTo({
																	...addressedTo,
																	lastName: e,
																});
															}}
														/>

														<Input
															label="Email"
															type="text"
															className="mr-1 w-full"
															required
															onChange={(e) => {
																setAddressedTo({
																	...addressedTo,
																	email: e,
																});
															}}
														/>

														<Input
															label="Role"
															type="text"
															className="ml-1 w-full"
															onChange={(e) => {
																setAddressedTo({
																	...addressedTo,
																	role: e,
																});
															}}
														/>
													</View>
												</View>
											</View>
										)}
									</>

									<View>
										{!newClientUser && (
											<Button
												type="button"
												onPress={() => {
													setNewClientUser(true);
												}}
												icon={faPlus}
												text="New Representative"
											/>
										)}
									</View>

									<View className="flex flex-row w-full justify-between mt-4">
										<Button
											type="button"
											variant="gray"
											onPress={() => {
												if (!newClientUser) {
													setReportOptions(null);
													setSelectedReport(null);
												} else {
													setNewClientUser(false);
												}
											}}
											text={
												newClientUser
													? "Back to Representatives"
													: "Back to Report Library"
											}
										/>

										<Button
											type="submit"
											variant="indigo"
											onPress={() => {
												setOrCreateClientUser();
											}}
											text={
												newClientUser
													? "Create Representative and Continue"
													: "Continue"
											}
										/>
									</View>
								</View>
							)}

							{stage === "reviewer" && selectedReport.requiresReview && (
								<View>
									<View style={{ marginTop: 10 }}>
										<Select
											name="Reviewer"
											info="This report type requires a peer review"
											placeholder={"Select Peer Reviewer"}
											onChange={(value) => {
												setSelectedUser(value.id);
											}}
											options={reviewUsers.map((user) => {
												return {
													name: `${user.firstName} ${user.lastName}`,
													id: user,
												};
											})}
										/>
									</View>
									<View className="flex flex-row w-full justify-between mt-4">
										<Button
											variant="gray"
											onPress={() => {
												setStage("addressTo");
											}}
											text={"Back to Addressee"}
										/>

										<Button
											variant="indigo"
											onPress={() => setStage("options")}
											text="Continue"
										/>
									</View>
								</View>
							)}
							{(stage === "options" || stage === "creation") && (
								<ScrollView className="space-y-1">
									<>
										{reportOptions.map((option, index) => {
											switch (option.type) {
												case "sampleCollection":
													switch (option.name) {
														case "Asbestos":
															return (
																<View key={index}>
																	<Text>Asbestos Sample Collection</Text>
																	<View className="mt-1 w-full">
																		<Select
																			name={
																				"Select the asbestos sample collection*"
																			}
																			required={true}
																			onChange={(value) => {
																				reportResponses.asbestosSampleCollection =
																					value.value;
																			}}
																			options={project.sampleCollections
																				.filter(
																					(collection) =>
																						collection.type === "ASBESTOS"
																				)
																				.map((collection) => {
																					name: collection.title;
																					value: collection;
																				})}
																		/>
																	</View>
																</View>
															);
													}
												case "lab":
													return (
														<View key={index}>
															{labs ? (
																<>
																	<Select
																		name={"Select the lab used *"}
																		required={true}
																		onChange={(value) => {
																			reportResponses.lab = {
																				name: value.value.name,
																			};
																			setLabLocations(value.value.locations);
																		}}
																		options={labs.map((option) => {
																			return {
																				name: option.name,
																				value: option,
																			};
																		})}
																	/>
																	{labLocations && (
																		<Select
																			key={index}
																			name={"Select the lab location used *"}
																			required={true}
																			onChange={(value) => {
																				reportResponses.lab.location =
																					value.value;
																			}}
																			options={labLocations.map((option) => {
																				return {
																					name: option.name,
																					value: option,
																				};
																			})}
																		/>
																	)}
																</>
															) : (
																<Text>Loading Labs</Text>
															)}
														</View>
													);
												case "text":
													return (
														<Input
															label={`${option.name} ${
																option.required ? "*" : ""
															}`}
															key={index}
															type="text"
															class="input w-input"
															required={option.required}
															onChange={(e) => {
																reportResponses[option.dataName] = e;
															}}
														/>
													);
												case "select":
													return (
														<Select
															key={index}
															name={`${option.name} ${
																option.required ? "*" : ""
															}`}
															required={option.required}
															onChange={(value) => {
																reportResponses[option.dataName] = value.value;
															}}
															options={option.options.map((option) => {
																return {
																	name: `${option.text}`,
																	value: option.value,
																};
															})}
														/>
													);
												case "date":
													return (
														<Input
															label={`${option.name} ${
																option.required ? "*" : ""
															}`}
															key={index}
															type="date"
															class="input w-input"
															required={option.required}
															onChange={(e) => {
																reportResponses[option.dataName] = e;
															}}
														/>
													);

												default:
													break;
											}
										})}
									</>

									<View className="flex flex-row w-full justify-between mt-6">
										<Button
											variant="gray"
											onPress={() => {
												if (selectedReport.requiresReview) {
													setStage("reviewer");
												} else {
													setStage("addressTo");
												}
											}}
											class="button button-outline button-block w-button"
											text={
												selectedReport.requiresReview
													? "Back to Reviewer"
													: "Back to Addressee"
											}
										/>

										<Button
											variant="indigo"
											disabled={stage === "creation"}
											class={`button ${
												stage !== "creation" && "button-primary"
											} button-block w-button`}
											onPress={() => {
												createReport();
											}}
											text={
												stage === "creation"
													? "Creating Report"
													: "Create Report"
											}
										/>
									</View>
								</ScrollView>
							)}
						</Card>
					</ScrollView>
				) : (
					<>
						<CardContainer>
							{reportTypes.map((reportType, index) => {
								return (
									<Card
										key={index}
										title={reportType.name}
										subheading={reportType.category}
										content={
											<>
												<Text className="text-gray-900 dark:text-white">
													{reportType.description}
												</Text>
												<Button
													disabled={!reportType.active}
													onPress={() => {
														setSelectedReport(reportType);

														setReportOptions(JSON.parse(reportType.options));
													}}
													variant={reportType.active ? "indigo" : "gray"}
													text={
														reportType.active
															? "Select Report Type"
															: "Available Soon"
													}
												/>
											</>
										}
									/>
								);
							})}
						</CardContainer>
					</>
				)}
			</>
		</>
	);
};

NewReport.propTypes = {
	route: PropTypes.object,
};
