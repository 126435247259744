import React from "react";
import PropTypes from "prop-types";
import { ScrollView, View, Text } from "react-native";
import { DateTime } from "luxon";
import { useNavigation } from "@react-navigation/native";

// Components

import { PageHeader } from "../../../components/Header.js";
import Tabs, { Tab } from "../../../components/Tabs";
import { List } from "../../../components/List.js";
import { ListItem } from "../../../components/List.js";
import { addNotification } from "../../../components/Notification.js";
import OptionsMenu from "../../../components/OptionsMenu";
import SlideOver from "../../../components/Slideover";
import { Select } from "../../../components/Forms";
import DataContainer, { DataItem } from "../../../components/DataDisplay";
import LoadingScreen from "../../../components/LoadingScreen";
import { Alert } from "../../../components/Alert";

// Containers
import { EquipmentSlideOver } from "../../../containers/EquipmentSlideOver";

// Utils
import { getData, SentryLogger } from "../../../utils";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as equipmentGQL from "../../../graphql/equipment";
import * as userGQL from "../../../graphql/user";

// Icons

import {
	faToolbox,
	faArrowDownLeftAndArrowUpRightToCenter,
	faInfoCircle,
	faHistory,
	faFileAlt,
	faStickyNote,
	faPencilAlt,
	faPlus,
	faEdit,
} from "@fortawesome/pro-duotone-svg-icons";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb.js";
import { NoteSlideOver } from "../../../containers/NoteSlideOver";
import CardContainer, { Card } from "../../../components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

const tabs = [
	{ name: "Your Equipment", href: "#", icon: faToolbox },
	{
		name: "Transfer Request",
		href: "#",
		icon: faArrowDownLeftAndArrowUpRightToCenter,
	},
];

export function Equipment({}) {
	const navigation = useNavigation();
	const [currentEquipment, setCurrentEquipment] = React.useState(null);
	const [newOffice, setNewOffice] = React.useState(null);
	let [userData, setUserData] = React.useState({});
	let [user, setUser] = React.useState(null);
	let [currentTab, setCurrentTab] = React.useState(tabs[0].name);
	let [transfers, setTransfers] = React.useState(null);
	let [newTransferRequest, setNewTransferRequest] = React.useState({});
	let [newItem, setNewItem] = React.useState("");
	let [users, setUsers] = React.useState([]);
	let [userEquipment, setUserEquipment] = React.useState(null);

	React.useEffect(() => {
		const userInfo = getData("@storage_Key")
			.then((user) => {
				setUserData(user);
			})

			.catch((err) => {
				SentryLogger(err);
			});
		userInfo;
	}, []);

	const { loading: userLoading } = useQuery(gql(equipmentGQL.getUser), {
		variables: {
			id: userData.id,
		},
		onCompleted: (data) => {
			setTransfers([...data.getUser.transferRequests]);
			setUser(data.getUser);
		},
		onError: (error) => {
			Alert("There was an error getting your equipment");
			SentryLogger(JSON.stringify(error, null, 2));
		},
		pollInterval: 10000,
		fetchPolicy: "network and cache",
	});

	const { loading: usersLoading, refetch } = useQuery(gql(userGQL.listUsers), {
		variables: {
			limit: 10000,
		},
		onCompleted: (data) => {
			setUsers(data.listUsers.items);
			setCurrentTab(tabs[0].name);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
	});

	const { loading: equipmentLoading } = useQuery(
		gql(equipmentGQL.listEquipment),
		{
			variables: {
				limit: 10000,
			},
			onCompleted: (data) => {
				setUserEquipment(data.listEquipment.items);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);

	/**
	 * createEquipmentHistoryOperation
	 * @param {*} id - the id of the equipment that is being created
	 * creates a history document assigned to the newly crated equipment document
	 * which shows the office the equipment is assigned to
	 */

	const [createEquipmentHistoryOperation] = useMutation(
		gql(equipmentGQL.createEquipmentHistory),
		{
			onCompleted: () => {
				navigation.navigate("/app/equipment");
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert("Couldn't create Equipment History please try again");
			},
		}
	);

	const [createTransferRequest] = useMutation(
		gql(equipmentGQL.createTransferRequest),
		{
			onCompleted: () => {
				setNewItem(null);
				addNotification({
					title: "Equipment Transferred Successfully",
					text: "Equipment was successfully transferred!",
					type: "success",
					time: 5000,
				});
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert("Couldn't create transfer request please try again");
			},
		}
	);

	const [updateTransferRequest] = useMutation(
		gql(equipmentGQL.updateTransferRequest),
		{
			onCompleted: (data) => {
				if (data.updateTransferRequest.accepted) {
					addNotification({
						title: "Equipment Transferred Successfully",
						text: "Equipment was successfully transferred!",
						type: "success",
						time: 5000,
					});
				} else {
					addNotification({
						title: "Transfer Request Cancelled",
						text: "Transfer request was cancelled",
						type: "success",
						time: 5000,
					});
				}
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert("Couldn't update transfer request please try again");
			},
		}
	);

	const [updateEquipmentHistory] = useMutation(
		gql(equipmentGQL.updateEquipmentHistory),
		{
			onCompleted: () => {},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert("Couldn't update please try again");
			},
		}
	);

	const handleTransferRequest = async (
		accepted,
		transferID,
		equipmentID,
		historyID
	) => {
		await updateTransferRequest({
			variables: {
				input: {
					id: transferID,
					accepted: accepted,
				},
			},
		});

		if (accepted) {
			// update the history to check it out the equipment for the previous holder and make a new history for the new holder

			await updateEquipmentHistory({
				variables: {
					input: { id: historyID, checkIn: DateTime.now().toISO() },
				},
			});

			await createEquipmentHistoryOperation({
				variables: {
					input: {
						user: user.id,
						equipment: equipmentID,
						checkOut: DateTime.now().toISO(),
					},
				},
			});
		}
	};

	if (userLoading || usersLoading || equipmentLoading) {
		return <LoadingScreen loadItem={"Equipment"} />;
	}

	const navigateToEquipment = (id) => {
		navigation.navigate("SingleEquipment", {
			id: id,
		});
	};

	return (
		<>
			<Breadcrumb>
				<Crumb name="Equipment" location="Equipment" />
			</Breadcrumb>
			<PageHeader title="Equipment" openMenu />
			<Tabs>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							current={currentTab === tab.name ? true : false}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			<>
				{currentTab === "Your Equipment" && (
					<View className="px-2 pt-2">
						<List
							title="Your Equipment"
							headers={["Name", "Barcode", "Calibration Due"]}
							usesOptions
						>
							{userEquipment &&
								userEquipment
									.filter(
										(eq) =>
											// filtering the equipment that has a user in the history
											eq.history[0] &&
											eq.history[0].user &&
											eq.history[0].user.id &&
											eq.history[0].user.id === user.id
									)
									.map((eq, index) => {
										return (
											<ListItem
												key={index}
												options={
													<OptionsMenu
														options={[
															{
																name: "Create Transfer Request",
																icon: faPlus,
																onPress: () => {
																	setNewItem("transfer request");
																	setNewTransferRequest({
																		equipment: eq.id,
																		holder: user.id,
																	});
																},
															},
															{
																name: "Update Equipment",
																icon: faPencilAlt,
																onPress: () => {
																	setNewItem("update");
																	setCurrentEquipment(eq);
																	setNewOffice(eq);
																},
															},
														]}
													/>
												}
												items={[
													{
														content: (
															<View className="flex space-between">
																{eq.isKit ? (
																	<View className="flex flex-row mt-2">
																		<FontAwesomeIcon
																			icon={faToolbox}
																			className="text-gray-500 mr-2"
																		/>
																		<Text className="text-gray-500 ml-1">
																			Kit #
																		</Text>
																		<Text className="text-gray-500">
																			{eq.name}
																		</Text>
																	</View>
																) : (
																	<View className="flex ">
																		<Text className="text-lg text-black mr-2">
																			{eq.name}
																		</Text>
																		{eq.additionalComponents && (
																			<Text className="text-sm mr-2">
																				Additional Components:{" "}
																				{eq.additionalComponents}
																			</Text>
																		)}
																	</View>
																)}
															</View>
														),
														onPress: () => navigateToEquipment(eq.id),
													},
													{
														onPress: () => navigateToEquipment(eq.id),
														content: eq.barcode,
													},
													{
														onPress: () => navigateToEquipment(eq.id),
														content: eq.expirationDate
															? eq.expirationDate
															: "N/A",
													},
												]}
											/>
										);
									})}
						</List>
					</View>
				)}
			</>

			<>
				{currentTab === "Transfer Request" && (
					<View className="px-2 pt-2">
						<List
							title="Transfer Request"
							headers={["Name", "Barcode", "From"]}
							usesOptions
						>
							{transfers &&
								transfers
									.filter((transfer) => transfer.accepted === null)
									.map((request, index) => {
										return (
											<ListItem
												key={index}
												items={[
													{
														content: request.equipment.name,
													},
													{
														content: request.equipment.barcode,
													},
													{
														content: `${request.holder.firstName} ${request.holder.lastName}`,
													},
												]}
												buttons={[
													{
														name: "Accept",
														color: "blue",
														onClick: () => {
															handleTransferRequest(
																true,
																request.id,
																request.equipment.id,
																request.equipment.history[0].id
															);
														},
													},
													{
														name: "Reject",
														color: "red",
														onClick: () => {
															handleTransferRequest(
																false,
																request.id,
																request.equipment.id,
																request.equipment.history[0].id
															);
														},
													},
												]}
											/>
										);
									})}
						</List>
					</View>
				)}
			</>

			<EquipmentSlideOver
				isOpen={newItem === "update"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				type="update"
				//currentUser={user}
				currentEquipment={currentEquipment}
				currentHistory={newOffice}
			/>
			<SlideOver
				name="Transfer Request"
				description="Transfer equipment to another Team Member"
				isOpen={newItem === "transfer request"}
				closeRequest={() => setNewItem(null)}
				onSubmit={(e) => {
					e.preventDefault();
					createTransferRequest({
						variables: {
							input: newTransferRequest,
						},
					});
				}}
			>
				<Select
					name="User"
					options={
						users
							? users
									.filter((user) => user.isTeamMember || user.isContractor)
									.sort((a, b) => {
										if (a.firstName > b.firstName) return 1;
										if (a.firstName < b.firstName) return -1;
										return 0;
									})
									.map((user) => {
										return {
											name: `${user.firstName} ${user.lastName}`,
											id: user.id,
										};
									})
							: []
					}
					onChange={(value) =>
						setNewTransferRequest({
							...newTransferRequest,
							receiver: value.id,
						})
					}
				/>
			</SlideOver>
		</>
	);
}

const singleEquipmentTabs = [
	{ name: "Information", href: "#", icon: faInfoCircle },
	{ name: "History", href: "#", icon: faHistory },
	{ name: "Documents", href: "#", icon: faFileAlt },
	{ name: "Notes", href: "#", icon: faStickyNote },
];

export const SingleEquipment = ({ route }) => {
	const { id } = route.params;
	const navigation = useNavigation();
	const [newItem, setNewItem] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState(
		singleEquipmentTabs[0].name
	);
	const [equipment, setEquipment] = React.useState(null);
	const [notes, setNotes] = React.useState(null);
	const [noteToUpdate, setNoteToUpdate] = React.useState(null);

	/**
	 * getEquipment
	 * get equipment by it id and sets data in state using the setEquipment dispatch
	 */
	React.useEffect(() => {
		if (equipment && equipment.isKit) {
			singleEquipmentTabs.push({
				name: "Tools",
				href: "#",
				icon: faToolbox,
			});
		}
	}, [equipment]);

	const { loading: equipmentLoading, refetch } = useQuery(
		gql(equipmentGQL.getEquipment),
		{
			variables: {
				id: id,
			},
			onCompleted: (data) => {
				setEquipment(data.getEquipment);
				setNotes(data.getEquipment.notes);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				navigation.goBack();
			},
			fetchPolicy: "network and cache",
		}
	);

	const handleButtonPress = () => {
		switch (currentTab) {
			case "Notes":
				return [
					{
						name: "Add Note",
						icon: faPlus,
						onPress: () => setNewItem("newNote"),
					},
				];
			case "Information":
				return [
					{
						name: "Update",
						icon: faEdit,
						onPress: () => setNewItem("update"),
					},
				];
			default:
				break;
		}
	};

	if (equipmentLoading || !equipment) {
		return <LoadingScreen loadItem="Equipment" />;
	}

	return (
		<>
			<Breadcrumb>
				<Crumb name="Equipment" location="Equipment" />
				<Crumb
					name={equipment.name}
					location="SingleEquipment"
					params={{ id: equipment.id }}
				/>
			</Breadcrumb>
			<PageHeader
				title={equipment.name}
				goBack={{ location: "Equipment" }}
				buttons={handleButtonPress()}
			/>

			<Tabs>
				{singleEquipmentTabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							current={currentTab === tab.name ? true : false}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			{currentTab === "Information" && (
				<View className="px-2 pt-2">
					<DataContainer title="Equipment Information">
						<DataItem
							name="Name"
							value={equipment.name ? equipment.name : "No Name"}
						/>
						{equipment.isKit !== true && (
							<DataItem
								name="Additional Components"
								value={
									equipment.additionalComponents || "No Additional Components"
								}
							/>
						)}
						<DataItem
							name="Barcode"
							value={equipment.barcode ? equipment.barcode : "No Barcode"}
						/>
						<DataItem
							name="Serial Number"
							value={equipment.sn ? equipment.sn : "No Serial Number"}
						/>
						<DataItem
							name="Modal"
							value={equipment.model ? equipment.model : "No Modal"}
						/>
						<DataItem
							name="Next Calibration Date"
							value={
								equipment.expirationDate
									? equipment.expirationDate
									: "No Calibration Date Due"
							}
						/>
					</DataContainer>
					<EquipmentSlideOver
						isOpen={newItem === "update"}
						closeRequest={() => {
							setNewItem(null);
						}}
						onComplete={() => {
							setNewItem(null);
							refetch();
						}}
						type="update"
						//currentUser={user}
						currentEquipment={equipment}
						currentHistory={equipment.history[0]}
					/>
				</View>
			)}

			{currentTab === "History" && (
				<View className="px-2 pt-2">
					<DataContainer title="Equipment Information">
						<DataItem
							name="Name"
							value={equipment.name ? equipment.name : "No Name"}
						/>
						<DataItem
							name="Barcode"
							value={equipment.barcode ? equipment.barcode : "No Barcode"}
						/>
						<DataItem
							name="Serial Number"
							value={equipment.sn ? equipment.sn : "No Serial Number"}
						/>
						<DataItem
							name="Modal"
							value={equipment.modal ? equipment.modal : "No Modal"}
						/>
						<DataItem
							name="Next Calibration Date"
							value={
								equipment.expirationDate
									? equipment.expirationDate
									: "No Calibration Date Due"
							}
						/>
					</DataContainer>
				</View>
			)}

			{currentTab === "Notes" && (
				<ScrollView>
					{equipment.notes !== null ? (
						<CardContainer>
							{notes.map((note, index) => {
								return (
									<Card
										key={index}
										title={note.title}
										surTitle={`Created by ${note.user.firstName} ${note.user.lastName}`}
										subheading={note.description}
										content={DateTime.fromISO(note.date).toLocaleString()}
										options={
											<View
												style={{
													flexDirection: "row",
													justifyContent: "flex-end",
													alignItems: "center",
												}}
											>
												<OptionsMenu
													options={[
														{
															name: "Edit",
															icon: faEdit,
															onPress: () => {
																setNewItem("updateNote");
																setNoteToUpdate(note);
															},
														},
													]}
												/>
											</View>
										}
									/>
								);
							})}
						</CardContainer>
					) : (
						<Card
							title="No Notes"
							subheading="There are currenly no notes for this equipment, click the plus button to add a note."
						/>
					)}

					<NoteSlideOver
						isOpen={newItem === "newNote"}
						closeRequest={() => {
							setNewItem(null);
						}}
						onComplete={() => {
							setNewItem(null);
							refetch();
						}}
						type="create"
						equipment={equipment}
					/>
					<NoteSlideOver
						isOpen={newItem === "updateNote"}
						closeRequest={() => {
							setNewItem(null);
						}}
						onComplete={() => {
							setNewItem(null);
							refetch();
						}}
						type="update"
						currentNote={noteToUpdate}
					/>
				</ScrollView>
			)}

			{currentTab === "Tools" && (
				<ScrollView>
					{equipment.isKit !== null ? (
						<CardContainer>
							{equipment &&
								equipment.tools.map((tool, index) => {
									return (
										<Card
											key={index}
											title={tool.name}
											subheading={`Barcode: ${tool.barcode}`}
											content={`SN: ${tool.sn}`}
										/>
									);
								})}
						</CardContainer>
					) : (
						<Card
							title="No Tools"
							subheading="There are currenly equipment in this kit."
						/>
					)}

					<EquipmentSlideOver
						isOpen={newItem === "newEquipment"}
						closeRequest={() => {
							setNewItem(null);
						}}
						onComplete={() => {
							setNewItem(null);
						}}
						currentEquipment={{
							kit: equipment.id,
						}}
						type="create"
						nonKit={true}
					/>
				</ScrollView>
			)}
		</>
	);
};

SingleEquipment.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
	route: PropTypes.object,
};
