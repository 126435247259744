import { useNavigation } from "@react-navigation/native";
import aws from "aws-sdk";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";

// Components

import { Alert } from "../../components/Alert";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import Button from "../../components/Button";
import { Checkbox, FileUpload, Input, TextArea } from "../../components/Forms";
import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
import NoItems from "../../components/NoItems";

// GraphQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as emailGQL from "../../graphql/emails";
import * as emailsGQL from "../../graphql/emails";

// Utils

import { API_URL, awsConfig, getData } from "../../utils";

//Icons

import {
	faCalendar,
	faEnvelope,
	faEnvelopeOpen,
	faFile,
	faUser,
} from "@fortawesome/pro-duotone-svg-icons";

let config = new aws.Config(awsConfig);

let s3 = new aws.S3(config);

export const Email = ({ route }) => {
	const { id } = route.params;
	const user = getData("@storage_Key").then((user) => user);
	const [email, setEmail] = React.useState(null);
	const [showConfirm, setShowConfirm] = React.useState(false);

	/**
	 * @name getEmail
	 * @description Get email from the database
	 * @param {*} id the email id
	 * @returns {void}
	 */

	useQuery(gql(emailGQL.getEmail), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setEmail(data.getEmail);
		},

		fetchPolicy: "no-cache",
	});

	const sendEmail = (preview) => {
		fetch(`${API_URL}/emails/mass-email`, {
			method: "POST",
			mode: "cors",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				emailData: email,
				preview: preview ? user.email : null,
				to: email.sentTo,
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.status === 200) {
					Alert(res.message, "success");
				} else {
					Alert(res.message, "error");
				}
				updateEmail({
					variables: {
						input: {
							id: email.id,
							status: "sent",
							sentAt: DateTime.now().toISO(),
							sentBy: user.id,
						},
					},
				});
			});
	};

	/**
	 * @name updateEmail
	 * @description Update email in the database
	 * @param {*} input the input update object, MUST include id
	 * @returns {void}
	 * @author Connor Sampson
	 * @date 2022-02-21
	 */

	const [updateEmail] = useMutation(gql(emailGQL.updateEmail), {
		onCompleted: (data) => {
			setEmail(data.updateEmail);
			Alert("Email updated successfully");
		},

		onError: () => {
			Alert("Couldn't update email please try again");
		},
	});

	const uploadFile = async (file, featureIdx) => {
		let params = {
			Bucket: "srp-in-field",
			Key: `emails/${id}/${file.name}-${DateTime.now().toISO()}`,
			Body: file,
			ContentType: file.type,
			ACL: "public-read",
		};
		let upload = await s3.upload(params).promise();
		let features = JSON.parse(email.features);
		features[featureIdx].image = upload.Location;
		updateEmail({
			id,
			features: JSON.stringify(features),
		});
	};

	if (!email) return <LoadingScreen loadItem={"Email"} />;

	return (
		<View className="bg-white dark:bg-gray-900">
			<Breadcrumb>
				<Crumb name="Admin" location="Admin Projects" />
				<Crumb name="Emails" location="Emails" />
				<>
					<Crumb
						name={email.title ? email.title : "No Title"}
						link={`/admin/emails/${email.id}`}
					/>
				</>
			</Breadcrumb>
			<PageHeader
				openMenu
				title={email.title ? email.title : "No Title"}
				info={[
					{
						icon: faCalendar,
						text: email.createdAt
							? `Created At: ${DateTime.fromISO(
									email.createdAt
							  ).toLocaleString()}`
							: "",
					},
					{
						icon: faUser,
						text: `Created By: ${email.createdBy.firstName} ${email.createdBy.lastName}`,
					},
					email.updatedAt && {
						icon: faCalendar,
						text: `Updated At: ${DateTime.fromISO(
							email.updatedAt
						).toLocaleString()}`,
					},
				]}
				buttons={[
					{
						name: "Send Preview",
						icon: faFile,
						onPress: () => {
							sendEmail(true);
						},
					},
					{
						name: "Send Email",
						icon: faEnvelope,
						onPress: () => {
							setShowConfirm(true);
						},
					},
				]}
			/>

			<View className=" mx-8 space-y-3">
				<Text className="block text-sm font-medium text-gray-700 dark:text-white">
					Send To
				</Text>
				<View className="flex flex-row space-x-2 gap-2">
					<Checkbox
						checkedValue={
							email.sentTo && email.sentTo.split(",").includes("all")
						}
						label="All"
						onChange={(e) => {
							let sentTo = email.sentTo ? email.sentTo.split(",") : [];
							if (e) {
								sentTo.push("all");
							} else {
								sentTo = sentTo.filter((t) => t !== "all");
							}
							updateEmail({
								variables: {
									input: {
										id,
										sentTo: sentTo.toString(),
									},
								},
							});
						}}
					/>

					<Checkbox
						checkedValue={
							email.sentTo && email.sentTo.split(",").includes("team")
						}
						label="Team"
						onChange={(e) => {
							let sentTo = email.sentTo ? email.sentTo.split(",") : [];
							if (e) {
								sentTo.push("team");
							} else {
								sentTo = sentTo.filter((t) => t !== "team");
							}
							updateEmail({
								variables: {
									input: {
										id,
										sentTo: sentTo.toString(),
									},
								},
							});
						}}
					/>

					<Checkbox
						checkedValue={
							email.sentTo && email.sentTo.split(",").includes("subcontractors")
						}
						label="Subcontractors"
						onChange={(e) => {
							let sentTo = email.sentTo ? email.sentTo.split(",") : [];
							if (e) {
								sentTo.push("subcontractors");
							} else {
								sentTo = sentTo.filter((t) => t !== "subcontractors");
							}
							updateEmail({
								variables: {
									input: {
										id,
										sentTo: sentTo.toString(),
									},
								},
							});
						}}
					/>
					<Checkbox
						checkedValue={
							email.sentTo && email.sentTo.split(",").includes("clients")
						}
						label="Clients"
						onChange={(e) => {
							let sentTo = email.sentTo ? email.sentTo.split(",") : [];
							if (e) {
								sentTo.push("clients");
							} else {
								sentTo = sentTo.filter((t) => t !== "clients");
							}
							updateEmail({
								variables: {
									input: {
										id,
										sentTo: sentTo.toString(),
									},
								},
							});
						}}
					/>
				</View>

				<View>
					<Text className="block text-sm font-medium text-gray-700 dark:text-white">
						Main Image
					</Text>
					<FileUpload onFiles={() => {}} />
				</View>

				<Input
					label="Title"
					name="title"
					value={email.title}
					onChange={(e) => setEmail({ ...email, title: e })}
					onBlur={(e) =>
						updateEmail({
							variables: {
								input: {
									id: id,
									title: e,
								},
							},
						})
					}
					placeholder="Title of Email Here"
				/>

				<TextArea
					label="Body"
					name="body"
					value={email.body}
					onChange={(e) => setEmail({ ...email, body: e })}
					placeholder="Body of Email Here"
				/>
				<View>
					<View as="div" className="pt-6 ">
						<>
							<View className="text-lg">
								<View className="text-left w-full flex justify-between items-start text-gray-400">
									<Text className="font-medium text-gray-900">Sections</Text>
									<View className="ml-6 h-7 flex items-center"></View>
								</View>
							</View>
							<View as="dd" className="mt-2 pr-12">
								<Text className="font-medium">Section Items</Text>
								<Text className="text-gray-500">
									Enter email items such as features, important information, or
									call outs
								</Text>
								<View className="space-y-6">
									{email.features &&
										JSON.parse(email.features).map((feature, index) => (
											<View key={index} className="space-y-2" draggable>
												<View className="flex flex-col md:flex-row items-center justify-between ">
													<View className="flex-1">
														<Text className="block text-sm font-medium text-gray-700 dark:text-white">
															Feature Image
														</Text>
														{feature.image ? (
															<View>
																<TouchableOpacity
																	className="bg-red-500 p-2 absolute rounded-md w-auto m-2 cursor-pointer"
																	onPress={() => {
																		// delete feature.image;

																		let features = JSON.parse(email.features);
																		features[index].image = null;
																		updateEmail({
																			variables: {
																				input: {
																					id: id,
																					features: JSON.stringify(features),
																				},
																			},
																		});
																	}}
																>
																	<Text className="text-white font-medium text-xs uppercase">
																		Delete
																	</Text>
																</TouchableOpacity>

																<Image
																	className={
																		"w-full h-36 rounded-lg shadow-lg object-cover"
																	}
																	source={{ uri: feature.image }}
																	alt={feature.title}
																/>
															</View>
														) : (
															<FileUpload
																onFiles={(files) => {
																	uploadFile(files[0], index);
																}}
															/>
														)}
													</View>

													<View className="flex-2 ">
														<Input
															label="Feature Title"
															placeholder="Title of Feature"
															value={feature.title}
															onChange={(e) => {
																let features = JSON.parse(email.features);
																features[index].title = e;
																setEmail({
																	...email,
																	features: JSON.stringify(features),
																});
															}}
															onBlur={(e) => {
																let features = JSON.parse(email.features);
																features[index].title = e;
																updateEmail({
																	id,
																	features: JSON.stringify(features),
																});
															}}
														/>
														<TextArea
															label="Feature Body"
															value={feature.text}
															onChange={(e) => {
																let features = JSON.parse(email.features);
																features[index].text = e;
																setEmail({
																	...email,
																	features: JSON.stringify(features),
																});
															}}
															onBlur={(e) => {
																let features = JSON.parse(email.features);
																features[index].text = e;
																updateEmail({
																	variables: {
																		input: {
																			id: id,
																			features: JSON.stringify(features),
																		},
																	},
																});
															}}
														/>
													</View>
												</View>
												<Text
													className="text-red-500 text-right text-xs uppercase cursor-pointer"
													onPress={() => {
														let features = JSON.parse(email.features);
														features.splice(index, 1);
														setEmail({
															...email,
															features: JSON.stringify(features),
														});
														updateEmail({
															variables: {
																input: {
																	id: id,
																	features: JSON.stringify(features),
																},
															},
														});
													}}
												>
													Delete Section
												</Text>
											</View>
										))}
								</View>
								<View className="mt-2">
									<Button
										onPress={() => {
											let features = email.features
												? JSON.parse(email.features)
												: [];

											updateEmail({
												variables: {
													input: {
														id: id,
														features: JSON.stringify([
															...features,
															{
																title: "",
																text: "",
																image: JSON.stringify(email.features),
															},
														]),
													},
												},
											});
										}}
										text="Add New Section"
									/>
								</View>
							</View>
						</>
					</View>

					<View className="mt-10">
						<Button
							text="Save"
							onPress={() =>
								updateEmail({
									variables: {
										input: {
											id,
											title: email.title,
											body: email.body,
											features: email.features,
											updatedAt: DateTime.now().toISO(),
											sentTo: email.sentTo,
										},
									},
								})
							}
						/>
					</View>

					<Modal
						isOpen={showConfirm}
						title="Send Email"
						description="Are you sure you want to send this email?"
						rightButton={{
							text: "Send Email",
							onPress: () => {
								setShowConfirm(false);
								sendEmail();
							},
						}}
					/>
				</View>
			</View>
		</View>
	);
};

export const Emails = ({ history }) => {
	const user = getData("@storage_Key").then((user) => user);
	let [emails, setEmails] = React.useState([]);
	const navigation = useNavigation();

	/**
	 * @name listEmails
	 * @description List all emails from the database
	 * @param {*} nt the next token
	 * @returns {void}
	 * @author Connor Sampson
	 * @date 2022-02-21
	 */

	useQuery(gql(emailsGQL.listEmails), {
		onCompleted: (data) => {
			setEmails(data.listEmails.items);
		},
	});

	/**
	 * @name createEmail
	 * @description Create a new email and push user to single email page
	 * @returns {void}
	 * @author Connor Sampson
	 * @date 2022-02-21
	 */

	const [createEmail] = useMutation(gql(emailsGQL.createEmail), {
		onCompleted: (data) => {
			setEmails(data.createEmail);
			history.push(`/admin/emails/${data.createEmail.id}`);
		},
		onError: () => {
			Alert("Couldn't create email please try again");
		},
	});
	const navigateToEmail = (id) => {
		navigation.navigate("Email", { id });
	};

	if (!emails) {
		return <LoadingScreen loadItem="Emails" />;
	}

	return (
		<>
			<Breadcrumb>
				<Crumb name="Admin" location="Admin Projects" />
				<Crumb name="Emails" location="Emails" />
			</Breadcrumb>
			<PageHeader
				openMenu
				title="Emails"
				buttons={[
					{
						icon: faEnvelopeOpen,
						name: "New Email",
						onPress: () => {
							createEmail({
								variables: {
									input: {
										createdAt: DateTime.now().toISO(),
										createdBy: user.id,
									},
								},
							});
						},
					},
				]}
			/>
			<>
				{emails.length ? (
					<List headers={["Title", "Status", "Created At", "Sent At"]}>
						{emails.map((email) => (
							<ListItem
								key={email.id}
								items={[
									{
										content: email.title ? email.title : "No Title",
										onPress: () => navigateToEmail(email.id),
									},
									{
										content: email.status,
										onPress: `/admin/emails/${email.id}`,
									},
									{
										content: DateTime.fromISO(email.createdAt).toLocaleString({
											month: "long",
											day: "numeric",
											year: "numeric",
											hour: "numeric",
											minute: "numeric",
											timeZoneName: "short",
										}),

										onPress: `/admin/emails/${email.id}`,
									},
									{
										content: email.sentAt
											? DateTime.fromISO(email.sentAt).toLocaleString({
													month: "long",
													day: "numeric",
													year: "numeric",
													hour: "numeric",
													minute: "numeric",
													timeZoneName: "short",
											  })
											: "Not Sent",
										onPress: `/admin/emails/${email.id}`,
									},
								]}
							/>
						))}
					</List>
				) : (
					<NoItems
						surTitle="Currently No  Emails"
						title="No Emails"
						text="There are currently no emails in the system"
					/>
				)}
			</>
		</>
	);
};

Emails.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
};
Email.propTypes = {
	route: PropTypes.object,
};
