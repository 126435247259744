import React from "react";
import PropTypes from "prop-types";
import NoItems from "../../../components/NoItems";
import LoadingScreen from "../../../components/LoadingScreen";
import { PageHeader } from "../../../components/Header";
import Tabs, { Tab } from "../../../components/Tabs";
import { List, ListItem } from "../../../components/List";
import OptionsMenu from "../../../components/OptionsMenu";
import { useNavigation } from "@react-navigation/native";

// GQL

import { gql, useQuery } from "@apollo/client";
import * as queries from "../../../graphql/reports";
import {
	faBook,
	faFileSignature,
	faEye,
} from "@fortawesome/pro-duotone-svg-icons";

//Utils
import { getData, SentryLogger } from "../../../utils";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";

export default function ReportingCenter({}) {
	const navigation = useNavigation();
	let user = getData("@storage_Key").then((user) => user);
	let [reports, setReports] = React.useState(null);
	let [currentTab, setCurrentTab] = React.useState("Reports");
	let [tabs, setTabs] = React.useState([]);

	React.useEffect(() => {
		let _tabs = [{ name: "Reports", icon: faBook }];
		if (user.canReviewReports) {
			_tabs.push({ name: "Needs Your Approval", icon: faFileSignature });
		}
		setTabs(_tabs);
	}, []);

	/**
	 *
	 * @param {boolean} isReviewerQuery - determine to send query as author or reviewer
	 */

	const { loading: reportsLoading } = useQuery(gql(queries.listReport), {
		variables: {
			filter:
				currentTab === "Reports"
					? { author: { eq: user.id } }
					: { reviewer: { eq: user.id } },
		},
		onCompleted: (data) => {
			setReports(data.listReports.items);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
	});

	const navigateToReport = (id) => {
		navigation.navigate("Report", { id: id });
	};

	if (reportsLoading) {
		return <LoadingScreen loadItem="Reports" />;
	}
	return (
		<>
			<Breadcrumb>
				<Crumb name="Reporting Center" location="Reporting Center" />
			</Breadcrumb>
			<PageHeader title="Reports" openMenu />
			<Tabs>
				{tabs &&
					tabs.map((tab, index) => (
						<Tab
							key={index}
							name={tab.name}
							icon={tab.icon}
							current={currentTab === tab.name}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					))}
			</Tabs>

			{reports && reports.length ? (
				<List
					headers={[
						"Report Type",
						"Project Title",
						"Project Number",
						"Report Status",
						"Client",
					]}
					usesOptions
				>
					{reports.map((report, index) => {
						return (
							<ListItem
								key={index}
								items={[
									{
										content: report.reportType.name,

										onPress: () => navigateToReport(report.id),
									},
									{
										content: report.project?.title,
										onPress: () => navigateToReport(report.id),
									},
									{
										content: report.project?.number,
										onPress: () => navigateToReport(report.id),
									},
									{ content: report.status },
									{
										content:
											report.project?.client && report.project?.client.name
												? report.project?.client.name
												: "No Client Name",
									},
								]}
								options={
									<OptionsMenu
										options={[
											{
												icon: faEye,
												name: "View Report",
												onPress: () => {
													navigateToReport(report.id);
												},
											},
										]}
									/>
								}
							/>
						);
					})}
				</List>
			) : (
				<>
					{reports !== null && (
						<NoItems
							surTitle={"No Reports"}
							title="Currently No Reports"
							text="Reports you have created via projects will show here"
						/>
					)}
				</>
			)}
		</>
	);
}

ReportingCenter.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
};
