export const listBodies = /* GraphQL */ `
	query ListBodies(
		$filter: TableBodyFilterInput
		$limit: Int
		$nextToken: String
	) {
		listBodies(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				version {
					id
					version
					created
					sop {
						id
						title
					}
				}
				body
				language
			}
		}
	}
`;

export const createBody = /* GraphQL */ `
	mutation CreateBody($input: CreateBodyInput!) {
		createBody(input: $input) {
			id
		}
	}
`;

export const getBody = /* GraphQL */ `
	query GetBody($id: ID!) {
		getBody(id: $id) {
			id
			version {
				id
				version
				created
				sop {
					id
					title
				}
			}
			body
			language
		}
	}
`;

export const updateBody = /* GraphQL */ `
	mutation UpdateBody($input: UpdateBodyInput!) {
		updateBody(input: $input) {
			id
			version {
				id
				version
				created
			}
			body
			language
		}
	}
`;

export const deleteBody = /* GraphQL */ `
	mutation DeleteBody($input: DeleteBodyInput!) {
		deleteBody(input: $input) {
			id
		}
	}
`;
