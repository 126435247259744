import PropTypes from "prop-types";
import React from "react";
import { Image, Text, View } from "react-native";
import { getSignedUrl } from "../utils";

export default function User({ user, photoOnly, size = "sm" }) {
	const sizes = {
		tiny: { photo: "h-4 w-4", text: "text-xs" },
		xs: { photo: "h-6 w-6", text: "text-sm" },
		sm: { photo: "h-10 w-10", text: "text-lg" },
		md: { photo: "h-12 w-12", text: "txt-xl" },
		lg: { photo: "h-16 w-16", text: "text-2xl" },
	};

	return (
		<View className="flex flex-row items-center">
			<View className="flex-shrink-0">
				<>
					{user && user.profilePicture ? (
						<Image
							//className={`${sizes[size].photo} rounded-full`}
							source={{ uri: getSignedUrl(user.profilePicture) }}
							alt="profile"
						/>
					) : (
						<View className="flex">
							<View
								className={`${sizes[size].photo}  rounded-full bg-indigo-500 flex items-center justify-center `}
							>
								<Text
									testID="name-text"
									className={`text-white font-extrabold ${sizes[size].text} text-center leading-none`}
								>
									{user && user.firstName ? user.firstName[0] : ""}
									{user && user.lastName ? user.lastName[0] : ""}
								</Text>
							</View>
						</View>
					)}
				</>
			</View>
			<>
				{!photoOnly && user && (
					<View
						//testID="name-text"
						className="min-w-0 flex-1 m-1"
					>
						<Text
							testID="name-text"
							className="text-sm font-medium text-gray-900 dark:text-white"
						>
							{user.firstName} {user.lastName}
						</Text>
						<Text testID="role-text" className="text-sm text-gray-500">
							{user.role}
						</Text>
					</View>
				)}
			</>
		</View>
	);
}

User.propTypes = {
	user: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		profilePicture: PropTypes.string,
		role: PropTypes.string,
	}),
	photoOnly: PropTypes.bool,
	size: PropTypes.oneOf(["tiny", "xs", "sm", "md", "lg"]),
};
