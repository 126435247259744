export const createMFA = /* GraphQL */ `
	mutation CreateMFA($input: CreateMFAInput!) {
		createMFA(input: $input) {
			id
			user {
				id
				firstName
				lastName
				profilePicture
				email
				phone
				bio
				role
				isAdmin
				isPayrollAdmin
				isTimeAdmin
				isTimeOverrider
				isEquipmentManager
				isClerk
				isHR
				isTeamMember
				isContractor
				isLogistic
				isActive
				isSalary
				subAdmin
				linkExpiration
				canReviewReports
				department
				emailGeneral
				emailProjects
				emailReports
				emailEquipment
				appNotificationGeneral
				appNotificationProjects
				appNotificationReports
				appNotificationEquipment
				currentYearMaxAllotment
				companyStartDate
				subcontractor {
					id
					name
				}
				dailyDocuments {
					id
					date
					timeIn
					timeOut
					project {
						id
						title
					}
				}
				mfaDevices {
					id
					name
					device
					method
				}
			}
		}
	}
`;

export const deleteMFA = /* GraphQL */ `
	mutation DeleteMFA($input: DeleteMFAInput!) {
		deleteMFA(input: $input) {
			id
			user {
				id
				firstName
				lastName
				profilePicture
				email
				phone
				bio
				role
				isAdmin
				isPayrollAdmin
				isTimeAdmin
				isTimeOverrider
				isEquipmentManager
				isClerk
				isHR
				isTeamMember
				isContractor
				isLogistic
				isActive
				isSalary
				subAdmin
				linkExpiration
				canReviewReports
				department
				emailGeneral
				emailProjects
				emailReports
				emailEquipment
				appNotificationGeneral
				appNotificationProjects
				appNotificationReports
				appNotificationEquipment
				currentYearMaxAllotment
				companyStartDate
				subcontractor {
					id
					name
				}
				dailyDocuments {
					id
					date
					timeIn
					timeOut
					project {
						id
						title
					}
				}
				mfaDevices {
					id
					name
					device
					method
				}
			}
		}
	}
`;

export const confirmMFA = /* GraphQL */ `
	mutation ConfirmMFA($code: String!, $id: ID!) {
		confirmMFA(code: $code, id: $id) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isClerk
			isHR
			isTeamMember
			isContractor
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isLogistic
			isActive
			isSalary
			isSafetyUser
			subAdmin
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			currentYearMaxAllotment
			companyStartDate
			subcontractor {
				id
				name
			}
			isManager
			manager {
				id
				firstName
				lastName
				profilePicture
				role
			}
			isPayrollAdmin
			isTimeAdmin
			location {
				id
				name
			}
			apsEmployeeID
			mfaDevices {
				device
				method
				name
			}
		}
	}
`;
