import { useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { ScrollView, Text, View } from "react-native";

// Components

import { Alert } from "../../../../components/Alert";
import BreadCrumb, { Crumb } from "../../../../components/Breadcrumb";
import Button from "../../../../components/Button";
import { Card } from "../../../../components/Card";
import { Checkbox, Input } from "../../../../components/Forms";
import { PageHeader } from "../../../../components/Header";
import LoadingScreen from "../../../../components/LoadingScreen";
import Modal from "../../../../components/Modal";
import NoItems from "../../../../components/NoItems";
import Tabs, { Tab } from "../../../../components/Tabs";

// GQL
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as timeEntryGQL from "../../../../graphql/timeEntry";

//Util
import {
	calculateTimeDuration,
	getData,
	sendUpdateEmail,
	SentryLogger,
} from "../../../../utils";

// Icons
import {
	faClock,
	faHashtag,
	faIslandTropical,
	faPlus,
	faTrash,
	faEdit,
} from "@fortawesome/pro-duotone-svg-icons";

// Containers
import { TimeEntrySlideover } from "../../../../containers/TimeEntrySlideOver";
import { PTODataForUser } from "../../../FinanceHRCenter/PTOAnalysis";
import { Table } from "../../../../components/Table";

export const Employee = ({ route }) => {
	let tabs = [
		{
			icon: faClock,
			name: "Time Entries",
		},
		{
			icon: faIslandTropical,
			name: "PTO Requests",
		},
	];
	const { id } = route.params;
	const navigation = useNavigation();
	const [user, setUser] = React.useState(null);
	const [manager, setManager] = React.useState(null);
	const [startDate, setStartDate] = React.useState([]);
	const [timeEntries, setTimeEntries] = React.useState([]);
	const [currentTab, setCurrentTab] = React.useState(tabs[0].name);
	const [newItem, setNewItem] = React.useState(null);
	const [timeDeletion, setTimeDeletion] = React.useState(null);
	const [updatedTimeEntry, setUpdatedTimeEntry] = React.useState({});
	let [endDate, setEndDate] = React.useState([]);
	const [userPTOData, setUserPTOData] = React.useState(null);
	const [buttonAction, setButtonAction] = React.useState(null);

	const [listTimeBetweenDates] = useLazyQuery(
		gql(timeEntryGQL.listTimeEntriesBetweenDates),
		{
			onCompleted: (data) => {
				setTimeEntries([...data.listTimeEntriesBetweenDates.items]);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
			fetchPolicy: "cache-and-network",
		}
	);

	let [deleteTimeEntry] = useMutation(gql(timeEntryGQL.deleteTimeEntry), {
		onCompleted: (data) => {
			setTimeDeletion({});
			setNewItem(null);
			refetch({ variables: { id: id } });

			if (data.deleteTimeEntry.approved) {
				const previousTimeIn = DateTime.fromISO(
					data.deleteTimeEntry.timeIn
				).toFormat("h:mm a LL/dd/yyyy");
				const previousTimeOut = DateTime.fromISO(
					data.deleteTimeEntry.timeOut
				).toFormat("h:mm a LL/dd/yyyy");
				const updatedTimeIn = "Deleted";
				const updatedTimeOut = "Deleted";
				sendUpdateEmail(
					data.deleteTimeEntry.project.title,
					data.deleteTimeEntry.user,
					"Deleted by authorized Time Overrider",
					data.deleteTimeEntry.approvedBy,
					previousTimeIn,
					previousTimeOut,
					updatedTimeIn,
					updatedTimeOut
				);
			}
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			setNewItem(null);
		},
	});

	// Mutations and Queries

	const [updateTimeEntry] = useMutation(gql(timeEntryGQL.updateTimeEntry), {
		onCompleted: (data) => {
			let timeEntry = data.updateTimeEntry;

			const previousTimeIn = DateTime.fromISO(timeEntry.timeIn).toFormat(
				"h:mm a LL/dd/yyyy"
			);
			const previousTimeOut = DateTime.fromISO(timeEntry.timeOut).toFormat(
				"h:mm a LL/dd/yyyy"
			);
			setNewItem(null);
			setUpdatedTimeEntry({});
			refetch();

			// Conditional check to see if the time entry is approved or not and if the email should be sent

			if (!timeEntry.approved) {
				const updatedTimeIn = "No Change";
				const updatedTimeOut = "No Change";

				sendUpdateEmail(
					timeEntry.project.title,
					timeEntry.user,
					"Un-approved by authorized Time Overrider",
					user,
					previousTimeIn,
					previousTimeOut,
					updatedTimeIn,
					updatedTimeOut
				);
			}
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("Couldn't update time entry please try again");
		},
	});

	const { refetch } = useQuery(gql(timeEntryGQL.getUserProjectsAndTime), {
		variables: {
			id: id,
		},
		onCompleted: async (data) => {
			const manager = await getData("@storage_Key").then((user) => user);

			if (data.getUser.manager.id !== manager.id) {
				navigation.goBack();
			}

			setUser(data.getUser);
			setTimeEntries([...data.getUser.timeEntries]);
			setManager(data.getUser.manager);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	const getSortedProjects = (projects) => {
		let sortedProjects = projects
			.filter((pc) => pc.project !== null)
			.filter((pc) => pc.project.stage !== "Complete")
			.sort((a, b) => {
				if (a.project.title > b.project.title) return 1;
				if (a.project.title < b.project.title) return -1;
				return 0;
			});

		sortedProjects.unshift({
			project: {
				id: "0",
				title: "SRP Environmental",
			},
		});

		return sortedProjects.map((pc) => {
			if (pc.project) {
				return {
					title: pc.project.title,
					id: pc.project.id,
					disabled: false,
				};
			}
		});
	};

	const handleButtonClick = () => {
		switch (currentTab) {
			case "Time Entries":
				setNewItem("newTimeEntry");
				break;
			case "PTO Requests":
				setButtonAction("createPTO");
				break;
			default:
				break;
		}
	};

	const getButtonText = () => {
		switch (currentTab) {
			case "Time Entries":
				return "Create Time Entry";
			case "PTO Requests":
				return "Create and Approve PTO";
			default:
				break;
		}
	};

	/**
	 * years
	 * generates all the years from a given start year
	 * @param {string} years - a 4 digit year (YYYY)
	 * @returns {array} - the array of years
	 */

	if (!user) {
		return <LoadingScreen loadItem="Employee" />;
	}

	return (
		<>
			<BreadCrumb>
				<Crumb name="Time Clock" location={"Time Clock"} />
				<Crumb name="Manager Center" location={"Manager Center"} />
				<Crumb
					name={`${user.firstName} ${user.lastName}`}
					location="Employee"
					params={{ id: id }}
				/>
			</BreadCrumb>
			<PageHeader
				goBack={{ location: "Manager Center" }}
				title={`${user.firstName} ${user.lastName}`}
				description={`${user.role || "No Role"}`}
				buttons={[
					{
						name: getButtonText(),
						icon: faPlus,
						onPress: () => handleButtonClick(),
					},
				]}
				info={
					userPTOData
						? [
								{
									text: `Current PTO ${
										userPTOData?.availablePTO || "0"
									} hour(s)`,
									icon: faHashtag,
								},
								{
									text: `Available PTO For Advancement ${
										userPTOData?.advancableAmount || "0"
									} hour(s)`,
									icon: faHashtag,
								},
						  ]
						: []
				}
			/>
			<Tabs
				currentValue={currentTab}
				onChange={(value) => {
					setCurrentTab(value);
				}}
			>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							current={currentTab === tab.name ? true : false}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			{currentTab === "Time Entries" && (
				<ScrollView>
					<Card
						title={`Time Entries for ${user.firstName} ${user.lastName} `}
						content={
							<View className="flex flex-col m-2">
								<View className="grid grid-cols-3 gap-2 mb-2">
									<Input
										name="Start Date"
										label="Start Date"
										type="date"
										onChange={(e) => {
											setStartDate(e.target.value);
										}}
									/>
									<Input
										name="End Date"
										label="End Date"
										type="date"
										onChange={(e) => {
											setEndDate(e.target.value);
										}}
									/>
									<Button
										onPress={() => {
											endDate = DateTime.fromISO(endDate)
												.plus({ day: 1 })
												.toISODate();

											listTimeBetweenDates({
												variables: {
													user: user.id,
													startDate: startDate,
													endDate: endDate,
												},
											});
										}}
										text="Update Results"
									/>
								</View>
								<View className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<View className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
										{timeEntries !== null ? (
											<>
												{timeEntries.length ? (
													<View className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
														<Table
															title={"Time Entries"}
															headers={[
																"Project",
																"Time In",
																"Time Out",
																"Total",
																"Approved",
															]}
															data={
																timeEntries &&
																timeEntries.length &&
																timeEntries
																	.sort((a, b) => {
																		if (a.timeIn < b.timeIn) return 1;
																		if (a.timeIn > b.timeIn) return -1;
																		return 0;
																	})
																	.map((entry) => {
																		const getTimeEntryOption = () => {
																			if (
																				!entry.approved ||
																				manager.isTimeOverrider === true
																			) {
																				return [
																					{
																						name: "Delete",
																						onPress: () => {
																							setNewItem("delete");
																							setTimeDeletion(entry);
																						},
																						icon: faTrash,
																					},
																					{
																						name: "Edit",
																						onPress: () => {
																							setNewItem("edit");
																							setUpdatedTimeEntry(entry);
																						},
																						icon: faEdit,
																					},
																				];
																			} else {
																				return null;
																			}
																		};

																		return {
																			options: getTimeEntryOption(),
																			cells: [
																				{
																					content: entry.project
																						? entry.project.title
																						: "No Project",
																				},
																				{
																					content: DateTime.fromISO(
																						entry.timeIn,
																						{
																							zone: DateTime.fromISO(
																								entry.timeIn
																							).zone,
																						}
																					).toFormat(
																						"EEEE, MMMM dd, yyyy h:mm a ZZZZ"
																					),
																				},
																				{
																					content: entry.timeOut
																						? DateTime.fromISO(entry.timeOut, {
																								zone: DateTime.fromISO(
																									entry.timeOut
																								).zone,
																						  }).toFormat(
																								"EEEE, MMMM dd, yyyy h:mm a ZZZZ"
																						  )
																						: "Clocked In",
																				},
																				{
																					content:
																						entry.timeIn && entry.timeOut
																							? calculateTimeDuration(
																									entry.timeIn,
																									entry.timeOut
																							  )
																							: "Clocked In",
																				},
																				{
																					content: (
																						<Checkbox
																							checkedValue={
																								entry && entry.approved
																									? true
																									: false
																							}
																							disabled={
																								entry.approved &&
																								!manager.isTimeOverrider
																							}
																							onChange={() => {
																								updateTimeEntry({
																									variables: {
																										input: {
																											id: entry && entry.id,
																											approved: !entry.approved
																												? true
																												: false,
																											approvedBy: user.id,
																											approvedAt:
																												DateTime.now().toISO(),
																										},
																									},
																								});
																							}}
																						/>
																					),
																				},
																			],
																		};
																	})
															}
														/>
													</View>
												) : (
													<NoItems
														surTitle="No Time Entries"
														title={`${user.firstName} does not have any time entries for this time period`}
														text="Please update the month and or year to find other time entires"
													/>
												)}
											</>
										) : (
											<Text className="text-xl text-indigo-500 font-bold text-center animate-pulse">
												Loading Time Entries
											</Text>
										)}
									</View>
								</View>
							</View>
						}
					/>
				</ScrollView>
			)}
			{currentTab === "PTO Requests" && (
				<PTODataForUser
					userID={id}
					HRView={false}
					buttonAction={buttonAction}
					onClearButtonAction={() => setNewItem(null)}
					onUserDataRetrieved={(data) => {
						setUserPTOData(data);
					}}
				/>
			)}

			<TimeEntrySlideover
				isOpen={newItem === "newTimeEntry"}
				closeRequest={() => setNewItem(true)}
				onComplete={() => {
					refetch({ variables: { id: id } });
					setNewItem(null);
				}}
				currentTimeEntry={{
					user: user.id,
				}}
				type="create"
				projects={getSortedProjects(user.projects)}
			/>
			<TimeEntrySlideover
				isOpen={newItem === "edit"}
				closeRequest={() => setNewItem(true)}
				onComplete={() => {
					refetch({ variables: { id: id } });
					setNewItem(null);
				}}
				type="update"
				projects={getSortedProjects(user.projects)}
				currentTimeEntry={{
					id: updatedTimeEntry.id,
					approved: updatedTimeEntry.approved,
					project: updatedTimeEntry.project,
					timeOut: updatedTimeEntry.timeOut,
					timeIn: updatedTimeEntry.timeIn,
					isMobilizing: updatedTimeEntry.isMobilizing,
					isDemobilizing: updatedTimeEntry.isDemobilizing,
					isStandby: updatedTimeEntry.isStandby,
				}}
				defaultValues={{
					project: updatedTimeEntry.project
						? updatedTimeEntry.project.title
						: "",
				}}
			/>
			{/* confirm delete */}
			<Modal
				title="Delete Time Entry"
				description="Are you sure you want to delete this time entry? This is permanent and cannot be undone and will also delete any Daily Sheet associated with this time entry."
				isOpen={newItem === "delete"}
				onClose={() => setNewItem(true)}
				rightButton={{
					text: "Delete Time Entry",
					onClick: () => {
						deleteTimeEntry({
							variables: {
								input: {
									id: timeDeletion.id,
								},
							},
						});
					},
				}}
			></Modal>
		</>
	);
};

Employee.propTypes = {
	route: PropTypes.object,
};
