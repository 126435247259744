import { useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { Pressable, ScrollView, Text, View } from "react-native";
import Toast from "react-native-toast-message";

// Components
import { Alert } from "../../components/Alert";
import Breadcrumbs, { Crumb } from "../../components/Breadcrumb";
import { Checkbox, Input, Select } from "../../components/Forms";
import { PageHeader } from "../../components/Header";
import NoItems from "../../components/NoItems";
import { Table } from "../../components/Table";

// GQL
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as timeEntryGQL from "../../graphql/timeEntry";

//Utils
import { getData, SentryLogger } from "../../utils";

// icons
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";

export function MultiTimeEntries({ route }) {
	const { id } = route.params;
	const navigation = useNavigation();
	const [user, setUser] = React.useState({});
	const [timeEntries, setTimeEntries] = React.useState([{}]);
	const [projects, setProjects] = React.useState([]);

	// Leave this
	React.useEffect(() => {
		const userInfo = async () => {
			await getData("@storage_Key")
				.then((user) => {
					if (!user.isTimeAdmin) {
						return navigation.goBack();
					}
					setUser(user);
					getUser({
						variables: {
							id: user.id,
						},
					});
				})

				.catch((err) => {
					SentryLogger(err);
				});
		};

		userInfo();
	}, []);

	const [getUser] = useLazyQuery(gql(timeEntryGQL.getUserProjects), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setUser(data.getUser);
			setProjects([...data.getUser.projects]);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("We couldn't get your time entries. Please try again", error);
		},
		fetchPolicy: "cache-and-network",
	});

	const [bulkCreateTimeEntries] = useMutation(
		gql(timeEntryGQL.bulkCreateTimeEntries),
		{
			onCompleted: () => {
				Toast.show({
					type: "success",
					text1: "Successfully Created",
					text2: "The multiple time entries have been created",
				});
				setTimeEntries([]);
				navigation.navigate("User Time", { id: id });
			},
			onError: () => {
				Toast.show({
					type: "error",
					text1: "Something Went Wrong",
					text2: "There was an error creating the time entries",
				});
			},
		}
	);

	if (!user) return null;

	const getSortedProjects = (projects) => {
		if (!projects) return [];

		let sortedProjects = projects
			.filter((pc) => pc.project !== null)
			.filter((pc) => pc.project.stage !== "Complete")
			.sort((a, b) => {
				if (a.project.title > b.project.title) return 1;
				if (a.project.title < b.project.title) return -1;
				return 0;
			});

		sortedProjects.unshift({
			project: {
				id: "0",
				title: "SRP Environmental",
				isLargeLoss: false,
			},
		});

		return sortedProjects.map((pc, index) => {
			if (pc.project) {
				return {
					key: index,
					name: pc.project.title,
					id: pc.project.id,
					isLargeLoss: pc.project.isLargeLoss,
					disabled: false,
				};
			}
		});
	};

	const handleOnChange = (value, key, index) => {
		let newTimeEntries = timeEntries;
		newTimeEntries[index][key] = value;
		setTimeEntries(() => newTimeEntries);
	};

	return (
		<>
			<Breadcrumbs>
				<Crumb
					name="Finance and HR Center"
					location={"Finance and HR Center"}
				/>
				<Crumb name="Time Analysis" location={"Time Analysis"} />
			</Breadcrumbs>
			<PageHeader
				title={user.fullName || "Loading User..."}
				description="Add Multiple Time Entries"
			/>

			<ScrollView>
				<View className="px-2 pt-2">
					<View className="w-full flex flex-col m-2">
						{timeEntries.length === 0 ? (
							<NoItems
								surTitle="No Time Entries"
								title="No Time Entries"
								text="You have no time entries for the selected date range. Please adjust the date ranges above"
							/>
						) : (
							<Table
								headers={["Project", "Time In", "Time Out", "Approved"]}
								usesOptions
								form
								data={timeEntries.map((entry, index) => ({
									options: [
										{
											name: "Delete",
											icon: faTrash,
											onPress: () => {
												setTimeEntries((prev) =>
													prev.filter((_, i) => i !== index)
												);
											},
										},
									],
									cells: [
										{
											content: (
												<Select
													name="Project"
													options={getSortedProjects(projects)}
													onChange={(value) =>
														handleOnChange(value.id, "project", index)
													}
												/>
											),
										},

										{
											content: (
												<Input
													label="Time In"
													type="datetime-local"
													onChange={(value) =>
														handleOnChange(value, "timeIn", index)
													}
												/>
											),
										},

										{
											content: (
												<Input
													label="Time Out"
													type="datetime-local"
													onChange={(value) =>
														handleOnChange(value, "timeOut", index)
													}
												/>
											),
										},
										{
											content: (
												<Checkbox
													label="Approved"
													onChange={(value) =>
														handleOnChange(value, "approved", index)
													}
												/>
											),
										},
									],
								}))}
							/>
						)}
					</View>
				</View>
				<View className="w-full flex flex-row justify-between items-center  m-2 px-8 ">
					<Pressable
						className="border-gray-300 border-2 p-4  rounded-lg w-1/3"
						onPress={() => setTimeEntries((prev) => [...prev, {}])}
					>
						<Text className="dark:text-white"> Add Entry </Text>
					</Pressable>
					<Pressable
						className="border-indigo-600 border-2 bg-indigo-500 p-4  rounded-lg w-1/3"
						onPress={() => {
							const invalidEntries = timeEntries.filter(
								(entry) => !entry.project || !entry.timeIn
							);

							for (let index = 0; index < timeEntries.length; index++) {
								// check if time is more than 24 hours

								if (timeEntries[index].timeIn && timeEntries[index].timeOut) {
									let timeDuration = DateTime.fromISO(
										timeEntries[index].timeOut
									).diff(DateTime.fromISO(timeEntries[index].timeIn), [
										"hours",
										"minutes",
									]);

									if (timeDuration.hours >= 24 && timeDuration.minutes >= 0) {
										Alert("Time duration cannot be more than 24 hours");
										return;
									}

									// hour and minutes less than 0

									if (timeDuration.hours <= 0 && timeDuration.minutes <= 0) {
										Alert("Time duration cannot be less than 0 minutes");
										return;
									}
								}
							}

							if (invalidEntries.length) {
								Toast.show({
									type: "error",
									text1: "All entries must have a project and time in",
								});
							} else {
								bulkCreateTimeEntries({
									variables: {
										input: timeEntries,
									},
								});
							}
						}}
					>
						<Text className="text-white">Save</Text>
					</Pressable>
				</View>
			</ScrollView>
		</>
	);
}

MultiTimeEntries.propTypes = {
	route: PropTypes.object,
};
