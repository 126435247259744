import React from "react";

// Navigation Components
import { useNavigation } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

// Utils

import { getData } from "../utils";

// Screens

import { FinishRegistration } from "../views/Auth/FinishRegistration";
import { ForgotPassword } from "../views/Auth/ForgotPassword";
import { Register } from "../views/Auth/Register";
import { SignIn } from "../views/Auth/SignIn";

export const AuthLayout = () => {
	const navigation = useNavigation();
	React.useEffect(() => {
		// get user and redirect if logged in
		const getLocalUserDetails = async () => {
			await getData("@storage_Key").then((user) => {
				if (user !== "null" && user !== null && user !== undefined) {
					return navigation.navigate("App");
				}
			});
		};
		getLocalUserDetails();
	}, []);

	const Stack = createNativeStackNavigator();

	return (
		<Stack.Navigator>
			<Stack.Screen
				name="SignIn"
				component={SignIn}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="ForgotPassword"
				component={ForgotPassword}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="Register"
				component={Register}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="FinishRegistration"
				component={FinishRegistration}
				options={{ headerShown: false }}
			/>
		</Stack.Navigator>
	);
};
