import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";

// Components
import { Input, Switch } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation } from "@apollo/client";
import * as bodyGQL from "../graphql/admin/body.js";
import * as versionGQL from "../graphql/admin/version.js";

export const VersionSlideOver = ({
	type = "create",
	currentVersion,
	currentSOP,
	isOpen,
	closeRequest = () => {},
	onComplete,
}) => {
	const [newVersion, setNewVersion] = React.useState({});
	const [sopData, setSopData] = React.useState({});

	React.useEffect(() => {
		if (currentVersion) {
			setNewVersion(currentVersion);
		}
	}, [currentVersion]);

	React.useEffect(() => {
		if (currentSOP) {
			setSopData(currentSOP);
		}
	}, [currentSOP]);

	const [createVersion] = useMutation(gql(versionGQL.createVersion), {
		onCompleted: async (data) => {
			await createBody({
				variables: {
					input: {
						version: data.createVersion.id,
						// body: newVersion.body,
						body: "test",
						language: "EN",
					},
				},
			});

			onComplete(data.createVersion);
		},
		onError: () => {
			alert("Couldn't create your Version please try again");
		},
	});
	const [createBody] = useMutation(gql(bodyGQL.createBody), {
		onCompleted: (data) => {
			onComplete(data.createBody);
		},
		onError: () => {
			alert("Couldn't create your body please try again");
		},
	});

	const [updateVersion] = useMutation(gql(versionGQL.updateVersion), {
		onCompleted: async () => {},
		onError: () => {
			alert("Couldn't update your Version please try again");
		},
	});

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "New Version" : "Edit Version"}
			description={
				type === "create" ? "Initial Version will be 1.0.0 " : "Edit Version"
			}
			closeRequest={() => closeRequest()}
			onSubmit={() => {
				let input = {
					created: DateTime.now().toISO(),
					version: newVersion.version,
					sop: sopData.id,
					public: newVersion.public,
				};
				if (type === "create") {
					//add in any necessary fields
					createVersion({ variables: { input: input } });
				} else {
					updateVersion({
						variables: {
							input: {
								id: newVersion.id,
								version: newVersion.version,
							},
						},
					});
				}
			}}
			buttonRightText={type === "create" ? "Create" : "Update"}
		>
			<Input
				type="text"
				class="w-input"
				required
				label="Version"
				placeholder={newVersion ? newVersion.version : ""}
				onChange={(e) => {
					setNewVersion({
						...newVersion,
						version: e,
					});
				}}
			/>
			<Switch
				label={"Available to Public"}
				value={newVersion.public === true ? true : false}
				// defaultValue={newVersion.public}
				onChange={(value) => {
					setNewVersion({ ...newVersion, public: value });
				}}
			/>
		</SlideOver>
	);
};
VersionSlideOver.propTypes = {
	route: PropTypes.object,
	type: PropTypes.string,
	currentVersion: PropTypes.object,
	currentSOP: PropTypes.object,
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func,
	onComplete: PropTypes.func,
};
