import * as Linking from "expo-linking";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { ScrollView, Text, View, Switch } from "react-native";
import * as Animatable from "react-native-animatable";
import Accordion from "react-native-collapsible/Accordion";
import Toast from "react-native-toast-message";

// Components
import Modal from "../../../components/Modal";
import NoItems from "../../../components/NoItems";
import { Table } from "../../../components/Table";

// Containers
import { ExpenseSlideOver } from "../../../containers/ExpenseSlideOver";

// Icons
import {
	faFileArrowDown,
	faPen,
	faTrash,
} from "@fortawesome/pro-duotone-svg-icons";

// GraphQL
import { gql, useMutation } from "@apollo/client";
import * as expenseGQL from "../../../graphql/clerk/expense";
import { SentryLogger } from "../../../utils";

export const ExpensesSummary = ({
	expenseTypes,
	project,
	billingPeriod,
	billingPhase,
	refetch,
	user,
}) => {
	const [newItem, setNewItem] = React.useState(null);
	const [updateType, setUpdateType] = React.useState({});
	const [deleteItem, setDeleteItem] = React.useState({});
	const [activeSections, setActiveSections] = React.useState([]);
	const [group, setGroup] = React.useState(true);

	const [deleteExpense] = useMutation(gql(expenseGQL.deleteExpense), {
		onCompleted: (data) => {
			Toast.show({
				type: "success",
				text1: "Expense Deleted",
				text2: `The expense in the with amount ${data.deleteExpense.amount} has been deleted`,
			});

			setNewItem(null);
			setDeleteItem({});
			refetch();
		},
		onError: (error) => {
			Alert("Whoops we couldn't delete the expense", error.message);
			SentryLogger(error);
		},
	});

	const _updateSections = (activeSections) => {
		setActiveSections(activeSections);
	};

	const headers = group
		? ["Amount", "Date", "User", "Description"]
		: ["Amount", "Date", "User", "Description", "Type"];

	const getRowData = (expense) => {
		let cells = [
			{
				content: `$${expense.amount}`,
			},
			{
				content: DateTime.fromISO(expense.date).toFormat("MMMM dd, yyyy"),
			},
			{
				content: expense.user ? expense.user.fullName : "No User Assigned",
			},
			{
				content: expense.description,
			},
		];

		if (!group) {
			if (
				expense.expenseType &&
				expenseTypes.find((type) => type.id === expense.expenseType.id)
			) {
				cells.push({
					content: expense.expenseType.category.name,
				});
			} else {
				cells.push({
					content: "No Type",
				});
			}
		}

		return {
			cells: cells,
			options: [
				{
					name: "Download Receipt",
					icon: faFileArrowDown,
					onPress: () => {
						Linking.openURL(expense.receipt);
					},
				},
				{
					name: "Edit Expense",
					icon: faPen,
					onPress: () => {
						setNewItem("editExpense");
						setUpdateType(expense);
					},
				},
				{
					name: "Delete Expense",
					icon: faTrash,
					onPress: () => {
						if (
							!user.isSuperClerk &&
							(!project.clerk || user.id !== project.clerk.id)
						) {
							Toast.show({
								type: "error",
								text1: "You don't have permission to delete expenses",
								text2:
									"You need to be the clerk for this project or a super clerk.",
							});
						} else {
							setNewItem("deleteExpense");
							setDeleteItem(expense);
						}
					},
				},
			],
		};
	};

	const getExpensesByType = () => {
		if (!project) return [];
		if (!expenseTypes || !expenseTypes.length) return [];

		let expenses = [];

		if (!billingPhase) {
			expenses = billingPeriod
				? project.expenses.filter((expense) => {
						let date = DateTime.fromISO(expense.date).toISODate();
						if (
							date >= billingPeriod.startDate &&
							date <= billingPeriod.endDate
						) {
							return expense;
						}
				  })
				: project.expenses;
		} else {
			expenses = billingPhase.expenses;
		}

		return expenseTypes.map((expenseType) => {
			return {
				type: expenseType,
				expenses: expenses
					.filter((expense) => expenseType.id === expense.expenseType?.id)
					.sort((a, b) => {
						if (a.date > b.date) return 1;
						if (a.date < b.date) return -1;
						return 0;
					}),
			};
		});
	};

	const _renderHeader = ({ type, expenses }, index) => {
		return (
			<Animatable.View
				duration={1200}
				transition="backgroundColor"
				className={`flex flex-row justify-between border-b items-center p-2 px-3 bg-white dark:bg-gray-900 border-gray-200 dark:border-gray-800
				 ${index % 2 === 0 ? "bg-gray-50 dark:bg-gray-800" : ""}`}
			>
				<View>
					<Text className="text-lg font-medium dark:text-white">
						{type.name}
					</Text>
					<Text className="text-md font-light dark:text-white">
						<Text className="text-md font-medium dark:text-white">
							{expenses.length}
						</Text>{" "}
						Expenses
					</Text>
				</View>
			</Animatable.View>
		);
	};

	const _renderContent = ({ expenses, type }) => {
		return (
			<View className="px-1 w-full">
				{expenses.length ? (
					<Table
						headers={headers}
						data={expenses.map((expense) => {
							return getRowData(expense);
						})}
					/>
				) : (
					<NoItems
						surTitle={`No ${type.name} Expenses`}
						title="Currently No Expenses"
						text="There is no expenses tagged to this project yet"
					/>
				)}
			</View>
		);
	};

	return (
		<ScrollView className=" ">
			<View className="px-1 py-3">
				<View className="flex flex-row justify-between items-center pl-2">
					<View>
						<Text className="text-xl font-bold  text-gray-700">
							{group ? "Expenses by Type" : "Ungrouped Expenses"}
						</Text>
						<Text className="text-md font-light dark:text-white">
							{project.expenses.length} Expenses
						</Text>
					</View>

					<View className="flex flex-row items-center pr-2">
						<Switch
							value={group}
							onValueChange={() => setGroup(!group)}
							trackColor={{ false: "#767577", true: "#81b0ff" }}
							thumbColor={group ? "#f5dd4b" : "#f4f3f4"}
							ios_backgroundColor="#3e3e3e"
							style={{ marginHorizontal: 10 }}
						/>
						<Text className="text-md font-light dark:text-white">
							{group ? "Grouped" : "All"}
						</Text>
					</View>
				</View>
			</View>

			{group ? (
				<Accordion
					sections={getExpensesByType()}
					activeSections={activeSections}
					renderHeader={_renderHeader}
					renderContent={_renderContent}
					onChange={_updateSections}
				/>
			) : (
				<Table
					headers={headers}
					data={project.expenses.map((expense) => {
						return getRowData(expense);
					})}
				/>
			)}

			<Modal
				isOpen={newItem === "deleteExpense"}
				title="Delete Expense Item"
				description={`Are you sure want to delete this expense for the amount of $${
					deleteItem && deleteItem.amount
				}? This action cannot be undone and any billing using this will need to be updated. This is extremely destructive proceed with caution. Setting the expense to inactive will prevent users from selecting it but keep it in the system for billing purposes`}
				rightButton={{
					text: "Delete Expense",
					onPress: () => {
						deleteExpense({
							variables: {
								input: {
									id: deleteItem.id,
								},
							},
						});
					},
				}}
			/>

			<ExpenseSlideOver
				isOpen={newItem === "editExpense"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					setUpdateType({});
					refetch();
				}}
				currentExpense={{
					id: updateType.id,
					project: updateType.project && updateType.project.id,
					user: updateType.user && updateType.user.id,
					expenseType: updateType.expenseType && updateType.expenseType.id,
					amount: updateType.amount,
					description: updateType.description,
					receipt: updateType.receipt,
					date: updateType.date,
				}}
				currentExpenseType={
					updateType.expenseType ? updateType.expenseType : null
				}
				defaultValues={{
					expenseType: updateType.expenseType && updateType.expenseType.name,
					user: updateType.user && updateType.user.fullName,
				}}
				currentProject={project}
				type="update"
				isClerkingPage={true}
				users={
					project &&
					project.team.map((tm) => {
						return tm.teamMember;
					})
				}
				expenseTypes={
					project.billingRate ? [...project.billingRate.expenseTypes] : []
				}
			/>
		</ScrollView>
	);
};

ExpensesSummary.propTypes = {
	expenseTypes: PropTypes.array.isRequired,
	project: PropTypes.object.isRequired,
	billingPeriod: PropTypes.object,
	billingPhase: PropTypes.object,
	refetch: PropTypes.func.isRequired,
	user: PropTypes.object,
};
