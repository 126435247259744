import React from "react";
import { View, Text, Image } from "react-native";
import useInterval from "use-interval";
import SvgQRCode from "react-native-qrcode-svg";

// utils
import { getData, SentryLogger } from "../../utils";

export const WorkID = () => {
	const [user, setUser] = React.useState({});
	const [time, setTime] = React.useState(
		`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`
	);

	React.useEffect(() => {
		getData("@storage_Key")
			.then((storedUser) => {
				setUser(JSON.parse(storedUser));
			})
			.catch((err) => {
				SentryLogger(err);
			});
	}, []);

	useInterval(() => {
		setTime(
			`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`
		);
	}, 1000);

	return (
		<View className="flex flex-1 items-center justify-center">
			<View className="mx-2 shadow w-auto rounded bg-indigo-500">
				<View className=" shadow-xl flex flex-row justify-between items-center px-5 py-5">
					<Text className="text-white font-bold text-2xl text-center ">
						SRP Environmental
					</Text>
					<Image
						className="w-16 h-16  rounded-lg"
						source={require("../../assets/NEW-SRP-Logo.jpg")}
					/>
				</View>
				{/* Info Row */}
				<View className="flex flex-row p-4 bg-indigo-700">
					{/* Text Column */}
					<View className="w-3/5">
						<Text className="text-white font-bold text-xl flex-wrap ">
							{user.firstName} {user.lastName}
						</Text>
						<Text className="text-white pt-5">TITLE</Text>
						<Text className="text-white font-medium text-lg">{user.role}</Text>

						<Text className="text-white pt-5 flex-wrap">STATUS</Text>
						<Text className="text-white font-medium text-lg">
							{user.isActive ? "Active" : "Not Active"}
						</Text>
					</View>

					{/* Image Column */}
					<View className="ml-2 w-2/5 ">
						<Image
							className=" w-32 h-44 rounded-t-lg "
							source={require("../../assets/NEW-SRP-Logo.jpg")}
						/>
						{user.isTeamMember ? (
							<View className="bg-blue-500 w-32 border-l-4 border-r-4 border-b-4 border-blue-200 rounded-b-lg">
								<Text className="text-white font-medium text-lg text-center">
									Employee
								</Text>
							</View>
						) : (
							<View className="bg-orange-500 w-32 border-l-4 border-r-4 border-b-4 border-orange-200 rounded-b-lg">
								<Text className="text-white font-medium text-lg text-center">
									Contractor
								</Text>
							</View>
						)}
					</View>
				</View>

				<View className="py-6 flex items-center justify-center">
					<SvgQRCode
						color="white"
						size={150}
						backgroundColor={"transparent"}
						value={user.id}
					/>
					{/* TimeStamp */}
					<Text className="pt-2 text-white">{time}</Text>
				</View>
			</View>
		</View>
	);
};
