import { ProjectsScreen } from "../views/BaseViews";
import { Clients, SingleClient } from "../views/MainApp/Clients";
import { Equipment, SingleEquipment } from "../views/MainApp/Equipment";
import Home from "../views/MainApp/Home";
import { Project } from "../views/MainApp/Project";
import { Audit, AuditItem } from "../views/MainApp/Project/Audit";
import DryStandard, {
	SingleDryStandard,
} from "../views/MainApp/Project/DryStandards";
import SingleNote from "../views/MainApp/Project/Notes";
import {
	HomogeneousArea,
	SampleCollection,
	SingleSample,
} from "../views/MainApp/Project/Sample";
import SingleDailySheet from "../views/MainApp/Project/SingleDailySheet";
import Space from "../views/MainApp/Project/Space";
import ReportingCenter from "../views/MainApp/ReportingCenter";
import { NewReport } from "../views/MainApp/ReportingCenter/NewReport";
import { ProjectReports } from "../views/MainApp/ReportingCenter/ProjectReports";
import Report from "../views/MainApp/ReportingCenter/Report";
import {
	// PublicSOPs,
	PublicSingleSop,
} from "../views/MainApp/SOPs/SOPs";
import TimeClock from "../views/MainApp/TimeClock";
import { ManagerCenter } from "../views/MainApp/TimeClock/ManagerCenter";
import { Employee } from "../views/MainApp/TimeClock/ManagerCenter/Employee";
import SingleDailySheets from "../views/MainApp/TimeClock/SingleTimeEntry";
import { TimeAnalysis } from "../views/MainApp/TimeClock/TimeAnalysis";
import { UserTime } from "../views/MainApp/TimeClock/UserTime";
import { Notifications, Profile, Settings } from "../views/User";

// Icons
import {
	faFileAlt,
	faMemoPad,
	faRocket,
	faToolbox,
	faUserTie,
	faWatchApple,
} from "@fortawesome/pro-duotone-svg-icons";

export const mainNav = [
	{
		layout: "app",
		name: "Projects",
		component: Home,
		path: "/",
		icon: faRocket,
	},
	{
		layout: "app",
		name: "Project",
		component: Project,
		path: "/project/:id",
		noShow: true,
	},

	{
		layout: "app",
		name: "Reports",
		component: ProjectsScreen,
		path: "projects",
		noShow: true,
	},
	{
		layout: "app",
		name: "Space",
		component: Space,
		path: "/space/:id",
		noShow: true,
	},
	{
		layout: "app",
		name: "Equipment",
		component: Equipment,
		path: "equipment",
		icon: faToolbox,
		accessLevel: ["teamMember", "contractor"],
	},
	{
		layout: "app",
		name: "SingleEquipment",
		component: SingleEquipment,
		path: "equipment/:id",
		noShow: true,
		accessLevel: ["teamMember", "contractor"],
	},
	{
		layout: "app",
		name: "SampleCollection",
		component: SampleCollection,
		path: "/project/sample-collection/:id",
		noShow: true,
	},
	{
		layout: "app",
		name: "HomogeneousArea",
		component: HomogeneousArea,
		path: "/project/areas/:id",
		noShow: true,
	},
	{
		layout: "app",
		name: "SingleSample",
		component: SingleSample,
		path: "/project/sample-collection/sample/:id",
		noShow: true,
	},

	{
		layout: "app",
		name: "Clients",
		component: Clients,
		path: "clients",
		icon: faUserTie,
		accessLevel: ["teamMember"],
	},
	{
		layout: "app",
		name: "SingleClient",
		component: SingleClient,
		path: "client/:id",
		noShow: true,
		accessLevel: ["teamMember"],
	},
	{
		layout: "app",
		name: "Audit",
		component: Audit,
		path: "audit",
		noShow: true,
	},
	{
		layout: "app",
		name: "AuditItem",
		component: AuditItem,
		path: "audit/:id",
		noShow: true,
	},
	{
		layout: "app",
		name: "SingleNote",
		component: SingleNote,
		path: "/note/:id",
		noShow: true,
	},
	{
		layout: "app",
		name: "DryStandards",
		component: DryStandard,
		path: "/dryStandards",
		noShow: true,
	},
	{
		layout: "app",
		name: "SingleDryStandard",
		component: SingleDryStandard,
		path: "/dryStandards/:id",
		noShow: true,
	},
	{
		layout: "app",
		name: "Time Clock",
		component: TimeClock,
		path: "time-clock",
		icon: faWatchApple,
		accessLevel: ["teamMember", "contractor"],
	},

	{
		layout: "app",
		name: "Single Time Entry",
		component: SingleDailySheets,
		path: "time-clock/:date/:id",
		noShow: true,
		path: "timeclock",
		accessLevel: ["teamMember", "contractor"],
	},
	{
		component: SingleDailySheet,
		path: "/project/daily-document/:id",
		name: "Single Daily Sheet",
		layout: "app",
		noShow: true,
		accessLevel: ["teamMember", "contractor"],
	},
	{
		noShow: true,
		path: "/manager-center",
		name: "Manager Center",
		component: ManagerCenter,
		layout: "app",
		accessLevel: ["teamMember"],
	},
	{
		noShow: true,
		path: "/time-analysis",
		name: "Time Admin Center",
		component: TimeAnalysis,
		layout: "app",
		accessLevel: ["teamMember"],
	},
	{
		layout: "app",
		name: "User Time",
		component: UserTime,
		path: "/timeclock/time-analysis/usertime/:id",
		noShow: true,
		accessLevel: ["teamMember"],
	},
	{
		path: "/manager-center/:id",
		name: "Employee",
		component: Employee,
		layout: "app",
		noShow: true,
		accessLevel: ["teamMember"],
	},

	{
		layout: "app",
		name: "Reporting Center",
		component: ReportingCenter,
		path: "/reporting-center",
		icon: faFileAlt,
		accessLevel: ["teamMember", "contractor"],
	},

	{
		layout: "app",
		name: "Report",
		component: Report,
		path: "/reporting-center/report/:id",
		noShow: true,
		accessLevel: ["teamMember", "contractor"],
	},
	{
		layout: "app",
		name: "Project Reports",
		component: ProjectReports,
		path: "/reporting-center/project-reports/:id",
		noShow: true,
		accessLevel: ["teamMember", "contractor"],
	},
	{
		layout: "app",
		name: "New Report",
		component: NewReport,
		path: "/reporting-center/new-report/:id",
		noShow: true,
		accessLevel: ["teamMember", "contractor"],
	},
	{
		layout: "app",
		name: "Profile",
		component: Profile,
		path: "profile",
		noShow: true,
	},
	{
		layout: "app",
		name: "Settings",
		component: Settings,
		path: "settings",
		noShow: true,
	},
	{
		layout: "app",
		name: "Notifications",
		component: Notifications,
		path: "notifications",
		noShow: true,
	},
	// {
	// 	layout: "app",
	// 	name: "SOPs",
	// 	component: PublicSOPs,
	// 	path: "sops",
	// 	icon: faMemoPad,
	// 	accessLevel: ["teamMember", "contractor"],
	// },
	{
		layout: "app",
		name: "PublicSingleSop",
		component: PublicSingleSop,
		path: "sops/public/:id",
		icon: faMemoPad,
		accessLevel: ["teamMember", "contractor"],
		noShow: true,
	},
];
