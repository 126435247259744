import { faBell, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { PropTypes } from "prop-types";
import React from "react";
import { Text, TouchableOpacity, View } from "react-native";

export const NotificationContainer = ({ title, body, dismiss }) => {
	return (
		<TouchableOpacity
			className="absolute top-0  z-10 w-full max-h-42"
			onPress={() => {
				dismiss();
			}}
		>
			<View className="shadow mt-10 mx-2 p-4  bg-gray-200 dark:bg-gray-800 rounded-2xl opacity-95">
				<TouchableOpacity
					className="absolute top-2 right-2"
					onPress={() => {
						dismiss();
					}}
				>
					<FontAwesomeIcon icon={faTimes} size={20} />
				</TouchableOpacity>

				<View className="flex flex-row justify-start items-center">
					<FontAwesomeIcon icon={faBell} size={30} />

					<View className="ml-4">
						<Text className="font-bold text-lg text-gray-900 dark:text-white">
							{title}
						</Text>
						<Text className=" text-gray-900 dark:text-white">{body}</Text>
					</View>
				</View>
			</View>
		</TouchableOpacity>
	);
};

NotificationContainer.propTypes = {
	title: PropTypes.string.isRequired,
	body: PropTypes.string.isRequired,
	dismiss: PropTypes.func.isRequired,
};

export function addNotification(notification) {
	let notifications = [];
	if (!notifications) {
		notifications = [];
	}
	notifications.push(notification);
}
