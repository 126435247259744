//import { listLocations } from "../../graphql/admin/projectsScreen";

export const listProjectRoles = /* GraphQL */ `
	query ListProjectRoles(
		$filter: TableProjectRoleFilterInput
		$limit: Int
		$nextToken: String
	) {
		listProjectRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				active
				sageItem
				sageOvertimeItem
			}
		}
	}
`;

export const createProjectRole = /* GraphQL */ `
	mutation CreateProjectRol($input: CreateProjectRoleInput!) {
		createProjectRole(input: $input) {
			id
			name
			sageItem
			sageOvertimeItem
		}
	}
`;

export const deleteProjectRole = /* GraphQL */ `
	mutation DeleteProjectRole($input: DeleteProjectRoleInput!) {
		deleteProjectRole(input: $input) {
			id
			name
			sageItem
			sageOvertimeItem
		}
	}
`;

export const updateProjectRole = /* GraphQL */ `
	mutation UpdateProjectRole($input: UpdateProjectRoleInput!) {
		updateProjectRole(input: $input) {
			id
			name
			sageItem
			sageOvertimeItem
		}
	}
`;
