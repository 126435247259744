export const listEmails = /* GraphQL */ `
	query ListEmails(
		$limit: Int
		$nextToken: String
		$filter: TableEmailFilterInput
	) {
		listEmails(limit: $limit, nextToken: $nextToken, filter: $filter) {
			items {
				id
				title
				body
				createdAt
				createdBy {
					firstName
					lastName
				}
				updatedAt
				features
				status
				sentAt
				sentBy {
					firstName
					lastName
				}
				sentTo
			}
			nextToken
		}
	}
`;

export const getEmail = /* GraphQL */ `
	query GetEmail($id: ID!) {
		getEmail(id: $id) {
			title
			body
			createdAt
			createdBy {
				firstName
				lastName
			}
			updatedAt
			features
			status
			sentAt
			sentBy {
				firstName
				lastName
			}
			sentTo
		}
	}
`;

export const createEmail = /* GraphQL */ `
	mutation CreateEmail($input: CreateEmailInput!) {
		createEmail(input: $input) {
			id
		}
	}
`;

export const updateEmail = /* GraphQL */ `
	mutation UpdateEmail($input: UpdateEmailInput!) {
		updateEmail(input: $input) {
			title
			body
			createdAt
			createdBy {
				firstName
				lastName
			}
			updatedAt
			features
			status
			sentAt
			sentBy {
				firstName
				lastName
			}
			sentTo
		}
	}
`;
