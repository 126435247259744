import PropTypes from "prop-types";
import React, { useState } from "react";
import { View } from "react-native";

// Components
import { Alert } from "../components/Alert";
import {
	Checkbox,
	FieldSet,
	Input,
	Radio,
	Select,
	Switch,
	TextArea,
} from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as clientOperations from "../graphql/admin/clients";
import * as locations from "../graphql/admin/location";
import * as subcontractorGQL from "../graphql/admin/subcontractors";
import * as userOperations from "../graphql/user";

// Icons
import {
	faBadgeCheck,
	faEnvelope,
	faPhone,
	faUser,
} from "@fortawesome/pro-duotone-svg-icons";
import { SentryLogger } from "../utils";

const UserSlideOver = ({
	isOpen,
	closeRequest,
	onComplete = () => {},
	type,
	defaultValues = {},
	currentUser,
}) => {
	const [user, setUser] = React.useState({});
	const [sendEmail, setSendEmail] = React.useState(false);
	const [isClient, setIsClient] = React.useState(false);
	const [officeLocations, setOfficeLocations] = React.useState([]);
	const [clients, setClients] = React.useState([]);
	const [subcontractors, setSubcontractors] = useState([]);
	const [managers, setManagers] = useState([]);
	const [isFullTime, setIsFullTime] = React.useState(false);
	const [isPartTime, setIsPartTime] = React.useState(false);
	const [isSeasonal, setIsSeasonal] = React.useState(false);

	React.useEffect(() => {
		if (currentUser) {
			setUser(currentUser);
		}
	}, [currentUser]);

	React.useEffect(() => {
		if (user.employmentType === "Full Time") {
			setIsFullTime(true);
		} else if (user.employmentType === "Part Time") {
			setIsPartTime(true);
		} else if (user.employmentType === "Seasonal") {
			setIsSeasonal(true);
		}
	}, [user.employmentType]);

	const [createUser] = useMutation(gql(userOperations.createUser), {
		onCompleted: (data) => {
			if (sendEmail) {
				sendInviteEmail(data.createUser);
			}
			setSendEmail(false);
			onComplete(data.createUser);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("Couldn't create a user please try again");
		},
	});

	const [updateUser] = useMutation(gql(userOperations.updateUser), {
		onCompleted: (data) => {
			setUser(data.updateUser);
			setUser({});
			onComplete(data.updateUser);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("Couldn't update user please try again");
		},
	});

	// queries

	useQuery(gql(subcontractorGQL.listSubcontractors), {
		variables: {
			limit: 100,
		},
		onCompleted: (data) => {
			setSubcontractors([...data.listSubcontractors.items]);
		},
		onError: () => {},
	});

	useQuery(gql(locations.listLocations), {
		variables: {
			limit: 1000,

			filter: {
				isBranchLocation: true,
			},
		},
		onCompleted: (data) => {
			setOfficeLocations([...data.listLocations.items]);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
	});
	useQuery(gql(userOperations.listUsers), {
		variables: {
			limit: 1000,
			filter: {
				isManager: true,
			},
		},
		onCompleted: (data) => {
			setManagers([...data.listUsers.items]);
		},
		onError: () => {},
	});

	useQuery(gql(clientOperations.listClients), {
		variables: {
			limit: 10000,
		},
		onCompleted: (data) => {
			setClients(data.listClients.items);
		},
		onError: () => {},
	});

	const sendInviteEmail = (userData) => {
		fetch(
			"https://fk57a28ct3.execute-api.us-west-2.amazonaws.com/v2/emails/welcome",
			{
				method: "POST", // *GET, POST, PUT, DELETE, etc.
				mode: "cors",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					id: userData.id,
					firstName: userData.firstName,
					lastName: userData.lastName,
					email: userData.email,
				}),
			}
		)
			.then((response) => response.json())
			.then((user) => {
				addNotification({
					title: "Welcome Email Sent!",
					text: `The invite email was sent to ${user.firstName} ${user.lastName} at ${user.email}`,
					type: "success",
					time: 5000,
				});
			});
	};

	return (
		<SlideOver
			isOpen={isOpen}
			name={
				type === "create"
					? "Create User"
					: `Edit ${user.firstName} ${user.lastName}`
			}
			description={
				type === "create"
					? " Create a user in SRP in Field"
					: `Edit the information and settings for ${user.firstName} ${user.lastName} in SRP in Field`
			}
			closeRequest={() => {
				closeRequest();
			}}
			buttonRightText={type === "create" ? "Create" : "Update"}
			onSubmit={() => {
				// delete typename of the user object
				delete user.__typename;

				if (type === "create") {
					// create User

					createUser({
						variables: {
							input: {
								...user,
								isRequesting: false,
							},
						},
					});
				} else {
					updateUser({
						variables: {
							input: {
								id: user.id,
								isRequesting: false,
								...user,
							},
						},
					});
				}
			}}
		>
			<FieldSet
				legend="Basic Information"
				description="Enter user's basic information"
			>
				<View className="inline-flex">
					<Input
						className="mr-1"
						type="text"
						label="First Name"
						placeholder="First Name"
						value={user.firstName}
						onChange={(e) => {
							setUser({
								...user,
								firstName: e,
							});
						}}
					/>
					<Input
						type="text"
						className="ml-1"
						label="Last Name"
						placeholder="Last Name"
						value={user.lastName}
						onChange={(e) => {
							setUser({
								...user,
								lastName: e,
							});
						}}
					/>
				</View>

				<Input
					type="text"
					label="Role"
					placeholder="Role"
					value={user.role}
					onChange={(e) => {
						setUser({
							...user,
							role: e,
						});
					}}
				/>
				<Input
					type="email"
					label="Email"
					icon={faEnvelope}
					placeholder="Email"
					value={user.email}
					onChange={(e) => {
						setUser({
							...user,
							email: e,
						});
					}}
				/>
				<Input
					type="text"
					label="Phone"
					icon={faPhone}
					placeholder="Phone Number"
					value={user.phone}
					onChange={(e) => {
						setUser({
							...user,
							phone: e,
						});
					}}
				/>

				<TextArea
					label="Bio"
					placeholder="Bio"
					icon={faUser}
					value={user.bio ? user.bio : ""}
					onChange={(e) => {
						setUser({
							...user,
							bio: e,
						});
					}}
				/>
				<Input
					label="Hire Date"
					placeholder="Hire Date"
					// value={
					// 	user.companyStartDate ? companyStartDate.bio : companyStartDate.bio
					// }
					onChange={(e) => {
						setUser({
							...user,
							companyStartDate: e,
						});
					}}
					type="date"
				/>
				<Input
					type="text"
					label="APS Employee ID"
					placeholder="APS Employee ID"
					icon={faBadgeCheck}
					value={user.apsEmployeeID ? user.apsEmployeeID : ""}
					onChange={(e) => {
						setUser({
							...user,
							apsEmployeeID: e,
						});
					}}
				/>

				<Input
					type="text"
					label="Department"
					placeholder="Department"
					value={user.department ? user.department : ""}
					onChange={(e) => {
						setUser({
							...user,
							department: e,
						});
					}}
				/>

				<Input
					type="text"
					label="SageIntacct"
					placeholder="SageIntacct"
					value={user.sageIntacct ? user.sageIntacct : ""}
					onChange={(e) => {
						setUser({
							...user,
							sageIntacct: e,
						});
					}}
				/>
			</FieldSet>
			<FieldSet legend="Permissions" description="Check all that apply">
				<Switch
					label="Clerk"
					info="Should this user have clerking abilities"
					value={user.isClerk === true ? true : false}
					onChange={(e) => {
						setUser({ ...user, isClerk: e });
					}}
				/>
				<Switch
					label="Super Clerk"
					info="Clerk with additional abilities (must be a clerk)"
					value={user.isSuperClerk === true ? true : false}
					onChange={(e) => {
						setUser({ ...user, isSuperClerk: e });
					}}
					disabled={!user.isClerk}
				/>
				<Switch
					label="Human Resources"
					info="Should this user have HR abilities"
					value={user.isHR === true ? true : false}
					onChange={(e) => {
						setUser({ ...user, isHR: e });
					}}
				/>
				<Switch
					label="Admin"
					info="Should this user have report reviewing abilities"
					value={user.isAdmin === true ? true : false}
					onChange={(e) => {
						setUser({ ...user, isAdmin: e });
					}}
				/>

				<Switch
					label="Report Reviewer"
					info="Should this user need to review reports"
					value={user.canReviewReports ? true : false}
					onChange={(e) => {
						setUser({
							...user,
							canReviewReports: e,
						});
					}}
				/>
				<Switch
					label="Time Overrider"
					info="Should this user have the ability to override time"
					value={user.isTimeOverrider === true ? true : false}
					onChange={(e) => {
						setUser({ ...user, isTimeOverrider: e });
					}}
				/>
			</FieldSet>
			<FieldSet
				legend="User Type"
				description="Check which one applies, if any"
			>
				<Radio
					defaultValue={
						user.isTeamMember
							? "Team Member"
							: user.isContractor
							? "Subcontractor"
							: "Client"
					}
					onChange={(value) => {
						if (value.label === "Team Member") {
							setUser({
								...user,
								isTeamMember: true,
								isContractor: false,
							});
							delete user.client;
							setIsClient(false);
						}
						if (value.label === "Subcontractor") {
							setUser({
								...user,
								isContractor: true,
								isTeamMember: false,
								location: null,
							});
							delete user.client;
							setIsClient(false);
						}

						if (value.label === "Client") {
							setIsClient(!isClient);
							setUser({
								...user,
								isContractor: false,
								isTeamMember: false,
								location: null,
							});
						}
					}}
					options={[
						{
							id: 1,
							label: "Team Member",
						},
						{
							id: 2,
							label: "Subcontractor",
						},
						{
							id: 3,
							label: "Client",
						},
					]}
					horizontal={true}
				/>

				{user.isTeamMember && (
					<>
						<Select
							name="Office Location"
							defaultValue={
								defaultValues.location ? defaultValues.location : []
							}
							onChange={(value) => {
								setUser({ ...user, location: value.value });
							}}
							options={officeLocations
								.sort((a, b) => {
									if (a.name > b.name) return 1;
									if (a.name < b.name) return -1;
									return 0;
								})
								.map((location) => {
									return { name: location.name, value: location.id };
								})}
						/>
						<Select
							name="Team Member's Manager"
							defaultValue={defaultValues.manager ? defaultValues.manager : []}
							onChange={(value) => {
								setUser({ ...user, manager: value.value }); // this was manager: e we dont want that we want to assign the value to the manager key
							}}
							options={managers
								.sort((a, b) => {
									if (a.name > b.name) return 1;
									if (a.name < b.name) return -1;
									return 0;
								})
								.map((manager) => {
									return {
										name: `${manager.firstName} ${manager.lastName}`,
										value: manager.id, // this was manager.isManger , we want the id here so we can set that to manger on the user update object
									};
								})}
						/>
						<Checkbox
							name="Manager"
							label="Manager"
							description="Check if user is a manager"
							checkedValue={user.isManager}
							onChange={(e) => {
								setUser({
									...user,
									isManager: e ? true : false,
								});
							}}
						/>
						<Checkbox
							name="Payroll Admin"
							label="Payroll Admin"
							description="Check if user is a payroll admin"
							checkedValue={user.isPayrollAdmin}
							onChange={(e) => {
								setUser({
									...user,
									isPayrollAdmin: e ? true : false,
								});
							}}
						/>
						<Checkbox
							name="Time Admin"
							label="Time Admin"
							description="Check if user is a time admin"
							checkedValue={user.isTimeAdmin}
							onChange={(e) => {
								setUser({
									...user,
									isTimeAdmin: e ? true : false,
								});
							}}
						/>
						<Checkbox
							name="Equipment Manager"
							label="Equipment Manager"
							description="Check if user is a equipment manager"
							checkedValue={user.isEquipmentManager}
							onChange={(e) => {
								setUser({
									...user,
									isEquipmentManager: e ? true : false,
								});
							}}
						/>
						<Checkbox
							name="Payroll Exempt"
							label="Payroll Exempt"
							description="Should user be removed from the payroll export"
							checkedValue={user.payrollExempt ? user.payrollExempt : false}
							onChange={(e) => {
								setUser({
									...user,
									payrollExempt: e ? true : false,
								});
							}}
						/>

						<Radio
							name="Compensation Type"
							label="Compensation Type"
							defaultValue={
								user.isHourly ? "Hourly" : user.isSalary ? "Salary" : null
							}
							onChange={(value) => {
								if (value.label === "Salary") {
									setUser({
										...user,
										isHourly: false,
										isSalary: true,
									});
								}
								if (value.label === "Hourly") {
									setUser({
										...user,
										isHourly: true,
										isSalary: false,
									});
								}
							}}
							options={[
								{ label: "Salary", value: "salary" },
								{ label: "Hourly", value: "hourly" },
							]}
						/>
						<Radio
							name="Employment Type"
							label="Employment Type"
							defaultValue={
								isFullTime
									? "Full Time"
									: isPartTime
									? "Part Time"
									: isSeasonal
									? "Seasonal"
									: null
							}
							onChange={(value) => {
								if (value.label === "Full Time") {
									setUser({
										...user,
										employmentType: "Full Time",
									});
								}
								if (value.label === "Part Time") {
									setUser({
										...user,
										employmentType: "Part Time",
									});
								}
								if (value.label === "Seasonal") {
									setUser({
										...user,
										employmentType: "Seasonal",
									});
								}
							}}
							options={[
								{ label: "Full Time", value: "fullTime" },
								{ label: "Part Time", value: "parTime" },
								{ label: "Seasonal", value: "seasonal" },
							]}
						/>
					</>
				)}

				{user.isContractor && (
					<>
						<Select
							name="Subcontractor"
							onChange={(value) => {
								setUser({
									...user,
									subcontractor: value.value,
								});
							}}
							options={
								subcontractors &&
								subcontractors
									.sort((a, b) => {
										if (a.name > b.name) return 1;
										if (a.name < b.name) return -1;
										return 0;
									})
									.map((subcontractor) => {
										return {
											name: subcontractor.name,
											value: subcontractor.id,
										};
									})
							}
						/>
						<Checkbox
							label="Subcontractor Admin"
							description="Should this user have Subcontractor Admin abilities "
							checkedValue={user.subAdmin ? user.subAdmin : false}
							onChange={(e) =>
								setUser({
									...user,
									subAdmin: e ? true : false,
								})
							}
						/>
					</>
				)}

				{isClient && (
					<Select
						name="Client"
						onChange={(value) => {
							setUser({ ...user, client: value.value });
						}}
						options={
							clients &&
							clients
								.sort((a, b) => {
									if (a.name > b.name) return 1;
									if (a.name < b.name) return -1;
									return 0;
								})
								.map((client) => {
									return { name: client.name, value: client.id };
								})
						}
					/>
				)}
			</FieldSet>
		</SlideOver>
	);
};

UserSlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	defaultValues: PropTypes.object,
	user: PropTypes.string,
	currentUser: PropTypes.object,
	onComplete: PropTypes.func,
};

export { UserSlideOver };
