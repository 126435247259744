import React from "react";
import PropTypes from "prop-types";
import { View, Text } from "react-native";

export default function LoadingScreen({ loadItem, subtext }) {
	return (
		<>
			<View className="bg-white dark:bg-gray-800 h-full flex flex-1 flex-col justify-center">
				<View className="max-w-7xl mx-auto  px-4 sm:py-24 sm:px-6 lg:px-8">
					<View className="text-center">
						<Text className="text-base font-semibold text-indigo-600 tracking-wide uppercase">
							SRP in Field
						</Text>
						<View className="flex flex-row">
							<Text
								testID="loadingText"
								className="mt-1 text-4xl font-extrabold text-gray-900 dark:text-white sm:text-5xl sm:tracking-tight lg:text-6xl"
							>
								Loading {loadItem}
							</Text>
							<View className="flex items-center justify-center h-3 w-3">
								<View className="animate-ping absolute inline-flex h-5 w-5 rounded-full bg-purple-400 opacity-75"></View>
								<View className="relative inline-flex rounded-full h-3 w-3 bg-purple-500"></View>
							</View>
						</View>
						{subtext && (
							<Text
								testID="subtext"
								className="mt-1 text-xl font-medium text-indigo-500"
							>
								{subtext}
							</Text>
						)}
					</View>
				</View>
			</View>
		</>
	);
}

LoadingScreen.propTypes = {
	loadItem: PropTypes.string.isRequired,
	subtext: PropTypes.string,
};
