import { useNavigation } from "@react-navigation/native";
import React from "react";

// navigation
import { Navigation as NavigationContainer } from "../components/Navigation";
import { authNav } from "../routes/admin";

// utils
import { getData } from "../utils";

export const AdminLayout = () => {
	const navigation = useNavigation();
	React.useEffect(() => {
		// get user and redirect if logged in
		const getLocalUserDetails = async () => {
			await getData("@storage_Key").then((user) => {
				if (
					user === null ||
					user === undefined ||
					!user.token ||
					!user.isAdmin
				) {
					return navigation.navigate("App");
				}
			});
		};
		getLocalUserDetails();
	});
	return (
		<>
			<NavigationContainer routes={authNav} layoutName="Admin Center" />
		</>
	);
};
