export const createUser = /* GraphQL */ `
	mutation CreateUser($input: CreateUserInput!) {
		createUser(input: $input) {
			id
			firstName
			lastName
			email
		}
	}
`;

export const getUser = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			firstName
			lastName
			email
			role
			profilePicture
			isTeamMember
			isManager
			isTimeOverrider
			isSalary
			isClerk
			isHR
			isPayrollAdmin
			isTimeAdmin
			isContractor
			currentYearMaxAllotment
			companyStartDate
			manager {
				id
				firstName
				lastName
			}
			client {
				id
				name
			}
			location {
				id
				name
			}
			isActive
			isRequesting
			isManager
			manager {
				id
				firstName
				lastName
			}
			sageIntacct
			apsEmployeeID
		}
	}
`;

export const listUsers = /* GraphQL */ `
	query ListUsers(
		$filter: TableUserFilterInput
		$limit: Int
		$nextToken: String
	) {
		listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				firstName
				lastName
				email
				role
				isTimeOverrider
				isSalary
				profilePicture
				isPayrollAdmin
				isTimeAdmin
				isTeamMember
				isManager
				isContractor
				currentYearMaxAllotment
				companyStartDate
				manager {
					id
					firstName
					lastName
				}
				client {
					id
					name
				}
				location {
					id
					name
				}
				isActive
				isRequesting
			}
			nextToken
		}
	}
`;

export const deleteUser = /* GraphQL */ `
	mutation DeleteUser($input: DeleteUserInput!) {
		deleteUser(input: $input) {
			id
			firstName
			lastName
			email
		}
	}
`;

export const updateUser = /* GraphQL */ `
	mutation UpdateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			id
			firstName
			lastName
			email
			role
			profilePicture
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isSalary
			isTeamMember
			isManager
			isContractor
			currentYearMaxAllotment
			companyStartDate
			manager {
				id
				firstName
				lastName
			}
			client {
				id
				name
			}
			location {
				id
				name
			}
			isActive
			isRequesting
			isManager
			manager {
				id
				firstName
				lastName
			}
			sageIntacct
			apsEmployeeID
		}
	}
`;

export const getUserProjects = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			isManager
			isAdmin
			isTeamMember
			isTempWorker
			isContractor
			isTimeOverrider
			isSalary
			firstName
			lastName
			email
			phone
			role
			profilePicture
			cognito
			currentYearMaxAllotment
			companyStartDate
			projects {
				id
				pinned
				project {
					projects {
						id
						title
					}
					stage
					id
					title
					number
					start
					isLargeLoss
					sageIntacct
					client {
						id
						isOrganization
						name
						location {
							id
							isBranchLocation
							name
							lineOne
							lineTwo
							city
							state
							zip
							latitude
							longitude
						}
					}
					isWorkspace
					isViewable
					surveys {
						id
						project {
							id
							title
							number
							start
							isWorkspace
							isViewable
						}
						title
						date
						items {
							id
							date
							title
							description
							order
							location
						}
					}
					location {
						id
						isBranchLocation
						lab {
							id
							name
						}
						name
						client {
							id
							isOrganization
							name
						}
						lineOne
						lineTwo
						city
						state
						zip
						latitude
						longitude
					}
					office {
						id
						isBranchLocation
						lab {
							id
							name
						}
						name
						client {
							id
							isOrganization
							name
						}
						lineOne
						lineTwo
						city
						state
						zip
						latitude
						longitude
					}

					team {
						id
						project {
							id
							title
							number
							start
							isWorkspace
							isViewable
						}
						teamMember {
							id
							isManager
							isAdmin
							isTeamMember
							isTempWorker
							isContractor
							firstName
							lastName
							email
							phone
							role
							profilePicture
							cognito
							canBeAddedToProject
							linkExpiration
							organization
							isRequesting
							bio
						}
						projectRole {
							id
							name
						}
						isSuperAdmin
						isAdmin
						isGeneral
						isReadOnly
						isActive
					}
				}

				teamMember {
					id
					isManager
					isAdmin
					isTeamMember
					isTempWorker
					isContractor
					firstName
					lastName
					email
					phone
					location {
						id
						isBranchLocation
						lab {
							id
							name
						}
						name
						client {
							id
							isOrganization
							name
						}
						lineOne
						lineTwo
						city
						state
						zip
						latitude
						longitude
					}
					role
					profilePicture
					cognito
					canBeAddedToProject
					linkExpiration
					organization
					isRequesting
					bio
				}
				projectRole {
					id
					name
				}
				isSuperAdmin
				isAdmin
				isGeneral
				isReadOnly
				isActive
			}
			canBeAddedToProject
			linkExpiration
			organization
			isRequesting
			bio
		}
	}
`;
