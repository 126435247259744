import { useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import React from "react";
import { View } from "react-native";

// Components
import { Alert } from "../../components/Alert";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import { Input } from "../../components/Forms";
import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List";
import LoadingScreen from "../../components/LoadingScreen";
import NoItems from "../../components/NoItems";
import OptionsMenu from "../../components/OptionsMenu";
import Pagination from "../../components/Pagination";
import SlideOver from "../../components/Slideover";
import Tabs, { Tab } from "../../components/Tabs";

// GraphQL
import { gql, useMutation, useQuery } from "@apollo/client";
import * as subcontractorGQL from "../../graphql/admin/subcontractors";

// Icons
import {
	faEye,
	faPen,
	faPlus,
	faRocket,
	faUsers,
} from "@fortawesome/pro-duotone-svg-icons";

export function Subcontractors() {
	const navigation = useNavigation();
	const [newItem, setNewItem] = React.useState(null);
	const [subcontractors, setSubcontractors] = React.useState(null);
	const [newSubcontractor, setNewSubcontractor] = React.useState(null);

	const { refetch: subcontractorsRefetch } = useQuery(
		gql(subcontractorGQL.listSubcontractors),
		{
			variables: {
				limit: 100,
			},
			onCompleted: (data) => {
				let items = data.listSubcontractors.items;
				setSubcontractors([...items]);
			},
		}
	);

	const [createSubcontractor] = useMutation(
		gql(subcontractorGQL.createSubcontractor),
		{
			onCompleted: (data) => {
				setNewItem(null);
				setNewSubcontractor(data.createSubcontractor);

				subcontractorsRefetch();
			},
			onError: () => {
				Alert("Couldn't create subcontractor please try again");
			},
		}
	);

	if (!subcontractors) {
		return <LoadingScreen loadItem="Subcontractors" />;
	}

	return (
		<View className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
			<Breadcrumb baseLocation="Work In Progress" className="mt-2">
				<Crumb name={"Subcontractors"} location={"Subcontractors"} />
			</Breadcrumb>

			<PageHeader
				openMenu
				title="Subcontractors"
				buttons={[
					{
						name: "New Subcontractor",
						icon: faPlus,
						onPress: () => {
							setNewItem("subcontractors");
						},
					},
				]}
			/>

			<List title={"Subcontractors"} headers={["Name"]}>
				{subcontractors &&
					subcontractors

						.sort((a, b) => {
							if (a.name > b.name) return 1;
							if (a.name < b.name) return -1;
							return 0;
						})

						.map((subcontractor) => {
							return (
								<ListItem
									key={subcontractor.id}
									items={[
										{
											onPress: () => {
												navigation.navigate("Subcontractor", {
													id: subcontractor.id,
													name: subcontractor.name,
												});
											},
											content: subcontractor.name,
										},
									]}
								/>
							);
						})}
			</List>

			<SlideOver
				closeRequest={() => {
					setNewItem(null);
				}}
				isOpen={newItem === "subcontractors"}
				name={"New Subcontractor"}
				description={"Add a new subcontractor"}
				onSubmit={(e) => {
					e.preventDefault();
					createSubcontractor({
						variables: {
							input: newSubcontractor,
						},
					});
				}}
			>
				<Input
					label="Name"
					onChange={(e) =>
						setNewSubcontractor({ ...newSubcontractor, name: e.target.value })
					}
				/>
			</SlideOver>
			<Pagination start={20} stop={23} total={23} />
		</View>
	);
}

export function Subcontractor({ route }) {
	const { id, name } = route.params;
	const navigation = useNavigation();
	const [users, setUsers] = React.useState(null);
	const [newItem, setNewItem] = React.useState(null);
	const [subcontractor, setSubcontractor] = React.useState({});
	const [updatedSubcontractor, setUpdatedSubcontractor] = React.useState({});
	const [currentTab, setCurrentTab] = React.useState("Users");
	const [currentPage, setCurrentPage] = React.useState(1);

	let itemsPerPage = 20;
	let tabs = [
		{ name: "Users", icon: faUsers },
		{ name: "Projects", icon: faRocket },
	];

	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end = users
		? currentPage * itemsPerPage > users.length
			? users.length
			: currentPage * itemsPerPage
		: 0;

	React.useEffect(() => {
		setCurrentPage(1);
	}, [currentTab]);

	const { loading } = useQuery(gql(subcontractorGQL.getSubcontractor), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setSubcontractor(data.getSubcontractor);
			setUsers([...data.getSubcontractor.users]);
			setCurrentTab(() => tabs[0].name);
		},
	});

	const [updateSubcontractor] = useMutation(
		gql(subcontractorGQL.updateSubcontractor),
		{
			onCompleted: (data) => {
				setSubcontractor(data.updateSubcontractor);
				setNewItem(null);
			},
			onError: () => {
				Alert("There was an error updating the subcontractor's information.");
			},
		}
	);

	let options = (user) => (
		<OptionsMenu
			options={[
				{
					name: "View User",
					icon: faEye,
					onPress: () => {
						navigation.navigate("User", {
							id: user.id,
							name: `${user.firstName} ${user.lastName}}`,
						});
					},
				},
			]}
		/>
	);

	const navigateToUser = (user) => {
		navigation.navigate("User", {
			id: user.id,
			name: `${user.firstName} ${user.lastName}}`,
		});
	};

	if (loading) {
		return <LoadingScreen loadItem={name || "Subcontractor"} />;
	}

	return (
		<View className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
			{/* Page title & actions */}
			<Breadcrumb baseLocation="Work In Progress" className="mt-2">
				<Crumb name={"Subcontractors"} location={"Subcontractors"} />
				<Crumb
					name={subcontractor.name}
					location={"Subcontractor"}
					params={{ id: subcontractor.id }}
				/>
			</Breadcrumb>
			<PageHeader
				goBack
				title={subcontractor.name}
				buttons={[
					{
						name: "Update Subcontractor",
						icon: faPen,
						onPress: () => {
							setNewItem("update subcontractors");
							setUpdatedSubcontractor({
								name: subcontractor.name,
							});
						},
					},
				]}
			/>
			<Tabs
				onChange={(value) => {
					setCurrentTab(value);
				}}
			>
				{tabs.map((tab) => {
					return (
						<Tab
							key={tab.name}
							name={tab.name}
							current={currentTab === tab.name ? true : false}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			{currentTab === "Users" && (
				<>
					{users ? (
						<>
							<List
								title="Users"
								headers={["First Name", "Last Name", "Email", "Role"]}
								usesOptions
							>
								{users
									.sort((a, b) => {
										if (a.firstName > b.firstName) return 1;
										if (a.firstName < b.firstName) return -1;
										return 0;
									})
									.map((user, index) => {
										if (index + 1 >= start && index < end) {
											return (
												<ListItem
													key={index}
													items={[
														{
															content: user.firstName,
															onPress: () => navigateToUser(user),
														},
														{
															content: user.lastName,
															onPress: () => navigateToUser(user),
														},
														{
															content: user.email,
															onPress: () => navigateToUser(user),
														},
														{
															content: user.role,
															onPress: () => navigateToUser(user),
														},
													]}
													options={options(user)}
												/>
											);
										}
									})}
							</List>
							<Pagination
								start={start}
								stop={end}
								total={users.length}
								nextClicked={() => setCurrentPage(currentPage + 1)}
								previousClicked={() => setCurrentPage(currentPage - 1)}
							/>
						</>
					) : (
						<NoItems surTitle="No Users" title={"No Subcontractor Users"} />
					)}
				</>
			)}
			<SlideOver
				closeRequest={() => {
					setNewItem(null);
				}}
				isOpen={newItem === "update subcontractors"}
				name={"Update Subcontractor"}
				description={"Update subcontractor"}
				onSubmit={(e) => {
					e.preventDefault();
					let input = updatedSubcontractor;
					input.id = id;
					updateSubcontractor({
						variables: {
							input: {
								name: e,
							},
						},
					});
				}}
			>
				<Input
					label="Name"
					value={updatedSubcontractor.name}
					onChange={(e) =>
						setUpdatedSubcontractor({
							variables: {
								input: id,
								...updatedSubcontractor,
								name: e,
							},
						})
					}
				/>
			</SlideOver>
		</View>
	);
}

Subcontractor.propTypes = {
	route: PropTypes.object,
};
