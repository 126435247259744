export const getUserNotifications = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			notifications {
				id
				user {
					id
					firstName
					lastName
				}
				date
				message
				title
				isRead
				project {
					id
				}
				report {
					id
				}
				equipmentRequest {
					id
				}
				createdBy {
					id
					profilePicture
					role
					firstName
					lastName
				}
			}
		}
	}
`;

export const getNotification = /* GraphQL */ `
	query GetNotification($id: ID!) {
		getNotification(id: $id) {
			id
			user {
				id
				firstName
				lastName
			}
			date
			message
			isRead
			project {
				id
			}
			report {
				id
			}
			equipmentRequest {
				id
			}
		}
	}
`;

export const createNotification = /* GraphQL */ `
	mutation CreateNotification($input: CreateNotificationInput!) {
		createNotification(input: $input) {
			id
		}
	}
`;

export const updateNotification = /* GraphQL */ `
	mutation UpdateNotification($input: UpdateNotificationInput!) {
		updateNotification(input: $input) {
			id
			user {
				id
				notifications {
					id
					user {
						id
						firstName
						lastName
					}
					date
					message
					isRead
					project {
						id
					}
					report {
						id
					}
					equipmentRequest {
						id
					}
					createdBy {
						id
						profilePicture
						role
						firstName
						lastName
					}
				}
			}
		}
	}
`;

export const deleteNotification = /* GraphQL */ `
	mutation DeleteNotification($input: DeleteNotificationInput!) {
		deleteNotification(input: $input) {
			id
		}
	}
`;
