export const listLocations = /* GraphQL */ `
	query ListLocations(
		$filter: TableLocationFilterInput
		$limit: Int
		$nextToken: String
	) {
		listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
			}
		}
	}
`;

export const createEquipment = /* GraphQL */ `
	mutation CreateEquipment($input: CreateEquipmentInput!) {
		createEquipment(input: $input) {
			id
			barcode
			model
			name
			sn
			expirationDate
			isKit
			kit {
				id
				tools {
					id
					name
				}
			}
			history {
				id
				office {
					id
					name
				}
				user {
					id
					firstName
					lastName
					profilePicture
					role
				}
				checkIn
				checkOut
			}
		}
	}
`;
export const deleteEquipment = /* GraphQL */ `
	mutation DeleteEquipment($input: DeleteEquipmentInput!) {
		deleteEquipment(input: $input) {
			id
		}
	}
`;

export const createEquipmentHistory = /* GraphQL */ `
	mutation CreateEquipmentHistory($input: CreateEquipmentHistoryInput!) {
		createEquipmentHistory(input: $input) {
			id
		}
	}
`;
export const listTransferRequest = /* GraphQL */ `
  query ListTransferRequest(
    $filter: TableTransferRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransferRequest(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
    }
  }
`;
export const createTransferRequest = /* GraphQL */ `
	mutation CreateTransferRequest($input: CreateTransferRequestInput!) {
		createTransferRequest(input: $input) {
			id
			accepted
			receiver {
				id
				firstName
				lastName
			}
		}
	}
`;
export const updateTransferRequest = /* GraphQL */ `
	mutation UpdateTransferRequest($input: UpdateTransferRequestInput!) {
		updateTransferRequest(input: $input) {
			id
			accepted
		}
	}
`;
export const updateEquipment = /* GraphQL */ `
	mutation UpdateEquipment($input: UpdateEquipmentInput!) {
		updateEquipment(input: $input) {
			id
		}
	}
`;

export const updateEquipmentHistory = /* GraphQL */ `
	mutation updateEquipmentHistory($input: UpdateEquipmentHistoryInput!) {
		updateEquipmentHistory(input: $input) {
			id
		}
	}
`;

export const getEquipment = /* GraphQL */ `
	query GetEquipment($id: ID!) {
		getEquipment(id: $id) {
			additionalComponents
			id
			name
			barcode
			sn
			model
			status
			isKit
			tools {
				id
				name
				barcode
				status
				sn
				model
				isKit
			}
			expirationDate
			notes {
				id
				date
				description
				title
				user {
					id
					firstName
					lastName
				}
			}
			history {
				id
				office {
					id
					name
				}
				user {
					id
					firstName
					lastName
					profilePicture
					role
				}
				checkIn
				checkOut
			}
			attachments {
				date
				id
				link
				name
			}
		}
	}
`;

export const listEquipment = /* GraphQL */ `
	query ListEquipment(
		$filter: TableEquipmentFilterInput
		$limit: Int
		$nextToken: String
	) {
		listEquipment(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				additionalComponents
				name
				barcode
				expirationDate
				model
				sn
				isKit
				tools {
					id
					name
					barcode
					status
					sn
					model
				}
				history {
					id
					checkIn
					checkOut
					office {
						id
						name
					}
					user {
						id
						firstName
						lastName
						role
						profilePicture
					}
				}
				attachments {
					date
					id
					link
					name
				}
			}
		}
	}
`;

export const listEquipmentHistories = /* GraphQL */ `
	query listEquipmentHistories(
		$filter: TableEquipmentHistoryFilterInput
		$limit: Int
		$nextToken: String
	) {
		listEquipmentHistories(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				checkIn
				checkOut
				office {
					id
					name
				}
				user {
					id
					firstName
					lastName
					role
					profilePicture
				}
				equipment {
					id
					name
					barcode
					model
					sn
				}
			}
			nextToken
		}
	}
`;

export const getUser = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isClerk
			isHR
			isTeamMember
			isContractor
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isLogistic
			isActive
			isSalary
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			currentYearMaxAllotment
			companyStartDate
			subcontractor {
				id
				name
			}
			isManager
			manager {
				id
				firstName
				lastName
				profilePicture
				role
			}
			isPayrollAdmin
			isTimeAdmin
			location {
				id
				name
			}
			equipment {
				id
				checkIn
				checkOut
				office {
					id
					name
				}
				user {
					id
					firstName
					lastName
				}
				equipment {
					id
					name
					sn
					barcode
					expirationDate
					model
					isKit
					kit {
						id
						name
						tools {
							id
							name
						}
					}
				}
			}
			transferRequests {
				id
				accepted
				holder {
					id
					firstName
					lastName
				}
				equipment {
					id
					name
					barcode
					history {
						id
					}
				}
			}
		}
	}
`;

export const createEquipmentLink = /* GraphQL */ `
	mutation CreateEquipmentLink($input: CreateEquipmentLinkInput!) {
		createEquipmentLink(input: $input) {
			id
			link
			date
			equipment {
				id
			}
		}
	}
`;
export const deleteEquipmentLink = /* GraphQL */ `
	mutation DeleteEquipmentLink($input: DeleteEquipmentLinkInput!) {
		deleteEquipmentLink(input: $input) {
			id
			link
			name
		}
	}
`;
export const updateEquipmentLink = /* GraphQL */ `
	mutation UpdateEquipmentLink($input: UpdateEquipmentLinkInput!) {
		updateEquipmentLink(input: $input) {
			id
			name
			link
			date
			equipment {
				id
			}
		}
	}
`;

export const createEquipmentKit = /* GraphQL */ `
	mutation CreateEquipmentKit($input: CreateEquipmentKitInput!) {
		createEquipmentKit(input: $input) {
			id
			name
			isKit
			kit {
				id
				name
			}
		}
	}
`;
