import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { Text } from "react-native";

// Components
import { Alert } from "../components/Alert";
import { Input, Select } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation, useQuery } from "@apollo/client";
import * as projectGQL from "../graphql/project";
import * as sampleCollectionGQL from "../graphql/sampling";

// UTILS
import { getData, sampleTypes } from "../utils";

export const SampleCollectionSlideOver = ({
	type = "create",
	//currentSampleCollection,
	isOpen,
	closeRequest,
	onComplete,
	project,
}) => {
	const user = getData("@storage_Key").then((user) => user);

	const [sampleCollection, setSampleCollection] = React.useState({});
	const [labs, setLabs] = React.useState([]);
	const [labLocations, setLabLocations] = React.useState([]);

	// React.useEffect(() => {
	// 	if (currentSampleCollection) {
	// 		setSampleCollection(currentSampleCollection);
	// 	}
	// }, [currentSampleCollection]);

	useQuery(gql(projectGQL.listLabs), {
		onCompleted: (data) => {
			setLabs([...data.listLabs.items]);
			setLabLocations(
				data.listLabs.items[0] ? [...data.listLabs.items[0].locations] : []
			);
		},
	});

	const [createSampleCollection] = useMutation(
		gql(projectGQL.createSampleCollection),
		{
			onCompleted: (data) => {
				onComplete(data.createSampleCollection);

				closeRequest();
			},
			onError: () => {
				Alert("Sample Collection could not be created. Please try again");
			},
		}
	);

	const [updateSampleCollection] = useMutation(
		gql(sampleCollectionGQL.updateSampleCollection),
		{
			onCompleted: (data) => {
				onComplete(data.updateSampleCollection);
			},
			onError: () => {
				Alert("Sample Collection could not be updated. Please try again");
			},
		}
	);

	return (
		<SlideOver
			isOpen={isOpen}
			name={
				type === "create" ? "New Sample Collection" : "Update Sample Collection"
			}
			description={
				type === "create"
					? `Add a new sample collection to ${project.title}`
					: `Update ${sampleCollection.title}`
			}
			closeRequest={() => closeRequest()}
			onSubmit={() => {
				let input = sampleCollection;

				if (type === "create") {
					input.project = project.id;
					input.date = DateTime.now().toISO();
					input.createdBy = user.id;
					createSampleCollection({
						variables: {
							input: sampleCollection,
						},
					});
				} else {
					updateSampleCollection({
						variables: {
							input: sampleCollection,
						},
					});
				}
			}}
			buttonRight={type === "create" ? "Create" : "Update"}
		>
			<Input
				required
				type="text"
				placeholder="Sample Collection Name"
				value={sampleCollection.name}
				onChange={(e) =>
					setSampleCollection({
						...sampleCollection,
						title: e,
					})
				}
			/>

			<>
				{labs.length ? (
					<Select
						name="Lab"
						required
						value={sampleCollection.lab}
						onChange={(value) => {
							let labData = JSON.parse(value.value);
							setSampleCollection({
								...sampleCollection,
								lab: labData.id,
							});
							setLabLocations(labData.locations);
						}}
						options={labs.map((lab) => {
							return {
								name: lab.name,
								value: JSON.stringify(lab),
							};
						})}
					/>
				) : (
					<Text className="mt-5">
						No Labs Avaliable. You can select the lab after creation
					</Text>
				)}
			</>
			<>
				{sampleCollection.lab && (
					<>
						{labLocations.length ? (
							<Select
								name="Lab Facility"
								required
								value={sampleCollection.location}
								onChange={(value) => {
									setSampleCollection({
										...sampleCollection,
										labLocation: value.id,
									});
								}}
								options={labLocations.map((location) => {
									return {
										name: location.name,
										id: location.id,
									};
								})}
							/>
						) : (
							<Text className="mt-5">
								No Lab Locations Avaliable. You can select the lab location
								after creation
							</Text>
						)}
					</>
				)}
			</>

			<Select
				name="Type"
				value={sampleCollection.type}
				onChange={(value) => {
					setSampleCollection({
						...sampleCollection,
						type: value.value,
					});
				}}
				options={sampleTypes.map((type) => {
					return {
						name: type.name,
						value: type.value,
					};
				})}
			/>
		</SlideOver>
	);
};

SampleCollectionSlideOver.propTypes = {
	type: PropTypes.string,
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func,
	onComplete: PropTypes.func,
	project: PropTypes.object.isRequired,
};
