import PropTypes from "prop-types";
import React from "react";
import {
	Platform,
	ScrollView,
	Text,
	TouchableOpacity,
	View,
} from "react-native";

// Components
import Button from "../components/Button";
import { Input } from "../components/Forms";

// Lighting
import { useColorScheme } from "nativewind";

// Icons
import { faSearch } from "@fortawesome/pro-solid-svg-icons";

export const List = ({
	title,
	headers,
	usesOptions,
	children,
	showFully = false,
	onSearch,
}) => {
	if (!showFully && Platform.OS !== "web") {
		return (
			<ScrollView className="h-full">
				{title && (
					<View className="px-4 pt-5 sm:px-6 mb-3">
						<Text className="text-gray-500 dark:text-white text-xs font-medium uppercase">
							{title}
						</Text>
					</View>
				)}
				<View className="border-t bg-white border-gray-200 dark:text-gray-500 divide-x divide-gray-100 dark:bg-gray-900 ">
					{onSearch && (
						<View className="mx-2">
							<Input
								icon={faSearch}
								className="w-full"
								placeholder="Search"
								onChange={onSearch}
							/>
						</View>
					)}
					{children}
				</View>
			</ScrollView>
		);
	}
	return (
		<>
			<ScrollView className="hidden">
				<View className="px-4 pt-5 sm:px-6">
					<Text className="text-gray-500 dark:text-gray-200 text-xs font-medium uppercase">
						{title}
					</Text>
				</View>
				<View className="mt-3 border-t bg-white border-gray-200 divide-x divide-gray-100 dark:bg-gray-900">
					{onSearch && (
						<View className="mx-2">
							<Input
								icon={faSearch}
								className="w-full"
								placeholder="Search"
								onChange={onSearch}
							/>
						</View>
					)}
					{children}
				</View>
			</ScrollView>

			<ScrollView className=" mt-0 block">
				<View className="align-middle inline-block min-w-full border-b border-gray-200">
					<View className="min-w-full">
						{onSearch && (
							<View className="mx-2">
								<Input
									icon={faSearch}
									className="w-full"
									placeholder="Search"
									onChange={onSearch}
								/>
							</View>
						)}
						<View>
							<View className="flex-row border-t-0 border-gray-200 dark:border-gray-500 ">
								{headers.map((header, index) => {
									return (
										<View
											key={index}
											style={{ flex: 1, alignSelf: "stretch" }}
											className="px-6 py-3 border-b border-gray-200  bg-gray-50 dark:border-gray-500 dark:bg-gray-800"
										>
											<Text className="text-gray-900 dark:text-white text-sm font-normal text-left uppercase">
												{header}
											</Text>
										</View>
									);
								})}

								{usesOptions && (
									<View className="pr-6 py-3 border-b border-gray-200 bg-gray-50 text-gray-500 dark:border-gray-500 dark:bg-gray-800 text-xs font-medium  uppercase " />
								)}
							</View>
						</View>
						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "center",
							}}
							className="bg-white dark:bg-gray-900"
						>
							{children}
						</View>
					</View>
				</View>
			</ScrollView>
		</>
	);
};

List.propTypes = {
	title: PropTypes.string,
	headers: PropTypes.arrayOf(PropTypes.string).isRequired,
	usesOptions: PropTypes.bool,
	children: PropTypes.node,
	showFully: PropTypes.bool,
	onSearch: PropTypes.func,
};

export const ListItem = ({
	items,
	options,
	buttons,
	smallView,
	showFully = false,
}) => {
	const renderContent = (content) => {
		// if content is text return the text tag wrapping the content

		if (typeof content === "string") {
			return (
				<Text className="text-gray-900 dark:text-white text-sm font-normal text-left">
					{content}
				</Text>
			);
		} else {
			return <View className=" bg-white dark:bg-gray-900">{content}</View>;
		}

		// return the content wrapped in a view tag
	};

	if (!showFully && Platform.OS !== "web") {
		return (
			<View className="w-full  bg-white dark:bg-gray-900 ">
				{smallView ? (
					<View className=" min-w-full group flex justify-between px-4 py-2 hover:bg-gray-50 dark:hover:bg-gray-700  ">
						{smallView}
						<View className="absolute z-50 top-4 right-4">
							{options && (
								<View className="flex align-self-end">{options}</View>
							)}
						</View>
					</View>
				) : (
					<View className="w-full  bg-white dark:bg-gray-900 ">
						<TouchableOpacity
							onPress={items[0].onPress ? items[0].onPress : () => {}}
							className="w-full flex flex-row items-center justify-between p-2 bg-white dark:bg-gray-900 "
						>
							<View className="">{renderContent(items[0].content)}</View>

							{options ? <View>{options}</View> : null}
						</TouchableOpacity>
					</View>
				)}
			</View>
		);
	} else {
		return (
			<>
				<View className="hidden min-w-full bg-white dark:bg-gray-900 ">
					{smallView ? (
						<View className=" min-w-full group flex items-center justify-between px-4 py-4 hover:bg-gray-50  bg-white dark:hover:bg-gray-700 sm:px-6 dark:bg-gray-900">
							{smallView}
							{options && (
								<View className="flex align-self-end">{options}</View>
							)}
						</View>
					) : (
						<View className="min-w-full flex flex-row items-center bg-white dark:bg-gray-900">
							<TouchableOpacity
								onPress={items[0].onPress ? items[0].onPress : () => {}}
								className="bg-white dark:bg-gray-900"
							>
								<View className="min-w-full group flex  justify-start px-4 py-4 hover:bg-gray-50  bg-white dark:hover:bg-gray-700 sm:px-6 dark:bg-gray-900">
									{renderContent(items[0].content)}
								</View>

								{options ? <View className="pr-6">{options}</View> : null}
							</TouchableOpacity>
						</View>
					)}
				</View>
				<View className="block relative flex-1 flex-row items-center self-stretch bg-white dark:bg-gray-900 ">
					{items.map((item, index) => {
						return (
							<TouchableOpacity
								onPress={() => {
									if (item.onPress) {
										item.onPress();
									}
								}}
								key={index}
								style={{ flex: 1, alignSelf: "stretch" }}
								className="bg-white dark:bg-gray-900 "
							>
								<View className="cursor-pointer px-6 py-3 bg-white dark:bg-gray-900 ">
									{renderContent(item.content)}
								</View>
							</TouchableOpacity>
						);
					})}

					{options && <View className="pr-3 float-right">{options}</View>}
					{buttons && (
						<View className="pr-3 pt-1 float-right">
							{buttons.map((button) => {
								return (
									<Button
										className={`mr-2 text-${button.color}-500`}
										key={button.name}
										title={button.name}
										onPress={() => button.onPress}
									/>
								);
							})}
						</View>
					)}
				</View>
			</>
		);
	}
};

ListItem.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			onPress: PropTypes.func,
			content: PropTypes.any,
		})
	),
	buttons: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string,
			onClick: PropTypes.func.isRequired,
			name: PropTypes.string.isRequired,
		})
	),
	options: PropTypes.node,
	smallView: PropTypes.node,
	showFully: PropTypes.bool,
};

export const PinnedList = ({ title, children }) => {
	return (
		<View className="w-full pt-3 border-b border-gray-200  bg-white dark:border-gray-500 dark:bg-gray-900">
			<Text className="pl-2 text-gray-500 dark:text-white w-full text-xs font-medium uppercase tracking-wide">
				Pinned {title}
			</Text>
			<ScrollView horizontal className="mt-3 ">
				{children}
			</ScrollView>
		</View>
	);
};

PinnedList.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
};

export const PinnedItem = ({ item, color, index, onPress = () => {} }) => {
	const { colorScheme } = useColorScheme();

	return (
		<View key={index}>
			<TouchableOpacity
				className="flex flex-row w-full m-2"
				onPress={() => onPress()}
			>
				<View
					className={`
					bg-${
						color || "indigo"
					}-600 flex-shrink-0 flex items-center justify-center w-16 h-16 rounded-l-lg
				`}
				>
					<Text className="text-white text-sm font-medium">
						{item.title.split(" ").map((word, wordIndex) => {
							if (wordIndex > 2) {
								return null;
							}
							return word[0];
						})}
					</Text>
				</View>
				<View className=" flex items-center justify-between border-t border-r border-b border-gray-200 bg-white dark:border-gray-500 dark:bg-gray-900 rounded-r-lg">
					<View className="px-4 py-2">
						<Text
							className={`${
								colorScheme === "light" ? "text-gray-900" : "text-white"
							} text-sm`}
						>
							{item.title}
						</Text>
						<Text className="text-gray-900 dark:text-white text-sm">
							{item.client && item.client.name ? item.client.name : "No Client"}
						</Text>
					</View>
				</View>
			</TouchableOpacity>
		</View>
	);
};

PinnedItem.propTypes = {
	item: PropTypes.object,
	index: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
	color: PropTypes.string,
	onPress: PropTypes.func,
};

export const BillingTable = ({ header = [], children, showFooter = false }) => {
	return (
		<View className="px-4 sm:px-6 lg:px-8">
			<View className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
				<View className="w-full divide-y divide-gray-300">
					<View className="flex flex-row ">
						{header.map((item, index) => (
							<Text
								key={`billing-View-header-${index}`}
								scope="col"
								className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 dark:text-white sm:pl-6 md:pl-0 flex-1"
							>
								{item}
							</Text>
						))}
					</View>

					<View>{children}</View>
					<>
						{showFooter && (
							<View>
								<View>
									<Text
										scope="row"
										colSpan={header.length}
										className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:View-cell md:pl-0"
									>
										Subtotal
									</Text>
									<Text
										scope="row"
										className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden"
									>
										Subtotal
									</Text>
									<Text className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
										$3,900.00
									</Text>
								</View>
								<View>
									<Text
										scope="row"
										colSpan={header.length}
										className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:View-cell md:pl-0"
									>
										Tax
									</Text>
									<Text
										scope="row"
										className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
									>
										Tax
									</Text>
									<Text className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
										$585.00
									</Text>
								</View>
								<View>
									<Text
										scope="row"
										colSpan={header.length}
										className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:View-cell md:pl-0"
									>
										Total
									</Text>
									<Text
										scope="row"
										className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
									>
										Total
									</Text>
									<Text className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
										$4,485.00
									</Text>
								</View>
							</View>
						)}
					</>
				</View>
			</View>
		</View>
	);
};

BillingTable.propTypes = {
	header: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.any,
	showFooter: PropTypes.bool,
};

export const BillingItem = ({ items }) => {
	return (
		<View className="border-b border-gray-200 flex flex-row">
			{items.map((item, index) => (
				<View
					key={`${item}-${index}`}
					className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0 flex-1 items-start  "
				>
					{typeof item === "string" ? (
						<Text className="text-xs text-gray-900 dark:text-white text-left">
							{item}
						</Text>
					) : (
						item
					)}
				</View>
			))}
		</View>
	);
};

BillingItem.propTypes = {
	items: PropTypes.any,
};
