/* This example requires Tailwind CSS v2.0+ */

import DateTimePicker from "@react-native-community/datetimepicker";
import CheckboxCode from "expo-checkbox";
import * as DocumentPicker from "expo-document-picker";
import * as ImagePicker from "expo-image-picker";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
	Modal,
	Platform,
	ScrollView,
	Switch as SwitchCode,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";

// Components
import Badge from "./Badge";
import Button from "./Button";

// Icon
import {
	faArrowLeft,
	faCamera,
	faCloudUploadAlt,
	faFile,
	faImages,
	faTimes,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

// Lighting
import { useColorScheme } from "nativewind";

export const Select = ({
	name,
	defaultValue = "",
	description,
	options,
	onChange,
	icon,
	multiple,
}) => {
	const { colorScheme } = useColorScheme();
	let [open, setOpen] = React.useState(false);
	let [option, setOption] = React.useState(null);
	let [multiOptions, setMultiOptions] = React.useState([]);
	let [search, setSearch] = React.useState("");
	let optionsWithOutAKey = options.filter(
		(option) => option.key === null || option.key === undefined
	).length;

	if (multiple && optionsWithOutAKey) {
		throw new Error(
			"When using a multiselect, you must provide a key value named key. This is usually the index"
		);
	}

	return (
		<View className="mt-5 w-full">
			<Text
				testID="name-text"
				className="pb-1 font-medium text-gray-700 dark:text-gray-300"
			>
				{name}
			</Text>
			<TouchableOpacity onPress={() => setOpen(true)}>
				<View className="w-full flex-row items-center  h-8  border-indigo-500 border rounded pl-2">
					{icon && (
						<FontAwesomeIcon
							icon={icon}
							className={"h-5 w-5 text-gray-400"}
							color={"#111827"}
						/>
					)}
					{!multiple ? (
						<Text className="font-medium text-gray-900 dark:text-white">
							{option ? option.name : defaultValue}
						</Text>
					) : (
						<View className="flex flex-row items-center h-8">
							{multiOptions.length ? (
								<>
									{multiOptions.map((option, index) => {
										return (
											<View key={index} className="mr-2 ">
												<Badge text={option.name} size="sm" />
											</View>
										);
									})}
								</>
							) : (
								<Text className="font-medium text-gray-500 dark:text-white ">
									Select Options
								</Text>
							)}
						</View>
					)}
				</View>
			</TouchableOpacity>

			<Modal
				animationType="slide"
				transparent={false}
				visible={open}
				onRequestClose={() => {
					setOpen(false);
				}}
			>
				<View className="pb-1 h-full w-full bg-white dark:bg-gray-900">
					<View className="w-full flex-row items-center justify-between px-5 pt-12 pb-4">
						<View>
							<TouchableOpacity
								onPress={() => {
									setOpen(false);
									setSearch("");
								}}
							>
								<FontAwesomeIcon
									icon={faArrowLeft}
									color={colorScheme === "light" ? "black" : "white"}
								/>
							</TouchableOpacity>
						</View>
						<View>
							<Text className="text-right text-lg font-bold text-gray-900 dark:text-white">
								{name} Selection
							</Text>
							{description && (
								<Text className="text-right text-gray-900 dark:text-white  w-80">
									{description}
								</Text>
							)}
						</View>
					</View>

					<ScrollView>
						<View className="px-2 mb-2">
							<Input
								icon={icon && icon}
								label={`Search ${name}`}
								placeholder={`Search ${name}`}
								onChange={(e) => setSearch(e.toLowerCase())}
							/>
						</View>
						{options &&
							options.length &&
							options
								.filter(
									(option) =>
										option.name && option.name.toLowerCase().includes(search)
								)
								.sort((a, b) => {
									if (a.name < b.name) {
										return -1;
									}
									if (a.name > b.name) {
										return 1;
									}
									return 0;
								})
								.map((item, index) => {
									return (
										<TouchableOpacity
											key={index}
											onPress={() => {
												if (!multiple) {
													setOption(item);
													setSearch("");
													setOpen(false);
													if (onChange) {
														onChange(item);
													}
												} else {
													if (
														multiOptions.find(
															(option) => option.key === item.key
														)
													) {
														// remove the item
														let newSelectedOptions = multiOptions.filter(
															(option) => option.key !== item.key
														);
														setMultiOptions(() => newSelectedOptions);
														if (onChange) {
															onChange(newSelectedOptions);
														}
													} else {
														// insert the item
														setMultiOptions(() => [...multiOptions, item]);
														if (onChange) {
															onChange([...multiOptions, item]);
														}
													}
												}
											}}
										>
											{multiple ? (
												<View className="h-16 w-full border-gray-500 border-b-2 flex-row items-center px-2">
													<View
														className={`flex justify-center items-center rounded-full ${
															multiOptions &&
															multiOptions.find(
																(option) => item.name === option.name
															)
																? "border-indigo-500  bg-indigo-500"
																: "border-gray-500"
														} border-2 h-5 w-5`}
													>
														{multiOptions &&
															multiOptions.find(
																(option) => item.name === option.name
															) && (
																<View className="rounded-full bg-white  h-2 w-2" />
															)}
													</View>
													<Text className="px-2 text-gray-900 dark:text-white">
														{item.name}
													</Text>
												</View>
											) : (
												<View className="h-16 w-full border-gray-500 border-b-2 flex-row items-center px-2">
													<View
														className={`flex justify-center items-center rounded-full ${
															option && item.name === option.name
																? "border-indigo-500  bg-indigo-500"
																: "border-gray-500"
														} border-2 h-5 w-5`}
													>
														{option && item.name === option.name && (
															<View className="rounded-full bg-white  h-2 w-2" />
														)}
													</View>
													<Text className="px-2 text-gray-900 dark:text-white">
														{item.name}
													</Text>
												</View>
											)}
										</TouchableOpacity>
									);
								})}
					</ScrollView>
				</View>
			</Modal>
		</View>
	);
};

export const Switch = ({
	label,
	info,
	onChange,
	defaultValue = false,
	value,
	disabled = false,
}) => {
	const [enabled, setEnabled] = React.useState(defaultValue);

	React.useState(() => {
		setEnabled(value);
	}, [value]);
	return (
		<View className="flex-row w-full mt-5 items-center justify-between">
			{(label || info) && (
				<View className="flex-grow flex flex-col w-4/6">
					{label && (
						<Text
							testID="label-text"
							className="text-sm  text-gray-900 dark:text-white "
						>
							{label}
						</Text>
					)}

					{info && <Text className="text-sm text-gray-500">{info}</Text>}
				</View>
			)}
			<SwitchCode
				disabled={disabled}
				trackColor={{ false: "#767577", true: "#6366f1" }}
				thumbColor={enabled ? "#fff" : "#f4f3f4"}
				ios_backgroundColor="#3e3e3e"
				onValueChange={() => {
					onChange(!enabled);
					setEnabled(!enabled);
				}}
				value={enabled}
			/>
		</View>
	);
};

export const Input = (props) => {
	const [show, setShow] = React.useState(false);

	const formatDate = (date, time) => {
		if (props.type === "date") {
			return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
		}

		var hours = time.getHours();
		var minutes = time.getMinutes();
		var amPM = hours >= 12 ? "PM" : "AM";
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? "0" + minutes : minutes;
		var strTime = hours + ":" + minutes + " " + amPM;

		return `${
			date.getMonth() + 1
		}/${date.getDate()}/${date.getFullYear()} ${strTime}`;
	};

	const [date, setDate] = useState(
		props.value ? new Date(props.value) : new Date() // to set default from props or current date
	);
	const [time, setTime] = useState(
		props.value ? new Date(props.value) : new Date() // to set default from props or current date
	);
	const [mode, setMode] = useState("date");

	const onChangeDateTime = (event, selectedValue) => {
		setShow(Platform.OS === "ios");
		props.onChange(selectedValue);

		if (Platform.OS === "ios" && props.type === "datetime-local") {
			setMode("datetime");
			const currentDate = selectedValue || new Date();
			setDate(currentDate);
			setTime(currentDate);
			return;
		}

		if (Platform.OS === "android" && props.type === "date") {
			const currentDate = selectedValue || new Date();
			setDate(currentDate);
			setShow(false);
			return;
		}

		// for date and time
		if (mode == "date") {
			const currentDate = selectedValue || new Date();
			setDate(currentDate);
			setMode("time");
			setShow(Platform.OS !== "ios"); // to show time
		} else {
			const selectedTime = selectedValue || new Date();
			setTime(selectedTime);
			setShow(Platform.OS === "ios"); // to hide back the picker
			setMode("date"); // defaulting to date for next open
		}
	};
	const showMode = (currentMode) => {
		setShow(true);
		setMode(currentMode);
	};

	const showDatePicker = () => {
		if (Platform.OS === "ios" && props.type === "datetime-local") {
			showMode("datetime");
		} else {
			showMode("date");
		}
	};

	// date only
	if (props.type === "date") {
		if (Platform.OS === "web") {
			return (
				<InputShell {...props}>
					<input
						className={
							"w-full text-gray-900 dark:text-white border-none outline-none "
						}
						type="date"
						onChange={(e) => {
							props.onChange(e.target.value);
						}}
						value={props.value}
					/>
				</InputShell>
			);
		}

		if (Platform.OS === "android" || Platform.OS === "ios") {
			return (
				<InputShell {...props}>
					<View>
						<TouchableOpacity onPress={showDatePicker}>
							<Text className="text-gray-900 dark:text-white">
								{formatDate(date, time, true)}
							</Text>
							{!show && (
								<Text className="text-xs text-gray-500 mt-2">
									Tap to change date
								</Text>
							)}
						</TouchableOpacity>
						{show && (
							<DateTimePicker
								value={date}
								//minimumDate={Date.parse(new Date())}
								display="default"
								mode={mode}
								onChange={onChangeDateTime}
							/>
						)}
					</View>
				</InputShell>
			);
		}
	}

	// date and time

	if (props.type === "datetime-local") {
		if (Platform.OS === "web") {
			return (
				<InputShell {...props}>
					<input
						className={`${
							props.icon && "pl-2"
						} mt-1  relative rounded-md shadow-sm  flex flex-row items-center  w-full  focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-8  border-gray-300 bg-white dark:bg-gray-900 dark:text-white   dark:border-indigo-500 dark:border`}
						type="datetime-local"
						onChange={(e) => {
							props.onChange(e.target.value);
						}}
						value={props.value}
					/>
				</InputShell>
			);
		}

		if (Platform.OS === "android" || Platform.OS === "ios") {
			return (
				<InputShell {...props}>
					<View>
						<TouchableOpacity onPress={showDatePicker}>
							<Text className="text-indigo-500">{formatDate(date, time)}</Text>
							{!show && (
								<Text className="text-xs text-gray-500 mt-2">
									Tap to change date and time
								</Text>
							)}
						</TouchableOpacity>
						{show && (
							<DateTimePicker
								value={date}
								// minimumDate={Date.parse(new Date())}
								display="default"
								mode={mode}
								onChange={onChangeDateTime}
							/>
						)}
					</View>
				</InputShell>
			);
		}
	}

	return (
		<InputShell {...props}>
			<TextInput
				disabled={props.disabled}
				type={props.type ? props.type : "text"}
				name={props.label}
				className={`w-full h-full px-2 text-gray-900 dark:text-white ${
					props.icon && ""
				} ${
					props.error ? "border-red-500" : "border-indigo-500"
				} border rounded-md`}
				placeholder={props.placeholder}
				required={props.required}
				onBlur={(e) => {
					if (props.onBlur) {
						props.onBlur(e);
					}
				}}
				onChangeText={(e) => {
					if (props.onChange) {
						props.onChange(e);
					}
				}}
				value={props.value && props.value}
				step={props.step && props.step}
				autoComplete={props.autoComplete && props.autoComplete}
				secureTextEntry={props.secureTextEntry}
				autoCorrect={props.autoCorrect || false}
				autoCapitalize={props.autoCapitalize || "sentences"}
				keyboardType={props.keyboardType || "default"}
				ref={props.ref}
			/>
			{props.error && (
				<Text className="text-xs text-red-500 mt-2">{props.error}</Text>
			)}
		</InputShell>
	);
};

export const Checkbox = ({
	required,
	label,
	description,
	checkedValue = false,
	onChange,
	disabled = false,
}) => {
	let [checked, setChecked] = React.useState(checkedValue);

	React.useEffect(() => {
		setChecked(checkedValue);
	}, [checkedValue]);

	return (
		<TouchableOpacity
			onPress={() => {
				if (!disabled) {
					onChange(!checked);
					setChecked(!checked);
				}
			}}
			className=" flex-row items-start my-3"
		>
			<View className="flex items-center h-5">
				<CheckboxCode
					disabled={disabled}
					value={checked}
					required={required}
					id="offers"
					name="offers"
					type="checkbox"
					className="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 dark:bg-gray-800 rounded"
					onValueChange={(e) => {
						onChange(e);
						setChecked(e);
					}}
				/>
			</View>
			{(label || description) && (
				<View className="ml-3 text-sm flex items-start">
					<Text
						testID="label-text"
						htmlFor="offers"
						className="font-medium text-gray-700 dark:text-white"
					>
						{label && label}
					</Text>

					<Text testID="description-text" className="text-gray-500 text-wrap">
						{description && description}
					</Text>
				</View>
			)}
		</TouchableOpacity>
	);
};

export const Radio = ({
	label,
	onChange,
	options,
	horizontal,
	defaultValue,
}) => {
	let [checked, setChecked] = React.useState(null);

	return (
		<View className>
			{label && (
				<Text
					testID="label-text"
					className="font-medium  pb-2 text-gray-900 dark:text-white"
				>
					{label}
				</Text>
			)}
			<View className={`${horizontal ? "flex-row" : "flex-col"}`}>
				{options &&
					options.map((option, index) => {
						return (
							<TouchableOpacity
								key={index}
								onPress={() => {
									setChecked(index);
									if (onChange) {
										onChange(options[index]);
									}
								}}
							>
								<View className="w-full flex-row items-start pl-2 pb-2">
									<View
										className={`flex justify-center items-center rounded-full ${
											defaultValue === option.label || index === checked
												? "border-indigo-500  bg-indigo-500"
												: "border-gray-500"
										} border-2 h-5 w-5`}
									>
										{defaultValue === option.label ||
											(index === checked && (
												<View className="rounded-full bg-white  h-2 w-2" />
											))}
									</View>
									<View>
										<Text className="text-xs sm:text-sm font-medium px-2 text-gray-900 dark:text-white overflow-wrap">
											{option.label}
										</Text>
										<Text className="text-sm px-2 text-gray-500">
											{option.description && option.description}
										</Text>
									</View>
								</View>
							</TouchableOpacity>
						);
					})}
			</View>
		</View>
	);
};

export const FieldSet = ({ legend, description, children }) => {
	return (
		<View>
			<Text
				testID="legend-text"
				className="text-sm font-medium text-gray-900 dark:text-white"
			>
				{legend}
			</Text>

			<Text className="block text-sm font-light text-gray-700 dark:text-white">
				{description && description}
			</Text>

			{children}
		</View>
	);
};

export const TextArea = ({
	value,
	label,
	onBlur,
	row = 3,
	defaultValue,
	required,
	onChange = () => {},
}) => {
	return (
		<View className="sm:col-span-6">
			<Text
				testID="label-text"
				htmlFor={label}
				className="block text-sm font-medium text-gray-700 dark:text-white"
			>
				{label}
			</Text>
			<View className="mt-1">
				<TextInput
					onChangeText={(e) => {
						onChange(e);
					}}
					onBlur={(e) => {
						if (onBlur) {
							onBlur(e);
						}
					}}
					multiline
					id={label}
					name={label}
					numberOfLines={row}
					value={value && value}
					required={required}
					className="h-36 mt-1 px-2 relative rounded-md shadow-sm  flex flex-row items-center  w-full border-2 bord focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 bg-white dark:bg-gray-900 dark:text-white dark:border-indigo-500 dark:border"
					defaultValue={defaultValue}
				/>
			</View>
		</View>
	);
};

export const FileUpload = ({
	label,
	onFiles,
	type = "*/*",
	multiple = false,
}) => {
	const [files, setFiles] = useState([]);
	const [open, setOpen] = useState(false);
	const { colorScheme } = useColorScheme();

	React.useEffect(() => {
		if (files.length > 0) {
			onFiles(files);
		}
	}, [files]);

	const getMedia = async (camera = false) => {
		let result;
		if (camera) {
			result = await ImagePicker.launchCameraAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.All,
				allowsMultipleSelection: multiple,
				quality: 1,
			});
		} else {
			result = await ImagePicker.launchImageLibraryAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.All,
				allowsMultipleSelection: multiple,
				quality: 1,
			});
		}

		if (Platform.OS === "ios") {
			if (!result.canceled) {
				setFiles((previousFiles) => [
					...previousFiles,
					{ ...result, name: result.uri.split("/").pop() },
				]);
			}
		}
		if (Platform.OS === "web") {
			if (!result.didCancel) {
				setFiles((previousFiles) => [
					...previousFiles,
					{ ...result, name: result.uri.split("/").pop() },
				]);
			}
		}
	};

	return (
		<>
			{(!files.length > 0 || multiple) && (
				<>
					{Platform.OS === "web" ? (
						<View className="max-w-lg ">
							<Text className="text-md font-bold">{label}</Text>
							<TouchableOpacity
								onPress={() => {
									DocumentPicker.getDocumentAsync({
										type: type,
										multiple: multiple,
									}).then(async (res) => {
										if (Array.isArray(res)) {
											setFiles((prev) => [...prev, ...res]);
										} else {
											setFiles((prev) => [...prev, res]);
										}
									});
								}}
								className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
							>
								<View className="space-y-1 text-center flex items-center">
									<FontAwesomeIcon
										icon={faCloudUploadAlt}
										size={30}
										color={colorScheme === "dark" ? "#fff" : "#000"}
										className="mx-auto h-12 w-12 text-gray-400"
									/>
									<Text className="text-xs text-gray-500">File up to 10MB</Text>
								</View>
							</TouchableOpacity>
						</View>
					) : (
						<>
							<Button
								text={label}
								onPress={() => {
									setOpen(true);
								}}
							/>
							<Modal
								visible={open}
								onBackdropPress={() => setOpen(false)}
								transparent={true}
								animationIn="fadeIn"
								animationOut="fadeOut"
								animationInTiming={500}
								animationOutTiming={500}
								backdropTransitionInTiming={500}
							>
								<View className="h-full w-full bg-gray-900 opacity-75"></View>
								<View className="absolute bottom-0 right-0 flex flex-col items-center  justify-center w-full h-52">
									<View className="bg-white dark:bg-gray-800 rounded-lg p-4 w-full h-full">
										<View className="flex flex-row justify-between">
											<TouchableOpacity
												onPress={() => {
													setOpen(false);
												}}
												className="p-2 flex flex-row"
											>
												<FontAwesomeIcon icon={faTimes} />
											</TouchableOpacity>
										</View>

										<View className="p-2">
											<TouchableOpacity
												onPress={async () => {
													await getMedia(true);
													setOpen(false);
												}}
												className="p-2 flex flex-row"
											>
												<FontAwesomeIcon icon={faCamera} />
												<Text className="ml-2">Camera</Text>
											</TouchableOpacity>
										</View>
										<View className="p-2">
											<TouchableOpacity
												onPress={async () => {
													await getMedia(false);
													setOpen(false);
												}}
												className="p-2 flex flex-row"
											>
												<FontAwesomeIcon icon={faImages} />
												<Text className="ml-2">Pick from Camera Roll</Text>
											</TouchableOpacity>
										</View>
										<View className="p-2">
											<TouchableOpacity
												onPress={() => {
													DocumentPicker.getDocumentAsync({
														type: type,
														multiple: multiple,
													})
														.then(async (res) => {
															if (Array.isArray(res)) {
																setFiles((prev) => [...prev, ...res]);
															} else {
																setFiles((prev) => [...prev, res]);
															}
															setOpen(false);
														})
														.catch(() => {
															setOpen(false);
														});
												}}
												className="p-2 flex flex-row"
											>
												<FontAwesomeIcon icon={faFile} />
												<Text className="ml-2">Select File</Text>
											</TouchableOpacity>
										</View>
									</View>
								</View>
							</Modal>
						</>
					)}
				</>
			)}

			{files.length ? (
				<View className="border-t-2 mt-2">
					<Text className="text-xs text-gray-500">
						{files.length} {files.length > 1 ? "files" : "file"} selected for
						upload
					</Text>
					{files.map((selectedFile, index) => {
						return (
							<View
								key={index}
								className="flex flex-row justify-between my-2 items-center"
							>
								<Text>{selectedFile.name}</Text>
								<TouchableOpacity
									onPress={() => {
										setFiles((prev) => {
											return prev.filter(
												(file) => file.name !== selectedFile.name
											);
										});
									}}
								>
									<FontAwesomeIcon
										icon={faTimes}
										size={20}
										color={colorScheme === "dark" ? "#fff" : "#000"}
									/>
								</TouchableOpacity>
							</View>
						);
					})}
				</View>
			) : null}
		</>
	);
};

FileUpload.propTypes = {
	onFiles: PropTypes.func.isRequired,
	type: PropTypes.string,
	multiple: PropTypes.bool,
	label: PropTypes.string,
};

Select.propTypes = {
	name: PropTypes.string || PropTypes.number,
	defaultValue: PropTypes.string,
	description: PropTypes.string,
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func,
	icon: PropTypes.any,
	multiple: PropTypes.any,
};

Switch.propTypes = {
	label: PropTypes.string,
	info: PropTypes.string,
	onChange: PropTypes.func,
	defaultValue: PropTypes.bool,
	value: PropTypes.bool,
	disabled: PropTypes.bool,
};

TextArea.propTypes = {
	value: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	row: PropTypes.number,
	defaultValue: PropTypes.string,
	required: PropTypes.bool,
};

FieldSet.propTypes = {
	legend: PropTypes.string,
	description: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

Radio.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	description: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	defaultValue: PropTypes.string,
	options: PropTypes.array.isRequired,
	horizontal: PropTypes.bool,
};

Checkbox.propTypes = {
	required: PropTypes.bool,
	name: PropTypes.string,
	label: PropTypes.string,
	description: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	checkedValue: PropTypes.bool,
	disabled: PropTypes.bool,
};

Input.propTypes = {
	className: PropTypes.string,
	required: PropTypes.bool,
	label: PropTypes.string,
	helper: PropTypes.string,
	icon: PropTypes.any,
	placeholder: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	require: PropTypes.bool,
	disabled: PropTypes.bool,
	autoComplete: PropTypes.string,
	value: PropTypes.any,
	step: PropTypes.string,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onChange: PropTypes.func,
	secureTextEntry: PropTypes.bool,
	autoCapitalize: PropTypes.string,
	autoCorrect: PropTypes.bool,
	time: PropTypes.string,
	keyboardType: PropTypes.string,
	error: PropTypes.any,
	ref: PropTypes.any,
};

const InputShell = ({ label, icon, children, helper, type }) => {
	return (
		<View className={"my-3 w-full "}>
			<Text
				testID="label-text"
				htmlFor={label}
				className="block text-sm font-medium text-gray-700 dark:text-gray-300"
			>
				{label}
			</Text>
			{type === "date" || type === "datetime-local" ? (
				<View
					className={`${
						icon && "pl-2"
					} mt-1  relative rounded-md shadow-sm  flex flex-row items-center  w-full  focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-8  border-gray-300 bg-white dark:bg-gray-900 dark:text-white   dark:border-indigo-500 dark:border`}
				>
					{children}
				</View>
			) : (
				<View
					className={`${
						icon && "pl-2"
					} mt-1  relative rounded-md shadow-sm  flex flex-row items-center  w-full  focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-8  border-gray-300 bg-white dark:bg-gray-900 dark:text-white   dark:border-indigo-500 dark:border`}
				>
					{icon && (
						<View className="">
							<FontAwesomeIcon icon={icon} aria-hidden="true" />
						</View>
					)}
					{children}
				</View>
			)}
			{helper && <Text className="mt-1 text-sm text-gray-500">{helper}</Text>}
		</View>
	);
};

InputShell.propTypes = {
	label: PropTypes.string,
	info: PropTypes.string,
	children: PropTypes.any,
	helper: PropTypes.string,
	type: PropTypes.string,
	icon: PropTypes.any,
};
