// Utils
import { s3 } from "../utils";

/**
 * @typedef FileReturnObject
 * @property {string} key the key for where the file is placed in S3
 * @property {string} Location
 * @property {string} Etag
 */

/**
 * uploadFile
 * @param {object} file The file object. This file should include a uri key
 * @param {string} key The key for where the file will be placed in S3
 * @return {Promise<FileReturnObject|String>}  if successful the promise returns the key the data from the s3 upload as an object else it returns the s3 error
 * @description the function uploads a file that has been retrieved from expo-document-picker. Please see https://docs.expo.dev/versions/latest/sdk/document-picker/ for more information
 * @authors Connor Sampson <csampson@srpenvironmental.net> and Gabryel Jackson <gjackson@srpenvironmental.net>
 * @since 3.0.17
 *
 * @example
 * uploadFile(file, "location/in/s3/to/place/the/file")
 *  .then((data) => {
 *
 *  })
 *  .catch((error) =>
 */

export const uploadFile = (file, key) => {
	// error checking
	if (!file) {
		throw new Error("You must supply a file object");
	}
	if (typeof file !== "object" || !file.uri) {
		throw new Error(
			"You must supply a file object with a uri key. Please see https://docs.expo.dev/versions/latest/sdk/document-picker/ for more information for how you can retrieve a file correctly"
		);
	}
	if (!key || typeof key !== "string") {
		throw new Error("You must supply a key for the s3 location");
	}

	// all is good upload the file
	return new Promise(async (resolve, reject) => {
		if (typeof file === "string") {
			resolve(file);
		}

		let fileData = await fetch(file.uri);
		fileData = await fileData.blob();

		const params = {
			Bucket: "srp-in-field",
			Key: key,
			Body: fileData,
		};
		s3.upload(params, function (err, data) {
			if (err) {
				reject(err);
			}
			resolve({ ...data, key });
		});
	});
};

/**
 * uploadFile
 * @param {string} key the key for where the file is placed in s3
 * @return {string}  the signed url of the file from S3
 * @description this function returns the signed url of file from s3
 * @authors Connor Sampson <csampson@srpenvironmental.net> and Gabryel Jackson <gjackson@srpenvironmental.net>
 * @since 3.0.17
 *
 * @example const signedURL = getSignedUrl("location/in/s3/to/place/the/file");
 */
export const getSignedUrl = (key) => {
	let params = { Bucket: "srp-in-field", Key: key };
	return s3.getSignedUrl("getObject", params);
};

/**
 * uploadFile
 * @param {string} key the key for where the file is placed in s3
 * @return {object}  an object of the file object attributes in s3
 * @description this function returns the signed url of file from s3
 * @authors Connor Sampson <csampson@srpenvironmental.net> and Gabryel Jackson <gjackson@srpenvironmental.net>
 * @since 3.0.17
 *
 * @example const signedURL = getFileAttributes("location/in/s3/to/place/the/file");
 */

export const getFileAttributes = (key) => {
	if (!key) {
		throw new Error("No key was provided");
	}

	const input = {
		Bucket: "srp-in-field",
		Key: key,
	};

	return new Promise(async (resolve, reject) => {
		try {
			const response = s3.getObjectAttributes(input);

			resolve(response);
		} catch (error) {
			reject(error);
		}
	});
};
