import { useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Text } from "react-native";

// Components

import { PageHeader } from "../../components/Header";
import NoItems from "../../components/NoItems";
import Pagination from "../../components/Pagination";
import { Table } from "../../components/Table";

// Containers

import { LabSlideOver } from "../../containers/LabSlideOver";
import { LocationSlideOver } from "../../containers/LocationSlideOver";

// GQL

import { gql, useQuery } from "@apollo/client";
import { View } from "react-native";
import * as labGQL from "../../graphql/lab";

// Icon

import { faPen, faPlus } from "@fortawesome/pro-duotone-svg-icons";

export function Labs({}) {
	const navigation = useNavigation();
	const [openType, setOpenType] = useState(null);
	const [labs, setLabs] = useState(null);
	const [editLab, setEditLab] = useState(null);
	const itemsPerPage = 20;
	let [currentPage, setCurrentPage] = useState(1);

	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end = labs
		? currentPage * itemsPerPage > labs.length
		: currentPage * itemsPerPage;

	const { refetch: labsRefetch } = useQuery(gql(labGQL.listLabs), {
		onCompleted: (data) => {
			setLabs([...data.listLabs.items]);
		},
	});

	const navigateToLab = (id) => {
		navigation.navigate("Lab", {
			id: id,
		});
	};

	return (
		<View className="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-white dark:bg-gray-900">
			{/* Page title & actions */}
			<PageHeader
				openMenu
				title="Labs"
				buttons={[
					{
						name: "New Lab",
						icon: faPlus,
						onPress: () => {
							setOpenType("newLab");
						},
					},
				]}
			/>

			{labs && labs.length ? (
				<>
					<Table
						headers={["Name", "Locations"]}
						data={labs.map((lab) => {
							return {
								onPress: () => navigateToLab(lab.id),
								cells: [
									{
										content: (
											<Text className="dark:text-white">{lab.name}</Text>
										),
									},
									{
										content: (
											<Text className="dark:text-white">
												{lab.locations.length}
											</Text>
										),
									},
								],
								options: [
									{
										name: "Edit",
										icon: faPen,
										onPress: () => {
											setEditLab(lab);
											setItem("editLab");
										},
									},
								],
							};
						})}
					/>

					<Pagination
						start={start}
						stop={end}
						total={labs.length}
						nextClicked={() => setCurrentPage(currentPage + 1)}
						previousClicked={() => setCurrentPage(currentPage - 1)}
					/>
				</>
			) : (
				<NoItems
					surTitle={"No Lab"}
					title="No Labs Found"
					text="You can create a lab by tapping the button above."
				/>
			)}

			{/* New Project Role */}
			<LabSlideOver
				isOpen={openType !== null}
				onComplete={() => {
					setOpenType(null);
					labsRefetch();
				}}
				closeRequest={() => {
					setOpenType(null);
				}}
				type={openType === "newLab" ? "create" : "update"}
				currentLab={openType === "newLab" ? null : editLab}
			/>
		</View>
	);
}

export function Lab({ route }) {
	const { id } = route.params;
	const [openType, setOpenType] = useState(null);
	const [editLocation, setEditLocation] = useState(null);

	const {
		data: lab,
		refetch,
		loading,
	} = useQuery(gql(labGQL.getLab), {
		variables: {
			id: id,
		},

		fetchPolicy: "cache-and-network",
	});

	if (loading) return <Text>Loading...</Text>;

	return (
		<View>
			<PageHeader
				title="Lab"
				goBack={{
					location: "Labs",
				}}
				buttons={[
					{
						name: "New Location",
						icon: faPlus,
						onPress: () => {
							setOpenType("newLocation");
						},
					},
				]}
			/>

			{lab.locations && lab.locations.length ? (
				<Table
					headers={["Name", "Address"]}
					data={lab.locations.map((location) => {
						return {
							cells: [
								{
									content: (
										<Text className="dark:text-white">{location.name}</Text>
									),
								},
								{
									content: (
										<Text className="dark:text-white">{location.lineOne}</Text>
									),
								},
							],
							options: [
								{
									name: "Edit",
									icon: faPen,
									onPress: () => {
										setEditLocation(location);
										setOpenType("editLocation");
									},
								},
							],
						};
					})}
				/>
			) : (
				<NoItems
					surTitle={"No Locations"}
					title="No Locations Found"
					text="You can create a location by tapping the button above."
				/>
			)}

			<LocationSlideOver
				isOpen={openType !== null}
				onComplete={() => {
					setOpenType(null);
					refetch();
				}}
				closeRequest={() => {
					setOpenType(null);
				}}
				type={openType === "newLocation" ? "create" : "update"}
				currentLocation={
					openType === "newLocation" ? { lab: lab.id } : editLocation
				}
			/>
		</View>
	);
}

Lab.propTypes = {
	route: PropTypes.object,
};
