import React from "react";
import PropTypes from "prop-types";
import { View, Text, TouchableOpacity, Image } from "react-native";

export default function Gallery({ className, children }) {
	return (
		<View className={`flex flex-row flex-wrap ${className && className}`}>
			{children}
		</View>
	);
}

Gallery.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export const GalleryItem = ({ source, name, size }) => {
	return (
		<View className="relative m-4">
			<View
				className={
					"focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 dark:bg-gray-800 overflow-hidden"
				}
			>
				<>
					{source && (
						<Image
							source={{ uri: source }}
							className={
								"h-20 w-20 md:h-40 md:w-40 rounded-lg group-hover:opacity-75 object-cover pointer-events-none"
							}
						/>
					)}
				</>

				<TouchableOpacity
					type="button"
					className="absolute inset-0 focus:outline-none"
				>
					<Text testID="button-name-text" className="sr-only">
						View details for {name}
					</Text>
				</TouchableOpacity>
			</View>
			<>
				<Text
					testID="name-text"
					className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none"
				>
					{name}
				</Text>
				<Text
					testID="size-text"
					className="block text-sm font-medium text-gray-500 pointer-events-none"
				>
					{size}
				</Text>
			</>
		</View>
	);
};

GalleryItem.propTypes = {
	source: PropTypes.string,
	name: PropTypes.string,
	size: PropTypes.string,
};
