import PropTypes from "prop-types";
import React from "react";

// Components
import { Alert } from "../components/Alert";
import {
	Input,
	Select,
	// FileUpload,
} from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation } from "@apollo/client";
import * as areaGQL from "../graphql/homogeneousArea";

// UTILS
import { SentryLogger } from "../utils";

export const HomogeneousAreaSlideOver = ({
	type = "create",
	currentArea,
	currentSampleCollection,
	isOpen,
	closeRequest,
	onCompleted,
}) => {
	const [homogeneousArea, setHomogeneousArea] = React.useState({});
	const [sampleCollection, setSampleCollection] = React.useState(null);

	React.useEffect(() => {
		if (currentArea) {
			setHomogeneousArea(currentArea);
		}
		if (currentSampleCollection) {
			setSampleCollection(currentSampleCollection);
		}
	}, [currentArea, currentSampleCollection]);

	const [createHomogeneousArea] = useMutation(
		gql(areaGQL.createHomogeneousArea),
		{
			onCompleted: (data) => {
				onCompleted(data.createHomogeneousArea);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);

	const [updateHomogeneousArea] = useMutation(
		gql(areaGQL.updateHomogeneousArea),
		{
			onCompleted: (data) => {
				onCompleted(data.updateHomogeneousArea);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert("Couldn't update your sample please try again");
			},
		}
	);

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "New Area" : "Update Area"}
			description={
				type === "create"
					? "Add a new homogeneous area"
					: `Update ${homogeneousArea.name}`
			}
			closeRequest={() => closeRequest()}
			onSubmit={() => {
				if (type === "create") {
					let input = {
						sampleCollection: sampleCollection.id,
						name: homogeneousArea.name,
						material: homogeneousArea.material,
						squareFootage: homogeneousArea.squareFootage,
						potentialForContact: homogeneousArea.potentialForContact,
						influenceOfVibration: homogeneousArea.influenceOfVibration,
						influenceOfAirErosion: homogeneousArea.influenceOfAirErosion,
					};

					createHomogeneousArea({
						variables: {
							input: input,
						},
					});
				} else {
					updateHomogeneousArea({
						variables: {
							input: {
								id: homogeneousArea.id,
								name: homogeneousArea.name,
								material: homogeneousArea.material,
								squareFootage: homogeneousArea.squareFootage,
								potentialForContact: homogeneousArea.potentialForContact,
								influenceOfVibration: homogeneousArea.influenceOfVibration,
								influenceOfAirErosion: homogeneousArea.influenceOfAirErosion,
							},
						},
					});
				}
			}}
			buttonRight={type === "create" ? "Create" : "Update"}
			currentArea={homogeneousArea}
		>
			<Input
				label="Name"
				type="text"
				class="w-input"
				value={homogeneousArea.name}
				onChange={(e) =>
					setHomogeneousArea({
						...homogeneousArea,
						name: e,
					})
				}
			/>
			<Input
				label="Material"
				type="text"
				class="w-input"
				value={homogeneousArea.material}
				placeholder={homogeneousArea.material}
				onChange={(e) =>
					setHomogeneousArea({
						...homogeneousArea,
						material: e,
					})
				}
			/>
			<Input
				label="Square Footage"
				type="text"
				class="w-input"
				value={homogeneousArea.squareFootage}
				placeholder={homogeneousArea.squareFootage}
				onChange={(e) =>
					setHomogeneousArea({
						...homogeneousArea,
						squareFootage: e,
					})
				}
			/>
			<>
				<Select
					name="Potential For Contact"
					type="text"
					class="w-input"
					value={homogeneousArea.potentialForContact}
					onChange={(value) => {
						setHomogeneousArea({
							...homogeneousArea,
							potentialForContact: value.value,
						});
					}}
					defaultValue={homogeneousArea.potentialForContact}
					options={[
						{
							name: "Low",
							value: "Low",
						},
						{
							name: "Moderate",
							value: "Moderate",
						},
						{
							name: "High",
							value: "High",
						},
					]}
				/>
				<Select
					name="Influence Of Vibration"
					type="text"
					class="w-input"
					value={homogeneousArea.influenceOfVibration}
					onChange={(value) => {
						setHomogeneousArea({
							...homogeneousArea,
							influenceOfVibration: value.value,
						});
					}}
					defaultValue={homogeneousArea.influenceOfVibration}
					options={[
						{
							name: "Low",
							value: "Low",
						},
						{
							name: "Moderate",
							value: "Moderate",
						},
						{
							name: "High",
							value: "High",
						},
					]}
				/>
				<Select
					name="Influence Of Air Erosion"
					type="text"
					class="w-input"
					value={homogeneousArea.influenceOfAirErosion}
					onChange={(value) => {
						setHomogeneousArea({
							...homogeneousArea,
							influenceOfAirErosion: value.value,
						});
					}}
					defaultValue={homogeneousArea.influenceOfAirErosion}
					options={[
						{
							name: "Low",
							value: "Low",
						},
						{
							name: "Moderate",
							value: "Moderate",
						},
						{
							name: "High",
							value: "High",
						},
					]}
				/>
			</>
		</SlideOver>
	);
};

HomogeneousAreaSlideOver.propTypes = {
	type: PropTypes.string,
	currentArea: PropTypes.object,
	currentSampleCollection: PropTypes.object,
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func,
	onCompleted: PropTypes.func,
	defaultValues: PropTypes.object,
};
