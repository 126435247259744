import PropTypes from "prop-types";
import React from "react";

// Components
import { Select, TextArea } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation } from "@apollo/client";
import * as bodyGQL from "../graphql/admin/body.js";

export const BodySlideOver = ({
	type = "create",
	currentBody,
	currentVersion,
	isOpen,
	closeRequest = () => {},
	onComplete = () => {},
}) => {
	const [newBody, setNewBody] = React.useState({});
	const [newVersion, setNewVersion] = React.useState({});

	React.useEffect(() => {
		if (currentBody) {
			setNewBody(currentBody);
		}
		if (currentVersion) {
			setNewVersion(currentVersion);
		}
	}, [currentBody]);

	const [createBody] = useMutation(gql(bodyGQL.createBody), {
		onCompleted: (data) => {
			onComplete(data.createBody);
		},
		onError: () => {
			alert("Couldn't create your body please try again");
		},
	});

	const [updateBody] = useMutation(gql(bodyGQL.updateBody), {
		onCompleted: () => {},
		onError: () => {
			alert("Couldn't update your Body please try again");
		},
	});

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "New Body" : "Edit Body"}
			closeRequest={() => closeRequest()}
			onSubmit={() => {
				let input = {
					version: newVersion.id,
					body: newBody.body,
					language: newBody.language,
				};
				if (type === "create") {
					//add in any necessary fields
					createBody({ variables: { input: input } });
				} else {
					updateBody({
						variables: {
							input: {
								id: newBody.id,
								body: newBody.body,
								language: newBody.language,
							},
						},
					});
				}
			}}
			buttonRightText={type === "create" ? "Create" : "Update"}
		>
			<Select
				name="Language"
				defaultValue={newBody.language ? newBody.language : ""}
				onChange={(value) => {
					setNewBody({ ...newBody, language: value.value });
				}}
				options={[
					{ name: "English (En)", value: "EN" },
					{
						name: "Spanish (Es)",
						value: "ES",
					},
					{
						name: "French (Fr)",
						value: "FR",
					},
				]}
			></Select>

			<TextArea
				label="Body"
				value={newBody.body}
				defaultValue={newBody.body ? newBody.body : ""}
				onChange={(value) => {
					setNewBody({ ...newBody, body: value.value });
				}}
			/>
		</SlideOver>
	);
};
BodySlideOver.propTypes = {
	route: PropTypes.object,
	type: PropTypes.string,
	currentBody: PropTypes.object,
	currentVersion: PropTypes.object,
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func,
	onComplete: PropTypes.func,
};
