import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

// Components
import { Alert } from "../components/Alert";
import SlideOver from "../components/Slideover";
import { Input } from "../components/Forms";

// GQL
import { useMutation, gql } from "@apollo/client";
import * as projectGQL from "../graphql/project";

// Utils
import { getData } from "../utils";

export const SpaceSlideOver = ({
	type = "create",
	currentSpace,
	isOpen,
	closeRequest,
	onComplete,
	project,
}) => {
	const user = getData("@storage_Key").then((user) => user);
	const [newSpaceData, setNewSpaceData] = React.useState({});

	React.useEffect(() => {
		if (currentSpace) {
			setNewSpaceData(currentSpace);
		}
	}, [currentSpace]);

	const [createSpace] = useMutation(gql(projectGQL.createSpace), {
		onCompleted: (data) => {
			if (!newSpaceData.name) {
				return Alert("You must provide a space name");
			}
			onComplete(data.createSpace);
		},
	});
	return (
		<SlideOver
			isOpen={isOpen}
			name="New Space"
			description={`Add a new space collection to ${project.title}`}
			closeRequest={() => closeRequest()}
			onSubmit={() => {
				newSpaceData.createBy = user.id;
				newSpaceData.project = project.id;
				newSpaceData.projectSub = project.id;
				newSpaceData.date = DateTime.now().toISO();
				createSpace({
					variables: {
						input: newSpaceData,
					},
				});
			}}
			project={project}
			buttonRightText={type === "create" ? "Create" : "Update"}
		>
			<Input
				type="search"
				class="search-input w-input"
				name="query"
				placeholder="Space Name"
				id="search"
				required
				value={newSpaceData.name}
				onChange={(e) => setNewSpaceData({ ...newSpaceData, name: e })}
			/>
		</SlideOver>
	);
};
SpaceSlideOver.propTypes = {
	route: PropTypes.object,
	type: PropTypes.string,
	currentSpace: PropTypes.object,
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func,
	onComplete: PropTypes.func,
	project: PropTypes.object,
};
