import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Pressable, Text, View } from "react-native";
import Toast from "react-native-toast-message";

// components

import { Alert } from "../components/Alert";
import Button from "../components/Button";
import { FileUpload, Input, Select } from "../components/Forms";
import SlideOver from "../components/Slideover";

// gql

import { gql, useMutation } from "@apollo/client";
import * as expenseGQL from "../graphql/clerk/expense";

//utils

import { getData, s3 } from "../utils";

/**
 * MultidayExpenseSlideOver
 * @param {object} props - props to be supplied to the slide over
 * @param {object} props.currentExpense - the currentExpense to be update (type should be set to update if this prop is passed)
 * @param {string} props.projectID - the current project
 * @param {object} props.user - the current logged in user
 * @param {function} props.onComplete - a function to be called when the slide over has completed the createRequest it will send the moisture point back to the parent component
 * @param {function} props.closeRequest - a function to be called when the slide over been closed by the user
 * @param {boolean} props.isClerkingPage - a boolean to determine if the data should be created or simply passed back to the parent component
 * @param {string} props.type - the type of the slide over (create or update)
 * @param {boolean} props.isOpen - a boolean to determine if the slide over should be shown
 * @returns {object} - JSX
 */

const MultidayExpenseSlideOver = ({
	isOpen,
	closeRequest = () => {},
	type,
	onComplete,
	isClerkingPage,
	projectID,
	currentExpense,
	defaultValues = {},
	users,
	currentProject,
	expenseTypes = [],
}) => {
	const [expense, setExpense] = React.useState({});
	const [user, setUser] = React.useState(null);
	const [expenseType, setExpenseType] = React.useState(null);
	const [billingPhases, setBillingPhases] = React.useState([]);

	/**
	 * handleDayChange
	 * @param index {number} - the index of the day
	 * @param keyBeingChanged {string} - the key that is going to be changed
	 * @param value {string} - the value that will be set to the key
	 */

	const [days, setDays] = useState([]);

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [amount, setAmount] = useState("");
	const [description, setDescription] = useState("");

	React.useEffect(() => {
		if (currentExpense) {
			setExpense(currentExpense);
		} else {
			setExpense({});
		}
	}, [currentExpense]);

	React.useEffect(() => {
		if (currentProject) {
			setBillingPhases([...currentProject.billingPhases]);
		}
	}, [currentProject]);

	React.useEffect(() => {
		const getUser = async () => {
			getData("@storage_Key").then((userInfo) => setUser(userInfo));
		};
		getUser();
	}, []);

	const [bulkCreateExpenses] = useMutation(gql(expenseGQL.bulkCreateExpenses), {
		onCompleted: (data) => {
			Toast.show({
				type: "success",
				text1: "Successfully Created",
				text2: "The multi-day expenses have been created",
			});
			onComplete(data.bulkCreateExpenses);
			setExpenseType(null);
		},
		onError: () => {
			Toast.show({
				type: "error",
				text1: "Something Went Wrong",
				text2: "There was an error creating the expense",
			});
		},
	});

	const uploadFile = (file) => {
		return new Promise(async (resolve, reject) => {
			if (!expenseType.requiresReceipt || !file) {
				resolve(file);
			} else if (typeof file === "string") {
				resolve(file);
			}
			let key = `project/${projectID}/receipts/${DateTime.now().toISO()}-${
				file.name
			}`;

			let fileData = await fetch(file.uri);
			fileData = await fileData.blob();

			const params = {
				Bucket: "srp-in-field",
				Key: key,
				Body: fileData,
				ACL: "public-read",
			};
			s3.upload(params, function (err, data) {
				if (err) {
					reject(err);
				}
				resolve(data.Location);
			});
		});
	};

	const handleDayChange = (index, keyBeingChanged, value) => {
		let newDays = days;
		newDays[index][keyBeingChanged] = value;

		setDays(() => [...newDays]);
	};

	return (
		<SlideOver
			isOpen={isOpen}
			name="Multi-Day Expense"
			description={
				"Auto populate multiple days with a specific amount that you can edit later or add one day at a time"
			}
			closeRequest={() => {
				closeRequest();
			}}
			onSubmit={async () => {
				// days looks like this [{ date: "2023-03-12", amount: "90.00"}, { date: "2023-03-13", amount: "80.00"}]

				if (
					days.filter(
						(day) => isNaN(day.amount) || !day.amount || day.amount === "0"
					).length
				) {
					return Alert("Please enter valid amounts");
				}

				// check that there are days  ie days.length
				if (!expense || !expense.expenseType.id) {
					Alert("Missing Expense Type");
					return;
				}

				// check that there are days  ie days.length
				if (!days.length) {
					Alert("Missing Days", "Please allocate days");
					return;
				}

				// let the user know which dates are blank
				let daysWithEmptyDates = days.filter(
					(day) => !day.date || day.date === ""
				);
				if (daysWithEmptyDates.length) {
					return Alert(
						"There are one or more blank dates. Please fix before continuing"
					);
				}

				// check that there are no days with empty keys ie (date and amount)

				let daysWithEmptyAmounts = days.filter((day) => !day.amount);
				if (daysWithEmptyAmounts.length) {
					return Alert(
						"There are one or more blank amounts. Please fix before continuing"
					);
				}

				if (expenseType.requiresReceipt && !expense.receipt) {
					return Alert("Please upload a file");
				}

				// check that there are no days with the same date
				const toFindDuplicates = (array) =>
					array.filter((item, index) => array.indexOf(item) !== index);
				const duplicates = toFindDuplicates(days.map((day) => day.date));
				if (duplicates.length) {
					// let the user know which dates have dups
					return Alert(
						`There are duplicate days for the following days: ${duplicates}`
					);
				}

				if (type === "create" && !expenseType.requiresReceipt) {
					// create expense

					let input = days.map((day) => {
						return {
							project: projectID,
							user: isClerkingPage ? expense.user : user.id,
							billingPhase: expense.billingPhase,
							expenseType: expense.expenseType.id,
							description: description,
							date: day.date,
							amount: day.amount,
						};
					});

					bulkCreateExpenses({
						variables: {
							input: input,
						},
					});
				} else {
					// Check for a file
					if (!expense.receipt) {
						return Alert(
							"You have selected a expense type that requires a receipt, please attach a receipt"
						);
					}

					// create expense

					uploadFile(expense.receipt)
						.then((s3Location) => {
							let input = days.map((day) => {
								return {
									project: projectID,
									user: isClerkingPage ? expense.user : user.id,
									expenseType: expense.expenseType.id,
									receipt: s3Location,
									description: description,
									date: day.date,
									amount: day.amount,
								};
							});

							bulkCreateExpenses({
								variables: {
									input: input,
								},
							});
						})
						.catch(() => {
							Alert("Your receipt image could not be saved please try again!");
						});
				}
			}}
		>
			<>
				<View className="m-1 p-1  flex items-center w-full">
					<View className="w-full">
						<FileUpload
							label="Upload Receipt"
							multiple={false}
							onFiles={(files) => {
								setExpense({ ...expense, receipt: files[0] });
							}}
						/>
						<Text className="text-xs dark:text-white">
							{expenseType
								? expenseType.requiresReceipt === true
									? "Receipt is required"
									: "Receipt is not required"
								: ""}
						</Text>
					</View>

					{isClerkingPage ? (
						<Select
							name="User"
							options={
								users
									? users
											.filter((user) => user.isTeamMember || user.isContractor)
											.sort((a, b) => {
												if (a.firstName > b.firstName) return 1;
												if (a.firstName < b.firstName) return -1;
												return 0;
											})
											.map((user) => {
												return {
													name: `${user.firstName} ${user.lastName}`,
													value: user.id,
												};
											})
									: []
							}
							onChange={(value) => {
								setExpense({ ...expense, user: value.value });
							}}
						/>
					) : null}

					{/* {expense.billingPhase && ( */}
					{
						<Select
							name="Billing Phase"
							// defaultValue={getDefaultValue(dailySheet)}
							onChange={(value) => {
								setExpense({
									...expense,
									billingPhase: value.value,
								});
							}}
							options={billingPhases.map((billingPhase) => {
								return {
									name: billingPhase.name,
									value: billingPhase.id,
								};
							})}
						/>
					}
					{/* )} */}

					<Input
						label="Description"
						value={expense.description}
						onChange={(e) => setDescription(e)}
					/>

					<Select
						name="Expense Type"
						defaultValue={defaultValues.expenseType}
						options={
							expenseTypes
								? expenseTypes
										.sort((a, b) => {
											if (a.name > b.name) return 1;
											if (a.name < b.name) return -1;
											return 0;
										})
										.map((expenseType) => {
											return {
												name: expenseType.name,
												value: expenseType,
											};
										})
								: null
						}
						onChange={(value) => {
							setExpense({ ...expense, expenseType: value.value });
							setExpenseType(value.value);
						}}
					/>

					<View className="w-full border-t border-b border-indigo-500 my-2 py-2">
						<View className="w-full items-start flex mt-2">
							<Text className="font-medium text-md">Auto Allocation</Text>
						</View>

						<Input
							label="Rate"
							required
							type="number"
							value={amount}
							onChange={(e) => setAmount(e)}
						/>
						<View className="w-full flex flex-row items-center justify-between pr-2">
							<View className="w-1/2 mr-2">
								<Input
									label="Start Date"
									onChange={(e) => {
										setStartDate(e);
									}}
									type="date"
								/>
							</View>
							<View className="w-1/2">
								<Input
									label="End Date"
									onChange={(e) => {
										setEndDate(e);
									}}
									type="date"
								/>
							</View>
						</View>

						<View className="mt-4 self-end">
							<Button
								text="Auto Allocate"
								onPress={() => {
									let start = DateTime.fromISO(startDate).toISODate();
									let end = DateTime.fromISO(endDate).toISODate();
									let currentDate = start;
									let dates = [];
									// check there is a start and end date
									if (!start || !end) {
										return Alert("You must enter a start and stop date");
									}

									// check that the startDate is less than the end date
									if (start >= end) {
										return Alert(
											"The end date must be greater than the start date"
										);
									}

									while (currentDate <= end) {
										dates.push({ date: currentDate, amount: amount });
										currentDate = DateTime.fromISO(currentDate)
											.plus({ day: 1 })
											.toISODate();
									}

									setDays(dates);
								}}
							/>
						</View>
					</View>
				</View>
			</>

			<View className="m-1 flex items-center w-full">
				{days.length ? (
					<View className="w-full">
						{days.map((day, index) => (
							<View key={index} className="w-full my-2">
								<View className="flex flex-row w-full items-center justify-between">
									<View>
										<Input
											label="Date"
											value={day.date}
											onChange={(e) => {
												handleDayChange(index, "date", e);
											}}
											type="date"
										/>
									</View>
									<View>
										<Input
											label="Rate"
											required
											type="number"
											value={day.amount}
											onChange={(e) => {
												handleDayChange(index, "amount", e);
											}}
										/>
									</View>
								</View>
								<Pressable
									className="self-end"
									onPress={() => {
										setDays((prev) => prev.filter((_, i) => i !== index));
									}}
								>
									<Text className="text-red-500 font-medium py-2">Delete</Text>
								</Pressable>
								<View className="border-b border-gray-300 " />
							</View>
						))}
					</View>
				) : null}

				<View className="w-full flex justify-end">
					<Button
						text="Add Date"
						onPress={() => {
							setDays((prev) => [
								...prev,
								{ date: DateTime.now().toISODate(), amount: 0 },
							]);
						}}
					/>
				</View>
			</View>
		</SlideOver>
	);
};

MultidayExpenseSlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	isClerkingPage: PropTypes.bool,
	projectID: PropTypes.string,
	currentExpense: PropTypes.object,
	defaultValues: PropTypes.object,
	currentProject: PropTypes.object,
	users: PropTypes.array,
	expenseTypes: PropTypes.array,
};

export { MultidayExpenseSlideOver };
