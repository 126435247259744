import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { View } from "react-native";

// Components
import { Alert } from "../components/Alert";
import { FieldSet, Input, Radio, Select, TextArea } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GraphQL

import { gql, useMutation } from "@apollo/client";
import * as timeEntryGQL from "../graphql/timeEntry";

// Utils
import { handleTimeEntryChange } from "../utils";

// const ListOfAmericanTimeZones = [
// 	{
// 		name: "Hawaii-Aleutian Standard Time (HST)",
// 		utc: "UTC-10:00",
// 	},
// 	{
// 		name: "Hawaii-Aleutian Daylight Time (HDT)",
// 		utc: "UTC-09:00",
// 	},
// 	{
// 		name: "Alaska Standard Time (AKDT)",
// 		utc: "UTC-08:00",
// 	},
// 	{
// 		name: "Alaska Daylight Time (AKDT)",
// 		utc: "UTC-07:00",
// 	},
// 	{
// 		name: "Pacific Standard Time (PST)",
// 		utc: "UTC-08:00",
// 	},
// 	{
// 		name: "Pacific Daylight Time (PDT)",
// 		utc: "UTC-07:00",
// 	},
// 	{
// 		name: "Mountain Standard Time (MST)",
// 		utc: "UTC-07:00",
// 	},
// 	{
// 		name: "Mountain Daylight Time (MDT)",
// 		utc: "UTC-06:00",
// 	},
// 	{
// 		name: "Central Standard Time (CST)",
// 		utc: "UTC-06:00",
// 	},
// 	{
// 		name: "Central Daylight Time (CDT)",
// 		utc: "UTC-05:00",
// 	},
// 	{
// 		name: "Eastern Standard Time (EST)",
// 		utc: "UTC-05:00",
// 	},
// 	{
// 		name: "Eastern Daylight Time (EDT)",
// 		utc: "UTC-04:00",
// 	},
// 	{
// 		name: "Atlantic Standard Time (AST)",
// 		utc: "UTC-04:00",
// 	},
// 	{
// 		name: "Atlantic Daylight Time (ADT)",
// 		utc: "UTC-03:00",
// 	},
// ];

const TimeEntrySlideover = ({
	isOpen,
	closeRequest,
	onComplete,
	currentTimeEntry = {},
	users,
	projects,
	type = "create",
	clerking = false,
	currentProject,
}) => {
	const [timeEntry, setTimeEntry] = React.useState({});

	const [billingPhases, setBillingPhases] = React.useState([]);

	//const [timeEntryOverride, setTimeEntryOverride] = React.useState({});
	// const [actingUser, setActingUser] = React.useState(null);
	// React.useEffect(() => {
	// 	const getUser = async () => {
	// 		await getData("user")
	// 			.then((user) => {
	// 				setActingUser(user);
	// 			})
	// 			.catch((e) => {
	//
	// 			});
	// 	};
	// 	getUser();
	// }, []);

	React.useEffect(() => {
		if (currentTimeEntry) {
			setTimeEntry(currentTimeEntry);
		}
	}, [currentTimeEntry]);

	React.useEffect(() => {
		if (currentProject) {
			setBillingPhases([...currentProject.billingPhases]);
		}
	}, [currentProject]);

	const [createTimeEntry] = useMutation(gql(timeEntryGQL.createTimeEntry), {
		onCompleted: (data) => {
			onComplete(data.updateTimeEntry);
		},
		onError: () => {
			Alert("Couldn't update time entry please try again");
		},
	});

	const [updateTimeEntry] = useMutation(gql(timeEntryGQL.updateTimeEntry), {
		onCompleted: (data) => {
			onComplete(data.updateTimeEntry);
		},
		onError: () => {
			Alert("Couldn't update time entry please try again");
		},
	});

	const convertISOToDate = (date) => {
		if (!date) return null;
		return DateTime.fromISO(date, { setZone: true }).toFormat(
			"yyyy-LL-dd'T'HH:mm"
		);
	};

	const getDefaultValue = (timeEntry) => {
		if (timeEntry.isMobilizing) {
			return "Mobilization";
		}
		if (timeEntry.isDemobilizing) {
			return "Demobilization";
		}
		if (timeEntry.isStandby) {
			return "Standby";
		}
		return "Regular";
	};

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "Create Time Entry" : "Edit Time Entry"}
			description={
				type === "create"
					? "Create a time entry for a user"
					: "Edit the time entry"
			}
			closeRequest={() => {
				closeRequest();
			}}
			onSubmit={() => {
				// check if time is more than 24 hours

				if (timeEntry.timeIn && timeEntry.timeOut) {
					let timeDuration = DateTime.fromISO(timeEntry.timeOut).diff(
						DateTime.fromISO(timeEntry.timeIn),
						["hours", "minutes"]
					);

					if (timeDuration.hours >= 24 && timeDuration.minutes >= 0) {
						Alert("Time duration cannot be more than 24 hours");
						return;
					}

					// hour and minutes less than 0

					if (timeDuration.hours <= 0 && timeDuration.minutes <= 0) {
						Alert("Time duration cannot be less than 0 minutes");
						return;
					}
				}

				if (type === "create" && !timeEntry.timeIn) {
					Alert("Please enter a time in");
					return;
				}
				if (type === "create" && !timeEntry.project) {
					Alert("Please select a project");
					return;
				}

				if (type === "edit" && !timeEntry.timeIn && !timeEntry.timeOut) {
					Alert("When editing time, the in and time out cannot be empty");
					return;
				}

				if (users && !timeEntry.user) {
					Alert("Please select a user");
				}

				if (type === "create") {
					createTimeEntry({
						variables: {
							input: timeEntry,
						},
					});
				}

				if (type === "update") {
					let input = {
						id: timeEntry.id,
						billingPhase: timeEntry.billingPhase,
						timeIn: convertISOToDate(timeEntry.timeIn),
						timeOut: convertISOToDate(timeEntry.timeOut),
						timeInBilling: timeEntry.timeInBilling,
						timeOutBilling: timeEntry.timeOutBilling,
						isMobilizing: timeEntry.isMobilizing,
						isDemobilizing: timeEntry.isDemobilizing,
						isStandby: timeEntry.isStandby,
					};

					if (timeEntry.project?.id) {
						input.project = timeEntry.project.id;
					} else {
						input.project = timeEntry.project;
					}

					updateTimeEntry({
						variables: {
							input: input,
						},
					});
				}
			}}
		>
			<>
				{users && (
					<Select
						name="User"
						options={users.map((user) => ({
							value: user.id,
							name: `${user.firstName} ${user.lastName}`,
						}))}
						onChange={(value) => {
							setTimeEntry({
								...timeEntry,
								user: value.value,
							});
						}}
					/>
				)}
			</>

			<>
				{projects && (
					<Select
						name="Projects"
						defaultValue={
							currentTimeEntry !== null &&
							currentTimeEntry.project &&
							currentTimeEntry.project.title
						}
						options={projects.map((project) => ({
							value: project.id,
							name: `${project.title}`,
						}))}
						onChange={(value) => {
							setTimeEntry({
								...timeEntry,
								project: value.value,
							});
						}}
					/>
				)}
			</>
			<Input
				className="ml-1 w-full"
				type="datetime-local"
				name="time in"
				label="Time In"
				value={convertISOToDate(timeEntry.timeIn)}
				onChange={(e) => {
					// convert datetime-local to DateTime ISO

					// get year, date, month, hour, minute, and second

					setTimeEntry((prev) => ({
						...prev,
						timeIn: handleTimeEntryChange(e),
					}));
				}}
				icon={"user-clock"}
				placeholder={"9:00 AM"}
			/>

			<Input
				className="ml-1 w-full"
				type="datetime-local"
				name="time out"
				label="Time Out"
				value={convertISOToDate(timeEntry.timeOut)}
				onChange={(e) => {
					setTimeEntry((prev) => ({
						...prev,
						timeOut: handleTimeEntryChange(e),
					}));
				}}
				icon={"user-clock"}
				placeholder={"5:00 PM"}
			/>

			<View className="space-y-3 ">
				{
					<FieldSet legend="Type">
						<Radio
							label="Time Type"
							defaultValue={getDefaultValue(timeEntry)}
							onChange={(value) => {
								if (value.label === "Mobilization")
									setTimeEntry({
										...timeEntry,
										isMobilizing: true,
										isDemobilizing: false,
										isStandby: false,
									});

								if (value.label === "Demobilization") {
									setTimeEntry({
										...timeEntry,
										isMobilizing: false,
										isDemobilizing: true,
										isStandby: false,
									});
								}
								if (value.label === "Standby") {
									setTimeEntry({
										...timeEntry,
										isMobilizing: false,
										isDemobilizing: false,
										isStandby: true,
									});
								}
								if (value.label === "Regular") {
									setTimeEntry({
										...timeEntry,
										isMobilizing: false,
										isDemobilizing: false,
										isStandby: false,
									});
								}
							}}
							options={[
								{ label: "Mobilization" },
								{ label: "Demobilization" },
								{ label: "Standby" },
								{ label: "Regular" },
							]}
						/>
					</FieldSet>
				}
			</View>

			<>
				{clerking && (
					<>
						<FieldSet
							legend="Edit Billing Hours"
							description="Change the hours that will be billed for this project. This will not effect the users time sheet for Payroll purposes."
						>
							{/* TODO must make the field require on each other*/}
							{/* {timeEntry.billingPhase && ( */}
							{
								<Select
									name="Billing Phase"
									// defaultValue={getDefaultValue(timeEntry)}
									value={timeEntry.billingPhase}
									onChange={(value) => {
										setTimeEntry((prev) => ({
											...prev,
											billingPhase: value.value,
										}));
									}}
									options={billingPhases.map((billingPhase) => {
										return {
											name: billingPhase.name,
											value: billingPhase.id,
										};
									})}
								/>
							}
							{/* )} */}

							<Input
								label="Time In"
								type="datetime-local"
								value={convertISOToDate(timeEntry.timeInBilling)}
								onChange={(e) => {
									setTimeEntry((prev) => ({
										...prev,
										timeInBilling: handleTimeEntryChange(e),
									}));
								}}
								required={timeEntry.timeOutBilling ? true : false}
							/>
							<Input
								label="Time Out"
								type="datetime-local"
								value={convertISOToDate(timeEntry.timeOutBilling)}
								onChange={(e) => {
									setTimeEntry((prev) => ({
										...prev,
										timeOutBilling: handleTimeEntryChange(e),
									}));
								}}
								required={timeEntry.timeInBilling ? true : false}
							/>
						</FieldSet>
					</>
				)}
			</>

			<>
				{type === "update" && (
					<>
						<FieldSet
							legend="History"
							description="Why was this time entry changed?"
						>
							<TextArea
								label="Reason"
								onChange={() => {
									// setTimeEntryOverride((prev) => ({
									// 	...prev,
									// 	reason: value,
									// }));
								}}
								required={timeEntry.approved ? true : false}
							/>
						</FieldSet>
					</>
				)}
			</>
		</SlideOver>
	);
};

TimeEntrySlideover.propTypes = {
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func.isRequired,
	onComplete: PropTypes.func.isRequired,
	currentTimeEntry: PropTypes.object,
	users: PropTypes.arrayOf(PropTypes.object),
	projects: PropTypes.arrayOf(PropTypes.object),
	type: PropTypes.string,
	clerking: PropTypes.bool,
	currentProject: PropTypes.object,
	defaultValues: PropTypes.object,
};

export { TimeEntrySlideover };
