import Constants from "expo-constants";
import * as Haptics from "expo-haptics";
import * as Linking from "expo-linking";
import { DateTime } from "luxon";
import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import {
	Dimensions,
	Image,
	Modal,
	Platform,
	Pressable,
	Switch,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import Toast from "react-native-toast-message";
import useInterval from "use-interval";

//components
import UpdateNotification from "../components/UpdateNotice";

// Screens
import { WorkID } from "../views/User/WorkID";

// Navigation Components
import {
	createDrawerNavigator,
	DrawerContentScrollView,
	DrawerItem,
} from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";

// Icons
import {
	faArrowLeft,
	faArrowLeftFromLine,
	faBell,
	faBuildingShield,
	faChevronDown,
	faChevronUp,
	faCogs,
	faIdBadge,
	faLandmark,
	faLifeRing,
	faLightbulbCfl,
	faMoneyBill,
	faTimes,
	faUserCircle,
	faUserHardHat,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

// Light Controls
import { useColorScheme } from "nativewind";

// Utils
import { ScrollView } from "react-native-gesture-handler";
import {
	calculateTimeDuration,
	getData,
	getSignedUrl,
	SentryLogger,
	storeData,
} from "../utils";

//GQL
import { gql, useLazyQuery } from "@apollo/client";
import * as timeEntryGQL from "../graphql/timeEntry";

const Drawer = createDrawerNavigator();

const windowDimensions = Dimensions.get("window");

export const Navigation = ({ routes, layoutName }) => {
	const [user, setUser] = useState({});
	const [search, setSearch] = useState("");
	const [dimensions, setDimensions] = useState({
		window: windowDimensions,
	});

	useEffect(() => {
		const subscription = Dimensions.addEventListener("change", ({ window }) => {
			setDimensions({ window });
		});
		return () => subscription?.remove();
	});

	const renderScreens = (routes) => {
		return routes.map((item, index) => {
			if (item.screens) {
				return (
					<Drawer.Screen key={index} name={`${item.name}`}>
						{(props) => (
							<View className="flex  bg-white dark:bg-gray-900">
								<View className="flex flex-1 bg-white dark:bg-gray-900">
									<item.component
										{...props}
										search={search}
										clearSearch={() => setSearch("")}
									/>
								</View>
								{renderScreens(item.screens)}
							</View>
						)}
					</Drawer.Screen>
				);
			} else {
				return (
					<Drawer.Screen key={index} name={`${item.name}`}>
						{(props) => (
							<View className="flex flex-1 bg-white dark:bg-gray-900">
								<item.component
									{...props}
									search={search}
									clearSearch={() => setSearch("")}
								/>
							</View>
						)}
					</Drawer.Screen>
				);
			}
		});
	};

	useEffect(() => {
		getData("@storage_Key").then((storedUser) => {
			setUser(storedUser);
		});
	}, []);

	return (
		<Drawer.Navigator
			initialRouteName="Home"
			drawerContent={(props) => (
				<CustomDrawerContent
					{...props}
					user={user}
					routes={routes}
					nonAppLayout={!!layoutName}
					layoutName={layoutName}
				/>
			)}
			screenOptions={{
				headerShown: false,
				drawerType: dimensions.window.width >= 768 ? "permanent" : "front",
			}}
		>
			{renderScreens(routes)}
		</Drawer.Navigator>
	);
};

Navigation.propTypes = {
	routes: PropTypes.arrayOf(PropTypes.object),
	layoutName: PropTypes.string,
};

function CustomDrawerContent(props) {
	const navigation = useNavigation();
	const user = props.user || {};
	const { colorScheme, setColorScheme } = useColorScheme();
	const [showLightOptions, setShowLightOptions] = useState(false);
	const [showID, setShowID] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [currentTimeEntry, setCurrentTimeEntry] = React.useState(null);

	const [getUser] = useLazyQuery(gql(timeEntryGQL.getUserAndTime), {
		onCompleted: (data) => {
			let currentTimeEntry = data.getUser.timeEntries.find((timeEntry) => {
				return timeEntry.timeOut === null;
			});
			setCurrentTimeEntry(currentTimeEntry);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("We couldn't get your time entries. Please try again", error);
		},
		fetchPolicy: "cache-and-network",
	});

	React.useEffect(() => {
		getData("@storage_Key")
			.then((user) => {
				if (user) {
					getUser({
						variables: {
							id: user.id,
						},
					}).then(() => {
						return;
					});
				}
			})
			.catch((err) => {
				Toast.show({
					type: "error",
					text1: "Error",
					text2: "We couldn't get your information. Please try again",
				});
				SentryLogger(err);
				return;
			});
	}, []);

	const logout = () => {
		storeData("@storage_Key", null)
			.then(() => {
				navigation.navigate("Auth");
			})
			.catch((err) => {
				SentryLogger(err);
				Toast.show({
					type: "error",
					text1: "Error",
					text2: "We couldn't log you out. Please try again",
				});
			});
		navigation.navigate("Auth");
	};
	return (
		<DrawerContentScrollView
			{...props}
			contentContainerStyle={{
				flex: 1,
				top: 0,
				bottom: 0,
				backgroundColor: colorScheme === "dark" ? "#1a202c" : "#fff",
			}}
		>
			<View className="h-full dark:bg-gray-900">
				{/* Logo and name in a flex-row  */}
				<View className="flex flex-row items-center justify-between px-4 pt-4">
					<View className="flex flex-row items-center">
						<Image
							className="w-10 h-10 rounded-full mr-2 "
							source={require("../assets/srp-icon.png")}
						/>
						<Text className="text-xl font-bold text-indigo-500">
							SRP inField
						</Text>
					</View>
				</View>

				<TouchableOpacity
					onPress={() => {
						Platform.OS !== "web" &&
							Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
						setShowMenu(!showMenu);
					}}
					className="flex w-full flex-row items-center p-4 justify-between"
				>
					<View className="flex flex-row items-center">
						{user.profilePicture ? (
							<Image
								className="w-10 h-10 rounded-full mr-2"
								source={{ uri: getSignedUrl(user.profilePicture) }}
							/>
						) : (
							<FontAwesomeIcon
								size={28}
								color={colorScheme === "light" ? "gray" : "white"}
								icon={faUserCircle}
							/>
						)}

						<View className="flex ">
							<Text className="font-black text-lg  text-gray-800 dark:text-gray-100 ">
								{user.firstName} {user.lastName}
							</Text>
							<Text className="font-bold text-md text-gray-500 dark:text-white">
								{user.role}
							</Text>
						</View>
					</View>
					<View>
						{showMenu ? (
							<FontAwesomeIcon icon={faChevronUp} />
						) : (
							<FontAwesomeIcon icon={faChevronDown} />
						)}
					</View>
				</TouchableOpacity>

				{currentTimeEntry && (
					<CurrentRunningTimeEntry currentTimeEntry={currentTimeEntry} />
				)}

				{showMenu && (
					<View className="bg-gray-100 dark:bg-gray-800 flex flex-col w-full">
						<TouchableOpacity
							onPress={() => {
								navigation.navigate("Profile");
								Platform.OS !== "web" &&
									Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
							}}
							className="flex w-full flex-row items-center px-4 py-4"
						>
							<FontAwesomeIcon
								size={20}
								color={colorScheme === "light" ? "gray" : "white"}
								icon={faUserCircle}
							/>
							<Text className="font-bold text-md text-gray-500 dark:text-white ml-2">
								Profile
							</Text>
						</TouchableOpacity>

						{user.isAdmin ? (
							<TouchableOpacity
								onPress={() => navigation.navigate("Admin")}
								className="flex w-full flex-row items-center px-4 py-4"
							>
								<FontAwesomeIcon
									size={20}
									color={colorScheme === "light" ? "gray" : "white"}
									icon={faCogs}
								/>
								<Text className="font-bold text-md text-gray-500 dark:text-white ml-2">
									Admin Center
								</Text>
							</TouchableOpacity>
						) : null}

						{user.isClerk ? (
							<TouchableOpacity
								onPress={() => navigation.navigate("Clerk")}
								className="flex w-full flex-row items-center px-4 py-4"
							>
								<FontAwesomeIcon
									size={20}
									color={colorScheme === "light" ? "gray" : "white"}
									icon={faMoneyBill}
								/>
								<Text className="font-bold text-md text-gray-500 dark:text-white ml-2">
									Clerk and PC Center
								</Text>
							</TouchableOpacity>
						) : null}
						<>
							{user.isTimeAdmin ? (
								<TouchableOpacity
									onPress={() => navigation.navigate("Finance and HR Center")}
									className="flex w-full flex-row items-center px-4 py-4"
								>
									<FontAwesomeIcon
										size={20}
										color={colorScheme === "light" ? "gray" : "white"}
										icon={faLandmark}
									/>
									<Text className="font-bold text-md text-gray-500 dark:text-white ml-2">
										Finance and HR Center
									</Text>
								</TouchableOpacity>
							) : null}
						</>
						<>
							{user.subAdmin ? (
								<TouchableOpacity
									onPress={() => navigation.navigate("SubcontractorAdmin")}
									className="flex w-full flex-row items-center px-4 py-4"
								>
									<FontAwesomeIcon
										size={20}
										color={colorScheme === "light" ? "gray" : "white"}
										icon={faUserHardHat}
									/>
									<Text className="font-bold text-md text-gray-500 dark:text-white ml-2">
										Subcontractor Admin
									</Text>
								</TouchableOpacity>
							) : null}
						</>
						<>
							{user.isSafetyUser ? (
								<TouchableOpacity
									onPress={() => navigation.navigate("SafetyCenter")}
									className="flex w-full flex-row items-center px-4 py-4"
								>
									<FontAwesomeIcon
										size={20}
										color={colorScheme === "light" ? "gray" : "white"}
										icon={faBuildingShield}
									/>
									<Text className="font-bold text-md text-gray-500 dark:text-white ml-2">
										Safety Center
									</Text>
								</TouchableOpacity>
							) : null}
						</>

						<TouchableOpacity
							onPress={() => {
								logout();
								Platform.OS !== "web" &&
									Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
							}}
							className="flex w-full flex-row items-center px-4 py-4"
						>
							<FontAwesomeIcon
								size={20}
								color={colorScheme === "light" ? "gray" : "white"}
								icon={faArrowLeftFromLine}
							/>
							<Text className="font-bold text-md text-gray-500 dark:text-white ml-2">
								Logout
							</Text>
						</TouchableOpacity>
					</View>
				)}

				<ScrollView>
					{props.routes
						.filter((item) => {
							if (!item.accessLevel) return true;
							if (
								item.accessLevel.includes("contractor") &&
								(user.isContractor === true || user.isTeamMember === true)
							) {
								return true;
							} else if (
								item.accessLevel.includes("teamMember") &&
								user.isTeamMember === true
							) {
								return true;
							} else {
								return false;
							}
						})
						.map((item, index) => {
							if (item.noShow) return null;

							return (
								<DrawerItem
									key={index}
									label={({ focused }) => (
										<Text
											className={`${
												focused
													? "text-indigo-500"
													: "text-gray-900 dark:text-white"
											} font-medium`}
										>
											{item.displayName || item.name}
										</Text>
									)}
									icon={() =>
										item.icon ? (
											<FontAwesomeIcon
												size={20}
												color={colorScheme === "light" ? "gray" : "white"}
												icon={item.icon}
											/>
										) : null
									}
									onPress={() => {
										navigation.navigate(item.name);
									}}
									//className="text-gray-500 dark:text-white"
								/>
							);
						})}

					{props.layoutName !== "app" && (
						<DrawerItem
							label={({ focused }) => (
								<Text
									className={`${
										focused
											? "text-indigo-500"
											: "text-gray-900 dark:text-white"
									} font-medium`}
								>
									Return to App
								</Text>
							)}
							icon={() => (
								<FontAwesomeIcon
									size={20}
									color={colorScheme === "light" ? "gray" : "white"}
									icon={faArrowLeft}
								/>
							)}
							onPress={() => {
								navigation.navigate("App");
							}}
							//className="text-gray-500 dark:text-white"
						/>
					)}

					<UpdateNotification />
				</ScrollView>

				<View className="absolute bg-white dark:bg-gray-900 bottom-0 pb-4 right-0 w-full ">
					<View className="border-b-2 border-gray-100" />
					<View className="flex flex-row justify-between py-2 px-4">
						<TouchableOpacity
							onPress={() => {
								Platform.OS !== "web" &&
									Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
								setShowLightOptions(true);
							}}
						>
							<FontAwesomeIcon icon={faLightbulbCfl} size={28} color={"gray"} />
						</TouchableOpacity>

						<TouchableOpacity
							onPress={() => {
								Platform.OS !== "web" &&
									Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
								return navigation.navigate("Notifications");
							}}
						>
							<FontAwesomeIcon icon={faBell} size={28} color={"gray"} />
						</TouchableOpacity>

						<TouchableOpacity
							onPress={() => {
								Linking.openURL("https://srpinfield.com/help");
								setShowLightOptions(true);
							}}
						>
							<FontAwesomeIcon icon={faLifeRing} size={28} color={"gray"} />
						</TouchableOpacity>

						<TouchableOpacity
							onPress={() => {
								Platform.OS !== "web" &&
									Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
								setShowID(true);
							}}
						>
							<FontAwesomeIcon icon={faIdBadge} size={28} color={"gray"} />
						</TouchableOpacity>
					</View>
					<View className="flex flex-row items-center justify-between px-2 pt-2 border-t-2  border-gray-100">
						<Text className="text-gray-500 font-medium">
							©️ {new Date().getFullYear()} SRP Environmental
						</Text>
						<Text className="text-gray-500 font-medium">
							{Constants.expoConfig.version}
						</Text>
					</View>
				</View>
			</View>
			<Modal
				visible={showLightOptions}
				onBackdropPress={() => setShowLightOptions(false)}
				transparent={true}
				animationIn="fadeIn"
				animationOut="fadeOut"
				animationInTiming={500}
				animationOutTiming={500}
				backdropTransitionInTiming={500}
			>
				<View className="h-full w-full bg-gray-900 opacity-75"></View>
				<View className="absolute bottom-0 right-0 flex flex-col items-center  justify-center w-full h-52  ">
					<View className="bg-white dark:bg-gray-800 rounded-lg p-4 w-full h-full">
						<View className="flex flex-row justify-between ">
							<Text className="font-bold text-lg dark:text-white">
								Lighting Options
							</Text>
							<TouchableOpacity
								onPress={() => {
									setShowLightOptions(false);
								}}
							>
								<FontAwesomeIcon icon={faTimes} size={24} color={"gray"} />
							</TouchableOpacity>
						</View>

						{/* Switches to use the dark mode or use the system settings */}
						<View className="flex flex-row justify-between items-center py-2">
							<Text className="font-bold text-md dark:text-white">
								Dark Mode
							</Text>
							<Switch
								value={colorScheme === "dark"}
								onValueChange={() =>
									setColorScheme(colorScheme === "light" ? "dark" : "light")
								}
							/>
						</View>
						<View className="flex flex-row justify-between items-center py-2">
							<View>
								<Text className="font-bold text-md dark:text-white">
									Use device settings
								</Text>
							</View>

							<Switch
								value={colorScheme === "system"}
								onValueChange={() => {
									setColorScheme("system");
								}}
							/>
						</View>
						<Text className="text-xs text-gray-500">
							This will set the lighting mode to the Light or Dark selection
							located in your devices Display and Brightness settings
						</Text>
					</View>
				</View>
			</Modal>

			<Modal
				visible={showID}
				onBackdropPress={() => setShowLightOptions(false)}
				transparent={true}
				animationIn="fadeIn"
				animationOut="fadeOut"
				animationInTiming={500}
				animationOutTiming={500}
				backdropTransitionInTiming={500}
			>
				<View className="h-full w-full bg-gray-900 opacity-95"></View>
				<View className="absolute bottom-0 right-0 flex flex-col items-center justify-center w-full h-5/6">
					<View className="bg-white dark:bg-gray-800 rounded-lg p-4 w-full h-full">
						<View className="flex flex-row justify-between">
							<Text className="font-bold text-lg">Worker Identification</Text>
							<TouchableOpacity
								onPress={() => {
									setShowID(false);
								}}
							>
								<FontAwesomeIcon icon={faTimes} size={24} color={"gray"} />
							</TouchableOpacity>
						</View>
						<WorkID />
					</View>
				</View>
			</Modal>
		</DrawerContentScrollView>
	);
}

CustomDrawerContent.propTypes = {
	user: PropTypes.object,
	routes: PropTypes.arrayOf(PropTypes.object),
	state: PropTypes.object,
	nonAppLayout: PropTypes.bool,
	navigation: PropTypes.object,
	layoutName: PropTypes.string,
};

function CurrentRunningTimeEntry({ currentTimeEntry }) {
	const [currentRunningTime, setCurrentRunningTime] = React.useState(null);
	const navigation = useNavigation();
	useInterval(() => {
		if (currentTimeEntry) {
			let currentTime = DateTime.local();
			setCurrentRunningTime(
				calculateTimeDuration(currentTimeEntry.timeIn, currentTime)
			);
		}
	}, 1000);

	return (
		<>
			{currentRunningTime ? (
				<Pressable
					onPress={() => {
						navigation.navigate("Time Clock");
					}}
					className="flex items-start mx-4 px-2 py-2 border-2 shadow rounded border-indigo-500"
				>
					<Text className="font-medium text-center text-gray-600 dark:text-gray-300">
						Current Running Time
					</Text>
					<Text className="text-center text-gray-600 dark:text-gray-300">
						Project{" "}
						<Text className="text-gray-900 dark:text-white">
							{currentTimeEntry.project
								? currentTimeEntry.project.title
								: "No Project"}
						</Text>
					</Text>
					<Text className="text-center text-gray-600 dark:text-gray-300">
						Time{" "}
						<Text className="text-gray-900 dark:text-white">
							{currentRunningTime}
						</Text>
					</Text>
				</Pressable>
			) : null}
		</>
	);
}

CurrentRunningTimeEntry.propTypes = {
	currentTimeEntry: PropTypes.object,
};
