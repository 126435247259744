import { useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import React from "react";
import { Pressable, Text, View } from "react-native";
import Toast from "react-native-toast-message";

// Components

import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List";
import LoadingScreen from "../../components/LoadingScreen";
import Pagination from "../../components/Pagination";

// containers
import { ProjectSlideOver } from "../../containers/ProjectSlideOver";

// GraphQL

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import * as projectGQL from "../../graphql/clerk/projects";

// Icons
import {
	faCheckToSlot,
	faClipboard,
	faMagnifyingGlassDollar,
	faPlus,
	faRocket,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

// utils
import NoItems from "../../components/NoItems";
import { getData, SentryLogger } from "../../utils";

const DataCard = ({ name, filter, onPress, icon, currentSelected }) => {
	const { data, loading } = useQuery(gql(projectGQL.listTotals), {
		variables: {
			filter: filter,
		},
		fetchPolicy: "network-only",
	});

	return (
		<Pressable
			onPress={() => onPress()}
			className={`${
				currentSelected ? "bg-indigo-500" : "bg-white"
			} my-2 mx-2 flex-1 shadow rounded-lg  `}
		>
			<View className="p-5">
				<View className="flex-col md:items-center">
					<View className="flex-shrink-0 mb-2 md:mb-0">
						<FontAwesomeIcon
							icon={icon}
							size={24}
							color={currentSelected ? "white" : "black"}
							aria-hidden="true"
						/>
					</View>
					<View className="md:ml-5">
						<Text
							className={`text-sm font-medium ${
								currentSelected ? "text-white" : "text-gray-500"
							} `}
						>
							{name}
						</Text>
						<Text
							className={`text-lg font-medium ${
								currentSelected ? "text-white" : "text-gray-900"
							}`}
						>
							{loading ? "Loading data... " : data?.listProjects?.total || 0}
						</Text>
					</View>
				</View>
			</View>
			<View
				className={`${
					currentSelected ? "bg-indigo-50" : "bg-gray-50"
				} px-5 py-3`}
			>
				<View className="text-sm">
					<Pressable onPress={() => onPress()}>
						<Text className="font-medium text-cyan-700 hover:text-cyan-900">
							{currentSelected ? "Currently Viewing" : "View All"}
						</Text>
					</Pressable>
				</View>
			</View>
		</Pressable>
	);
};

DataCard.propTypes = {
	name: PropTypes.string.isRequired,
	filter: PropTypes.object.isRequired,
	onPress: PropTypes.func.isRequired,
	icon: PropTypes.object.isRequired,
	currentSelected: PropTypes.bool,
};

export function WorkInProgress() {
	const navigation = useNavigation();
	const [user, setUser] = React.useState({});
	const [projects, setProjects] = React.useState(null);
	const [search, setSearch] = React.useState("");
	const [currentSelected, setCurrentSelected] = React.useState(0);
	const [item, setItem] = React.useState(null);

	const itemsPerPage = 30;
	let [currentPage, setCurrentPage] = React.useState(1);
	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end = projects
		? currentPage * itemsPerPage >
		  projects.filter((project) =>
				project.title.toLowerCase().includes(search.toLowerCase())
		  ).length
			? projects.filter((project) =>
					project.title.toLowerCase().includes(search.toLowerCase())
			  ).length
			: currentPage * itemsPerPage
		: 0;

	const cards = [
		{
			name: "My Projects",
			filter: {
				clerk: {
					eq: user.id,
				},
				isBilled: false,
			},

			icon: faClipboard,
		},
		{
			name: "In Billing",
			filter: {
				isBilled: false,
			},

			icon: faRocket,
		},
		{
			name: "In Billing Review",
			filter: {
				inBillingReview: true,
			},

			icon: faMagnifyingGlassDollar,
		},
		{
			name: "Billed Projects",
			filter: {
				isBilled: true,
			},
			icon: faCheckToSlot,
		},
	];

	const [listProjects, { loading }] = useLazyQuery(
		gql(projectGQL.listProjects),
		{
			fetchPolicy: "network-only",
		}
	);

	React.useEffect(() => {
		getData("@storage_Key").then((user) => {
			setUser(user);
			listProjects({
				variables: {
					filter: {
						clerk: {
							eq: user.id,
						},
						isBilled: false,
					},
				},
			})
				.then(handleGetSuccess)
				.catch(handleGetError);
		});
	}, []);

	const handleGetSuccess = (data) => {
		setProjects([...data.data.listProjects.items]);
	};

	const handleGetError = (error) => {
		Toast.show({
			type: "error",
			text1: "Error Listing Projects",
			text2: error.message,
		});

		SentryLogger(JSON.stringify(error, null, 2));
	};

	const navigateToProject = (project) => {
		navigation.navigate("ClerkProject", {
			id: project.id,
			title: project.title,
		});
	};

	if (!projects) return <LoadingScreen loadItem="Projects" />;

	return (
		<>
			<PageHeader
				openMenu
				title={"Projects"}
				description={"Projects in billing process"}
				buttons={[
					{
						icon: faPlus,
						name: "Create Project",
						onPress: () => {
							setItem("createProject");
						},
					},
				]}
			/>

			<View className="mt-8">
				<View className="max-w-6xl px-4 sm:px-6">
					<Text className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
						Overview
					</Text>
					<View className="block flex-row flex-wrap w-full">
						{cards.map((card, index) => (
							<DataCard
								key={index}
								name={card.name}
								filter={card.filter}
								onPress={() => {
									listProjects({
										variables: {
											filter: card.filter,
										},
									})
										.then(handleGetSuccess)
										.catch(handleGetError);
									setCurrentSelected(() => index);
								}}
								icon={card.icon}
								currentSelected={currentSelected === index}
							/>
						))}
					</View>

					<Pressable
						onPress={() => {
							listProjects({}).then(handleGetSuccess).catch(handleGetError);
							setCurrentSelected(0);
						}}
					>
						<Text className="text-indigo-500">List all projects</Text>
					</Pressable>
				</View>
			</View>

			{!loading && !projects.length ? (
				<NoItems
					title="No projects found"
					surTitle={`No projects matching ${cards[currentSelected].name}`}
					text={"Select a different filter to view more projects"}
				/>
			) : (
				<List
					headers={["Title", "Client"]}
					onSearch={(value) => {
						setSearch(value);
					}}
				>
					{projects &&
						projects
							.sort((a, b) => {
								if (a.title > b.title) return 1;
								if (a.title < b.title) return -1;
								return 0;
							})
							.filter((project) =>
								project.title.toLowerCase().includes(search.toLowerCase())
							)

							.map((project, index) => {
								if (index + 1 >= start && index < end) {
									return (
										<ListItem
											key={project.id}
											items={[
												{
													content: (
														<Text className="mx-3 text-gray-900 dark:text-white">
															{project.title}
														</Text>
													),
													onPress: () => navigateToProject(project),
												},
												{
													content: project.client
														? project.client.name
														: "No Client Assigned",
													onPress: () => navigateToProject(project),
												},
											]}
										/>
									);
								}
							})}
				</List>
			)}

			<Pagination
				start={start}
				stop={end}
				total={
					projects.filter((project) =>
						project.title.toLowerCase().includes(search.toLowerCase())
					).length
				}
				nextClicked={() => setCurrentPage(currentPage + 1)}
				previousClicked={() => setCurrentPage(currentPage - 1)}
			/>

			<ProjectSlideOver
				isOpen={item === "createProject"}
				type="create"
				clerking={true}
				onComplete={() => {
					setItem(null);
					listProjects({
						variables: {
							filter: {
								clerk: {
									eq: user.id,
								},
								isBilled: false,
							},
						},
					})
						.then(handleGetSuccess)
						.catch(handleGetError);
					setCurrentSelected(0);
				}}
				closeRequest={() => {
					setItem(null);
				}}
			/>
		</>
	);
}

WorkInProgress.propTypes = {
	search: PropTypes.string,
};
