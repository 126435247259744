import { useNavigation } from "@react-navigation/native";
import * as Linking from "expo-linking";
import React from "react";
import {
	Image,
	KeyboardAvoidingView,
	ScrollView,
	Text,
	TouchableOpacity,
	View,
} from "react-native";

// Components

import { Alert } from "../../components/Alert";
import Button from "../../components/Button";
import { Checkbox, Input } from "../../components/Forms";

// GQL
import { gql, useMutation } from "@apollo/client";
import { DateTime } from "luxon";
import * as userGQL from "../../graphql/user";

// Utils
import { API_URL, storeData } from "../../utils";

const lowerCaseLetters = /[a-z]/g;
const upperCaseLetters = /[A-Z]/g;
const numbers = /[0-9]/g;
const special = /[=+-^$*.{}()?"!@#%&/,><':;|_~`]/g;

export const Register = () => {
	const navigation = useNavigation();
	const [userRequest, setUserRequest] = React.useState({
		firstName: "",
		lastName: "",
		email: "",
		organization: "",
		isRequesting: false,
	});

	const [accepted, setAccepted] = React.useState(false);
	const [success, setSuccess] = React.useState(false);
	const [error, setError] = React.useState(false);
	let [code, setCode] = React.useState("");

	const [createUserRequest] = useMutation(gql(userGQL.createUser), {
		onCompleted: (data) => {
			setSuccess(true);
			generatePasswordResetToken({
				variables: {
					email: data.createUser.email,
				},
			});
		},
		onError: (error) => {
			setError(error.message);
		},
	});

	let [generatePasswordResetToken] = useMutation(
		gql(userGQL.generatePasswordResetToken),
		{
			onCompleted: (data) => {
				let { email, passwordResetToken } = data.generatePasswordResetToken;

				sendVerificationCode(email, passwordResetToken);
			},
		}
	);

	const sendVerificationCode = (email, code) => {
		fetch(`${API_URL}/emails/send-code`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				code,
			}),
		})
			.then((res) => res.json())
			.catch(() => {
				Alert(
					"Unable to send verification code",
					"You must verify your email, but we were unable to send the verification link to your email. Please sign in to resend the verification the code."
				);
			});
	};

	let [resetPassword] = useMutation(gql(userGQL.resetPassword), {
		onCompleted: (data) => {
			storeData("@storage_Key", data.resetPassword);
			navigation.navigate("App");
			return;
		},
		onError: (error) => {
			setError(error.message);
		},
	});

	return (
		<KeyboardAvoidingView
			behavior="padding"
			className="h-full flex items-center justify-center bg-gray-50 dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8"
		>
			<View className="max-w-md w-full space-y-2 flex items-center justify-center">
				<View className="flex items-center justify-center">
					<Image
						className="h-12 w-12"
						source={require("../../assets/srp-icon.png")}
						alt="SRP inField"
					/>
				</View>

				<>{error && <Text className="text-red-500">{error}</Text>}</>

				{!success ? (
					<ScrollView className="mt-8 space-y-6">
						<Text className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
							Create Account
						</Text>
						<View className="mt-6 ">
							<Input
								label="First Name"
								placeholder="John"
								className="col-span-3"
								value={userRequest.firstName}
								onChange={(e) => {
									setUserRequest({
										...userRequest,
										firstName: e,
									});
								}}
								required
								autoComplete={"given-name"}
							/>
							<Input
								label="Last Name"
								placeholder="Smith"
								className="col-span-3"
								value={userRequest.lastName}
								onChange={(e) => {
									setUserRequest({
										...userRequest,
										lastName: e,
									});
								}}
								autoComplete={"family-name"}
							/>
							<Input
								label="Email"
								placeholder="jsmith@srpenvironmental.net"
								className="col-span-6"
								value={userRequest.email}
								onChange={(e) => {
									setUserRequest({
										...userRequest,
										email: e,
									});
								}}
								required
								autoCapitalize={"none"}
								autoCompleteType={"email"}
								keyboardType={"email-address"}
							/>
							<Input
								label="Password"
								placeholder="Password"
								className="col-span-6"
								value={userRequest.password}
								onChange={(e) => {
									setUserRequest({
										...userRequest,
										password: e,
									});
								}}
								required
								secureTextEntry
								autoCapitalize={"none"}
								autoComplete={"password-new"}
								autoCorrect={false}
							/>
							<Input
								label="Phone"
								placeholder="(XXX) XXX-XXXX"
								className="col-span-6"
								value={userRequest.phone}
								onChange={(e) => {
									setUserRequest({
										...userRequest,
										phone: e,
									});
								}}
								required
								autoCapitalize={"none"}
								autoComplete={"tel"}
							/>

							<Input
								label="Organization"
								placeholder="Organization"
								className="col-span-6"
								value={userRequest.organization}
								onChange={(e) => {
									setUserRequest({
										...userRequest,
										organization: e,
									});
								}}
								required
								autoComplete={"off"}
							/>
						</View>
						<Checkbox
							className="mt-6"
							required
							checkedValue={accepted}
							onChange={() => {
								setAccepted(!accepted);
							}}
							label="I agree to the terms and conditions"
							description={
								<View>
									<Text className=" text-gray-500">
										By creating an account you agree to SRP inField's{" "}
										<TouchableOpacity
											onPress={() =>
												Linking.openURL("https://srpinfield.com/tos")
											}
										>
											<Text className={"text-indigo-500"} to="/tos">
												Terms and Conditions
											</Text>
										</TouchableOpacity>
									</Text>
								</View>
							}
						/>

						<View className="mt-6">
							<Button
								type="submit"
								className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								text="Create Account"
								onPress={() => {
									setError(null);
									if (
										!userRequest.firstName ||
										!userRequest.lastName ||
										!userRequest.email ||
										!userRequest.phone ||
										!userRequest.organization ||
										!userRequest.password
									) {
										setError("Please fill out all fields");
										return;
									}

									let password = userRequest.password;

									if (
										!password.length >= 8 ||
										!password.match(upperCaseLetters) ||
										!password.match(lowerCaseLetters) ||
										!password.match(numbers) ||
										!password.match(special)
									) {
										setError(
											"Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
										);
										return;
									}

									if (!accepted) {
										setError("Please accept the terms and conditions");
										return;
									}

									createUserRequest({
										variables: {
											input: {
												...userRequest,
												linkExpiration: DateTime.now()
													.plus({ days: 1 })
													.toISO(),
											},
										},
									});
								}}
							/>
						</View>
						<TouchableOpacity
							onPress={() => {
								navigation.navigate("SignIn");
							}}
							className="mb-8"
						>
							<Text className="pb-6 text-center text-sm font-medium text-indigo-500">
								Back to Login
							</Text>
						</TouchableOpacity>
					</ScrollView>
				) : (
					<>
						<Text className="mt-3 text-center  font-normal text-gray-900 dark:text-white">
							Enter your the code that was just sent to your email.
						</Text>
						<View className="w-1/2">
							{error && <Text className="text-red-500">{error}</Text>}
						</View>
						<View className="mt-8 space-y-6 w-full">
							<Input
								label="Code"
								placeholder="Auth Code"
								required
								type="text"
								value={code}
								onChange={(e) => {
									setCode(e);
								}}
							/>
							<TouchableOpacity
								onPress={() => {
									generatePasswordResetToken({
										variables: {
											email: userRequest.email,
										},
									});
								}}
							>
								<Text className="mt-2 text-indigo-500 group-hover:text-indigo-400 dark:text-gray-100 dark:group-hover:text-indigo-50 cursor:pointer">
									Resend Authentication Code
								</Text>
							</TouchableOpacity>

							<View className="mt-6">
								<Button
									text="Verify and Sign In"
									onPress={() => {
										resetPassword({
											variables: {
												email: userRequest.email,
												password: userRequest.password,
												code: code,
											},
										});
									}}
								/>
							</View>
						</View>
					</>
				)}
			</View>
		</KeyboardAvoidingView>
	);
};
