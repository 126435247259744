import { DateTime } from "luxon";
import PropTypes, { bool } from "prop-types";
import React from "react";
import { Image, ScrollView, View } from "react-native";

// Component
import DataContainer, { DataItem } from "./DataDisplay";

// Icons
import { faCheck, faTimes } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

// Utils
import { getSignedUrl } from "../utils";

const DailySheetView = ({
	timeEntry,
	dailyDocument,
	showTimeEntryDetails = true,
}) => {
	/**
	 * calculateTimeDuration
	 * @param {string} timeIn - ISO 8601 String
	 * @param {string} timeOut - ISO 8601 String
	 * @returns {string} - formatted time difference
	 */

	const calculateTimeDuration = (timeIn, timeOut) => {
		return DateTime.fromISO(timeOut)
			.diff(DateTime.fromISO(timeIn))
			.toFormat("hh:mm:ss");
	};

	const data = timeEntry ? timeEntry.dailyDocument : dailyDocument;

	return (
		<ScrollView className="p-2 space-y-4">
			<>
				{showTimeEntryDetails && (
					<DataContainer
						title={DateTime.fromISO(timeEntry.timeIn).toFormat(
							"EEEE, MMMM dd, yyyy"
						)}
					>
						<DataItem
							name="Project"
							value={timeEntry ? timeEntry.project.title : ""}
						/>

						<DataItem
							name="Time Out"
							value={DateTime.fromISO(timeEntry.timeOut).toLocaleString(
								DateTime.DATETIME_FULL
							)}
						/>
						<DataItem
							name="Time Duration"
							value={calculateTimeDuration(timeEntry.timeIn, timeEntry.timeOut)}
						/>
					</DataContainer>
				)}
			</>

			<View className="mt-2">
				{data && (
					<DataContainer title={"Daily Sheet Information"}>
						<DataItem
							name="Moisture Mapping"
							value={
								data.moistureMapping ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="Drydown"
							value={
								data.dryDown ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							testID={"name-test"}
							name="Sampling"
							value={
								data.sampling ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="Admin"
							value={
								data.admin ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="AirFare"
							value={
								data.airfare ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>

						<DataItem name="Activities" value={data.activities} />
						<DataItem
							name="Moblization"
							value={
								data.mobilization ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="Demoblization"
							value={
								data.demobilization ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem name="Hotel Name" value={data.hotelName} />
						<DataItem name="Hotel Number" value={data.hotelNumber} />
						<DataItem name="Hotel Status" value={data.hotelStatus} />
						<DataItem name="Rental Car Status" value={data.rentalCarStatus} />
						<DataItem
							name="Rental Car"
							value={
								data.rentalCar ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="Personal or Company Mileage"
							value={data.personalCompanyMilage}
						/>
						<DataItem name="Rental Car Make" value={data.rentalCarMake} />
						<DataItem name="Rental Car Model" value={data.rentalCarModel} />
						<DataItem
							name="Fuel"
							value={
								data.fuel ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>

						<DataItem
							name="Boroscope"
							value={
								data.boroscope ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="Fall Protection"
							value={
								data.fallProtection ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>

						<DataItem
							name="Matterport"
							value={
								data.matterport ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="Mobile Command Center"
							value={
								data.mobileCommandCenter ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>

						<DataItem
							name="Moisture Meter"
							value={
								data.moistureMeter ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="Per Diem"
							value={
								data.perDiem ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>

						<DataItem
							name="Respirator"
							value={
								data.respirator ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="Trailer Package "
							value={
								data.trailerPackage ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="Thermal Imaging Camera"
							value={
								data.flir ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="Thermohygrometer"
							value={
								data.thermohygrometer ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="360 Spherical Camera"
							value={
								data.sphericalCamera ? (
									<FontAwesomeIcon size={10} icon={faCheck} />
								) : (
									<FontAwesomeIcon size={10} icon={faTimes} />
								)
							}
						/>
						<DataItem
							name="Signature"
							value={
								data.signature ? (
									<View className="h-32 w-full">
										<Image
											source={{
												uri: getSignedUrl(data.signature),
											}}
											className="w-full h-full"
										/>
									</View>
								) : (
									"No Signature"
								)
							}
						/>
					</DataContainer>
				)}
			</View>
		</ScrollView>
	);
};

DailySheetView.propTypes = {
	timeEntry: PropTypes.object,
	dailyDocument: PropTypes.object,
	showTimeEntryDetails: bool,
};

export default DailySheetView;
