import React from "react";
import PropTypes from "prop-types";

// SVG

import { Rect } from "react-native-svg";
import Badge from "./Badge";

const Rectangle = ({
	symbol,
	points,
	color,
	previouslyDemoed,
	onPress = () => {},
}) => {
	let width = points[points.length - 1].width;
	let height = points[points.length - 1].height;
	let middleX = (points[0].x + points[points.length - 1].x) / 2;
	let middleY = (points[0].y + points[points.length - 1].y) / 2;

	return (
		<>
			<Rect
				// Reduce the points to be a string

				x={points[0].x}
				y={points[0].y}
				width={width}
				height={height}
				fill={color || "white"}
				stroke={color || "black"}
				strokeWidth={10}
				fillOpacity={0.5}
				strokeDasharray={previouslyDemoed ? "10,10" : "0"}
				onPress={() => onPress()}
			/>
			{middleX && middleY && symbol ? (
				<Badge middleX={middleX} middleY={middleY} symbol={symbol} />
			) : null}
		</>
	);
};

Rectangle.propTypes = {
	symbol: PropTypes.string,
	points: PropTypes.array,
	color: PropTypes.string,
	previouslyDemoed: PropTypes.bool,
	onPress: PropTypes.func,
};

export default Rectangle;
