import { useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import React from "react";
import { Platform } from "react-native";

// Icons
import { faHome } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

// SVG
import Svg, { Path } from "react-native-svg";

// Lighting
import { useColorScheme } from "nativewind";

import { Text, TouchableOpacity, View } from "react-native";

export default function Breadcrumb({
	children,
	//navigation,
	baseLocation = "Projects",
}) {
	if (Platform.OS === "web") {
		const navigation = useNavigation();
		const { colorScheme } = useColorScheme();
		return (
			<>
				<View className="flex " aria-label="Breadcrumb">
					<View className="bg-white rounded-md shadow sm:px-6 flex-row space-x-4 m-1">
						<View className="flex-row">
							<TouchableOpacity
								onPress={() => {
									navigation.navigate(baseLocation);
								}}
								className="text-gray-400 hover:text-gray-500 flex items-center justify-center "
							>
								<FontAwesomeIcon
									icon={faHome}
									aria-hidden="true"
									color={colorScheme === "light" ? "black" : "white"}
								/>
								<Text className="sr-only">Home</Text>
							</TouchableOpacity>
						</View>
						{children}
					</View>
				</View>
			</>
		);
	} else {
		return null;
	}
}

export const Crumb = ({ name, location, params, current }) => {
	const navigation = useNavigation();
	return (
		<>
			<View className="flex">
				<View className="flex flex-row items-center">
					<Svg
						className="flex-shrink-0 w-6 h-full text-gray-200 dark:text-gray-400"
						viewBox="0 0 24 44"
						preserveAspectRatio="none"
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
					>
						<Path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
					</Svg>
					<TouchableOpacity
						onPress={() => {
							navigation.navigate(location, params);
						}}
						className="ml-4 "
						aria-current={current ? "page" : undefined}
					>
						<Text
							testID="crumb-text"
							className="text-sm font-medium text-gray-500 dark:text-gray-200 hover:text-gray-700 dark:hover:text-gray-500"
						>
							{name}
						</Text>
					</TouchableOpacity>
				</View>
			</View>
		</>
	);
};

Breadcrumb.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	navigation: PropTypes.any,
	baseLocation: PropTypes.string,
};
Crumb.propTypes = {
	name: PropTypes.string.isRequired,
	location: PropTypes.string,
	params: PropTypes.object,
	current: PropTypes.bool,
};
