export const listFields = /* GraphQL */ `
	query ListFields(
		$filter: TableFieldFilterInput
		$limit: Int
		$nextToken: String
	) {
		listFields(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				sageItemID
				categoryType
				active
			}
		}
	}
`;

export const createField = /* GraphQL */ `
	mutation CreateField($input: FieldInput!) {
		createField(input: $input) {
			name
			type
			amount
		}
	}
`;

export const updateField = /* GraphQL */ `
	mutation UpdateField($input: FieldInput!) {
		updateField(input: $input) {
			name
			type
			amount
		}
	}
`;

export const deleteField = /* GraphQL */ `
	mutation DeleteField($input: FieldInput!) {
		deleteField(input: $input) {
			name
			type
			amount
		}
	}
`;

export const bulkCreateFields = /* GraphQL */ `
	mutation BulkCreateFields($input: [FieldInput!]!) {
		bulkCreateFields(input: $input) {
			name
			type
			amount
		}
	}
`;

export const bulkUpdateFields = /* GraphQL */ `
	mutation BulkUpdateFields($input: [FieldInput!]!) {
		bulkUpdateFields(input: $input) {
			name
			type
			amount
		}
	}
`;
