export const listSubcontractors = /* GraphQL */ `
	query ListSubcontractors(
		$filter: TableSubcontractorFilterInput
		$limit: Int
		$nextToken: String
	) {
		listSubcontractors(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				users {
					id
					firstName
					lastName
				}
				name
			}
		}
	}
`;

export const listProjectRoles = /* GraphQL */ `
	query ListProjectRoles(
		$filter: TableProjectRoleFilterInput
		$limit: Int
		$nextToken: String
	) {
		listProjectRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
			}
			nextToken
		}
	}
`;

export const createSubcontractor = /* GraphQL */ `
	mutation CreateSubcontractor($input: CreateSubcontractorInput!) {
		createSubcontractor(input: $input) {
			id
			name
			users {
				id
				firstName
				lastName
			}
		}
	}
`;

export const updateSubcontractor = /* GraphQL */ `
	mutation UpdateSubcontractor($input: UpdateSubcontractorInput!) {
		updateSubcontractor(input: $input) {
			id
			name
			users {
				id
				firstName
				lastName
			}
		}
	}
`;

export const getSubcontractor = /* GraphQL */ `
	query GetSubcontractor($id: ID!) {
		getSubcontractor(id: $id) {
			id
			name
			users {
				id
				firstName
				lastName
				profilePicture
				email
				phone
				bio
				role
				isAdmin
				isClerk
				isHR
				isTeamMember
				isContractor
				isPayrollAdmin
				isTimeAdmin
				isTimeOverrider
				isEquipmentManager
				isLogistic
				isActive
				isSalary
				subAdmin
				linkExpiration
				canReviewReports
				currentYearMaxAllotment
				companyStartDate
			}
			projects {
				id
				title
				sageIntacct
				startDate
				stage
			}
		}
	}
`;
