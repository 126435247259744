/* This example requires Tailwind CSS v2.0+ */

import React from "react";
import PropTypes from "prop-types";
import { View, TouchableOpacity, Text } from "react-native";

// Components
import User from "./User";

export default function CardContainer({ children }) {
	return (
		<View className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mx-2">
			{children}
		</View>
	);
}

CardContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export const Card = ({
	surTitle,
	user,
	title,
	subheading,
	content,
	options,
	onPress,
	children,
}) => {
	const getContent = () => {
		if (typeof content === "string") {
			return <Text className="text-gray-900 dark:text-white">{content}</Text>;
		}
		return content;
	};

	if (onPress) {
		return (
			<View className="m-2">
				<TouchableOpacity
					className={`bg-gray-50 dark:bg-gray-900 px-4 py-5 sm:px-6 rounded divide-solid ${
						onPress &&
						"transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 cursor-pointer"
					}`}
					onPress={() => {
						if (onPress) {
							onPress();
						}
					}}
				>
					<View className="flex">
						<View className="border-b-1 border-gray-500">
							{surTitle && <Text testID="surtitle">{surTitle}</Text>}
							{title && (
								<Text
									testID="title"
									className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
								>
									{title}
								</Text>
							)}
							{subheading && (
								<Text
									testID="subheading"
									className="mt-1 mb-1 text-sm text-gray-500"
								>
									{subheading}
								</Text>
							)}
						</View>
						<View>{children && children}</View>
						<View>{options && options}</View>
						<View>{content && getContent(content)}</View>
						<View className="mt-2">{user && <User user={user} />}</View>
					</View>
				</TouchableOpacity>
			</View>
		);
	}
	return (
		<View className="m-2">
			<View
				className={
					"bg-gray-50 dark:bg-gray-900 px-4 py-5 sm:px-6 rounded divide-solid"
				}
			>
				<View className="flex">
					<View className="border-b-1 border-gray-500">
						{surTitle && <Text testID="surtitle">{surTitle}</Text>}
						{title && (
							<Text
								testID="title"
								className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
							>
								{title}
							</Text>
						)}
						{subheading && (
							<Text
								testID="subheading"
								className="mt-1 mb-1 text-sm text-gray-500"
							>
								{subheading}
							</Text>
						)}
					</View>
					<View>{children && children}</View>
					<View>{options && options}</View>
					<View>{content && getContent(content)}</View>
					<View className="mt-2">{user && <User user={user} />}</View>
				</View>
			</View>
		</View>
	);
};

Card.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	surTitle: PropTypes.string,
	user: PropTypes.object,
	title: PropTypes.string,
	subheading: PropTypes.string,
	content: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	options: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	onPress: PropTypes.func,
};
