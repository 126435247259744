import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { Platform, ScrollView, Text, View } from "react-native";
//import * as Sharing from "expo-sharing";
import { useNavigation } from "@react-navigation/native";
import * as Linking from "expo-linking";

// Components
import { Alert } from "../../../components/Alert";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import CardContainer, { Card } from "../../../components/Card";
import DataContainer, { DataItem } from "../../../components/DataDisplay";
import Gallery, { GalleryItem } from "../../../components/Gallery";
import { PageHeader } from "../../../components/Header";
import LoadingScreen from "../../../components/LoadingScreen.js";
import NoItems from "../../../components/NoItems";
import Tabs, { Tab } from "../../../components/Tabs";

//Icons
import {
	faInfoCircle,
	faList,
	faPen,
	faPencil,
	faPlus,
} from "@fortawesome/pro-duotone-svg-icons";

// Containers

import { AuditSlideOver } from "../../../containers/AuditSlideOver";

// GQL

import { gql, useQuery } from "@apollo/client";
import * as surveyGQL from "../../../graphql/audits";

// Utils
import { API_URL, getData, getSignedUrl, SentryLogger } from "../../../utils";

const tabs = [
	{ name: "Items", icon: faList },
	{
		name: "Information",

		icon: faInfoCircle,
	},
];

export const Audit = ({ route }) => {
	const { id } = route.params;
	const user = getData("@storage_Key").then((user) => user);
	const navigation = useNavigation();
	const [projectUser, setProjectUser] = React.useState({});
	const [newItem, setNewItem] = React.useState([]);
	const [survey, setSurvey] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState(tabs[0].name);
	let [generating, setGenerating] = React.useState(false);

	// if (surveyInfo) {
	// 	projectUser = getCurrentUser(surveyInfo.project, navigation, user);
	// }

	let { loading, refetch } = useQuery(gql(surveyGQL.getSurvey), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			const getCurrentUser = async (data) => {
				const userData = await getData("@storage_Key").then((user) => user);
				let team;
				if (data && data.project.workspace) {
					team = data.project.workspace.team;
				} else {
					team = data.project.team;
				}
				let projectUser = team.find(
					(tm) => tm.teamMember && tm.teamMember.id === userData.id
				);
				setProjectUser(projectUser);

				return projectUser;
			};
			setSurvey(data.getSurvey);
			getCurrentUser(data.getSurvey);
		},
		onError: (error) => {
			Alert(
				"We cannot find this project in our system. Make sure you have the right url."
			);
			SentryLogger(JSON.stringify(error, null, 2));
			navigation.goBack();
		},
		fetchPolicy: "cache-and-network",
	});
	if (!survey || loading) return <LoadingScreen loadItem={"Audit"} />;

	const navigateToAuditItem = (id) => {
		return navigation.navigate("AuditItem", {
			id: id,
		});
	};

	const generateReport = async () => {
		setGenerating(true);
		await fetch(`${API_URL}/site-audit`, {
			method: "POST",
			body: JSON.stringify(survey),
			mode: "cors",
		})
			.then((res) => {
				if (res.status === 200) {
					return res.json();
				} else {
					Alert("There was an error generating the file");
				}
			})
			.then((data) => {
				setGenerating(false);

				if (!data && !data.url) {
					Alert("There was an error generating the file");
				} else {
					Linking.openURL(data.url);
				}
			})
			.catch((error) => {
				setGenerating(false);
				SentryLogger(JSON.parse(error, null, 2));
				Alert(
					"Error Generating Report",
					"There was an error generating the report. Try again. If the error persist please contact support at help.srpinfield.com`"
				);
			});
	};

	const getButtons = () => {
		if (currentTab === "Items") {
			return !projectUser.isReadOnly && user
				? [
						{
							name: "Add Item",
							icon: faPlus,
							onPress: () => setNewItem("auditItem"),
						},
				  ]
				: [];
		}

		if (currentTab === "Information") {
			return !projectUser.isReadOnly && user
				? [
						{
							name: "Update Audit Information",
							icon: faPen,
							onPress: () => setNewItem("auditItem"),
						},
				  ]
				: [];
		}
	};

	return (
		<>
			<Breadcrumb className="mt-2">
				<>
					{survey.project && survey.project.workspace && (
						<Crumb
							name={survey.project.workspace.title}
							location="Project"
							params={{ id: survey.project.workspace.id }}
						/>
					)}
				</>
				<Crumb
					name={survey.project.title}
					location="Project"
					params={{ id: survey.project.id }}
				/>

				<Crumb
					name={survey.title}
					location="Audit"
					params={{ id: survey.id }}
				/>
			</Breadcrumb>

			<PageHeader
				title={survey.title ? survey.title : "Unnamed Survey Collection"}
				description={survey.project && survey.project.title}
				buttons={getButtons()}
				goBack={{
					location: "Project",
					params: {
						id: survey.project.id,
					},
				}}
			/>
			<Tabs>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							current={currentTab === tab.name ? true : false}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>

			{currentTab === "Items" && (
				<>
					{survey.items && survey.items.length ? (
						<ScrollView>
							<CardContainer>
								{survey.items.map((item, index) => {
									return (
										<Card
											key={index}
											onPress={() => navigateToAuditItem(item.id)}
											user={item.user && item.user}
											title={item.title}
											subheading={
												item.date
													? DateTime.fromISO(item.date).toLocaleString(
															DateTime.DATETIME_FULL
													  )
													: "No Date"
											}
											content={
												<Text className="text-gray-900 dark:text-white">
													{item.description}
												</Text>
											}
										/>
									);
								})}
							</CardContainer>
						</ScrollView>
					) : (
						<NoItems
							surTitle={"No Items"}
							title="Currently No Items"
							text="Create a item by clicking new item"
						/>
					)}

					<AuditSlideOver
						isOpen={newItem === "auditItem"}
						onClose={() => setNewItem(null)}
						onComplete={() => {
							setNewItem(null);
							refetch();
						}}
						user={user}
						survey={survey}
						type="create"
					/>
				</>
			)}

			{currentTab === "Information" && (
				<>
					<DataContainer
						title={survey.title ? survey.title : "No Audit Collection Title"}
						description="Information about audit collection"
					>
						<DataItem
							name="Number of Items"
							value={
								<Text className="text-gray-900 dark:text-white">
									{survey.items ? survey.items.length : "No Items"}
								</Text>
							}
						/>

						<DataItem
							name="UUID"
							value={
								<Text className="text-gray-900 dark:text-white">
									{survey && survey.id}
								</Text>
							}
						/>
					</DataContainer>

					<View className="m-2">
						<Button
							title="Generate Report"
							onPress={() => {
								generateReport(survey);
							}}
							enabled={!generating}
							text={generating ? "Generating..." : "Generate Report"}
						/>
					</View>
				</>
			)}
		</>
	);
};

Audit.propTypes = {
	navigation: PropTypes.object.isRequired,
	survey: PropTypes.object,
	user: PropTypes.object,
	reload: PropTypes.func,
	route: PropTypes.object,
};

export const AuditItem = ({ route }) => {
	const { id } = route.params;
	const navigation = useNavigation();
	const [projectUser, setProjectUser] = React.useState({});
	const [user, setUser] = React.useState({});
	const [surveyItem, setSurveyItem] = React.useState(null);
	const [newItem, setNewItem] = React.useState(null);

	React.useEffect(() => {
		const getUser = async () => {
			await getData("@storage_Key").then((user) => setUser(user));
		};
		getUser();
	}, []);

	const { refetch } = useQuery(gql(surveyGQL.getSurveyItem), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			const getCurrentUser = async (data) => {
				const userData = await getData("@storage_Key").then((user) => user);
				let team;
				if (data && data.survey.project.workspace) {
					team = data.survey.project.workspace.team;
				} else {
					team = data.survey.project.team;
				}
				let projectUser = team.find(
					(tm) => tm.teamMember && tm.teamMember.id === userData.id
				);
				setProjectUser(projectUser);
				// setAudit(data.getSurveyItem);
				// getMedia(data.getSurveyItem);
			};

			setSurveyItem(data.getSurveyItem);
			getCurrentUser(data.getSurveyItem);
		},
		onError: (error) => {
			Alert("We cannot find this survey in our system. Please try again.");
			SentryLogger(JSON.stringify(error, null, 2));
			navigation.goBack();
		},
	});

	if (!surveyItem) {
		return <LoadingScreen loadItem={"Sample"} />;
	}

	return (
		<View className="bg-white dark:bg-gray-900  h-full">
			<>
				<Breadcrumb>
					<Crumb
						name={surveyItem.survey.project.title}
						location="Project"
						params={{ id: surveyItem.survey.project.id }}
					/>
					<Crumb
						name={surveyItem.survey && surveyItem.survey.title}
						location="Audit"
						params={{ id: surveyItem.survey.id }}
					/>
					<Crumb
						name={surveyItem.title}
						location="AuditItem"
						params={{ id: surveyItem.id }}
					/>
				</Breadcrumb>
				<PageHeader
					title={surveyItem ? surveyItem.title : "Unnamed Item"}
					description={
						surveyItem && surveyItem.survey && surveyItem.survey.title
							? surveyItem.survey.title
							: "No Survey Title"
					}
					goBack={{
						location: "Audit",
						params: { id: surveyItem.survey.id },
					}}
					buttons={
						!projectUser.isReadOnly && user
							? [
									{
										name: "Update Audit Items",
										icon: faPencil,
										onPress: () => {
											setNewItem("updateSurvey");
										},
									},
							  ]
							: []
					}
				/>

				{/* Todo add data container containing the single item information */}

				<DataContainer
					title={
						surveyItem.title ? surveyItem.title : "No Audit Collection Title"
					}
					description={
						surveyItem.date
							? DateTime.fromISO(surveyItem.date).toLocaleString(
									DateTime.DATETIME_SHORT
							  )
							: "No Date"
					}
				>
					<>
						{surveyItem.media && surveyItem.media.length ? (
							<DataItem
								name={"Media"}
								value={
									<Gallery>
										{surveyItem.media.map((item, index) => {
											let signedURL = getSignedUrl(item.key);

											return <GalleryItem key={index} source={signedURL} />;
										})}
									</Gallery>
								}
							/>
						) : null}
					</>

					<DataItem
						name="Description"
						value={
							<Text className="text-gray-900 dark:text-white">
								{surveyItem.description
									? surveyItem.description
									: "No Item Description"}
							</Text>
						}
					/>

					<>
						{surveyItem.location ? (
							<>
								{Platform.OS !== "web" ? (
									<DataItem
										name={"Location"}
										value={
											<View className="mt-2">
												<Text className="text-gray-900 dark:text-white">
													Latitude: {JSON.parse(surveyItem.location).latitude}
												</Text>
												<Text className="text-gray-900 dark:text-white">
													Longitude: {JSON.parse(surveyItem.location).longitude}
												</Text>

												<Text className="text-gray-500 mt-2">
													Location viewing is not currently supported, we are
													working on a fix.
												</Text>
											</View>
										}
									/>
								) : (
									<DataItem
										name={"Location"}
										value={
											<View className="mt-2">
												<Text className="text-gray-900 dark:text-white">
													Latitude: {JSON.parse(surveyItem.location).latitude}
												</Text>
												<Text className="text-gray-900 dark:text-white">
													Longitude: {JSON.parse(surveyItem.location).longitude}
												</Text>

												<Text className="text-gray-500 mt-2">
													Location viewing is not currently supported on web.
													Please use a mobile device to view the location on a
													map
												</Text>
											</View>
										}
									/>
								)}
							</>
						) : null}
					</>

					<DataItem
						name="Created By"
						value={
							<Text className="text-gray-900 dark:text-white">
								{surveyItem && surveyItem.user && surveyItem.user.firstName}{" "}
								{surveyItem && surveyItem.user && surveyItem.user.lastName}
							</Text>
						}
					/>
				</DataContainer>
				<AuditSlideOver
					isOpen={newItem === "updateSurvey"}
					onClose={() => setNewItem(null)}
					onComplete={() => {
						setNewItem(null);
						refetch();
					}}
					user={user}
					currentItem={surveyItem}
					type="update"
				/>
			</>
		</View>
	);
};

AuditItem.propTypes = {
	navigation: PropTypes.object.isRequired,
	survey: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	reload: PropTypes.func.isRequired,
	route: PropTypes.object,
};
