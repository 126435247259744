export const listProjects = /* GraphQL */ `
	query ListProjects(
		$filter: TableProjectFilterInput
		$limit: Int
		$nextToken: String
	) {
		listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				title
				number
				stage
				client {
					id
					name
				}
				workspace {
					id
					title
				}
			}
			nextToken
		}
	}
`;

export const createProjectTeamMember = /* GraphQL */ `
	mutation CreateProjectTeamMember($input: CreateProjectTeamMemberInput!) {
		createProjectTeamMember(input: $input) {
			id
			projectRole {
				id
				name
			}
			teamMember {
				id
			}
			project {
				id
			}
		}
	}
`;

export const listProjectRoles = /* GraphQL */ `
	query ListProjectRoles {
		listProjectRoles {
			items {
				id
				name
			}
		}
	}
`;

export const deleteAllProjectData = /* GraphQL */ `
	mutation DeleteAllProjectData($input: DeleteProjectInput!) {
		deleteAllProjectData(input: $input) {
			id
		}
	}
`;
