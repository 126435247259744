import { useNavigation } from "@react-navigation/native";
import React from "react";

// navigation
import { Navigation as NavigationContainer } from "../components/Navigation";
import { subcontractorAdminNav } from "../routes/subcontractorAdmin";

// utils
import { getData } from "../utils";

export const SubcontractorAdminLayout = () => {
	const navigation = useNavigation();
	React.useEffect(() => {
		// get user and redirect if logged in
		const getLocalUserDetails = async () => {
			await getData("@storage_Key").then((user) => {
				if (
					(user === "null" ||
						user === null ||
						user === undefined ||
						!user.token) &&
					!user.subAdmin
				) {
					return navigation.navigate("Subcontractor Users");
				}
			});
		};
		getLocalUserDetails();
	});
	return (
		<>
			<NavigationContainer
				routes={subcontractorAdminNav}
				layoutName="Subcontractor Center"
			/>
		</>
	);
};
