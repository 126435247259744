import { useNavigation } from "@react-navigation/native";
import React from "react";

// navigation
import { Navigation as NavigationContainer } from "../components/Navigation";
import { financeAndHrCenterNav } from "../routes/financeHRCenter";

// utils
import { getData } from "../utils";

export const FinanceAndHrCenterLayout = () => {
	const navigation = useNavigation();
	React.useEffect(() => {
		// get user and redirect if logged in
		const getLocalUserDetails = async () => {
			await getData("@storage_Key").then((user) => {
				if (user) {
					if (
						(user === "null" ||
							user === null ||
							user === undefined ||
							!user.token) &&
						!user.isTimeAdmin
					) {
						return navigation.navigate("App");
					}
				} else {
					return navigation.navigate("App");
				}
			});
		};
		getLocalUserDetails();
	});
	return (
		<>
			<NavigationContainer
				routes={financeAndHrCenterNav}
				layoutName="Finance and HR Center"
			/>
		</>
	);
};
