export const listBillingPeriods = /* GraphQL */ `
	query ListBillingPeriods(
		$filter: TableBillingPeriodFilterInput
		$limit: Int
		$nextToken: String
	) {
		listBillingPeriods(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				startDate
				endDate
				status
			}
		}
	}
`;

export const createBillingPeriod = /* GraphQL */ `
	mutation CreateBillingPeriod($input: CreateBillingPeriodInput!) {
		createBillingPeriod(input: $input) {
			id
			name
			startDate
			endDate
			status
		}
	}
`;

export const updateBillingPeriod = /* GraphQL */ `
	mutation UpdateBillingPeriod($input: UpdateBillingPeriodInput!) {
		updateBillingPeriod(input: $input) {
			id
			name
			startDate
			endDate
			status
		}
	}
`;

export const deleteBillingPeriod = /* GraphQL */ `
	mutation DeleteBillingPeriod($input: DeleteBillingPeriodInput!) {
		deleteBillingPeriod(input: $input) {
			id
			name
			startDate
			endDate
			status
		}
	}
`;

export const getBillingPeriod = /* GraphQL */ `
	query GetBillingPeriod($id: ID!) {
		getBillingPeriod(id: $id) {
			id
			name
			startDate
			endDate
			status
		}
	}
`;
