import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";

// Components
import { Input } from "../components/Forms";
import { addNotification } from "../components/Notification";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation } from "@apollo/client";
import * as projectGQL from "../graphql/project";

export const ProjectAuditSlideOver = ({
	type = "create",
	currentSurvey,
	isOpen,
	closeRequest,
	onComplete,
	project,
}) => {
	const [survey, setSurvey] = React.useState({});

	React.useEffect(() => {
		if (currentSurvey) {
			setSurvey(currentSurvey);
		}
	}, [currentSurvey]);

	const [createNewAudit] = useMutation(gql(projectGQL.createAudit), {
		onCompleted: (data) => {
			onComplete(data.createSurvey);

			setSurvey({});
			addNotification({
				title: "Audit Created Successfully",
				text: "Audit was successfully created!",
				type: "success",
				time: 5000,
			});
		},
		onError: () => {
			addNotification({
				title: "Something Went Wrong",
				text: "Your audit could not be created.",
				type: "error",
				time: 5000,
				supportText: true,
			});
		},
	});

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "New Audit" : "Edit audit"}
			description={
				type === "create" ? `Add a new audit to ${project.title}` : "Edit audit"
			}
			closeRequest={() => closeRequest()}
			onSubmit={() => {
				let input = survey;
				input.date = DateTime.now().toISO();
				input.project = project.id;
				if (type === "create") {
					createNewAudit({ variables: { input: input } });
				} else {
					updateAudit({
						variables: {
							input: input,
						},
					});
				}
			}}
			buttonRightText={type === "create" ? "Create" : "Update"}
			project={project}
		>
			<Input
				required
				name="name"
				Text="Name"
				placeholder="Initial Audit"
				onChange={(e) => setSurvey({ ...survey, title: e })}
			/>
		</SlideOver>
	);
};
ProjectAuditSlideOver.propTypes = {
	route: PropTypes.object,
	type: PropTypes.string,
	currentSurvey: PropTypes.object,
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func,
	onComplete: PropTypes.func,
	project: PropTypes.object,
};
