import {
	ApolloClient,
	ApolloLink,
	InMemoryCache,
	createHttpLink,
	from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useEffect, useState } from "react";
import Toast from "react-native-toast-message";

import { devAPIDomain, getData, inProduction, storeData } from "../utils";

export const apiDomain = inProduction
	? "https://yawlbrvguh.execute-api.us-east-1.amazonaws.com/v1/graphql"
	: `${devAPIDomain}:4000/dev/graphql`;

export const useApolloClient = ({ removeUser = () => {} }) => {
	const [client, setClient] = useState();

	const httpLink = createHttpLink({
		uri: apiDomain,
	});

	const authLink = setContext((_, { headers }) => {
		// return the headers to the context so httpLink can read them

		return Promise.all([getData("@storage_Key")]).then((values) => {
			let token = values[0] ? values[0].token : null;

			let headersData = {
				...headers,
				authorization: token ? `Bearer ${token}` : "",
			};

			return {
				headers: headersData,
			};
		});
	});

	const errorLink = onError(
		({ graphQLErrors, networkError, operation, forward }) => {
			if (graphQLErrors) {
				graphQLErrors.map(({ message }) => {
					if (!inProduction) {
						// eslint-disable-next-line no-console
						console.log(`[GraphQL error]: Message: ${message}`);
					}
				});
			}
			if (networkError) {
				if (networkError.result?.errors) {
					networkError.result.errors.forEach(({ extensions }) => {
						switch (extensions.code) {
							case "UNAUTHENTICATED":
								Promise.all([storeData("@storage_Key", null)]).then(() => {
									Toast.show({
										type: "error",
										text1: "Session Expired",
										text2: "Please login again",
									});

									removeUser();
								});
								// Skip the query if the user is unauthenticated
								operation.setContext({ skip: true });
								break;
							case "FORBIDDEN":
								Toast.show({
									type: "error",
									text1: "Network Error",
									text2: "Please check your internet connection",
								});

								break;
							default:
								Toast.show({
									type: "error",
									text1: "Network Error",
									text2: "Please check your internet connection",
								});

								break;
						}
					});
				}
			}
			// Call the next link in the chain
			return forward(operation);
		}
	);

	const authAfterware = new ApolloLink((operation, forward) => {
		return forward(operation).map((response) => {
			const { errors } = response;

			if (errors) {
				for (const error of errors) {
					if (error.extensions?.code === "UNAUTHENTICATED") {
						// Clear the cache and log the user out
						Promise.all([storeData("@storage_Key", null)]).then(() => {
							Toast.show({
								type: "error",
								text1: "Session Expired",
								text2: "Please login again",
							});

							removeUser();
						});
						removeUser();
					}
				}
			}

			return response;
		});
	});

	useEffect(() => {
		function init() {
			const cache = new InMemoryCache();

			// await before instantiating ApolloClient, else queries might run before the cache is persisted

			// await persistCache({
			// 	cache,
			// 	storage: new AsyncStorageWrapper(AsyncStorage),
			// 	maxSize: 300 * 1024 * 1024,
			// });

			setClient(
				new ApolloClient({
					link: from([errorLink, authAfterware, authLink.concat(httpLink)]),
					cache,
				})
			);
		}

		init();
	}, []);

	return {
		client,
	};
};
