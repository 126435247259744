import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { useNavigation } from "@react-navigation/native";

// Components

import { Alert } from "../../../components/Alert";
import LoadingScreen from "../../../components/LoadingScreen";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import NoItems from "../../../components/NoItems";
import { PageHeader } from "../../../components/Header";
import { List, ListItem } from "../../../components/List";
import OptionsMenu from "../../../components/OptionsMenu";

// GQL

import { gql, useQuery } from "@apollo/client";
import * as queries from "../../../graphql/projectReports";

// Utils
import { SentryLogger } from "../../../utils";

// Icons
import { faPlus, faEye } from "@fortawesome/pro-duotone-svg-icons";

export const ProjectReports = ({ route }) => {
	const { id } = route.params;
	const [project, setProject] = React.useState(null);
	const navigation = useNavigation();

	useQuery(gql(queries.getProjectReports), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setProject(data.getProject);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("Could not get project reports. Please try again");
		},
	});

	const navigateToReport = (report) => {
		navigation.navigate("Report", {
			id: report.id,
		});
	};

	if (!project) {
		return <LoadingScreen loadItem={"Project Reports"} />;
	}
	return (
		<>
			<Breadcrumb className="mt-2">
				<>
					{project.workspace && (
						<Crumb
							name={project.workspace.title}
							location="Project"
							params={{ id: project.workspace.id }}
						/>
					)}
				</>

				<Crumb
					name={project.title}
					location="Project"
					params={{ id: project.id }}
				/>
				<Crumb
					name="Report Center"
					location="Reporting Center"
					params={{ id: project.id }}
				/>
			</Breadcrumb>

			<PageHeader
				title="Reports"
				goBack={{
					location: "Project",
					params: { id: project.id },
				}}
				buttons={[
					{
						name: "Create Report",
						icon: faPlus,
						onPress: () => {
							navigation.navigate("New Report", {
								id: id,
							});
						},
					},
				]}
			/>

			<>
				{project.reports.length ? (
					<List headers={["Name", "Date", "Author", "Reviewer"]} usesOptions>
						{project.reports.map((report, index) => {
							return (
								<ListItem
									key={index}
									items={[
										{
											content: report.reportType.name
												? report.reportType.name
												: "No Report Name",
											onPress: () => navigateToReport(report),
										},
										{
											content: report.date
												? DateTime.fromISO(report.date).toFormat("MMMM D, YYYY")
												: "Creation Date Unavailable",
											onPress: () => navigateToReport(report),
										},
										{
											content: report.author
												? `${report.author.firstName} ${report.author.lastName}`
												: "No Author",

											onPress: () => navigateToReport(report),
										},
										{
											content: report.reviewer
												? `${report.reviewer.firstName} ${report.reviewer.lastName}`
												: "No Reviewer",
											onPress: () => navigateToReport(report),
										},
									]}
									options={
										<OptionsMenu
											options={[
												{
													name: "View",
													icon: faEye,
													onPress: () => {
														navigateToReport(report);
													},
												},
											]}
										/>
									}
								/>
							);
						})}
					</List>
				) : (
					<NoItems
						surTitle={"No Reports"}
						title="Currently No Reports"
						text="Create a report by clicking create above"
					/>
				)}
			</>
		</>
	);
};

ProjectReports.propTypes = {
	route: PropTypes.object,
};
