export const listLabs = /* GraphQL */ `
	query ListLabs(
		$limit: Int
		$nextToken: String
		$filter: TableLabFilterInput
	) {
		listLabs(limit: $limit, nextToken: $nextToken, filter: $filter) {
			items {
				id
				name
				isEMPATAccredited
				locations {
					id
				}
			}
		}
	}
`;

export const getLab = /* GraphQL */ `
	query GetLab($id: ID!) {
		getLab(id: $id) {
			id
			name

			locations {
				name
				lineOne
				lineTwo
				city
				state
				zip
			}
		}
	}
`;

export const createLab = /* GraphQL */ `
	mutation CreateLab($input: CreateLabInput!) {
		createLab(input: $input) {
			id
		}
	}
`;

export const updateLab = /* GraphQL */ `
	mutation UpdateLab($input: UpdateLabInput!) {
		updateLab(input: $input) {
			id
		}
	}
`;
