import { useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
	Image,
	Pressable,
	ScrollView,
	Text,
	TouchableOpacity,
	View,
} from "react-native";

// Components
import { Alert } from "../../components/Alert";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import { Card } from "../../components/Card";
import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List";
import LoadingScreen from "../../components/LoadingScreen";
import NoItems from "../../components/NoItems";
import OptionsMenu from "../../components/OptionsMenu";
import Pagination from "../../components/Pagination";
import Tabs, { Tab } from "../../components/Tabs";

//Containers
import { NoteSlideOver } from "../../containers/NoteSlideOver";

// GraphQL
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as safetyGQL from "../../graphql/safety/safetyQuestionnaire";
import * as userGQL from "../../graphql/user";

//Utils
import { API_URL, SentryLogger, getData, getSignedUrl } from "../../utils";

// Icons
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import {
	faFileInvoice,
	faInfoCircle,
	faNote,
	faPlus,
	faSearch,
} from "@fortawesome/pro-duotone-svg-icons";
import DataContainer, { DataItem } from "../../components/DataDisplay";
import { Select } from "../../components/Forms";
import SlideOver from "../../components/Slideover";
import { SafetyQuestionnaireSlideOver } from "../../containers/SafetySlideOver";

// Icons
import {
	faMemoCircleInfo,
	faMemoPad,
	faNewspaper,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

const DataCard = ({ name, filter, onPress, icon, currentSelected }) => {
	const { data, loading } = useQuery(gql(safetyGQL.listSafetyQuestionnaires), {
		variables: {
			filter: filter,
		},
		fetchPolicy: "cache-and-network",
	});

	return (
		<Pressable
			onPress={() => onPress()}
			className={`${
				currentSelected ? "bg-indigo-500" : "bg-white"
			} my-2 mx-2 flex-1 shadow rounded-lg  justify-between flex flex-col`}
		>
			<View className="p-5">
				<View className="flex-col md:items-center">
					<View className="flex-shrink-0 mb-2 md:mb-0">
						<FontAwesomeIcon
							icon={icon}
							size={24}
							color={currentSelected ? "white" : "black"}
							aria-hidden="true"
						/>
					</View>
					<View className="md:ml-5">
						<Text
							className={`text-sm font-medium ${
								currentSelected ? "text-white" : "text-gray-500"
							} `}
						>
							{name}
						</Text>
						<Text
							className={`text-lg font-medium ${
								currentSelected ? "text-white" : "text-gray-900"
							}`}
						>
							{loading
								? "Loading data... "
								: data?.listSafetyQuestionnaires?.total || 0}
						</Text>
					</View>
				</View>
			</View>
			<View
				className={`${
					currentSelected ? "bg-indigo-50" : "bg-gray-50"
				} px-5 py-3 `}
			>
				<View className="text-sm ">
					<Pressable onPress={() => onPress()}>
						<Text className="font-medium text-cyan-700 hover:text-cyan-900">
							{currentSelected ? "Currently Viewing" : "View All"}
						</Text>
					</Pressable>
				</View>
			</View>
		</Pressable>
	);
};

DataCard.propTypes = {
	name: PropTypes.string.isRequired,
	filter: PropTypes.object.isRequired,
	onPress: PropTypes.func.isRequired,
	icon: PropTypes.object.isRequired,
	currentSelected: PropTypes.bool,
};

export const SafetyReports = ({}) => {
	const navigation = useNavigation();
	const [reports, setReports] = React.useState([]);
	const [isEditOpen, setIsEditOpen] = React.useState(false);
	const [safetyUsers, setSafetyUsers] = React.useState([]);
	const [safetyQuestionnaire, setSafetyQuestionnaire] = React.useState({});
	const [user, setUser] = React.useState({});
	const [currentSelected, setCurrentSelected] = React.useState(0);
	const itemsPerPage = 10;
	let [currentPage, setCurrentPage] = useState(1);
	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end = reports
		? currentPage * itemsPerPage > reports.length
		: currentPage * itemsPerPage;

	React.useEffect(() => {
		const getUserInfo = async () => {
			getData("@storage_Key").then((data) => {
				setUser(data);
			});
		};
		getUserInfo();
	}, []);

	const cards = [
		{
			name: "Submitted Today",
			onPress: "#",
			icon: faNewspaper,
			filter: {
				dateCreated: {
					between: [
						new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
						new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
					],
				},
			},
		},

		{
			name: "Assigned to Me",
			onPress: "#",
			icon: faNewspaper,
			filter: {
				reviewer: {
					eq: user?.id,
				},
			},
		},

		{
			name: "No Status",
			onPress: "#",
			icon: faMemoCircleInfo,
			filter: {
				status: {
					eq: null,
				},
			},
		},

		{
			name: "Under Investigation",
			onPress: "#",
			icon: faSearch,
			filter: {
				status: {
					eq: "Under Investigation",
				},
			},
		},
		{
			name: "Completed",
			onPress: "#",
			icon: faSearch,
			filter: {
				status: {
					eq: "Completed",
				},
			},
		},
		{
			name: "All Safety Reports",
			onPress: "#",
			icon: faMemoPad,
			filter: {},
		},
	];

	const [listSafetyQuestionnaires, { loading, refetch }] = useLazyQuery(
		gql(safetyGQL.listSafetyQuestionnaires),
		{
			onCompleted: (data) => {
				setReports(data.listSafetyQuestionnaires.items);
				// setCurrentTab(tabs[0].name);
			},

			fetchPolicy: "cache-and-network",
		}
	);

	React.useState(() => {
		listSafetyQuestionnaires({
			variables: {
				filter: cards[0].filter,
			},
		});
	}, []);

	const updateFilter = (filter) => {
		listSafetyQuestionnaires({
			variables: {
				filter: filter,
			},
		});
	};

	// list users and filter out isSafetyUser
	useQuery(gql(userGQL.listUsers), {
		variables: {
			filter: {
				isSafetyUser: true,
			},
		},
		onCompleted: (data) => {
			setSafetyUsers(data.listUsers.items);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
	});

	//update safetyQuestionnaire

	const [updateSafetyQuestionnaire] = useMutation(
		gql(safetyGQL.updateSafetyQuestionnaire),

		{
			onCompleted: () => {
				setIsEditOpen(false);
				refetch();
			},
		}
	);
	const sortableReports = [...reports];

	const navigateToQuestionnaire = (id) => {
		navigation.navigate("Single Report", {
			id: id,
		});
	};

	if (loading || !reports) {
		return <LoadingScreen loadItem={"Safety Questionnaire"} />;
	}

	return (
		<View>
			<Breadcrumb baseLocation="Dashboard">
				<Crumb name="Reports" location="Safety Reports" />
			</Breadcrumb>
			<PageHeader title="Reports" openMenu />

			<View className="mt-8">
				<View className="max-w-6xl px-4 sm:px-6">
					<Text className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
						Overview
					</Text>
					<View className="block flex-row flex-wrap w-full">
						{cards.map((card, index) => (
							<DataCard
								key={index}
								name={card.name}
								filter={card.filter}
								onPress={() => {
									updateFilter(card.filter), setCurrentSelected(() => index);
								}}
								icon={card.icon}
								currentSelected={currentSelected === index}
							/>
						))}
					</View>
				</View>
			</View>
			{sortableReports && sortableReports.length > 0 ? (
				<>
					<List
						usesOptions
						title="Safety Questionnaires"
						headers={[
							"Date Created",
							"Worker",
							"Reported to PM",
							"Required EMS",
							"Wearing PPE",
							"Status",
							"Assigned to",
						]}
					>
						<>
							{sortableReports &&
								sortableReports
									.sort((a, b) => {
										if (a.dateCreated > b.dateCreated) return -1;
										if (a.dateCreated < b.dateCreated) return 1;
										return 0;
									})
									.map((entry, index) => (
										<ListItem
											key={index}
											smallView={
												<View className="w-full rounded-lg shadow-sm border-2 border-gray-200 dark:border-indigo-200 p-2">
													<TouchableOpacity
														onPress={() => {
															navigateToQuestionnaire(entry.id);
														}}
														className=" font-bold text-md dark:text-white "
													>
														<Text className=" font-bold text-md dark:text-white ">
															Date: {entry.dateCreated}
														</Text>
													</TouchableOpacity>
													<Text className=" font-bold text-md dark:text-white ">
														Worker:{" "}
														{entry.timeEntry
															? entry.timeEntry.user.firstName +
															  " " +
															  entry.timeEntry.user.lastName
															: "No Worker Attached"}
													</Text>
													<Text className=" font-bold text-md dark:text-white ">
														Reported to PM:{" "}
														{entry.reportedToPM !== null
															? entry.reportedToPM === true
																? "Yes"
																: "No"
															: "Not Answered"}
													</Text>
													<Text className=" font-bold text-md dark:text-white ">
														Required EMS:{" "}
														{entry.requiredCare !== null
															? entry.requiredCare === true
																? "Yes"
																: "No"
															: "Not Answered"}
													</Text>
													<Text className=" font-bold text-md dark:text-white ">
														Wearing PPE:{" "}
														{entry.wearingPPE !== null
															? entry.wearingPPE === true
																? "Yes"
																: "No"
															: "Not Answered"}
													</Text>
													<TouchableOpacity
														onPress={() => {
															setIsEditOpen(true);
															setSafetyQuestionnaire(entry);
														}}
														className=" font-bold text-md dark:text-white "
													>
														<Text className=" font-bold text-md dark:text-white ">
															Status:{" "}
															{entry.status ? entry.status : "Not Started"}
														</Text>
													</TouchableOpacity>
													<TouchableOpacity
														onPress={() => {
															setIsEditOpen(true);
															setSafetyQuestionnaire(entry);
														}}
														className=" font-bold text-md dark:text-white "
													>
														<Text className=" font-bold text-md dark:text-white ">
															Assigned to:{" "}
															{entry.reviewer
																? entry.reviewer.firstName +
																  " " +
																  entry.reviewer.lastName
																: "Not Assigned"}
														</Text>
													</TouchableOpacity>
												</View>
											}
											items={[
												{
													content: entry.dateCreated,
													onPress: () => navigateToQuestionnaire(entry.id),
												},

												{
													content: entry.timeEntry
														? entry.timeEntry.user.firstName +
														  " " +
														  entry.timeEntry.user.lastName
														: "No Worker Assigned",
													onPress: () => navigateToQuestionnaire(entry.id),
												},

												{
													content:
														entry.reportedToPM === true
															? "Yes"
															: entry.reportedToPM === false
															? "No"
															: "Not Answered",

													onPress: () => navigateToQuestionnaire(entry.id),
												},
												{
													content:
														entry.requiredCare === true
															? "Yes"
															: entry.requiredCare === false
															? "No"
															: "Not Answered",
													onPress: () => navigateToQuestionnaire(entry.id),
												},
												{
													content:
														entry.wearingPPE === true
															? "Yes"
															: entry.wearingPPE === false
															? "No"
															: "Not Answered",
													onPress: () => navigateToQuestionnaire(entry.id),
												},
												{
													content: entry.status ? entry.status : "Not Started",
													onPress: () => {
														setIsEditOpen(true);
														setSafetyQuestionnaire(entry);
													},
												},
												{
													content: entry.reviewer
														? entry.reviewer.firstName +
														  " " +
														  entry.reviewer.lastName
														: "Not Assigned",
													onPress: () => {
														setIsEditOpen(true);
														setSafetyQuestionnaire(entry);
													},
												},
											]}
											options={
												<OptionsMenu
													options={[
														{
															name: "View",
															onPress: () => {
																navigateToQuestionnaire(entry.id);
															},
														},
													]}
												/>
											}
										/>
									))}
						</>
					</List>
					<Pagination
						start={start}
						stop={end}
						total={reports.length}
						nextClicked={() => setCurrentPage(currentPage + 1)}
						previousClicked={() => setCurrentPage(currentPage - 1)}
					/>
				</>
			) : (
				<NoItems
					title={cards[currentSelected].name}
					surTitle="No Reports"
					text="There are no reports to display"
				/>
			)}

			<SlideOver
				isOpen={isEditOpen}
				name="Update Report Status"
				description="Update the status of the report"
				closeRequest={() => {
					setIsEditOpen(false);
				}}
				onSubmit={() => {
					updateSafetyQuestionnaire({
						variables: {
							input: {
								id: safetyQuestionnaire.id,
								status: safetyQuestionnaire.status,
								reviewer: safetyQuestionnaire.reviewer.id,
							},
						},
					});
				}}
			>
				<Select
					name={"Case Status"}
					defaultValue={safetyQuestionnaire.status}
					onChange={(value) => {
						setSafetyQuestionnaire({
							...safetyQuestionnaire,
							status: value.value,
						});
					}}
					options={[
						{
							name: "Under Investigation",
							value: "Under Investigation",
						},
						{
							name: "Completed",
							value: "Completed",
						},
					]}
				/>
				<Select
					name={"Assigned to"}
					defaultValue={
						safetyQuestionnaire.reviewer
							? `${safetyQuestionnaire.reviewer.firstName} ${safetyQuestionnaire.reviewer.lastName}`
							: null
					}
					onChange={(value) => {
						setUser({ ...user, reviewer: value.value });
						setSafetyQuestionnaire({
							...safetyQuestionnaire,
							reviewer: value.value,
						});
					}}
					options={safetyUsers.map((safetyUser) => {
						return {
							name: `${safetyUser.firstName} ${safetyUser.lastName}`,
							value: safetyUser,
						};
					})}
				/>
			</SlideOver>
		</View>
	);
};

SafetyReports.propTypes = {
	search: PropTypes.string,
	currentSafetyQuestionnaire: PropTypes.object,
};
const tabs = [
	{
		name: "Details",
		icon: faInfoCircle,
		href: "#",
	},
	{
		name: "Notes & Follow-Ups",
		icon: faNote,
		href: "#",
	},
];

export const SingleSafetyReport = ({ route }) => {
	const { id } = route.params;
	const navigation = useNavigation();
	const [currentTab, setCurrentTab] = React.useState(tabs[0].name);
	const [newItem, setNewItem] = React.useState(false);
	const [safetyQuestionnaire, setSafetyQuestionnaire] = React.useState(null);
	const [isEditOpen, setIsEditOpen] = React.useState(false);
	// const [generating, setGenerating] = React.useState(false);

	const printSafety = () => {
		// setGenerating(true);
		fetch(`${API_URL}/reports/safety/safetyQuestionnaire`, {
			method: "POST",
			body: JSON.stringify(safetyQuestionnaire),
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.url) {
					let link = document.createElement("a");
					link.setAttribute("target", "_blank");
					link.href = data.url;
					link.click();
				} else {
					Alert("Error Generating Safety Report");
				}
				// setGenerating(false);
			})
			.catch(() => {
				// setGenerating(false);
				Alert("Error connecting to server");
			});
	};

	const { loading, refetch } = useQuery(gql(safetyGQL.getSafetyQuestionnaire), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setSafetyQuestionnaire(data.getSafetyQuestionnaire);
			setCurrentTab(currentTab || tabs[0].name);
		},
		onError: (error) => {
			SentryLogger(error);
		},
		fetchPolicy: "cache-and-network",
	});

	if (loading || !safetyQuestionnaire) {
		return <LoadingScreen loadItem={"Single Report"} />;
	}

	//create a button for the Notes and Follow Ups tab that will navigate to the Notes and Follow Ups page
	const getButtons = () => {
		switch (currentTab) {
			case "Notes & Follow-Ups":
				return [
					{
						name: "Add Note",
						icon: faPlus,
						onPress: () => {
							setNewItem("Add Note");
						},
					},
				];
			case "Details":
				return [
					{
						icon: faFileInvoice,
						name: "Generate Safety Report",
						onPress: () => {
							printSafety();
						},
					},
					{
						name: "Update Report Status",
						icon: faPencil,
						onPress: () => {
							setIsEditOpen(true);
						},
					},
				];
			default:
				return [];
		}
	};

	return (
		<View>
			<Breadcrumb baseLocation="Dashboard">
				<Crumb name="Reports" location="Safety Reports" />
				<Crumb
					name="Single Safety Reports"
					location="Single Report"
					params={{ id: safetyQuestionnaire.id }}
				/>
			</Breadcrumb>
			<PageHeader
				title="Report"
				buttons={getButtons()}
				goBack={{
					location: "Safety Reports",
				}}
			/>
			<View className="container m-2">
				<Tabs>
					{tabs.map((tab, index) => {
						return (
							<Tab
								key={index}
								name={tab.name}
								current={currentTab === tab.name ? true : false}
								icon={tab.icon}
								onPress={() => {
									setCurrentTab(tab.name);
								}}
							/>
						);
					})}
				</Tabs>
				{currentTab === "Details" && (
					<ScrollView>
						<View className="flex flex-row justify-between">
							<View className="flex flex-col">
								<DataContainer>
									<DataItem
										name={"Date Created"}
										value={
											safetyQuestionnaire?.dateCreated === null
												? "No Date Created"
												: safetyQuestionnaire?.dateCreated
										}
									/>
									<DataItem
										name={"Worker"}
										value={
											safetyQuestionnaire?.timeEntry?.user.firstName +
											" " +
											safetyQuestionnaire?.timeEntry?.user.lastName
										}
									/>
									<DataItem
										name={"Status"}
										value={
											safetyQuestionnaire?.status === null
												? "Not Started"
												: safetyQuestionnaire?.status
										}
									/>
									<DataItem
										name={"Assigned To"}
										value={
											safetyQuestionnaire?.reviewer === null
												? "Not Assigned"
												: `${safetyQuestionnaire?.reviewer.firstName} ${safetyQuestionnaire?.reviewer.lastName}`
										}
									/>
									<DataItem
										name={"Reported To PM"}
										value={
											safetyQuestionnaire?.reportedToPM === false ? "No" : "Yes"
										}
									/>
									<DataItem
										name={"Required Care"}
										value={
											safetyQuestionnaire?.requiredCare === false ? "No" : "Yes"
										}
									/>
									<DataItem
										name={"Injuries"}
										value={`${safetyQuestionnaire?.injuries} ${
											safetyQuestionnaire && safetyQuestionnaire.extendedInjury
												? `, ${safetyQuestionnaire.extendedInjury}`
												: ""
										}`}
									/>
									<DataItem
										name={"Occurred On Site"}
										value={
											safetyQuestionnaire?.occurredOnSite === false
												? "No"
												: "Yes"
										}
									/>
									<DataItem
										name={"How injury occurred"}
										value={safetyQuestionnaire?.howInjuryOccurred}
									/>
									<DataItem
										name={"Wearing PPE"}
										value={
											safetyQuestionnaire?.wearingPPE === true ? "Yes" : "No"
										}
									/>
									<DataItem
										name={"Provided PPE"}
										value={
											safetyQuestionnaire?.providedPPE === true ? "Yes" : "No"
										}
									/>
									<View>
										{safetyQuestionnaire.media ? (
											<ScrollView>
												{safetyQuestionnaire.media.map((media, index) => {
													return (
														<Image
															key={index}
															style={{ width: 350, height: 350 }}
															source={{
																uri: getSignedUrl(media.key),
															}}
														/>
													);
												})}
											</ScrollView>
										) : null}
									</View>
								</DataContainer>
							</View>
						</View>
						<SafetyQuestionnaireSlideOver
							isOpen={isEditOpen}
							type={"update"}
							currentSafetyQuestionnaire={safetyQuestionnaire}
							closeRequest={() => {
								setIsEditOpen(false);
							}}
							onComplete={() => {
								setIsEditOpen(false);
								refetch();
							}}
						/>
					</ScrollView>
				)}
				{currentTab === "Notes & Follow-Ups" && (
					<>
						<View className="">
							{safetyQuestionnaire.notes ? (
								<ScrollView>
									{safetyQuestionnaire.notes.map((note, index) => {
										return (
											<Card
												key={index}
												title={note.title}
												content={note.description}
												onPress={() => {
													navigation.navigate("Single Safety Note", {
														id: note.id,
													});
												}}
											/>
										);
									})}
								</ScrollView>
							) : (
								<NoItems
									surTitle={"No Notes"}
									title="Currently No Notes"
									text="Create a note to add to this report"
								/>
							)}
						</View>
					</>
				)}

				<NoteSlideOver
					isOpen={newItem === "Add Note"}
					closeRequest={() => {
						setNewItem("");
					}}
					onComplete={() => {
						setNewItem(null);
						refetch();
					}}
					safetyID={safetyQuestionnaire.id}
					safetyQuestionnaire={safetyQuestionnaire}
				/>
			</View>
		</View>
	);
};

SingleSafetyReport.propTypes = {
	route: PropTypes.object,
	isOpen: PropTypes.bool.isRequired,
	safetyQuestionnaire: PropTypes.object,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
};
