import { useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import React from "react";
import { View } from "react-native";

import { DateTime } from "luxon";

// components

import { Alert } from "../../../components/Alert";
import Breadcrumbs, { Crumb } from "../../../components/Breadcrumb";
import DailySheetView from "../../../components/DailySheetView";
import { Checkbox } from "../../../components/Forms";
import { PageHeader } from "../../../components/Header";
import { List, ListItem } from "../../../components/List";
import LoadingScreen from "../../../components/LoadingScreen";
import Modal from "../../../components/Modal";
import OptionsMenu from "../../../components/OptionsMenu";
import {
	calculateTimeDuration,
	getData,
	sendUpdateEmail,
} from "../../../utils";

// Container

import { TimeEntrySlideover } from "../../../containers/TimeEntrySlideOver";

//icons

import { faEye, faPen, faTrash } from "@fortawesome/pro-duotone-svg-icons";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as timeEntryGQL from "../../../graphql/timeEntry";

//Utils
import { SentryLogger } from "../../../utils";

export function UserTime({ route }) {
	const navigation = useNavigation();
	const [user, setUser] = React.useState({});
	const { id } = route.params;
	const [timeUser, setTimeUser] = React.useState({});
	const [newItem, setNewItem] = React.useState("");
	const [updatedTimeEntry, setUpdatedTimeEntry] = React.useState({});
	const [viewTimeEntry, setViewTimeEntry] = React.useState(false);
	const [timeEntries, setTimeEntries] = React.useState([]);
	const [deleteTimeEntryData, setDeleteTimeEntryData] = React.useState(null);

	// check to make sure user is payroll admin, if not redirect back to last page
	React.useEffect(() => {
		const userInfo = async () => {
			await getData("@storage_Key")
				.then((user) => {
					if (!user.isTimeAdmin) {
						return navigation.goBack();
					}
					setUser(user);
				})

				.catch((err) => {
					SentryLogger(err);
				});
		};

		userInfo();
	}, []);

	const [updateTimeEntry] = useMutation(gql(timeEntryGQL.updateTimeEntry), {
		onCompleted: (data) => {
			let timeEntry = data.updateTimeEntry;

			const previousTimeIn = DateTime.fromISO(timeEntry.timeIn).toFormat(
				"h:mm a LL/dd/yyyy"
			);
			const previousTimeOut = DateTime.fromISO(timeEntry.timeOut).toFormat(
				"h:mm a LL/dd/yyyy"
			);
			setNewItem(null);
			setUpdatedTimeEntry({});
			userRefetch();

			// Conditional check to see if the time entry is approved or not and if the email should be sent

			if (!timeEntry.approved) {
				const updatedTimeIn = "No Change";
				const updatedTimeOut = "No Change";

				sendUpdateEmail(
					timeEntry.project.title,
					timeEntry.user,
					"Un-approved by authorized Time Overrider",
					user,
					previousTimeIn,
					previousTimeOut,
					updatedTimeIn,
					updatedTimeOut
				);
			}
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("Couldn't update time entry please try again");
		},
	});

	//list time entries

	const { loading, refetch: userRefetch } = useQuery(
		gql(timeEntryGQL.getUserProjectsAndTime),
		{
			variables: {
				id: id,
			},
			onCompleted: (data) => {
				setTimeUser(data.getUser);
				setTimeEntries([...data.getUser.timeEntries]);
			},
			onError: (error) => {
				Alert("Couldn't load time entries please try again");
				SentryLogger(JSON.stringify(error, null, 2));
			},
			fetchPolicy: "cache-and-network",
		}
	);

	/**
	 * getSortedProjects
	 * sorts the projects by title, removes completed projects, and add SRP Environmental to the top of the list
	 * @param {array} projects - the projects to sort
	 * @returns {array} - the sorted projects
	 */

	const getSortedProjects = (projects) => {
		let sortedProjects = [];
		if (projects) {
			sortedProjects = projects
				.filter((pc) => pc.project !== null)
				.filter((pc) => pc.project.stage !== "Complete")
				.sort((a, b) => {
					if (a.project.title > b.project.title) return 1;
					if (a.project.title < b.project.title) return -1;
					return 0;
				});
		}

		sortedProjects.unshift({
			project: {
				id: "0",
				title: "SRP Environmental",
			},
		});

		return sortedProjects.map((pc) => {
			if (pc.project) {
				return {
					title: pc.project.title,
					id: pc.project.id,
					disabled: false,
				};
			}
		});
	};

	const [deleteTimeEntry] = useMutation(gql(timeEntryGQL.deleteTimeEntry), {
		onCompleted: (data) => {
			setDeleteTimeEntryData(null);
			userRefetch();
			if (data.deleteTimeEntry.approved) {
				const previousTimeIn = DateTime.fromISO(
					data.deleteTimeEntry.timeIn
				).toFormat("h:mm a LL/dd/yyyy");
				const previousTimeOut = DateTime.fromISO(
					data.deleteTimeEntry.timeOut
				).toFormat("h:mm a LL/dd/yyyy");
				const updatedTimeIn = "Deleted";
				const updatedTimeOut = "Deleted";
				sendUpdateEmail(
					data.deleteTimeEntry.project.title,
					data.deleteTimeEntry.user,
					"Deleted by authorized Time Overrider",
					user,
					previousTimeIn,
					previousTimeOut,
					updatedTimeIn,
					updatedTimeOut
				);
			}
		},
		onError: (error) => {
			Alert("Error deleting time entry");
			SentryLogger(JSON.stringify(error, null, 2));
		},
	});

	if (loading || !timeUser) {
		return <LoadingScreen loadItem={"User"} />;
	}

	return (
		<>
			<Breadcrumbs>
				<Crumb name="Time Clock" location={"Time Clock"} />
				<Crumb name="Time Analysis Center" location={"Time Admin Center"} />
				<Crumb
					name={timeUser.firstName + " " + timeUser.lastName}
					location={"User Time"}
					params={{ id: timeUser.id }}
				/>
			</Breadcrumbs>
			<PageHeader
				title={
					timeUser.isContractor
						? "Contractor Time Entries"
						: "Employee Time Entries"
				}
				buttons={
					user.isPayrollAdmin
						? [
								{
									name: "Add Time Entry",
									// icon: "plus",
									onPress: () => setNewItem("newTimeEntry"),
								},
						  ]
						: null
				}
			/>
			<View className="w-full">
				<List
					headers={[
						"Time In",
						"Time Out",
						"Duration",
						"Project",
						"Updated By",
						"Description",
						"Approved",
					]}
					usesOptions
				>
					{timeEntries
						.sort((a, b) => {
							if (!a.timeIn) return 1;
							if (!b.timeIn) return -1;
							if (a.timeIn > b.timeIn) return -1;
							if (a.timeIn < b.timeIn) return 1;
							return 0;
						})
						.map((timeEntry, index) => {
							return (
								<ListItem
									key={index}
									options={
										<OptionsMenu
											options={[
												{
													name: "View",
													icon: faEye,
													onPress: () => {
														navigation.navigate("Time Clock Single Entry", {
															id: timeEntry.id,
														});
													},
												},

												!timeEntry.approved || user.isTimeOverrider
													? {
															name: "Edit Time Entry",
															icon: faPen,
															onPress: () => {
																setNewItem("edit");
																setUpdatedTimeEntry({
																	id: timeEntry.id,
																	approved: timeEntry.approved,
																	project: timeEntry.project,
																	timeIn: timeEntry.timeIn,
																	timeOut: timeEntry.timeOut,
																	isMobilizing: timeEntry.isMobilizing,
																	isDemobilizing: timeEntry.isDemobilizing,
																	isStandby: timeEntry.isStandby,
																});
															},
													  }
													: null,

												!timeEntry.approved || user.isTimeOverrider
													? {
															name: "Delete Time Entry",
															icon: faTrash,
															onPress: () => {
																setDeleteTimeEntryData(timeEntry);
															},
													  }
													: null,
											]}
										/>
									}
									items={[
										{
											content: DateTime.fromISO(timeEntry.timeIn).toFormat(
												"EEEE, MMMM dd, yyyy h:mm a ZZZZ"
											),
										},

										{
											content: DateTime.fromISO(timeEntry.timeOut).toFormat(
												"EEEE, MMMM dd, yyyy h:mm a ZZZZ"
											),
										},
										{
											content: timeEntry.timeOut
												? calculateTimeDuration(
														timeEntry.timeIn,
														timeEntry.timeOut
												  )
												: "Clocked In",
										},
										{
											content: timeEntry.project ? timeEntry.project.title : "",
										},
										{
											content: timeEntry.updatedBy
												? `${timeEntry.updatedBy.firstName} ${timeEntry.updatedBy.lastName}`
												: "",
										},
										{
											content: timeEntry.dailyDocument
												? timeEntry.dailyDocument.activities
													? timeEntry.dailyDocument.activities
													: "No Description"
												: "No Daily Sheet",
										},
										{
											content: (
												<Checkbox
													checkedValue={
														timeEntry && timeEntry.approved ? true : false
													}
													disabled={timeEntry.approved && !user.isTimeOverrider}
													onChange={() => {
														updateTimeEntry({
															variables: {
																input: {
																	id: timeEntry && timeEntry.id,
																	approved: !timeEntry.approved ? true : false,

																	approvedBy: user.id,

																	approvedAt: DateTime.now().toISO(),
																},
															},
														});
													}}
												/>
											),
										},
									]}
								/>
							);
						})}
				</List>
			</View>

			<Modal
				title="Delete Time Entry"
				description="Are you sure you want to delete this time entry? This is permanent and cannot be undone and will also delete any Daily Sheet associated with this time entry."
				isOpen={!!deleteTimeEntryData}
				onClose={() => setNewItem(true)}
				rightButton={{
					text: "Delete",
					onPress: () => {
						deleteTimeEntry({
							variables: {
								id: deleteTimeEntryData.id,
							},
						});
					},
				}}
			/>

			<Modal
				isOpen={viewTimeEntry !== false}
				onCloseRequest={() => {
					setViewTimeEntry(false);
				}}
				title="Time Entry and Daily Sheet"
			>
				<DailySheetView timeEntry={viewTimeEntry} />
			</Modal>

			<TimeEntrySlideover
				isOpen={newItem === "newTimeEntry"}
				closeRequest={() => setNewItem(true)}
				onComplete={() => {
					userRefetch({ variables: { id: id } });
					setNewItem(null);
				}}
				currentTimeEntry={{
					user: id,
				}}
				type="create"
				projects={getSortedProjects(timeUser.projects)}
			/>
			<TimeEntrySlideover
				isOpen={newItem === "edit"}
				closeRequest={() => setNewItem(true)}
				onComplete={() => {
					userRefetch({ variables: { id: id } });
					setNewItem(null);
				}}
				type="update"
				projects={getSortedProjects(timeUser.projects)}
				currentTimeEntry={{
					id: updatedTimeEntry.id,
					project: updatedTimeEntry.project,
					approved: updatedTimeEntry.approved,
					timeOut: updatedTimeEntry.timeOut,
					timeIn: updatedTimeEntry.timeIn,
					isMobilizing: updatedTimeEntry.isMobilizing,
					isDemobilizing: updatedTimeEntry.isDemobilizing,
					isStandby: updatedTimeEntry.isStandby,
				}}
			/>
		</>
	);
}

UserTime.propTypes = {
	route: PropTypes.object,
	history: PropTypes.shape({
		goBack: PropTypes.func.isRequired,
		push: PropTypes.func.isRequired,
	}),
};
