import { DateTime } from "luxon";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { View, Text } from "react-native";

// Components
import { Alert } from "../components/Alert";
import Button from "../components/Button";
import { Input, Select } from "../components/Forms";
import SlideOver from "../components/Slideover";

// Utils
import { API_URL, getData, SentryLogger } from "../utils";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as ptoGQL from "../graphql/pto";
import { listUsers } from "../graphql/user";

const PTORequestSlideOver = ({
	isOpen,
	closeRequest = () => {},
	type,
	onComplete,
	currentPTO,
	user,
	creatingViaManager = false,
}) => {
	const localUser = getData("@storage_Key").then((data) => data);
	const [PTO, setPTO] = useState({});
	const [days, setDays] = useState([
		{
			hours: 0,
			startDate: DateTime.now().toISO(),
			reason: null,
		},
	]);

	const [isBereavement, setIsBereavement] = React.useState(false);
	const [notifyHR, setNotifyHR] = React.useState(false);
	const [users, setUsers] = React.useState([]);
	const [reason, setReason] = React.useState("");
	const [bereavementPerson, setBereavementPerson] = React.useState("");

	const hrUserList = users
		.filter((user) => user.isHR === true)
		.map((user) => {
			return {
				user: user.firstName,
				value: user.id,
			};
		});

	React.useEffect(() => {
		if (currentPTO) {
			setPTO(currentPTO);
		}
	}, [currentPTO]);

	//get user

	useQuery(gql(listUsers), {
		onCompleted: (data) => {
			setUsers([...data.listUsers.items]);
		},
		onError: (err) => {
			SentryLogger(JSON.stringify(err, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	const BULK_CREATE_PTO = gql`
		mutation bulkCreatePTO($input: [CreatePTOInput!]!) {
			bulkCreatePTO(input: $input) {
				id
			}
		}
	`;

	const [bulkCreatePTO] = useMutation(BULK_CREATE_PTO, {
		onCompleted: (data) => {
			onComplete(data.createPTO);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert(
				"Something Went Wrong",
				"There was an error creating the PTO Request"
			);
		},
	});
	const [updatePTORequest] = useMutation(gql(ptoGQL.updatePTO), {
		onCompleted: (data) => {
			onComplete(data.updatePTO);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert(
				"Something Went Wrong",
				"There was an error updating the PTO Request"
			);
		},
	});

	/**
	 * handleDayChange
	 * @param index {number} - the index of the day
	 * @param keyBeingChanged {string} - the key that is going to be changed
	 * @param value {string} - the value that will be set to the key
	 */
	const handleDayChange = (index, keyBeingChanged, value) => {
		let newDays = days;

		if (bereavementPerson === "Coworker") {
			if (newDays[index].hours > 4) {
				newDays[index].hours = null;
				return Alert(
					"Cannot request more than 4 hours days for coworker bereavement"
				);
			}
		}
		if (newDays[index].hours > 8) {
			newDays[index].hours = null;
			return Alert("Cannot request more than 8 hours");
		}
		// const weekday = DateTime.fromISO(newDays[index].startDate).weekday;
		// if (weekday === 6 || weekday === 7) {
		// 	newDays[index][keyBeingChanged] = null;
		// 	return Alert("Cannot request PTO on weekends");
		// }

		if (reason === "Military" || reason === "Other") {
			setNotifyHR(true);
		}
		newDays[index][keyBeingChanged] = value;
		setDays(() => newDays);
	};

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "Create PTO Request" : "Edit PTO Request"}
			headerView={
				<>
					{user ? (
						<View className="mt-2 flex flex-row w-max justify-between items-center">
							<Text className="text-white">
								Available PTO:
								<Text className="font-bold">
									{" "}
									{user.availablePTO ? user.availablePTO : 0} Hours
								</Text>
							</Text>

							<Text className="text-white ">
								Amount That Can Be Advanced:
								<Text className="font-bold">
									{" "}
									{user.advancableAmount ? user.advancableAmount : 0} Hours
								</Text>
							</Text>
						</View>
					) : null}
				</>
			}
			closeRequest={() => {
				closeRequest();
			}}
			onSubmit={async () => {
				if (type === "create") {
					// check that each day in days has the following

					if (reason === null) {
						Alert("Missing Reason", "Please enter a reason");
						return;
					}
					if (
						days.find((day) => !day.hours || day.hours === 0 || day.hours > 8)
					) {
						Alert(
							"Missing Hours or above 8 Hours",
							"Please enter the correct amount of hours"
						);
						return;
					}
					if (days.find((day) => day.startDate === null)) {
						Alert("Missing Date", "Please select a date");
						return;
					}

					// create PTO
					if (creatingViaManager) {
						await bulkCreatePTO({
							variables: {
								input: days.map((day) => {
									return {
										user: user && user.id,
										// need to be the user storage in local storage
										approvedBy: localUser.id,
										approvedAt: DateTime.now().toISO(),
										approved: true,
										bereavementPerson: bereavementPerson || null,
										startDate: day.startDate,
										...day,
									};
								}),
							},
						});
					} else {
						await bulkCreatePTO({
							variables: {
								input: days.map((day) => {
									return {
										...day,
										startDate: day.startDate,
										bereavementPerson: day.bereavementPerson || null,
										user: user && user.id,
									};
								}),
							},
						});
						await fetch(`${API_URL}/notifications/notification`, {
							method: "POST",
							mode: "cors",
							body: JSON.stringify({
								notification: {
									title: "🏝️ New Time Off Request",
									message: `${user.firstName} ${user.lastName} has requested ${
										days.length
									} ${pluralize("day", days.length)} of PTO`,
									createdBy: user.id,
									user: user && user.manager && user.manager.id,
								},
								type: "PTO",
							}),
						})
							.then((res) => res.json())
							.catch((err) => {
								SentryLogger(err);
							});
					}

					//Notify HR if reason is Other or Military
					if (notifyHR === true) {
						let i = 0;

						while (i < hrUserList.length) {
							// send notification
							fetch(`${API_URL}/notifications/notification`, {
								method: "POST",
								mode: "cors",
								body: JSON.stringify({
									notification: {
										title: "Special PTO Requested",
										message: `${user.firstName} ${
											user.lastName
										} has requested ${days.length} ${pluralize(
											"day",
											days.length
										)} of special cause PTO`,
										createdBy: user.id,
										user: hrUserList[i].value,
									},
									type: "PTO",
								}),
							})
								.then((res) => res.json())
								.catch((err) => {
									SentryLogger("Notification");
									SentryLogger(err);
								});
							i++;
						}
					}
					onComplete();
				} else {
					await updatePTORequest({
						variables: {
							input: PTO,
						},
					});
				}
			}}
		>
			<>
				{user && user.isSalary ? (
					<Select
						name="Reason"
						onChange={(value) => {
							setReason(value.name);
							handleDayChange(0, "reason", value.name);
						}}
						options={[
							{ name: "Vacation" },
							{ name: "Sick" },
							{ name: "Bereavement" },
							{ name: "Military" },
							{ name: "Parental" },
							{ name: "Jury Duty" },
							{ name: "Other" },
						]}
					/>
				) : (
					<Select
						name="Reason"
						onChange={(value) => {
							setReason(value.name);
							handleDayChange(0, "reason", value.name);
							if (value.name === "Bereavement") {
								setIsBereavement(true);
							} else if (value.name !== "Bereavement") {
								setIsBereavement(false);
							}
						}}
						options={[
							{ name: "Vacation" },
							{ name: "Sick" },
							{ name: "Bereavement" },
							{ name: "Military" },
							{ name: "Parental" },
							{ name: "Jury Duty" },
							{ name: "Other" },
						]}
					/>
				)}
				{isBereavement && (
					<>
						<Select
							name="Bereavement Person"
							onChange={(value) => {
								setBereavementPerson(value.name);
								handleDayChange(0, "bereavementPerson", value.name);
							}}
							options={[
								{ name: "Spouse" },
								{ name: "Domestic/Civil union Partner" },
								{ name: "Child" },
								{ name: "Parent (include in-laws)" },
								{ name: "Sibling (include in-laws)" },
								{ name: "Grandparent" },
								{ name: "Aunt" },
								{ name: "Uncle" },
								{ name: "Coworker" },
							]}
						/>
					</>
				)}

				{days.map((day, index) => {
					return (
						<View
							key={index}
							className="m-1 p-1 border-b-2 border-indigo-500 flex items-center w-full"
						>
							<Input
								label="Date"
								onChange={(e) => {
									let d = new Date(DateTime.now());
									d.setDate(d.getDate() - 1);
									if (e < d) {
										Alert("Date is not eligible", "Please enter a future date");
									} else {
										handleDayChange(index, "startDate", e);
									}
								}}
								type="date"
							/>
							<Input
								label={"Hours"}
								helper="No more than 8 hours - only full hour increments"
								onChange={(e) => {
									handleDayChange(index, "hours", Math.round(e).toString());
								}}
								type="num-pad"
							/>
						</View>
					);
				})}
			</>

			<Button
				text="Add Additional Days"
				onPress={() => {
					if (reason === "") {
						Alert("Need to select reason before adding days");
					} else if (bereavementPerson === "Coworker") {
						Alert("Only one day may be taken for coworker bereavement");
					} else if (reason === "Bereavement" && days.length > 2) {
						Alert("Maximum of 3 days for Bereavement may be requested");
					} else if (reason === "Bereavement" && bereavementPerson === "") {
						Alert("Bereavement person must be selected");
					} else {
						setDays((prev) => [
							...prev,
							{
								startDate: DateTime.now().toISO(),
								reason: reason,
								bereavementPerson: bereavementPerson || null,
							},
						]);
					}
				}}
			/>
		</SlideOver>
	);
};

PTORequestSlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	currentPTO: PropTypes.object,
	user: PropTypes.object.isRequired,
	creatingViaManager: PropTypes.bool,
};

export { PTORequestSlideOver };
