import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import { LogBox } from "react-native";
import { TailwindProvider } from "tailwindcss-react-native";
// import * as Notifications from "expo-notifications";
import { useNavigation } from "@react-navigation/native";
import * as Linking from "expo-linking";
import * as Location from "expo-location";
import PropTypes from "prop-types";
import Toast from "react-native-toast-message";
import useInterval from "react-useinterval";

// Navigation
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import "react-native-gesture-handler";

// Layouts
import { AdminLayout } from "./src/layouts/Admin";
import { AppLayout } from "./src/layouts/App";
import { AuthLayout } from "./src/layouts/Auth";
import { ClerkLayout } from "./src/layouts/Clerk";
import { FinanceAndHrCenterLayout } from "./src/layouts/FinanceHRCenter";
import { SafetyCenterLayout } from "./src/layouts/SafetyCenter";
import { SubcontractorAdminLayout } from "./src/layouts/SubContractorAdmin";
// Components
import { NotificationContainer } from "./src/components/Notification";

// GraphQL
import { ApolloProvider, gql, useLazyQuery, useMutation } from "@apollo/client";
import * as userGQL from "./src/graphql/user";
import { useApolloClient } from "./src/hooks";

// Intl needs
import "intl";
import "intl/locale-data/jsonp/en";

// Native Wind
import { NativeWindStyleSheet } from "nativewind";

// Sentry Crash and Analytics Reporting
import * as Sentry from "sentry-expo";

// Utils
import LoadingScreen from "./src/components/LoadingScreen";
import { getData, inProduction } from "./src/utils";

// Toast
// import Toast from "react-native-toast-message";

Sentry.init({
	dsn: "https://68ce7ef8e48441218a68e96d9e47055c@o4504447853133824.ingest.sentry.io/4504447854968832",
	enableInExpoDevelopment: false,
	debug: !inProduction, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

NativeWindStyleSheet.setOutput({
	default: "native",
});

LogBox.ignoreLogs(["Warning: React does not recognize the"]);

const TimeLocationChecker = () => {
	const [getUser] = useLazyQuery(gql(userGQL.getUser), {
		onCompleted: async (data) => {
			let currentTimeEntry = data.getUser.currentTimeEntry;
			//if the user's current time entry is there and if the project attached
			// to the time entry is a large loss project then create a new location for the user
			if (
				currentTimeEntry &&
				currentTimeEntry.project &&
				currentTimeEntry.project.isLargeLoss === true
			) {
				//create a new location for the user

				let { status } = await Location.requestForegroundPermissionsAsync();
				if (status !== "granted") {
					return;
				}

				let location = await Location.getCurrentPositionAsync({});

				if (location && location.coords) {
					createLocation({
						variables: {
							input: {
								timeEntry: currentTimeEntry.id,
								latitude: location.coords.latitude,
								longitude: location.coords.longitude,
								timestamp: new Date().toISOString(),
							},
						},
					});
				}
			} else {
			}
		},
		onError: (error) => {
			JSON.stringify(error, null, 2);
		},
		fetchPolicy: "cache-and-network",
	});

	const [createLocation] = useMutation(gql(userGQL.createLocation), {
		onCompleted: () => {},
		onError: (error) => {
			JSON.stringify(error, null, 2);
		},
	});

	//check location every 5 minutes
	useInterval(async () => {
		let user = await getData("@storage_Key").then((data) => data);
		if (!user) {
			// no user data to work with so we will return
			return;
		}

		getUser({
			variables: {
				id: user.id,
			},
		});
	}, 300000);

	return null;
};

const AuthLayouts = () => {
	const navigation = useNavigation();
	const { client } = useApolloClient({
		removeUser: () => {
			navigation.navigate("Auth");
		},
	});

	if (!client) return <LoadingScreen loadItem={"App"} />;
	return (
		<ApolloProvider client={client}>
			<AuthLayout />
		</ApolloProvider>
	);
};

const AppLayouts = () => {
	const navigation = useNavigation();
	const Stack = createNativeStackNavigator();

	const { client } = useApolloClient({
		removeUser: () => {
			navigation.navigate("Auth");
		},
	});

	if (!client) return <LoadingScreen loadItem={"App"} />;
	return (
		<ApolloProvider client={client}>
			<Stack.Navigator screenOptions={{ gestureEnabled: false }}>
				<Stack.Screen
					name="App"
					component={AppLayout}
					options={{ headerShown: false }}
				/>
				<Stack.Screen
					name="Admin"
					component={AdminLayout}
					options={{ headerShown: false }}
				/>
				<Stack.Screen
					name="Clerk"
					component={ClerkLayout}
					options={{ headerShown: false }}
				/>
				<Stack.Screen
					name="Finance and HR Center"
					component={FinanceAndHrCenterLayout}
					options={{ headerShown: false }}
				/>
				<Stack.Screen
					name="SafetyCenter"
					component={SafetyCenterLayout}
					options={{ headerShown: false }}
				/>
				<Stack.Screen
					name="SubcontractorAdmin"
					component={SubcontractorAdminLayout}
					options={{ headerShown: false }}
				/>
				<Stack.Screen
					name="Auth"
					component={AuthLayout}
					options={{ headerShown: false }}
				/>
			</Stack.Navigator>
			<TimeLocationChecker />
		</ApolloProvider>
	);
};

const AppNavigation = (user) => {
	const Stack = createNativeStackNavigator();

	return (
		<Stack.Navigator
			screenOptions={{ gestureEnabled: false }}
			initialRouteName={user ? "App" : "Auth"}
		>
			<Stack.Screen
				name="Auth"
				component={AuthLayouts}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="App"
				component={AppLayouts}
				options={{ headerShown: false }}
			/>
		</Stack.Navigator>
	);
};

AppNavigation.propTypes = {
	user: PropTypes.object,
};

const App = () => {
	const [notification, setNotification] = useState(null);
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);

	React.useEffect(() => {
		getData("@storage_Key").then((data) => {
			setUser(data);
			setLoading(false);
		});
		setLoading(false);
	}, []);

	const linking = {
		prefixes: [
			Linking.createURL("/"),
			"https://app.srpinfield.com",
			"srpinfield://",
		],
	};

	// const notificationListener = useRef();
	// const responseListener = useRef();

	// useEffect(() => {
	// 	if (
	// 		(Platform.OS === "ios" || Platform.OS === "android") &&
	// 		!Constants.isDevice
	// 	) {
	// 		Notifications.setNotificationHandler({
	// 			handleNotification: async () => ({
	// 				shouldShowAlert: true,
	// 				shouldPlaySound: true,
	// 				shouldSetBadge: true,
	// 			}),
	// 		});

	// 		// This listener is fired whenever a notification is received while the app is foregrounded
	// 		notificationListener.current =
	// 			Notifications.addNotificationReceivedListener((notification) => {
	// 				setNotification(notification);
	// 			});
	// 		// This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
	// 		responseListener.current =
	// 			Notifications.addNotificationResponseReceivedListener((response) => {
	//
	// 			});
	// 		return () => {
	// 			Notifications.removeNotificationSubscription(
	// 				notificationListener.current
	// 			);
	// 			Notifications.removeNotificationSubscription(responseListener.current);
	// 		};
	// 	}
	// }, []);

	return loading ? (
		<LoadingScreen loadItem="App" />
	) : (
		<TailwindProvider>
			{notification && notification.request && notification.request.content && (
				<NotificationContainer
					title={notification.request.content.title || "New Notification"}
					body={notification.request.content.body || "No body"}
					data={notification.request.content.data || {}}
					dismiss={() => setNotification(null)}
				/>
			)}
			<NavigationContainer linking={linking}>
				<StatusBar style="auto" />
				<AppNavigation user={user} />
				<Toast />
			</NavigationContainer>
		</TailwindProvider>
	);
};

export default App;
