import { Alert as AlertFunction, Platform } from "react-native";

const alertPolyfill = (title, description, options) => {
	const result = window.confirm(
		[title, description].filter(Boolean).join("\n")
	);

	if (result) {
		const confirmOption = options
			? options.find(({ style }) => style !== "cancel")
			: null;
		confirmOption && confirmOption.onPress();
	} else {
		const cancelOption = options
			? options.find(({ style }) => style === "cancel")
			: null;
		cancelOption && cancelOption.onPress();
	}
};

export const Alert =
	Platform.OS === "web" ? alertPolyfill : AlertFunction.alert;
