// simple react screen
import { useNavigation } from "@react-navigation/native";
import * as Clipboard from "expo-clipboard";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Platform, ScrollView, View } from "react-native";
import Toast from "react-native-toast-message";

// GQL
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { UserSlideOver } from "../../containers/UserSliderOver";
import * as userOperations from "../../graphql/admin/users";

// components
import { Alert } from "../../components/Alert";
import Breadcrumbs, { Crumb } from "../../components/Breadcrumb";
import DataContainer, { DataItem } from "../../components/DataDisplay";
import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List";
import LoadingScreen from "../../components/LoadingScreen";
import NoItems from "../../components/NoItems";
import OptionsMenu from "../../components/OptionsMenu";
import Pagination from "../../components/Pagination";
import Tabs, { Tab } from "../../components/Tabs";
import User from "../../components/User";

// Icons
import {
	faEdit,
	faEye,
	faIdBadge,
	faPaperPlane,
	faPlus,
	faUserAlt,
	faUserCrown,
	faUserHardHat,
	faUserPlus,
	faUserSlash,
	faUserTie,
} from "@fortawesome/pro-duotone-svg-icons";

// utils
import { API_URL, SentryLogger } from "../../utils";
import { Input } from "../../components/Forms";

const tabs = [
	{ name: "SRP Users", icon: faUserCrown },
	{ name: "Subcontractors", icon: faUserHardHat },
	{ name: "Clients", icon: faUserTie },
	{ name: "Outside", icon: faUserAlt },
	{ name: "Requesting", icon: faUserPlus },
	{ name: "Deactivated", icon: faUserSlash },
];
export const Users = () => {
	const navigation = useNavigation();
	let itemsPerPage = 20;
	const [users, setUsers] = React.useState(null);
	const [filteredUsers, setFilteredUsers] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState(tabs[0].name);
	const [newItem, setNewItem] = React.useState(null);
	const [search, setSearch] = React.useState("");

	let [currentPage, setCurrentPage] = useState(1);
	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end = filteredUsers
		? currentPage * itemsPerPage > filteredUsers.length
			? filteredUsers.length
			: currentPage * itemsPerPage
		: 0;

	useEffect(() => {
		getFilteredUsers();

		setCurrentPage(1);
	}, [currentTab, users]);

	const { loading: usersLoading, refetch: usersRefetch } = useQuery(
		gql(userOperations.listUsers),
		{
			onCompleted: (data) => {
				setUsers([...data.listUsers.items]);

				setCurrentTab(currentTab || tabs[0].name);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);

	const getFilteredUsers = () => {
		switch (currentTab) {
			case "SRP Users":
				let _users =
					users &&
					users.filter(
						(user) => user.isTeamMember === true && user.isActive !== false
					);
				setFilteredUsers(_users);
				break;
			case "Subcontractors":
				let _subcontractorUsers =
					users &&
					users.filter(
						(user) => user.isContractor === true && user.isActive !== false
					);
				setFilteredUsers(_subcontractorUsers);
				break;
			case "Clients":
				let _clientUsers =
					users &&
					users.filter(
						(user) => user.client !== null && user.isActive !== false
					);
				setFilteredUsers(_clientUsers);
				break;
			case "Outside":
				let _outside =
					users &&
					users.filter(
						(user) =>
							user.client === null &&
							user.isTeamMember !== true &&
							user.isContractor !== true &&
							user.isRequesting !== true &&
							user.isActive !== false
					);
				setFilteredUsers(_outside);
				break;
			case "Deactivated":
				let _deactivatedUsers =
					users && users.filter((user) => user.isActive === false);
				setFilteredUsers(_deactivatedUsers);
				break;
			case "Requesting":
				let _requestingUser =
					users &&
					users.filter(
						(user) => user.isRequesting === true && user.isActive !== false
					);
				setFilteredUsers(_requestingUser);
				break;
			default:
				let _defaultUsers =
					users &&
					users.filter(
						(user) => user.isTeamMember === true && user.isActive !== false
					);
				setFilteredUsers(_defaultUsers);
				break;
		}
	};

	const getNotItemTitle = () => {
		switch (currentTab) {
			case "SRP Users":
				return "No SRP Users";
			case "Clients":
				return "No Client Users";
			case "Outside":
				return "No Outside Users";
			case "Requesting":
				return "No Requesting Users";
			case "Deactivated":
				return "No Deactivated Users";
			default:
				return "No Users";
		}
	};

	const getHeaders = () => {
		switch (currentTab) {
			case "SRP Users":
				return ["Name", "Email", "Role"];
			case "Subcontractors":
				return ["Name", "Email", "Subcontractor"];
			case "Clients":
				return ["Name", "Email", "Role", "Client"];
			case "Outside":
				return ["Name", "Email", "Role"];
			case "Requesting":
				return ["Name", "Email", "Code"];
			case "Deactivated":
				return ["Name", "Email", "Role"];
			default:
				return [];
		}
	};

	const navigateToUser = (id) => {
		navigation.navigate("User", { id: id });
	};

	const getListItem = (user, index) => {
		switch (currentTab) {
			case "Clients":
				return (
					<ListItem
						key={index}
						items={[
							{
								content: user.firstName,
								onPress: () => navigateToUser(user.id),
							},
							{ content: user.lastName },
							{ content: user.email },
							{ content: user.role },
							{
								content:
									user.client && user.client.name
										? user.client.name
										: "No Client Name",
							},
						]}
						options={options}
					/>
				);

			case "SRP Users":
				return (
					<ListItem
						key={index}
						items={[
							{
								content: `${user.firstName} ${user.lastName}`,
								onPress: () => navigateToUser(user.id),
							},
							{ content: user.email, onPress: () => navigateToUser(user.id) },
							{ content: user.role, onPress: () => navigateToUser(user.id) },
						]}
						options={options(user)}
					/>
				);

			case "Subcontractors":
				return (
					<ListItem
						key={index}
						items={[
							{
								content: user.firstName,
								onPress: () => navigateToUser(user.id),
							},
							{ content: user.lastName },
							{ content: user.email },
							{
								content: user.subcontractor
									? user.subcontractor.name
									: "Not Assigned",
							},
						]}
						options={options(user)}
					/>
				);

			case "Requesting":
				return (
					<ListItem
						key={index}
						items={[
							{ content: user.firstName },
							{ content: user.lastName },
							{ content: user.email },
							{ content: "" },
						]}
						options={options(user)}
					/>
				);

			default:
				return (
					<ListItem
						key={index}
						items={[
							{ content: user.firstName },
							{ content: user.lastName },
							{ content: user.email },
							{ content: user.role },
						]}
						options={options(user)}
					/>
				);
		}
	};

	let options = (user) => (
		<OptionsMenu
			options={[
				{
					name: "View User",
					icon: faEye,
					onPress: () => {
						navigateToUser(user.id);
					},
				},
			]}
		/>
	);

	if (usersLoading) {
		return <LoadingScreen loadItem="Users" />;
	}

	return (
		<View>
			<PageHeader
				openMenu
				title="Users"
				buttons={[
					{
						name: "Add New User",
						icon: faPlus,
						onPress: () => {
							setNewItem("user");
						},
					},
				]}
			/>

			<Tabs
				onChange={(value) => {
					setCurrentTab(value);
				}}
			>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							icon={tab.icon}
							current={currentTab === tab.name}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			{filteredUsers && filteredUsers.length ? (
				<>
					<Input
						label="Search Users"
						onChange={(e) => {
							setSearch(e);
						}}
					/>
					<List headers={getHeaders()} title="Users" usesOptions>
						{filteredUsers
							.filter((member) =>
								member.fullName.toLowerCase().includes(search.toLowerCase())
							)
							.sort((a, b) => {
								if (a.firstName > b.firstName) return 1;
								if (a.firstName < b.firstName) return -1;
								return 0;
							})
							.map((user, index) => {
								if (index + 1 >= start && index < end) {
									return getListItem(user, index);
								}
							})}
					</List>
					<Pagination
						start={start}
						stop={end}
						total={
							users.filter((user) => {
								return (
									user.firstName.toLowerCase().includes(search.toLowerCase()) ||
									user.lastName.toLowerCase().includes(search.toLowerCase())
								);
							}).length
						}
						nextClicked={() => setCurrentPage(currentPage + 1)}
						previousClicked={() => setCurrentPage(currentPage - 1)}
					/>
				</>
			) : (
				<NoItems
					surTitle="No Users"
					title={getNotItemTitle()}
					text="There are no users to display."
				/>
			)}

			<UserSlideOver
				isOpen={newItem === "user"}
				closeRequest={() => {
					setNewItem(null);
				}}
				type="create"
				onComplete={() => {
					setNewItem(null);
					usersRefetch();
				}}
			/>
		</View>
	);
};

export const UserInfo = ({ route }) => {
	const { id } = route.params;
	const [user, setUser] = React.useState(null);
	const [newItem, setNewItem] = React.useState(null);

	React.useEffect(() => {
		if (id) {
			getUser({
				variables: {
					recallValue: DateTime.now().toISO(),
					id: id,
				},
			});
		}
	}, [id]);

	// React.useEffect(() => {
	// 	const userInfo = getData("@storage_Key")
	// 		.then((user) => {
	// 			setUserData(user);
	// 		})

	// 		.catch((err) => {
	//
	// 		});
	// 	userInfo;
	// }, []);

	const [getUser] = useLazyQuery(gql(userOperations.getUser), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setUser(data.getUser);
		},
		onError: (error) => {
			Alert("Couldn't update user please try again");
			SentryLogger(JSON.stringify(error, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	const [updateUser] = useMutation(gql(userOperations.updateUser), {
		onCompleted: (data) => {
			setUser(data.updateUser);
			setNewItem(null);
		},
		onError: (error) => {
			Alert("Couldn't update user please try again");
			SentryLogger(JSON.stringify(error, null, 2));
		},
	});

	const sendInviteEmail = async () => {
		try {
			updateUser({
				variables: {
					input: {
						id: user.id,
						linkExpiration: DateTime.now().plus({ days: 1 }).toISO(),
					},
				},
			});

			Toast.show({
				text1: "Email Expiration Link Set",
				text2: `The invite email link will expire on ${DateTime.now()
					.plus({
						days: 1,
					})
					.toLocaleString(DateTime.DATETIME_SHORT)}`,
			});

			fetch(`${API_URL}/emails/welcome`, {
				method: "POST", // *GET, POST, PUT, DELETE, etc.
				body: JSON.stringify({
					id: user.id,
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
				}),
			})
				.then((response) => response.json())
				.then(() => {
					Toast.show({
						text1: "Welcome Email Sent!",
						text2: `The invite email was sent to ${user.firstName} ${user.lastName} at ${user.email}`,
					});
				});
		} catch (error) {
			return Toast.show({
				type: "error",
				text1: "Error",
				text2:
					"Could not set expiration link. Please try sending the email again",
			});
		}
	};

	if (!user) {
		return <LoadingScreen loadItem={"User"} />;
	}

	return (
		<>
			<Breadcrumbs>
				<Crumb name="Users" location={"Users"} />
				<Crumb name={`${user.firstName} ${user.lastName}`} location="User" />
			</Breadcrumbs>
			<PageHeader
				goBack={{
					location: "Users",
				}}
				title={`${user.firstName} ${user.lastName}`}
				description={user.role ? user.role : "No Role"}
				buttons={[
					{
						name: "Update User",
						icon: faEdit,
						onPress: () => {
							setNewItem("user");
						},
					},
				]}
				options={
					<OptionsMenu
						options={[
							{
								name: "Send Invite Email",
								icon: faPaperPlane,
								onPress: () => {
									sendInviteEmail();
								},
							},
							{
								name: "Generate ID",
								icon: faIdBadge,
								onPress: () => {
									sendInviteEmail();
								},
							},
							{
								name: "Deactivate User",
								icon: faUserSlash,
								onPress: () => {
									updateUser({
										variables: {
											input: {
												id: user.id,
												isActive: false,
											},
										},
									});
								},
							},
						]}
					/>
				}
			/>

			<ScrollView showsVerticalScrollIndicator={false}>
				<DataContainer title={"Basic Information"}>
					<DataItem name="Name" value={`${user.firstName} ${user.lastName}`} />
					<DataItem name="Email" value={user.email} />
					<DataItem name="Phone" value={user.phone} />
					<DataItem name="SageIntact" value={user.sageIntacct} />
					<>
						{user.isTeamMember && (
							<>
								<DataItem name="Department Number" value={user.department} />
								<DataItem name="APS Employee ID" value={user.apsEmployeeID} />
								<DataItem
									name="Manager"
									value={
										user.manager ? <User user={user.manager} /> : "No Manager"
									}
								/>
								<DataItem
									name="Office Location"
									value={`${
										user.location ? user.location.name : "No Location"
									}`}
								/>
								<DataItem name="Bio" value={user.bio} />
							</>
						)}
					</>
					<>
						{user.client && (
							<DataItem
								name="Client"
								value={user.client.name ? user.client.name : "No Client Name"}
							/>
						)}

						{user.isContractor && (
							<DataItem
								name="Subcontractor Company"
								value={
									user.subcontractor
										? user.subcontractor.name
										: "Not Assigned To A Subcontractor"
								}
							/>
						)}
					</>
					<DataItem name="Account Setup" value={user.password ? "Yes" : "No"} />
					<DataItem name="2FA Enabled" value={user.mfaEnabled ? "Yes" : "No"} />
					<DataItem
						name="UUID"
						value={user.id}
						onPress={async () => {
							await Clipboard.setStringAsync(user.id);
							Toast.show({ text1: "Copied to clipboard" });
						}}
						info={Platform.OS === "web" ? "Click to copy" : "Tap to copy"}
					/>
				</DataContainer>
			</ScrollView>

			<UserSlideOver
				isOpen={newItem === "user"}
				closeRequest={() => {
					setNewItem(null);
				}}
				type={"update"}
				currentUser={{
					id: user.id,
					firstName: user.firstName,
					lastName: user.lastName,
					email: user.email,
					phone: user.phone,
					sageIntacct: user.sageIntacct,
					department: user.department,
					apsEmployeeID: user.apsEmployeeID,
					role: user.role,
					mfaEnabled: user.mfaEnabled,
					bio: user.bio,
					companyStartDate: user.companyStartDate,
					payrollExempt: user.payrollExempt,
					isTimeOverrider: user.isTimeOverrider,
					employmentType: user.employmentType,
					...user,
					manager: user.manager ? user.manager.id : null,
					location: user.location ? user.location.id : null,
					client: user.client ? user.client.id : null,
					subcontractor: user.subcontractor ? user.subcontractor.id : null,
				}}
				defaultValues={{
					location: user.location ? user.location.name : null,
					manager: user.manager ? user.manager.fullName : null,
				}}
				onComplete={() => {
					setNewItem(null);
					getUser({ variables: { id: user.id } });
				}}
			/>
		</>
	);
};

Users.propTypes = {
	search: PropTypes.string,
};

UserInfo.propTypes = {
	user: PropTypes.object,
	route: PropTypes.object,
};
