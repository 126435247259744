import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { View } from "react-native";
import { Checkbox } from "../components/Forms";

// Components

import { Alert } from "../components/Alert";
import { FileUpload, Input, Select } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as equipmentGQL from "../graphql/equipment.js";
import * as userGQL from "../graphql/user.js";

// Utils

import { EquipmentModals, SentryLogger, uploadFile } from "../utils";

const EquipmentSlideOver = ({
	isOpen,
	closeRequest = () => {},
	type,
	currentUser,
	currentEquipment,
	currentHistory,
	onComplete,
	nonKit = false,
}) => {
	const [users, setUsers] = React.useState([]);
	const [user, setUser] = React.useState(null);
	const [equipment, setEquipment] = React.useState({});
	const [newEquipment, setNewEquipment] = React.useState({});
	const [newHistory, setNewHistory] = React.useState({});
	const [locations, setLocations] = React.useState(null);
	const [files, setFiles] = React.useState([]);
	const [disableSubmit, setDisableSubmit] = React.useState(false);

	React.useEffect(() => {
		if (currentEquipment) {
			setNewEquipment(currentEquipment);
		}
		if (currentHistory) {
			setNewHistory(currentHistory);
		}
		if (currentUser) {
			setUser(currentUser);
		}
	}, [currentEquipment, currentHistory, currentUser]);

	const [createEquipmentHistoryOperation] = useMutation(
		gql(equipmentGQL.createEquipmentHistory),
		{
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);
	const [createNewEquipment] = useMutation(gql(equipmentGQL.createEquipment), {
		onCompleted: (data) => {
			onComplete(data.createEquipment);
			createEquipmentHistoryOperation({
				variables: {
					input: {
						equipment: data.createEquipment.id,
						office: newHistory.location,
						checkOut: DateTime.now().toISO(),
						user: newHistory.user,
					},
				},
			});
		},
	});

	const [updateEquipment] = useMutation(gql(equipmentGQL.updateEquipment), {
		onCompleted: async (data) => {
			onComplete(data.updateEquipment);
			setNewEquipment(data.updateEquipment);

			let input = {
				equipment: data.updateEquipment.id,
				office: newHistory.location,
				checkOut: DateTime.now().toISO(),
			};
			if (newHistory.user) {
				input.user = newHistory.user.id ? newHistory.user.id : newHistory.user;
			} else {
				input.checkIn = DateTime.now().toISO();
			}
			await createEquipmentHistoryOperation({
				variables: {
					input: input,
				},
			});

			if (files.length > 0) {
				files.forEach(async (file) => {
					await uploadDocument(file, data.updateEquipment.id);
				});
			}
		},
		onError: () => {
			Alert("Error updating the equipment. Please retry");
		},
	});

	useQuery(gql(equipmentGQL.listEquipment), {
		onCompleted: (data) => {
			setEquipment([...data.listEquipment.items]);
		},

		fetchPolicy: "cache-and-network",
	});

	useQuery(gql(equipmentGQL.listLocations), {
		onCompleted: (data) => {
			setLocations([...data.listLocations.items]);
		},

		fetchPolicy: "cache-and-network",
	});

	useQuery(gql(userGQL.listUsers), {
		onCompleted: (data) => {
			setUsers([...data.listUsers.items]);
		},

		fetchPolicy: "cache-and-network",
	});

	const [createEquipmentLink] = useMutation(
		gql(equipmentGQL.createEquipmentLink)
	);

	const uploadDocument = async (file, equipmentID) => {
		let key = `equipment/${equipmentID}/certifications/${file.name}`;
		await uploadFile(file.file, key).then(() => {
			createEquipmentLink({
				variables: {
					input: {
						name: file.name,
						equipment: equipmentID,
						link: key,
						date: DateTime.now().toISO(),
					},
				},
			});
		});
	};

	return (
		<SlideOver
			name={type === "create" ? "Add New Equipment" : "Update Equipment"}
			description={
				type === "create"
					? "Add new asset into the SRP inField"
					: "Update equipment information"
			}
			isOpen={isOpen}
			closeRequest={() => {
				closeRequest();
			}}
			buttonRightDisabled={disableSubmit}
			onSubmit={async () => {
				//The equipment slide over contains a disable button that will disable
				//the save button so file upload can work.

				setDisableSubmit(true);

				const showErrorMessage = (message) => {
					Alert(message);
					setDisableSubmit(false);
				};

				if (type === "create") {
					if (!newEquipment.name) {
						return showErrorMessage("Please enter equipment name");
					}
					if (!newEquipment.barcode) {
						return showErrorMessage("Please enter equipment barcode");
					}
					if (!newEquipment.sn) {
						return showErrorMessage("Please enter equipment serial number");
					}
					if (!newEquipment.model && !newEquipment.isKit) {
						return showErrorMessage("Please enter equipment model");
					}

					createNewEquipment({
						variables: { input: { ...newEquipment } },
					});
				} else {
					let input = {
						additionalComponents: newEquipment.additionalComponents,
						id: newEquipment.id,
						name: newEquipment.name,
						barcode: newEquipment.barcode,
						sn: newEquipment.sn,
						model: newEquipment.model,
						expirationDate: newEquipment.expirationDate,
						isKit: newEquipment.isKit,
						office: newHistory.location ? newHistory.location.id : null,
						user: newHistory.user ? newHistory.user.id : null,
					};
					await updateEquipment({
						variables: {
							input: input,
						},
					});
				}
			}}
		>
			{/* Only an admin can update equipment fully, others can only update calibration */}

			{!nonKit && (
				<Checkbox
					name="kit"
					checkedValue={newEquipment.isKit}
					description={"Is this a kit?"}
					onChange={(e) => setNewEquipment({ ...newEquipment, isKit: e })}
				>
					Is this a kit?
				</Checkbox>
			)}

			{newEquipment.isKit ? (
				<>
					<Input
						label="Example:  kit number-(type of kit)"
						required
						placeholder={newEquipment && newEquipment.name}
						onChange={(e) => {
							setNewEquipment({ ...newEquipment, name: e });
						}}
					/>
					<Input
						label="Barcode"
						required
						placeholder={newEquipment && newEquipment.barcode}
						onChange={(e) => {
							setNewEquipment({ ...newEquipment, barcode: e });
						}}
					/>
					<Input
						label="Serial Number"
						required
						placeholder={newEquipment && newEquipment.sn}
						onChange={(e) => setNewEquipment({ ...newEquipment, sn: e })}
					/>
					{equipment && equipment && (
						<Select
							name="Location"
							defaultValue={
								currentHistory &&
								currentHistory.office &&
								currentHistory.office.name
							}
							options={
								locations && locations.length
									? locations
											.filter((location) => location.name)
											.map((location) => {
												return { name: location.name, value: location.id };
											})
									: []
							}
							onChange={(value) => {
								if (value.value === null) {
									Alert("You must select an option");
								}
								setNewHistory({ ...newHistory, location: value.value });
							}}
						/>
					)}
					<Select
						name="User"
						defaultValue={
							currentHistory &&
							currentHistory.user &&
							currentHistory.user.firstName + " " + currentHistory.user.lastName
						}
						options={
							users &&
							users
								.filter((user) => user.isTeamMember || user.isContractor)
								.sort((a, b) => {
									if (a.firstName > b.firstName) return 1;
									if (a.firstName < b.firstName) return -1;
									return 0;
								})
								.map((user) => {
									return {
										name: `${user.firstName} ${user.lastName}`,
										value: user.id,
									};
								})
						}
						onChange={(value) => {
							if (value.value === null) {
								Alert("You must select an option");
							}
							setNewHistory({ ...newHistory, user: value.value });
						}}
					/>
					{/*//TODO: Add multiselect to kit*/}
					{/*<Select*/}
					{/*	name="Tools"*/}
					{/*	// defaultValue={*/}
					{/*	// 	currentEquipment &&*/}
					{/*	// 	currentEquipment.kit &&*/}
					{/*	// 	currentEquipment.kit.tools*/}
					{/*	// }*/}
					{/*	options={*/}
					{/*		equipmentList &&*/}
					{/*		equipmentList*/}
					{/*			//.filter((item) => item.isKit === false)*/}
					{/*			.map((item) => {*/}
					{/*				return {*/}
					{/*					name: item.name,*/}
					{/*					value: item.id,*/}
					{/*				};*/}
					{/*			})*/}
					{/*	}*/}
					{/*	onChange={(value) => {*/}
					{/*		setNewEquipment({ ...newEquipment, tools: value.value });*/}
					{/*	}}*/}
					{/*/>*/}
				</>
			) : (
				<>
					{(user && user.isAdmin) || type === "create" ? (
						<>
							<Input
								label="Name"
								required
								placeholder={newEquipment && newEquipment.name}
								onChange={(e) => {
									setNewEquipment({ ...newEquipment, name: e });
								}}
							/>
							<Input
								label="Additional Components"
								required
								placeholder={newEquipment && newEquipment.additionalComponents}
								onChange={(e) => {
									setNewEquipment({ ...newEquipment, additionalComponents: e });
								}}
								helper={"Separate each component with a comma"}
							/>
							<Input
								label="Barcode"
								required
								placeholder={newEquipment && newEquipment.barcode}
								onChange={(e) => {
									setNewEquipment({ ...newEquipment, barcode: e });
								}}
							/>
							<Input
								label="Serial Number"
								required
								placeholder={newEquipment && newEquipment.sn}
								onChange={(e) => setNewEquipment({ ...newEquipment, sn: e })}
							/>
							<Input
								label="Calibration Due Date"
								type="date"
								required
								onChange={(e) =>
									setNewEquipment({ ...newEquipment, expirationDate: e })
								}
							/>
							<Select
								name="Model"
								defaultValue={newEquipment && newEquipment.model}
								options={EquipmentModals.sort((a, b) => {
									if (a.name > b.name) return 1;
									if (a.name < b.name) return -1;
									return 0;
								})}
								onChange={(value) => {
									if (value.value === null) {
										Alert("You must select an option");
									}
									setNewEquipment({ ...newEquipment, model: value.value });
								}}
							/>
							{equipment && equipment && (
								<Select
									name="Location"
									defaultValue={
										currentHistory &&
										currentHistory.office &&
										currentHistory.office.name
									}
									options={
										locations && locations.length
											? locations
													.filter((location) => location.name)
													.map((location) => {
														return { name: location.name, value: location.id };
													})
											: []
									}
									onChange={(value) => {
										if (value.value === null) {
											Alert("You must select an option");
										}
										setNewHistory({ ...newHistory, location: value.value });
									}}
								/>
							)}
							<Select
								name="User"
								defaultValue={
									currentHistory &&
									currentHistory.user &&
									currentHistory.user.firstName +
										" " +
										currentHistory.user.lastName
								}
								options={
									users &&
									users
										.filter((user) => user.isTeamMember || user.isContractor)
										.sort((a, b) => {
											if (a.firstName > b.firstName) return 1;
											if (a.firstName < b.firstName) return -1;
											return 0;
										})
										.map((user) => {
											return {
												name: `${user.firstName} ${user.lastName}`,
												value: user.id,
											};
										})
								}
								onChange={(value) => {
									if (value.value === null) {
										Alert("You must select an option");
									}
									setNewHistory({ ...newHistory, user: value.value });
								}}
							/>
							<View>
								{equipment.attachments ? (
									<>
										<p
											className="text-red-500"
											onClick={() =>
												setNewEquipment({ ...newEquipment, attachment: null })
											}
										>
											Clear Image
										</p>
										<img
											alt={"Equipment Document Image"}
											src={
												typeof newEquipment.attachment === "string"
													? newEquipment.attachment
													: URL.createObjectURL(newEquipment.attachment)
											}
										/>
									</>
								) : (
									<FileUpload
										multiple
										label="Upload Document"
										onFiles={(files) => {
											setFiles(files);
										}}
									/>
								)}
							</View>
						</>
					) : (
						<Input
							label="Calibration Due Date"
							type="date"
							required
							onChange={(e) =>
								setNewEquipment({ ...newEquipment, expirationDate: e })
							}
						/>
					)}
				</>
			)}
		</SlideOver>
	);
};
EquipmentSlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	currentUser: PropTypes.object,
	currentEquipment: PropTypes.object,
	currentHistory: PropTypes.object,
	onComplete: PropTypes.func.isRequired,
	nonKit: PropTypes.bool,
};

export { EquipmentSlideOver };
