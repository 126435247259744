/* eslint-disable no-case-declarations */
import { useNavigation } from "@react-navigation/native";
import AWS from "aws-sdk";
import * as Linking from "expo-linking";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Text, View } from "react-native";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as queries from "../../../graphql/reports";
import * as reportOperations from "../../../graphql/reports";

//Components

import { Alert } from "../../../components/Alert";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import CardContainer, { Card } from "../../../components/Card";
import DataContainer, { DataItem } from "../../../components/DataDisplay";
import { FileUpload } from "../../../components/Forms";
import { PageHeader } from "../../../components/Header";
import { List, ListItem } from "../../../components/List";
import LoadingScreen from "../../../components/LoadingScreen";
import { addNotification } from "../../../components/Notification";
import OptionsMenu from "../../../components/OptionsMenu";
import Tabs, { Tab } from "../../../components/Tabs";

// Utils
import {
	faBadgeCheck,
	faBookAlt,
	faClipboardList,
	faCloudArrowDown,
	faEye,
	faInfoCircle,
	faRefresh,
	faTrash,
	faUnlink,
} from "@fortawesome/pro-duotone-svg-icons";
import { awsConfig, getData, getSignedUrl, SentryLogger } from "../../../utils";

AWS.config.update(awsConfig);

let s3 = new AWS.S3({ params: { Bucket: "srp-in-field" } });

let tabs = [
	{ name: "Overview", icon: faInfoCircle },
	{ name: "Responses", icon: faClipboardList },
	{ name: "Documents", icon: faBookAlt },
];

export default function Report({ route }) {
	const { id } = route.params;
	// let { id } = useParams();

	const navigation = useNavigation();
	let [user, setUser] = React.useState(null);
	let [report, setReport] = React.useState(null);
	let [currentTab, setCurrentTab] = useState(tabs[0].name);
	let [files, setFiles] = React.useState([]);
	let [projectUser, setProjectUser] = React.useState(null);

	React.useEffect(() => {
		const getUser = async () => {
			await getData("@storage_Key")
				.then((user) => {
					setUser(user);
				})
				.catch((err) => {
					SentryLogger(err);
				});
		};
		getUser();
	}, []);

	const getCurrentUser = async (project) => {
		let user = await getData("@storage_Key").then((user) => user);
		let team = project.workspace
			? project.team.concat(project.workspace.team)
			: project.team;

		let projectUser = team.find((tm) => tm.teamMember.id === user.id);

		if (projectUser) {
			return projectUser;
		} else {
			addNotification({
				title: "Unauthorized Access",
				text: "Sorry you are not authorized to access this project. You must be added to the project team before you can access it",
				type: "error",
				time: 10000,
				supportText: true,
			});
			navigation.navigate("App");
		}
	};

	const { refetch: reportRefetch } = useQuery(gql(queries.getReport), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			//  setReport(data.getReport);

			let { author, reviewer } = data.getReport;
			let projectUser = getCurrentUser(data.getReport.project);

			setProjectUser(projectUser);
			if (
				(projectUser && (projectUser.isAdmin || projectUser.isSuperAdmin)) ||
				(reviewer && reviewer.id === user.id) ||
				(author && author.id === user.id)
			) {
				setReport(data.getReport);
			} else {
				addNotification({
					title: "Unauthorized Access",
					text: "Sorry you are not authorized to access this report. You must be added to the project team before you can access it",
					type: "error",
					time: 10000,
					supportText: true,
				});
				navigation.navigate("App");
			}
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("Report Does Not Exist");
			navigation.navigate("App");
		},
	});

	/**
	 * downloadReport
	 * makes a callout to S3 to download the report for the user
	 * @param {string} link
	 * @param {string} name
	 */

	const downloadReport = async (link) => {
		try {
			let url = getSignedUrl(link);
			Linking.openURL(url);
		} catch (err) {
			Alert("There was an error downloading the report. Please try again.");
		}
	};

	/**
	 * deleteReport
	 * Delete reportLink object and the s3 object
	 * @param {string} documentId - document (reportLink) id
	 * @param {string} link - S3 Key
	 */

	let [deleteReportLink] = useMutation(gql(reportOperations.deleteReportLink), {
		onCompleted: (data) => {
			let params = { Bucket: "srp-in-field", Key: data.deleteReport.link };
			s3.deleteObject(params, (err) => {
				let reportDeletion;

				if (!err) {
					addNotification({
						title: `Document titled ${reportDeletion.data.deleteReportLink.name} Deleted`,
						text: "Report object and data has been deleted",
						type: "success",
						time: 10000,
					});
				}
			});
			reportRefetch();
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			addNotification({
				title: "Uh Oh Something Went Wrong",
				text: "We could not delete this document. Please try again. If this continues to happen please go to help.srpinfield.com and start a chat with one of our engineers",
				type: "error",
				time: 5000,
			});
		},
	});

	const getSignedDocumentURL = async (link) => {
		let params = { Bucket: "srp-in-field", Key: link };

		s3.getSignedUrlPromise("getObject", params)
			.then((url) => {
				let link = document.createElement("a");
				link.setAttribute("target", "_blank");
				link.href = url;
				link.click();
			})
			.catch(() => {
				addNotification({
					title: "Error Getting URL",
					text: "We could not get the signed url for this document. Please try again. If this continues to happen please go to help.srpinfield.com and start a chat with one of our engineers",
					type: "error",
					time: 5000,
				});
			});
	};

	/**
	 * setOfficialStatus
	 * Set the reportLink isOfficial to be true, which allows the report to be viewed on the project's overview page
	 * @param {string} reportLinkId
	 * @param {boolean} value - the opposite of its current isOfficial value
	 *
	 */

	const [setOfficialStatus] = useMutation(
		gql(reportOperations.updateReportLink),
		{
			onCompleted: () => {
				addNotification({
					title: "Document Updated",

					// text: value
					//   ? `This document is now viewable in the overview tab of the ${
					//       report.project.workspace ? "workspace" : "project"
					//     }`
					//   : "This report is no longer official",

					type: "success",
					time: 5000,
				});
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert(
					"Uh Oh Something Went Wrong",
					"We could not update the status of this report. Please try again. If this continues to happen please go to help.srpinfield.com and start a chat with one of our engineers"
				);
			},
		}
	);

	const renderResponse = (option, responses) => {
		switch (option.type) {
			case "select":
				let values = option.options;
				let response = responses[option.dataName];
				values.map((value, index) => {
					if (value.value === response) {
						return (
							<Text className="text-gray-900 dark:text-white" key={index}>
								{value.text}
							</Text>
						);
					}
				});

				break;
			case "date":
				let dateValue = responses[option.dataName];
				return (
					<Text className="text-gray-900 dark:text-white">
						{DateTime.fromISO(dateValue).toFormat("MMMM Do, YYYY")}
					</Text>
				);
			case "text":
				let textValue = responses[option.dataName];
				return (
					<Text className="text-gray-900 dark:text-white">{textValue}</Text>
				);

			default:
				break;
		}
	};

	const getButtons = () => {
		switch (currentTab) {
			case "Documents":
				return [
					{
						name: "Regenerate Report",
						icon: faRefresh,
						onPress: () => {
							createReport();
						},
					},
				];

			default:
				return null;
		}
	};

	const [createReportLink] = useMutation(
		gql(reportOperations.createReportLink),
		{
			onCompleted: () => {
				reportRefetch();
				addNotification({
					title: "Successfully Created Daily Sheet!",
					text: "Your daily sheet was successfully created",
					time: 5000,
					type: "success",
				});
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert(
					"Uh Oh Something Went Wrong",
					"There was an error creating the report. Please try again. If this continues to happen please go to help.srpinfield.com and start a chat with one of our engineers"
				);
			},
		}
	);

	const createReport = async () => {
		addNotification({
			title: "Validating Responses",
			text: "Verifying that your responses are good",
			type: "info",
			time: 5000,
		});

		let reportOptions = JSON.parse(report.reportType.options);
		let reportResponses = JSON.parse(report.responses);
		let noResponses = reportOptions
			.filter((option) => option.required)
			.filter((option) => {
				if (
					reportResponses[option.dataName] == undefined ||
					reportResponses[option.dataName] == null
				) {
					return option;
				}
			});

		if (noResponses.length) {
			addNotification({
				title: "Uh Oh Something Doesn't Look Right",
				message: "All fields with an astrix (*) are required",
				type: "error",
				time: 5000,
			});
			return;
		}

		reportResponses.author = { teamMember: projectUser };
		reportResponses.reviewer = {
			teamMember: report.reviewer,
			projectRole: "Peer Reviewer",
		};
		reportResponses.addressedTo = report.addressedTo;
		reportResponses.project = report.project;

		addNotification({
			title: "Creating Report",
			text: "Responses look good! We are creating the report now",
			type: "success",
			time: 5000,
		});

		try {
			let response = await fetch(report.reportType.endPoint, {
				method: "POST", // *GET, POST, PUT, DELETE, etc.
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(reportResponses),
			});
			let data = await response.json();

			createReportLink(report.id, data);
		} catch (error) {
			SentryLogger(error);
			if (noResponses.length) {
				addNotification({
					title: "Uh Oh Something Went Wrong",
					text: "Please try again. If this continues to happen please go to help.srpinfield.com and start a chat with one of our engineers",
					type: "error",
					supportText: true,
					time: 5000,
				});
				return;
			}
		}
	};

	if (!report) {
		return <LoadingScreen loadItem="Report" />;
	}

	return (
		<>
			<Breadcrumb className="mt-2">
				<>
					{report.project?.workspace && (
						<Crumb
							name={report.project?.workspace.title}
							location="Project"
							params={{ id: report.project?.workspace.id }}
						/>
					)}
				</>

				<Crumb
					name={report.project?.title}
					location="Project"
					params={{ id: report.project?.id }}
				/>
				<Crumb
					name="Report Center"
					location="Reporting Center"
					params={{ id: report.project.id }}
					href={`/app/reporting-center/${report.project.id}`}
				/>
				<Crumb
					name="Report"
					location="Report"
					params={{ id: report.project.id }}
					//href={`/app/reporting-center/report/${report.project.id}`}
				/>
			</Breadcrumb>
			<PageHeader
				goBack={{
					location: "Project",
					params: {
						id: report.project.id,
					},
				}}
				title={report.reportType.name}
				buttons={getButtons()}
			/>
			<Tabs
				currentValue={currentTab}
				onChange={(value) => {
					setCurrentTab(value);
				}}
			>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							current={currentTab === tab.name ? true : false}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>

			{currentTab === "Overview" && (
				<CardContainer>
					{report.author && <Card title="Author" user={report.author} />}
					{report.reviewer && <Card title="Reviewer" user={report.reviewer} />}
				</CardContainer>
			)}

			{currentTab === "Responses" && (
				<>
					{report.reportType.options && (
						<DataContainer>
							{JSON.parse(report.reportType.options).map((option, index) => {
								let responses = JSON.parse(report.responses);
								return (
									<DataItem
										key={`response-${index}`}
										name={`${index + 1}. ${option.name}`}
										value={renderResponse(option, responses)}
									/>
								);
							})}
						</DataContainer>
					)}
				</>
			)}

			{currentTab === "Documents" && (
				<>
					<View className="p-10">
						<Text className="text-gray-900 dark:text-white">
							Upload Documents
						</Text>
						<FileUpload
							files={files}
							onFiles={setFiles}
							server={{
								process: function (fieldName, file, metadata, load, error) {
									s3.upload(
										{
											Key:
												`projects/${report.project.id}/report/${file.name}` +
												"_" +
												Date.now(),
											Body: file,
											ContentType: file.type,
										},
										function (err, data) {
											if (err) {
												error("Something went wrong", error);
												return;
											}
											load(data.Key);
											createReportLink({
												variables: {
													input: {
														report: report.id,
														link: data.Location,
														name: file.name,
													},
												},
											});
										}
									);
								},
							}}
							allowMultiple={true}
							maxFiles={3}
							name="files"
						/>
					</View>

					<View>
						{report && (
							<List
								showFully={true}
								headers={["Name", "Date", "Type", "Official Status"]}
								usesOptions
							>
								{report.documents.map((document, index) => {
									let fileName = document.name
										? document.name.split(".")
										: [""];
									let fileType = fileName[fileName.length - 1];
									return (
										<ListItem
											showFully={true}
											key={`document-${index}`}
											items={[
												{
													content: document.name
														? document.name
														: "No Document Name",
												},
												{
													content: DateTime.fromISO(document.date).toFormat(
														"MMMM D, YYYY"
													),
												},
												{
													content: fileType,
												},
												{
													content: document.isOfficial
														? "Official Document"
														: "Unofficial Document",
												},
											]}
											options={
												<OptionsMenu
													options={[
														{
															name: "View",
															icon: faEye,
															onPress: () => {
																getSignedDocumentURL(document.link);
															},
														},

														{
															name: "Download",
															icon: faCloudArrowDown,
															onPress: () => {
																downloadReport(document.link, document.name);
															},
														},

														{
															name: "Delete",
															icon: faTrash,
															onPress: () => {
																deleteReportLink({
																	variables: {
																		input: {
																			id: document.id,
																		},
																	},
																});
															},
														},

														{
															name: document.isOfficial
																? "Make Unofficial"
																: "Make Official",
															icon: document.isOfficial
																? faUnlink
																: faBadgeCheck,
															onPress: () => {
																setOfficialStatus({
																	variables: {
																		input: {
																			id: document.id,
																			isOfficial: !document.isOfficial,
																		},
																	},
																});
															},
														},
													]}
												/>
											}
										/>
									);
								})}
							</List>
						)}
					</View>
				</>
			)}
		</>
	);
}

Report.propTypes = {
	route: PropTypes.object,
};
