import { faMemoPad } from "@fortawesome/pro-duotone-svg-icons";
import { SafetySingleNote } from "../views/SafetyCenter/Notes";
import {
	SafetyReports,
	SingleSafetyReport,
} from "../views/SafetyCenter/Reports";

export const safetyCenterNav = [
	{
		name: "Safety Reports",
		layout: "SafetyCenter",
		component: SafetyReports,
		path: "/reports",
		icon: faMemoPad,
	},
	{
		name: "Single Report",
		layout: "SafetyCenter",
		component: SingleSafetyReport,
		path: "/reports/:id",
		noShow: true,
	},
	{
		name: "Single Safety Note",
		layout: "SafetyCenter",
		component: SafetySingleNote,
		path: "/note/:id",
		noShow: true,
	},
];
