import { useNavigation } from "@react-navigation/native";
import codes from "country-calling-code";
import * as ImagePicker from "expo-image-picker";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Image, ScrollView, Text, TouchableOpacity, View } from "react-native";

// components
import { Alert } from "../../components/Alert";
import { PageHeader } from "../../components/Header";
import Tabs, { Tab } from "../../components/Tabs";

import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import Button from "../../components/Button";
import {
	Checkbox,
	FieldSet,
	Input,
	Select,
	TextArea,
} from "../../components/Forms";
import { List, ListItem } from "../../components/List";
import Modal from "../../components/Modal";
import OptionsMenu from "../../components/OptionsMenu";

// gql
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as mfaGQL from "../../graphql/mfa";
import * as userOperations from "../../graphql/user";

// Icons
import {
	faBell,
	faCamera,
	faCheck,
	faIdBadge,
	faImage,
	faLock,
	faPlus,
	faShieldAlt,
	faSignOutAlt,
	faTimes,
	faUserCheck,
	faUserLock,
	faUserSlash,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

// Utils
import LoadingScreen from "../../components/LoadingScreen";
import {
	API_URL,
	getData,
	getSignedUrl,
	SentryLogger,
	storeData,
	uploadFile,
} from "../../utils";

const tabs = [
	{ name: "Profile", href: "#", icon: faIdBadge },
	{ name: "Password", href: "#", icon: faLock },
	{ name: "Notifications", href: "#", icon: faBell },
	{ name: "Account", href: "#", icon: faUserCheck },
	{ name: "MFA", href: "#", icon: faUserLock },
];

export const Settings = () => {
	const navigation = useNavigation();
	const [user, setUser] = React.useState(null);
	const [currentTab, setCurrentTab] = useState(tabs[0].name);
	const [newPassword, setNewPassword] = React.useState("");
	const [deleteModal, showDeleteModal] = React.useState(false);
	const [mfaModal, showMfaModal] = React.useState(false);
	const [phone, setPhone] = React.useState("");
	const [userCode, setUserCode] = React.useState("");
	const [image, setImage] = useState(null);
	const [confirmModal, setConfirmModal] = React.useState(false);
	const [deviceName, setDeviceName] = React.useState("");
	const [countryCode, setCountryCode] = React.useState("");
	const [terms, setTerms] = React.useState(false);

	let [confirmPassword, setConfirmPassword] = React.useState("");
	let [updateUser, setUpdateUser] = React.useState({});

	React.useState(() => {
		const getUserInfo = async () => {
			await getData("@storage_Key")
				.then((res) => {
					getUser({
						variables: {
							id: res.id,
						},
					});
					setUpdateUser({ id: res.id });
				})
				.catch((err) => {
					SentryLogger(err);
				});
		};
		getUserInfo();
	}, []);

	const [getUser] = useLazyQuery(gql(userOperations.getProfileInfo), {
		onCompleted: (data) => {
			setUser(data.getUser);
		},
		onError: (error) => {
			SentryLogger(error);
		},
		fetchPolicy: "cache-and-network",
	});

	const [updateUserInfo] = useMutation(gql(userOperations.updateUser), {
		onCompleted: (data) => {
			setUpdateUser(data.updateUser);
			if (data && data.updateUser) {
				storeData("@storage_Key", data.updateUser);
				setUser(data.updateUser);
				setUpdateUser(data.updateUser);
				Alert("Profile updated successfully");
			}
		},
		onError: (error) => {
			SentryLogger(error);
			Alert("Couldn't update user operations please try again");
		},
	});

	const [removeAccount] = useMutation(gql(userOperations.updateUser), {
		onCompleted: (data) => {
			if (data && data.updateUser) {
				storeData("@storage_Key", null);
				navigation.navigate("Auth");
				Alert("Account deleted successfully");
			}
		},
		onError: (error) => {
			SentryLogger(error);
			Alert("Error removing account. Please try again");
		},
	});

	const updatePassword = () => {
		//password requirements
		let lowerCaseLetters = /[a-z]/g;
		let upperCaseLetters = /[A-Z]/g;
		let numbers = /[0-9]/g;
		let special = /[=+-^$*.{}()?"!@#%&/,><':;|_~`]/g;

		if (newPassword !== confirmPassword) {
			//   todo add notification alerting passwords do not match
		}

		if (
			!(
				newPassword.length >= 8 &&
				newPassword.match(upperCaseLetters) &&
				newPassword.match(lowerCaseLetters) &&
				newPassword.match(numbers) &&
				newPassword.match(special)
			)
		) {
			Alert(
				"Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character"
			);
			return;
		}

		updateUserInfo({
			variables: {
				input: {
					id: user.id,
					password: newPassword,
				},
			},
		});
	};

	/**
	 * pickImage
	 * @param {*} useCamera whether to use the camera or the photo library
	 */
	const pickImage = async (useCamera) => {
		/**
		 * setAndUploadImage
		 * @param {*} image the uri of the image
		 * @param {*} filetype the file type of the image
		 */
		const setAndUploadImage = async (image, filetype) => {
			const key = `users/profile-pictures/${user.id}}`;
			const response = await fetch(image);
			const blob = await response.blob();
			try {
				await uploadFile(blob, key, filetype);

				setImage(image);
				setUpdateUser({
					...updateUser,
					profilePicture: key,
				});
			} catch {
				Alert(
					"There was an error uploading your profile picture",
					"Please try again"
				);
			}
		};
		if (useCamera) {
			// call the camera

			const permissionResult =
				await ImagePicker.requestCameraPermissionsAsync();

			if (permissionResult.granted === false) {
				Alert("You've refused to allow this app to access your camera");
			} else {
				let result = await ImagePicker.launchCameraAsync({
					mediaTypes: ImagePicker.MediaTypeOptions.Images,
					allowsEditing: true,
					aspect: [4, 3],
					quality: 1,
				});

				if (!result.cancelled) {
					setAndUploadImage(result.uri, result.type);
				}
			}
		} else {
			// No permissions request is necessary for launching the image library
			let result = await ImagePicker.launchImageLibraryAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.All,
				allowsEditing: true,
				aspect: [4, 3],
				quality: 1,
			});

			if (!result.cancelled) {
				if (result.assets) {
					let asset = result.assets[0];
					await setAndUploadImage(asset, asset.type);
				} else {
					await setAndUploadImage(result.uri, result.type);
				}
			}
		}
	};

	const deleteProfilePicture = async () => {
		setImage(null);
		setUser({
			...user,
			profilePicture: null,
		});
		setUpdateUser({
			...updateUser,
			profilePicture: null,
		});
	};

	const deleteAccount = () => {
		removeAccount({
			variables: {
				input: {
					id: user.id,
					email: "",
					password: "",
					phone: "",
					isActive: false,
				},
			},
		});
	};

	const logout = () => {
		storeData("@storage_Key", null);
		navigation.navigate("Auth");
	};

	const [confirmMFA] = useMutation(gql(mfaGQL.confirmMFA), {
		onCompleted: () => {
			let fullPhone = "+" + countryCode + phone;
			createMFA({
				variables: {
					input: {
						device: fullPhone,
						method: "SMS",
						name: deviceName,
						user: user.id,
					},
				},
			});
			setConfirmModal(false);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert(error.message);
		},
	});

	const [createMFA] = useMutation(gql(mfaGQL.createMFA), {
		onCompleted: (data) => {
			setCountryCode(null);
			setDeviceName("");
			setUser(data.createMFA.user);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("There was an error deleting your device");
		},
	});

	const [deleteMFADevice] = useMutation(gql(mfaGQL.deleteMFA), {
		onCompleted: (data) => {
			setUser(data.deleteMFA.user);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("There was an error deleting your device");
		},
	});

	const getButtonText = () => {
		switch (currentTab) {
			case "MFA":
				return "Add New Device";
		}
	};

	const handleButtonClick = () => {
		switch (currentTab) {
			case "MFA":
				showMfaModal();
				break;
		}
	};

	if (!user) return <LoadingScreen loadItem="Your Settings" />;

	return (
		<>
			<Breadcrumb>
				<Crumb name="Profile" location="Profile" />
				<Crumb name="Settings" location="Setting" />
			</Breadcrumb>
			<PageHeader
				goBack={{
					location: "Profile",
				}}
				title={"Settings"}
				buttons={[
					{
						name: getButtonText(),
						icon: faPlus,
						onPress: () => handleButtonClick(),
					},
				]}
			/>
			<Tabs
				currentValue={currentTab}
				// mobile handling

				onPress={(value) => {
					setCurrentTab(value);
				}}
			>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							current={currentTab === tab.name ? true : false}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			{currentTab === "Profile" && (
				<View className=" m-5">
					<ScrollView className="mb-44" showsVerticalScrollIndicator={false}>
						<FieldSet legend="Profile Photo">
							<View className="flex w-full items-center  ">
								{/* Create a round image with profile picture or view box with the user initials if no profile picture. When clicked on it should prompt the system to show or upload a picture */}

								<View className="relative">
									{image || user.profilePicture ? (
										<Image
											source={{
												uri: image || getSignedUrl(user.profilePicture),
											}}
											className="h-32 w-32 rounded-full"
										/>
									) : (
										<View className="h-32 w-32 rounded-full bg-indigo-500 flex flex-row items-center justify-center">
											<Text className="text-white font-medium text-4xl">
												{user.firstName[0]}
												{user.lastName[0]}
											</Text>
										</View>
									)}
									{/* Add an overlay with a an plus icon */}
									<View className="h-32 w-32 absolute z-10 bg-slate-900 rounded-full border-2 border-white dark:border-gray-800 opacity-20"></View>
									<View className="h-32 w-32 absolute z-20 rounded-full flex justify-end">
										<View className="flex flex-row items-end justify-center gap pb-3 pt-1 ">
											<TouchableOpacity
												onPress={() => {
													pickImage(false);
												}}
											>
												<FontAwesomeIcon
													icon={faImage}
													color="white"
													size={25}
												/>
											</TouchableOpacity>
											<TouchableOpacity
												className="ml-2"
												onPress={() => {
													pickImage(true);
												}}
											>
												<FontAwesomeIcon
													icon={faCamera}
													color="white"
													size={25}
												/>
											</TouchableOpacity>
										</View>
									</View>
								</View>

								{(image || user.profilePicture) && (
									<TouchableOpacity
										onPress={() => {
											deleteProfilePicture();
										}}
										className="pt-2"
									>
										<Text className="text-red-500 font-medium text-center">
											Delete Profile Picture
										</Text>
									</TouchableOpacity>
								)}
							</View>
						</FieldSet>

						<View className="inline-flex w-full">
							<Input
								// type is text not profile

								disabled={user.isTeamMember || user.isContractor ? true : false}
								value={updateUser.firstName}
								type="text"
								label="First Name"
								className="mr-1 w-full"
								onChange={(e) =>
									setUpdateUser({
										...updateUser,
										firstName: e,
									})
								}
							/>
							<Input
								disabled={user.isTeamMember || user.isContractor ? true : false}
								className="ml-1 w-full"
								type="text"
								label="Last Name"
								value={updateUser.lastName}
								onChange={(e) =>
									setUpdateUser({
										...updateUser,
										lastName: e,
									})
								}
							/>
						</View>

						<Input
							disabled={user.isTeamMember || user.isContractor ? true : false}
							type="text"
							label="Role"
							value={updateUser.role}
							onChange={(e) =>
								setUpdateUser({
									...updateUser,
									role: e,
								})
							}
						/>
						<Input
							type="text"
							label="Email Address"
							value={updateUser.email}
							onChange={(e) =>
								setUpdateUser({
									...updateUser,
									email: e,
								})
							}
							helper="This will change your login email. Make sure your are setting the correct email"
						/>
						<Input
							type="text"
							label="Phone Number"
							value={updateUser.phone}
							onChange={(e) =>
								setUpdateUser({
									...updateUser,
									phone: e,
								})
							}
						/>
						<TextArea
							label="Bio"
							onChange={(e) =>
								setUpdateUser({
									...updateUser,
									bio: e,
								})
							}
						/>

						<Button
							onPress={() => {
								updateUserInfo({
									variables: {
										input: updateUser,
									},
								});
							}}
							className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							text="Update Profile"
						/>
					</ScrollView>
				</View>
			)}
			{currentTab === "Password" && (
				<View className=" m-5">
					<View>
						<FieldSet
							legend="New Password"
							description="Make a new password for your account"
						>
							<Input
								type="password"
								required
								label="New Password"
								onChange={(e) => setNewPassword(e)}
							/>

							<Input
								type="password"
								required
								label="Confirm New Password"
								onChange={(e) => setConfirmPassword(e)}
							/>
							{confirmPassword.length ? (
								<View className="flex flex-row ">
									<FontAwesomeIcon
										icon={newPassword === confirmPassword ? faCheck : faTimes}
									/>

									<Text
										className={
											newPassword === confirmPassword
												? "text-blue-500"
												: "text-red-500"
										}
									>
										{newPassword === confirmPassword
											? "Passwords Match"
											: "Passwords Do Not Match"}
									</Text>
								</View>
							) : null}
						</FieldSet>
						<Button
							type="submit"
							className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							text="Change Password"
							onPress={() => {
								updatePassword();
							}}
						/>
					</View>
				</View>
			)}
			{currentTab === "Notifications" && (
				<View className=" m-5">
					<ScrollView>
						<FieldSet
							legend="Email Notifications"
							description="Select which types of notifications you'd like to be emailed about"
						>
							<Checkbox
								name="checkbox"
								checkedValue={user.emailGeneral || updateUser.emailGeneral}
								label="General Email Notifications"
								description="Do you want to receive general email notifications"
								onChange={(e) => {
									setUpdateUser({
										...updateUser,
										emailGeneral: e,
									});
								}}
							/>
							<Checkbox
								name="checkbox"
								checkedValue={user.emailProjects || user.emailProjects}
								label="Project Email Notifications"
								description="Do you want to receive project email notifications"
								onChange={(e) => {
									setUpdateUser({
										...updateUser,
										emailProjects: e,
									});
								}}
							/>
							<Checkbox
								name="checkbox"
								checkedValue={user.emailReports || updateUser.emailReports}
								label="Report Email Notification"
								description="Do you want to receive report email notifications"
								onChange={(e) => {
									setUpdateUser({
										...updateUser,
										emailReports: e,
									});
								}}
							/>
							<Checkbox
								name="checkbox"
								checkedValue={user.emailEquipment || updateUser.emailEquipment}
								label="Notification"
								description="Do you want to receive equipment email notifications"
								onChange={(e) => {
									setUpdateUser({
										...updateUser,
										emailEquipment: e,
									});
								}}
							/>
						</FieldSet>
						<FieldSet
							legend="App Notifications"
							description="Select which types of notifications you'd like to receive in app"
						>
							<Checkbox
								name="checkbox"
								checkedValue={
									user.appNotificationGeneral ||
									updateUser.appNotificationGeneral
								}
								label="General Notifications"
								description="Do you want to receive general notifications"
								onChange={(e) => {
									setUpdateUser({
										...updateUser,
										appNotificationGeneral: e,
									});
								}}
							/>
							<Checkbox
								name="checkbox"
								checkedValue={
									user.appNotificationProjects ||
									updateUser.appNotificationProjects
								}
								label="Project Notifications"
								description="Do you want to receive project notifications"
								onChange={(e) => {
									setUpdateUser({
										...updateUser,
										appNotificationProjects: e,
									});
								}}
							/>
							<Checkbox
								name="checkbox"
								checkedValue={
									user.appNotificationReports ||
									updateUser.appNotificationReports
								}
								label="Report Notifications"
								description="Do you want to receive report notifications"
								onChange={(e) => {
									setUpdateUser({
										...updateUser,
										appNotificationReports: e,
									});
								}}
							/>
							<Checkbox
								name="checkbox"
								checkedValue={
									user.appNotificationEquipment ||
									updateUser.appNotificationEquipment
								}
								label="Equipment Notifications"
								description="Do you want to receive equipment notifications"
								onChange={(e) => {
									setUpdateUser({
										...updateUser,
										appNotificationEquipment: e,
									});
								}}
							/>
						</FieldSet>
						<Button
							icon={faBell}
							text="Update Notification Preferences"
							onPress={() => {
								updateUserInfo({
									variables: {
										input: updateUser,
									},
								});
							}}
						/>
					</ScrollView>
				</View>
			)}
			{currentTab === "Account" && (
				<View className=" m-5">
					<View>
						<FieldSet legend="Sign Out Of Your Account">
							<Button
								icon={faSignOutAlt}
								text="Logout"
								onPress={() => logout()}
							/>
						</FieldSet>
					</View>
					<View className="border-t-2 border-gray-300 mt-2">
						<FieldSet
							legend="Delete Your Account"
							description="Deleting your account is permanent and cannot be undone after 30 days"
						>
							<View className="pt-2">
								<Button
									icon={faUserSlash}
									variant="red"
									text="Delete Account"
									onPress={() => {
										showDeleteModal(true);
									}}
								/>
							</View>
						</FieldSet>
					</View>
				</View>
			)}

			{currentTab === "MFA" && (
				<View className=" m-5">
					<View>
						<List title={"Devices"} headers={["Name", "Method", "Device"]}>
							{user &&
								user.mfaDevices &&
								user.mfaDevices.map((device, index) => {
									return (
										<ListItem
											key={index}
											items={[
												{
													content: device.name,
												},
												{
													content: device.method,
												},
												{
													content: device.device,
												},
											]}
											options={
												<OptionsMenu
													options={[
														{
															icon: faShieldAlt,
															name: "Delete",
															onPress: () => {
																deleteMFADevice({
																	variables: {
																		input: {
																			id: device.id,
																		},
																	},
																});
															},
															variant: "red",
														},
													]}
												/>
											}
										/>
									);
								})}
						</List>
					</View>
				</View>
			)}

			<Modal
				isOpen={deleteModal}
				closeRequest={() => {
					showDeleteModal(false);
				}}
				title="Delete Account"
				description={
					"Are you sure you want to delete your account? This cannot be undone after 30 days"
				}
				rightButton={{
					text: "Delete Account",
					onPress: () => {
						deleteAccount();
					},
					variant: "red",
				}}
			/>
			{/* create a moal to add a new device */}

			<Modal
				isOpen={mfaModal}
				closeRequest={() => {
					showMfaModal(false);
				}}
				title="What's your phone number?"
				rightButton={{
					text: "Send Confirmation Code",

					onPress: async () => {
						// check if the number is valid and has a country code

						if (!terms)
							return Alert(
								"You must agree to receiving SMS messages from a toll-free number"
							);

						if (!deviceName) {
							return Alert("You must enter a device name");
						}

						if (!countryCode) {
							return Alert("You must enter a country code");
						}

						let fullPhone = "+" + countryCode + phone;

						function isValidPhoneNumber(phone) {
							if (!phone) return false;
							// regex to check if the phone number is valid and has a country code no spaces, letters or dashes after the country code
							var re = /^\+[1-9]{1}[0-9]{3,14}$/;
							return re.test(phone);
						}

						if (!isValidPhoneNumber(fullPhone)) {
							// return an alert
							return Alert(
								"You must enter a valid phone number",
								"It must also contain the country code"
							);
						}

						// call the fetch statement to send the code to the users phone

						await fetch(`${API_URL}/security/sms-code`, {
							method: "POST",
							cors: true,
							body: JSON.stringify({ phone: fullPhone, userID: user.id }),
						})
							.then((res) => {
								if (res.status === 200) {
									// if the status is 200 then show the modal to enter the code
									showMfaModal(false);
									setConfirmModal(true);
								} else {
									Alert("Something went wrong", "Please try again");
								}
							})
							.catch((error) => {
								SentryLogger(error);
								return Alert("Something went wrong", "Please try again");
							});
					},

					variant: "blue",
				}}
				description={
					"By tapping Send Confirmation Code below we will send you a SMS to confirm your phone number. Message and data rates may apply. "
				}
			>
				{/*create a ternary operator to check if the phone number has been entered if not make the field required/ */}

				<View className="">
					<Input
						required
						label="Device Name"
						type="number"
						name="phone number"
						// value={phone}
						// placeholder="iPhone 14 Pro Max"
						onChange={(e) => setDeviceName(e)}
						//validate the phone number using regex to make sure a phone number is entered and if not alert the user to enter a phone number
					/>
					<FieldSet legend={"Phone Number"}>
						<Select
							name="Country Code"
							options={
								[...codes]
									.sort((a, b) => {
										// make the United States the first option
										if (a.country === "United States") {
											return -1;
										}
										if (b.country === "United States") {
											return 1;
										}
										return 0;
									})
									.map((code) => {
										return {
											name: `${code.country} (${code.countryCodes[0]})`,
											value: code.countryCodes[0],
										};
									}) || []
							}
							onChange={(e) => {
								setCountryCode(e.value);
							}}
							placeholder="Select a country code"
							autoComplete="tel-device"
						/>

						<Input
							required
							label="Phone Number"
							type="number"
							name="phone number"
							value={phone}
							placeholder="XXXXXXXXXX"
							onChange={(e) => {
								let number = e.replace(/\D/g, "");
								setPhone(number);
							}}
							autoComplete="tel"
						/>
					</FieldSet>
					<Checkbox
						label="I agree to receiving SMS messages from a toll-free number"
						checked={terms}
						onChange={(e) => {
							setTerms(e);
						}}
					/>
				</View>
			</Modal>

			{/* create a modal to confirm the code */}
			<Modal
				isOpen={confirmModal}
				closeRequest={() => {
					setConfirmModal(false);
				}}
				title="What's your confirmation code?"
				rightButton={{
					text: "Confirm",
					onPress: () => {
						// confirm the code is right

						confirmMFA({
							variables: {
								code: userCode,
								id: user.id,
							},
						});
					},
					variant: "blue",
				}}
				description={
					"Enter the code we sent to your phone number to confirm your account"
				}
			>
				<View className="flex flex-row justify-between">
					<Input
						label="Confirmation Code"
						type="number"
						name="confirmation code"
						placeholder="Enter your code"
						onChange={(e) => setUserCode(e)}
						required
						autoComplete={"sms-otp"}
					/>
				</View>
			</Modal>
		</>
	);
};

Settings.propTypes = {
	route: PropTypes.object,
};
