import {
	faClipboardList,
	faEnvelope,
	faFileAlt,
	faMemoPad,
	faProjectDiagram,
	faToolbox,
	faUsers,
	faUsersBetweenLines,
	faVialVirus,
} from "@fortawesome/pro-duotone-svg-icons";
import { Email, Emails } from "../views/Admin/Emails";
import Equipment, { SingleEquipment } from "../views/Admin/Equipment";
import { Lab, Labs } from "../views/Admin/Labs";
import { ProjectRolesScreen } from "../views/Admin/ProjectRoles";
import AdminProjects from "../views/Admin/Projects";
import { ReportType, ReportTypes } from "../views/Admin/ReportTypes";
import { Body, SOPs, SingleSOP, Version } from "../views/Admin/SOPs";
import {
	SingleSubcontractorScreen,
	SubcontractorScreen,
} from "../views/Admin/Subcontractors";
import { UserInfo, Users } from "../views/Admin/Users";

export const authNav = [
	{
		layout: "/admin",
		name: "Admin Projects",
		component: AdminProjects,
		path: "/projects",
		icon: faProjectDiagram,
	},
	{
		name: "Users",
		component: Users,
		path: "/",
		icon: faUsers,
	},
	{
		name: "User",
		component: UserInfo,
		path: "/user/:id",
		noShow: true,
	},

	{
		name: "ProjectRoles",
		component: ProjectRolesScreen,
		path: "/admin/project-roles",
		icon: faClipboardList,
	},
	{
		layout: "admin",
		name: "Equipment",
		component: Equipment,
		path: "equipment",
		icon: faToolbox,
	},
	{
		layout: "admin",
		name: "AdminSingleEquipment",
		component: SingleEquipment,
		path: "equipment/:id",
		noShow: true,
	},

	{
		layout: "admin",
		name: "Subcontractors",
		component: SubcontractorScreen,
		path: "subcontractors",
		icon: faUsersBetweenLines,
	},
	{
		layout: "admin",
		name: "SingleSubcontractor",
		component: SingleSubcontractorScreen,
		path: "subcontractors/:id",
		noShow: true,

		name: "Report Types",
		component: ReportTypes,
		path: "report-types",
		icon: faFileAlt,
	},
	{
		layout: "admin",
		name: "Report Type",
		component: ReportType,
		path: "report-types/:id",
		noShow: true,
	},
	{
		layout: "admin",
		name: "Emails",
		component: Emails,
		path: "emails",
		icon: faEnvelope,
	},
	{
		layout: "admin",
		name: "Email",
		component: Email,
		path: "emails/:id",
		noShow: true,
	},
	{
		layout: "admin",
		name: "SOPs",
		component: SOPs,
		path: "sops",
		icon: faMemoPad,
	},
	{
		layout: "admin",
		name: "SingleSOP",
		component: SingleSOP,
		path: "sops/:id",
		noShow: true,
	},
	{
		layout: "admin",
		name: "Version",
		component: Version,
		path: "sops/version/:id",
		noShow: true,
	},
	{
		layout: "admin",
		name: "Body",
		component: Body,
		path: "sops/version/body/:id",
		noShow: true,
	},
	{
		layout: "admin",
		name: "Labs",
		component: Labs,
		path: "labs",
		icon: faVialVirus,
	},
	{
		layout: "admin",
		name: "Lab",
		component: Lab,
		path: "labs/:id",
		icon: faVialVirus,
		noShow: true,
	},
];
