import React from "react";
//import { Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
//import useInterval from "react-useinterval";
// import * as Device from "expo-device";
// import * as Notifications from "expo-notifications";

// navigation
import { Navigation as NavigationContainer } from "../components/Navigation";
import { mainNav } from "../routes/routes";

// GraphQL
// import * as devicesGQL from "../graphql/devices";
// import { gql, useMutation } from "@apollo/client";

// utils
import { getData } from "../utils";

export const AppLayout = () => {
	const navigation = useNavigation();
	// const [user, setUser] = React.useState(null);

	React.useEffect(() => {
		// get user and redirect if logged in
		getData("@storage_Key").then((user) => {
			if (
				user === "null" ||
				user === null ||
				user === undefined ||
				!user.token
			) {
				return navigation.navigate("Auth");
			}
			// setUser(user);
		});
	}, []);

	// const [createDevice] = useMutation(gql(devicesGQL.createDevice), {
	// 	onCompleted: async (data) => {
	//
	// 		await storeData("@storage_Key", data.createDevice.owner);
	// 	},
	// 	onError: (error) => {
	//
	// 	},
	// });

	// const registerDevice = async (token) => {
	// 	if (!token) return;
	// 	let deviceTokens =
	// 		user && user.devices
	// 			? Array.from(new Set(user.devices.map((device) => device.token)))
	// 			: [];

	// 	// the device token is already in the users account

	//
	// 	if (deviceTokens.includes(token)) {
	//
	// 		return;
	// 	}

	// 	//the device needs to be added to the users account
	// 	createDevice({
	// 		variables: {
	// 			input: {
	// 				token: token,
	// 				owner: user.id,
	// 			},
	// 		},
	// 	});
	// };

	// useInterval(() => {
	// 	if (Platform.OS !== "web") {
	// 		registerForPushNotificationsAsync()
	// 			.then(async (token) => {
	// 				if (!user) return;
	// 				registerDevice(token, user);
	// 			})
	// 			.catch((error) =>
	// 	}
	// }, 30000);

	return (
		<>
			<NavigationContainer routes={mainNav} layoutName="app" />
		</>
	);
};

// async function registerForPushNotificationsAsync() {
// 	let token;
// 	if (Device.isDevice) {
// 		const { status: existingStatus } =
// 			await Notifications.getPermissionsAsync();
// 		let finalStatus = existingStatus;
// 		if (existingStatus !== "granted") {
// 			const { status } = await Notifications.requestPermissionsAsync();
// 			finalStatus = status;
// 		}
// 		if (finalStatus !== "granted") {
// 			alert("Failed to get push token for push notification!");
// 			return;
// 		}
// 		token = (await Notifications.getExpoPushTokenAsync()).data;
//
// 	} else {
// 		// alert("Must use physical device for Push Notifications");
// 	}

// 	if (Platform.OS === "android") {
// 		Notifications.setNotificationChannelAsync("default", {
// 			name: "default",
// 			importance: Notifications.AndroidImportance.MAX,
// 			vibrationPattern: [0, 250, 250, 250],
// 			lightColor: "#FF231F7C",
// 		});
// 	}

// 	return token;
// }
