import { useNavigation } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";
import axios from "axios";

// Containers

// Components
import { Alert } from "../../components/Alert";
import Badge from "../../components/Badge";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import { PageHeader } from "../../components/Header";
import {
	BillingItem,
	BillingTable,
	List,
	ListItem,
} from "../../components/List";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
import NoItems from "../../components/NoItems";
import OptionsMenu from "../../components/OptionsMenu";
import Tabs, { Tab } from "../../components/Tabs";
import User from "../../components/User";

// Screens
import {
	DailySheetSummary,
	ExpensesSummary,
	TimeEntriesSummary,
} from "./ProjectScreens";

// Containers

import { BillingPeriodSlideOver } from "../../containers/BillingPeriodSlideOver";
import { BillingPhaseSlideOver } from "../../containers/BillingPhaseSlideOver";
import { ExpenseSlideOver } from "../../containers/ExpenseSlideOver";
import { MultidayExpenseSlideOver } from "../../containers/MultidayExpenseSlideOver";
import { ProjectSlideOver } from "../../containers/ProjectSlideOver";
import { ProjectTeamMember } from "../../containers/ProjectTeamMember";
import { TimeEntrySlideover } from "../../containers/TimeEntrySlideOver";

// GraphQL

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as billingPeriodGQL from "../../graphql/clerk/billingPeriod";
import * as billingPhaseGQL from "../../graphql/clerk/billingPhase";
import * as projectGQL from "../../graphql/clerk/projects";
import * as timeEntryGQL from "../../graphql/timeEntry";

// Utils

import { API_URL, SentryLogger, getData } from "../../utils";

// Icons

import {
	faBriefcase,
	faClock,
	faExclamationTriangle,
	faFileExport,
	faFileInvoice,
	faFileSignature,
	faFolderUser,
	faHashtag,
	faInfoCircle,
	faMoneyCheckDollarPen,
	faPen,
	faPlus,
	faReceipt,
	faTrash,
	faUsers,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import Toast from "react-native-toast-message";

const getProjectRole = (role) => {
	if (role === null || role === undefined) {
		return "No Role";
	}
	if (typeof role === "string") {
		return role;
	}
	return role.name;
};

export function Project({ search, route }) {
	const tabs = [
		{
			name: "Overview",
			icon: faInfoCircle,
		},
		{
			name: "Time Entries",
			icon: faClock,
		},
		{
			name: "Daily Sheets",
			icon: faFileSignature,
		},
		{
			name: "Expenses",
			icon: faReceipt,
		},
		{
			name: "Team",
			icon: faUsers,
		},
	];

	const { id, billingPeriodID, billingPhaseID, title } = route.params;
	const navigation = useNavigation();
	const [project, setProject] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState(tabs[0].name);
	const [newItem, setNewItem] = React.useState(null);
	const [deleteItem, setDeleteItem] = React.useState(null);
	const [currentDailySheet, setCurrentDailySheet] = React.useState({});
	const [updateTM, setUpdateTM] = React.useState({});
	const [updateBillingPeriod, setUpdateBillingPeriod] = React.useState({});
	const [billingPeriod, setBillingPeriod] = React.useState(null);
	const [updateBillingPhase, setUpdateBillingPhase] = React.useState({});
	const [billingPhase, setBillingPhase] = React.useState(null);
	const [deleteTimeEntryData, setDeleteTimeEntryData] = React.useState(null);
	const [generating, setGenerating] = React.useState(false);
	const [user, setUser] = React.useState(null);

	React.useEffect(() => {
		const getLocalUser = async () => {
			getData("@storage_Key").then((res) => {
				setUser(res);
			});
		};
		getLocalUser();
	}, []);

	const { refetch } = useQuery(gql(projectGQL.getProject), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setProject(data.getProject);
		},

		fetchPolicy: "cache-and-network",
	});

	const [getBillingPeriod] = useLazyQuery(
		gql(billingPeriodGQL.getBillingPeriod),
		{
			variables: {
				id: billingPeriodID,
			},
			onCompleted: (data) => {
				setBillingPeriod(data.getBillingPeriod);
			},
		}
	);

	const [getBillingPhase] = useLazyQuery(gql(billingPhaseGQL.getBillingPhase), {
		onCompleted: (data) => {
			setBillingPhase(data.getBillingPhase);
		},
	});

	const [deleteProjectTeamMember] = useMutation(
		gql(projectGQL.deleteTeamMember),
		{
			onCompleted: () => {
				refetch();
			},
			onError: () => {
				Alert("Something Went Wrong", "Team member was not removed");
			},
		}
	);

	const [deleteTimeEntry] = useMutation(gql(timeEntryGQL.deleteTimeEntry), {
		onCompleted: () => {
			setDeleteTimeEntryData(null);
			refetch({
				variables: {
					id: project.id,
				},
			});
		},
		onError: () => {
			Alert("Error deleting time entry");
		},
		fetchPolicy: "cache-and-network",
	});

	const [deleteBillingPeriod] = useMutation(
		gql(billingPeriodGQL.deleteBillingPeriod),
		{
			onCompleted: () => {
				setNewItem(null);
				setDeleteItem(null);
				refetch();
			},
			onError: () => {
				Alert("We could not delete this billing period, please try again");
			},
			fetchPolicy: "cache-and-network",
		}
	);

	const [deleteBillingPhase] = useMutation(
		gql(billingPhaseGQL.deleteBillingPhase),
		{
			onCompleted: () => {
				setNewItem(null);
				setDeleteItem(null);
				refetch();
			},
			onError: () => {
				Alert("We could not delete this billing phase, please try again");
			},
			fetchPolicy: "network-only",
		}
	);

	const [deleteDailyDocument] = useMutation(
		gql(timeEntryGQL.deleteDailyDocument),
		{
			onCompleted: () => {
				setNewItem(null);
				setDeleteItem(null);
				setCurrentDailySheet(null);
				refetch();
			},
			onError: () => {
				Alert("We could not delete this expense type, please try again");
			},
		}
	);

	React.useEffect(() => {
		if (billingPeriodID) {
			getBillingPeriod({
				variables: {
					id: billingPeriodID,
				},
			});
		}
	}, [id, billingPeriodID]);

	if (!billingPeriodID && project !== null) {
		if (!project.usePhaseBilling) {
			tabs.push({
				name: "Billing Period",
				icon: faMoneyCheckDollarPen,
			});
		}
	}

	if (billingPeriodID) {
		// remove the overview tab
		tabs.shift();
	}

	React.useEffect(() => {
		if (billingPhaseID) {
			getBillingPhase({
				variables: {
					id: billingPhaseID,
				},
			});
		}
	}, [billingPhaseID]);

	if (!billingPhase && project !== null) {
		if (project.usePhaseBilling) {
			tabs.push({
				name: "Billing Phase",
				icon: faMoneyCheckDollarPen,
			});
		}
	}

	if (billingPhaseID) {
		// remove the overview tab
		tabs.shift();
	}

	const controller = new AbortController();
	const printInvoice = () => {
		setGenerating(true);
		fetch(`${API_URL}/billing/generate-invoice`, {
			method: "POST",
			body: JSON.stringify({
				id: project.id,
				billingPeriodID: billingPeriodID,
				billingPhaseID: billingPhaseID,
			}),
			signal: controller.signal,
		})
			.then((res) => {
				if (res.ok) {
					return res.json();
				} else {
					throw new Error("Error Generating Invoice");
				}
			})
			.then((data) => {
				if (data.url) {
					let link = document.createElement("a");
					link.setAttribute("target", "_blank");
					link.href = data.url;
					link.click();
				} else {
					Toast.show({
						text1: "Error Generating Invoice",
						type: "error",
					});
				}
				setGenerating(false);
			})
			.catch((error) => {
				if (error.name === "AbortError") {
					return Toast.show({
						text1: "Successfully Cancelled",
						type: "success",
					});
				}
				setGenerating(false);
				Toast.show({
					text1: "Error Generating Invoice",
					text2: error.message,
					type: "error",
				});
				SentryLogger(error);
			});
	};

	const cancelInvoiceGeneration = () => {
		setGenerating(false);
		controller.abort();
	};

	const getHeaderButtons = () => {
		switch (currentTab) {
			case "Time Entries":
				return [
					{
						icon: faFileInvoice,
						name: `Generate Invoice ${billingPeriodID ? "for Period" : ""}`,
						onPress: () => {
							if (!project.billingRate) {
								Toast.show({
									text1: "No Rate Sheet Attached",
									text2: "You must have a rate sheet assigned to the project",
									type: "error",
								});
							} else {
								printInvoice();
							}
						},
					},
					{
						icon: faPlus,
						name: "Add Time Entry",
						onPress: () => {
							setNewItem("newTimeEntry");
						},
					},
				];
			case "Team":
				return [
					{
						icon: faFileInvoice,
						name: `Generate Invoice ${billingPeriodID ? "for Period" : ""}`,
						onPress: () => {
							//
							if (!project.billingRate) {
								Toast.show({
									text1: "No Rate Sheet Attached",
									text2: "You must have a rate sheet assigned to the project",
									type: "error",
								});
							} else {
								printInvoice();
							}
						},
					},
					{
						icon: faPlus,
						name: "Add Team Member",
						onPress: () => {
							setNewItem("newTeamMember");
						},
					},
				];
			case "Billing Period":
				return [
					{
						icon: faFileInvoice,
						name: `Generate Invoice ${billingPeriodID ? "for Period" : ""}`,
						onPress: () => {
							if (!project.billingRate) {
								Toast.show({
									text1: "No Rate Sheet Attached",
									text2: "You must have a rate sheet assigned to the project",
									type: "error",
								});
							} else {
								printInvoice();
							}
						},
					},
					{
						icon: faPlus,
						name: "Add Billing Period",
						onPress: () => {
							setNewItem("createBillingPeriod");
						},
					},
				];
			case "Billing Phase":
				return [
					{
						icon: faFileInvoice,
						name: `Generate Invoice ${billingPeriodID ? "for Period" : ""}`,
						onPress: () => {
							if (!project.billingRate) {
								Toast.show({
									text1: "No Rate Sheet Attached",
									text2: "You must have a rate sheet assigned to the project",
									type: "error",
								});
							} else {
								printInvoice();
							}
						},
					},
					{
						icon: faPlus,
						name: "Add Billing Phase",
						onPress: () => {
							setNewItem("createBillingPhase");
						},
					},
				];
			default:
				return [
					{
						icon: faFileInvoice,
						name: `Generate Invoice ${billingPeriodID ? "for Period" : ""}`,
						onPress: () => {
							if (!project.billingRate) {
								Toast.show({
									text1: "No Rate Sheet Attached",
									text2: "You must have a rate sheet assigned to the project",
									type: "error",
								});
							} else {
								printInvoice();
							}
						},
					},
				];
		}
	};

	const getOptionsMenu = () => {
		return (
			<OptionsMenu
				options={[
					{
						icon: faPen,
						name: "Edit Project",
						onPress: () => {
							setNewItem("editProject");
						},
					},
					{
						icon: faPlus,
						name: "Create Expense",
						onPress: () => {
							setNewItem("createExpense");
						},
					},
					{
						icon: faPlus,
						name: "Create Multi-day Expense",
						onPress: () => {
							setNewItem("createMulti-dayExpense");
						},
					},
					{
						icon: faFileExport,
						name: "Export to SageIntacct",
						onPress: () => {
							setNewItem("createExpense");
						},
					},
					{
						icon: faFileExport,
						name: "Download Supporting Documents",
						onPress: () => {
							Toast.show({
								text1: "Downloading Supporting Documents",
								text2: "Please wait...",
								type: "info",
							});
							const requestData = {
								project: {
									id: id,
									title: project.title,
								},
							};
							axios({
								method: "post",
								url: `${API_URL}/billing/generate-supporting-docs`,
								data: requestData,
								responseType: "blob",
							})
								.then((response) => {
									if (response.status === 200) {
										return response.data;
									} else {
										Toast.show({
											text1: "Error downloading ZIP file:",
											text2: response.statusText,
											type: "error",
										});
									}
								})
								.then((blob) => {
									// Create a temporary URL for the blob data
									const blobUrl = window.URL.createObjectURL(blob);

									// Create a temporary link element
									const link = document.createElement("a");
									link.href = blobUrl;
									link.download = "Supporting Documents.zip"; // Set the download name
									link.click();

									// Clean up the URL and the link element
									window.URL.revokeObjectURL(blobUrl);
									link.remove();
								})
								.catch((error) => {
									Toast.show({
										text1: "Server Error",
										text2: error,
										type: "error",
									});
								});
						},
					},
					{
						icon: faUsers,
						name: "Bulk add Team Member",
						onPress: () => {
							// setShowBulkUsers(true);
						},
					},
					{
						icon: faFileExport,
						name: "Generate COW File (All Time Entries)",
						onPress: () => {
							fetch(`${API_URL}/billing/cow-file`, {
								method: "POST",
								body: JSON.stringify({
									project: {
										id: id,
										title: project.title,
									},
								}),
							})
								.then((res) => {
									return res.json();
								})
								.then((data) => {
									if (data.url) {
										Linking.openURL(data.url);
									} else {
										Alert("There was an error generating the cow file");
									}
								})
								.catch(() => {
									Alert("There was an error generating the sage file");
								});
						},
					},
				]}
			/>
		);
	};

	const getOverview = () => {
		if (!project) return null;
		let billing;

		if (!billingPhase) {
			billing = billingPeriod ? billingPeriod.billing : project.billing;
		} else {
			billing = billingPhase.billing;
		}
		return (
			<BillingTable header={["Category", "Subtotal", "MarkUp", "Total"]}>
				<BillingItem
					items={[
						"Labor",
						`$${billing.subTotalLabor}`,
						<View key={billing.laborMarkup + billing.catMarkup}>
							<Text
								style={{
									fontSize: 12,
								}}
							>
								Labor Markup ${billing.laborMarkup}
							</Text>
							<Text
								style={{
									fontSize: 12,
								}}
							>
								Cat Markup ${billing.catMarkup}
							</Text>
						</View>,

						`$${billing.totalLabor}`,
					]}
				/>

				{billing.categories.map((category, index) => {
					return (
						<BillingItem
							key={index}
							items={[
								category.name,

								`$${category.subTotal}`,

								`$${category.markup}`,

								`$${category.total}`,
							]}
						/>
					);
				})}

				<BillingItem items={["Total", " ", " ", `$${billing.total}`]} />
			</BillingTable>
		);
	};

	const navigateToBillingPeriod = (period) => {
		navigation.navigate("ClerkProject", {
			title: project.title,
			id: project.id,
			billingPeriodID: period.id,
		});
	};

	const navigateToBillingPhase = (phase) => {
		navigation.navigate("ClerkProjectPhase", {
			title: project.title,
			id: project.id,
			billingPhaseID: phase.id,
		});
	};

	if (!project) {
		return <LoadingScreen loadItem={title || "Project"} />;
	}

	return (
		<View className="h-full w-full">
			<Modal
				isOpen={generating}
				closeRequest={() => cancelInvoiceGeneration()}
				title={"Generating Invoice"}
			>
				<View className="flex-col items-center justify-center">
					<View className="flex flex-row items-center">
						<ActivityIndicator size="large" className="mr-2" />
						<Text className="text-gray-900 dark:text-white pt-2">
							The invoice is being generated. Please do not leave the screen.
							You may need to accept pop-up blockers once the invoice has been
							generated
						</Text>
					</View>
				</View>
			</Modal>

			<Breadcrumb baseLocation="Work In Progress">
				<Crumb name="Work In Progress" location="Work In Progress" />
				<Crumb
					name={project.title}
					location={billingPeriodID ? "ClerkProject" : "ClerkProjectPhase"}
					params={
						billingPeriodID
							? {
									id,
									billingPeriodID,
									title,
							  }
							: {
									id,
									billingPhaseID,
									title,
							  }
					}
				/>
				<>
					{billingPeriod && (
						<Crumb
							name={billingPeriod.name}
							location="Project"
							params={{
								id,
								billingPeriodID,
								billingPhaseID,
								title,
							}}
						/>
					)}
				</>
			</Breadcrumb>
			<PageHeader
				goBack={
					billingPeriodID
						? {
								location: "ClerkProject",

								params: {
									id,
									title,
								},
						  }
						: {
								location: "Work In Progress",
						  }
				}
				title={project.title}
				description={"Projects in billing process"}
				buttons={getHeaderButtons()}
				options={getOptionsMenu()}
				info={[
					{
						text: ` ${project.client ? project.client.name : "No Client"} (${
							project.client ? project.client.sageIntacct : "No Sage Intacct"
						})`,
						icon: faBriefcase,
						href: project.client ? `/clerk/clients/${project.client.id}` : null,
					},
					{
						text: `Sage Intacct: ${
							project.sageIntacct ? project.sageIntacct : "No SageIntacct"
						}`,
						icon: faHashtag,
					},
					{
						text: project.clerk
							? `${project.clerk.fullName}`
							: "No Clerk Assigned",
						icon: faFolderUser,
					},
				]}
			/>
			<Tabs>
				{tabs.map((tab) => (
					<Tab
						key={tab.name}
						name={tab.name}
						icon={tab.icon}
						onPress={() => setCurrentTab(tab.name)}
						current={currentTab === tab.name}
					/>
				))}
			</Tabs>

			{!project.billingRate && (
				<View className="w-full Text-2 flex items-center justify-center">
					<FontAwesomeIcon icon={faExclamationTriangle} color={"red"} />
					<Text className="ml-2 uppercase">
						No billing rate has been assigned to this project
					</Text>
				</View>
			)}

			{currentTab === "Overview" && <>{getOverview()}</>}

			{currentTab === "Expenses" && (
				<ExpensesSummary
					expenseTypes={
						project.billingRate ? project.billingRate.expenseTypes : []
					}
					project={project}
					billingPeriod={billingPeriod}
					billingPhase={billingPhase}
					user={user}
					refetch={() => refetch()}
				/>
			)}

			{currentTab === "Time Entries" && (
				<TimeEntriesSummary
					project={project}
					billingPeriod={billingPeriod}
					billingPhase={project}
					refetch={() => refetch()}
				/>
			)}

			{currentTab === "Daily Sheets" && (
				<DailySheetSummary
					dailySheetSummaries={project.dailyDocumentSummaries}
					project={project}
					billingPeriod={billingPeriod}
					billingPhase={billingPhase}
				/>
			)}

			{currentTab === "Team" && (
				<List
					title="Team Members"
					headers={["User", "Project Role", "Permissions", "Type", "Added"]}
					usesOptions
				>
					{project.team
						.filter((tm) => {
							let name = tm.teamMember
								? `${tm.teamMember.firstName}${tm.teamMember.lastName}`.toLocaleLowerCase()
								: "n/an/a";
							if (name.includes(search)) {
								return tm;
							}
						})
						.map((tm, index) => {
							let teamMember = tm.teamMember
								? tm.teamMember
								: { firstName: "N/A", lastName: "N/A", role: "Deleted User" };

							return (
								<ListItem
									key={index}
									smallView={
										<>
											<TouchableOpacity className="w-full rounded-lg shadow-sm border-2 border-gray-200 dark:border-indigo-200 p-2">
												<Text className=" font-bold text-md">
													{teamMember.firstName} {teamMember.lastName}
												</Text>
												<Text className="font-medium">
													Project Role: {""}{" "}
													<Text className="text-indigo-500">
														{getProjectRole(tm.projectRole)}
													</Text>
												</Text>
												<View className="flex flex-row pt-2 justify-between items-center">
													<View>
														<Text className="text-xs font-light text-gray-900 dark:text-white pb-2 ">
															Access Level
														</Text>
														<View className="flex flex-row">
															{tm.isSuperAdmin && (
																<Badge className={"mr-2"} text="Super Admin" />
															)}
															{tm.isAdmin && (
																<Badge className={"mr-2"} text="Admin" />
															)}
															{tm.isGeneral && (
																<Badge className={"mr-2"} text="General" />
															)}
															{tm.isReadOnly && (
																<Badge className={"mr-2"} text="Read Only" />
															)}
														</View>
													</View>
													<View className="">
														<Text className="text-xs font-light text-gray-900 dark:text-white text-right pb-2">
															User Type
														</Text>

														<View className="flex flex-row w-full">
															{teamMember.isTeamMember && (
																<Badge className="ml-2" text="Employee" />
															)}
															{teamMember.isContractor && (
																<Badge className="ml-2" text="Vendor" />
															)}
															{teamMember.client && (
																<Badge className="ml-2" text="Client" />
															)}
															{!teamMember.isTeamMember &&
																!teamMember.isContractor &&
																!teamMember.client && (
																	<Badge className="ml-2" text="Outside User" />
																)}
														</View>
													</View>

													<View className="">
														<Text className="text-xs font-light text-gray-900 dark:text-white text-right pb-2">
															Added:{" "}
															{DateTime.fromISO(tm.added).toFormat(
																"MMMM dd, yyyy"
															)}
														</Text>
													</View>
												</View>
											</TouchableOpacity>
										</>
									}
									items={[
										{
											content: <User user={teamMember} photoOnly={false} />,
										},
										{ content: getProjectRole(tm.projectRole) },
										{
											content: (
												<>
													{tm.isSuperAdmin && <Badge text="Super Admin" />}
													{tm.isAdmin && <Badge text="Admin" />}
													{tm.isGeneral && <Badge text="General" />}
													{tm.isReadOnly && <Badge text="Read Only" />}
												</>
											),
										},

										{
											content: (
												<>
													{teamMember.isTeamMember && <Badge text="Employee" />}
													{teamMember.isContractor && <Badge text="Vendor" />}
													{teamMember.client && <Badge text="Client" />}
													{!teamMember.isTeamMember &&
														!teamMember.isContractor &&
														!teamMember.client && <Badge text="Outside User" />}
												</>
											),
										},
										{
											content: DateTime.fromISO(tm.added).toFormat(
												"MMMM dd, yyyy"
											),
										},
									]}
									options={
										<>
											<OptionsMenu
												options={[
													{
														name: "Edit Team Member",
														icon: faPen,
														onPress: () => {
															setUpdateTM({
																id: tm.id,
																teamMember: tm.teamMember,
																projectRole: tm.projectRole,
															});
															setNewItem("edit-user");
														},
													},
													{
														name: "Remove Team Member",
														icon: faTrash,
														onPress: () => {
															// check if the user has anytime entries. If they do we prompt them to delete them first.

															let hasTimeEntries = false;

															project.timeEntries.forEach((te) => {
																if (
																	te.user &&
																	te.user.id === tm.teamMember.id
																) {
																	hasTimeEntries = true;
																}
															});

															if (hasTimeEntries) {
																return Alert(
																	"Cannot Remove Team Member",
																	"This team member has time entries. Please delete the user's time entries or move them to a different project first."
																);
															}

															deleteProjectTeamMember({
																variables: { input: { id: tm.id } },
															});
														},
													},
												]}
											/>
										</>
									}
								/>
							);
						})}
				</List>
			)}

			{currentTab === "Billing Period" && (
				<View className="px-1">
					{project.billingPeriods.length ? (
						<>
							<List
								title="Billing Periods"
								headers={["Name", "Start Date", "End Date", "Status"]}
								usesOptions
							>
								{project.billingPeriods.map((period, index) => {
									return (
										<ListItem
											key={index}
											items={[
												{
													onPress: () => navigateToBillingPeriod(period),
													content: period.name,
												},
												{
													onPress: () => navigateToBillingPeriod(period),
													content: period.startDate,
												},
												{
													onPress: () => navigateToBillingPeriod(period),
													content: period.endDate,
												},
												{
													onPress: () => navigateToBillingPeriod(period),
													content: period.status,
												},
											]}
											options={
												<OptionsMenu
													options={[
														{
															name: "Edit Billing Period",
															icon: faPen,
															onPress: () => {
																setNewItem("editBillingPeriod");
																setUpdateBillingPeriod(period);
															},
														},
														{
															name: "Delete Billing Period",
															icon: faTrash,
															onPress: () => {
																setNewItem("deleteBillingPeriod");
																setDeleteItem(period);
															},
														},
													]}
												/>
											}
										/>
									);
								})}
							</List>
						</>
					) : (
						<NoItems
							surTitle="No Billing Periods"
							title="Currently No Billing Periods"
							text="There are no billing periods tagged to this project yet. Billing periods allow you to break the project into smaller segments that can be billed to the client rather than one large bill at the end of the project."
						/>
					)}
				</View>
			)}

			{currentTab === "Billing Phase" && (
				<View className="px-1">
					{project.billingPhases && project.billingPhases.length ? (
						<>
							<List title="Billing Phases" headers={["Name"]} usesOptions>
								{[...project.billingPhases]
									.sort((a, b) => {
										if (a.name < b.name) return -1;
										if (a.name > b.name) return 1;
										return 0;
									})
									.map((phase, index) => {
										return (
											<ListItem
												key={index}
												items={[
													{
														onPress: () => navigateToBillingPhase(phase),
														content: phase.name,
													},
												]}
												options={
													<OptionsMenu
														options={[
															{
																name: "Edit Billing Phase",
																icon: faPen,
																onPress: () => {
																	setNewItem("editBillingPhase");
																	setUpdateBillingPhase(phase);
																},
															},
															{
																name: "Delete Billing Phase",
																icon: faTrash,
																onPress: () => {
																	setNewItem("deleteBillingPhase");
																	setDeleteItem(phase);
																},
															},
														]}
													/>
												}
											/>
										);
									})}
							</List>
						</>
					) : (
						<NoItems
							surTitle="No Billing Phases"
							title="Currently No Billing Phases"
							text="There are no billing phases tagged to this project yet. Billing phases allow you to break the project into smaller segments that can be billed to the client rather than one large bill at the end of the project."
						/>
					)}
				</View>
			)}

			<ExpenseSlideOver
				isOpen={newItem === "createExpense"}
				projectID={project.id}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				type="create"
				users={
					project &&
					project.team.map((tm) => {
						return tm.teamMember;
					})
				}
				isClerkingPage={true}
				currentProject={project}
				expenseTypes={
					project.billingRate ? [...project.billingRate.expenseTypes] : []
				}
			/>

			<MultidayExpenseSlideOver
				isOpen={newItem === "createMulti-dayExpense"}
				projectID={project.id}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				type="create"
				users={
					project &&
					project.team.map((tm) => {
						return tm.teamMember;
					})
				}
				isClerkingPage={true}
				currentProject={project}
				expenseTypes={
					project.billingRate ? [...project.billingRate.expenseTypes] : []
				}
			/>

			<ProjectSlideOver
				isOpen={newItem === "editProject"}
				closeRequest={() => {
					setNewItem(null);
				}}
				clerking
				type="update"
				onComplete={() => {
					refetch();
					setNewItem(null);
				}}
				currentProject={{
					id: project.id,
					title: project.title,
					stage: project.stage,
					sageIntacct: project.sageIntacct,
					number: project.number,
					isLargeLoss: project.isLargeLoss,
					isBilled: project.isBilled,
					isCAT: project.isCAT,
					usePhaseBilling: project.usePhaseBilling,
					unscheduledEquipmentCharge: project.unscheduledEquipmentCharge,
					perDiem: project.perDiem,
					client: project.client ? project.client.id : null,
					office: project.office ? project.office.id : null,
				}}
				defaultSelectValues={{
					stage: project.stage,
					office: project.office?.name,
					client: project.client?.name,
					location: project.location ? project.location.name : "",
					billingRate: project.billingRate ? project.billingRate.name : "",
					clerk: project.clerk ? project.clerk.fullName : "",
				}}
			/>

			<TimeEntrySlideover
				isOpen={newItem === "newTimeEntry"}
				currentTimeEntry={{ project: id }}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				closeRequest={() => {
					setNewItem(null);
				}}
				users={
					project
						? project.team
								.filter((tm) => tm.teamMember)
								.map((tm) => tm.teamMember)
						: []
				}
				type="create"
				clerking={true}
				currentProject={project}
			/>

			<ProjectTeamMember
				isOpen={newItem === "newTeamMember"}
				onClose={() => setNewItem(null)}
				type="create"
				onComplete={() => {
					refetch();
					setNewItem(null);
				}}
				project={project}
			/>

			<ProjectTeamMember
				isOpen={newItem === "edit-user"}
				onClose={() => setNewItem(null)}
				type="update"
				onComplete={() => {
					refetch();
					setNewItem(null);
				}}
				currentTeamMember={{
					id: updateTM.id,
					projectRole: updateTM.projectRole ? updateTM.projectRole.id : null,
				}}
				project={project}
				defaultSelectValues={{
					role: updateTM.projectRole ? updateTM.projectRole.name : null,
					user: updateTM.teamMember
						? `${updateTM.teamMember.firstName} ${updateTM.teamMember.lastName}`
						: null,
				}}
			/>

			<Modal
				isOpen={newItem === "deleteBillingPeriod"}
				title="Delete Billing Period"
				description={`Are you sure want to delete ${
					deleteItem && deleteItem.name
				}? You will need to add this billing period back if you wish to use it in the future`}
				rightButton={{
					text: "Delete Period",
					onPress: () => {
						deleteBillingPeriod({
							variables: {
								input: {
									id: deleteItem.id,
								},
							},
						});
					},
				}}
			/>

			<BillingPeriodSlideOver
				currentBillingPeriod={{
					project: project.id,
				}}
				isOpen={newItem === "createBillingPeriod"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				type="create"
			/>

			<BillingPeriodSlideOver
				isOpen={newItem === "editBillingPeriod"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					setUpdateBillingPeriod({});
					refetch();
				}}
				currentBillingPeriod={{
					id: updateBillingPeriod.id,
					name: updateBillingPeriod.name,
					startDate: updateBillingPeriod.startDate,
					endDate: updateBillingPeriod.endDate,
					status: updateBillingPeriod.status,
					project: project.id,
				}}
				type="update"
			/>

			<Modal
				isOpen={newItem === "deleteBillingPhase"}
				title="Delete Billing Phase"
				description={`Are you sure want to delete ${
					deleteItem && deleteItem.name
				}? You will need to add this billing phase back if you wish to use it in the future`}
				rightButton={{
					text: "Delete Phase",
					onPress: () => {
						deleteBillingPhase({
							variables: {
								input: {
									id: deleteItem.id,
								},
							},
						});
					},
				}}
			/>

			<BillingPhaseSlideOver
				currentBillingPhase={{
					project: project.id,
				}}
				isOpen={newItem === "createBillingPhase"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				type="create"
			/>

			<BillingPhaseSlideOver
				isOpen={newItem === "editBillingPhase"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					setUpdateBillingPhase({});
					refetch();
				}}
				currentBillingPhase={{
					id: updateBillingPhase.id,
					name: updateBillingPhase.name,
				}}
				type="update"
			/>

			<Modal
				title="Delete Time Entry"
				description="Are you sure you want to delete this time entry? This is permanent and cannot be undone and will also delete any Daily Sheet associated with this time entry."
				isOpen={!!deleteTimeEntryData}
				onClose={() => setNewItem(true)}
				rightButton={{
					text: "Delete Time Entry",
					onPress: () => {
						deleteTimeEntry({
							variables: {
								input: {
									id: deleteTimeEntryData.id,
								},
							},
						});
					},
				}}
			/>

			<Modal
				title="Delete Daily Sheet"
				description="Are you sure you want to delete this daily sheet? This is permanent and cannot be undone. The user will need to make a new daily sheet to replace this one."
				isOpen={newItem === "deleteDailySheet"}
				onClose={() => setNewItem(true)}
				rightButton={{
					text: "Delete Daily Sheet",
					onPress: () => {
						deleteDailyDocument({
							variables: {
								input: {
									id: currentDailySheet.id,
								},
							},
						});
					},
				}}
			/>
		</View>
	);
}

Project.propTypes = {
	search: PropTypes.string,
	route: PropTypes.object,
};
