import PropTypes from "prop-types";
import React, { useState } from "react";
import GooglePlacesAutocompleteWeb from "react-google-places-autocomplete";
import { Pressable, Text, View } from "react-native";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";

// Components
import { Alert } from "../components/Alert";
import { Input } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation } from "@apollo/client";
import * as locationGQL from "../graphql/admin/location";

// Icons
import { faBuilding } from "@fortawesome/pro-duotone-svg-icons";
import { Platform } from "react-native";

const LocationSlideOver = ({
	isOpen,
	closeRequest = () => {},
	type,
	onComplete,
	currentLocation,
}) => {
	const [location, setLocation] = useState({});
	const [manualEntry, setManualEntry] = useState(false);
	const [showSecondLine, setShowSecondLine] = useState(false);
	React.useEffect(() => {
		if (currentLocation) {
			setLocation(currentLocation);
		}
	}, [currentLocation]);

	/**
	 * @name createOffice
	 * @description creates a new office location and passes the data to getOfficeLocation
	 * @returns {void}
	 */

	const [createLocation] = useMutation(gql(locationGQL.createLocation), {
		onCompleted: (data) => {
			onComplete(data.createLocation);
			setLocation({});
		},
		onError: () => {
			Alert("Couldn't create office please try again");
		},
	});

	/**
	 * @name updateOffice
	 * @description updates the current office location then calls getOfficeLocation to list the locations
	 * @returns {void}
	 */

	const [updateLocation] = useMutation(gql(locationGQL.updateLocation), {
		onCompleted: (data) => {
			onComplete(data.updateLocation);
		},
		onError: () => {
			Alert("Couldn't update office please try again");
		},
	});

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "New Location" : "Edit Location"}
			description={
				type === "create"
					? "Create a new location"
					: "Edit location information"
			}
			closeRequest={() => {
				closeRequest();
			}}
			onSubmit={() => {
				if (type === "create") {
					createLocation({
						variables: {
							input: location,
						},
					});
				} else {
					updateLocation({
						variables: {
							input: location,
						},
					});
				}
			}}
		>
			<Input
				required
				name="project"
				label={"Location Name"}
				value={location.name}
				onChange={(e) => setLocation({ ...location, name: e })}
				icon={faBuilding}
				placeholder={"Location Name"}
			/>
			<Input
				name="SageIntacct"
				label="SageIntacct"
				value={location.sageIntacct}
				onChange={(e) =>
					setLocation({
						...location,
						sageIntacct: e,
					})
				}
				placeholder={"SageIntacct"}
			/>
			<View>
				<Text className="text-sm font-medium text-gray-900 dark:text-white">
					Address
				</Text>

				<Text className=" text-sm font-light text-gray-700 dark:text-white">
					{manualEntry
						? "Enter the address manually"
						: "Search for the address"}
				</Text>
			</View>

			<>
				{!manualEntry ? (
					<>
						{Platform.OS === "web" ? (
							<GooglePlacesAutocompleteWeb
								fetchDetails={true}
								apiKey="AIzaSyAZPJ0iPDp64ImrHANzq7DEQp4oCHYgVyA"
								autocompletionRequest={{ types: ["street_address", "premise"] }}
								selectProps={{
									placeholder: "Search",
									onChange: ({ value }) => {
										if (value.terms.length === 5) {
											setLocation({
												...location,
												lineOne: `${value.terms[0].value} ${value.terms[1].value}`,
												city: value.terms[2].value,
												state: value.terms[3].value,
												zip: value.terms[4].value,
											});
											setShowSecondLine(true);
										}
									},
								}}
							/>
						) : (
							<GooglePlacesAutocomplete
								placeholder="Search"
								query={{
									key: "AIzaSyAZPJ0iPDp64ImrHANzq7DEQp4oCHYgVyA",
									language: "en",
								}}
								requestUrl={{
									useOnPlatform: "web", // or "all"
									url: "https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api", // or any proxy server that hits https://maps.googleapis.com/maps/api
								}}
							/>
						)}
					</>
				) : (
					<>
						<Input
							required
							name="lineOne"
							label="Line One"
							value={location.lineOne}
							onChange={(e) =>
								setLocation({
									...location,
									lineOne: e,
								})
							}
							placeholder={"Line One"}
						/>
						<Input
							name="lineTwo"
							label="Line Two"
							value={location.lineTwo}
							onChange={(e) =>
								setLocation({
									...location,
									lineTwo: e,
								})
							}
							placeholder={"Line Two"}
						/>
						<Input
							required
							name="city"
							label="City"
							value={location.city}
							onChange={(e) =>
								setLocation({
									...location,
									city: e,
								})
							}
							placeholder={"City"}
						/>
						<Input
							required
							name="state"
							label="State"
							value={location.state}
							onChange={(e) =>
								setLocation({
									...location,
									state: e,
								})
							}
							placeholder={"State"}
						/>
						<Input
							required
							name="zip"
							label="Zip"
							value={location.zip}
							onChange={(e) =>
								setLocation({
									...location,
									zip: e,
								})
							}
							placeholder={"Zip"}
						/>
					</>
				)}
				{showSecondLine && !manualEntry ? (
					<Input
						name="lineTwo"
						label="Line Two"
						value={location.lineTwo}
						onChange={(e) =>
							setLocation({
								...location,
								lineTwo: e,
							})
						}
						placeholder={"Line Two"}
					/>
				) : null}

				<Pressable
					onPress={() => {
						setManualEntry(!manualEntry);
					}}
				>
					<Text className="text-sm font-medium text-gray-900 dark:text-white">
						{manualEntry ? "Use Google Places" : "Enter Manually"}
					</Text>
				</Pressable>
			</>
		</SlideOver>
	);
};

LocationSlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	currentLocation: PropTypes.object,
};

export { LocationSlideOver };
