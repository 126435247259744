import { A } from "@expo/html-elements";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
	KeyboardAvoidingView,
	Modal,
	ScrollView,
	Text,
	TouchableOpacity,
	View,
} from "react-native";

// icons
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

export default function SlideOver({
	isOpen,
	name,
	description,
	headerView,
	children,
	closeRequest,
	buttonRight = "Save",
	buttonRightDisabled = false,
	onSubmit,
	learnMore,
	disableScroll = false,
}) {
	const [open, setOpen] = useState(isOpen);

	React.useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	React.useEffect(() => {
		if (open === false) {
			closeRequest();
		}
		// eslint-disable-next-line
	}, [open]);

	return (
		<View className="">
			<Modal
				animationType="slide"
				transparent={true}
				visible={open}
				onRequestClose={() => {
					setOpen(false);
				}}
				testID="slideover"
			>
				<ScrollView
					className="h-full bg-transparent"
					scrollEnabled={!disableScroll}
				>
					<View className="h-full w-full absolute -z-10 bg-gray-500 opacity-50" />
					<View className=" h-full right-0 flex items-end bg-transparent">
						<View
							className={
								"bg-white dark:bg-gray-800 w-full sm:w-4/6 md:w-2/6 lg:w3/6"
							}
						>
							<ScrollView className="h-screen">
								{/* Heading of Slideover */}
								<View className="py-6 px-4 bg-indigo-700 sm:px-6">
									<View className="flex flex-row items-center justify-between">
										<Text className="text-lg font-medium text-white">
											{name}
										</Text>
										<View className="ml-3 h-7 pt-4 flex items-center">
											<TouchableOpacity onPress={() => setOpen(false)}>
												<FontAwesomeIcon
													icon={faTimes}
													color="white"
													size={25}
												/>
											</TouchableOpacity>
										</View>
									</View>
									<View className="mt-1">
										<Text className="text-sm text-indigo-300">
											{description}
										</Text>
									</View>

									<View className="mt-1">{headerView}</View>
								</View>
								{/* Content */}
								<KeyboardAvoidingView
									behavior="padding"
									className="flex-1 flex flex-col justify-between "
								>
									<View className="px-4 divide-y divide-gray-200 sm:px-6">
										<View className="space-y-6 pt-6 pb-5 w-full">
											{children}
										</View>
										<View className="pt-4 pb-6">
											{learnMore && (
												<View className="mt-4 flex text-sm">
													<A
														href={learnMore.href}
														target="_blank"
														rel="noreferrer"
														className="group inline-flex items-center text-gray-500 hover:text-gray-900"
													>
														Learn more about sharing
													</A>
												</View>
											)}
										</View>
									</View>
								</KeyboardAvoidingView>
								{/* Buttons */}
								<View className="px-4 py-4 pb-4 flex flex-row justify-end w-full">
									<TouchableOpacity
										testID="cancel-button"
										type="button"
										className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm  hover:bg-gray-50 dark:bg-gray-900 dark:hover:gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										onPress={() => setOpen(false)}
									>
										<Text className="text-sm font-medium text-gray-700">
											Cancel
										</Text>
									</TouchableOpacity>
									<TouchableOpacity
										testID="submit-button"
										onPress={(e) => (!buttonRightDisabled ? onSubmit(e) : null)}
										className={`ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm rounded-md ${
											buttonRightDisabled
												? "bg-gray-400 hover:bg-gray-500"
												: "bg-indigo-600 hover:bg-indigo-700"
										}  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
									>
										<Text
											testID="submit-button-text"
											className="text-sm font-medium text-white"
										>
											{buttonRight}
										</Text>
									</TouchableOpacity>
								</View>
							</ScrollView>
						</View>
					</View>
				</ScrollView>
			</Modal>
		</View>
	);
}

SlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	headerView: PropTypes.any,
	description: PropTypes.any,
	closeRequest: PropTypes.func.isRequired,
	children: PropTypes.node,
	buttonRightDisabled: PropTypes.bool,
	buttonRight: PropTypes.string,
	onSubmit: PropTypes.func.isRequired,
	learnMore: PropTypes.string,
	disableScroll: PropTypes.bool,
};
