/* This example requires Tailwind CSS v2.0+ */

import * as Haptics from "expo-haptics";
import PropTypes from "prop-types";
import React from "react";
import { Modal, Platform, Text, TouchableOpacity, View } from "react-native";

// Icons
import { faEllipsisVertical, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

export default function OptionsView({ options }) {
	const [open, setOpen] = React.useState(false);
	// const colorScheme = useColorScheme();

	React.useEffect(() => {
		if (open) {
			const close = () => {
				setOpen(false);
				Platform.OS === "web" && document.removeEventListener("click", close);
			};
			Platform.OS === "web" && document.addEventListener("click", close);
		}
	}, [open]);

	const handleButtonClick = (option) => {
		if (option.onPress) {
			option.onPress();
		}
		setOpen(false);
	};

	return (
		<View
			as="div"
			className="ml-3  mt-2 abs inline-block align-baseline text-left"
		>
			<>
				<View>
					<TouchableOpacity
						onPress={() => {
							Platform.OS !== "web" && Haptics.selectionAsync();
							setOpen(!open);
						}}
						className=" text-gray-400 hover:text-gray-600  focus:outline-none "
					>
						<FontAwesomeIcon
							icon={faEllipsisVertical}
							aria-hidden="true"
							color={"blue"}
						/>
					</TouchableOpacity>
				</View>

				<Modal
					visible={open}
					onBackdropPress={() => setOpen(false)}
					transparent={true}
					animationIn="fadeIn"
					animationOut="fadeOut"
					animationInTiming={500}
					animationOutTiming={500}
					backdropTransitionInTiming={500}
				>
					<View className="h-full w-full bg-gray-900 opacity-75"></View>
					<View className="absolute bottom-0 right-0 flex flex-col items-center  justify-center w-full min-h-52">
						<View className="bg-white dark:bg-gray-800 rounded-t-lg p-4 w-full h-full">
							<View className="bg-white p-4">
								<View className="mb-2 flex-row justify-between border-gray-500 bottom-b-2">
									<Text className="text-xl font-bold">Options</Text>
									<TouchableOpacity onPress={() => setOpen(false)}>
										<FontAwesomeIcon icon={faTimes} size={20} color="black" />
									</TouchableOpacity>
								</View>

								{options && (
									<View
										className=" pb-4 pt-2 pr-2 flex flex-row flex-wrap items-center "
										horizontal
										showsHorizontalScrollIndicator={false}
									>
										{options.map((option, optionIndex) => (
											<TouchableOpacity
												key={optionIndex}
												onPress={() => handleButtonClick(option)}
												className="mr-2 p-3 mt-2 flex flex-row items-center justify-center bg-gray-200 rounded-md shadow-sm"
											>
												{option.icon && (
													<View className="mr-1">
														<FontAwesomeIcon
															icon={option.icon}
															size={18}
															color="blue"
														/>
													</View>
												)}
												<Text className="text-blue-500 font-medium">
													{option.name}
												</Text>
											</TouchableOpacity>
										))}
									</View>
								)}
							</View>
						</View>
					</View>
				</Modal>
			</>
		</View>
	);
}

OptionsView.propTypes = {
	children: PropTypes.node,
	options: PropTypes.array,
};
