export const getUser = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isClerk
			isHR
			isTeamMember
			isContractor
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isLogistic
			isActive
			isSafetyUser
			isSalary
			subAdmin
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			currentYearMaxAllotment
			companyStartDate
			subcontractor {
				id
				name
			}
			isManager
			manager {
				id
				firstName
				lastName
				profilePicture
				role
			}
			isPayrollAdmin
			isTimeAdmin
			location {
				id
				name
			}
			apsEmployeeID
			devices {
				token
			}
			mfaDevices {
				name
				method
				device
			}
			mfaDevices {
				name
				method
				device
			}
			currentTimeEntry {
				id
				project {
					isLargeLoss
				}
			}
		}
	}
`;

export const updateUser = /* GraphQL */ `
	mutation UpdateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isClerk
			isHR
			isTeamMember
			isContractor
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isSafetyUser
			isLogistic
			isActive
			isSalary
			subAdmin
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			currentYearMaxAllotment
			companyStartDate
			subcontractor {
				id
				name
			}
			isManager
			manager {
				id
				firstName
				lastName
				profilePicture
				role
			}
			isPayrollAdmin
			isTimeAdmin
			location {
				id
				name
			}
			apsEmployeeID
			token
		}
	}
`;

export const getProfileInfo = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isClerk
			isHR
			isTeamMember
			isContractor
			isLogistic
			isSafetyUser
			isActive
			isSalary
			subAdmin
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			currentYearMaxAllotment
			companyStartDate
			subcontractor {
				id
				name
			}
			dailyDocuments {
				id
				date
				timeIn
				timeOut
				project {
					id
					title
				}
			}

			mfaDevices {
				id
				name
				device
				method
			}
			mfaDevices {
				id
				name
				device
				method
			}
		}
	}
`;

export const getUserandProjects = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isClerk
			isHR
			isTeamMember
			isContractor
			isLogistic
			isActive
			isSafetyUser
			isSalary
			subAdmin
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			currentYearMaxAllotment
			companyStartDate
			isManager
			manager {
				id
			}
			projects {
				id
				project {
					id
					title
					stage
					workspace {
						id
						title
					}
				}
				isActive
			}
		}
	}
`;
export const listUsers = /* GraphQL */ `
	query ListUsers(
		$filter: TableUserFilterInput
		$sort: UserSortInput
		$limit: Int
		$nextToken: String
	) {
		listUsers(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
			sort: $sort
		) {
			items {
				id
				firstName
				lastName
				fullName
				profilePicture
				email
				phone
				bio
				role
				companyStartDate
				isTeamMember
				isContractor
			}
			nextToken
		}
	}
`;

export const generatePasswordResetToken = /* GraphQL */ `
	mutation GeneratePasswordResetToken($email: String!) {
		generatePasswordResetToken(email: $email) {
			id
			email
			passwordResetToken
		}
	}
`;

export const resetPassword = /* GraphQL */ `
	mutation ResetPassword($email: String!, $code: String!, $password: String!) {
		resetPassword(email: $email, code: $code, password: $password) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isClerk
			isHR
			isTeamMember
			isContractor
			isPayrollAdmin
			isTimeOverrider
			isTimeAdmin
			isEquipmentManager
			isLogistic
			isActive
			isSalary
			isSuperClerk
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			currentYearMaxAllotment
			companyStartDate
			subcontractor {
				id
				name
			}
			isManager
			manager {
				id
				firstName
				lastName
				profilePicture
				role
			}
			isPayrollAdmin
			isTimeAdmin
			location {
				id
				name
			}
			apsEmployeeID
			token
		}
	}
`;

export const login = /* GraphQL */ `
	query Login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isClerk
			isHR
			isTeamMember
			isContractor
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isLogistic
			isActive
			isSafetyUser
			isSalary
			isSuperClerk
			subAdmin
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			currentYearMaxAllotment
			companyStartDate
			subcontractor {
				id
				name
			}
			isManager
			manager {
				id
				firstName
				lastName
				profilePicture
				role
			}
			isPayrollAdmin
			isTimeAdmin
			location {
				id
				name
			}
			apsEmployeeID
			mfaDevices {
				device
				method
				name
			}
			token
		}
	}
`;

export const checkActiveStatus = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			isActive
		}
	}
`;

export const createUser = /* GraphQL */ `
	mutation CreateUser($input: CreateUserInput!) {
		createUser(input: $input) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isClerk
			isHR
			isTeamMember
			isContractor
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isSafetyUser
			isLogistic
			isActive
			isSalary
			subAdmin
			linkExpiration
			canReviewReports
			department
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			currentYearMaxAllotment
			companyStartDate
			subcontractor {
				id
				name
			}
			isManager
			manager {
				id
				firstName
				lastName
				profilePicture
				role
			}
			isPayrollAdmin
			isTimeAdmin
			location {
				id
				name
			}
			apsEmployeeID
		}
	}
`;

export const createLocation = /* GraphQL */ `
	mutation CreateLocation($input: CreateLocationInput!) {
		createLocation(input: $input) {
			id
		}
	}
`;

export const getUserPTO = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			firstName
			lastName
			profilePicture
			email
			phone
			pto {
				id
				date
				hours
				approved
			}
			ptoAdditions {
				id
				date
				amount
			}
			manager {
				id
				firstName
				lastName
				profilePicture
				role
			}
		}
	}
`;
