import { useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Text, View } from "react-native";

// Components

import { Alert } from "../../components/Alert";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import Button from "../../components/Button";
import { Input, Select } from "../../components/Forms";
import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List";
import LoadingScreen from "../../components/LoadingScreen";
import Pagination from "../../components/Pagination";
import SlideOver from "../../components/Slideover";
import Tabs, { Tab } from "../../components/Tabs";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import {
	faFileInvoice,
	faPlus,
	faUser,
} from "@fortawesome/pro-duotone-svg-icons";
import * as subcontractorGQL from "../../graphql/admin/subcontractors";
import * as rateSheetGQL from "../../graphql/rateSheet";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export const SubcontractorScreen = ({}) => {
	const navigation = useNavigation();
	let [newItem, setNewItem] = useState(null);
	let [subcontractors, setSubcontractors] = useState(false);
	let [newSubcontractor, setNewSubcontractor] = useState(null);
	let itemsPerPage = 20;
	let [currentPage, setCurrentPage] = useState(1);
	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end =
		currentPage * itemsPerPage > subcontractors.length
			? subcontractors.length
			: currentPage * itemsPerPage;
	const { refetch: subcontractorsRefetch } = useQuery(
		gql(subcontractorGQL.listSubcontractors),
		{
			variables: {
				limit: 100,
			},
			onCompleted: (data) => {
				let items = data.listSubcontractors.items;
				setSubcontractors([...items]);
			},
		}
	);

	const [createSubcontractor] = useMutation(
		gql(subcontractorGQL.createSubcontractor),
		{
			onCompleted: (data) => {
				setNewItem(null);
				setNewSubcontractor(data.createSubcontractor);

				subcontractorsRefetch();
			},
			onError: () => {
				Alert("Couldn't create subcontractor please try again");
			},
		}
	);
	const navigateToSubcontractor = (id) => {
		navigation.navigate("Subcontractor", { id: id });
	};

	return (
		<View className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
			{/* Page title & actions */}
			<PageHeader
				openMenu
				title="Subcontractors"
				buttons={[
					{
						name: "New Subcontractor",
						icon: faPlus,
						onPress: () => {
							setNewItem("subcontractors");
						},
					},
				]}
			/>
			<Breadcrumb className="mt-2">
				<Crumb name={"Subcontractors"} location={"Subcontractors"} />
			</Breadcrumb>
			{/* Subcontractors List */}

			<List name={"Subcontractors"} headers={["Subcontractors"]}>
				{subcontractors &&
					subcontractors.map((subcontractor) => {
						return (
							<ListItem
								key={subcontractor.id}
								items={[
									{
										onPress: () => {
											navigateToSubcontractor(subcontractor.id);
										},
										content: (
											<View className="flex flex-row items-center truncate space-x-3 bg-white dark:bg-gray-900">
												<View
													className={classNames(
														"w-2.5 h-2.5 flex-shrink-0 rounded-full"
													)}
													aria-hidden="true"
												/>
												<Text className="font-medium truncate text-sm leading-6  text-gray-900 dark:text-white">
													{subcontractor.name}
												</Text>
											</View>
										),
									},
								]}
							/>
						);
					})}
			</List>

			<SlideOver
				closeRequest={() => {
					setNewItem(null);
					setNewSubcontractor({});
				}}
				isOpen={newItem === "subcontractors"}
				name={"New Subcontractor"}
				description={"Add a new subcontractor"}
				onSubmit={() => {
					createSubcontractor({
						variables: {
							input: newSubcontractor,
						},
					});
				}}
			>
				<Input
					label="Name"
					onChange={(e) =>
						setNewSubcontractor({ ...newSubcontractor, name: e })
					}
				/>
			</SlideOver>
			<Pagination
				start={start}
				stop={end}
				total={subcontractors.length}
				nextClicked={() => setCurrentPage(currentPage + 1)}
				previousClicked={() => setCurrentPage(currentPage - 1)}
			/>
		</View>
	);
};

export const SingleSubcontractorScreen = ({ route }) => {
	const { id } = route.params;
	let [newItem, setNewItem] = useState(null);
	let [subcontractor, setSubcontractor] = useState(false);
	let [updatedSubcontractor, setUpdatedSubcontractor] = useState({});
	let [currentTab, setCurrentTab] = React.useState("Users");
	let [projectRoles, setProjectRoles] = useState(null);
	let [users] = useState(null);
	let [rateSheets, setRateSheets] = useState(false);
	let [rateSheet, setRateSheet] = useState({});
	const [rateSheetFields, setRateSheetFields] = React.useState([
		{ projectRole: null, price: 0 },
	]);
	let tabs = [
		{ name: "Users", href: "#", icon: faUser },
		{ name: "Rate Sheets", href: "#", icon: faFileInvoice },
	];

	const { loading: subcontractorLoading } = useQuery(
		gql(subcontractorGQL.getSubcontractor),
		{
			variables: {
				id: id,
			},
			onCompleted: (data) => {
				setSubcontractor(data.getSubcontractor);
			},
		}
	);

	const [createRateField] = useMutation(gql(rateSheetGQL.createRateField), {
		onCompleted: () => {},
		onError: () => {
			setNewItem(null);
		},
	});

	const [createRateSheet] = useMutation(gql(rateSheetGQL.createRateSheet), {
		onCompleted: () => {
			createRateField(createRateSheet.id);
		},
		onError: () => {
			Alert("There was an error creating the rate sheet. Please try again");
		},
	});
	const [updateSubcontractor] = useMutation(
		gql(subcontractorGQL.updateSubcontractor),
		{
			onCompleted: (data) => {
				setSubcontractor(data.updateSubcontractor);
				setNewItem(null);
			},
			onError: () => {
				Alert("There was an error updating the subcontractor's information.");
			},
		}
	);
	/**
	 * @name listProjectRoles
	 * @description gets all the project roles from the database
	 * @returns {void}
	 * @author Connor Sampson <csampson@srpenvironmental.net>
	 * @date 2022-02-22
	 */

	useQuery(gql(subcontractorGQL.listProjectRoles), {
		variables: {
			limit: 100,
		},
		onCompleted: (data) => {
			let items = data.listProjectRoles.items;
			setProjectRoles([...items]);
		},
	});

	useQuery(gql(rateSheetGQL.listRateSheets), {
		variables: {
			limit: 100,
			filter: {
				subcontractor: { eq: id },
			},
		},
		onCompleted: (data) => {
			let items = data.listRateSheets.items;
			setRateSheets([...items]);
		},
	});

	const handleButtonClick = () => {
		switch (currentTab) {
			case "Users":
				setNewItem("users");
				break;
			case "Rate Sheets":
				setNewItem("rate sheets");
				break;

			default:
				break;
		}
	};

	if (subcontractorLoading) {
		return <LoadingScreen loadItem={"Subcontractor"} />;
	}

	return (
		<View className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
			{/* Page title & actions */}
			<PageHeader
				title={subcontractor.name}
				buttons={[
					{
						name: "Update Subcontractor",

						onPress: () => {
							handleButtonClick();
							setNewItem("update subcontractors");
							setUpdatedSubcontractor({
								id: subcontractor.id,
								name: subcontractor.name,
							});
						},
					},
				]}
			/>
			<Breadcrumb className="mt-2">
				<Crumb name={"Subcontractors"} location={"Subcontractors"} />
				<Crumb
					name={subcontractor.name}
					location={"Subcontractor"}
					params={{ id: subcontractor.id }}
				/>
			</Breadcrumb>
			<Tabs>
				{tabs.map((tab) => {
					return (
						<Tab
							key={tab.name}
							name={tab.name}
							current={currentTab === tab.name ? true : false}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			<>
				{currentTab === "Rate Sheets" && (
					<View className="px-2 pt-2">
						<List headers={["Rate Sheets"]}>
							{rateSheets &&
								rateSheets

									// .sort((a, b) => {
									//   if (a.name > b.name) return 1;
									//   if (a.name < b.name) return -1;
									//   return 0;
									// })

									.map((rateSheet) => {
										return (
											<ListItem
												key={rateSheet.id}
												items={[
													{
														href: `/admin/subcontractors/${subcontractor.id}/ratesheet/${rateSheet.id}`,
														content: (
															<View className="flex items-center truncate space-x-3">
																<View
																	className={classNames(
																		rateSheet.active
																			? "bg-indigo-500 "
																			: "bg-gray-200",
																		"w-2.5 h-2.5 flex-shrink-0 rounded-full"
																	)}
																	aria-hidden="true"
																/>
																<Text className="font-medium truncate text-sm leading-6">
																	{rateSheet.name}
																</Text>
															</View>
														),
													},
												]}
											/>
										);
									})}
						</List>
					</View>
				)}
				{currentTab === "Users" && (
					<View className="px-2 pt-2">
						<List headers={["Users"]}>
							{users &&
								users.map((user) => {
									return (
										<ListItem
											key={user.id}
											items={[
												{
													href: `/admin/subcontractors/${subcontractor.id}/user/${user.id}`,
													content: (
														<View className="flex items-center truncate space-x-3">
															<View
																className={classNames(
																	user.active
																		? "bg-indigo-500 "
																		: "bg-gray-200",
																	"w-2.5 h-2.5 flex-shrink-0 rounded-full"
																)}
																aria-hidden="true"
															/>
															<Text className="font-medium truncate text-sm leading-6">
																{user.name}
															</Text>
														</View>
													),
												},
											]}
										/>
									);
								})}
						</List>
					</View>
				)}
			</>
			{/* Rate Sheet SlideOver */}
			<SlideOver
				closeRequest={() => {
					setNewItem(null);
				}}
				isOpen={newItem === "rate sheets"}
				name={"Rate Sheets"}
				description={"Rate Sheets"}
				onSubmit={() => {
					if (!rateSheet.name) {
						return Alert("Please enter a name for the rate sheet.");
					}
					let rateSheetFieldErrors = rateSheetFields.filter(
						(field) => field.projectRole === null
					);
					if (rateSheetFieldErrors.length > 0) {
						return Alert(
							"Please select a project role for each rate sheet field."
						);
					}
					createRateSheet({
						variables: {
							name: rateSheet.name,
							subcontractor: id,
							active: true,
							date: DateTime.now().toISO(),
							price: 0,
						},
					});
				}}
			>
				<Input
					label="Rate Sheet Name"
					onChange={(e) => {
						setRateSheet({ name: e });
					}}
				/>

				{rateSheetFields.map((rateSheetField, index) => {
					return (
						<>
							<View className="grid grid-cols-12 gap-2">
								<View className="col-span-12 sm:col-span-6 ">
									<Select
										name="Project Role"
										onChange={(value) => {
											let newFields = rateSheetFields;
											newFields[index].projectRole = value.value;
											setRateSheetFields(newFields);
										}}
										options={
											projectRoles
												? projectRoles
														.sort((a, b) => {
															if (a.name > b.name) return 1;
															if (a.name < b.name) return -1;
															return 0;
														})
														.map((projectRoles) => {
															return {
																name: projectRoles.name,
																value: projectRoles.id,
															};
														})
												: [{ name: "Loading...", disabled: true }]
										}
									/>
								</View>
								<View className="col-span-12 sm:col-span-6 ">
									<Input
										label="Price"
										onChange={(e) => {
											let newFields = rateSheetFields;
											newFields[index].price = e;
											setRateSheetFields(newFields);
										}}
									/>
								</View>
							</View>
							<Text
								className="text-red-500 hover:text-red-600 cursor-pointer text-sm text-right"
								onPress={() => {
									let newFields = rateSheetFields;
									newFields.splice(index, 1);
									setRateSheetFields(newFields);
								}}
							>
								Delete Rate Field
							</Text>
						</>
					);
				})}

				<Button
					text="Add New Field"
					onPress={() => {
						setRateSheetFields([
							...rateSheetFields,
							{ projectRole: null, price: 0 },
						]);
					}}
				/>
			</SlideOver>

			<SlideOver
				closeRequest={() => {
					setNewItem(null);
				}}
				isOpen={newItem === "users"}
				name={"Users"}
				description={"Add New User"}
				onSubmit={(e) => {
					setRateSheetFields(e);
				}}
			></SlideOver>
			{/* Update Subcontractor SlideOver */}
			<SlideOver
				closeRequest={() => {
					setNewItem(null);
				}}
				isOpen={newItem === "update subcontractors"}
				name={"Update Subcontractor"}
				description={"Update subcontractor"}
				onSubmit={() => {
					updateSubcontractor({
						variables: {
							input: updatedSubcontractor,
						},
					});
				}}
			>
				<Input
					label="Name"
					value={updatedSubcontractor.name}
					onChange={(e) =>
						setUpdatedSubcontractor({
							...updatedSubcontractor,
							name: e,
						})
					}
				/>
			</SlideOver>
		</View>
	);
};

SingleSubcontractorScreen.propTypes = {
	route: PropTypes.object,
};
