import React from "react";
import { View } from "react-native";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";

// Components
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List";
import NoItems from "../../components/NoItems";
import Pagination from "../../components/Pagination";
import LoadingScreen from "../../components/LoadingScreen";
import OptionsMenu from "../../components/OptionsMenu";
import Tabs, { Tab } from "../../components/Tabs";

// Containers
import { ProjectSlideOver } from "../../containers/ProjectSlideOver";
import RateSheetContainer from "../../containers/RateSheetContainer";
import { ClientSlideOver } from "../../containers/ClientSlideOver";

// GraphQL
import { gql, useQuery } from "@apollo/client";
import * as clientGQL from "../../graphql/clerk/clients";

// Icons
import {
	faPen,
	faInfoCircle,
	faReceipt,
	faUsers,
	faHashtag,
	faPlus,
	faBuilding,
	faUserTie,
} from "@fortawesome/pro-duotone-svg-icons";

export const Clients = () => {
	const navigation = useNavigation();
	const [clients, setClients] = React.useState([]);
	const itemsPerPage = 30;
	let [currentPage, setCurrentPage] = React.useState(1);
	const [search, setSearch] = React.useState("");

	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end = clients
		? currentPage * itemsPerPage >
		  clients.filter((client) =>
				client.name.toLowerCase().includes(search.toLowerCase())
		  ).length
			? clients.filter((client) =>
					client.name.toLowerCase().includes(search.toLowerCase())
			  ).length
			: currentPage * itemsPerPage
		: 0;

	useQuery(gql(clientGQL.listClients), {
		onCompleted: (data) => {
			setClients([...data.listClients.items]);
		},
	});

	const navigateToClient = (client) => {
		navigation.navigate("Client", {
			id: client.id,
			name: client.name,
		});
	};

	return (
		<View>
			<Breadcrumb baseLocation="Work In Progress">
				<Crumb name="Clients" location="Clients" />
			</Breadcrumb>
			<PageHeader title="Clients" openMenu />
			{clients.length ? (
				<List
					headers={["Name"]}
					onSearch={(value) => {
						setSearch(value);
					}}
				>
					{clients
						.filter((client) =>
							client.name.toLowerCase().includes(search.toLowerCase())
						)
						.sort((a, b) => {
							return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
						})
						.map((client, index) => {
							if (index + 1 >= start && index < end) {
								return (
									<ListItem
										key={index}
										items={[
											{
												content: client.name,
												onPress: () => navigateToClient(client),
											},
										]}
									/>
								);
							}
						})}
				</List>
			) : (
				<NoItems surTitle="No Clients Found" title="No Clients" />
			)}

			<Pagination
				start={start}
				stop={end}
				total={
					clients.filter((client) =>
						client.name.toLowerCase().includes(search.toLowerCase())
					).length
				}
				nextClicked={() => setCurrentPage(currentPage + 1)}
				previousClicked={() => setCurrentPage(currentPage - 1)}
			/>
		</View>
	);
};

Clients.propTypes = {
	search: PropTypes.string,
};

const clientTabs = [
	{
		name: "Projects",
		icon: faInfoCircle,
	},
	{
		name: "Billing Rates",
		icon: faReceipt,
	},
	{
		name: "Users",
		icon: faUsers,
	},
];

export function Client({ route }) {
	const { id, name } = route.params;
	const navigation = useNavigation();
	const [client, setClient] = React.useState({});
	const [currentTab, setCurrentTab] = React.useState(clientTabs[0].name);
	const [selectedProject, setCurrentProject] = React.useState({});
	const [newItem, setNewItem] = React.useState(null);

	const { loading, refetch } = useQuery(gql(clientGQL.getClient), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setClient(data.getClient);
		},
	});

	if (loading || !client) {
		return <LoadingScreen loadItem={name || "Client"} />;
	}

	const getButtons = () => {
		switch (currentTab) {
			case "Projects":
				return [
					{
						name: "Edit Client",
						icon: faPen,
						onPress: () => {
							setNewItem("editClient");
						},
					},
					{
						name: "New Project",
						icon: faPlus,
						onPress: () => {
							setNewItem("project");
						},
					},
				];
			case "Billing Rates":
				return [
					{
						name: "New Billing Rate",
						icon: faPlus,
						onPress: () => {
							setNewItem("billingRate");
						},
					},
				];
			case "Users":
				return [
					{
						name: "New User",
						icon: faPlus,
						onPress: () => {
							setNewItem("user");
						},
					},
				];
			default:
				return [];
		}
	};

	const navigateToProject = (project) => {
		navigation.navigate("Project", {
			id: project.id,
			name: project.title,
		});
	};

	const navigateToUser = (user) => {
		navigation.navigate("User", {
			id: user.id,
			name: `${user.firstName} ${user.lastName}`,
		});
	};

	const navigateToRateSheet = (rateSheet) => {
		navigation.navigate("RateSheet", {
			id: rateSheet.id,
			name: rateSheet.name,
		});
	};

	return (
		<>
			<Breadcrumb baseLocation="Work In Progress">
				<Crumb name="Clients" icon={faBuilding} location="Clients" />
				<Crumb
					name={client.name}
					icon={faUserTie}
					location="Client"
					params={{ id: client.id, name: client.name }}
				/>
			</Breadcrumb>
			<PageHeader
				goBack
				title={client.name}
				buttons={getButtons()}
				info={[
					{
						text: `Sage Intacct: ${client.sageIntacct}`,
						icon: faHashtag,
					},
				]}
			/>
			<Tabs>
				{clientTabs.map((tab) => (
					<Tab
						key={tab.name}
						name={tab.name}
						icon={tab.icon}
						onPress={() => setCurrentTab(tab.name)}
						current={currentTab === tab.name}
					/>
				))}
			</Tabs>

			{currentTab === "Projects" && (
				<>
					{client.projects && client.projects.length > 0 ? (
						<List
							title="Projects"
							headers={["Title", "Sage Intacct"]}
							usesOptions
						>
							{client.projects.map((project, index) => (
								<ListItem
									items={[
										{
											content: project.title,
											onPress: () => navigateToProject(project),
										},
										{
											content: project.sageIntacct,
											onPress: () => navigateToProject(project),
										},
									]}
									options={
										<OptionsMenu
											options={[
												{
													name: "Edit Project",
													icon: faPen,
													onPress: () => {
														setCurrentProject(project);
														setNewItem("editProject");
													},
												},
											]}
										/>
									}
									key={index}
								/>
							))}
						</List>
					) : (
						<NoItems surTitle="No Project" title="Currently No Projects" />
					)}
				</>
			)}

			{currentTab === "Billing Rates" && (
				<View className="px-1">
					<View className="px-1">
						{client.billingRates && client.billingRates.length > 0 ? (
							<List title="Billing Rate" headers={["Name"]} usesOptions>
								{client.billingRates.map((sheet, index) => (
									<ListItem
										key={index}
										items={[
											{
												content: sheet.name,
												onPress: () => navigateToRateSheet(sheet),
											},
										]}
										options={
											<OptionsMenu
												options={[
													{
														name: "Edit",
														icon: faPen,
														onPress: () => navigateToRateSheet(sheet),
													},
												]}
											/>
										}
									/>
								))}
							</List>
						) : (
							<NoItems
								surTitle="No Billing Rates"
								title="No Client Specific Billing Rates"
							/>
						)}
					</View>
				</View>
			)}

			{currentTab === "Users" && (
				<View className="px-1">
					{client.users && client.users.length > 0 ? (
						<List title="Users" headers={["Name", "Roles", "Email"]}>
							{client.users.map((user, index) => (
								<ListItem
									items={[
										{
											content: `${user.firstName} ${user.lastName}`,
											onPress: () => {
												navigateToUser(user);
											},
										},
										{
											content: user.role,
											onPress: () => {
												navigateToUser(user);
											},
										},
										{
											content: user.email,
											onPress: () => {
												navigateToUser(user);
											},
										},
									]}
									key={index}
								/>
							))}
						</List>
					) : (
						<NoItems surTitle="No Users" title="No Client Users" />
					)}
				</View>
			)}

			<ProjectSlideOver
				isOpen={newItem === "editProject"}
				onClose={() => setNewItem(null)}
				clerking
				type="update"
				onComplete={() => {
					refetch();
					setCurrentProject({});
					setNewItem(null);
				}}
				currentProject={{
					id: selectedProject.id,
					title: selectedProject.title,
					stage: selectedProject.stage,
					sageIntacct: selectedProject.sageIntacct,
					number: selectedProject.number,
					isLargeLoss: selectedProject.isLargeLoss,
					usePhaseBilling: selectedProject.usePhaseBilling,
				}}
				defaultSelectValues={{
					stage: selectedProject.stage,
					office: selectedProject.office?.name,
					client: selectedProject.client?.name,
					location: selectedProject.location
						? selectedProject.location.name
						: "",
					billingRate: selectedProject.billingRate
						? selectedProject.billingRate.name
						: "",
				}}
			/>

			<RateSheetContainer
				isOpen={newItem === "billingRate"}
				closeRequest={() => {
					setNewItem(null);
				}}
				client={client}
				onSubmit={() => {
					setNewItem(null);
					refetch();
				}}
			/>

			<ClientSlideOver
				isOpen={newItem === "editClient"}
				closeRequest={() => setNewItem("")}
				currentClient={{
					id: client.id,
					name: client.name,
					sageIntacct: client.sageIntacct,
				}}
				currentLocation={{
					id: client.location ? client.location.id : null,
					lineOne: client.location ? client.location.lineOne : null,
					lineTwo: client.location ? client.location.lineTwo : "",
					city: client.location ? client.location.city : null,
					state: client.location ? client.location.state : null,
					zip: client.location ? client.location.zip : null,
				}}
				onComplete={() => {
					setNewItem("");
					refetch();
				}}
				type="update"
			/>
		</>
	);
}

Client.propTypes = {
	search: PropTypes.string,
	route: PropTypes.object,
};
