export const createSafetyQuestionnaire = /* GraphQL */ `
	mutation CreateSafetyQuestionnaire($input: CreateSafetyQuestionnaireInput!) {
		createSafetyQuestionnaire(input: $input) {
			id
			reportedToPM
			requiredCare
			injuries
			occurredOnJobSite
			howInjuryOccurred
			wearingPPE
			providedPPE
			dateCreated
			timeEntry {
				id
				user {
					id
					firstName
					lastName
				}
			}
		}
	}
`;
export const createNote = /* GraphQL */ `
	mutation CreateNote($input: CreateNoteInput!) {
		createNote(input: $input) {
			id
			description
		}
	}
`;

export const listSafetyQuestionnaires = /* GraphQL */ `
	query ListSafetyQuestionnaires(
		$filter: TableSafetyQuestionnaireFilterInput
		$limit: Int
		$nextToken: String
	) {
		listSafetyQuestionnaires(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				id
				reportedToPM
				requiredCare
				injuries
				occurredOnJobSite
				howInjuryOccurred
				wearingPPE
				providedPPE
				dateCreated
				status
				reviewer {
					id
					firstName
					lastName
				}
				notes {
					id
					title
					description
					media {
						id
						key
					}
				}
				timeEntry {
					id
					user {
						id
						firstName
						lastName
					}
				}
			}
			total
		}
	}
`;

export const getSafetyQuestionnaire = /* GraphQL */ `
	query GetSafetyQuestionnaire($id: ID!) {
		getSafetyQuestionnaire(id: $id) {
			id
			reportedToPM
			requiredCare
			injuries
			occurredOnJobSite
			howInjuryOccurred
			wearingPPE
			providedPPE
			dateCreated
			status
			reviewer {
				id
				firstName
				lastName
			}
			notes {
				id
				title
				description
				media {
					id
					key
				}
			}
			timeEntry {
				id
				user {
					id
					firstName
					lastName
				}
			}
			media {
				id
				key
			}
		}
	}
`;

export const updateSafetyQuestionnaire = /* GraphQL */ `
	mutation UpdateSafetyQuestionnaire($input: UpdateSafetyQuestionnaireInput!) {
		updateSafetyQuestionnaire(input: $input) {
			id
			reportedToPM
			requiredCare
			injuries
			occurredOnJobSite
			howInjuryOccurred
			wearingPPE
			providedPPE
			dateCreated
			status
			reviewer {
				id
				firstName
				lastName
			}
			notes {
				id
				description
			}
			timeEntry {
				id
				user {
					id
					firstName
					lastName
				}
			}
		}
	}
`;

export const deleteSafetyQuestionnaire = /* GraphQL */ `
	mutation DeleteSafetyQuestionnaire($input: DeleteSafetyQuestionnaireInput!) {
		deleteSafetyQuestionnaire(input: $input) {
			id
		}
	}
`;

export const bulkCreateMedia = /* GraphQL */ `
	mutation bulkCreateMedia($input: [CreateMediaInput!]!) {
		bulkCreateMedia(input: $input) {
			id
		}
	}
`;
