import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { View } from "react-native";

// components
import { Alert } from "../components/Alert";
import SlideOver from "../components/Slideover";
import {
	Checkbox,
	FieldSet,
	Input,
	Radio,
	//Select,
	TextArea,
} from "../components/Forms";

// GQL
import { gql, useMutation } from "@apollo/client";
import * as spaceGQL from "../graphql/space";

// icons
import {
	faGlassWaterDroplet,
	faImage,
	faMemoCircleCheck,
	faRulerCombined,
	//faBlockBrick,
} from "@fortawesome/pro-duotone-svg-icons";

/**
 * MoisturePointSlideOver
 * @param {object} props - props to be supplied to the slide over
 * @param {object} props.currentMoisturePoint - the currentMoisturePoint to be update (type should be set to update if this prop is passed)
 * @param {object} props.standards - the standards for the space (NEEDED for the moisture point to get the dry standard materials)
 * @param {object} props.space - the space for the surface of the moisture point (NEEDED for the moisture point to get the dry standard materials)
 * @param {object} props.surface - the moisture point that is going to be attached to the surface
 * @param {object} props.user - the current logged in user
 * @param {function} props.onComplete - a function to be called when the slide over has completed the createRequest it will send the moisture point back to the parent component
 * @param {function} props.closeRequest - a function to be called when the slide over been closed by the user
 * @param {boolean} props.skipDataCreation - a boolean to determine if the data should be created or simply passed back to the parent component
 * @param {string} props.type - the type of the slide over (create or update)
 * @param {boolean} props.isOpen - a boolean to determine if the slide over should be shown
 * @returns {object} - JSX
 */

export const MoisturePointSlideOver = ({
	currentMoisturePoint,
	//space,
	surface,
	user,
	onComplete = () => {},
	closeRequest = () => {},
	skipDataCreation = false,
	type = "create",
	isOpen,
}) => {
	const [moisturePoint, setMoisturePoint] = React.useState({
		relative: true,
		locationType: "wall",
	});
	const [hasSpecialInstructions, setHasSpecialInstructions] =
		React.useState(false);
	//const [defaultMaterial, setDefaultMaterial] = React.useState("");

	React.useEffect(() => {
		if (currentMoisturePoint) {
			setMoisturePoint(currentMoisturePoint);
		}
	}, [currentMoisturePoint]);

	React.useEffect(() => {
		if (surface && surface.moisturePoints && surface.moisturePoints.length) {
			let lastPoint = surface.moisturePoints[surface.moisturePoints.length - 1];
			setDefaultMaterial(
				lastPoint.material ? lastPoint.material.material : null
			);
			setMoisturePoint({
				...moisturePoint,
				material: lastPoint.material ? lastPoint.material.id : null,
			});
		}
	}, [surface]);

	const [createMoisturePoint] = useMutation(gql(spaceGQL.createMoisturePoint), {
		onCompleted: (data) => {
			cleanUp();
			onComplete(data.createMoisturePoint);
		},
		onError: (error) => {
			Alert("Error", error.message);
		},
	});

	const [updateMoisturePoint] = useMutation(gql(spaceGQL.updateMoisturePoint), {
		onCompleted: (data) => {
			cleanUp();
			onComplete(data.updateMoisturePoint);
		},
		onError: (error) => {
			Alert("Error", error.message);
		},
	});

	const cleanUp = () => {
		setMoisturePoint({ relative: true, locationType: "wall" });
		setHasSpecialInstructions(false);
	};

	return (
		<SlideOver
			isOpen={isOpen}
			name="Moisture Data"
			description="Fill out the data below to finish adding the moisture data to the space"
			closeRequest={() => {
				//remove the current wall touched from the mapData
				cleanUp();
				closeRequest();
			}}
			onSubmit={() => {
				// error handling

				if (!moisturePoint.material && !moisturePoint.isNoAccess) {
					Alert("Please select the material of the surface");
					return;
				}

				if (
					!moisturePoint.mc &&
					!moisturePoint.isNoAccess &&
					!moisturePoint.isVisual
				) {
					Alert("Please fill out the moisture content data");
					return;
				}

				if (
					(!moisturePoint.feet || !moisturePoint.inches) &&
					moisturePoint.locationType === "wall" &&
					!moisturePoint.isNoAccess &&
					!moisturePoint.isVisual
				) {
					Alert("Please fill out the moisture height (feet and inches)");
					return;
				}

				if (!moisturePoint.locationType && !moisturePoint.isNoAccess) {
					Alert("Please select a moisture location type");
					return;
				}

				if (hasSpecialInstructions && !moisturePoint.specialInstructions) {
					Alert(
						"Please fill out the special instructions or uncheck the special instructions checkbox"
					);

					return;
				}

				moisturePoint.date = DateTime.now().toISO();

				let heightOfDamage = parseFloat(
					`${moisturePoint.feet}.${moisturePoint.inches}`
				);

				/** determine the color of the surface */

				if (moisturePoint.isNoAccess || moisturePoint.isVisual) {
					moisturePoint.color = "gray";
				} else if (moisturePoint.locationType === "floor") {
					moisturePoint.color = "none";
				} else if (moisturePoint.specialInstructions) {
					moisturePoint.color = "orange";
				} else if (moisturePoint.material.dryOnly) {
					moisturePoint.color = "red";
				} else if (moisturePoint.locationType === "ceiling") {
					moisturePoint.color = "yellow";
				} else if (heightOfDamage > 4) {
					moisturePoint.color = "purple";
				} else if (heightOfDamage >= 2 && heightOfDamage <= 4) {
					moisturePoint.color = "blue";
				} else if (heightOfDamage < 2 || heightOfDamage > 0.5) {
					moisturePoint.color = "green";
				} else if (heightOfDamage > 0) {
					moisturePoint.color = "green";
				}

				if (skipDataCreation) {
					cleanUp();
					return onComplete(moisturePoint);
				}

				if (type === "create") {
					//	let user = getData("@storage_Key").then((user) => user);

					createMoisturePoint({
						variables: {
							input: {
								surface: surface.id,
								user: user.id,
								date: moisturePoint.date,
								mc: moisturePoint.mc,
								color: moisturePoint.color,
								feet: parseInt(moisturePoint.feet || 0, 10),
								inches: parseInt(moisturePoint.inches || 0, 10),
								material: moisturePoint.material
									? moisturePoint.material.id
									: null,
								isVisual: moisturePoint.isVisual,
								isNoAccess: moisturePoint.isNoAccess,
								flir: moisturePoint.flir,
								sap: moisturePoint.sap,
								relative: moisturePoint.relative,
								locationType: moisturePoint.locationType,
								specialInstructions: moisturePoint.specialInstructions,
								previouslyDemoed: moisturePoint.previouslyDemoed,
								isAMG: moisturePoint.isAMG,
							},
						},
					});

					cleanUp();
				} else {
					let user = getData("@storage_Key").then((user) => user);
					updateMoisturePoint({
						variables: {
							input: {
								id: moisturePoint.id,
								user: user.id,
								mc: moisturePoint.mc,
								color: moisturePoint.color,
								feet: parseInt(moisturePoint.feet || 0, 10),
								inches: parseInt(moisturePoint.inches || 0, 10),
								material: moisturePoint.material
									? moisturePoint.material.id
									: null,
								flir: moisturePoint.flir,
								sap: moisturePoint.sap,
								isVisual: moisturePoint.isVisual,
								isNoAccess: moisturePoint.isNoAccess,
								isAMG: moisturePoint.isAMG,
								relative: moisturePoint.relative,
								locationType: moisturePoint.locationType,
								specialInstructions: moisturePoint.specialInstructions,
								previouslyDemoed: moisturePoint.previouslyDemoed,
							},
						},
					});
				}
			}}
		>
			<Checkbox
				label="No Access"
				description="Check this if the surface is currently not accessible"
				checked={moisturePoint.isNoAccess}
				onChange={(value) => {
					setMoisturePoint({ ...moisturePoint, isNoAccess: value });
				}}
			/>

			{!moisturePoint.isNoAccess ? (
				<>
					<Checkbox
						label="Visual Damage"
						description="Only check this if the surface is damaged visually (not if there is moisture)"
						checked={moisturePoint.isVisual}
						onChange={(value) => {
							setMoisturePoint({ ...moisturePoint, isVisual: value });
						}}
					/>
					{/*<Select*/}
					{/*	name="Material"*/}
					{/*	defaultValue={defaultMaterial}*/}
					{/*	icon={faBlockBrick}*/}
					{/*	options={spaceStandard()}*/}
					{/*	onChange={(value) => {*/}
					{/*		setMoisturePoint({*/}
					{/*			...moisturePoint,*/}
					{/*			material: value.id,*/}
					{/*		});*/}
					{/*	}}*/}
					{/*/>*/}
					<View className="mt-5">
						<FieldSet legend="Location Type">
							<Radio
								name="Location Type Radio"
								horizontal={true}
								onChange={(value) => {
									if (value.locationType === "Wall") {
										setMoisturePoint({
											...moisturePoint,
											locationType: null,
										});

										if (value.locationType === "Ceiling") {
											setMoisturePoint({
												...moisturePoint,
												locationType: null,
											});
										}
										if (value.locationType === "Floor") {
											setMoisturePoint({
												...moisturePoint,
												locationType: null,
											});
										}
									}
								}}
								options={[
									{
										id: 1,
										label: "Wall",
									},
									{
										id: 2,
										label: "Ceiling",
									},
									{
										id: 3,
										label: "Floor",
									},
								]}
							/>
						</FieldSet>
					</View>

					{!moisturePoint.isVisual ? (
						<>
							<Input
								label="Moisture Content"
								value={moisturePoint.mc}
								icon={faGlassWaterDroplet}
								onChange={(e) => {
									setMoisturePoint({
										...moisturePoint,
										mc: e,
									});
								}}
							/>
							<View className="mt-5">
								<FieldSet legend="Read Type">
									<Radio
										horizontal={true}
										name="Read Type"
										onChange={(value) => {
											if (value.readType === "Relative") {
												setMoisturePoint({
													...moisturePoint,
													readType: value.readType,
												});
											}
											if (value.readType === "Penetrative") {
												setMoisturePoint({
													...moisturePoint,
													readType: value.readType,
												});
											}
										}}
										options={[
											{
												id: 1,
												label: "Relative",
											},
											{
												id: 2,
												label: "Penetrative",
											},
										]}
									/>
								</FieldSet>
							</View>

							<Input
								label="Feet"
								type="number"
								icon={faRulerCombined}
								value={moisturePoint.feet}
								onChange={(e) => {
									setMoisturePoint({
										...moisturePoint,
										feet: e,
									});
								}}
							/>

							<Input
								label="Inches"
								type="number"
								icon={faRulerCombined}
								value={moisturePoint.inches}
								onChange={(e) => {
									setMoisturePoint({
										...moisturePoint,
										inches: e,
									});
								}}
							/>

							<Checkbox
								label="Demoed"
								description={
									"Was this surface already demoed before SRP's arrival?"
								}
								onChange={(value) => {
									setMoisturePoint({
										...moisturePoint,
										previouslyDemoed: value,
									});
								}}
							/>

							<Checkbox
								label="Special Instructions"
								description="Does this surface need to be treated with special instructions"
								onChange={(value) => {
									setHasSpecialInstructions(value);
								}}
							/>

							{hasSpecialInstructions && (
								<TextArea
									label="Special Instructions"
									onChange={(e) => {
										setMoisturePoint({
											...moisturePoint,
											specialInstructions: e,
										});
									}}
								/>
							)}
						</>
					) : (
						<></>
					)}
					<Input
						label="FLIR"
						value={moisturePoint.flir}
						icon={faImage}
						onChange={(e) => {
							setMoisturePoint({
								...moisturePoint,
								flir: e,
							});
						}}
					/>
					<Input
						label="Site Audit"
						icon={faMemoCircleCheck}
						value={moisturePoint.sap}
						onChange={(e) => {
							setMoisturePoint({
								...moisturePoint,
								audit: e,
							});
						}}
					/>
					<Checkbox
						label="AMG"
						description={"Is there AMG present on this surface?"}
						onChange={(value) => {
							setMoisturePoint({
								...moisturePoint,
								isAMG: value,
							});
						}}
					/>
				</>
			) : (
				<></>
			)}
		</SlideOver>
	);
};

MoisturePointSlideOver.propTypes = {
	currentMoisturePoint: PropTypes.object,
	space: PropTypes.object,
	surface: PropTypes.object,
	user: PropTypes.object,
	onComplete: PropTypes.func,
	closeRequest: PropTypes.func,
	skipDataCreation: PropTypes.bool,
	type: PropTypes.string,
	isOpen: PropTypes.bool,
};
