import { useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
	//Dimensions,
	Platform,
	Pressable,
	ScrollView,
	Text,
	View,
} from "react-native";
//import Carousel from "react-native-reanimated-carousel";

// Containers
import { BodySlideOver } from "../../containers/BodySlideOver";
import { SopSlideOver } from "../../containers/SopSlideOver";
import { VersionSlideOver } from "../../containers/VersionSlideOver";

// Components
import { Badge } from "../../components/Badge";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import DataContainer, { DataItem } from "../../components/DataDisplay";
import { Select } from "../../components/Forms";
import { PageHeader } from "../../components/Header.js";
import { List, ListItem } from "../../components/List.js";
import LoadingScreen from "../../components/LoadingScreen";
import OptionsMenu from "../../components/OptionsMenu";

// GQL
import { gql, useMutation, useQuery } from "@apollo/client";
import * as bodyGQL from "../../graphql/admin/body";
import * as sopGQL from "../../graphql/admin/sops";
import * as versionGQL from "../../graphql/admin/version";

// Icons
import {
	faEdit,
	faEye,
	faFile,
	faPlus,
	faTrash,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

// Utils
import Modal from "../../components/Modal";
import { SentryLogger } from "../../utils";

export const SOPs = ({ search }) => {
	const navigation = useNavigation();
	const [sops, setSops] = React.useState(null);
	const [sopId, setSopId] = React.useState({});
	const [newItem, setNewItem] = React.useState(null);

	const { loading: sopLoading, refetch } = useQuery(gql(sopGQL.listSOPs), {
		onCompleted: (data) => {
			setSops(data.listSOPs.items);
		},
		onError: (error) => {
			SentryLogger(error, "Error in SOPs");
		},
		fetchPolicy: "cache-and-network",
	});

	const [deleteSop] = useMutation(gql(sopGQL.deleteSOP), {
		onCompleted: () => {
			refetch();
		},
		onError: (error) => {
			SentryLogger(error, "Error in Delete SOP");
		},
	});

	const navigateToSop = (id) => {
		navigation.navigate("SingleSOP", { id });
	};

	if (sopLoading || !sops) return <LoadingScreen loadItem="SOPs" />;

	return (
		<>
			<Breadcrumb baseLocation="Dashboard">
				<Crumb name="SOPs" location="SOPs" />
			</Breadcrumb>
			<PageHeader
				openMenu
				title="SOPs"
				buttons={[
					{
						name: "Create SOP",
						icon: faPlus,
						onPress: () => setNewItem("newSOP"),
					},
				]}
			/>

			<List
				headers={["Title", "Public Version", "Tags", "Versions"]}
				usesOptions
			>
				{sops &&
					sops
						.filter((sops) => {
							return (
								sops.title.toLowerCase().includes(search) ||
								sops.versions.length.toLowerCase().includes(search)
							);
						})
						.sort((a, b) => {
							if (a.title > b.title) return 1;
							if (a.title < b.title) return -1;
							return 0;
						})
						.map((sop, index) => {
							return (
								<ListItem
									key={index}
									items={[
										{
											content: sop.title,
											onPress: () => navigateToSop(sop.id),
										},
										{
											content: sop.publicVersion
												? sop.publicVersion.version
												: "No Public Version",
											onPress: () => navigateToSop(sop.id),
										},
										{
											content: (
												<View className="flex flex-row space-between items-center">
													{sop.tags.map((tag, index) => {
														return (
															<Badge key={`tag-${index}`} text={tag.name} />
														);
													})}
												</View>
											),
											onPress: () => navigateToSop(sop.id),
										},
										{
											content: sop.versions.length,
											onPress: () => navigateToSop(sop.id),
										},
									]}
									options={
										<OptionsMenu
											options={[
												{
													name: "View",
													icon: faEye,
													onPress: () => navigateToSop(sop.id),
												},
												{
													name: "Delete",
													icon: faTrash,
													onPress: () => {
														setNewItem("confirmDelete");
														setSopId(sop);
													},
												},
											]}
										/>
									}
									smallView={
										//going to be converted from touchable opacity to a button
										<>
											<Pressable onPress={() => navigateToSop(sop.id)}>
												<View>
													<Text className=" font-bold text-md dark:text-white ">
														{sop.title}
													</Text>
													<Text className="font-medium dark:text-white">
														Versions: {`${sop.versions.length} Versions`}
													</Text>
													<Text className="font-medium dark:text-white">
														Current Public Version:{" "}
														{sop.publicVersion
															? sop.publicVersion.version
															: "No Public Version"}
													</Text>
													<View className="flex flex-row space-between items-center">
														{sop.tags.map((tag, index) => {
															return (
																<View key={`tag-${index}`} className="mr-2">
																	<Badge text={tag.name} />
																</View>
															);
														})}
													</View>
												</View>
											</Pressable>
										</>
									}
								/>
							);
						})}
				<Modal
					isOpen={newItem === "confirmDelete"}
					closeRequest={() => setNewItem(null)}
					onComplete={() => {
						setNewItem(null);
					}}
					title="Confirm Delete"
					description={`Are you sure you want to delete ${sopId.title}?`}
					closeButton="Cancel"
					rightButton={{
						text: "Delete SOP",
						onPress: () => {
							deleteSop({
								variables: {
									input: {
										id: sopId.id,
									},
								},
							});
							setNewItem(null);
						},
					}}
				></Modal>

				<SopSlideOver
					isOpen={newItem === "newSOP"}
					closeRequest={() => setNewItem(null)}
					onComplete={() => {
						setNewItem(null);
					}}
				></SopSlideOver>
			</List>
		</>
	);
};

SOPs.propTypes = {
	route: PropTypes.object,
	search: PropTypes.string,
	smallView: PropTypes.oneOf([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
};

export const SingleSOP = ({ route }) => {
	const { id } = route.params;
	const navigation = useNavigation();
	const [sop, setSop] = useState(null);
	const [newItem, setNewItem] = useState("");
	const [newVersion, setNewVersion] = useState({});

	const { loading, refetch } = useQuery(gql(sopGQL.getSOP), {
		variables: { id: id },
		onCompleted: (data) => {
			setSop(data.getSOP);
		},

		fetchPolicy: "cache-and-network",
	});

	const [deleteVersion] = useMutation(gql(versionGQL.deleteVersion), {
		onCompleted: () => {
			refetch();
		},
		onError: (error) => {
			SentryLogger(error, "Error in Delete Version");
		},
	});

	const navigateToVersion = (id) => {
		navigation.navigate("Version", { id: id });
	};

	if (loading || !sop) {
		return <LoadingScreen loadItem={"Single Sop"} />;
	}

	return (
		<>
			<Breadcrumb baseLocation="Dashboard">
				<Crumb name="SOPs" location="SOPs" />
				<Crumb
					name={`${sop.title}`}
					location="SingleSOP"
					params={{
						id: sop.id,
					}}
				/>
			</Breadcrumb>

			<PageHeader
				openMenu
				title={`Title: ${sop.title}`}
				buttons={[
					{
						name: "Add Version",
						icon: faPlus,
						onPress: () => setNewItem("newVersion"),
					},
				]}
			/>

			<DataContainer title={"Information"}>
				<View className="flex flex-row space-between items-center pb-4">
					<List
						showFully
						headers={["Version", "Date Created", "Public"]}
						usesOptions
					>
						{sop &&
							sop.versions.map((version, index) => {
								return (
									<ListItem
										key={index}
										items={[
											{
												content: <Text>{version.version}</Text>,
											},
											{
												content: (
													<Text className="text-align-left ">
														{version.created
															? DateTime.fromISO(
																	version.created
															  ).toLocaleString()
															: "No Date"}
													</Text>
												),
											},
											{
												content: (
													<Text className="font-medium dark:text-white pl-6">
														{version.public === true ? "True" : "False"}
													</Text>
												),
											},
										]}
										options={
											<View className={"padding-inline"}>
												<OptionsMenu
													options={[
														{
															name: "View",
															icon: faEdit,
															onPress: () => {
																navigateToVersion(version.id);
															},
														},
														{
															name: "Delete",
															icon: faTrash,
															onPress: () => {
																setNewItem("confirmDeleteVersion");
																setNewVersion(version);
															},
														},
													]}
												/>
											</View>
										}
										smallView={
											<>
												<Pressable
													onPress={() => navigateToVersion(version.id)}
												>
													<View className="flex-row items-center pb-1 justify-right space-x-16 pt-2">
														<View>
															<Badge text={`${version.version}  `} />
														</View>
														<View>
															<Badge
																text={
																	version.created
																		? DateTime.fromISO(
																				version.created
																		  ).toLocaleString()
																		: "No Date"
																}
															/>
														</View>
														<View>
															<Badge
																text={
																	version.public === true ? "True" : "False"
																}
															/>
														</View>
													</View>
												</Pressable>
											</>
										}
									/>
								);
							})}
					</List>
				</View>
				<View className="flex flex-row ">
					<DataItem
						name="Tags"
						value={sop.tags.map((tag, index) => {
							return (
								<View key={index} className={"pb-1 pt-1"}>
									<Badge text={tag.name} />
								</View>
							);
						})}
					/>
				</View>

				<DataItem
					name="Current Public Version"
					value={
						sop.publicVersion ? sop.publicVersion.version : "No Public Version"
					}
				/>

				{/* attachments will possibly need to show full image here */}
				<View className="flex flex-row space-between items-center">
					<DataItem
						name="Attachments"
						value={sop.attachments.map((attachment, index) => {
							return (
								<View key={`attachment-${index}`} className="mr-2">
									<Badge text={attachment.name} />
								</View>
							);
						})}
					/>
				</View>
			</DataContainer>

			<VersionSlideOver
				isOpen={newItem === "newVersion"}
				closeRequest={() => setNewItem(null)}
				currentVersion={newVersion}
				currentSOP={sop}
				onComplete={() => {
					refetch();
					setNewItem(null);
				}}
			></VersionSlideOver>
			<Modal
				isOpen={newItem === "confirmDeleteVersion"}
				closeRequest={() => setNewItem(null)}
				onComplete={() => {
					setNewItem(null);
				}}
				title="Delete Version"
				description={`Are you sure you want to delete version:${newVersion.version}?`}
				closeButton="Cancel"
				rightButton={{
					text: "Delete Version",
					onPress: () => {
						deleteVersion({
							variables: {
								input: {
									id: newVersion.id,
								},
							},
						});
						refetch();
						setNewItem(null);
					},
				}}
			></Modal>
		</>
	);
};

SingleSOP.propTypes = {
	route: PropTypes.object,
};

const attachments = [
	{
		name: "Test File.pdf",
		fileSize: "100 MB",
	},
	{
		name: "Test File 2.pdf",
		fileSize: "3.76 GB",
	},
];

const Attachment = ({ name, key, fileSize }) => {
	return (
		<Pressable
			onPress={async () => {
				const url = await getSignedUrl(key);
				Linking.openUrl(url);
			}}
			className="border-indigo-500 border rounded-lg p-4 flex flex-row items-center justify-between"
		>
			<FontAwesomeIcon icon={faFile} size={30} />
			<View>
				<Text className="font-medium">{name}</Text>
				<Text className="font-light">{fileSize}</Text>
			</View>
		</Pressable>
	);
};
Attachment.propTypes = {
	name: PropTypes.string,
	key: PropTypes.string,
	fileSize: PropTypes.string,
};

export const Version = ({ route }) => {
	const { id } = route.params;
	//const navigation = useNavigation();
	const [version, setVersion] = useState(null);
	const [newItem, setNewItem] = useState(null);

	//const width = Dimensions.get("window").width;

	const { loading } = useQuery(gql(versionGQL.getVersion), {
		variables: { id: id },
		onCompleted: (data) => {
			setVersion(data.getVersion);
		},

		fetchPolicy: "cache-and-network",
	});

	// const navigateToBody = (id) => {
	// 	navigation.navigate("Body", { id });
	// };

	if (loading || !version) {
		return <LoadingScreen loadItem={"Version"} />;
	}

	return (
		<>
			<Breadcrumb baseLocation="Dashboard">
				<Crumb name="SOPs" location="SOPs" />
				<Crumb
					name={`${version.sop.title}`}
					location="SingleSOP"
					params={{ id: version.sop.id }}
				/>
				<Crumb
					name={`${version.version}`}
					location="Version"
					params={{ id: version.id }}
				/>
			</Breadcrumb>
			<PageHeader
				openMenu
				title={version.sop.title}
				description={`Version ${version.version}`}
				buttons={[
					{
						name: "Edit Version",
						icon: faEdit,
						onPress: () => setNewItem("Edit Version"),
					},
					{
						name: "Add Body",
						icon: faPlus,
						onPress: () => setNewItem("New Body"),
					},
				]}
			/>
			<ScrollView>
				<DataContainer title={"Version Information"} className={"text-lg"}>
					<DataItem name={"Version"} value={version.version} />
					<DataItem
						name={"Date Created"}
						value={
							version.created
								? DateTime.fromISO(version.created).toLocaleString()
								: "No Date"
						}
					/>
					<DataItem
						name={"Public"}
						value={version.public === true ? "True" : "False"}
					/>
				</DataContainer>

				<DataContainer
					title={"Translations"}
					description={"Swipe to see more translations"}
				>
					<View className="flex-1">
						{/* <Carousel
							width={width * 0.9}
							height={width / 2}
							data={version.bodies}
							scrollAnimationDuration={1000}
							onSnapToItem={(index) => 
							renderItem={({ item }) => {
								return (
									<Pressable
										onPress={() => navigateToBody(item.body.id)}
										className="flex-1 mx-2 rounded-lg shadow"
									>
										<View className="flex flex-row justify-end mb-2">
											<Button
												size="xs"
												icon={faEye}
												onPress={navigateToBody(item.body.id)}
												text="View Translation"
											/>
										</View>
										<DataContainer title={item.language}>
											<Text>{item.body}</Text>
										</DataContainer>
									</Pressable>
								);
							}}
						/> */}
					</View>
				</DataContainer>
				<DataContainer
					title={"Attachments"}
					description={`${
						Platform.OS === "web" ? "Click" : "Tap"
					} file to download / view`}
				>
					<View className="flex flex-row flex-wrap items-center">
						{attachments.map((file, index) => {
							return (
								<View key={index} className="mr-2">
									<Attachment
										fileSize={file.fileSize}
										name={file.name}
										key={file.key}
									/>
								</View>
							);
						})}
					</View>
				</DataContainer>
			</ScrollView>

			<VersionSlideOver
				isOpen={newItem === "Edit Version"}
				type="update"
				closeRequest={() => setNewItem(null)}
				currentVersion={version}
				onCompleted={() => {
					setNewItem(null);
				}}
			></VersionSlideOver>
			<BodySlideOver
				isOpen={newItem === "New Body"}
				closeRequest={() => setNewItem(null)}
				onCompleted={() => {
					setNewItem(null);
				}}
			></BodySlideOver>
		</>
	);
};

Version.propTypes = {
	route: PropTypes.object,
};

export const Body = ({ route }) => {
	const { id } = route.params;
	//const navigation = useNavigation();
	const [body, setBody] = useState(null);
	const [newItem, setNewItem] = useState(null);

	const { loading } = useQuery(gql(bodyGQL.getBody), {
		variables: { id: id },
		onCompleted: (data) => {
			setBody(data.getBody);
		},
	});
	const [deleteBody] = useMutation(gql(bodyGQL.deleteBody), {
		onCompleted: () => {
			refetch();
		},
		onError: (error) => {
			SentryLogger(error, "Error in Delete Body");
		},
	});

	if (loading || !body) {
		return <LoadingScreen loadItem={"Body"} />;
	}

	return (
		<>
			<Breadcrumb baseLocation="Dashboard">
				<Crumb name="SOPs" location="SOPs" />
				<Crumb
					name={`Sop: ${body.version.sop.title}`}
					location="SingleSOP"
					params={{ id: body.version.sop.id }}
				/>
				<Crumb
					name={`${body.version.version}`}
					location="Version"
					params={{ id: body.version.id }}
				/>
				<Crumb name="Body" location="Body" params={{ id: body.id }} />
			</Breadcrumb>
			<PageHeader
				openMenu
				title={`Sop Title:  ${body.version.sop.title}`}
				description={`Version ${body.version.version}`}
				buttons={[
					{
						name: "Edit Body",
						icon: faEdit,
						onPress: () => setNewItem("Edit Body"),
					},
					{
						name: "Delete Body",
						icon: faTrash,
						onPress: () => setNewItem("Confirm Delete"),
					},
				]}
			/>
			{/* TODO: Add code to reload and show the selected body attached to the language  */}

			<View className="w-full px-4 flex flex-row justify-end">
				<View className="w-52">
					<Select
						name={"Language"}
						defaultValue={body.language ? body.language : ""}
						onChange={() => {
							// TODO: Add code to reload and show the selected body attached to the language
						}}
						options={[
							{ name: "English (En)", value: "EN" },
							{
								name: "Spanish (Es)",
								value: "ES",
							},
							{
								name: "French (Fr)",
								value: "FR",
							},
						]}
					/>
				</View>
			</View>

			<View>
				<DataContainer title={"Content of Body"}>
					<Text>{body.body}</Text>
				</DataContainer>
			</View>
			<View className=""></View>

			<BodySlideOver
				isOpen={newItem === "Edit Body"}
				type={"update"}
				closeRequest={() => setNewItem(null)}
				currentBody={body}
				onCompleted={() => {
					setNewItem(null);
				}}
			></BodySlideOver>
			<Modal
				isOpen={newItem === "Confirm Delete"}
				closeRequest={() => setNewItem(null)}
				onComplete={() => {
					setNewItem(null);
				}}
				title="Delete Body"
				description={"Are you sure you want to delete this body?"}
				closeButton="Cancel"
				rightButton={{
					text: "Delete Body",
					onPress: () => {
						deleteBody({
							variables: {
								id: body.id,
							},
						});

						setNewItem(null);
					},
				}}
			></Modal>
		</>
	);
};

Body.propTypes = {
	route: PropTypes.object,
};
