import { useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import React from "react";
import Toast from "react-native-toast-message";
import { Switch, Text, View } from "react-native";

// components
import { Alert } from "../../components/Alert";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
import NoItems from "../../components/NoItems";
import OptionsMenu from "../../components/OptionsMenu";
import { Table } from "../../components/Table";
import Tabs, { Tab } from "../../components/Tabs";
import Button from "../../components/Button";
import { Input } from "../../components/Forms";

// containers
import RateSheetContainer, {
	CategoryContainer,
	ExpenseTypeSlideOver,
	RateFieldContainer,
} from "../../containers/RateSheetContainer";

// GQL
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as fieldGQL from "../../graphql/fields";
import * as rateSheetGQL from "../../graphql/rateSheet";

// Icons
import {
	faDollarSquare,
	faHardHat,
	faList,
	faPen,
	faPlus,
	faSave,
	faSignature,
	faTrash,
	faCog,
} from "@fortawesome/pro-duotone-svg-icons";

// Utils
import { getData } from "../../utils";
import { equipmentItems } from "../../utils/defaultVariables";

export const RateSheets = () => {
	const navigation = useNavigation();
	const [user, setUser] = React.useState({});
	const [openItem, setOpenItem] = React.useState(null);
	const [rateSheets, setRateSheets] = React.useState([]);
	const [deleteItem, setDeleteItem] = React.useState(null);

	React.useEffect(() => {
		getData("@storage_Key").then((data) => {
			setUser(data);
		});
	}, []);

	const { data, loading, refetch, error } = useQuery(
		gql(rateSheetGQL.listRateSheets)
	);

	const handleSuccess = ({ listRateSheets }) => {
		setRateSheets(listRateSheets.items);
	};

	const handleError = (error) => {
		Toast.show({
			type: "error",
			text1: "We could not load the rate sheets",
			text2: error.message,
		});
	};

	React.useEffect(() => {
		if (error) {
			handleError(error);
		}

		if (data) {
			handleSuccess(data);
		}
	}, [error, data]);

	const [deleteRateSheet] = useMutation(gql(rateSheetGQL.deleteRateSheet), {
		onCompleted: () => {
			refetch().then(handleSuccess).catch(handleError);
			setDeleteItem(null);
		},
		onError: () => {
			Alert("We could not delete this rate sheet, please try again");
		},
	});

	if (loading) {
		return <LoadingScreen loadItem="Billing Rate Sheets" />;
	}

	const navigateToRateSheet = (rateSheet) => {
		navigation.navigate("Rate Sheet", {
			id: rateSheet.id,
			name: rateSheet.name,
		});
	};
	return (
		<>
			<Breadcrumb baseLocation="Work In Progress">
				<Crumb name="Rate Sheets" location={"Rate Sheets"} />
			</Breadcrumb>
			<PageHeader
				openMenu
				title="Billing Rates Sheets"
				buttons={
					user.isSuperClerk
						? [
								{
									name: "New Rate Sheet",
									icon: faPlus,
									onPress: () => {
										setOpenItem("billingRateSheet");
									},
								},
						  ]
						: null
				}
			/>
			{rateSheets.length ? (
				<List title="Rate Sheets" headers={["Name", "Client"]} usesOptions>
					{rateSheets.map((sheet, index) => {
						return (
							<ListItem
								key={index}
								items={[
									{
										content: sheet.name,
										onPress: () => {
											navigateToRateSheet(sheet);
										},
									},
									{
										content: sheet.client
											? sheet.client.name
											: "Not Client Specific",
										onPress: () => {
											navigateToRateSheet(sheet);
										},
									},
								]}
								options={
									<OptionsMenu
										options={[
											{
												name: "Edit",
												onPress: () => {
													navigateToRateSheet(sheet);
												},
												icon: faPen,
											},
											{
												name: "Delete",
												onPress: () => {
													setDeleteItem(sheet);
												},
												icon: faTrash,
											},
										]}
									/>
								}
							/>
						);
					})}
				</List>
			) : (
				<NoItems
					surTitle={"No Rate Sheets"}
					title={"Currently No Rate Sheets"}
					text="Click New Rate Sheet to add a rate sheet to the system"
				/>
			)}

			<Modal
				isOpen={deleteItem !== null}
				title="Delete Rate Sheet"
				description={`Are you sure want to delete ${
					deleteItem && deleteItem.name
				}? This action cannot be undone and any billing using this will need to be updated. This is extremely destructive proceed with caution.`}
				rightButton={{
					variant: "red",
					text: "Delete Rate Sheet",
					onPress: () => {
						deleteRateSheet({
							variables: {
								input: {
									id: deleteItem.id,
								},
							},
						}).then(() => {
							setDeleteItem(null);
						});
					},
				}}
			/>

			<RateSheetContainer
				isOpen={openItem === "billingRateSheet"}
				closeRequest={() => {
					setOpenItem(null);
				}}
				onComplete={async () => {
					setOpenItem(null);
					await refetch().then(handleSuccess).catch(handleError);
				}}
			/>
		</>
	);
};

export const RateSheet = ({ route }) => {
	const tabs = [
		{
			name: "Labor Prices",
			icon: faHardHat,
		},
		{
			name: "Categories",
			icon: faList,
		},

		{
			name: "Expense Types",
			icon: faDollarSquare,
		},
		{
			name: "Daily Sheet Fields",
			icon: faSignature,
		},
		{
			name: "Other Settings",
			icon: faCog,
		},
	];
	const { id, name } = route.params;
	const [openItem, setOpenItem] = React.useState(null);
	const [currentItem, setCurrentItem] = React.useState(null);
	const [defaultValues, setDefaultValues] = React.useState(null);
	const [rateSheet, setRateSheet] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState("labor prices");
	const [user, setUser] = React.useState(null);
	const [fields, setFields] = React.useState([]);
	const [projectFields, setProjectFields] = React.useState([]);

	let variablesForGet = {
		variables: {
			id: id,
		},
	};

	React.useEffect(() => {
		getData("@storage_Key").then((data) => {
			setUser(data);
		});
	}, []);

	React.useEffect(() => {
		getRateSheet(variablesForGet).then(handleGetSuccess).catch(handleGetError);
	}, [id]);

	const [getRateSheet, { loading, refetch }] = useLazyQuery(
		gql(rateSheetGQL.getRateSheet),
		{
			fetchPolicy: "network-only",
		}
	);
	// useQuery(gql(rateSheetGQL.listCategories), {
	// 	variables: {
	// 		filter: {
	// 			rateSheet: {
	// 				eq: id,
	// 			},
	// 		},
	// 	},
	// 	onCompleted: (data) => {
	// 	},
	//
	// 	fetchPolicy: "cache-and-network",
	// });

	const handleGetSuccess = ({ data }) => {
		const projectFields = [...data.getRateSheet.fields].filter(
			(field) => field.projectRole !== null
		);
		setRateSheet(data.getRateSheet);
		setFields(data.getRateSheet.dailyDocumentFields || []);
		setProjectFields(projectFields);
	};

	const handleGetError = (error) => {
		Toast.show({
			type: "error",
			text1: "Error Getting Rate Sheet",
			text2: error.message,
		});
	};

	const handleMutationSuccess = (type, action) => {
		Toast.show({
			type: "success",
			text1: `${type} ${action}`,
			text2: `${type} has been ${action.toLowerCase()} successfully`,
		});
		refetch().then(handleGetSuccess).catch(handleGetError);
	};

	const handleMutationsError = (error, type, action) => {
		Toast.show({
			type: "error",
			text1: `Error ${action} ${type}`,
			text2: error.message,
		});
	};

	const preformDeletion = (promise, type) => {
		promise
			.then(() => {
				handleMutationSuccess(type, "Deleted");
			})
			.catch((error) => {
				handleMutationsError(error, type, "Deleted");
			});
	};

	const preformMultipleMutations = (promises, type) => {
		Promise.all(promises)
			.then(() => {
				handleMutationSuccess(type, "Updated");
			})
			.catch((error) => {
				handleMutationsError(error, type, "Updated");
			});
	};

	const [bulkCreateFields] = useMutation(gql(fieldGQL.bulkCreateFields));
	const [bulkUpdateFields] = useMutation(gql(fieldGQL.bulkUpdateFields));
	const [deleteRateField] = useMutation(gql(rateSheetGQL.deleteRateField));
	const [deleteField] = useMutation(gql(fieldGQL.deleteField));
	const [deleteCategory] = useMutation(gql(rateSheetGQL.deleteCategory));
	const [deleteExpenseType] = useMutation(gql(rateSheetGQL.deleteExpenseType));
	const [updateRateSheet] = useMutation(gql(rateSheetGQL.updateRateSheet));

	const getButtons = () => {
		let buttons = [];
		if (user.isSuperClerk) {
			buttons.push({
				name: "Edit",
				icon: faPen,
				onPress: () => {
					setOpenItem("editRateSheet");
				},
			});
		}

		if (currentTab === "labor prices") {
			buttons.push({
				name: "Labor Price",
				icon: faPlus,
				onPress: () => {
					setOpenItem("rateField");
					setCurrentItem(null);
				},
			});
		}

		if (currentTab === "categories") {
			buttons.push({
				name: "Category",
				icon: faPlus,
				onPress: () => {
					setOpenItem("category");
					setCurrentItem(null);
				},
			});
		}

		if (currentTab === "expense types") {
			buttons.push({
				name: "Expense Type",
				icon: faPlus,
				onPress: () => {
					setOpenItem("expenseType");
					setCurrentItem(null);
				},
			});
		}

		return buttons;
	};

	if (!rateSheet || loading) {
		return <LoadingScreen loadItem={name || "Rate Sheet"} />;
	}

	return (
		<>
			<Breadcrumb baseLocation="Work In Progress">
				<Crumb name="Rate Sheets" location={"Rate Sheets"} />
				<Crumb
					name={rateSheet.name}
					href={`/clerk/billing-rates/${rateSheet.id}`}
				/>
			</Breadcrumb>
			<PageHeader goBack title={rateSheet.name} buttons={getButtons()} />
			<Tabs>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							icon={tab.icon}
							active={currentTab === tab.name.toLowerCase()}
							onPress={() => {
								setCurrentTab(tab.name.toLowerCase());
							}}
						/>
					);
				})}
			</Tabs>

			{currentTab === "labor prices" && (
				<>
					{projectFields.length ? (
						<Table
							title="Labor Prices"
							headers={["Name", "Price"]}
							data={rateSheet.fields.map((field) => {
								return {
									cells: [
										{
											content: field.projectRole
												? field.projectRole.name
												: "No Role",
										},
										{
											content: field.price,
										},
									],
									options: [
										{
											name: "Edit",
											onPress: () => {
												setOpenItem("editRateField");
												setCurrentItem(field);
											},
											icon: faPen,
										},
										{
											name: "Delete",
											onPress: () => {
												preformDeletion(
													deleteRateField({
														variables: {
															input: {
																id: field.id,
															},
														},
													}),
													"Labor Price"
												);
											},
											icon: faTrash,
										},
									],
								};
							})}
						/>
					) : (
						<NoItems
							title="No Labor Prices"
							message="There are no labor prices for this rate sheet"
							buttonName="Add Labor Price"
							buttonAction={() => {
								setOpenItem("field");
								setCurrentItem(null);
							}}
						/>
					)}
				</>
			)}

			{currentTab === "categories" && (
				<>
					{rateSheet.categories && rateSheet.categories.length ? (
						<Table
							title="Categories"
							headers={["Name", "Markup", "Per Diem", "Daily Sheet Fields"]}
							data={rateSheet.categories.map((category) => {
								return {
									cells: [
										{
											content: category.name,
										},
										{
											content: category.markupPercentage || "0",
										},
										{
											content: category.isPerDiemCategory ? "Yes" : "No",
										},
										{
											content: category.isDailySheetCategory ? "Yes" : "No",
										},
									],

									options: [
										{
											name: "Edit",
											onPress: () => {
												setOpenItem("editCategory");
												setCurrentItem(category);
											},
											icon: faPen,
										},
										{
											name: "Delete",
											onPress: () => {
												preformDeletion(
													deleteCategory({
														variables: {
															input: {
																id: category.id,
															},
														},
													}),
													"Category"
												);
											},
											icon: faTrash,
										},
									],
								};
							})}
						/>
					) : (
						<NoItems
							surTitle={"No Categories"}
							title={"Currently No Categories"}
							text="Click New Category to add a category to the system"
						/>
					)}
				</>
			)}

			{currentTab === "expense types" && (
				<>
					{rateSheet.expenseTypes && rateSheet.expenseTypes.length ? (
						<Table
							headers={["Name", "Category", "Requires Receipt"]}
							data={rateSheet.expenseTypes.map((expenseType) => {
								return {
									cells: [
										{
											content: expenseType.name,
										},
										{
											content: expenseType.category?.name || "No Category",
										},
										{
											content: expenseType.requiresReceipt ? "Yes" : "No",
										},
									],

									options: [
										{
											name: "Edit",
											onPress: () => {
												setOpenItem("editExpenseType");
												setCurrentItem({
													...expenseType,
													category: expenseType.category?.id || null,
												});
												setDefaultValues({
													category: expenseType.category?.name || null,
												});
											},

											icon: faPen,
										},
										{
											name: "Delete",
											onPress: () => {
												preformDeletion(
													deleteExpenseType({
														variables: {
															input: {
																id: expenseType.id,
															},
														},
													}),
													"Expense Type"
												);
											},
											icon: faTrash,
										},
									],
								};
							})}
						/>
					) : (
						<NoItems
							surTitle={"No Expense Types"}
							title={"Currently No Expense Types"}
							text="Click New Expense Type to add an expense type to the system"
						/>
					)}
				</>
			)}

			{currentTab === "daily sheet fields" && (
				<>
					<View className="flex flex-row items-center p-2">
						<Switch
							value={rateSheet.usesNewFields}
							onValueChange={() => {
								updateRateSheet({
									variables: {
										input: {
											id: rateSheet.id,
											usesNewFields: !rateSheet.usesNewFields,
										},
									},
								})
									.then(handleMutationSuccess("Rate Sheet", "Updated"))
									.catch((error) =>
										handleMutationsError(error, "Rate Sheet", "Update")
									);
							}}
							trackColor={{ false: "#767577", true: "#81b0ff" }}
							thumbColor={rateSheet.usesNewFields ? "#f5dd4b" : "#f4f3f4"}
							ios_backgroundColor="#3e3e3e"
							style={{ marginHorizontal: 10 }}
						/>
						<Text className="text-md font-light dark:text-white">
							{!rateSheet.usesNewFields ? "Classic" : "Dynamic Fields"}
						</Text>
					</View>

					{rateSheet.usesNewFields ? (
						<>
							{fields && (
								<Table
									headers={["Name", "Type", "Amount"]}
									data={fields.map((field, index) => {
										return {
											cells: [
												{
													content: (
														<Input
															value={field.name}
															onChange={(e) => {
																setFields((prev) => {
																	return prev.map((item, i) => {
																		if (i === index) {
																			return {
																				...item,
																				name: e,
																			};
																		}

																		return item;
																	});
																});
															}}
														/>
													),
												},
												{
													content: field.type,
												},
												{
													content: (
														<Input
															value={field.amount}
															onChange={(e) => {
																setFields((prev) => {
																	return prev.map((item, i) => {
																		if (i === index) {
																			return {
																				...item,
																				amount: e,
																			};
																		}

																		return item;
																	});
																});
															}}
														/>
													),
												},
											],

											options: [
												{
													name: "Delete",
													onPress: () => {
														if (field.id) {
															preformDeletion(
																deleteField({
																	variables: {
																		input: {
																			id: field.id,
																		},
																	},
																}),
																"Daily Sheet Field"
															);
														} else {
															setFields((prev) => {
																return prev.filter((item, i) => {
																	return i !== index;
																});
															});
														}
													},
													icon: faTrash,
												},
											],
										};
									})}
								/>
							)}
						</>
					) : (
						<>
							{equipmentItems.map((item, index) => {
								return (
									<View
										key={index}
										className="flex flex-row justify-between items-center p-2 border-b border-gray-200 dark:border-gray-800"
									>
										<View className="flex flex-row justify-start items-center">
											<Text className="text-md font-light dark:text-white">
												{item.name}
											</Text>
										</View>

										<View className="flex flex-row justify-end items-center">
											<Input
												value={rateSheet[item.value]}
												onBlur={(e) => {
													updateRateSheet({
														variables: {
															input: {
																id: rateSheet.id,
																// on blur retuen e.target.value leave in place
																[item.value]: e.target.value,
															},
														},
													})
														.then(
															handleMutationSuccess("Rate Sheet", "Updated")
														)
														.catch((error) =>
															handleMutationsError(
																error,
																"Rate Sheet",
																"Update"
															)
														);
												}}
											/>
										</View>
									</View>
								);
							})}
						</>
					)}

					<View className="flex flex-row justify-start items-center mx-3">
						<View className="mr-3">
							<Button
								text="New Field"
								icon={faPlus}
								onPress={() => {
									setFields((prev) => [
										...prev,
										{
											name: "",
											type: "checkbox",
											rateSheet: rateSheet.id,
										},
									]);
								}}
							/>
						</View>

						<Button
							text="Save"
							icon={faSave}
							onPress={() => {
								const existingFields = fields
									.filter(
										(field) => field.id !== null && field.id !== undefined
									)
									.map((field) => {
										return {
											id: field.id,
											name: field.name,
											type: field.type,
											amount: field.amount,
										};
									});
								const newFields = fields.filter(
									(field) => field.id === null || field.id === undefined
								);
								preformMultipleMutations(
									[
										bulkUpdateFields({
											variables: {
												input: existingFields,
											},
										}),

										bulkCreateFields({
											variables: {
												input: newFields,
											},
										}),
									],
									"Daily Sheet Fields"
								);
							}}
						/>
					</View>
				</>
			)}

			<RateSheetContainer
				isOpen={openItem === "editRateSheet"}
				closeRequest={() => {
					setOpenItem(null);
				}}
				onComplete={() => {
					setOpenItem(null);
					getRateSheet(variablesForGet)
						.then(handleGetSuccess)
						.catch(handleGetError);
				}}
				type="update"
				currentRateSheet={rateSheet}
			/>

			<ExpenseTypeSlideOver
				isOpen={openItem === "expenseType" || openItem === "editExpenseType"}
				closeRequest={() => {
					setOpenItem(null);
				}}
				onComplete={() => {
					setOpenItem(null);
					getRateSheet(variablesForGet)
						.then(handleGetSuccess)
						.catch(handleGetError);
				}}
				type={openItem === "expenseType" ? "create" : "update"}
				rateSheet={{ id: rateSheet.id, name: rateSheet.name }}
				currentExpenseType={
					openItem === "editExpenseType"
						? {
								...currentItem,
								rateSheet: rateSheet?.id,
								category: currentItem?.category?.id,
						  }
						: null
				}
				categoryTypes={rateSheet?.categories}
				defaultValues={defaultValues}
			/>

			<CategoryContainer
				isOpen={openItem === "category" || openItem === "editCategory"}
				closeRequest={() => {
					setOpenItem(null);
				}}
				onComplete={() => {
					setOpenItem(null);
					getRateSheet(variablesForGet)
						.then(handleGetSuccess)
						.catch(handleGetError);
				}}
				currentCategory={openItem === "editCategory" ? currentItem : null}
				type={openItem === "category" ? "create" : "update"}
				rateSheet={{ id: rateSheet.id, name: rateSheet.name }}
			/>

			<RateFieldContainer
				isOpen={openItem === "rateField" || openItem === "editRateField"}
				closeRequest={() => {
					setOpenItem(null);
				}}
				onComplete={() => {
					setOpenItem(null);
					getRateSheet(variablesForGet)
						.then(handleGetSuccess)
						.catch(handleGetError);
				}}
				currentRateField={
					openItem === "editRateField"
						? {
								id: currentItem.id,
								projectRole: currentItem.projectRole
									? currentItem.projectRole.id
									: null,
								price: currentItem.price,
						  }
						: null
				}
				type={openItem === "rateField" ? "create" : "update"}
				rateSheet={{ id: rateSheet.id, name: rateSheet.name }}
				defaultValues={
					openItem === "editRateField"
						? {
								projectRole: currentItem.projectRole
									? currentItem.projectRole.name
									: "",
						  }
						: {}
				}
			/>
		</>
	);
};

RateSheet.propTypes = {
	route: PropTypes.object,
};
