import PropTypes from "prop-types";
import React, { useState } from "react";

// Components
import { Alert } from "../components/Alert";
import { Checkbox, Input } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL

import { gql, useMutation } from "@apollo/client";
import * as projectRoleGQL from "../graphql/admin/projectRole";

const ProjectRole = ({
	isOpen,
	currentRole,
	onComplete,
	closeRequest = () => {},
	type,
}) => {
	let [role, setRole] = useState({});

	React.useEffect(() => {
		if (currentRole) {
			setRole(currentRole);
		}
	}, [currentRole]);

	const [createProjectRole] = useMutation(
		gql(projectRoleGQL.createProjectRole),
		{
			onCompleted: (data) => {
				onComplete(data.createProjectRole);
			},
			onError: () => {
				Alert("Error creating project role");
			},
		}
	);

	const [updateProjectRole] = useMutation(
		gql(projectRoleGQL.updateProjectRole),
		{
			onCompleted: () => {
				onComplete();
			},
			onError: () => {
				Alert("Error updating project role");
			},
		}
	);

	return (
		<SlideOver
			closeRequest={() => {
				closeRequest();
			}}
			isOpen={isOpen}
			name={type === "create" ? "New Project Role" : "Edit Project Role"}
			description={
				type === "create" ? "Add a new project role" : "Edit the project role"
			}
			onSubmit={() => {
				if (type === "create") {
					createProjectRole({
						variables: {
							input: role,
						},
					});
				} else {
					updateProjectRole({
						variables: {
							input: role,
						},
					});
				}
			}}
		>
			<Input
				label="Name"
				value={role.name}
				onChange={(e) => setRole({ ...role, name: e })}
			/>
			<Input
				label="Sage Item ID"
				value={role.sageItem}
				onChange={(e) => setRole({ ...role, sageItem: e })}
			/>
			<Input
				label="Sage Item Nonstandard ID"
				value={role.sageItemOvertime}
				onChange={(e) =>
					setRole({
						...role,
						sageOvertimeItem: e,
					})
				}
			/>
			<Checkbox
				label="Active"
				value={role.active}
				description="Check to make this role active"
				onChange={(e) => setRole({ ...role, active: e })}
			/>
		</SlideOver>
	);
};

ProjectRole.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	currentRole: PropTypes.object,
};

export default ProjectRole;
