import SingleSubcontractorAdmin from "../views/SubcontractorAdmin/Users";
import SubAdminUser from "../views/SubcontractorAdmin/User";
import SubAdminProjects, {
	SubProject,
} from "../views/SubcontractorAdmin/Project";
import { faProjectDiagram, faUser } from "@fortawesome/pro-duotone-svg-icons";

export const subcontractorAdminNav = [
	{
		name: "Subcontractor Users",
		component: SingleSubcontractorAdmin,
		path: "/subcontractor-admin/users",
		icon: faUser,
	},
	{
		name: "Subcontractor User",
		component: SubAdminUser,
		path: "/subcontractor-admin/users/:id",
		noShow: true,
	},
	{
		name: "Subcontractor Projects",
		component: SubAdminProjects,
		path: "/subcontractor-admin/projects",
		icon: faProjectDiagram,
	},
	{
		name: "Subcontractor Project",
		component: SubProject,
		path: "/subcontractor-admin/projects/:id",
		noShow: true,
	},
];
