import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { Text, TouchableOpacity, View } from "react-native";

// components
import { Alert } from "../components/Alert";
import { Checkbox, FieldSet, Input, Select, Switch } from "../components/Forms";
import { addNotification } from "../components/Notification";
import SlideOver from "../components/Slideover";

// gql

import { gql, useMutation, useQuery } from "@apollo/client";
import * as projectsData from "../graphql/projectsScreen";
import * as rateSheetGQL from "../graphql/rateSheet";
import * as userGQL from "../graphql/user";

// utils

import { getData, projectStages, SentryLogger } from "../utils";

// icons

import {
	faBuilding,
	faDollar,
	faHashtag,
	faInfoCircle,
	faUser,
} from "@fortawesome/pro-duotone-svg-icons";

const ProjectSlideOver = ({
	isOpen,
	closeRequest = () => {},
	type,
	onComplete = () => {},
	currentProject,
	defaultSelectValues = {},
	clerking = false,
}) => {
	const [user, setUser] = React.useState(null);
	const [project, setProject] = React.useState({
		isWorkspace: false,
		unscheduledEquipmentCharge: true,
	});
	const [newClient, setNewClient] = React.useState({});
	const [projectRole, setProjectRole] = React.useState([]);
	const [clients, setClients] = React.useState([]);
	const [clientLocations, setClientLocations] = React.useState([]);
	const [billingRates, setBillingRates] = React.useState([]);
	const [addClient, setAddClient] = React.useState(false);
	const [addLocation, setAddLocation] = React.useState(false);
	const [newLocation, setNewLocation] = React.useState({});
	const [offices, setOffices] = React.useState([]);
	const [projectRoles, setProjectRoles] = React.useState([]);
	const [clerks, setClerks] = React.useState([]);

	React.useEffect(() => {
		getData("@storage_Key").then((user) => {
			setUser(user);
		});
	}, []);

	React.useEffect(() => {
		if (currentProject) {
			setProject(currentProject);
		}
	}, [currentProject]);

	// queries

	useQuery(gql(projectsData.listClients), {
		onCompleted: (data) => {
			let items = data.listClients.items;
			setClients([...items]);
		},

		fetchPolicy: "cache-and-network",
	});

	useQuery(gql(projectsData.listLocations), {
		variables: {
			filter: {
				isBranchLocation: true,
			},
		},
		onCompleted: (data) => {
			let items = data.listLocations.items;
			setOffices([...items]);
		},

		fetchPolicy: "cache-and-network",
	});

	useQuery(gql(projectsData.listProjectRoles), {
		variables: {
			filter: {
				active: true,
			},
		},
		onCompleted: (data) => {
			let items = data.listProjectRoles.items;
			setProjectRoles([...items]);
		},
	});

	useQuery(gql(rateSheetGQL.listRateSheets), {
		onCompleted: (data) => {
			setBillingRates([...data.listRateSheets.items]);
		},
		onError: () => {
			Alert("There was an error getting the rate sheets. Please try again");
		},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "cache-and-network",
	});

	useQuery(gql(userGQL.listUsers), {
		variables: {
			filter: {
				isActive: true,
				isClerk: true,
			},
		},
		onCompleted: (data) => {
			setClerks([...data.listUsers.items]);
		},
	});

	// mutations

	const [createProject] = useMutation(gql(projectsData.createProject), {
		onCompleted: async (data) => {
			try {
				await createProjectTeamMember({
					variables: {
						input: {
							project: data.createProject.id,
							teamMember: user.id,
							projectRole: projectRole,
							isSuperAdmin: true,
							added: DateTime.local().toISO(),
						},
					},
				});
				onComplete(data.createProject.id);
				setProject({
					isWorkspace: false,
					unscheduledEquipmentCharge: true,
				});
			} catch (err) {
				Alert("There was an error adding you to the project", err);
			}
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert(error.message);
		},
	});

	const [createProjectTeamMember] = useMutation(
		gql(projectsData.createProjectTeamMember),
		{
			onError: () => {
				addNotification({
					title: "Something Went Wrong",
					text: "There was an error assign you to the project. Please contact the tech department by going to srpinfield.com/help",
					type: "error",
					supportText: true,
					time: 5000,
				});
			},
		}
	);

	const [createClientLocation] = useMutation(gql(projectsData.createLocation), {
		onCompleted: (data) => {
			createClient({
				variables: {
					input: {
						name: newClient.name,
						location: data.createLocation.id,
					},
				},
			});
		},
		onError: () => {
			Alert("There was an error creating the client location");
		},
	});

	const [createClient] = useMutation(gql(projectsData.createClient), {
		onCompleted: (data) => {
			project.client = data.createClient.id;
		},
		onError: () => {
			Alert("There was an error creating the client");
		},
	});

	const [createLocation] = useMutation(gql(projectsData.createLocation), {
		onCompleted: (data) => {
			project.location = data.createLocation.id;
		},
		onError: () => {
			Alert(
				"There was an error creating the project location. You can add it later under the project information tab"
			);
		},
	});

	const [updateProject] = useMutation(gql(projectsData.updateProject), {
		onCompleted: (data) => {
			onComplete(data.updateProject);
			setProject({
				isWorkspace: false,
				unscheduledEquipmentCharge: true,
			});
			// addNotification({
			// 	title: "Project Updated",
			// 	text: "The project was successfully updated",
			// 	type: "success",
			// 	time: 5000,
			// });
		},
		onError: () => {
			Alert("Error");

			Alert({
				title: "Uh Oh Something Went Wrong",
				text: "There was an error updating the project. Please try again",
				type: "error",
				supportText: true,
				time: 5000,
			});
		},
	});

	return (
		<SlideOver
			closeRequest={() => {
				setProject({
					isWorkspace: false,
					unscheduledEquipmentCharge: true,
				});
				closeRequest();
			}}
			isOpen={isOpen}
			name={type === "create" ? "Create Project" : "Edit Project"}
			description={
				type === "create"
					? "Get started by filling in the information below to create your new project."
					: "Get started by updating the information below."
			}
			onSubmit={async () => {
				if (type === "create") {
					if (!project.title) {
						return Alert("Missing Project Title");
					}
					if (!project.office) {
						return Alert("Missing Project Office");
					}
					if (!project.client && !addClient) {
						return Alert("Missing Project Client");
					}
					if (!projectRole) {
						return Alert("Missing Project Role");
					}
				}

				// create client location and new client
				if (addClient) {
					await createClientLocation({
						variables: {
							input: {
								lineOne: newClient.lineOne,
								lineTwo: newClient.lineTwo,
								city: newClient.city,
								state: newClient.state,
								zip: newClient.zip,
							},
						},
					});

					await createLocation({
						variables: {
							input: {
								name: newLocation.name,
								lineOne: newLocation.lineOne,
								lineTwo: newLocation.lineTwo,
								city: newLocation.city,
								state: newLocation.state,
								zip: newLocation.zip,
								client: project.client,
							},
						},
					});
				}

				// create project location

				if (addLocation) {
					await createLocation({
						variables: {
							input: {
								name: newLocation.name,
								lineOne: newLocation.lineOne,
								lineTwo: newLocation.lineTwo,
								city: newLocation.city,
								state: newLocation.state,
								zip: newLocation.zip,
								client: project.client,
							},
						},
					});
				}

				if (type === "create") {
					// create project

					let newProjectInput = project;
					newProjectInput.stage = "Mobilization";
					newProjectInput.startDate = DateTime.local().toISO();
					await createProject({
						variables: {
							input: newProjectInput,
						},
					});
				} else {
					await updateProject({ variables: { input: project } });
				}
			}}
			buttonRight={type === "create" ? "Create Project" : "Update Project"}
		>
			<Input
				required
				name="project"
				label="Project Title"
				onChange={(e) => {
					setProject({ ...project, title: e });
				}}
				icon={faInfoCircle}
				// placeholder={"Acme Water Loss"}
				value={project.title}
			/>
			<Input
				required
				icon={faHashtag}
				label="SageIntacct"
				onChange={(e) => {
					setProject({
						...project,
						sageIntacct: e,
						number: e,
					});
				}}
				value={project.sageIntacct || project.number}
				placeholder="WIP, Estimate, Invoice, or Project Number"
			/>
			<>
				{clerking && (
					<Input
						required
						icon={faDollar}
						label="Per Diem"
						type={"number"}
						value={project.perDiem}
						onChange={(e) => {
							setProject({
								...project,
								perDiem: e,
							});
						}}
						checkedValue={
							project.perDiem ? project.perDiem : `${project.perDiem}`
						}
						placeholder="60.00"
					/>
				)}

				{clerking && (
					<Select
						label="Billing Rate"
						name="Billing Rate"
						onChange={(e) => {
							setProject({ ...project, billingRate: e.value });
						}}
						options={billingRates.map((rate) => ({
							value: rate.id,
							name: rate.name,
						}))}
						defaultValue={defaultSelectValues.billingRate}
					/>
				)}

				{type === "update" && (
					<Select
						name="Stage"
						onChange={(e) => {
							setProject({ ...project, stage: e.value });
						}}
						options={projectStages.map((stage) => {
							return {
								name: stage.name,
								value: stage.name,
							};
						})}
						defaultValue={defaultSelectValues.stage}
					/>
				)}
			</>
			<Select
				name="Project Office"
				icon={faBuilding}
				onChange={(e) => {
					setProject({ ...project, office: e.value });
				}}
				options={offices
					.sort((a, b) => {
						if (a.name > b.name) return 1;
						if (a.name < b.name) return -1;
						return 0;
					})
					.map((office) => {
						return {
							name: office.name,
							value: office.id,
						};
					})}
				defaultValue={defaultSelectValues.office}
			/>
			<Switch
				label="Workspace"
				onChange={() => {
					setProject({
						...project,
						isWorkspace: !project.isWorkspace,
					});
				}}
				info="Making this a workspace will allow you to group multiple projects together"
			/>
			<View className="mb-2"></View>

			<>
				<TouchableOpacity
					testID={"button-client"}
					onPress={(e) => {
						setAddClient(!addClient);
						setProject({ ...project, location: e.value });
					}}
					style={{ lineHeight: 0.1 }}
					className="text-sm font-medium text-right text-indigo-600 hover:text-indigo-500 cursor-pointer"
				>
					<View className="mb-6"></View>
					<View>
						{addClient ? (
							<Text testID="button-client-text" className="text-red-500">
								Cancel New Client Creation
							</Text>
						) : (
							<Text testID="button-client-text" className="dark:text-white">
								New Client
							</Text>
						)}
					</View>
				</TouchableOpacity>

				<>
					{addClient ? (
						<>
							<FieldSet
								legend="Client Information"
								description="Enter client name and address"
							>
								<Input
									required
									label="Client Name"
									placeholder="Example Company"
									onChange={(e) => {
										setNewClient({ ...newClient, name: e });
									}}
								/>
								<Input
									required
									label="Line One"
									placeholder="123 Client Way"
									onChange={(e) => {
										setNewClient({ ...newClient, lineOne: e });
									}}
								/>
								<Input
									label="Two One"
									placeholder="Suite 103"
									onChange={(e) => {
										setNewClient({ ...newClient, lineTwo: e });
									}}
								/>
								<Input
									required
									label="City"
									placeholder="City"
									onChange={(e) => {
										setNewClient({ ...newClient, city: e });
									}}
								/>
								<Input
									required
									label="State"
									placeholder="State"
									onChange={(e) => {
										setNewClient({ ...newClient, state: e });
									}}
								/>
								<Input
									required
									label="Zip"
									placeholder="Zip"
									onChange={(e) => {
										setNewClient({ ...newClient, zip: e });
									}}
								/>
								<Input
									label="Sage Intacct"
									placeholder="CUS0001"
									onChange={(e) => {
										setNewClient({ ...newClient, sageIntacct: e });
									}}
									helper="If unknown, leave blank and the clerk team will add it later"
								/>
							</FieldSet>
						</>
					) : (
						<>
							<Select
								className="mb-2"
								name="Client"
								icon={faUser}
								onChange={(value) => {
									let client = JSON.parse(value.value);
									setClientLocations(client.locations);
									setProject({ ...project, client: client.id });
								}}
								options={clients
									.sort((a, b) => {
										if (a.name > b.name) return 1;
										if (a.name < b.name) return -1;
										return 0;
									})
									.map((client) => {
										return {
											name: client.name,
											value: JSON.stringify(client),
										};
									})}
								defaultValue={defaultSelectValues.client}
							/>
						</>
					)}
				</>

				{!addClient && (
					<TouchableOpacity
						testID={"button-location"}
						onPress={(e) => {
							setAddLocation(!addLocation);
							setProject({ ...project, location: e.value });
						}}
						style={{ lineHeight: 0.1 }}
						className="mb-2 text-sm font-medium text-right text-indigo-600 hover:text-indigo-500 cursor-pointer"
					>
						{addLocation ? (
							<Text testID="button-location-text" className="text-red-500">
								Cancel New Location Creation
							</Text>
						) : (
							<Text testID="button-location-text" className="dark:text-white">
								New Location
							</Text>
						)}
					</TouchableOpacity>
				)}

				<>
					{addLocation || addClient ? (
						<FieldSet
							legend="Project Location"
							description="Enter project location name and address"
						>
							<Input
								required
								label="Location Name"
								placeholder="Example Company"
								onChange={(e) => {
									setNewLocation({
										...newLocation,
										name: e,
									});
								}}
							/>
							<Input
								required
								label="Line One"
								placeholder="123 Client Way"
								onChange={(e) => {
									setNewLocation({
										...newLocation,
										lineOne: e,
									});
								}}
							/>
							<Input
								label="Two One"
								placeholder="Suite 103"
								onChange={(e) => {
									setNewLocation({
										...newLocation,
										lineTwo: e,
									});
								}}
							/>
							<Input
								required
								label="City"
								placeholder="City"
								onChange={(e) => {
									setNewLocation({
										...newLocation,
										city: e,
									});
								}}
							/>
							<Input
								required
								label="State"
								placeholder="State"
								onChange={(e) => {
									setNewLocation({
										...newLocation,
										state: e,
									});
								}}
							/>
							<Input
								required
								label="Zip"
								placeholder="Zip"
								onChange={(e) => {
									setNewLocation({ ...newLocation, zip: e });
								}}
							/>
						</FieldSet>
					) : (
						<>
							{clientLocations.length > 0 ? (
								<Select
									name="Project Location"
									onChange={(value) => {
										setProject({ ...project, location: value.value });
									}}
									options={clientLocations
										.sort((a, b) => {
											if (a.name > b.name) return 1;
											if (a.name < b.name) return -1;
											return 0;
										})
										.map((location) => {
											return {
												name: location.name,
												value: location.id,
											};
										})}
									defaultValue={defaultSelectValues.location}
								/>
							) : null}
						</>
					)}
				</>

				<>
					{type === "create" && (
						<Select
							required
							name="Project Role"
							onChange={(e) => {
								setProjectRole(e.value);
							}}
							options={projectRoles
								.sort((a, b) => {
									if (a.name > b.name) return 1;
									if (a.name < b.name) return -1;
									return 0;
								})
								.map((role) => {
									return {
										name: role.name,
										value: role.id,
									};
								})}
						/>
					)}
				</>

				<Checkbox
					name="checkbox"
					label="Large Loss"
					description="Check if this Project is a Large Loss"
					placeholder="Floor One"
					onChange={() =>
						setProject({
							...project,
							isLargeLoss: !project.isLargeLoss,
						})
					}
					checkedValue={project.isLargeLoss ? true : false}
				/>

				<>
					{clerking ? (
						<FieldSet legend="Clerk Settings">
							<Switch
								label="Phase Billing"
								defaultValue={project.usePhaseBilling}
								onChange={() => {
									setProject({
										...project,
										usePhaseBilling: project.usePhaseBilling,
									});
								}}
								info="Will this project be billed by Phase instead of Period?"
							/>

							<Checkbox
								name="Unscheduled Equipment Fee"
								label="Unscheduled Equipment Fee"
								description="Check if this to add an Unscheduled Equipment Fee to the invoice"
								onChange={() => {
									setProject({
										...project,
										unscheduledEquipmentCharge:
											!project.unscheduledEquipmentCharge,
									});
								}}
								checkedValue={project.unscheduledEquipmentCharge ? true : false}
							/>

							<Checkbox
								name="checkbox"
								label="Unscheduled Labor Fee"
								description="Check if this to add an Unscheduled Labor Fee to the invoice"
								onChange={(value) =>
									setProject({
										...project,
										isCAT: value,
									})
								}
								checkedValue={project.isCAT ? true : false}
							/>

							<Checkbox
								name="checkbox"
								label="Billing Review"
								description="Check if this Project is in Billing Review"
								onChange={(value) =>
									setProject({
										...project,
										inBillingReview: value,
									})
								}
								checkedValue={project.inBillingReview ? true : false}
							/>
							<Checkbox
								name="checkbox"
								label="Billed"
								description="Check if this Project is Billed"
								onChange={(value) =>
									setProject({
										...project,
										isBilled: value,
									})
								}
								checkedValue={project.isBilled ? true : false}
							/>

							<Select
								name="Assigned Clerk"
								onChange={(value) => {
									setProject({ ...project, clerk: value.value });
								}}
								options={
									clerks
										? clerks
												.sort((a, b) => {
													if (a.fullName > b.fullName) return 1;
													if (a.fullName < b.fullName) return -1;
													return 0;
												})
												.map((clerk) => {
													return {
														name: clerk.fullName,
														value: clerk.id,
													};
												})
										: []
								}
								defaultValue={defaultSelectValues.clerk || "Not Assigned"}
							/>
						</FieldSet>
					) : null}
				</>
			</>
		</SlideOver>
	);
};

ProjectSlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	currentProject: PropTypes.object,
	defaultSelectValues: PropTypes.object,
	clerking: PropTypes.bool,
};

export { ProjectSlideOver };
