import PropTypes from "prop-types";
import React from "react";

// Components
import { Alert } from "../components/Alert";
import { Checkbox, Input } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation } from "@apollo/client";
import * as labGQL from "../graphql/lab";

export const LabSlideOver = ({
	type = "create",
	currentLab,
	isOpen,
	closeRequest,
	onComplete,
}) => {
	const [lab, setLab] = React.useState({});

	React.useEffect(() => {
		if (currentLab) {
			setLab(currentLab);
		}
	}, [currentLab]);

	const [createLab] = useMutation(gql(labGQL.createLab), {
		onCompleted: (data) => {
			onComplete(data.createLab);

			closeRequest();
		},
		onError: () => {
			Alert("Lab could not be created. Please try again");
		},
	});

	const [updateLab] = useMutation(gql(labGQL.updateLab), {
		onCompleted: (data) => {
			onComplete(data.updateLab);
		},
		onError: () => {
			Alert("Lab could not be updated. Please try again");
		},
	});

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "New Lab" : "Update Lab"}
			closeRequest={() => closeRequest()}
			onSubmit={() => {
				if (type === "create") {
					createLab({
						variables: {
							input: lab,
						},
					});
				} else {
					updateLab({
						variables: {
							input: lab,
						},
					});
				}
			}}
			buttonRight={type === "create" ? "Create" : "Update"}
		>
			<Input
				required
				type="text"
				label={"Lab Name"}
				placeholder="Lab Name"
				value={lab.name}
				onChange={(e) =>
					setLab({
						...lab,
						name: e,
					})
				}
			/>
			<Checkbox
				label="EMPAT Accredited"
				description={"Is this lab EMPAT Accredited?"}
				checked={lab.isEMPATAccredited}
				onChange={(e) =>
					setLab({
						...lab,
						isEMPATAccredited: e,
					})
				}
			/>
		</SlideOver>
	);
};

LabSlideOver.propTypes = {
	type: PropTypes.string,
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func,
	onComplete: PropTypes.func,
	currentLab: PropTypes.object,
};
