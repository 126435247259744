import React from "react";
import { View } from "react-native";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";

// components

import { PageHeader } from "../../../components/Header.js";
import Tabs, { Tab } from "../../../components/Tabs";
import LoadingScreen from "../../../components/LoadingScreen";
import { List, ListItem } from "../../../components/List.js";

// Containers
import { LocationSlideOver } from "../../../containers/LocationSlideOver";
import { ClientSlideOver } from "../../../containers/ClientSlideOver";

// Utils
import { getData, SentryLogger } from "../../../utils";

// GQL

import { gql, useQuery } from "@apollo/client";
import * as clientGQL from "../../../graphql/clients.js";

// Icons

import {
	faRocket,
	faMapPin,
	faUser,
	faPencil,
	faPlus,
} from "@fortawesome/pro-duotone-svg-icons";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb.js";

export const Clients = ({ search }) => {
	const navigation = useNavigation();
	let [clients, setClients] = React.useState([]);

	const { loading: clientsLoading } = useQuery(gql(clientGQL.listClients), {
		variables: {
			limit: 5000,
		},
		onCompleted: (data) => {
			setClients(data.listClients.items);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
	});

	if (clientsLoading) return <LoadingScreen loadItem={"Clients"} />;

	const navigateToClient = (id) => {
		navigation.navigate("SingleClient", {
			id: id,
		});
	};

	return (
		<>
			<Breadcrumb className="mt-2">
				<Crumb name="Clients" location="Clients" />
			</Breadcrumb>
			<PageHeader title="Clients" openMenu />

			<List headers={["Name"]}>
				{clients &&
					clients
						.filter(
							(client) =>
								client.name && client.name.toLowerCase().includes(search)
						)
						.sort((a, b) => {
							if (a.name < b.name) return -1;
							if (a.name > b.name) return 1;
							return 0;
						})
						.map((client, index) => {
							return (
								<ListItem
									key={index}
									items={[
										{
											content: client.name,
											onPress: () => navigateToClient(client.id),
											// href: `/app/clients/${client.id}`
										}, // routes.js path: '/clients/:id'
									]}
								/>
							);
						})}
			</List>
		</>
	);
};

Clients.propTypes = {
	search: PropTypes.string,
};

const tabs = [
	{ name: "Projects", href: "#", icon: faRocket },
	{ name: "Locations", href: "#", icon: faMapPin },
	{ name: "Users", href: "#", icon: faUser },
];

export const SingleClient = ({ route }) => {
	const { id } = route.params;
	const navigation = useNavigation();
	const [user, setUser] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState(tabs[0].name);
	const [client, setClient] = React.useState(null);
	const [newItem, setNewItem] = React.useState(null);

	React.useEffect(() => {
		getData("@storage_Key")
			.then((user) => {
				setUser(user);
			})
			.catch((err) => SentryLogger(err));
	}, []);

	const { refetch } = useQuery(gql(clientGQL.getClient), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setClient(data.getClient);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			addNotification({
				title: " Client Not Found",
				text: "Can not find Client. If you think this is an error please contact support at help.srpinfield.com",
				type: "error",
				time: 5000,
				supportText: true,
			});
			return history.push("/app/clients");
		},
	});

	const getHeaderButtons = () => {
		switch (currentTab) {
			case "Projects":
				if (user.isTeamMember) {
					return [
						{
							name: "Edit Client",
							icon: faPencil,
							onPress: () => {
								setNewItem("editClient");
							},
						},
					];
				}
				break;
			case "Locations":
				if (user.isTeamMember) {
					return [
						{
							name: "Add Location",
							icon: faPlus,
							onPress: () => {
								setNewItem("location");
							},
						},
					];
				}
				break;
			default:
				return [];
		}
	};

	if (!client || !user) {
		return <LoadingScreen loadItem="Client" />;
	}

	const navigateToProject = (id) => {
		navigation.navigate("Project", {
			id: id,
		});
	};

	return (
		<>
			<Breadcrumb>
				<Crumb name="Clients" location="Clients" />
				<Crumb
					name={client.name}
					location="SingleClient"
					params={{
						id: client.id,
					}}
				/>
			</Breadcrumb>
			<PageHeader
				title={client.name}
				goBack={{
					location: "Clients",
					params: {
						id: client.id,
					},
				}}
				info={[
					{
						text: client.location
							? `${client.location.lineOne}, ${
									client.location.lineTwo ? `${client.location.lineTwo},` : ""
							  }  ${client.location.lineOne}, ${client.location.state} ${
									client.location.zip
							  }`
							: "No Client Location",
						icon: faMapPin,
					},
				]}
				buttons={getHeaderButtons()}
			/>
			<Tabs>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							current={currentTab === tab.name ? true : false}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			{currentTab === "Projects" && (
				<View className="px-2 pt-2">
					<List headers={["Title", "Stage", "Address"]}>
						{client &&
							client.projects.map((project, index) => {
								return (
									<ListItem
										key={index}
										items={[
											{
												content: project.title,
												onPress: () => navigateToProject(project.id),

												// href: `/app/project/${project.id}`,
											},
											{
												content: project.stage ? project.stage : "No Stage Set",
												onPress: () => navigateToProject(project.id),
												// href: `/app/project/${project.id}`,
											},
											{
												content: project.location
													? project.location.name
													: "No Location",
												onPress: () => navigateToProject(project.id),
												// href: `/app/project/${project.id}`,
											},
										]}
									/>
								);
							})}
					</List>
				</View>
			)}

			{currentTab === "Locations" && (
				<View className="px-2 pt-2">
					<List
						headers={["Name", "Line One", "Line Two", "City", "State", "Zip"]}
					>
						{client &&
							client.locations.map((location, index) => {
								return (
									<ListItem
										key={index}
										items={[
											{
												content: location.name,
											},
											{
												content: location.lineOne,
											},
											{
												content: location.lineTwo,
											},
											{
												content: location.city,
											},
											{
												content: location.state,
											},
											{
												content: location.zip,
											},
										]}
									/>
								);
							})}
					</List>
				</View>
			)}
			{currentTab === "Users" && (
				<View className="px-2 pt-2">
					<List headers={["First Name", "Last Name", "Email", "Role"]}>
						{client &&
							client.users.map((user, index) => {
								return (
									<ListItem
										key={index}
										items={[
											{
												content: user.firstName,
												onPress: () => navigateToUser(user.id),
												// href: `/app/clients/${user.id}`,
											},
											{
												content: user.lastName,
												onPress: () => navigateToUser(user.id),
												// href: `/app/clients/${user.id}`,
											},
											{
												content: user.email,
												onPress: () => navigateToUser(user.id),
												// href: `/app/clients/${user.id}`,
											},
											{
												content: user.role,
												onPress: () => navigateToUser(user.id),
												// href: `/app/clients/${user.id}`,
											},
										]}
									/>
								);
							})}
					</List>
				</View>
			)}
			<LocationSlideOver
				isOpen={newItem === "location"}
				closeRequest={() => setNewItem("")}
				currentLocation={{ client: client.id }}
				onComplete={() => {
					setNewItem("");
					refetch();
				}}
				type="create"
			/>
			<ClientSlideOver
				isOpen={newItem === "editClient"}
				closeRequest={() => setNewItem("")}
				currentClient={{
					id: client.id,
					name: client.name,
					sageIntacct: client.sageIntacct,
				}}
				currentLocation={{
					id: client.location ? client.location.id : null,
					lineOne: client.location ? client.location.lineOne : null,
					lineTwo: client.location ? client.location.lineTwo : "",
					city: client.location ? client.location.city : null,
					state: client.location ? client.location.state : null,
					zip: client.location ? client.location.zip : null,
				}}
				onComplete={() => {
					setNewItem("");
					refetch();
				}}
				type="update"
			/>
		</>
	);
};

SingleClient.propTypes = {
	route: PropTypes.object,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
};
