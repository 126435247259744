export const listProjects = /* GraphQL */ `
	query ListProjects(
		$filter: TableProjectFilterInput
		$limit: Int
		$nextToken: String
	) {
		listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				title
				number
				stage
				usePhaseBilling
				billingPhases {
					id
					name
				}
				clerk {
					id
					fullName
				}
				client {
					id
					name
				}
				workspace {
					id
					title
				}
			}
			nextToken
		}
	}
`;

export const createProjectTeamMember = /* GraphQL */ `
	mutation CreateProjectTeamMember($input: CreateProjectTeamMemberInput!) {
		createProjectTeamMember(input: $input) {
			id
			projectRole {
				id
				name
			}
			teamMember {
				id
			}
			project {
				id
			}
		}
	}
`;

export const getProject = /* GraphQL */ `
	query GetProject($id: ID!) {
		getProject(id: $id) {
			id
			title
			stage
			sageIntacct
			perDiem
			isBilled
			isCAT
			usePhaseBilling
			isLargeLoss
			unscheduledEquipmentCharge
			clerk {
				id
				fullName
			}
			client {
				id
				name
				sageIntacct
			}
			location {
				id
				name
			}
			office {
				id
				name
			}
			billingRate {
				id
				name
			}
			billingRate {
				id
				expenseTypes {
					id
					name
					category {
						id
						name
					}
					requiresReceipt
				}
				dailyDocumentFields {
					id
					name
					type
					amount
				}
			}
			billing {
				laborDates {
					date
					total
					items {
						user {
							id
							firstName
							lastName
						}
						projectRole {
							id
							name
						}
						timeEntries {
							id
							timeIn
							timeOut
							approved
							approvedBy {
								id
								firstName
								lastName
							}
							approvedAt
							timeInBilling
							timeOutBilling
						}
						lunchDeduction
						regularHours
						otHours
						premHours
						totalHours
						regularRate
						otRate
						premRate
						mobilizationDemobRate
						mobilizationDemobHours
						standbyRate
						standbyHours
						totalCharge
					}
				}
				categories {
					name
					subTotal
					markup
					total
				}
				total
				totalLabor
				subTotalLabor
				laborMarkup
				catMarkup
			}
			expenses {
				id
				date
				amount
				receipt
				expenseType {
					id
					name
					requiresReceipt
					category {
						id
						name
					}
				}
				description
				user {
					fullName
				}
			}

			timeEntries {
				id
				timeIn
				timeOut
				timeInBilling
				timeOutBilling
				projectApproved
				projectApprovedBy {
					id
					firstName
					lastName
				}
				projectApprovedAt
				approved
				approvedBy {
					id
					firstName
					lastName
				}
				approvedAt
				user {
					id
					firstName
					lastName
				}
				dailyDocument {
					id
					signature
				}
			}
			timeEntriesSummary {
				date
				timeEntries {
					id
					timeIn
					timeOut
					timeInBilling
					timeOutBilling
					projectApproved
					projectApprovedBy {
						id
						firstName
						lastName
					}
					projectApprovedAt
					approved
					approvedBy {
						id
						firstName
						lastName
					}
					approvedAt
					user {
						id
						firstName
						lastName
						fullName
					}
					dailyDocument {
						id
						signature
					}
					possibleDuplications {
						id
						timeIn
						timeOut
						project {
							title
						}
					}
					isMobilizing
					isDemobilizing
					isStandby
				}
				totalHours
				billableTimeEntries
				nonBillableTimeEntries
				approvedTimeEntries
				unapprovedTimeEntries
				missingTimeEntries {
					id
					fullName
					firstName
					lastName
					profilePicture
					role
				}
			}
			team {
				id
				isSuperAdmin
				isAdmin
				isGeneral
				isReadOnly
				projectRole {
					id
					name
				}
				isActive
				added
				teamMember {
					id
					firstName
					lastName
					profilePicture
					role
					isTeamMember
					isTempWorker
					isContractor
					client {
						id
					}
				}
			}
			billingPeriods {
				id
				name
				startDate
				endDate
				status
			}
			billingPhases {
				id
				name
			}

			dailyDocumentSummaries {
				date
				perDiem
				mobileCommandCenter
				dailyDocuments {
					id
					date
					perDiem
				}
				missingDailyDocuments {
					id
					fullName
					firstName
					lastName
					profilePicture
				}
			}
		}
	}
`;

export const deleteTeamMember = /* GraphQL */ `
	mutation DeleteProjectTeamMember($input: DeleteProjectTeamMemberInput!) {
		deleteProjectTeamMember(input: $input) {
			id
		}
	}
`;

export const listProjectRoles = /* GraphQL */ `
	query ListProjectRoles {
		listProjectRoles {
			items {
				id
				name
			}
		}
	}
`;

export const getBillingPeriod = /* GraphQL */ `
	query GetBillingPeriod($id: ID!) {
		getBillingPeriod(id: $id) {
			id
			name
			startDate
			endDate
			status
			billing {
				laborDates {
					date
					total
					items {
						user {
							id
							firstName
							lastName
						}
						projectRole {
							id
							name
						}
						timeEntries {
							id
							timeIn
							timeOut
							approved
							approvedBy {
								id
								firstName
								lastName
							}
							approvedAt
							timeInBilling
							timeOutBilling
						}
						lunchDeduction
						regularHours
						otHours
						premHours
						totalHours
						regularRate
						otRate
						premRate
						mobilizationDemobRate
						mobilizationDemobHours
						standbyRate
						standbyHours
						totalCharge
					}
				}
				equipment {
					expenses {
						unitPrice
						quantity
						description
						date
					}
					subTotal
					markup
					total
				}
				subsistence {
					subTotal
					markup
					total
				}
				rentedItems {
					subTotal
					markup
					total
				}
				purchasedSAOE {
					subTotal
					markup
					total
				}
				labAndSub {
					subTotal
					markup
					total
				}
				shipping {
					subTotal
					markup
					total
				}
				total
				totalLabor
			}
		}
	}
`;

export const getBillingPhase = /* GraphQL */ `
	query GetBillingPhase($id: ID!) {
		getBillingPhase(id: $id) {
			id
			name
		}
	}
`;

export const listTotals = /* GraphQL */ `
	query ListProjects(
		$filter: TableProjectFilterInput
		$limit: Int
		$nextToken: String
	) {
		listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
			total
		}
	}
`;
