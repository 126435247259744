import { Client, Clients } from "../views/Clerk/Clients";
import { Locations } from "../views/Clerk/Locations";
import { Project } from "../views/Clerk/Project";
import {
	DailySheetDay,
	SingleDailySheet,
} from "../views/Clerk/ProjectScreens/DailySheet";
import { RateSheet, RateSheets } from "../views/Clerk/RateSheets";
import { Subcontractor, Subcontractors } from "../views/Clerk/Subcontractors";
import { User, Users } from "../views/Clerk/Users";
import { WorkInProgress } from "../views/Clerk/WorkInProgress";

// icons
import {
	faFileInvoice,
	faHardHat,
	faMapPin,
	faRocket,
	faUserTie,
	faUsers,
} from "@fortawesome/pro-solid-svg-icons";

export const clerkNav = [
	{
		name: "Work In Progress",
		path: "/clerk/projects",
		component: WorkInProgress,
		icon: faRocket,
		baseLocation: true,
	},
	{
		name: "Clients",
		component: Clients,
		path: "/clerk/clients",
		icon: faUserTie,
	},
	{
		name: "Client",
		component: Client,
		noShow: true,
		path: "/clerk/clients/:id",
	},
	{
		name: "Locations",
		component: Locations,
		path: "/clerk/locations",
		icon: faMapPin,
	},

	{
		name: "Rate Sheets",
		component: RateSheets,
		path: "/clerk/billing-rates",
		icon: faFileInvoice,
	},
	{
		name: "ClerkProject",
		displayName: "Project",
		component: Project,
		noShow: true,
		path: "/clerk/projects/:id/:billingPeriodID",
	},
	{
		name: "DailySheetDay",
		component: DailySheetDay,
		noShow: true,
		path: "/clerk/projects/daily-sheets/:date/:projectID/:billingPeriodID",
	},
	{
		name: "ClerkProjectPhase",
		displayName: "Project",
		component: Project,
		noShow: true,
		path: "/clerk/projects/:id/:billingPhaseID",
	},
	{
		name: "DailySheetDayPhase",
		component: DailySheetDay,
		noShow: true,
		path: "/clerk/projects/daily-sheets/:date/:projectID/:billingPhaseID",
	},
	{
		name: "Clerk Single Daily Sheet",
		component: SingleDailySheet,
		noShow: true,
		path: "/clerk/projects/single-daily-sheet/:dailySheetID",
	},
	{
		name: "Rate Sheet",
		component: RateSheet,
		noShow: true,
		path: "/clerk/billing-rates/:id",
	},
	{
		name: "Subcontractors",
		component: Subcontractors,
		path: "/clerk/subcontractors",
		icon: faHardHat,
	},
	{
		name: "Subcontractor",
		component: Subcontractor,
		noShow: true,
		path: "/clerk/subcontractors/:id",
	},
	{
		name: "Users",
		component: Users,
		path: "/clerk/users",
		icon: faUsers,
	},
	{
		name: "User",
		component: User,
		noShow: true,
		path: "/clerk/users/:id",
	},
];
