const equipmentItems = [
    {
        name: "Thermal Imaging Camera",
        value: "flir",
    },
    {
        name: "Moisture Meter",
        value: "moistureMeter",
    },
    {
        name: "Thermohygrometer",
        value: "thermohygrometer",
    },
    {
        name: "Boroscope",
        value: "boroscope",
    },
    {
        name: "Matterport",
        value: "matterport",
    },
    {
        name: "360 Spherical Camera",
        value: "sphericalCamera",
    },
    {
        name: "Fall Protection (PFP)",
        value: "fallProtection",
    },
    {
        name: "Respirator (PRP)",
        value: "respirator",
    },
    {
        name: "Trailer Package",
        value: "trailerPackage",
    },
    {
        name: "Mobile Command Center",
        value: "mobileCommandCenter",
    },
    {
        name: "Passenger Vehicle",
        value: "vehicle",
    },
];

module.exports = {
    equipmentItems,
};