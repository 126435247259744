import { useNavigation } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { DateTime } from "luxon";
import React from "react";
import {
	Platform,
	ScrollView,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import useInterval from "use-interval";
import Toast from "react-native-toast-message";

// Components
import { Alert } from "../../../components/Alert";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import { Checkbox, Input, Radio, Select } from "../../../components/Forms";
import { PageHeader } from "../../../components/Header";
import LoadingScreen from "../../../components/LoadingScreen";
import Modal from "../../../components/Modal";
import NoItems from "../../../components/NoItems";
import Tabs, { Tab } from "../../../components/Tabs";

// Containers
import { DailySheetSlideOver } from "../../../containers/DailySheetSlideOver";
import { PTORequestSlideOver } from "../../../containers/PTORequestSlideOver";

// GQL
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as timeEntryGQL from "../../../graphql/timeEntry.js";
import { listUsers } from "../../../graphql/user";

// Utils
import {
	API_URL,
	calculateTimeDuration,
	getData,
	SentryLogger,
} from "../../../utils";

// Icons
import {
	faClock,
	faClockRotateLeft,
	faEye,
	faIslandTropical,
	faPlay,
	faPlus,
	faRocket,
	faStop,
	faUserTie,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { Table } from "../../../components/Table";
import { SafetyQuestionnaireSlideOver } from "../../../containers/SafetySlideOver";
import { PTODataForUser } from "../../FinanceHRCenter/PTOAnalysis";

const tabs = [
	{ name: "Time Clock", href: "#", icon: faClock },
	{ name: "Past Time Entries", href: "#", icon: faClockRotateLeft },
	{ name: "PTO Request", href: "#", icon: faIslandTropical },
];

export default function TimeClock() {
	const navigation = useNavigation();
	const [user, setUser] = React.useState({});
	const [tryingToClockIn, setTryingToClockIn] = React.useState(false);
	const [timeDailyItem, setTimeDailyItem] = React.useState(null);
	const [newItem, setNewItem] = React.useState(null);
	const [projects, setProjects] = React.useState([]);
	const [selectedProject, setSelectedProject] = React.useState({});
	const [travelType, setTravelType] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState(tabs[0].name);
	const [currentTimeEntry, setCurrentTimeEntry] = React.useState({});
	const [currentRunningTime, setCurrentRunningTime] = React.useState(null);
	const [todayTimeEntry, setTodayTimeEntry] = React.useState([]);
	const [payrollOpen, setPayrollOpen] = React.useState(false);
	const [startDate, setStartDate] = React.useState("");
	const [endDate, setEndDate] = React.useState("");
	const [downloading, setDownloading] = React.useState(false);
	const [payrollError, setPayrollError] = React.useState(null);
	const [allowUnapproved, setAllowUnapproved] = React.useState(false);
	const [showSafetyModal, setShowSafetyModal] = React.useState(false);
	const [safetyUsers, setSafetyUsers] = React.useState([]);
	const [safetyTimeEntry, setSafetyTimeEntry] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	let [pteStart, setPTEStart] = React.useState(
		DateTime.local().toISODate().split("T")[0]
	);

	let [pteEnd, setPTEEnd] = React.useState(
		DateTime.fromISO(DateTime.local().toISODate().split("T")[0])
			.plus({
				months: 1,
			})
			.minus({ days: 1 })
			.toISODate()
	);

	let [timeEntries, setTimeEntries] = React.useState([]);
	let [timeEntryEdit, setTimeEntryEdit] = React.useState(null);
	let [continuedStatus, setContinuedStatus] = React.useState(null);
	let [outTime, setOutTime] = React.useState(null);
	const [userPTOData, setUserPTOData] = React.useState({});

	const [getUser, { refetch }] = useLazyQuery(
		gql(timeEntryGQL.getUserAndTime),
		{
			onCompleted: (data) => {
				setUser(data.getUser);
				setProjects([...data.getUser.projects]);
				let currentTimeEntry = data.getUser.timeEntries.find((timeEntry) => {
					return timeEntry.timeOut === null;
				});
				setCurrentTimeEntry(currentTimeEntry);
				setLoading(false);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert("We couldn't get your time entries. Please try again", error);
				setLoading(false);
			},
			fetchPolicy: "cache-and-network",
			pollInterval: 10000,
		}
	);

	useQuery(gql(listUsers), {
		variables: {
			filter: {
				isSafetyUser: true,
			},
		},
		onCompleted: (data) => {
			setSafetyUsers([...data.listUsers.items]);
		},
		onError: (err) => {
			SentryLogger(JSON.stringify(err, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	const { refetch: refetchSingleDayTimeEntry } = useQuery(
		gql(timeEntryGQL.getSingleDayTimeEntry),
		{
			variables: {
				user: user.id,
				date: DateTime.now().toISODate(),
			},
			onCompleted: (data) => {
				setTodayTimeEntry([...data.getSingleDayTimeEntry]);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
			fetchPolicy: "cache-and-network",
		}
	);

	useQuery(gql(timeEntryGQL.listTimeEntries), {
		variables: {
			filter: {
				user: { eq: user.id },
				timeIn: {
					between: [pteStart, pteEnd],
				},
			},
		},
		onCompleted: (data) => {
			let items = [...data.listTimeEntries.items];
			items.sort((a, b) => {
				if (a.timeIn > b.timeIn) return 1;
				if (a.timeIn < b.timeIn) return -1;
				return 0;
			});

			setTimeEntries(items);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	// mutations

	const [clockIn] = useMutation(gql(timeEntryGQL.createTimeEntry), {
		onCompleted: (data) => {
			setTravelType(null);
			setCurrentTimeEntry(data.createTimeEntry);
			refetch();
			refetchSingleDayTimeEntry();
			setTryingToClockIn(false);
		},

		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert(error.message);
			setTryingToClockIn(false);
		},
	});

	const [clockOut] = useMutation(gql(timeEntryGQL.updateTimeEntry), {
		onCompleted: () => {
			setCurrentTimeEntry(null);
			setTimeEntryEdit(null);
			refetch();
			refetchSingleDayTimeEntry();
			setSelectedProject(null);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert(
				"Could not clock out. Please try again. If this continues to happen please contact support via the chat button below"
			);
		},
	});

	useInterval(() => {
		if (currentTimeEntry) {
			let currentTime = DateTime.local();
			setCurrentRunningTime(
				calculateTimeDuration(currentTimeEntry.timeIn, currentTime)
			);
		}
	}, 1000);

	React.useEffect(() => {
		const userInfo = async () => {
			await getData("@storage_Key")
				.then((user) => {
					setUser(user);
					getUser({
						variables: {
							id: user.id,
						},
					});
				})

				.catch((err) => {
					Toast.show({
						type: "error",
						text1: "Error",
						text2: "We couldn't get your information. Please try again",
					});
					SentryLogger(err);
				});
		};

		userInfo();
	}, []);

	/**
	 * getSortedProjects
	 * @param {*} projects - array of user projects
	 * @returns array of projects sorted
	 */

	const getSortedProjects = (projects) => {
		if (!projects) return [];

		let sortedProjects = projects
			.filter((pc) => pc.project !== null)
			.filter((pc) => pc.project.stage !== "Complete")
			.sort((a, b) => {
				if (a.project.title > b.project.title) return 1;
				if (a.project.title < b.project.title) return -1;
				return 0;
			});

		sortedProjects.unshift({
			project: {
				id: "0",
				title: "SRP Environmental",
				isLargeLoss: false,
			},
		});

		return sortedProjects.map((pc) => {
			if (pc.project) {
				return {
					name: pc.project.title,
					id: pc.project.id,
					isLargeLoss: pc.project.isLargeLoss,
					disabled: false,
				};
			}
		});
	};

	const getPageHeaderButtons = (user) => {
		let buttons = [];

		if (user.isManager) {
			buttons.push({
				name: "Manager Center",
				icon: faUserTie,
				onPress: () => {
					navigation.navigate("Manager Center");
				},
			});
		}
		if (user.isPayrollAdmin) {
			buttons.push({
				name: "Payroll Center",
				icon: faUserTie,
				onPress: () => {
					setPayrollOpen(true);
				},
			});
		}

		if (user.isTimeAdmin) {
			buttons.push({
				name: "Time Admin Center",
				icon: faUserTie,
				onPress: () => {
					navigation.navigate("Time Admin Center");
				},
			});
		}

		buttons.push({
			name: "Request PTO",
			icon: faIslandTropical,
			onPress: () => {
				setNewItem("PTORequest");
			},
		});
		return buttons;
	};

	const generatePayrollAnalysisReport = () => {
		if (!startDate || !endDate) {
			Alert("Please select a start and end date");
			return;
		}

		setDownloading(true);
		setPayrollError(null);

		fetch(`${API_URL}/time/payroll-analysis`, {
			method: "POST",
			mode: "cors",
			body: JSON.stringify({
				start: startDate,
				end: endDate,
				allowUnapproved: allowUnapproved,
			}),
		})
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				if (data.url) {
					// download
					if (Platform.OS !== "web") {
						Linking.openURL(data.url);
					} else {
						window.open(data.url, "_blank");
					}

					setDownloading(false);
					setPayrollOpen(false);
					setStartDate(null);
					setEndDate(null);
					setAllowUnapproved(false);
				} else {
					setPayrollError(data.message);
					setDownloading(false);
				}
			})
			.catch((error) => {
				SentryLogger(error);
				setDownloading(false);
			});
	};

	const navigateToTimeEntry = (id) => {
		navigation.navigate("Single Time Entry", {
			id: id,
		});
	};

	if (loading || !user) {
		return <LoadingScreen loadItem={"Time Clock"} />;
	}

	return (
		<View className="h-full">
			<Breadcrumb className="mt-2">
				<Crumb name={"Time Clock"} location="Time Clock" />
			</Breadcrumb>
			<PageHeader
				openMenu
				title="Time Clock"
				buttons={getPageHeaderButtons(user)}
			/>
			<Tabs>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							current={currentTab === tab.name}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			{currentTab === "Time Clock" && (
				<ScrollView className=" mt-2 ">
					<View className=" w-full">
						{/* Clock */}
						<View className="flex mb-5">
							{currentTimeEntry ? (
								<View className="flex flex-col items-center justify-center my-10 ">
									<TouchableOpacity
										className="flex items-center justify-center"
										onPress={() => {
											if (
												currentTimeEntry.project &&
												!currentTimeEntry.dailyDocument
											) {
												return Alert(
													"Please create a daily sheet for this project before clocking out"
												);
											} else {
												setSafetyTimeEntry(currentTimeEntry);

												clockOut({
													variables: {
														input: {
															timeOut: DateTime.now().toISO(),
															id: currentTimeEntry.id,
														},
													},
												});
											}

											//Line below enables/disables the safety questionnaire modal
											setShowSafetyModal(true);
										}}
									>
										<View className="bg-indigo-500 p-20  border-none rounded-full flex items-center justify-center animate-pulse shadow-sm">
											<FontAwesomeIcon icon={faStop} color={"#fff"} size={50} />
										</View>
									</TouchableOpacity>
									{/* Current Project Info */}
									<View className="mt-5 ml-0 md:mt-0 md:ml-5 ">
										<Text className="text-center text-gray-600 dark:text-gray-300">
											Current Project{" "}
											<Text className="text-gray-900 dark:text-white">
												{currentTimeEntry.project
													? currentTimeEntry.project.title
													: "No Project"}
											</Text>
										</Text>
										<Text className="text-center text-gray-600 dark:text-gray-300">
											Current Time{" "}
											<Text className="text-gray-900 dark:text-white">
												{currentRunningTime}
											</Text>
										</Text>
									</View>
								</View>
							) : (
								<View className="">
									<View>
										<View className={"flex items-center justify-center"}>
											<View className="px-2 w-full ">
												<Select
													icon={faRocket}
													name="Project"
													onChange={(value) => {
														setSelectedProject(value);
													}}
													options={getSortedProjects(projects)}
												/>
												<View className="mt-2">
													{selectedProject && selectedProject.isLargeLoss && (
														<Radio
															name="type-travel"
															label="Type of Work"
															onChange={(value) => {
																//if no travel type is selected alert the user and don't allow them to clock in
																if (!value) {
																	alert("Please select a type of work");
																}
																setTravelType(value.value);
															}}
															options={[
																{
																	label: "Mobilization",
																	value: "mobilization",
																	description:
																		"First day of travel (i.e. flying to the job site/disaster)",
																},
																{
																	label: "Demobilization",
																	value: "demobilization",
																	description:
																		"Leaving the job site/disaster (i.e. flying to home)",
																},
																{
																	label: "Standby",
																	value: "standby",
																	description:
																		"Waiting for the job to start (i.e. hold ups due to lack of access)",
																},
																{
																	label: "Regular",
																	value: "regular",
																	description: "Performing work as normal",
																},
															]}
														/>
													)}
												</View>
											</View>
											<View className="mt-2">
												{!tryingToClockIn ? (
													<View className="mt-2 self-center">
														<Button
															text="Start Time Clock"
															icon={faPlay}
															size="xl"
															onPress={() => {
																if (!selectedProject.id) {
																	return Alert(
																		"You must select a project before starting the time clock"
																	);
																}

																// check if selectedProject is largeLoss and if selectedProject is largeLoss check if travelType is selected if not alert user
																if (
																	selectedProject.isLargeLoss &&
																	!travelType
																) {
																	return Alert(
																		"You must select a travel type before starting the time clock"
																	);
																}

																// set input object

																let newTimeEntry = {
																	user: user.id,
																	timeIn: DateTime.local().toISO(),
																	project: selectedProject.id,
																};

																if (travelType === "mobilization") {
																	newTimeEntry.isMobilizing = true;
																}

																if (travelType === "demobilization") {
																	newTimeEntry.isDemobilizing = true;
																}

																if (travelType === "standby") {
																	newTimeEntry.isStandby = true;
																}
																clockIn({
																	variables: {
																		input: {
																			...newTimeEntry,
																		},
																	},
																});
															}}
														/>
													</View>
												) : (
													<View className="mt-2 self-center">
														<Text>Attempting to Clock in</Text>
													</View>
												)}
											</View>
										</View>
									</View>
								</View>
							)}
						</View>

						<View className="flex-1 px-2 mt-2">
							<View className="rounded overflow-hidden mt-2 lg:mt-0">
								{todayTimeEntry.length ? (
									<Table
										title="Today's Time Entries"
										headers={["Project", "Total Time"]}
										usesOptions
										data={todayTimeEntry.map((entry) => {
											const getOptions = () => {
												if (entry.dailyDocument) {
													return [
														{
															name: "View Entry",
															icon: faEye,
															onPress: () => {
																navigation.navigate("Single Time Entry", {
																	id: entry.id,
																});
															},
														},
													];
												} else {
													return [
														{
															name: "View Entry",
															icon: faEye,
															onPress: () => {
																navigation.navigate("Single Time Entry", {
																	id: entry.id,
																});
															},
														},
														{
															name: "Create Daily Sheet",
															icon: faPlus,
															onPress: () => {
																setNewItem("dailySheet");
																setTimeDailyItem(entry);
															},
														},
													];
												}
											};

											return {
												cells: [
													{
														content: (
															<View>
																<Text className="font-medium">
																	{entry.project
																		? entry.project.title
																		: "No Project"}
																</Text>
																<Text>
																	{entry.dailyDocument
																		? "Daily Document Added"
																		: "Daily Document Needed"}
																</Text>
															</View>
														),
													},
													{
														content:
															entry.timeIn && entry.timeOut
																? `Time: ${calculateTimeDuration(
																		entry.timeIn,
																		entry.timeOut
																  )}`
																: "Clock Running",
													},
												],
												options: getOptions(),
											};
										})}
									/>
								) : (
									<NoItems
										surTitle="No Time Entries for Today"
										title="No Time Entries"
										text="You have no time entries today. You can start one by selecting a project and clicking Start Time Clock"
									/>
								)}
							</View>
						</View>
					</View>
				</ScrollView>
			)}

			{currentTab === "Past Time Entries" && (
				<ScrollView>
					<View className="px-2 pt-2">
						<View className="w-full flex flex-col m-2">
							<View className="w-full flex flex-row justify-between items-center py-2">
								<View className="flex-1 px-2">
									<Input
										label="Start Date"
										type="date"
										onChange={(value) => {
											setPTEStart(value);
										}}
										value={pteStart}
									/>
								</View>
								<View className="flex-1 px-2">
									<Input
										label="End Date"
										type="date"
										onChange={(value) => {
											setPTEEnd(value);
										}}
										value={pteEnd}
									/>
								</View>
							</View>

							{timeEntries.length === 0 ? (
								<NoItems
									surTitle="No Time Entries"
									title="No Time Entries"
									text="You have no time entries for the selected date range. Please adjust the date ranges above"
								/>
							) : (
								<Table
									title="Past Time Entries"
									headers={["Time Entry", "Total Hours", "Client"]}
									usesOptions
									data={timeEntries.map((entry) => ({
										onPress: () => navigateToTimeEntry(entry.id),
										options: [
											{
												icon: faEye,
												name: "View",
												onPress: () => {
													navigateToTimeEntry(entry.id);
												},
											},
										],
										cells: [
											{
												content: (
													<View className="flex flex-row justify-between ">
														<View className="flex flex-col items-start">
															<Text>Project:</Text>
															<Text className="text-gray-700 dark:text-gray-100">
																Time In:
															</Text>
															<Text className="text-gray-700 dark:text-gray-100">
																Time Out:
															</Text>
														</View>

														<View className="flex flex-col  items-end">
															<Text className="text-gray-700 dark:text-gray-100 text-right">
																{entry.project
																	? entry.project.title
																	: "No Project"}
															</Text>
															<Text className="text-gray-700 dark:text-gray-100 text-right">
																{DateTime.fromISO(entry.timeIn).toLocaleString(
																	DateTime.DATETIME_MED
																)}
															</Text>
															<Text className="text-gray-700 dark:text-gray-100 text-right">
																{entry.timeOut
																	? DateTime.fromISO(
																			entry.timeOut
																	  ).toLocaleString(DateTime.DATETIME_MED)
																	: "Not Clocked Out"}
															</Text>
														</View>
													</View>
												),
											},

											{
												content:
													entry.timeOut && entry.timeIn
														? calculateTimeDuration(entry.timeIn, entry.timeOut)
														: "Not Clocked Out",
											},

											{
												content: entry.project.workspace
													? entry.project.workspace.client
														? entry.project.workspace.client.name
														: "No Client"
													: entry.project.client
													? entry.project.client.name
													: "No Client",
											},
										],
									}))}
								/>
							)}
						</View>
					</View>
				</ScrollView>
			)}

			{currentTab === "PTO Request" && (
				<View className="pt-2">
					<View className="pb-2 px-2 flex flex-row">
						<Text className="dark:text-white">
							Available PTO: {userPTOData.availablePTO || "0"} hour(s)
						</Text>
						<Text className="dark:text-white ml-2">
							Advanced PTO: {userPTOData.advancableAmount || "0"} hour(s)
						</Text>
					</View>
					<PTODataForUser
						userID={user.id || null}
						HRView={false}
						readOnlyView={true}
						onClearButtonAction={() => {}}
						onUserDataRetrieved={(data) => {
							setUserPTOData(data);
						}}
					/>
				</View>
			)}

			{/* Time Edit and Time Continue Modals */}

			<Modal
				isOpen={timeEntryEdit !== null}
				onRequestClose={() => setTimeEntryEdit(null)}
				title={"Clock Out"}
				description={`You are currently clocked in at ${DateTime.fromISO(
					timeEntryEdit && timeEntryEdit.timeIn
				).toLocaleString(DateTime.DATE_FULL)} at ${DateTime.fromISO(
					timeEntryEdit && timeEntryEdit.timeIn
				).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}`}
				rightButton={{
					text: "Clock Out",
					onPress: () => {
						clockOut({
							variables: {
								input: {
									id: timeEntryEdit.id,
									outTime,
								},
							},
						});
					},
				}}
				// add function to call modal to open slideover
			>
				<View>
					<View className="grid grid-col-2 gap-2">
						<Input
							type="datetime-local"
							helper={
								"The out time will be in the same time zone as the in time."
							}
							onChange={(e) => {
								let date = e.target.value.split("T")[0].split("-");
								let time = e.target.value.split("T")[1].split(":");
								let zone = DateTime.fromISO(
									timeEntryEdit && timeEntryEdit.timeIn
								).zoneName;
								let dateObject = {
									year: date[0],
									month: date[1],
									days: date[2],
									hour: time[0],
									minute: time[1],
								};

								setOutTime(
									DateTime.fromObject(dateObject, {
										zone: zone,
									}).toISO()
								);
							}}
						/>
					</View>
				</View>
			</Modal>
			<Modal
				isOpen={continuedStatus !== null}
				onRequestClose={() => setContinuedStatus(null)}
				title={
					continuedStatus && continuedStatus.success
						? "Time Reminder Paused"
						: "Error Pausing Time Reminder"
				}
				variant={continuedStatus && !continuedStatus.success ? "red" : "indigo"}
				description={
					continuedStatus && continuedStatus.success
						? "We have paused the time reminder for 2 hours. Please remember to clock out when you have completed working on this project"
						: "We had an issued pausing the time clock reminder. Please refresh the screen to try. If you continue to get this error please contact support"
				}
				rightButton={{
					text: "Close",
					onPress: () => {
						setContinuedStatus(null);
					},
				}}
			></Modal>
			<SafetyQuestionnaireSlideOver
				isOpen={newItem === "SafetyQuestionnaire"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				type="create"
				user={user}
				timeEntry={safetyTimeEntry}
			/>
			<PTORequestSlideOver
				isOpen={newItem === "PTORequest"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				type="create"
				user={user}
			/>

			<DailySheetSlideOver
				isOpen={newItem === "dailySheet"}
				timeDailyItem={timeDailyItem}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setTimeDailyItem(null);
					setNewItem(null);
					refetch();
				}}
				type="create"
			/>
			<DailySheetSlideOver
				isOpen={newItem === "editDailySheet"}
				timeDailyItem={timeDailyItem}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setTimeDailyItem(null);
					setNewItem(null);
					refetch();
				}}
				type="update"
			/>
			<Modal
				isOpen={showSafetyModal}
				title="Safety Questionnaire"
				closeRequest={() => {
					setShowSafetyModal(false);
				}}
				closeButton="No, I'm not injured"
				description="Were you injured or suspect that you may have been injured today? A non response (closing the app or modal) will be considered a 'No' response."
				rightButton={{
					text: "Yes, continue to questionnaire",
					onPress: () => {
						const safetyUserList = safetyUsers
							.filter((user) => user.isSafetyUser === true)
							.map((user) => {
								return {
									user: user.firstName,
									value: user.id,
								};
							});

						let i = 0;

						while (i < safetyUserList.length) {
							// send notification
							fetch(`${API_URL}/notifications/notification`, {
								method: "POST",
								mode: "cors",
								body: JSON.stringify({
									notification: {
										title: "Safety Incident Reported",
										message: `${user.firstName} ${user.lastName} is currently completing a questionnaire for a Safety Incident`,
										createdBy: user.id,
										user: safetyUserList[i].value,
									},
									type: "Safety Center",
								}),
							})
								.then((res) => res.json())
								.catch((err) => {
									SentryLogger("Notification");
									SentryLogger(err);
								});
							i++;
						}
						setShowSafetyModal(false);
						setNewItem("SafetyQuestionnaire");
					},
				}}
			/>
			<Modal
				isOpen={payrollOpen}
				closeRequest={() => {
					setPayrollOpen(false);
				}}
				rightButton={{
					text: "Generate Report",
					onPress: () => {
						generatePayrollAnalysisReport();
					},
				}}
				title="Payroll Report"
				description="Enter the start and end dates for the payroll report. Then click the 'Generate Report' button to generate the report. The report will start to generate and automatically download once the report is complete."
			>
				{payrollError && (
					<Text className="text-red-500 text-sm">{payrollError}</Text>
				)}
				{downloading ? (
					<Text className="animate-pulse font-medium">Generating Report</Text>
				) : (
					<View className="w-full">
						<View className="w-full flex flex-row items-center justify-between">
							<View className="flex-1 m-1">
								<Input
									label="Start Date"
									type="date"
									name="startDate"
									value={startDate}
									onChange={(e) => setStartDate(e)}
									required
								/>
							</View>
							<View className="flex-1 m-1">
								<Input
									label="End Date"
									type="date"
									name="endDate"
									value={endDate}
									onChange={(e) => setEndDate(e)}
									required
								/>
							</View>
						</View>

						<Checkbox
							label="Included Unapproved Time"
							name="includeUnapprovedTime"
							description="Checking this box will include all time including unapproved time in the report for the selected dates."
							checkedValue={allowUnapproved}
							onChange={(e) => setAllowUnapproved(e)}
						/>
					</View>
				)}
			</Modal>
		</View>
	);
}
