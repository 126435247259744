import React from "react";
import {
	Image,
	KeyboardAvoidingView,
	Text,
	TouchableOpacity,
	View,
} from "react-native";

// Components
import { Alert } from "../../components/Alert";
import Button from "../../components/Button";
import { Input } from "../../components/Forms";

// GQL

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as mfaGQL from "../../graphql/mfa";
import * as queries from "../../graphql/user";

// utils

import { API_URL, getData, SentryLogger, storeData } from "../../utils";

// icons
import { useNavigation } from "@react-navigation/native";

export const SignIn = () => {
	const navigation = useNavigation();
	let [username, setUsername] = React.useState("");
	let [password, setPassword] = React.useState("");
	let [showError, setShowError] = React.useState("");
	let [view, setView] = React.useState("login");
	let [attemptingLogin, setAttemptingLogin] = React.useState(false);
	let [code, setCode] = React.useState("");
	let [newPassword, setNewPassword] = React.useState("");
	let [user, setUser] = React.useState(null);
	let [mfa, setMFA] = React.useState(null);

	let lowerCaseLetters = /[a-z]/g;
	let upperCaseLetters = /[A-Z]/g;
	let numbers = /[0-9]/g;
	let special = /[=+-^$*.{}()?"!@#%&/,><':;|_~`]/g;

	let [generatePasswordResetToken] = useMutation(
		gql(queries.generatePasswordResetToken),
		{
			onCompleted: (data) => {
				let { email, passwordResetToken } = data.generatePasswordResetToken;

				sendVerificationCode(email, passwordResetToken);
			},

			fetchPolicy: "cache-and-network",
		}
	);

	let [resetPassword] = useMutation(gql(queries.resetPassword), {
		onCompleted: (data) => {
			storeData("@storage_Key", data.resetPassword);
			navigation.navigate("App");
			setView("login");
			navigation.navigate("App");
		},
		onError: (error) => {
			setShowError(error.message);
		},
		fetchPolicy: "cache-and-network",
	});

	let [login] = useLazyQuery(gql(queries.login), {
		onCompleted: async (data) => {
			if (data.login && data.login.isActive === false) {
				return Alert(
					"Inactive Account",
					"Sorry your account has been deactivated. Please contact help.srpinfield.com if you think this is a mistake"
				);
			}
			if (data.login) {
				// check for mfa devices

				if (data.login.mfaDevices.length > 0) {
					setUser(data.login);
					return setView("MFA");
				}

				storeData("@storage_Key", data.login).then(() => {
					navigation.navigate("App");
					setAttemptingLogin(false);
					setView("login");
				});
			} else {
				setAttemptingLogin(false);
				setShowError("Invalid username or password");
			}
		},
		onError: (error) => {
			if (error.message === "You must reset your password") {
				generatePasswordResetToken({ variables: { email: username } });
			} else if (error.message === "Email or password is incorrect") {
				setShowError(error.message);
				setAttemptingLogin(false);
			} else {
				setShowError(error.message);
				setAttemptingLogin(false);
				SentryLogger(error);
			}
		},
		fetchPolicy: "cache-and-network",
	});

	const sendMFACode = async (user, phone) => {
		await fetch(`${API_URL}/security/sms-code`, {
			method: "POST",
			cors: true,
			body: JSON.stringify({ phone: phone, userID: user.id }),
		})
			.then((res) => {
				if (res.status === 200) {
					// if the status is 200 then show the modal to enter the code
					setView("MFAConfirm");
				} else {
					Alert("Something went wrong", "Please try again");
				}
			})
			.catch(() => {
				return Alert("Something went wrong", "Please try again");
			});
	};

	const sendVerificationCode = (email, code) => {
		fetch(`${API_URL}/emails/send-code`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				code,
			}),
		})
			.then((res) => res.json())
			.then(() => {
				setView("reset");
			})
			.catch(() => {
				Alert(
					"Password Reset Failed",
					"You must reset your password, but we were unable to send the code to your email. Please go to the forgot password page to reset your password "
				);
			});
	};

	const [confirmMFA] = useMutation(gql(mfaGQL.confirmMFA), {
		onCompleted: async (data) => {
			await storeData("@storage_Key", data.confirmMFA).then(() => {
				navigation.navigate("App");
				setView("login");
				setAttemptingLogin(false);
			});
		},
		onError: (error) => {
			SentryLogger(error);
			Alert(error.message);
		},
	});

	React.useEffect(() => {
		// get user and redirect if logged in
		getData("user").then((user) => {
			if (user) {
				navigation.navigate("App");
			}
		});
	}, []);

	return (
		<KeyboardAvoidingView
			behavior="padding"
			className="h-full  flex items-center justify-center bg-gray-50 dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8"
		>
			{view === "login" && (
				<View className="max-w-md w-full h-full space-y-8  flex items-center justify-center">
					<View className="flex justify-center items-center">
						<Image
							className="h-12 w-12"
							source={require("../../assets/srp-icon.png")}
							alt="SRP inField"
						/>
						<Text className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
							Sign in to your account
						</Text>
					</View>
					<>{showError && <Text className="text-red-500">{showError}</Text>}</>

					<View className="mt-8 space-y-6 w-full">
						<View className="rounded-md space-y-3 w-full">
							<Input
								label="Email Address"
								type="email"
								placeholder="Email Address"
								onChange={(e) => {
									setUsername(e);
								}}
								autoCapitalize="none"
								autoCorrect={false}
								autoComplete="email"
							/>
							<Input
								type="password"
								label="Password"
								placeholder="Password"
								onChange={(e) => setPassword(e)}
								secureTextEntry={true}
								autoCapitalize="none"
								autoCorrect={false}
								autoComplete="password"
							/>
						</View>

						<View>
							<Button
								disabled={attemptingLogin ? true : false}
								onPress={() => {
									if (attemptingLogin) return;

									login({
										variables: {
											email: username,
											password: password,
										},
									});
									setShowError(false);
									setAttemptingLogin(true);
								}}
								text="Sign In"
							/>
						</View>
					</View>
					<View className="w-full flex-row items-center justify-between">
						<TouchableOpacity
							onPress={() => {
								navigation.navigate("Register");
							}}
						>
							<Text className="font-medium text-indigo-600 hover:text-indigo-500">
								Register
							</Text>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() => {
								navigation.navigate("ForgotPassword");
							}}
						>
							<Text className="font-medium text-indigo-600 hover:text-indigo-500">
								Forgot your password?
							</Text>
						</TouchableOpacity>
					</View>
				</View>
			)}

			{view === "reset" && (
				<View className="max-w-md w-full space-y-8">
					<View className="flex justify-center items-center">
						<Image
							className="h-8 w-8"
							source={require("../../assets/srp-icon.png")}
							alt="SRP inField"
						/>
						<Text className="mt-6 text-center text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-indigo-600">
							Time to reset your password
						</Text>
						<Text className="mt-6 text-gray-900 dark:text-white text-center">
							We just send a code to your email so you can reset your password.
							Please enter it below followed by a new password
						</Text>
					</View>
					<>{showError && <Text className="text-red-500">{showError}</Text>}</>

					<View className="mt-8 space-y-6">
						<View className="rounded-md shadow-sm space-y-3">
							<Input
								label="Verification Code"
								placeholder="######"
								onChange={(e) => setCode(e)}
								value={code}
							/>
							<Input
								label="Password"
								type="password"
								placeholder="Password"
								onChange={(e) => setNewPassword(e)}
								value={newPassword}
								secureTextEntry={true}
							/>
						</View>

						<View>
							<Button
								disabled={attemptingLogin ? true : false}
								onPress={() => {
									// check to make sure password meets the requirements

									if (
										!newPassword.length >= 8 ||
										!newPassword.match(upperCaseLetters) ||
										!newPassword.match(lowerCaseLetters) ||
										!newPassword.match(numbers) ||
										!newPassword.match(special)
									) {
										setShowError(
											"Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number and one special character"
										);
										return;
									}

									resetPassword({
										variables: {
											email: username,
											password: newPassword,
											code: code,
										},
									});

									setShowError(false);
									setAttemptingLogin(true);
								}}
								text="Reset Password"
							/>
						</View>
					</View>
				</View>
			)}

			{view === "MFA" && (
				<View className="max-w-md w-full space-y-8">
					<View className="flex justify-center items-center">
						<Image
							className="h-8 w-8"
							source={require("../../assets/srp-icon.png")}
							alt="SRP inField"
						/>
						<Text className="mt-6 text-center text-xl font-extrabold text-indigo-500  ">
							MFA Verification
						</Text>
						<Text className=" text-center text-md font-medium text-gray-900 dark:text-white  ">
							Please Select Your MFA Method
						</Text>

						{user &&
							user.mfaDevices.map((device, index) => {
								return (
									<View
										key={index}
										className="m-6 border-2 border-indigo-500  rounded-md shadow-sm p-3 w-full"
									>
										{device.method === "SMS" && (
											<TouchableOpacity
												onPress={() => {
													sendMFACode(user, device.device);
												}}
											>
												<Text className=" text-gray-900 dark:text-white text-center">
													Send a text to •••-•••-{device.device.slice(-4)}
												</Text>
											</TouchableOpacity>
										)}
									</View>
								);
							})}
					</View>
				</View>
			)}

			{view === "MFAConfirm" && (
				<View className="max-w-md w-full space-y-8">
					<View className="flex justify-center items-center">
						<Image
							className="h-8 w-8"
							source={require("../../assets/srp-icon.png")}
							alt="SRP inField"
						/>
						<Text className="mt-6 text-center text-xl font-extrabold text-indigo-500  ">
							MFA Verification
						</Text>
						<Text className=" text-center text-md font-medium text-gray-900 dark:text-white  ">
							Please Enter Your MFA Code
						</Text>

						<View className="mt-6">
							<Input
								placeholder="MFA Code"
								onChange={(text) => setMFA(text)}
								value={mfa}
								keyboardType="numeric"
								autoComplete="sms-otp"
							/>
						</View>
					</View>
					<View>
						<Button
							size="lg"
							onPress={() => {
								confirmMFA({
									variables: {
										id: user.id,
										code: mfa,
									},
								});
							}}
							text="Confirm"
						/>
					</View>
				</View>
			)}
		</KeyboardAvoidingView>
	);
};
