export const listClients = /* GraphQL */ `
	query ListClients(
		$filter: TableClientFilterInput
		$limit: Int
		$nextToken: String
	) {
		listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
			}
		}
	}
`;

export const getClient = /* GraphQL */ `
	query GetClient($id: ID!) {
		getClient(id: $id) {
			id
			name
			locations {
				id
				name
			}
			users {
				id
				firstName
				lastName
				role
				email
			}
			projects {
				id
				title
				sageIntacct
			}
			billingRates {
				id
				name
			}
		}
	}
`;
