export const listHomogeneousAreas = /* GraphQL */ `
	query ListHomogeneousAreas(
		$limit: Int
		$nextToken: String
		$filter: TableHomogeneousAreaFilterInput
	) {
		listHomogeneousAreas(
			limit: $limit
			nextToken: $nextToken
			filter: $filter
		) {
			items {
				id
				name
				material
			}
			nextToken
		}
	}
`;

export const getHomogeneousArea = /* GraphQL */ `
	query GetHomogeneousArea($id: ID!) {
		getHomogeneousArea(id: $id) {
			id
			name
			material
			squareFootage
			positive
			potentialForContact
			influenceOfVibration
			influenceOfAirErosion
			overallPotential
			sampleCollection {
				id
				title
			}
			samples {
				id
				number
				title
				date
				description
				result
				positive
				friable
			}
		}
	}
`;

export const createHomogeneousArea = /* GraphQL */ `
	mutation CreateHomogeneousArea($input: CreateHomogeneousAreaInput!) {
		createHomogeneousArea(input: $input) {
			id
		}
	}
`;

export const updateHomogeneousArea = /* GraphQL */ `
	mutation UpdateHomogeneousArea($input: UpdateHomogeneousAreaInput!) {
		updateHomogeneousArea(input: $input) {
			id
			name
			material
			squareFootage
			positive
			potentialForContact
			influenceOfVibration
			influenceOfAirErosion
			overallPotential
		}
	}
`;
