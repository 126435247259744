import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import {
	Platform,
	ScrollView,
	Text,
	TouchableOpacity,
	View,
} from "react-native";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Tabs({ children }) {
	if (Platform.OS === "web") {
		return (
			<View className="w-full">
				<ScrollView horizontal className="flex-row  w-full ">
					<View className="border-b border-gray-200 dark:border-gray-500 w-full ">
						<View
							className="-mb-px flex flex-row space-x-8 w-full"
							aria-label="Tabs"
						>
							{children}
						</View>
					</View>
				</ScrollView>
			</View>
		);
	}

	return (
		<View className="w-full">
			<ScrollView
				horizontal
				showsHorizontalScrollIndicator={false}
				className="border-b border-gray-200 dark:border-gray-500 w-full "
			>
				<View
					className="-mb-px flex flex-row justify-between space-x-8 w-full"
					aria-label="Tabs"
				>
					{children}
				</View>
			</ScrollView>
		</View>
	);
}

Tabs.propTypes = {
	children: PropTypes.node,
};

export const Tab = (props) => {
	if (Platform.OS === "web") {
		return (
			<>
				<TouchableOpacity
					className={classNames(
						props.current
							? "border-indigo-500 text-indigo-600"
							: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
						"group items-baseline align-baseline py-4 px-1 border-b-2 flex-row ml-2"
					)}
					aria-current={props.current ? "page" : undefined}
					onPress={() => props.onPress()}
				>
					<FontAwesomeIcon
						icon={props.icon}
						color={"#3f51b5"}
						aria-hidden="true"
					/>
					<Text
						className={`whitespace-nowrap font-medium text-sm dark:text-white ${
							props.icon && "ml-2"
						}`}
					>
						{props.name}
					</Text>
				</TouchableOpacity>
			</>
		);
	}

	return (
		<>
			<TouchableOpacity
				className={classNames(
					props.current
						? "border-indigo-500 text-indigo-600"
						: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
					"flex-1  align-baseline py-2 px-1 border-b-2 flex items-center justify-center  w-20"
				)}
				aria-current={props.current ? "page" : undefined}
				onPress={() => props.onPress()}
			>
				<FontAwesomeIcon
					icon={props.icon}
					color={"#3f51b5"}
					size={18}
					aria-hidden="true"
				/>
				<Text className={"text-[8px] dark:text-white pt-1"}>{props.name}</Text>
			</TouchableOpacity>
		</>
	);
};

Tab.propTypes = {
	name: PropTypes.string.isRequired,
	icon: PropTypes.any,
	current: PropTypes.bool,
	onPress: PropTypes.func.isRequired,
};
