import { useNavigation } from "@react-navigation/native";
import AWS from "aws-sdk";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
	Text,
	TouchableOpacity,
	Pressable,
	View,
	ScrollView,
} from "react-native";

// Components

//import { useParams, Switch } from "react-router-dom";
import { Alert } from "../../../components/Alert";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import DataContainer, { DataItem } from "../../../components/DataDisplay";
import { PageHeader } from "../../../components/Header";
import NoItems from "../../../components/NoItems";
import LoadingScreen from "../../../components/LoadingScreen";
import { addNotification } from "../../../components/Notification";
import OptionsMenu from "../../../components/OptionsMenu";
import User from "../../../components/User";
// import Gallery, { GalleryItem } from "../../../components/Gallery";
import { Input } from "../../../components/Forms";
import { List, ListItem } from "../../../components/List";
import Tabs, { Tab } from "../../../components/Tabs";

// Containers
import SlideOver from "../../../components/Slideover";
import { SampleSlideOver } from "../../../containers/SampleSlideOver";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as sampleGQL from "../../../graphql/sampling";
import * as areaGQL from "../../../graphql/homogeneousArea";

// Utils
import { awsConfig, getData, SentryLogger } from "../../../utils";

// Icons
import {
	faMapPin,
	faPen,
	faPencil,
	faPlus,
	faVials,
} from "@fortawesome/pro-duotone-svg-icons";
import { HomogeneousAreaSlideOver } from "../../../containers/HomogeneousAreaSlideOver";

AWS.config.update(awsConfig);

//let s3 = new AWS.S3({ params: { Bucket: "srp-in-field" } });

const tabs = [
	{ name: "Samples", href: "#", icon: faVials },
	{ name: "Areas", href: "#", icon: faMapPin },
];

export function SampleCollection({ route }) {
	const { id } = route.params;
	const navigation = useNavigation();
	const [newItem, setNewItem] = useState(null);
	const [homogeneousAreas, setHomogeneousAreas] = React.useState([]);
	const [sampleCollection, setSampleCollection] = React.useState(null);
	const [updatedSampleCollection, setUpdatedSampleCollection] = React.useState({
		id: id,
	});
	const [currentTab, setCurrentTab] = React.useState(tabs[0].name);

	// const [files, setFiles] = React.useState([]);
	// const [keys, setKeys] = React.useState([]);

	// const [addLocation, setAddLocation] = React.useState(false);

	let { loading, refetch } = useQuery(gql(sampleGQL.getSampleCollection), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			const getCurrentUser = async (data) => {
				const userInfo = await getData("@storage_Key").then((user) => user);
				let team;

				if (data && data.project.workspace) {
					team = data.project.workspace.team;
				} else {
					team = data.project.team;
				}

				let projectUser = team.find(
					(tm) => tm.teamMember && tm.teamMember.id === userInfo.id
				);

				if (!projectUser) {
					// addNotification({
					// 	title: "Unauthorized Access",
					// 	message:
					// 		"Sorry you are not authorized to access this project. You must be added to the project team before you can access it",
					// 	type: "error",
					// 	time: 5000,
					// });
					navigation.goBack();
				}
			};

			setSampleCollection(data.getSampleCollection);
			setHomogeneousAreas([...data.getSampleCollection.homogeneousAreas]);
			getCurrentUser(data.getSampleCollection);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	const [updateSampleCollectionOperation] = useMutation(
		gql(sampleGQL.updateSampleCollection),
		{
			variables: {
				input: updatedSampleCollection,
			},
			onCompleted: (data) => {
				setSampleCollection(data.updateSampleCollection);

				setUpdatedSampleCollection({
					id: data.updateSampleCollection.id,
					title: data.updateSampleCollection.title,
				});
				setNewItem(null);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert("There was an error updating the sample.");
			},
		}
	);

	// const getLocation = () => {
	//
	// 	if (navigator.permissions && navigator.permissions.query) {
	// 		//try permissions APIs first

	// 		navigator.permissions
	// 			.query({ name: "geolocation" })
	// 			.then(function (result) {
	// 				// Will return ['granted', 'prompt', 'denied']

	// 				const permission = result.state;
	// 				if (permission === "granted" || permission === "prompt") {
	// 					_onGetCurrentLocation();
	// 				}
	// 			});
	// 	} else if (navigator.geolocation) {
	// 		//then Navigation APIs

	// 		_onGetCurrentLocation();
	// 	} else {
	//
	// 	}

	// 	function _onGetCurrentLocation() {
	// 		navigator.geolocation.getCurrentPosition(function () {
	// 			//imitate map latlng construct
	// 			// setLat(position.coords.latitude);
	// 			// setLng(position.coords.longitude);
	// 		});
	// 	}
	// };

	const generateSampleReport = async () => {
		if (!sampleCollection.samples.length) {
			return addNotification({
				title: "Cannot Generate Sample Report",
				text: "You must have at least one sample to generate a sample report",
				type: "error",
				time: 5000,
			});
		}
		addNotification({
			title: "Generating Sample Report",
			text: "This might take a moment. Please do not refresh the screen. The report will open in a new tab",
			type: "success",
			time: 5000,
		});
		fetch(
			"https://fk57a28ct3.execute-api.us-west-2.amazonaws.com/v2/sample-report",
			{
				method: "POST",
				mode: "cors",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(sampleCollection),
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				// download

				let link = document.createElement("a");
				link.setAttribute("target", "_blank");
				link.href = data.url;
				link.click();
			})
			.catch((err) => {
				SentryLogger(err);
			});
	};

	if (!sampleCollection || loading) {
		return <LoadingScreen loadItem={"Sample"} />;
	}

	const navigateToArea = (id) => {
		return navigation.navigate("HomogeneousArea", {
			id: id,
		});
	};

	const navigateToSample = (id) => {
		return navigation.navigate("SingleSample", {
			id: id,
		});
	};

	return (
		<>
			<Breadcrumb className="mt-2">
				<>
					{sampleCollection.project && sampleCollection.project.workspace && (
						<Crumb
							name={sampleCollection.project.workspace.title}
							location="Project"
							params={{
								id: sampleCollection.project.workspace.id,
							}}
						/>
					)}
				</>

				<Crumb
					name={sampleCollection.project.title}
					location="Project"
					params={{
						id: sampleCollection.project.id,
					}}
				/>
				<Crumb
					name={
						sampleCollection.title
							? sampleCollection.title
							: "Unnamed Sample Collection"
					}
					location="SampleCollection"
					params={{
						id: sampleCollection.id,
					}}
				/>
			</Breadcrumb>
			<PageHeader
				title={
					sampleCollection.title
						? sampleCollection.title
						: "Unnamed Sample Collection"
				}
				info={[
					{
						text:
							sampleCollection &&
							sampleCollection.lab &&
							sampleCollection.lab.name,
						icon: faVials,
					},
					{
						text:
							sampleCollection &&
							sampleCollection.lab &&
							sampleCollection.labLocation
								? sampleCollection.labLocation.name
								: "No Lab Location Selected",
						icon: faMapPin,
					},
					{
						text: sampleCollection && sampleCollection.type,
						icon: faVials,
					},
				]}
				buttons={[
					currentTab === "Samples" && {
						name: "Add Sample",
						icon: faPlus,
						onPress: () => {
							setNewItem("createSample");
						},
					},
					currentTab === "Areas" && {
						name: "Add Area",
						icon: faPlus,
						onPress: () => {
							setNewItem("createArea");
						},
					},
				]}
				options={
					<OptionsMenu
						options={[
							{
								icon: faPencil,
								name: "Edit Sample Collection",
								onPress: () => {
									setNewItem("editSampleCollection");
								},
							},
							{
								icon: faPencil,
								name: "Generate Sample Collection Report",
								onPress: () => {
									generateSampleReport();
								},
							},
							{
								icon: faPencil,
								name: "Generate COC",
								onPress: () => {},
							},
						]}
					/>
				}
				goBack={{
					location: "Project",
					params: {
						id: sampleCollection.project.id,
					},
				}}
			/>

			<>
				{sampleCollection.type.toUpperCase() === "ASBESTOS" && (
					<Tabs>
						{tabs.map((tab, index) => {
							return (
								<Tab
									key={index}
									name={tab.name}
									current={currentTab === tab.name ? true : false}
									icon={tab.icon}
									onPress={() => {
										setCurrentTab(tab.name);
									}}
								/>
							);
						})}
					</Tabs>
				)}
			</>

			{sampleCollection.type.toUpperCase() === "MICROBIOLOGY" && (
				<>
					{sampleCollection &&
					sampleCollection.samples &&
					sampleCollection.samples.length ? (
						<>
							<List headers={["Number", "Title", "Type", "Medium", "Date"]}>
								{sampleCollection.samples.map((sample, index) => {
									return (
										<ListItem
											key={index}
											smallView={
												<TouchableOpacity
													onPress={() => navigateToSample(sample.id)}
													className="w-full p-2 rounded-lg shadow-sm border-2 border-gray-200 dark:border-indigo-200"
												>
													<Text>
														Sample Number: {""}
														{sample.number || "No Sample Number"}
													</Text>
													<Text>
														Sample Title: {""}
														{sample.title || "No Sample Title"}
													</Text>
													<Text>
														Sample Type: {""}
														{sample.type ? sample.type.name : "No Sample Title"}
													</Text>
													<Text>
														Sample Medium: {""}
														{sample.medium
															? sample.medium.name
															: "No Medium Selected"}
													</Text>
													<Text>
														Sample Date: {""}
														{sample.date
															? `${DateTime.fromISO(sample.date).toLocaleString(
																	DateTime.DATETIME_SHORT
															  )}`
															: "No Sample Date"}
													</Text>
												</TouchableOpacity>
											}
											items={[
												{
													onPress: () => navigateToSample(sample.id),
													content: sample.number
														? sample.number
														: "No Sample Number",
												},
												{
													onPress: () => navigateToSample(sample.id),
													content: sample.title
														? sample.title
														: "No Sample Title",
												},
												{
													onPress: () => navigateToSample(sample.id),
													content: `${
														sample.type ? sample.type.name : "No Type Selected"
													}`,
												},
												{
													onPress: () => navigateToSample(sample.id),
													content: `${
														sample.medium
															? sample.medium.name
															: "No Medium Selected"
													}`,
												},
												{
													onPress: () => navigateToSample(sample.id),
													content: sample.date
														? `${DateTime.fromISO(sample.date).toLocaleString(
																DateTime.DATETIME_SHORT
														  )}`
														: "No Sample Date",
												},
											]}
										/>
									);
								})}
							</List>
						</>
					) : (
						<NoItems
							surTitle={"No Samples"}
							title="Currently No Samples"
							text="Create a sample by clicking add above"
						/>
					)}
				</>
			)}

			{currentTab === "Samples" &&
				sampleCollection.type.toUpperCase() === "ASBESTOS" && (
					<>
						{sampleCollection &&
						sampleCollection.samples &&
						sampleCollection.samples.length ? (
							<>
								<List headers={["Number", "Title", "Results", "Date"]}>
									{sampleCollection.samples.map((sample, index) => {
										return (
											<ListItem
												key={index}
												smallView={
													<TouchableOpacity
														onPress={() => navigateToSample(sample.id)}
														className="w-full p-2 rounded-lg shadow-sm border-2 border-gray-200 dark:border-indigo-200"
													>
														<Text>
															Sample Number: {""}
															{sample.number || "No Sample Number"}
														</Text>
														<Text>
															Sample Title: {""}
															{sample.title || "No Sample Title"}
														</Text>
														<Text>
															Sample Results: {""}
															{sample.positive == true
																? "Positive"
																: "Negative"}
														</Text>
														<Text>
															Sample Date: {""}
															{sample.date
																? `${DateTime.fromISO(
																		sample.date
																  ).toLocaleString(DateTime.DATETIME_SHORT)}`
																: "No Sample Date"}
														</Text>
													</TouchableOpacity>
												}
												items={[
													{
														onPress: () => navigateToSample(sample.id),
														content: sample.number
															? sample.number
															: "No Sample Number",
													},
													{
														onPress: () => navigateToSample(sample.id),
														content: sample.title
															? sample.title
															: "No Sample Title",
													},
													{
														onPress: () => navigateToSample(sample.id),
														content:
															sample.positive == true ? "Positive" : "Negative",
													},
													{
														onPress: () => navigateToSample(sample.id),
														content: sample.date
															? `${DateTime.fromISO(sample.date).toLocaleString(
																	DateTime.DATETIME_SHORT
															  )}`
															: "No Sample Date",
													},
												]}
											/>
										);
									})}
								</List>
							</>
						) : (
							<NoItems
								surTitle={"No Samples"}
								title="Currently No Samples"
								text="Create a sample by clicking add above"
							/>
						)}
					</>
				)}

			{currentTab === "Areas" &&
				sampleCollection.type.toUpperCase() === "ASBESTOS" && (
					<>
						{sampleCollection && sampleCollection.homogeneousAreas && (
							<List headers={["Name", "Materials"]}>
								{homogeneousAreas.map((area, index) => {
									return (
										<ListItem
											key={index}
											items={[
												{
													onPress: () => navigateToArea(area.id),
													content: area.name ? area.name : "No Area Number",
												},
												{
													onPress: () => navigateToArea(area.id),
													content: area.material
														? area.material
														: "No Materials",
												},
											]}
											smallView={
												<TouchableOpacity
													onPress={() => navigateToArea(area.id)}
													className="w-full p-2 rounded-lg shadow-sm border-2 border-gray-200 dark:border-indigo-200"
												>
													<Text>
														Area Name: {""}
														{area.name || "No Area Name"}
													</Text>
													<Text>
														Sample Medium: {""}
														{area.material || "No Material "}
													</Text>
												</TouchableOpacity>
											}
										/>
									);
								})}
							</List>
						)}
					</>
				)}

			<SlideOver
				isOpen={newItem === "editSampleCollection"}
				name="Edit Sample Collection"
				description={"Edit the sample collection settings"}
				closeRequest={() => setNewItem(null)}
				buttonRight="Update"
				onSubmit={(e) => {
					updateSampleCollectionOperation(e);
				}}
			>
				<Input
					label="Sample Collection Name"
					value={updatedSampleCollection.title}
					onChange={(e) =>
						setUpdatedSampleCollection({
							variables: {
								input: updatedSampleCollection,
								...updatedSampleCollection,
								title: e,
							},
						})
					}
				/>
			</SlideOver>

			{/* Add Create Area SlideOver */}
			<HomogeneousAreaSlideOver
				type="create"
				isOpen={newItem === "createArea"}
				closeRequest={() => {
					setNewItem(null);
				}}
				currentSampleCollection={sampleCollection}
				onCompleted={() => {
					setNewItem(null);
					refetch();
				}}
			/>

			<SampleSlideOver
				isOpen={newItem === "createSample"}
				type="create"
				closeRequest={() => {
					setNewItem(null);
				}}
				currentSampleCollection={sampleCollection}
				onCompleted={(data) => {
					navigation.navigate("SingleSample", {
						id: data.id,
					});
					setNewItem(null);
				}}
			/>
		</>
	);
}

SampleCollection.propTypes = {
	route: PropTypes.object,
};

export const SingleSample = ({ route }) => {
	const { id, sampleCollection } = route.params;
	const [user, setUser] = React.useState({});
	const [projectUser, setProjectUser] = React.useState({});
	const [sample, setSample] = React.useState(null);
	const [micro, setMicro] = React.useState(false);

	React.useEffect(() => {
		const getUser = async () => {
			await getData("@storage_Key")
				.then((user) => setUser(user))
				.catch((err) => SentryLogger(err));
		};
		getUser();
	}, []);

	const [newItem, setNewItem] = useState(null);

	useQuery(gql(sampleGQL.getSample), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			const getCurrentUser = async (data) => {
				const user = await getData("@storage_Key").then((user) => user);
				let team;

				if (data && data.sampleCollection.project.workspace) {
					team = data.sampleCollection.project.workspace.team;
				} else {
					team = data.sampleCollection.project.team;
				}

				let projectUser = team.filter(
					(tm) => tm.teamMember && tm.teamMember.id === user.id
				);
				if (!projectUser.length) {
					addNotification({
						title: "Unauthorized Access",
						text: "Sorry you are not authorized to access this project. You must be added to the project team before you can access it",
						type: "error",
						time: 5000,
					});
				} else {
					setProjectUser(projectUser);
				}
			};
			checkType(data.getSample.sampleCollection.type);
			setSample(data.getSample);
			getCurrentUser(data.getSample);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			navigator.navigate("SampleCollection", {
				sampleCollection: sampleCollection,
			});
		},
		fetchPolicy: "cache-and-network",
	});

	/**
	 * getSignedUrl
	 * @param {string} link - the S3 key from the media object
	 * get a signed s3 url
	 */

	// const getSignedUrl = (link) => {
	// 	let params = { Bucket: "srp-in-field", Key: link };
	// 	return s3.getSignedUrl("getObject", params);
	// };

	const getButtons = () => {
		if (!sample.user) return;

		if (
			user.id === sample.user.id ||
			projectUser.isAdmin ||
			projectUser.isSuperAdmin
		) {
			return [
				{
					icon: faPen,
					name: "Update Sample",
					onPress: () => {
						setNewItem("updateSample");
					},
				},
			];
		}
	};

	const checkType = (type) => {
		if (type === "MICROBIOLOGY") {
			setMicro(true);
		} else {
			setMicro(false);
		}
	};

	if (!sample) {
		return <LoadingScreen loadItem={"sample"} />;
	}

	return (
		<>
			{/** * Will need to add in breadcrumbs so the user can navigate back to the samples */}

			<Breadcrumb className="mt-2">
				<>
					{sample.sampleCollection.project.workspace && (
						<Crumb
							name={sample.sampleCollection.project.workspace.title}
							location="Project"
							params={{
								id: sample.sampleCollection.project.workspace.id,
							}}
						/>
					)}
				</>

				<Crumb
					name={sample.sampleCollection.project.title}
					location="Project"
					params={{
						id: sample.sampleCollection.project.id,
					}}
				/>
				<Crumb
					name={sample.sampleCollection && sample.sampleCollection.title}
					location="SampleCollection"
					params={{
						id: sample.sampleCollection.id,
					}}
				/>
				<Crumb
					name={sample.number ? sample.number : "No Sample Number"}
					location="SingleSample"
					params={{
						id: sample.id,
					}}
				/>
			</Breadcrumb>

			<PageHeader
				title={`Sample: ${sample.number}`}
				buttons={getButtons()}
				goBack={{
					location: "SampleCollection",
					params: {
						id: sample.sampleCollection.id,
					},
				}}
			/>

			<DataContainer
				title={sample.number}
				description={
					sample.date
						? `${DateTime.fromISO(sample.date).toLocaleString(
								DateTime.DATETIME_SHORT
						  )}`
						: "No Sample Date"
				}
				className="m-2"
			>
				<DataItem name="Title" value={sample.title} />
				<DataItem name="Description" value={sample.description} />
				<DataItem
					name="Results"
					value={sample.positive == true ? sample.result : "Negative"}
				/>
				<DataItem
					name="Friable"
					value={sample.friable == true ? "True" : "False"}
				/>
				<>
					{micro ? (
						<>
							<DataItem
								name="Analysis Type"
								value={sample.type ? sample.type.name : "No Type Selected"}
							/>
							<DataItem
								name="Medium"
								value={
									sample.medium ? sample.medium.name : "No Medium Selected"
								}
							/>
						</>
					) : null}
				</>

				<DataItem name="Collected By" value={<User user={sample.user} />} />
				{/* {sample.media && sample.media.length !== 0 && (
					<DataItem
						name="Media"
						value={
							<Gallery>
								{sample.media.map((item, index) => {
									return (
										<GalleryItem key={index} source={getSignedUrl(item.key)} />
									);
								})}
							</Gallery>
						}
					/>
				)} */}
			</DataContainer>
			<SampleSlideOver
				isOpen={newItem === "updateSample"}
				type="update"
				closeRequest={() => {
					setNewItem(null);
				}}
				onCompleted={(data) => {
					setSample(data);
					setNewItem(null);
				}}
				currentSampleCollection={sample.sampleCollection}
				currentSample={sample}
				defaultValues={{
					type: sample.type ? sample.type.name : null,
					medium: sample.medium ? sample.medium.name : null,
				}}
			/>
		</>
	);
};

SingleSample.propTypes = {
	route: PropTypes.object,
};

export const HomogeneousArea = ({ route }) => {
	const { id } = route.params;
	const [homogeneousArea, setHomogeneousArea] = React.useState({});

	const [newItem, setNewItem] = React.useState(null);

	const { loading, refetch } = useQuery(gql(areaGQL.getHomogeneousArea), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setHomogeneousArea(data.getHomogeneousArea);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	if (loading) {
		return <LoadingScreen loadItem={"Homogeneous Area"} />;
	}
	return (
		<>
			<Breadcrumb className="mt-2">
				<Crumb
					name={
						homogeneousArea.sampleCollection &&
						homogeneousArea.sampleCollection.title
					}
					location="SampleCollection"
					params={{
						id:
							homogeneousArea.sampleCollection &&
							homogeneousArea.sampleCollection.id,
					}}
				/>

				<Crumb
					name={homogeneousArea.name}
					location="HomogeneousArea"
					params={{
						id: homogeneousArea.id,
					}}
				/>
			</Breadcrumb>
			<PageHeader
				title={homogeneousArea.name}
				description={
					homogeneousArea.sampleCollection &&
					homogeneousArea.sampleCollection.title
				}
				buttons={[
					{
						name: "Update Area",
						icon: faPencil,
						onPress: () => setNewItem("updateArea"),
					},
				]}
				goBack={{
					location: "SampleCollection",
					params: {
						id:
							homogeneousArea.sampleCollection &&
							homogeneousArea.sampleCollection.id,
					},
				}}
			/>
			<ScrollView>
				<DataContainer>
					<DataItem name={"Name"} value={homogeneousArea.name} />
					<DataItem name={"Material"} value={homogeneousArea.material} />
					<DataItem
						name={"Square Footage"}
						value={homogeneousArea.squareFootage}
					/>
					<DataItem
						name={"HA Results"}
						value={homogeneousArea.positive ? "Positive" : "Negative"}
					/>
					<DataItem
						name={"Potential for Contact"}
						value={
							homogeneousArea.potentialForContact
								? homogeneousArea.potentialForContact
								: "N/A"
						}
					/>
					<DataItem
						name={"Influence of Vibration"}
						value={
							homogeneousArea.influenceOfVibration
								? homogeneousArea.influenceOfVibration
								: "N/A"
						}
					/>
					<DataItem
						name={"Influence of Air Erosion"}
						value={
							homogeneousArea.influenceOfAirErosion
								? homogeneousArea.influenceOfAirErosion
								: "N/A"
						}
					/>
					<DataItem
						name={"Overall Potential"}
						value={homogeneousArea.overallPotential}
					/>
				</DataContainer>
				<>
					{homogeneousArea.samples.length ? (
						<List
							headers={[
								"Title",
								"Date",
								"Number",
								"Description",
								"Friable",
								"Result",
							]}
						>
							{homogeneousArea.samples.map((sample) => (
								<ListItem
									key={sample.id}
									items={[
										{
											content: sample.title ? sample.title : "No Sample Title",
										},
										{
											content: sample.date
												? DateTime.fromISO(sample.date).toFormat("yyyy LLL dd")
												: "No Sample Date",
										},
										{
											content: sample.number
												? sample.number
												: "No Sample Number",
										},
										{
											content: sample.description
												? sample.description
												: "No Sample Description",
										},
										{
											content: sample.friable == true ? "True" : "False",
										},
										{
											content: sample.result == true ? sample.result : "False",
										},
									]}
									smallView={
										<>
											<Pressable>
												<View>
													<Text className=" font-bold text-md dark:text-white ">
														{sample.title}
													</Text>
													<Text className="font-medium dark:text-white">
														Date:{" "}
														{`${
															sample.date
																? DateTime.fromISO(sample.date).toFormat(
																		"yyyy LLL dd"
																  )
																: "No Sample Date"
														} `}
													</Text>
													<Text className="font-medium dark:text-white">
														Number:{" "}
														{sample.number ? sample.number : "No Sample Number"}
													</Text>
													<Text className="font-medium dark:text-white">
														Description:{" "}
														{sample.description
															? sample.description
															: "No Sample Description"}
													</Text>
													<Text className="font-medium dark:text-white">
														Friable: {sample.friable == true ? "True" : "False"}
													</Text>
													<Text className="font-medium dark:text-white">
														Results: {sample.results == true ? "True" : "False"}
													</Text>
												</View>
											</Pressable>
										</>
									}
								/>
							))}
						</List>
					) : (
						<Text className="text-lg  text-center text-indigo-500 font-medium py-2">
							No samples associated to this area
						</Text>
					)}

					<HomogeneousAreaSlideOver
						type="update"
						currentArea={homogeneousArea}
						isOpen={newItem === "updateArea"}
						closeRequest={() => {
							setNewItem(null);
						}}
						onCompleted={() => {
							setNewItem(null);
							refetch();
						}}
					/>
				</>
			</ScrollView>
		</>
	);
};

HomogeneousArea.propTypes = {
	route: PropTypes.object,
};
