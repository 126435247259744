export const listReportingUsers = /* GraphQL */ `
	query ListReportingUsers {
		items {
			id
			firstName
			lastName
			role
		}
	}
`;

export const listReportTypes = /* GraphQL */ `
	query ListReportTypes(
		$filter: TableReportTypesFilterInput
		$limit: Int
		$nextToken: String
	) {
		listReportTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				options
				endPoint
				category
				description
				active
				requiresReview
			}
			nextToken
		}
		listReportUsers {
			items {
				id
				firstName
				lastName
				role
				title
			}
		}
	}
`;

export const createUser = /* GraphQL */ `
	mutation CreateUser($input: CreateUserInput!) {
		createUser(input: $input) {
			id
			isManager
			isAdmin
			isTeamMember
			isTempWorker
			isContractor
			firstName
			lastName
			email
			phone
			role
		}
	}
`;

export const createReport = /* GraphQL */ `
	mutation CreateReport($input: CreateReportInput!) {
		createReport(input: $input) {
			id
		}
	}
`;

export const createReportLink = /* GraphQL */ `
	mutation CreateReportLink($input: CreateReportLinkInput!) {
		createReportLink(input: $input) {
			id
			link
			date
			report {
				id
			}
		}
	}
`;

export const deleteReportLink = /* GraphQL */ `
	mutation DeleteReportLink($input: DeleteReportLinkInput!) {
		deleteReportLink(input: $input) {
			id
			link
			name
		}
	}
`;

export const getReport = /* GraphQL */ `
	query GetReport($id: ID!) {
		getReport(id: $id) {
			id
			date
			responses
			project {
				id
				title
				client {
					id
					name
					location {
						lineOne
						lineTwo
						city
						state
						zip
					}
				}
				location {
					id
					name
					lineOne
					lineTwo
					city
					state
					zip
				}
				office {
					id
					name
					lineOne
					lineTwo
					city
					state
					zip
				}
				team {
					id
					teamMember {
						id
						firstName
						lastName
						role
						phone
					}
					projectRole {
						id
						name
					}
					isAdmin
					isSuperAdmin
				}
				workspace {
					id
					title
					client {
						id
						name
					}
					team {
						id
						teamMember {
							id
							firstName
							lastName
							role
						}
						projectRole {
							id
							name
						}
						isAdmin
						isSuperAdmin
					}
				}
			}
			reportType {
				id
				name
				endPoint
				options
			}
			reviewer {
				id
				firstName
				lastName
				role
				profilePicture
			}
			author {
				id
				firstName
				lastName
				role
				profilePicture
				phone
			}
			addressedTo {
				id
				firstName
				lastName
				role
			}
			documents {
				id
				name
				link
				date
				isOfficial
			}
		}
	}
`;

export const getProject = /* GraphQL */ `
	query GetProject($id: ID!) {
		getProject(id: $id) {
			id
			title
			workspace {
				id
				title
				team {
					id
					teamMember {
						id
						firstName
						lastName
						role
						phone
						title
					}
					projectRole {
						id
						name
					}
					isAdmin
					isSuperAdmin
				}
				client {
					location {
						id
						name
						lineOne
						lineTwo
						city
						state
						zip
					}
					users {
						id
						firstName
						lastName
						email
					}
					id
					name
				}
				office {
					id
					name
					lineOne
					lineTwo
					city
					state
					zip
				}
			}
			location {
				id
				name
				lineOne
				lineTwo
				city
				state
				zip
			}
			client {
				location {
					id
					name
					lineOne
					lineTwo
					city
					state
					zip
				}
				users {
					id
					firstName
					lastName
					email
				}
				id
				name
			}
			team {
				id
				teamMember {
					id
					firstName
					lastName
					role
					phone
					title
				}
				projectRole {
					id
					name
				}
				isAdmin
				isSuperAdmin
			}
			office {
				id
				name
				lineOne
				lineTwo
				city
				state
				zip
			}
			samples {
				id
				title
				date
				type
			}
		}
	}
`;

export const listReport = /* GraphQL */ `
	query ListReports(
		$filter: TableReportFilterInput
		$limit: Int
		$nextToken: String
	) {
		listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				addressedTo {
					id
					firstName
					lastName
				}
				author {
					id
					firstName
					lastName
				}
				date
				documents {
					date
					id
					isOfficial
					link
					name
				}
				id
				officialReport
				project {
					id
					title
					client {
						id
						name
					}
					office {
						id
						name
					}
				}
				reportType {
					active
					category
					description
					endPoint
					id
					name
					options
					price
					requiresReview
				}
				responses
				reviewer {
					id
					firstName
					lastName
				}
				status
			}
			total
		}
	}
`;

export const updateReportLink = /* GraphQL */ `
	mutation UpdateReportLink($input: UpdateReportLinkInput!) {
		updateReportLink(input: $input) {
			id
			name
			link
			date
			isOfficial
		}
	}
`;

export const getReportTypes = /* GraphQL */ `
	query GetReportTypes($id: ID!) {
		getReportTypes(id: $id) {
			id
			name
			description
			category
			active
			options
			price
			endPoint
			requiresReview
		}
	}
`;
