import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";
import { View } from "react-native";
// components

import { List, ListItem } from "../../components/List";
import { PageHeader } from "../../components/Header";
import LoadingScreen from "../../components/LoadingScreen";
import NoItems from "../../components/NoItems";
import Pagination from "../../components/Pagination";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb.js";
import OptionsMenu from "../../components/OptionsMenu";

// GQL

import { gql, useLazyQuery } from "@apollo/client";
import * as subcontractorGQL from "../../graphql/admin/subcontractors";

//icons
import { faEye } from "@fortawesome/pro-duotone-svg-icons";

//utils
import { getData, SentryLogger } from "../../utils";

export default function SingleSubcontractorAdmin({}) {
	// const user = getData("@storage_Key").then((user) => user);
	const navigation = useNavigation();
	const itemsPerPage = 20;
	const [users, setUsers] = React.useState(null);
	const [subcontractor, setSubcontractor] = useState([]);
	let [currentPage, setCurrentPage] = useState(1);
	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end = users
		? currentPage * itemsPerPage > users.length
			? users.length
			: currentPage * itemsPerPage
		: 0;

	React.useEffect(() => {
		const getUser = async () => {
			await getData("@storage_Key")
				.then((user) => {
					getSubcontractor({ variables: { id: user.subcontractor.id } });
				})
				.catch((err) => SentryLogger(err));
		};
		getUser();
	}, []);

	const [getSubcontractor, { loading }] = useLazyQuery(
		gql(subcontractorGQL.getSubcontractor),
		{
			onCompleted: (data) => {
				setSubcontractor(data.getSubcontractor);
				setUsers([...data.getSubcontractor.users]);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
			fetchPolicy: "cache-and-network",
		}
	);

	let options = (user) => (
		<OptionsMenu
			options={[
				{
					name: "View User",
					icon: faEye,
					onPress: () => navigateToUser(user.id),
				},
			]}
		/>
	);

	const navigateToUser = (id) => {
		navigation.navigate("Subcontractor User", { id: id });
	};
	if (loading) {
		return <LoadingScreen loadItem={"Information"} />;
	}

	return (
		<View className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
			{/* Page title & actions */}
			<Breadcrumb className="mt-2">
				<Crumb name="Subcontractor Users" location="Subcontractor Users" />
			</Breadcrumb>

			<PageHeader openMenu title={subcontractor.name} />

			{users ? (
				<>
					<List
						headers={["First Name", "Last Name", "Email", "Role", "Status"]}
						usesOptions
					>
						{users
							.sort((a, b) => {
								if (a.firstName > b.firstName) return 1;
								if (a.firstName < b.firstName) return -1;
								return 0;
							})
							.map((user, index) => {
								if (index + 1 >= start && index < end) {
									return (
										<ListItem
											key={index}
											items={[
												{
													content: user.firstName,
													onPress: () => navigateToUser(user.id),
												},
												{
													content: user.lastName,
													onPress: () => navigateToUser(user.id),
												},
												{
													content: user.email,
													onPress: () => navigateToUser(user.id),
												},
												{
													content: user.role,
													onPress: () => navigateToUser(user.id),
												},
												{
													content:
														user.isActive === false ? "Deactivated" : "Active",
													onPress: () => navigateToUser(user.id),
												},
											]}
											options={options(user)}
										/>
									);
								}
							})}
					</List>
					<Pagination
						start={start}
						stop={end}
						total={users.length}
						nextClicked={() => setCurrentPage(currentPage + 1)}
						previousClicked={() => setCurrentPage(currentPage - 1)}
					/>
				</>
			) : (
				<NoItems surTitle="No Users" title="Currently No Users" text="" />
			)}
		</View>
	);
}
SingleSubcontractorAdmin.propTypes = {
	route: PropTypes.object,
};
