import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState } from "react";

// Components
import { Alert } from "../components/Alert";
import { FieldSet, Radio, Select } from "../components/Forms";
import SlideOver from "../components/Slideover";

// Utils
import { API_URL, getData } from "../utils";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as projectGQL from "../graphql/project";

export const ProjectTeamMember = ({
	isOpen,
	currentTeamMember,
	onComplete,
	project,
	defaultSelectValues = {},
	closeRequest = () => {},
	type = "create",
}) => {
	const [user, setUser] = useState(null);
	let [teamMember, setTeamMember] = useState({});
	let [projectRoles, setProjectRoles] = useState([]);
	let [users, setUsers] = useState([]);

	React.useEffect(() => {
		if (currentTeamMember) {
			setTeamMember(currentTeamMember);
		}
	}, [currentTeamMember]);

	React.useEffect(() => {
		const userInfo = async () => {
			await getData("@storage_Key").then((user) => {
				setUser(user);
			});
		};

		userInfo();
	}, []);

	useQuery(gql(projectGQL.listUsers), {
		onCompleted: (data) => {
			setUsers(data.listUsers.items);
		},
	});

	useQuery(gql(projectGQL.listProjectRoles), {
		variables: {
			filter: {
				active: true,
			},
		},
		onCompleted: (data) => {
			setProjectRoles([...data.listProjectRoles.items]);
		},
	});

	const [createTeamMember] = useMutation(
		gql(projectGQL.createProjectTeamMember),
		{
			onCompleted: async (data) => {
				// send notification
				await fetch(`${API_URL}/notifications/notification`, {
					method: "POST",
					mode: "cors",
					body: JSON.stringify({
						notification: {
							title: "🚀 New Project Incoming",
							message: `${user.firstName} ${user.lastName} has added you to ${project.title}`,
							createdBy: user.id,
							user: data.createProjectTeamMember.teamMember.id,
						},
						type: "projects",
					}),
				}).then((res) => res.json());

				onComplete();
			},
			onError: () => {
				Alert("Error creating project role");
			},
		}
	);

	const [updateTeamMember] = useMutation(
		gql(projectGQL.updateProjectTeamMember),
		{
			onCompleted: () => {
				onComplete();
			},
			onError: () => {
				Alert("Error updating project role");
			},
		}
	);

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "New Team Member" : "Edit Team Member"}
			description={`Add New Team Member to ${project.title}`}
			closeRequest={() => closeRequest}
			buttonRight={type === "create" ? "Add" : "Update"}
			disable={true}
			required={true}
			onSubmit={(e) => {
				e.preventDefault();
				if (teamMember.projectRole == null) {
					Alert("Please select a Project Role");
				} else if (type === "create" && teamMember.projectRole != null) {
					let input = teamMember;
					input.project = project.id;
					input.added = DateTime.local().toISO();
					if (teamMember.projectRole == null || teamMember.projectRole == "") {
						Alert("Please select a Project Role");
					} else {
						createTeamMember({
							variables: {
								input: input,
							},
						});
					}
				} else {
					let input = teamMember;
					delete input.teamMember;

					updateTeamMember({
						variables: {
							input: input,
						},
					});
				}
			}}
		>
			<Select
				name="User"
				options={users
					.filter(
						(user) => user.isActive !== false && user.isRequesting !== true
					)
					.sort((a, b) => {
						if (a.firstName > b.firstName) return 1;
						if (a.firstName < b.firstName) return -1;
						return 0;
					})
					.map((user) => {
						return {
							name: `${user.firstName} ${user.lastName}${
								user.isTeamMember ? " (SRP Employee)" : ""
							}${user.isContractor ? " (SRP Contractor)" : ""}`,
							value: user.id,
						};
					})}
				onChange={(value) =>
					setTeamMember({ ...teamMember, teamMember: value.value })
				}
				defaultValue={defaultSelectValues.user}
			/>

			<Select
				name="Role"
				options={projectRoles
					.sort((a, b) => {
						if (a.name > b.name) return 1;
						if (a.name < b.name) return -1;
						return 0;
					})
					.map((role) => {
						return {
							name: role.name,
							value: role.id,
						};
					})}
				onChange={(value) =>
					setTeamMember({ ...teamMember, projectRole: value.value })
				}
				defaultValue={defaultSelectValues.role}
			/>

			<FieldSet>
				<Radio
					name="permission"
					label="Permission"
					description="Can create, update, and delete all items associated with the project"
					onChange={(value) => {
						if (value.label == "Admin") {
							setTeamMember({
								...teamMember,
								isAdmin: true,
								isGeneral: false,
								isReadOnly: false,
							});
						}
						if (value.label == "General") {
							setTeamMember({
								...teamMember,
								isAdmin: false,
								isGeneral: true,
								isReadOnly: false,
							});
						}
						if (value.label == "Read Only") {
							setTeamMember({
								...teamMember,
								isAdmin: false,
								isGeneral: false,
								isReadOnly: true,
							});
						}
					}}
					options={[
						{
							id: 1,
							label: "Admin",
						},
						{
							id: 2,
							label: "General",
						},
						{
							id: 3,
							label: "Read Only",
						},
					]}
					horizontal={true}
				/>
			</FieldSet>
		</SlideOver>
	);
};

ProjectTeamMember.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	currentTeamMember: PropTypes.object,
	project: PropTypes.object,
	defaultSelectValues: PropTypes.object,
};
