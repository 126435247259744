import PropTypes from "prop-types";
import React, { useState } from "react";
import Toast from "react-native-toast-message";

// components
import { Checkbox, Input, Select } from "../components/Forms";
import SlideOver from "../components/Slideover";

// Icons
import { faHashtag, faReceipt } from "@fortawesome/pro-duotone-svg-icons";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as expenseTypeGQL from "../graphql/expenseType";
import * as rateSheetGQL from "../graphql/rateSheet";
import { SentryLogger } from "../utils";

/**
 * RateSheetContainer
 * @param {object} props - props to be supplied to the slide over
 * @param {object} props.currentRateSheet
 * @param {object} props.subcontractor
 * @param {object} props.client
 * @param {function} props.onComplete - a function to be called when the slide over has completed the createRequest it will send the moisture point back to the parent component
 * @param {function} props.closeRequest - a function to be called when the slide over been closed by the user
 * @param {boolean} props.isOpen - a boolean to determine if the slide over should be shown
 * @returns {object} - JSX
 */

const RateSheetContainer = ({
	isOpen,
	closeRequest,
	onComplete,
	currentRateSheet,
	type = "create",
	client,
	subcontractor,
}) => {
	const [rateSheet, setRateSheet] = React.useState({ name: "" });

	React.useEffect(() => {
		if (currentRateSheet) {
			setRateSheet(currentRateSheet);
		}
	}, [currentRateSheet]);

	const [createRateSheet] = useMutation(gql(rateSheetGQL.createRateSheet));
	const [updateRateSheet] = useMutation(gql(rateSheetGQL.updateRateSheet));

	const handleSuccess = (data, action) => {
		Toast.show({
			type: "success",
			text1: `Successfully ${action}`,
			text2: `The rate sheet has been ${action.toLowerCase()}`,
		});
		setRateSheet({ name: "" });
		onComplete(data);
	};

	const handleError = (error) => {
		Toast.show({
			text1: "Something Went Wrong",
			text2: error.message,
			type: "error",
			time: 5000,
		});
	};

	return (
		<SlideOver
			closeRequest={() => {
				closeRequest();
			}}
			isOpen={isOpen}
			name={"Billing Rate Sheets"}
			description={
				type === "create" ? "Create new rate sheet" : "Update rate sheet"
			}
			onSubmit={() => {
				if (!rateSheet.name) {
					return Toast.show({
						text1: "Please enter a name for the rate sheet",
						type: "error",
					});
				}

				if (type === "create") {
					let input = {
						name: rateSheet.name,
						client: client ? client.id : null,
						subcontractor: subcontractor ? subcontractor.id : null,
					};

					createRateSheet({
						variables: {
							input: input,
						},
					})
						.then((data) => handleSuccess(data, "Created"))
						.catch(handleError);
				} else {
					let input = {
						id: rateSheet.id,
						name: rateSheet.name,
						client: client ? client.id : null,
						subcontractor: subcontractor ? subcontractor.id : null,
					};

					updateRateSheet({
						variables: {
							input: input,
						},
					})
						.then((data) => handleSuccess(data, "Updated"))
						.catch(handleError);
				}
			}}
		>
			<Input
				label="Rate Sheet Name"
				value={rateSheet.name}
				onChange={(e) => {
					setRateSheet({ ...rateSheet, name: e });
				}}
			/>
		</SlideOver>
	);
};

RateSheetContainer.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	currentRateSheet: PropTypes.object,
	currentExpenseFields: PropTypes.object,
	currentProjectFields: PropTypes.object,
	billingRateSheet: PropTypes.bool,
	subcontractor: PropTypes.object,
	client: PropTypes.object,
};

export default RateSheetContainer;

export const CategoryContainer = ({
	isOpen,
	closeRequest,
	type,
	onComplete,
	currentCategory,
	rateSheet,
}) => {
	const CREATE_CATEGORY = gql`
		mutation createCategory($input: CategoryInput!) {
			createCategory(input: $input) {
				id
				name
				description
				markupPercentage
				rateSheet {
					id
				}
			}
		}
	`;

	const UPDATE_CATEGORY = gql`
		mutation updateCategory($input: CategoryInput!) {
			updateCategory(input: $input) {
				id
				name
				description
				markupPercentage
				rateSheet {
					id
				}
			}
		}
	`;

	const [category, setCategory] = React.useState({});
	const [submitting, setSubmitting] = React.useState(false);

	React.useEffect(() => {
		if (currentCategory) {
			setCategory(currentCategory);
		} else {
			setCategory({ rateSheet: rateSheet.id });
		}
	}, [currentCategory]);

	const [createCategory, { loading: createLoading }] =
		useMutation(CREATE_CATEGORY);

	const [updateCategory, { loading: updateLoading }] =
		useMutation(UPDATE_CATEGORY);

	// refs

	const nameRef = React.createRef();

	// functions

	const checkForError = (field) => {
		if (submitting && (!category[field] || category[field].trim() === "")) {
			return "This field is required";
		} else {
			return false;
		}
	};

	const handleSuccess = (data, action) => {
		Toast.show({
			text1: `${action} Category`,
			type: "success",
		});
		onComplete(data.createCategory);
		setCategory({});
		setSubmitting(false);
	};

	const handleError = (error) => {
		Toast.show({ text1: error.message, type: "error" });
		setSubmitting(false);
	};

	return (
		<SlideOver
			isOpen={isOpen}
			closeRequest={closeRequest}
			onSubmit={() => {
				if (!category.name || category.name.trim() === "") {
					// nameRef.current.onFocus();
					Toast.show({
						text1: "Please enter a name for the category",
						type: "error",
					});
					return;
				}

				setSubmitting(true);

				if (type === "create") {
					let input = {
						name: category.name,
						description: category.description,
						markupPercentage: category.markupPercentage,
						rateSheet: rateSheet.id,
						isPerDiemCategory: category.isPerDiemCategory,
						isDailySheetCategory: category.isDailySheetCategory,
					};
					createCategory({
						variables: {
							input: input,
						},
					})
						.then(({ data }) => handleSuccess(data, "Create"))
						.catch(handleError);
				} else {
					let input = {
						id: category.id || null,
						name: category.name,
						description: category.description,
						markupPercentage: category.markupPercentage,
						rateSheet: rateSheet.id,
						isPerDiemCategory: category.isPerDiemCategory,
						isDailySheetCategory: category.isDailySheetCategory,
					};
					updateCategory({
						variables: {
							input: input,
						},
					})
						.then(({ data }) => handleSuccess(data, "Update"))
						.catch(handleError);
				}
			}}
			name={currentCategory ? "Edit Category" : "Create Category"}
			loading={createLoading || updateLoading}
		>
			<Input
				label="Name"
				value={category.name}
				onChange={(e) => setCategory({ ...category, name: e })}
				ref={nameRef}
				error={checkForError("name")}
			/>
			<Input
				label="Markup"
				type="number"
				value={category.markupPercentage}
				onChange={(e) => setCategory({ ...category, markupPercentage: e })}
			/>
			<Input
				label="Description"
				value={category.description}
				onChange={(value) => setCategory({ ...category, description: value })}
			/>
			<Checkbox
				label="Category for Per Diem"
				description="Should per diem be placed in this category on the invoice"
				onChange={(e) => setCategory({ ...category, isPerDiemCategory: e })}
				checkedValue={category.isPerDiemCategory}
			/>

			<Checkbox
				label="Category for Daily Sheet Fields"
				description="If selected this category will be used for daily sheet fields"
				onChange={(e) => setCategory({ ...category, isDailySheetCategory: e })}
				checkedValue={category.isDailySheetCategory}
			/>
		</SlideOver>
	);
};

CategoryContainer.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	currentCategory: PropTypes.object,
	rateSheet: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	}),
};

export const ExpenseTypeSlideOver = ({
	isOpen,
	closeRequest = () => {},
	type,
	onComplete,
	currentExpenseType,
	categoryTypes,
	defaultValues = {},
	rateSheet,
}) => {
	const [expenseType, setExpenseType] = useState({});

	// sets the current expense type if there is one

	React.useEffect(() => {
		if (currentExpenseType) {
			setExpenseType(currentExpenseType);
		} else {
			setExpenseType({ active: true });
		}
	}, [currentExpenseType]);

	const [createExpenseType] = useMutation(
		gql(expenseTypeGQL.createExpenseType)
	);
	const [updateExpenseType] = useMutation(
		gql(expenseTypeGQL.updateExpenseType)
	);

	const handleSuccess = (data, action) => {
		onComplete(data.createExpenseType);
		setExpenseType({ active: true });
		Toast.show({
			text1: `Expense Type ${action}`,
			text2: `The expense type was created successfully ${action.toLowerCase()}`,
			type: "success",
			time: 5000,
		});
	};

	const handleError = (error) => {
		Toast.show({
			text1: "Something Went Wrong",
			text2: error.message,
			type: "error",
			time: 5000,
		});
	};

	return (
		<SlideOver
			isOpen={isOpen}
			name="New Expense Type "
			description={"Create a new expense type "}
			closeRequest={() => {
				closeRequest();
			}}
			onSubmit={() => {
				delete expenseType.__typename;

				if (type === "create") {
					// create expenseType

					let input = {
						name: expenseType.name,
						description: expenseType.description,
						category: expenseType.category.id,
						active: expenseType.active,
						rateSheet: rateSheet.id,
						sageItemID: expenseType.sageItemID,
						requiresReceipt: expenseType.requiresReceipt,
					};
					createExpenseType({
						variables: {
							input: input,
						},
					})
						.then((data) => handleSuccess(data, "Created"))
						.catch(handleError);
				} else {
					let input = {
						id: expenseType.id || null,
						name: expenseType.name,
						description: expenseType.description,
						category: expenseType.category.id,
						active: expenseType.active,
						rateSheet: rateSheet.id,
						sageItemID: expenseType.sageItemID,
						requiresReceipt: expenseType.requiresReceipt,
					};

					updateExpenseType({
						variables: {
							input: input,
						},
					})
						.then((data) => handleSuccess(data, "Updated"))
						.catch(handleError);
				}
			}}
		>
			<Input
				label="Name"
				required
				helper="Enter Expense Type Name"
				icon={faReceipt}
				value={expenseType.name}
				onChange={(e) =>
					setExpenseType({
						...expenseType,
						name: e,
					})
				}
			/>
			<Select
				name="Charge Category"
				defaultValue={defaultValues?.category}
				options={categoryTypes.map((type) => ({
					name: type.name,
					value: type,
				}))}
				onChange={(value) => {
					setExpenseType({ ...expenseType, category: value.value });
				}}
			/>
			<Input
				label="Sage Intacct"
				icon={faHashtag}
				required
				helper="Enter the Sage Intacct ID associated with this expense type"
				value={expenseType.sageItemID}
				onChange={(value) => {
					setExpenseType({
						...expenseType,
						sageItemID: value,
					});
				}}
			/>
			<Checkbox
				label="Active"
				checkedValue={expenseType.active}
				onChange={() => {
					setExpenseType({
						...expenseType,
						active: !expenseType.active,
					});
				}}
			/>

			<Checkbox
				label="Requires Receipt"
				checkedValue={expenseType.requiresReceipt}
				onChange={() => {
					setExpenseType({
						...expenseType,
						requiresReceipt: !expenseType.requiresReceipt,
					});
				}}
			/>
		</SlideOver>
	);
};

ExpenseTypeSlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	currentExpenseType: PropTypes.object,
	categoryTypes: PropTypes.arrayOf(PropTypes.string),
	defaultValues: PropTypes.object,
	rateSheet: PropTypes.object,
};

export const RateFieldContainer = ({
	isOpen,
	closeRequest = () => {},
	type,
	onComplete,
	currentRateField,
	rateSheet,
	defaultValues = {},
}) => {
	// use state variables

	const [rateField, setRateField] = useState({});
	const [projectRoles, setProjectRoles] = useState([]);

	// query hooks

	useQuery(gql(rateSheetGQL.listProjectRoles), {
		variables: {
			filter: {
				active: true,
			},
		},
		onCompleted: (data) => {
			setProjectRoles(data.listProjectRoles.items);
		},
		onError: (error) => {
			SentryLogger(error).then(() => {
				Toast.show({
					text1: "There was an error listing the project loads",
					type: "error",
				});
			});
		},
	});

	// mutation hooks
	const [createRateField] = useMutation(gql(rateSheetGQL.createRateField));
	const [updateRateField] = useMutation(gql(rateSheetGQL.updateRateField));

	const handleSuccess = (data, action) => {
		onComplete(data.createExpenseType);
		setRateField({ active: true });
		Toast.show({
			text1: `Expense Type ${action}`,
			text2: `The expense type was created successfully ${action.toLowerCase()}`,
			type: "success",
			time: 5000,
		});
	};

	const handleError = (error) => {
		Toast.show({
			text1: "Something Went Wrong",
			text2: error.message,
			type: "error",
			time: 5000,
		});
	};

	// sets the current rate field if there is one
	React.useEffect(() => {
		if (currentRateField) {
			setRateField(currentRateField);
		} else {
			setRateField({ active: true });
		}
	}, [currentRateField]);

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "New Rate Field" : "Edit Rate Field"}
			description={
				type === "create"
					? "Create a new rate field"
					: "Edit an existing rate field"
			}
			closeRequest={() => {
				closeRequest();
			}}
			onSubmit={() => {
				delete rateField.__typename;
				if (type === "create") {
					// create rate field
					createRateField({
						variables: {
							input: {
								price: rateField.price,
								projectRole: rateField.projectRole,
								rateSheet: rateSheet.id,
							},
						},
					})
						.then((data) => handleSuccess(data, "Created"))
						.catch(handleError);
				} else {
					updateRateField({
						variables: {
							input: {
								id: rateField.id,
								price: rateField.price,
								projectRole: rateField.projectRole,
							},
						},
					})
						.then((data) => handleSuccess(data, "Updated"))
						.catch(handleError);
				}
			}}
		>
			<Select
				name="Project Role"
				defaultValue={defaultValues.projectRole}
				onChange={(value) => {
					setRateField({
						...rateField,
						projectRole: value.value,
					});
				}}
				//options={[{ name: "helo world" }, { name: "hello world 2" }]}
				//
				options={
					projectRoles
						? projectRoles
								// .sort((a, b) => {
								// 	if (a.name > b.name) return 1;
								// 	if (a.name < b.name) return -1;
								// 	return 0;
								// })
								.map((projectRoles) => {
									return {
										name: projectRoles.name,
										value: projectRoles.id,
									};
								})
						: [{ name: "Loading...", disabled: true }]
				}
			/>
			<Input
				label="Price"
				required
				helper="Enter the standard price for this role"
				icon={faReceipt}
				value={rateField.name}
				onChange={(e) =>
					setRateField({
						...rateField,
						price: e,
					})
				}
			/>
		</SlideOver>
	);
};

RateFieldContainer.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	currentRateField: PropTypes.object,
	rateSheet: PropTypes.object,
	defaultValues: PropTypes.object,
};
