import { PropTypes } from "prop-types";
import React from "react";
import { View } from "react-native";

// Components

import { Alert } from "../../../components/Alert";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import DailySheetView from "../../../components/DailySheetView";
import { PageHeader } from "../../../components/Header";
import LoadingScreen from "../../../components/LoadingScreen";

// Container

import { DailySheetSlideOver } from "../../../containers/DailySheetSlideOver";

// GQL

import { gql, useQuery } from "@apollo/client";
import * as timeEntryGQL from "../../../graphql/timeEntry.js";

//Utils
import { SentryLogger } from "../../../utils";
import { faPlus, faPen, faSignature } from "@fortawesome/pro-duotone-svg-icons";

// import SignaturePad from "react-signature-pad-wrapper";

export default function SingleDailySheets({ route }) {
	const { id } = route.params ? route.params : {};

	let [newItem, setNewItem] = React.useState(null);
	let [timeEntry, setTimeEntry] = React.useState(null);

	const { loading, refetch } = useQuery(gql(timeEntryGQL.getTimeEntry), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setTimeEntry(data.getTimeEntry);
		},
		onError: (error) => {
			Alert("Could not retrieve time entry");
			SentryLogger(JSON.stringify(error));
		},
		fetchPolicy: "cache-and-network",
	});

	//if no signature for daily sheet then show the edit daily sheet button
	// If there is a daily sheet but no signature, let add a button in the page header that says "Sign Daily Sheet" which will bring up the daily sheet slide over and where type = "update" and allow them to sign it
	const getButtons = () => {
		let buttons = [];

		if (!timeEntry.dailyDocument) {
			buttons.push({
				name: "Add Daily Sheet",
				icon: faPlus,
				onPress: () => {
					setNewItem("dailySheet");
				},
			});
		}

		if (timeEntry.dailyDocument && !timeEntry.dailyDocument?.signature) {
			buttons.push({
				name: "Sign Daily Sheet",
				icon: faSignature,
				onPress: () => setNewItem("editDailySheet"),
			});
		}

		if (timeEntry.dailyDocument && !timeEntry.dailyDocument?.locked) {
			buttons.push({
				name: "Update Daily Sheet",
				icon: faPen,
				onPress: () => setNewItem("editDailySheet"),
			});
		}
		return buttons;
	};

	if (loading || !timeEntry) {
		return <LoadingScreen loadItem={"Single Time Entry"} />;
	}

	return (
		<>
			<Breadcrumb className="mt-2">
				<Crumb name={"Time Clock"} location={"Time Clock"} />

				<Crumb
					name="Time Entry"
					location={"Single Time Entry"}
					params={{ id: id }}
				/>
			</Breadcrumb>
			<PageHeader
				title="Time Entry"
				goBack={{
					location: "Time Clock",
				}}
				buttons={getButtons()}
			/>

			<View className="overflow-y-scroll">
				<DailySheetView timeEntry={timeEntry} />
			</View>

			<DailySheetSlideOver
				isOpen={newItem === "dailySheet" || newItem === "editDailySheet"}
				timeDailyItem={timeEntry}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				type={newItem === "dailySheet" ? "create" : "update"}
				currentDailySheet={
					timeEntry.dailyDocument ? timeEntry.dailyDocument : null
				}
			/>
		</>
	);
}

SingleDailySheets.propTypes = {
	timeEntry: PropTypes.object,
	route: PropTypes.object,
};
