export const listDryStandards = /* GraphQL */ `
	query ListDryStandards(
		$filter: TableDryStandardFilterInput
		$limit: Int
		$nextToken: String
	) {
		listDryStandards(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				title
				items {
					id
					material
					penetrative
					relative
					dryOnly
				}
			}
			nextToken
		}
	}
`;

export const getSpace = /* GraphQL */ `
	query GetSpace($id: ID!) {
		getSpace(id: $id) {
			id
			name
			mapped
			date
			map
			timeIn
			timeOut
			sap
			flir
			floorPlan {
				id
				name
				plan
			}
			project {
				id
				title
				client {
					id
					name
				}
				location {
					id
					name
				}
				team {
					id
					isAdmin
					isSuperAdmin
					isReadOnly
					isGeneral
					isActive
					teamMember {
						id
						firstName
						lastName
					}
				}
				floorPlans {
					id
					name
					plan
				}
				dryStandards {
					id
					title
					items {
						id
						material
						penatrative
						relative
						dryOnly
					}
				}
				workspace {
					id
					title
					client {
						id
						name
					}
					team {
						id
						isAdmin
						isSuperAdmin
						isReadOnly
						isGeneral
						isActive
						teamMember {
							id
							firstName
							lastName
						}
					}
				}
			}
			parent {
				id
				name
			}

			spaces {
				spaces {
					id
					name
					mapped
					date
					isFungalGrowth
					noAccess
					isDamage
					isWallDamage
					isCeilingDamage
					isFloorDamage
					date
					createBy {
						id
						firstName
						lastName
						role
						profilePicture
					}
				}
				id
				name
				mapped
				date
				isFungalGrowth
				noAccess
				isDamage
				isWallDamage
				isCeilingDamage
				isFloorDamage
				date
				createBy {
					id
					firstName
					lastName
					role
					profilePicture
				}
			}
			environmentalReadings {
				date
				gpp
				id
				isFahrenheit
				relativeHumidity
				temperature
				user {
					id
					firstName
					lastName
					role
					profilePicture
				}
			}
			dryStandard {
				id
				title
				items {
					id
					material
					penatrative
					relative
					dryOnly
				}
			}
			surfaces {
				id
				name
				points
				type
				moisturePoints {
					id
					date
					locationType
					mc
					height
					inches
					feet
					color
					specialInstructions
					previouslyDemoed
					flir
					sap
					isNoAccess
					isVisual
					isAMG
					material {
						id
						material
					}
					user {
						id
						firstName
						lastName
						profilePicture
						role
					}
				}
			}
		}
	}
`;

export const createSpace = /* GraphQL */ `
	mutation CreateSpace($input: CreateSpaceInput!) {
		createSpace(input: $input) {
			id
			name
			date
			projectSub
			project {
				id
				title
			}
			parent {
				id
				name
			}
			createBy {
				id
				firstName
				lastName
			}
		}
	}
`;

export const updateSpace = /* GraphQL */ `
	mutation UpdateSpace($input: UpdateSpaceInput!) {
		updateSpace(input: $input) {
			id
			name
			mapped
			project {
				id
				title
				client {
					id
					name
				}
				workspace {
					id
					title
					client {
						id
						name
					}
				}
				dryStandards {
					title
					items {
						id
						material
						relative
						penetrative
						dryOnly
					}
				}
			}
			parent {
				id
				name
			}
			dryStandard {
				id
				title
			}
			spaces {
				spaces {
					id
					name
					mapped
					date
					isFungalGrowth
					noAccess
					isDamage
					isWallDamage
					isCeilingDamage
					isFloorDamage
					date
					createBy {
						id
						firstName
						lastName
						role
						profilePicture
					}
				}
				id
				name
				mapped
				date
				isFungalGrowth
				noAccess
				isDamage
				isWallDamage
				isCeilingDamage
				isFloorDamage
				date
				createBy {
					id
					firstName
					lastName
					role
					profilePicture
				}
			}
			environmentalReadings {
				date
				gpp
				id
				isFahrenheit
				relativeHumidity
				temperature
				user {
					id
					firstName
					lastName
					role
					profilePicture
				}
			}
		}
	}
`;

export const createEnvironmentalReading = /* GraphQL */ `
	mutation CreateEnvironmentalReading(
		$input: CreateEnvironmentalReadingInput!
	) {
		createEnvironmentalReading(input: $input) {
			id
		}
	}
`;

export const updateEnvironmentalReading = /* GraphQL */ `
	mutation UpdateEnvironmentalReading(
		$input: UpdateEnvironmentalReadingInput!
	) {
		updateEnvironmentalReading(input: $input) {
			id
		}
	}
`;

export const deleteEnvironmentalReading = /* GraphQL */ `
	mutation DeleteEnvironmentalReading(
		$input: DeleteEnvironmentalReadingInput!
	) {
		deleteEnvironmentalReading(input: $input) {
			id
		}
	}
`;
export const createMoisturePoint = /* GraphQL */ `
	mutation CreateMoisturePoint($input: CreateMoisturePointInput!) {
		createMoisturePoint(input: $input) {
			id
			surface {
				id
				name
				points
				moisturePoints {
					mc
					color
					date
					feet
					inches
					previouslyDemoed
					locationType
					flir
					sap
					isNoAccess
					isVisual
					isAMG
					user {
						id
						firstName
						lastName
						profilePicture
						role
					}
					material {
						id
						material
					}
				}
			}
		}
	}
`;

export const updateMoisturePoint = /* GraphQL */ `
	mutation UpdateMoisturePoint($input: UpdateMoisturePointInput!) {
		updateMoisturePoint(input: $input) {
			id
			surface {
				id
				name
				points
				moisturePoints {
					mc
					color
					date
					feet
					inches
					previouslyDemoed
					locationType
					user {
						id
						firstName
						lastName
						profilePicture
						role
					}
					material {
						id
						material
					}
				}
			}
		}
	}
`;
