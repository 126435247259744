import { useState } from "react";
import { Text, View, Image } from "react-native";
import { PropTypes } from "prop-types";

// Components
import Pagination from "../components/Pagination";
import Button from "../components/Button";
import { List, ListItem, PinnedItem, PinnedList } from "../components/List";
import { AppFooter } from "../components/Footer";

import {
	Select,
	Switch,
	Input,
	Radio,
	FieldSet,
	Checkbox,
	TextArea,
} from "../components/Forms";
import Modal from "../components/Modal";

import { MoisturePointSlideOver } from "../containers/MoisturePointSlideOver";

// Icons
import { faRocket, faUser } from "@fortawesome/pro-duotone-svg-icons";

// GQL
import { useQuery, gql } from "@apollo/client";
import * as projectGQL from "../graphql/admin/projects";
import Gallery, { GalleryItem } from "../components/Gallery";

export function DashboardScreen() {
	return (
		<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
			<Text>Hello Dashboard</Text>
		</View>
	);
}

export function Clients() {
	return (
		<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
			<Text>Hello Clients</Text>
		</View>
	);
}

export function ProjectsScreen({ navigation }) {
	const [projects, setProjects] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 20;
	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let stop =
		currentPage * itemsPerPage > projects.length
			? projects.length
			: currentPage * itemsPerPage;

	useQuery(gql(projectGQL.listProjects), {
		onCompleted: (data) => {
			setProjects([...data.listProjects.items]);
		},
	});

	return (
		<View class="bg-white dark:bg-gray-900">
			<PinnedList>
				{projects.map((project, index) => {
					if (index > 5) return;
					return (
						<PinnedItem
							key={project.id}
							onPress={() => {
								navigation.navigate("Project", {
									id: project.id,
								});
							}}
							item={{
								title: project.title,
								client: project.client ? project.client.name : "No Client",
							}}
						/>
					);
				})}
			</PinnedList>
			{/* 
			<CardContainer>
				<Card title="Projects" surTitle={"On top of the world"}>
					<Text>Hello world</Text>
				</Card>
				<Card title="Projects" surTitle={"On top of the world"}>
					<Text>Hello world</Text>
				</Card>
			</CardContainer> */}

			<Gallery>
				<GalleryItem
					name="photo.js"
					size="20kb"
					source={
						"https://health.clevelandclinic.org/wp-content/uploads/sites/3/2022/02/TooMuchSodiuml-1051727580-770x533-1-745x490.jpg"
					}
				/>
			</Gallery>

			<List
				title="Projects Table"
				headers={[
					"Project Name",
					"Clients",
					"Stage",
					"Stage",
					"Stage",
					"Stage",
				]}
			>
				{projects.map((project) => {
					return (
						<ListItem
							key={project.id}
							items={[
								{
									onPress: () => {
										navigation.navigate("Project", {
											id: project.id,
										});
									},
									content: project.title,
								},
								{
									onPress: () => {
										navigation.navigate("Project", {
											id: project.id,
										});
									},
									content: project.client ? project.client.name : "No Client",
								},
								{
									onPress: () => {
										navigation.navigate("Project", {
											id: project.id,
										});
									},
									content: project.stage ? project.stage : "No Stage",
								},
								{
									onPress: () => {
										navigation.navigate("Project", {
											id: project.id,
										});
									},
									content: project.stage ? project.stage : "No Stage",
								},
								{
									onPress: () => {
										navigation.navigate("Project", {
											id: project.id,
										});
									},
									content: project.stage ? project.stage : "No Stage",
								},
								{
									onPress: () => {
										navigation.navigate("Project", {
											id: project.id,
										});
									},
									content: project.stage ? project.stage : "No Stage",
								},
							]}
						/>
					);
				})}
			</List>
			<Pagination
				start={start}
				stop={stop}
				total={projects.length}
				nextClicked={() => setCurrentPage(currentPage + 1)}
				previousClicked={() => setCurrentPage(currentPage - 1)}
			/>

			<AppFooter />
		</View>
	);
}

ProjectsScreen.propTypes = {
	navigation: PropTypes.object,
};
