import { faInfoCircle, faTimes } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal, Pressable, ScrollView, Text, View } from "react-native";

import OptionMenu from "./OptionsMenu";

export const Table = ({ title, headers, data, usesOptions }) => {
	const [selectedRow, setSelectedRow] = useState(null);

	const renderTableHeaders = () => {
		return (
			<View className="">
				<View className=" md:hidden flex-row justify-between  ">
					<Text className="font-bold flex-1 p-2 text-gray-700 px-2">
						{headers?.[0] || ""}
					</Text>
				</View>

				<View className="hidden md:flex flex-row justify-between  lg:hidden">
					{headers.map((header, headerIndex) => {
						if (headerIndex > 1) return null;
						return (
							<Text
								key={headerIndex}
								className={`font-bold flex-1 ${
									headerIndex === 0 && "pl-2"
								} pt-2 text-gray-700`}
							>
								{header}
							</Text>
						);
					})}
				</View>

				<View className="hidden lg:flex flex-row  pl-2 ">
					{headers.map((header, headerIndex) => (
						<Text
							key={headerIndex}
							className="font-bold flex-1 pb-2 text-gray-700"
						>
							{header}
						</Text>
					))}
					{usesOptions && (
						<Text className="font-bold flex-1 pb-2 text-gray-700"></Text>
					)}
				</View>
			</View>
		);
	};

	const renderCell = (content) => {
		if (typeof content === "string") {
			return (
				<Text className="text-gray-700 dark:text-gray-100">{content}</Text>
			);
		}
		return <View className="w-full">{content}</View>;
	};

	const renderTableRows = () => {
		return data.map((row, rowIndex) => {
			return (
				<Pressable
					key={rowIndex}
					onPress={() => {
						row.onPress && row.onPress();
					}}
					className="flex-row justify-between  border-t border-gray-200 dark:border-gray-800 mr-2 "
				>
					<View className="md:hidden lg:hidden w-full px-2">
						<View className="flex-row justify-between  w-full ">
							<View className="flex-1 font-bold p-2 text-gray-700 ">
								{renderCell(row?.cells?.[0]?.content || "")}
							</View>
							<Pressable onPress={() => setSelectedRow(row)}>
								<FontAwesomeIcon icon={faInfoCircle} size={22} color="gray" />
							</Pressable>
						</View>
					</View>

					<View className="hidden md:flex w-full px-2 lg:hidden">
						<View className="flex-row justify-between  w-full ">
							{row.cells.map((cell, cellIndex) => {
								if (cellIndex > 1) return null;
								return (
									<View
										key={cellIndex}
										className="font-bold flex-1 p-2 text-gray-700"
									>
										{renderCell(cell.content)}
									</View>
								);
							})}
							<Pressable onPress={() => setSelectedRow(row)}>
								<FontAwesomeIcon icon={faInfoCircle} size={22} color="gray" />
							</Pressable>
						</View>
					</View>

					<View className="hidden lg:flex flex-row w-full ">
						{row.cells.map((cell, cellIndex) => {
							return (
								<View
									key={cellIndex}
									className="font-bold flex-1 p-2 text-gray-700"
								>
									{renderCell(cell.content)}
								</View>
							);
						})}
						{row.options && (
							<View className="font-bold flex-1 p-2 text-gray-700 h-14 flex flex-row justify-end">
								<OptionMenu options={row.options} />
							</View>
						)}
					</View>
				</Pressable>
			);
		});
	};

	return (
		<View className="w-full">
			<ScrollView className="" style={{ width: "100%" }}>
				{title && (
					<View className="flex-row justify-between items-center w-full ">
						<Text className="text-xl font-bold p-2 text-gray-700">{title}</Text>
					</View>
				)}
				<View>{renderTableHeaders()}</View>
				<ScrollView style={{ width: "100%" }} nestedScrollEnabled>
					{renderTableRows()}
				</ScrollView>
			</ScrollView>

			<Modal
				visible={!!selectedRow}
				onBackdropPress={() => setSelectedRow(null)}
				transparent={true}
				animationIn="fadeIn"
				animationOut="fadeOut"
				animationInTiming={500}
				animationOutTiming={500}
				backdropTransitionInTiming={500}
			>
				<View className="h-full w-full bg-gray-900 opacity-75"></View>
				<View className="absolute bottom-0 right-0 flex flex-col items-center  justify-center w-full min-h-52">
					<View className="bg-white dark:bg-gray-800 rounded-t-lg p-4 w-full h-full">
						<View className="bg-white p-4">
							<View className="mb-2 flex-row justify-between border-gray-500 bottom-b-2">
								<Text className="text-xl font-bold">
									{selectedRow?.title ? selectedRow.title : "Information"}
								</Text>
								<Pressable onPress={() => setSelectedRow(null)}>
									<FontAwesomeIcon icon={faTimes} size={20} color="black" />
								</Pressable>
							</View>

							{selectedRow &&
								selectedRow.cells.map((cell, cellIndex) => (
									<View key={cellIndex} className="flex-row items-baseline">
										<Text className="text-gray-700 mb-2  pr-2">
											{headers[cellIndex]}:
										</Text>
										<View>{renderCell(cell.content)}</View>
									</View>
								))}

							{selectedRow && selectedRow.options && (
								<View className="">
									<Text className="text-lg font-medium ">Options</Text>
									<ScrollView
										className=" pb-4 pt-2 pr-2"
										horizontal
										showsHorizontalScrollIndicator={false}
									>
										{selectedRow.options.map((option, optionIndex) => (
											<Pressable
												key={optionIndex}
												onPress={() => {
													option.onPress();
													setSelectedRow(null);
												}}
												className="mr-2 p-3 flex flex-row items-center justify-center bg-gray-200 rounded-md shadow-sm"
											>
												{option.icon && (
													<View className="mr-1">
														<FontAwesomeIcon
															icon={option.icon}
															size={18}
															color="blue"
														/>
													</View>
												)}
												<Text className="text-blue-500 font-medium">
													{option.name}
												</Text>
											</Pressable>
										))}
									</ScrollView>
								</View>
							)}
						</View>
					</View>
				</View>
			</Modal>
		</View>
	);
};

Table.propTypes = {
	title: PropTypes.string,
	headers: PropTypes.arrayOf(PropTypes.string).isRequired,
	data: PropTypes.any,
	usesOptions: PropTypes.bool,
};
