/* This example requires Tailwind CSS v2.0+ */

import React from "react";
import PropTypes from "prop-types";
import { useColorScheme } from "nativewind";
import { View, Text, TouchableOpacity } from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/pro-duotone-svg-icons";

export default function Pagination({
	start,
	stop,
	total,
	nextClicked,
	previousClicked,
}) {
	const colorScheme = useColorScheme();
	return (
		<View
			className="flex-row bg-white dark:bg-gray-800 px-4 py-4 md:py-3 flex items-center justify-between border-t border-gray-200 pb-6"
			aria-label="Pagination"
		>
			<View className=" sm:block ">
				<Text
					testID="pagination-text"
					className="text-sm text-gray-700  dark:text-gray-200"
				>
					Showing{" "}
					<Text testID="start-text" className="font-medium">
						{start}
					</Text>{" "}
					to {""}
					<Text testID="stop-text" className="font-medium">
						{stop}
					</Text>{" "}
					of <Text className="font-medium">{total}</Text> results
				</Text>
			</View>
			<View className=" flex flex-row justify-end" testID="test-previous">
				{start !== 1 && (
					<TouchableOpacity
						testID="test-previous"
						onPress={() => {
							previousClicked();
						}}
						className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-gray-800 dark:text-white hover:bg-gray-50"
					>
						<Text className="hidden md:flex dark:text-white ">Previous</Text>
						<View className="flex sm:hidden">
							<FontAwesomeIcon
								icon={faChevronLeft}
								color={colorScheme === "light" ? "black" : "white"}
							/>
						</View>
					</TouchableOpacity>
				)}
				{stop !== total && (
					<TouchableOpacity
						testID="next-button"
						onPress={() => {
							nextClicked();
						}}
						className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white dark:bg-gray-800 dark:text-white hover:bg-gray-50  "
					>
						<Text className="hidden md:flex dark:text-white ">Next</Text>
						<View className="flex sm:hidden">
							<FontAwesomeIcon
								icon={faChevronRight}
								color={colorScheme === "light" ? "black" : "white"}
							/>
						</View>
					</TouchableOpacity>
				)}
			</View>
		</View>
	);
}

Pagination.propTypes = {
	start: PropTypes.number.isRequired,
	stop: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	nextClicked: PropTypes.func.isRequired,
	previousClicked: PropTypes.func.isRequired,
};
