// simple react screen

import { useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";

// components
// import { addNotification } from "../../components/Notifications";

import { Alert } from "../../components/Alert";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List";
import LoadingScreen from "../../components/LoadingScreen";
import NoItems from "../../components/NoItems";
import OptionsMenu from "../../components/OptionsMenu";
import Pagination from "../../components/Pagination";
import Tabs, { Tab } from "../../components/Tabs";
// gql

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import * as userGQL from "../../graphql/clerk/users";
import * as timeEntryGQL from "../../graphql/timeEntry";

// utils
import { calculateTimeDuration, SentryLogger } from "../../utils";

// icons
import {
	faClock,
	faEye,
	faPen,
	faPlus,
	faRocket,
} from "@fortawesome/pro-duotone-svg-icons";
import { TimeEntrySlideover } from "../../containers/TimeEntrySlideOver";

export const Users = () => {
	// const [showBulkProjects, setShowBulkProjects] = React.useState(false);
	// const [selectedUser, setSelectedUser] = React.useState(null);

	const navigation = useNavigation();
	const [users, setUsers] = useState([]);
	const itemsPerPage = 20;
	const [search, setSearch] = React.useState("");

	let [currentPage, setCurrentPage] = useState(1);

	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end = users
		? currentPage * itemsPerPage >
		  users.filter((user) => {
				return (
					user.firstName.toLowerCase().includes(search.toLowerCase()) ||
					user.lastName.toLowerCase().includes(search.toLowerCase())
				);
		  }).length
			? users.filter((user) => {
					return (
						user.firstName.toLowerCase().includes(search.toLowerCase()) ||
						user.lastName.toLowerCase().includes(search.toLowerCase())
					);
			  }).length
			: currentPage * itemsPerPage
		: 0;

	const { loading } = useQuery(gql(userGQL.listUsers), {
		onCompleted: (data) => {
			setUsers([...data.listUsers.items]);
		},
	});

	if (loading) {
		return <LoadingScreen loadItem="List Users" />;
	}

	return (
		<>
			<Breadcrumb baseLocation="Work In Progress">
				<Crumb name={"Users"} location="Users" />
			</Breadcrumb>
			<PageHeader openMenu title={"Users"} />
			<List
				headers={["Name"]}
				usesOptions
				onSearch={(value) => {
					setSearch(value);
				}}
			>
				{users
					.filter((user) => user.isActive !== false)
					.filter((user) => {
						return (
							user.firstName.toLowerCase().includes(search.toLowerCase()) ||
							user.lastName.toLowerCase().includes(search.toLowerCase())
						);
					})
					.sort((a, b) => {
						if (a.firstName < b.firstName) {
							return -1;
						}
						if (a.firstName > b.firstName) {
							return 1;
						}
						return 0;
					})
					.map((user, index) => {
						if (index + 1 >= start && index < end) {
							return (
								<ListItem
									key={index}
									smallView={
										<TouchableOpacity
											onPress={() => {
												navigation.navigate("User", {
													id: user.id,
													name: `${user.firstName} ${user.lastName}`,
												});
											}}
											className="w-full rounded-lg shadow-sm border-2 border-gray-200 dark:border-indigo-200 p-2"
										>
											<Text className=" font-bold text-md dark:text-white ">
												{user.firstName} {user.lastName}
											</Text>
											<Text className="font-medium dark:text-white">
												Role: {""}{" "}
												<Text className="text-indigo-500">
													{user.role || "No Role Assigned"}
												</Text>
											</Text>
										</TouchableOpacity>
									}
									items={[
										{
											onPress: () => {
												navigation.navigate("User", {
													id: user.id,
													name: `${user.firstName} ${user.lastName}`,
												});
											},
											content: user.firstName + " " + user.lastName,
										},
									]}
									options={
										<OptionsMenu
											options={[
												{
													icon: faPlus,
													name: "Bulk Add Projects",
													onPress: () => {
														// setSelectedUser(user);
														// setShowBulkProjects(true);
													},
												},
											]}
										/>
									}
								/>
							);
						}
					})}
			</List>

			<Pagination
				start={start}
				stop={end}
				total={
					users.filter((user) => {
						return (
							user.firstName.toLowerCase().includes(search.toLowerCase()) ||
							user.lastName.toLowerCase().includes(search.toLowerCase())
						);
					}).length
				}
				nextClicked={() => setCurrentPage(currentPage + 1)}
				previousClicked={() => setCurrentPage(currentPage - 1)}
			/>
		</>
	);
};

Users.propTypes = {
	search: PropTypes.string,
};

const tabs = [
	{
		name: "Projects",
		icon: faRocket,
	},
	{
		name: "Time Entries",
		icon: faClock,
	},
];

export function User({ route }) {
	// const [newItem, setNewItem] = useState(null);
	// const [showBulkProjects, setShowBulkProjects] = React.useState(false);

	const { id, name } = route.params || { id: null, name: null };
	const navigation = useNavigation();
	const [user, setUser] = React.useState(null);
	const [currentTab, setCurrentTab] = React.useState(tabs[0].name);
	const [timeEntries, setTimeEntries] = React.useState([]);
	const [timeUser, setTimeUser] = React.useState({});
	const [currentTimeEntry, setCurrentTimeEntry] = React.useState(null);
	const [openItem, setOpenItem] = React.useState(null);

	const itemsPerPage = 40;
	let [currentPage, setCurrentPage] = React.useState(1);
	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end = timeEntries
		? currentPage * itemsPerPage >
		  timeEntries.sort((a, b) => b.date - a.date).length
		: currentPage * itemsPerPage;

	const { refetch } = useQuery(gql(timeEntryGQL.getUserProjectsAndTime), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setTimeUser(data.getUser);
			setTimeEntries([...data.getUser.timeEntries]);
		},
		onError: () => {
			Alert("Could not retrieve user");
		},
		fetchPolicy: "network-only",
	});

	React.useEffect(() => {
		if (id) {
			getUser({
				variables: {
					recallValue: DateTime.now().toISO(),
					id: id,
				},
			});
		}
	}, [id]);

	const [getUser] = useLazyQuery(gql(userGQL.getUserProjects), {
		variables: {
			id: id,
		},
		onCompleted: (data) => {
			setUser(data.getUser);
		},
		onError: () => {
			SentryLogger.log("Could not retrieve user");
			Toast.show({
				type: "error",
				text1: "User Does Not Exist",
				text2:
					"We cannot find this user in our system. Make sure you have the right url.",
			});
			navigation.navigate("Users");
		},
	});

	React.useEffect(() => {
		if (id) {
			getUser({
				variables: {
					recallValue: DateTime.now().toISO(),
					id: id,
				},
			});
		}
	}, [id]);

	if (!user) {
		return <LoadingScreen loadItem={name || "User"} />;
	}

	return (
		<>
			<Breadcrumb baseLocation="Work In Progress">
				<Crumb name={"Users"} location="Users" />
				<>
					<Crumb name={`${user.firstName} ${user.lastName}`} location="User" />
				</>
			</Breadcrumb>
			<PageHeader
				goBack={{
					location: "Users",
				}}
				title={`${user.firstName} ${user.lastName}`}
				buttons={[
					{
						name: "Bulk Add Projects",
						icon: faPlus,
						onPress: () => {
							//  setShowBulkProjects(true)
						},
					},
				]}
			/>

			<Tabs>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={index}
							name={tab.name}
							icon={tab.icon}
							onPress={() => setCurrentTab(tab.name)}
						/>
					);
				})}
			</Tabs>

			{currentTab === "Projects" && (
				<List title="Projects" headers={["Tile"]} usesOptions>
					{[...user.projects]
						.filter((project) => project.project)
						.sort((a, b) => {
							if (a.project.title < b.project.title) {
								return -1;
							}
							if (a.project.title > b.project.title) {
								return 1;
							}
							return 0;
						})
						.map((projectLinking, index) => {
							let project = projectLinking.project;
							return (
								<ListItem
									key={index}
									items={[
										{
											href: `/clerk/projects/${project.id}`,
											content: project.title,
										},
									]}
									options={
										<OptionsMenu
											options={[
												{
													icon: faEye,
													name: "View Project",
													onPress: () => {
														navigation.navigate("Project", {
															id: project.id,
														});
													},
												},
											]}
										/>
									}
								/>
							);
						})}
				</List>
			)}

			{currentTab === "Time Entries" && (
				<ScrollView className="w-full">
					{timeEntries.length > 0 ? (
						<View>
							<List
								title={"Time Entries"}
								headers={[
									"Time In",
									"Time Out",
									"Duration",
									"Project",
									"Updated By",
									"Description",
									"Approved",
								]}
								usesOptions
							>
								{timeEntries
									.sort((a, b) => {
										if (a.timeIn < b.timeIn) return 1;
										if (a.timeIn > b.timeIn) return -1;
										return 0;
									})
									.map((entry, index) => (
										<ListItem
											key={index}
											smallView={
												<View className="w-full rounded-lg shadow-sm border-2 border-gray-200 dark:border-indigo-200 p-2">
													<View className="flex flex-row flex-wrap justify-between">
														<View>
															<Text className="text-sm font-light">
																Time In
															</Text>
															<Text className="text-sm font-semibold dark:text-white">
																{DateTime.fromISO(entry.timeIn).toLocaleString(
																	DateTime.DATETIME_FULL
																)}
															</Text>
														</View>
														<View>
															<Text className="text-sm font-light">
																Time Out
															</Text>
															<Text className="text-sm font-semibold dark:text-white">
																{entry.timeOut
																	? DateTime.fromISO(
																			entry.timeOut
																	  ).toLocaleString(DateTime.DATETIME_FULL)
																	: "N/A"}
															</Text>
														</View>
													</View>
													<View className="flex flex-row flex-wrap justify-between">
														<View>
															<Text className="text-sm font-light">
																Total Time
															</Text>
															<Text className="text-sm font-semibold dark:text-white">
																{entry.timeOut
																	? calculateTimeDuration(
																			entry.timeIn,
																			entry.timeOut
																	  )
																	: "Clocked In"}
															</Text>
														</View>
														<View>
															<Text className="text-sm font-light text-right">
																Project
															</Text>
															<Text className="text-sm font-semibold dark:text-white">
																{entry.project
																	? entry.project.title
																	: "No Project"}
															</Text>
														</View>
													</View>
												</View>
											}
											items={[
												{
													content: DateTime.fromISO(
														entry.timeIn
													).toLocaleString(DateTime.DATETIME_FULL),
												},
												{
													content: entry.timeOut
														? DateTime.fromISO(entry.timeOut).toLocaleString(
																DateTime.DATETIME_FULL
														  )
														: "N/A",
												},
												{
													content: entry.timeOut
														? DateTime.fromISO(entry.timeOut)
																.diff(DateTime.fromISO(entry.timeIn))
																.toFormat("hh:mm:ss") + " hours"
														: "N/A",
												},
												{
													content: entry.project
														? entry.project.title
														: "No Project",
												},
												{
													content: entry.updatedBy
														? entry.updatedBy.firstName +
														  " " +
														  entry.updatedBy.lastName
														: "",
												},
												{
													content: entry.description,
												},
												{
													content: entry.approved ? "Yes" : "No",
												},
											]}
											options={
												<OptionsMenu
													options={[
														{
															name: "Edit",
															icon: faPen,
															onPress: () => {
																setCurrentTimeEntry(entry);
																setOpenItem("timeEntry");
															},
														},
													]}
												/>
											}
										/>
									))}
							</List>

							<Pagination
								start={start}
								stop={end}
								total={timeUser.timeEntries.length}
								nextClicked={() => setCurrentPage(currentPage + 1)}
								previousClicked={() => setCurrentPage(currentPage - 1)}
							/>
						</View>
					) : (
						<NoItems title="No Time Entries" />
					)}
				</ScrollView>
			)}

			<TimeEntrySlideover
				isOpen={openItem === "timeEntry"}
				closeRequest={() => {
					setOpenItem("");
					setCurrentTimeEntry(null);
				}}
				currentTimeEntry={currentTimeEntry ? currentTimeEntry : null}
				onComplete={() => {
					setOpenItem("");
					setCurrentTimeEntry(null);
					refetch();
				}}
				projects={
					user.projects
						? user.projects
								.concat([{ project: { id: "0", title: "SRP Environmental" } }])
								.filter((project) => project.project !== null)
								.map((project) => project.project)
						: []
				}
				type="update"
				clerking
			/>
		</>
	);
}

User.propTypes = {
	route: PropTypes.object,
};
