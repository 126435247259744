import React, { useState } from "react";

// Components

import { List, ListItem } from "../../components/List";
import { PageHeader } from "../../components/Header";
import OptionMenu from "../../components/OptionsMenu";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";

// Containers
import { LocationSlideOver } from "../../containers/LocationSlideOver";

// GQL

import { gql, useQuery } from "@apollo/client";
import * as locationGQL from "../../graphql/admin/location";

// Icons
import { faPlus, faPen } from "@fortawesome/pro-duotone-svg-icons";

export function Locations() {
	let [newItem, setNewItem] = useState(null);
	let [offices, setOffices] = useState(null);
	let [updateOffice, setUpdateOffice] = useState({});

	const { refetch } = useQuery(gql(locationGQL.listLocations), {
		variables: {
			limit: 1000,
			filter: {
				isBranchLocation: true,
			},
		},
		onCompleted: (data) => {
			setOffices([...data.listLocations.items]);
		},
	});

	return (
		<>
			<Breadcrumb baseLocation="Work In Progress">
				<Crumb name="Locations" location="Locations" />
			</Breadcrumb>
			<PageHeader
				openMenu
				title="Locations"
				buttons={[
					{
						name: "New Location",
						icon: faPlus,
						onPress: () => {
							setNewItem("location");
						},
					},
				]}
			/>

			<List
				title={"Office Locations"}
				headers={["Name", "Sage Intacct"]}
				usesOptions
			>
				{offices &&
					offices

						.sort((a, b) => {
							if (a.name > b.name) return 1;
							if (a.name < b.name) return -1;
							return 0;
						})

						.map((location) => {
							return (
								<ListItem
									options={
										<OptionMenu
											options={[
												{
													name: "Edit",
													icon: faPen,
													onPress: () => {
														setNewItem("updateLocation");
														setUpdateOffice(location);
													},
												},
											]}
										/>
									}
									key={location.id}
									items={[
										{
											content: location.name,
										},
										{
											content: location.sageIntacct,
										},
									]}
								/>
							);
						})}
			</List>
			<LocationSlideOver
				type="create"
				isOpen={newItem === "location"}
				onComplete={() => {
					setNewItem(null);
				}}
				onCloseRequest={() => {
					refetch();
					setNewItem(null);
				}}
				currentLocation={{ isBranchLocation: true }}
			/>
			<LocationSlideOver
				type="update"
				isOpen={newItem === "updateLocation"}
				onComplete={() => {
					refetch();
					setNewItem(null);
				}}
				onCloseRequest={() => {
					setNewItem(null);
				}}
				currentLocation={updateOffice}
			/>
		</>
	);
}
