import PropTypes from "prop-types";
import React, { useState } from "react";

// Components
import { Alert } from "../components/Alert";
import { Input } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation } from "@apollo/client";
import * as billingPhaseGQL from "../graphql/clerk/billingPhase";
import { SentryLogger } from "../utils";

const BillingPhaseSlideOver = ({
	isOpen,
	closeRequest = () => {},
	type,
	onComplete,
	currentBillingPhase,
}) => {
	const [billingPhase, setBillingPhase] = useState({});

	// sets the current expense type if there is one

	React.useEffect(() => {
		if (currentBillingPhase) {
			setBillingPhase(currentBillingPhase);
		}
	}, [currentBillingPhase]);

	const [createBillingPhase] = useMutation(
		gql(billingPhaseGQL.createBillingPhase),
		{
			onCompleted: (data) => {
				onComplete(data.createBillingPhase);
			},
			onError: (error) => {
				SentryLogger(JSON.parse(error, null, 2));
				Alert(
					"Something Went Wrong",
					"There was an error creating the billing phase"
				);
			},
		}
	);
	const [updateBillingPhase] = useMutation(
		gql(billingPhaseGQL.updateBillingPhase),
		{
			onCompleted: (data) => {
				onComplete(data.updateBillingPhase);
			},
			onError: () => {
				Alert(
					"Something Went Wrong",
					"There was an error updating the billing phase"
				);
			},
		}
	);

	return (
		<SlideOver
			isOpen={isOpen}
			name="New Billing Phase "
			description={"Create a new billing phase "}
			closeRequest={() => {
				closeRequest();
			}}
			onSubmit={() => {
				if (type === "create") {
					createBillingPhase({
						variables: {
							input: billingPhase,
						},
					});
				} else {
					updateBillingPhase({
						variables: {
							input: billingPhase,
						},
					});
				}
			}}
		>
			<Input
				label="Name"
				required
				helper="Enter Billing Phase Name"
				value={billingPhase.name}
				onChange={(e) =>
					setBillingPhase({
						...billingPhase,
						name: e,
					})
				}
			/>
		</SlideOver>
	);
};

BillingPhaseSlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	currentBillingPhase: PropTypes.object,
};

export { BillingPhaseSlideOver };
