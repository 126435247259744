import { Link, useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Image, ScrollView, Text, TouchableOpacity, View } from "react-native";

import AWS from "aws-sdk";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";

// Components

import { Alert } from "../../../components/Alert";
import { Badge } from "../../../components/Badge";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import CardContainer, { Card } from "../../../components/Card";
import { FileUpload } from "../../../components/Forms";
import { PageHeader } from "../../../components/Header";
import { List, ListItem } from "../../../components/List";
import LoadingScreen from "../../../components/LoadingScreen.js";
import NoItems from "../../../components/NoItems";
import { addNotification } from "../../../components/Notification";
import OptionsMenu from "../../../components/OptionsMenu";
import SlideOver from "../../../components/Slideover";
import Tabs, { Tab } from "../../../components/Tabs";
import User from "../../../components/User";
import DataContainer, { DataItem } from "../../../components/DataDisplay";
import Pagination from "../../../components/Pagination";

// Utils

import {
	awsConfig,
	getData,
	projectStages,
	SentryLogger,
} from "../../../utils";

// Containers

import { NoteSlideOver } from "../../../containers/NoteSlideOver";
import { ProjectAuditSlideOver } from "../../../containers/ProjectAuditSlideOver";
import { ProjectSlideOver } from "../../../containers/ProjectSlideOver";
import { ProjectTeamMember } from "../../../containers/ProjectTeamMember";
import { SampleCollectionSlideOver } from "../../../containers/SampleCollectionSlideOver";
import { SpaceSlideOver } from "../../../containers/SpaceSlideOver";

// GQL

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as projectGQL from "../../../graphql/project";
import {
	deleteTeamMember,
	listProjectRoles,
	listUsers,
} from "../../../graphql/project";
// import { createProjectTeamMember } from "../../../graphql/projectsScreen";

import {
	faBooks,
	faBriefcase,
	faClipboardList,
	//faChartBar,
	faFileSignature,
	faFolderTree,
	faHashtag,
	faInfoCircle,
	faLayerGroup,
	faList,
	faMapPin,
	faPen,
	faPlus,
	faProjectDiagram,
	//faUpload,
	faSpinner,
	faStickyNote,
	faTasks,
	faTrash,
	faUsers,
	faVial,
} from "@fortawesome/pro-duotone-svg-icons";

AWS.config.update(awsConfig);

let s3 = new AWS.S3({ params: { Bucket: "srp-in-field" } });

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

AWS.config.update(awsConfig);

export const Project = ({ route, search }) => {
	const { id } = route.params;
	const navigation = useNavigation();
	const [user, setUser] = useState(null);
	let [tabs, setTabs] = React.useState([
		{ name: "Loading", href: "#", icon: faSpinner },
	]);
	let [projectUser, setProjectUser] = React.useState(null);
	let [project, setProject] = React.useState({});
	let [officialReports, setOfficialReports] = React.useState([]);
	// let [bulkUsers, setBulkUsers] = React.useState([]);
	// let [showBulkUsers, setShowBulkUsers] = React.useState(false);
	let [updateTM, setUpdateTM] = React.useState({});
	let [currentTab, setCurrentTab] = useState("Spaces");
	let [newItem, setNewItem] = useState(null);
	let [newNote, setNewNote] = React.useState({});
	let [uploading, setUploading] = React.useState(false);
	let [key, setKey] = React.useState(null);
	let [files, setFiles] = React.useState([]);

	//let [projectRoles, setProjectRoles] = React.useState([]);
	// const [counter, setCounter] = useState(0);

	const [getProject, { loading, refetch }] = useLazyQuery(
		gql(projectGQL.getProject),
		{
			variables: {
				id: id,
			},
			onCompleted: (data) => {
				const getCurrentUser = async (project) => {
					let team = project.workspace
						? project.team.concat(project.workspace.team)
						: project.team;

					let projectUser = team.filter(
						(tm) => tm.teamMember && tm.teamMember.id === user.id
					);
					if (projectUser.length) {
						setProjectUser(projectUser[0]);
					} else {
						addNotification({
							title: "Unauthorized Access",
							text: "Sorry you are not authorized to access this project. You must be added to the project team before you can access it",
							type: "error",
							time: 10000,
							supportText: true,
						});
						// history.push("/app");
						navigation.navigate("Projects", { id: id });
					}
				};

				setProject(data.getProject);
				getCurrentUser(data.getProject);
				data.getProject.reports.map((report) => {
					report.documents.map((document) => {
						if (document.isOfficial) {
							setOfficialReports((officialReports) => [
								...officialReports,
								{ document, report },
							]);
						}
					});
				});

				setNewNote({
					...newNote,
					user: user.id,
					project: data.getProject.id,
				});

				if (data.getProject.isWorkspace === true) {
					setTabs([
						{ name: "Projects", href: "#", icon: faProjectDiagram },
						{ name: "Team", href: "#", icon: faUsers },
						{ name: "Information", href: "#", icon: faInfoCircle },
					]);
					if (!currentTab) {
						setCurrentTab("Projects");
					}
				} else {
					setTabs([
						{ name: "Spaces", href: "#", icon: faLayerGroup },
						{ name: "Samples", href: "#", icon: faVial },
						{ name: "Audits", href: "#", icon: faClipboardList },
						{ name: "Notes", href: "#", icon: faStickyNote },
						{ name: "Reports", href: "#", icon: faBooks },
						{ name: "Team", href: "#", icon: faUsers },
						{ name: "Information", href: "#", icon: faInfoCircle },
					]);

					if (!currentTab) {
						setCurrentTab("Spaces");
					}
				}
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
				Alert(
					"Project Does Not Exist",
					"We cannot find this project in our system. Make sure you have the right url."
				);
				navigation.navigate("Projects");
			},
			fetchPolicy: "cache-and-network",
		}
	);

	React.useEffect(() => {
		getData("@storage_Key")
			.then((user) => {
				setUser(user);
			})
			.catch((err) => {
				SentryLogger(err);
			});
	}, []);

	useQuery(gql(projectGQL.listLabs), {
		onCompleted: () => {},
	});

	React.useEffect(() => {
		if (id) {
			getProject({
				variables: {
					recallValue: DateTime.now().toISO(),
					id: id,
				},
			});
		}
	}, [id]);

	// // Save the counter value to AsyncStorage when the component unmounts
	// useEffect(() => {
	// 	AsyncStorage.setItem("counter", JSON.stringify(counter));
	// 	return () => {
	// 		// Cleanup function to remove the event listener
	// 	};
	// }, [counter]);

	// // Load the counter value from AsyncStorage when the component mounts
	// useEffect(() => {
	// 	AsyncStorage.getItem("counter").then((value) => {
	// 		if (value !== null) {
	// 			setCounter(JSON.parse(value));
	// 		}
	// 	});
	// }, []);

	// const incrementCounter = () => {
	// 	setCounter(counter + 1);
	// };

	useQuery(gql(listUsers), {
		variables: {
			limit: 5000,
		},
		onCompleted: () => {
			// setUsers([...data.listUsers.items]);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
	});

	useQuery(gql(listProjectRoles), {
		onCompleted: () => {
			// setProjectRoles([...data.listProjectRoles.items]);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
	});

	const [deleteProjectTeamMember] = useMutation(gql(deleteTeamMember), {
		onCompleted: () => {
			refetch({
				variables: {
					id: project.id,
				},
			});
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("Something Went Wrong", "Team member was not removed");
		},
	});

	/**
	 * downloadReport
	 * makes a callout to S3 to download the report for the user
	 * @param {string} link
	 */

	const downloadReport = async (link, name) => {
		let s3 = new AWS.S3({ params: { Bucket: "srp-in-field" } });
		let params = { Bucket: "srp-in-field", Key: link };
		s3.getObject(params, (err, data) => {
			if (err) {
				addNotification({
					title: "Uh Oh Something Went Wrong",
					text: "We could not retrieve this report from our system",
					type: "error",
					time: 5000,
					supportText: true,
				});
				return;
			}

			let blob = new Blob([data.Body], { type: data.ContentType });
			let link = document.createElement("a");
			link.href = window.URL.createObjectURL(blob);
			link.download = name; //This is the name the file will be given when it is downloaded

			link.click();
			addNotification({
				title: "Report Downloading",
				text: "Please note if this is a report generated by the system you might get a message from Microsoft Word® about linking. Please click the affirmative option to continue.",
				type: "success",
				time: 10000,
			});
		});
	};

	const bulkUploadSpace = async () => {
		setUploading(true);
		if (key) {
			setNewItem(null);
		} else {
			alert("Please wait for file to finish uploading");
		}

		addNotification({
			title: "Our bots are on it",
			text: "You will get an email once they have finished uploading the spaces. You can navigate to another screen",
			type: "success",
			time: 5000,
		});
		setUploading(false);
		setKey("");
		setFiles([]);
		setUploading(false);

		fetch(
			"https://fk57a28ct3.execute-api.us-west-2.amazonaws.com/v2/data/bulk-space-upload",
			{
				method: "POST",
				mode: "cors", // no-cors, *cors, same-origin
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					project: { id: project.id, title: project.title },
					key: key,
					email: user.email,
				}),
			}
		);
	};

	const getButtonText = () => {
		switch (currentTab) {
			case "Projects":
				if (
					(projectUser.isSuperAdmin === true || projectUser.isAdmin === true) &&
					project.stage !== "Complete"
				) {
					return "Add New Project";
				}
				break;
			case "Spaces":
				if (projectUser.isReadOnly !== true && project.stage !== "Complete") {
					return "Add Spaces";
				}
				break;
			case "Team":
				if (
					(projectUser.isSuperAdmin === true || projectUser.isAdmin === true) &&
					project.stage !== "Complete"
				) {
					return "Add Team Member";
				}
				break;
			case "Notes":
				if (projectUser.isReadOnly !== true && project.stage !== "Complete") {
					return "Add Note";
				}
				break;
			case "Samples":
				if (projectUser.isReadOnly !== true && project.stage !== "Complete") {
					return "Add Sample Collection";
				}
				break;
			case "Audits":
				if (projectUser.isReadOnly !== true && project.stage !== "Complete") {
					return "Add Audit";
				}
				break;
			case "Reports":
				if (
					(projectUser.isSuperAdmin === true || projectUser.isAdmin === true) &&
					project.stage !== "Complete"
				) {
					return "Open In Report Center";
				}
				break;
			case "Information":
				return projectUser.isSuperAdmin === true || projectUser.isAdmin
					? "Edit Project"
					: null;
			default:
				break;
		}
	};

	const handleButtonClick = () => {
		switch (currentTab) {
			case "Projects":
				if (
					(projectUser.isSuperAdmin === true || projectUser.isAdmin === true) &&
					project.stage !== "Complete"
				) {
					setNewItem("project");
				}

				break;
			case "Spaces":
				setNewItem("space");
				break;
			case "Team":
				setNewItem("team");
				break;
			case "Notes":
				setNewItem("note");
				break;
			case "Samples":
				setNewItem("samples");
				break;
			case "Audits":
				setNewItem("audit");
				break;
			case "Reports":
				return navigation.navigate("Project Reports", { id: id });

			case "Information":
				if (projectUser.isSuperAdmin === true || projectUser.isAdmin) {
					setNewItem("editProject");
				}
				break;
			default:
				break;
		}
	};

	const getOptions = () => {
		switch (currentTab) {
			case "Spaces":
				return projectUser.isReadOnly !== true &&
					project.stage !== "Complete" ? (
					<OptionsMenu
						options={[
							// {
							// 	icon: faUpload,
							// 	text: "Bulk Upload",
							// 	onPress: () => {
							// 		setNewItem("bulkUpload");
							// 	},
							// },
							// {
							// 	icon: faChartBar,
							// 	name: "Generate Overall Assessment Report",
							// 	onPress: () => {
							// 		setNewItem("damageReport");
							// 	},
							// },
							{
								icon: faList,
								name: "View Dry Standards",
								onPress: () => {
									navigation.navigate("DryStandards", { id: id });
								},
							},
						]}
					/>
				) : null;
			case "Team":
				return projectUser.isReadOnly !== true &&
					project.stage !== "Complete" &&
					project.stage !== "Billing" ? (
					<OptionsMenu
						options={[
							{
								icon: faFileSignature,
								name: "See Your Daily Sheets",
								onPress: () => {
									navigation.navigate("Daily Sheet", {
										id: id,
									});
								},
							},
							projectUser.isSuperAdmin ||
								(projectUser.isAdmin && {
									icon: faUsers,
									name: "Bulk Add Users",
									onPress: () => {
										setNewItem("bulkAdd");
									},
								}),
							projectUser.isSuperAdmin ||
								(projectUser.isAdmin && {
									icon: faFolderTree,
									name: "Project Documents (Admin)",
									onPress: () => {
										navigation.navigate("ProjectDocuments", {
											id: id,
										});
									},
								}),
						]}
					/>
				) : null;
			default:
				break;
		}
	};

	const getProjectRole = (role) => {
		if (role === null || role === undefined) {
			return "No Role";
		}
		if (typeof role === "string") {
			return role;
		}
		return role.name;
	};

	if (loading || !project || !projectUser) {
		return <LoadingScreen loadItem={"Project"} />;
	}
	const navigateToSpace = (id) => {
		navigation.navigate("Space", { id: id });
	};
	const navigateToAudit = (id) => {
		navigation.navigate("Audit", {
			id: id,
		});
	};
	const navigateToProject = (id) => {
		navigation.navigate("Project", {
			id: id,
		});
	};
	const navigateToSample = (id) => {
		navigation.navigate("SampleCollection", {
			id: id,
		});
	};
	const navigateToNote = (id) => {
		navigation.navigate("SingleNote", {
			id: id,
		});
	};
	return (
		<>
			{/* <View>
				<Text>{counter}</Text>
				<Button title="Increment" onPress={incrementCounter} />
			</View> */}
			<Breadcrumb className="mt-2">
				<>
					{project.workspace && (
						<Crumb
							name={project.workspace.title}
							location="Project"
							params={{ id: project.workspace.id }}
						/>
					)}
				</>
				<Crumb
					name={project.title}
					href={`/app/project/${project.id}`}
					location="Project"
					params={{ id: project.id }}
				/>
			</Breadcrumb>
			<PageHeader
				goBack={{
					location: project.workspace ? "Project" : "Projects",
					params: project.workspace ? { id: project.workspace.id } : {},
				}}
				title={project.title}
				info={[
					{
						text: project.workspace
							? project.workspace.client && project.workspace.client.name
								? project.workspace.client.name
								: "No Client Name"
							: project.client && project.client.name
							? project.client.name
							: "No Client Name",
						icon: faBriefcase,
					},
					{
						text:
							project.location && project.location.name
								? project.location.name
								: "No Location Name",
						icon: faMapPin,
					},
					{
						text: project.stage ? project.stage : "No Stage Set",
						icon: faTasks,
					},
					{
						text: project.number ? project.number : "No Project Number",
						icon: faHashtag,
					},
				]}
				buttons={[
					{
						name: getButtonText(),
						icon: getButtonText() === "Edit Project" ? faPen : faPlus,
						onPress: () => handleButtonClick(),
					},
				]}
				options={getOptions()}
			/>
			<Tabs
				currentValue={currentTab}
				onChange={(value) => {
					setCurrentTab(value);
				}}
			>
				{tabs.map((tab, index) => {
					return (
						<Tab
							key={tab.name + index}
							name={tab.name}
							current={currentTab === tab.name ? true : false}
							icon={tab.icon}
							onPress={() => {
								setCurrentTab(tab.name);
							}}
						/>
					);
				})}
			</Tabs>
			<View className="flex-1">
				{currentTab === "Spaces" &&
					(project.spaces.length ? (
						<List title="Spaces" headers={["Name", "Number of Subspaces"]}>
							{[...project.spaces]
								.filter((space) => {
									if (!space.parent) return space;
								})
								.filter((space) =>
									space.name.toLocaleLowerCase().includes(search)
								)
								.sort((a, b) => {
									return a.name.localeCompare(b.name, undefined, {
										numeric: true,
									});
								})
								.map((space, index) => {
									return (
										<ListItem
											key={index}
											items={[
												{
													onPress: () => navigateToSpace(space.id),
													content: space.name,
												},
												{ content: space.spaces.length },
											]}
										/>
									);
								})}
						</List>
					) : (
						<NoItems
							surTitle={"No Space"}
							title="Currently No Spaces"
							text="Create a space by clicking create above"
						/>
					))}
				{currentTab === "Audits" && (
					<>
						{project.surveys.length ? (
							<List
								title="Audits"
								headers={["Name", "Number Of Audits", "Created On"]}
								usesOptions
							>
								{project.surveys
									.filter((survey) =>
										survey.title.toLocaleLowerCase().includes(search)
									)
									.map((survey, index) => {
										return (
											<ListItem
												key={index}
												items={[
													{
														onPress: () => navigateToAudit(survey.id),
														content: `${survey.title}`,
													},
													{
														content: survey.items.length,
														onPress: () => navigateToAudit(survey.id),
													},

													{
														onPress: () => navigateToAudit(survey.id),
														content: survey.date
															? DateTime.fromISO(survey.date).toLocaleString(
																	DateTime.DATETIME_SHORT
															  )
															: "No Date Set",
													},
												]}
												options={
													<OptionsMenu
														options={[
															{
																name: "View",
																onPress: () => {
																	navigateToAudit(survey.id);
																},
															},
														]}
													/>
												}
											/>
										);
									})}
							</List>
						) : (
							<NoItems
								surTitle={"No Audits"}
								title="Currently No Audits"
								text="Create an audit by clicking create above"
							/>
						)}
					</>
				)}
				{currentTab === "Projects" && (
					<>
						{project.projects.length ? (
							<>
								<List
									name={"Projects"}
									headers={["Projects", "Additional Members", "Stage"]}
									usesOptions
								>
									{project.projects
										.filter((project) => project.title.includes(search))
										.map((_project, index) => {
											let color =
												_project.stage &&
												projectStages.find(
													(stage) => stage.value === _project.stage
												).color;

											return (
												<ListItem
													key={index}
													items={[
														{
															onPress: () => navigateToProject(project.id),
															content: (
																<View className="flex items-center truncate space-x-3">
																	<View
																		className={classNames(
																			_project.stage
																				? `bg-${color}-500`
																				: "bg-indigo-500",
																			"w-2.5 h-2.5 flex-shrink-0 rounded-full"
																		)}
																		aria-hidden="true"
																	/>
																	<View className="font-medium truncate text-sm leading-6">
																		{_project.title}
																	</View>
																</View>
															),
														},
														{
															content: (
																<View className="flex items-center space-x-2">
																	<View className="flex flex-shrink-0 -space-x-1">
																		{_project.team && _project.team.length
																			? _project.team.map((member, index) => {
																					if (index > 6) return;
																					return (
																						<>
																							{member.teamMember
																								.profilePicture ? (
																								<Image
																									key={index}
																									className="max-w-none h-6 w-6 rounded-full ring-2 ring-white"
																									src={
																										member.teamMember
																											.profilePicture
																									}
																									alt={`${member.teamMember.firstName} ${member.teamMember.lastName}`}
																								/>
																							) : (
																								<Text
																									key={index}
																									className="max-w-none text-bold h-6 w-6 rounded-full text-center pt-0.5 ring-2 ring-white bg-indigo-500 text-white"
																								>
																									{
																										member.teamMember
																											.firstName[0]
																									}
																									{""}
																									{
																										member.teamMember
																											.lastName[0]
																									}
																								</Text>
																							)}
																						</>
																					);
																			  })
																			: "No Additional Team Members"}
																		{_project.team.length > 5 ? (
																			<View className=" mr-2 pt-0.5 leading-5 font-medium">
																				{" "}
																				+{_project.team.length - 5}
																			</View>
																		) : null}
																	</View>
																</View>
															),
														},
														{
															content: _project.stage
																? _project.stage
																: "No Stage Set",
														},
													]}
													options={
														<OptionsMenu
															options={[
																{
																	name: "View",
																	onPress: () => {
																		navigateToProject(_project.id);
																	},
																},
															]}
														/>
													}
												/>
											);
										})}
								</List>

								<Pagination start={20} stop={23} total={23} />
							</>
						) : (
							<NoItems
								surTitle="No Projects"
								title="You Have No Projects"
								text={
									projectUser.isReadOnly !== true
										? "Click the create button above to start a new project"
										: null
								}
							/>
						)}
					</>
				)}
				{currentTab === "Samples" && (
					<>
						{project.samples.length ? (
							<List
								title="Samples"
								headers={["Name", "Samples", "Type", "Lab", "Created On"]}
								usesOptions
							>
								{project.samples.map((sample, index) => {
									return (
										<ListItem
											key={index}
											items={[
												{
													onPress: () => {
														navigateToSample(sample.id);
													},
													content: `${
														sample.title ? sample.title : "No Sample Name"
													}`,
												},
												{
													onPress: () => {
														navigateToSample(sample.id);
													},
													content: sample.samples.length
														? sample.samples.length
														: "No samples",
												},
												{
													onPress: () => {
														navigateToSample(sample.id);
													},
													content: sample.type
														? sample.type
														: "No Type Selected",
												},

												{
													onPress: () => {
														navigateToSample(sample.id);
													},
													content: sample.lab ? sample.lab.name : "No Lab",
												},

												{
													onPress: () => {
														navigateToSample(sample.id);
													},
													content: sample.date
														? `${DateTime.fromISO(sample.date).toFormat(
																"LLL dd yyyy"
														  )}`
														: "No Sample Date",
												},
											]}
										/>
									);
								})}
							</List>
						) : (
							<NoItems
								surTitle={"No Samples"}
								title="Currently No Sample Collections"
								text="Create a sample collection by clicking create above"
							/>
						)}
					</>
				)}
				{currentTab === "Notes" && (
					<>
						{project.notes && project.notes.length ? (
							<ScrollView>
								<CardContainer>
									{project.notes
										.filter((note) => {
											if (
												(note.title &&
													note.title.toLocaleLowerCase().includes(search)) ||
												(note.description &&
													note.description.toLocaleLowerCase().includes(search))
											) {
												return note;
											}
										})
										.map((note, index) => {
											return (
												<Card
													onPress={() => {
														navigateToNote(note.id);
													}}
													key={index}
													user={note.user}
													title={note.title}
													subheading={DateTime.fromISO(note.date).toFormat(
														"DDD"
													)}
													content={note.description}
												/>
											);
										})}
								</CardContainer>
							</ScrollView>
						) : (
							<NoItems
								surTitle={"No Notes"}
								title="Currently No Notes"
								text="Create a note collection by clicking create above"
							/>
						)}
					</>
				)}
				{currentTab === "Reports" && (
					<>
						{officialReports.length ? (
							<List
								title="Reports"
								headers={["Name", "Created On"]}
								usesOptions
							>
								{officialReports.map((data, index) => {
									return (
										<ListItem
											key={index}
											items={[
												{
													content: `${data.report.reportType.name}`,
												},

												{ content: data.document.date },
											]}
											options={
												<OptionsMenu
													options={[
														{
															name: "Download",
															onPress: () => {
																downloadReport(
																	data.report.link,
																	data.report.name
																);
															},
														},
													]}
												/>
											}
										/>
									);
								})}
							</List>
						) : (
							<NoItems
								surTitle={"No Reports"}
								title="Currently No Official Reports"
								text="Create a new reports by clicking create above"
							/>
						)}
					</>
				)}
				{currentTab === "Team" && (
					<List
						title="Team Members"
						headers={["User", "Project Role", "Permissions", "Type"]}
						usesOptions
					>
						{project.team
							.filter((member) => member.teamMember)
							.filter((tm) => {
								let name = tm.teamMember
									? `${tm.teamMember.firstName}${tm.teamMember.lastName}`.toLocaleLowerCase()
									: "n/an/a";
								if (name.includes(search)) {
									return tm;
								}
							})
							.map((tm, index) => {
								let teamMember = tm.teamMember
									? tm.teamMember
									: { firstName: "N/A", lastName: "N/A", role: "Deleted User" };

								return (
									<ListItem
										key={index}
										smallView={
											<>
												<TouchableOpacity className="w-full rounded-lg shadow-sm border-2 border-gray-200 dark:border-indigo-200 p-2">
													<Text className=" font-bold text-md">
														{teamMember.firstName} {teamMember.lastName}
													</Text>
													<Text className="font-medium">
														Project Role: {""}{" "}
														<Text className="text-indigo-500">
															{getProjectRole(tm.projectRole)}
														</Text>
													</Text>
													<View className="flex flex-row pt-2 justify-between items-center">
														<View>
															<Text className="text-xs font-light text-gray-900 dark:text-white pb-2 ">
																Access Level
															</Text>
															<View className="flex flex-row">
																{tm.isSuperAdmin && (
																	<Badge
																		className={"mr-2"}
																		text="Super Admin"
																	/>
																)}
																{tm.isAdmin && (
																	<Badge className={"mr-2"} text="Admin" />
																)}
																{tm.isGeneral && (
																	<Badge className={"mr-2"} text="General" />
																)}
																{tm.isReadOnly && (
																	<Badge className={"mr-2"} text="Read Only" />
																)}
															</View>
														</View>
														<View className="">
															<Text className="text-xs font-light text-gray-900 dark:text-white text-right pb-2">
																User Type
															</Text>

															<View className="flex flex-row w-full">
																{teamMember.isTeamMember && (
																	<Badge className="ml-2" text="Employee" />
																)}
																{teamMember.isContractor && (
																	<Badge className="ml-2" text="Vendor" />
																)}
																{teamMember.client && (
																	<Badge className="ml-2" text="Client" />
																)}
																{!teamMember.isTeamMember &&
																	!teamMember.isContractor &&
																	!teamMember.client && (
																		<Badge
																			className="ml-2"
																			text="Outside User"
																		/>
																	)}
															</View>
														</View>
													</View>
												</TouchableOpacity>
											</>
										}
										items={[
											{
												content: <User user={teamMember} photoOnly={false} />,
											},
											{ content: getProjectRole(tm.projectRole) },
											{
												content: (
													<>
														{tm.isSuperAdmin && <Badge text="Super Admin" />}
														{tm.isAdmin && <Badge text="Admin" />}
														{tm.isGeneral && <Badge text="General" />}
														{tm.isReadOnly && <Badge text="Read Only" />}
													</>
												),
											},

											{
												content: (
													<>
														{tm.teamMember.isTeamMember && (
															<Badge text="Employee" />
														)}
														{tm.teamMember.isContractor && (
															<Badge text="Subcontractor" />
														)}
														{tm.teamMember.client && <Badge text="Client" />}
														{!tm.teamMember.isTeamMember &&
															!tm.teamMember.isContractor &&
															!tm.teamMember.client && (
																<Badge text="Outside User" />
															)}
													</>
												),
											},
										]}
										options={
											<>
												{!tm.isSuperAdmin && (
													<OptionsMenu
														options={[
															{
																name: "Edit Member ",
																icon: faPen,
																onPress: () => {
																	setUpdateTM({
																		tm: tm,
																		id: tm.id,
																		teamMember: tm.teamMember,
																		projectRole: teamMember.projectRole,
																	});
																	setNewItem("edit-user");
																},
															},
															{
																name: "Delete Member",
																onPress: () => {
																	deleteProjectTeamMember({
																		variables: { input: { id: tm.id } },
																	});
																},
																icon: faTrash,
															},
														]}
													/>
												)}
											</>
										}
									/>
								);
							})}
					</List>
				)}

				{currentTab === "Information" && (
					<ScrollView className="px-2 pt-2">
						<DataContainer>
							<DataItem name="Title" value={project.title} />
							<DataItem
								name="Client"
								value={
									project.workspace
										? project.workspace.client && project.workspace.client.name
											? project.workspace.client.name
											: "No Client Name"
										: project.client && project.client.name
										? project.client.name
										: "No Client Name"
								}
							/>
							<DataItem
								name="Location"
								value={
									<>
										{project.location ? (
											<>
												<Text className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
													{project.location.name || "No Location Name"}
												</Text>
												<Text className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
													{project.location.lineOne}
												</Text>
												{project.location.lineTwo && (
													<Text className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
														{project.location.lineTwo}
													</Text>
												)}
												<Text className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
													{project.location.city} {project.location.state},{" "}
													{project.location.zip}
												</Text>
											</>
										) : (
											<Text className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
												No Location Set
											</Text>
										)}
									</>
								}
							/>
							<DataItem
								name={
									project.workspace ? "Additional Team Members" : "Team Members"
								}
								value={project.team.length}
							/>

							<DataItem
								name="SageIntacct"
								value={project.sageIntacct || "No SageIntacct Number"}
							/>
							<DataItem
								name="Large Loss"
								value={project.isLargeLoss ? "Yes" : "No"}
							/>
							<DataItem
								name="Stage"
								value={project.stage ? project.stage : "No Stage Set"}
							/>
							<DataItem
								name="Dry Standards"
								className="text-blue-500"
								value={
									<TouchableOpacity
										onPress={() => {
											navigation.navigate("DryStandards", { id: id });
										}}
									>
										<Text className="text-blue-500">View Dry Standards</Text>
									</TouchableOpacity>
								}
							/>
							<DataItem name="UUID" value={project.id} />
						</DataContainer>
					</ScrollView>
				)}
			</View>

			<SampleCollectionSlideOver
				isOpen={newItem === "samples"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={(data) => {
					setNewItem(null);
					navigation.navigate("SampleCollection", {
						id: data.id,
					});
				}}
				project={project}
			/>

			<SpaceSlideOver
				isOpen={newItem === "space"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={(data) => {
					setNewItem(null);
					navigation.navigate("Space", {
						id: data.id,
					});
				}}
				project={project}
			/>

			<NoteSlideOver
				isOpen={newItem === "note"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={(data) => {
					setNewItem(null);
					navigation.navigate("SingleNote", {
						id: data.id,
					});
				}}
				project={project}
			/>

			<ProjectAuditSlideOver
				isOpen={newItem === "audit"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={(data) => {
					setNewItem(null);
					navigation.navigate("Audit", { id: data.id });
				}}
				project={project}
			/>

			<SlideOver
				isOpen={newItem === "bulkUpload"}
				name="Bulk Space Upload"
				description={
					<Text>
						<Link
							href="https://docs.google.com/spreadsheets/d/1v3kQ5fdmdIpbWF_Gs4CscP2t1gJeR6PwSiW4aSYFVLQ/template/preview"
							target="_blank"
							rel="noreferrer"
							className="underline"
						>
							Visit Google Sheets
						</Link>{" "}
						to load data. Make sure to export the sheet as a CSV file before
						importing
					</Text>
				}
				closeRequest={() => setNewItem(null)}
				buttonRight={uploading ? "Uploading" : "Upload"}
				onSubmit={(e) => {
					bulkUploadSpace(e);
				}}
			>
				<FileUpload
					files={files}
					onFiles={setFiles}
					server={{
						process: function (fieldName, file, metadata, load, error) {
							s3.upload(
								{
									Key: `projects/${project.id}/bulk-upload/${uuidv4()}`,
									Body: file,
									ContentType: file.type,
								},
								function (err, data) {
									if (err) {
										SentryLogger(err);
										error("Something went wrong", err);
										Alert(err);
										return;
									}

									load(data.Key);
									setKey(data.Key);
								}
							);
						},
					}}
					allowMultiple={false}
					name="files"
				/>
			</SlideOver>

			<ProjectSlideOver
				isOpen={newItem === "editProject"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={(updatedProject) => {
					setProject(updatedProject);
					setNewItem(null);
				}}
				currentProject={{
					id: project.id,
					title: project.title,
					stage: project.stage,
					sageIntacct: project.sageIntacct,
					number: project.number,
					isLargeLoss: project.isLargeLoss,
					usePhaseBilling: project.usePhaseBilling,
					office: project.office?.id,
					client: project.client?.id,
				}}
				defaultSelectValues={{
					stage: project.stage,
					office: project.office?.name,
					client: project.client?.name,
					location: project.location ? project.location.name : "",
				}}
				type="update"
			/>

			<ProjectTeamMember
				isOpen={newItem === "team"}
				name="New Team Member"
				description={`Add New Team Member to ${project.title}`}
				onClose={() => setNewItem(null)}
				type="create"
				onComplete={() => {
					refetch();
					setNewItem(null);
				}}
				project={project}
			/>

			<ProjectTeamMember
				isOpen={newItem === "edit-user"}
				onClose={() => setNewItem(null)}
				type="update"
				onComplete={() => {
					refetch();
					setNewItem(null);
				}}
				currentTeamMember={{
					id: updateTM.id,
					projectRole: updateTM.projectRole ? updateTM.projectRole.id : null,
				}}
				project={project}
				defaultSelectValues={{
					role: updateTM.projectRole ? updateTM.projectRole.name : null,
					user: updateTM.teamMember
						? `${updateTM.teamMember.firstName} ${updateTM.teamMember.lastName}`
						: null,
				}}
			/>
		</>
	);
};

Project.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
	search: PropTypes.string,
	route: PropTypes.object,
};

// {/* Slide Overs */}

// {/* if show add all modal add the className of the modal otherwise display hidden */}
// <View
// 	className={`${
// 		showBulkUsers
// 			? "z-20 absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-white dark:bg-gray-900 Text-2"
// 			: "hidden"
// 	}`}
// >
// 	<View className="z-50 flex flex-col space-y-4 ">
// 		<View className="flex flex-col space-y-2">
// 			<Text className="text-sm font-medium text-gray-700 dark:text-gray-200">
// 				Select users to add to the project
// 			</Text>
// 			<Select
// 				options={users
// 					.filter((user) => {
// 						return user.isActive !== false;
// 					})
// 					.filter((user) => {
// 						return !project.team
// 							.filter((u) => u.teamMember)
// 							.find((u) => u.teamMember.id === user.id);
// 					})
// 					.filter((user) => {
// 						return !bulkUsers.find((u) => u.id === user.id);
// 					})
// 					.sort((a, b) => {
// 						if (a.firstName < b.firstName) {
// 							return -1;
// 						}
// 						if (a.firstName > b.firstName) {
// 							return 1;
// 						}
// 						return 0;
// 					})
// 					.map((user) => ({
// 						name: `${user.firstName} ${user.lastName}`,
// 						user: user,
// 					}))}
// 				onChange={(value) => {
// 					setBulkUsers((prev) => [
// 						...prev,
// 						{
// 							...value.user,
// 							isAdmin: false,
// 							isSuperAdmin: false,
// 							isReadOnly: false,
// 							isGeneral: true,
// 						},
// 					]);
// 				}}
// 				placeholder="Select User"
// 			/>
// 		</View>
// 	</View>
// 	{/* list the users in the bulkUser list */}

// 	{bulkUsers.length > 0 ? (
// 		<View className="flex flex-col space-y-2">
// 			<Text className="text-sm font-medium text-gray-700 dark:text-gray-200">
// 				Users
// 			</Text>
// 			<View className="flex flex-col space-y-2 ">
// 				{bulkUsers.map((user, index) => (
// 					<View
// 						key={index}
// 						className="flex flex-row justify-between items-center"
// 					>
// 						<Text className="flex-1 text-sm font-medium text-gray-700 dark:text-gray-200 self-stretch">
// 							{user.firstName} {user.lastName}
// 						</Text>
// 						<View className="flex-1 self-stretch px-2">
// 							<Select
// 								name="Project Role"
// 								options={projectRoles.map((role) => ({
// 									name: role.name,
// 									role: role,
// 								}))}
// 								onChange={(value) => {
// 									setBulkUsers((prev) => {
// 										const updated = prev.map((u) => {
// 											if (u.id === user.id) {
// 												return {
// 													...u,
// 													projectRole: value.role,
// 												};
// 											}
// 											return u;
// 										});
// 										return updated;
// 									});
// 								}}
// 								placeholder="Select Role"
// 							/>
// 						</View>
// 						<View className="flex-1 self-stretch px-2">
// 							<Select
// 								name="Project Permissions"
// 								options={[
// 									{ name: "Read Only" },
// 									{ name: "General" },
// 									{ name: "Admin" },
// 								]}
// 								defaultValue={"General"}
// 								onChange={(value) => {
//

// 									let permission = {
// 										isGeneral: true,
// 										isAdmin: false,
// 										isReadOnly: false,
// 										isSuperAdmin: false,
// 									};

// 									switch (value.name) {
// 										case "Read Only":
// 											permission = {
// 												isGeneral: false,
// 												isAdmin: false,
// 												isReadOnly: true,
// 												isSuperAdmin: false,
// 											};
// 											break;
// 										case "General":
// 											permission = {
// 												isGeneral: true,
// 												isAdmin: false,
// 												isReadOnly: false,
// 												isSuperAdmin: false,
// 											};
// 											break;
// 										case "Admin":
// 											permission = {
// 												isGeneral: false,
// 												isAdmin: true,
// 												isReadOnly: false,
// 												isSuperAdmin: false,
// 											};
// 											break;
// 										default:
// 											permission = {
// 												isGeneral: false,
// 												isAdmin: false,
// 												isReadOnly: true,
// 												isSuperAdmin: false,
// 											};
// 									}

// 									setBulkUsers((prev) => {
// 										const updated = prev.map((u) => {
// 											if (u.id === user.id) {
// 												return {
// 													...u,
// 													...permission,
// 												};
// 											}
// 											return u;
// 										});
// 										return updated;
// 									});
// 								}}
// 							/>
// 						</View>

// 						<Button
// 							className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
// 							onPress={() => {
// 								setBulkUsers((prev) =>
// 									prev.filter((u) => u.id !== user.id)
// 								);
// 							}}
// 							text="Remove"
// 						/>
// 					</View>
// 				))}
// 			</View>
// 		</View>
// 	) : (
// 		<NoItems
// 			title="No User Selected"
// 			text="Please select user to start bulk adding users to the project"
// 		/>
// 	)}

// 	<View className="flex justify-end mt-4 space-x-3">
// 		<Button
// 			className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
// 			onClick={() => {
// 				setShowBulkUsers(false);
// 				setBulkUsers([]);
// 			}}
// 			text="Cancel"
// 		/>
// 		<Button
// 			type="button"
// 			className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
// 			onPress={() => {
// 				// check to make sure all users have a role

// 				const hasRole = bulkUsers.every((user) => user.projectRole);
// 				if (!hasRole) {
// 					alert("Please select a role for all users");
// 					return;
// 				}

// 				bulkUsers.forEach((user) => {
// 					addNewTeamMember({
// 						variables: {
// 							input: {
// 								teamMember: user.id,
// 								project: project.id,
// 								projectRole: user.projectRole.id,
// 								added: DateTime.local().toISO(),
// 								isAdmin: user.isAdmin,
// 								isGeneral: user.isGeneral,
// 								isReadOnly: user.isReadOnly,
// 								isSuperAdmin: user.isSuperAdmin,
// 							},
// 						},
// 					});
// 				});
// 				setBulkUsers([]);
// 				setShowBulkUsers(false);
// 			}}
// 			text="Add"
// 		/>
// 	</View>
// </View>
