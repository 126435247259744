export const getUser = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			isManager
			isAdmin
			isTeamMember
			isTempWorker
			isContractor
			firstName
			lastName
			email
			phone
			role
			profilePicture
			cognito
			projects {
				id
				pinned
				project {
					projects {
						id
						title
					}
					stage
					id
					title
					number
					start
					isLargeLoss
					sageIntacct
					client {
						id
						isOrganization
						name
						location {
							id
							isBranchLocation
							name
							lineOne
							lineTwo
							city
							state
							zip
							latitude
							longitude
						}
					}
					isWorkspace
					isViewable
					surveys {
						id
						project {
							id
							title
							number
							start
							isWorkspace
							isViewable
						}
						title
						date
						items {
							id
							date
							title
							description
							order
							location
						}
					}
					location {
						id
						isBranchLocation
						lab {
							id
							name
						}
						name
						client {
							id
							isOrganization
							name
						}
						lineOne
						lineTwo
						city
						state
						zip
						latitude
						longitude
					}
					office {
						id
						isBranchLocation
						lab {
							id
							name
						}
						name
						client {
							id
							isOrganization
							name
						}
						lineOne
						lineTwo
						city
						state
						zip
						latitude
						longitude
					}

					team {
						id
						project {
							id
							title
							number
							start
							isWorkspace
							isViewable
						}
						teamMember {
							id
							isManager
							isAdmin
							isTeamMember
							isTempWorker
							isContractor
							firstName
							lastName
							email
							phone
							role
							profilePicture
							cognito
							canBeAddedToProject
							linkExpiration
							organization
							isRequesting
							bio
						}
						projectRole {
							id
							name
						}
						isSuperAdmin
						isAdmin
						isGeneral
						isReadOnly
						isActive
					}
				}

				teamMember {
					id
					isManager
					isAdmin
					isTeamMember
					isTempWorker
					isContractor
					firstName
					lastName
					email
					phone
					location {
						id
						isBranchLocation
						lab {
							id
							name
						}
						name
						client {
							id
							isOrganization
							name
						}
						lineOne
						lineTwo
						city
						state
						zip
						latitude
						longitude
					}
					role
					profilePicture
					cognito
					canBeAddedToProject
					linkExpiration
					organization
					isRequesting
					bio
				}
				projectRole {
					id
					name
				}
				isSuperAdmin
				isAdmin
				isGeneral
				isReadOnly
				isActive
			}
			canBeAddedToProject
			linkExpiration
			organization
			isRequesting
			bio
		}
	}
`;

export const updatedPinned = /* GraphQL */ `
	mutation UpdateProjectTeamMember($input: UpdateProjectTeamMemberInput!) {
		updateProjectTeamMember(input: $input) {
			teamMember {
				projects {
					isActive
					pinned
					project {
						id
						title
						stage
						client {
							id
							name
						}
						team {
							id
							teamMember {
								firstName
								lastName
								profilePicture
							}
						}
						isWorkspace
						isViewable
					}
				}
			}
		}
	}
`;

export const listClients = /* GraphQL */ `
	query ListClients(
		$filter: TableClientFilterInput
		$limit: Int
		$nextToken: String
	) {
		listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				locations {
					id
					name
				}
			}
		}
	}
`;

export const listLocations = /* GraphQL */ `
	query ListLocations(
		$filter: TableLocationFilterInput
		$limit: Int
		$nextToken: String
	) {
		listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				isBranchLocation
			}
		}
	}
`;

export const createProject = /* GraphQL */ `
	mutation CreateProject($input: CreateProjectInput!) {
		createProject(input: $input) {
			id
		}
	}
`;

export const createProjectTeamMember = /* GraphQL */ `
	mutation CreateProjectTeamMember($input: CreateProjectTeamMemberInput!) {
		createProjectTeamMember(input: $input) {
			id
		}
	}
`;

export const createClient = /* GraphQL */ `
	mutation CreateClient($input: CreateClientInput!) {
		createClient(input: $input) {
			id
			name
		}
	}
`;

export const createLocation = /* GraphQL */ `
	mutation CreateLocation($input: CreateLocationInput!) {
		createLocation(input: $input) {
			id
			name
		}
	}
`;

export const updateProject = /* GraphQL */ `
	mutation UpdateProject($input: UpdateProjectInput!) {
		updateProject(input: $input) {
			id
			title
			stage
			number
			isViewable
			isWorkspace
			isLargeLoss
			isCAT
			unscheduledEquipmentCharge
			sageIntacct
			workspace {
				id
				title
				team {
					id
					isSuperAdmin
					isAdmin
					isGeneral
					isReadOnly
					projectRole {
						id
						name
					}
					isActive
					added
					teamMember {
						id
						firstName
						lastName
						profilePicture
						role
						isTeamMember
						isTempWorker
						isContractor
						client {
							id
						}
					}
				}

				client {
					id
					name
				}
				office {
					id
					name
				}
			}
			reports {
				id
				status
				reportType {
					id
					name
					category
				}
				documents {
					isOfficial
					name
					date
					link
				}
			}
			notes {
				id
				title
				description
				date
				user {
					id
					firstName
					lastName
					profilePicture
					role
				}
			}
			client {
				id
				name
				locations {
					id
					name
				}
			}
			location {
				id
				name
				lineOne
				lineTwo
				city
				state
				zip
			}
			team {
				id
				isSuperAdmin
				isAdmin
				isGeneral
				isReadOnly
				projectRole {
					id
					name
				}
				isActive
				added
				teamMember {
					id
					firstName
					lastName
					profilePicture
					role
					isTeamMember
					isTempWorker
					isContractor
					client {
						id
					}
				}
			}
			spaces {
				id
				name
				spaces {
					id
					name
					inherentParentDryStandard
					projectSub
					isFungalGrowth
					noAccess
					isDamage
					isWallDamage
					isCeilingDamage
					isFloorDamage
					damgage
					date
				}
				parent {
					id
					name
				}
			}
			surveys {
				id
				title
				date
				items {
					id
					date
					title
					description
					order
					location
				}
			}
			samples {
				id
				title
				lab {
					id
					name
				}
				labLocation {
					id
					isBranchLocation
					name
					lineOne
					lineTwo
					city
					state
					zip
					latitude
					longitude
				}
				type
				clearance
				samples {
					id
					number
					date
					title
					description
					volumeOrArea
					location
					projectSub
				}
				createdBy {
					id
					firstName
					lastName
					email
					phone
					role
					profilePicture
					bio
					title
				}
				date
			}
			office {
				id
				name
			}
			projects {
				id
				title
				stage
				number
				isViewable
				isWorkspace
				location {
					id
					name
				}
				team {
					id
					added
					teamMember {
						id
						firstName
						lastName
						profilePicture
						role
						isTeamMember
						isTempWorker
						isContractor
						client {
							id
						}
					}
				}
			}
		}
	}
`;

export const listProjectRoles = /* GraphQL */ `
	query ListProjectRoles {
		listProjectRoles {
			items {
				id
				name
			}
		}
	}
`;
