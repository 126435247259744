/* This example requires Tailwind CSS v2.0+ */
// Rendering
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal as ModalCode, Text, TouchableOpacity, View } from "react-native";

// Icons
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
// Component
import Button from "../components/Button";

export default function Modal({
	isOpen,
	closeRequest = () => {},
	children,
	title,
	description,
	rightButton,
	closeButton = "Cancel",
}) {
	const [open, setOpen] = useState(isOpen);

	React.useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	return (
		<ModalCode
			animationType="fade"
			transparent={true}
			visible={open}
			testID="modal"
			className="fixed inset-0 z-10 overflow-y-auto overscroll-x-contain"
		>
			<View className="relative w-full h-full flex items-center justify-center">
				<View className="h-full w-full bg-gray-500 opacity-75"></View>
				<View className="dark:bg-gray-800 bg-gray-50 position-fixed inline-flex  m-w-fit w-5/6  sm:w-1/2 mar rounded-lg shadow p-4 absolute z-40">
					<TouchableOpacity
						className=" text-2xl font-2xl absolute right-0 top-0 m-2 rounded-full h-8 w-8  bg-gray-300 flex items-center justify-center"
						onPress={() => {
							setOpen(false);
							closeRequest();
						}}
					>
						<FontAwesomeIcon icon={faTimes} size={20} color="white" />
					</TouchableOpacity>

					<View className="relative w-full mt-1 text-center flex items-center">
						<Text
							testID="title-text"
							className="text-lg  text-center font-medium text-gray-900 dark:text-white"
						>
							{title}
						</Text>

						<Text
							testID="description-text"
							className="text-sm text-gray-500 text-center"
						>
							{description}
						</Text>
						{children}
					</View>
					<View className=" w-full pt-4 flex flex-row justify-end">
						<>
							{rightButton && (
								<Button
									testID="submit-button"
									onPress={() => {
										rightButton.onPress
											? rightButton.onPress()
											: rightButton.onClick();
									}}
									text={rightButton.text}
									variant={rightButton.variant || "indigo"}
								/>
							)}
						</>
						<View className="ml-2">
							<Button
								testID="cancel-button"
								text={closeButton}
								variant="gray"
								onPress={() => {
									setOpen(false);
									closeRequest();
								}}
							/>
						</View>
					</View>
				</View>
			</View>
		</ModalCode>
	);
}

Modal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	onPress: PropTypes.func,
	children: PropTypes.node,
	title: PropTypes.string,
	description: PropTypes.string,
	rightButton: PropTypes.shape({
		text: PropTypes.string,
		onPress: PropTypes.func,
		onClick: PropTypes.func,
		variant: PropTypes.string,
	}),
	closeButton: PropTypes.string,
};

// Make a react-native screen that shows a user's name.
