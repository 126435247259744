import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";

// Components
import { Input } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation, useQuery } from "@apollo/client";
import * as bodyGQL from "../graphql/admin/body.js";
import * as sopGQL from "../graphql/admin/sops.js";
import * as userGQL from "../graphql/admin/users.js";
import * as versionGQL from "../graphql/admin/version.js";

// Utils
import { API_URL, getData, SentryLogger } from "../utils";

export const SopSlideOver = ({
	type = "create",
	currentSOP,
	isOpen,
	closeRequest = () => {},
	onComplete,
}) => {
	const [newSOP, setNewSOP] = React.useState(null);
	const [userData, setUserData] = React.useState(null);
	const [users, setUsers] = React.useState([]);

	React.useEffect(() => {
		if (currentSOP) {
			setNewSOP(currentSOP);
		}
	}, [currentSOP]);

	React.useEffect(() => {
		const getUser = async () => {
			await getData("@storage_Key").then((user) => setUserData(user));
		};
		getUser();
	}, []);

	const [createSOP] = useMutation(gql(sopGQL.createSOP), {
		onCompleted: (data) => {
			createVersion({
				variables: {
					input: {
						sop: data.createSOP.id,
						version: "1.0.0",
						public: false,
						created: DateTime.now().toISO(),
					},
				},
			});
			onComplete(data.createSOP);
			sendSOPNotification(data.createSOP);
		},
		onError: () => {
			alert("Couldn't create your SOP please try again");
		},
	});
	const [createVersion] = useMutation(gql(versionGQL.createVersion), {
		onCompleted: async (data) => {
			await createBody({
				variables: {
					input: {
						version: data.createVersion.id,
						// body: newVersion.body,
						body: "test",
						language: "EN",
					},
				},
			});

			onComplete(data.createVersion);
		},
		onError: () => {
			alert("Couldn't create your Version please try again");
		},
	});
	const [createBody] = useMutation(gql(bodyGQL.createBody), {
		onCompleted: (data) => {
			onComplete(data.createBody);
		},
		onError: () => {
			alert("Couldn't create your body please try again");
		},
	});

	const [updateSOP] = useMutation(gql(sopGQL.updateSOP), {
		onCompleted: (data) => {
			onComplete(data.updateSOP);
		},
		onError: () => {
			alert("Couldn't update your SOP please try again");
		},
	});

	useQuery(gql(userGQL.listUsers), {
		variables: {
			filter: {
				isTeamMember: true,
			},
		},
		onCompleted: (data) => {
			setUsers([...data.listUsers.items]);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	const sendSOPNotification = async () => {
		users &
			users.map(async (user) => {
				await fetch(`${API_URL}/notifications/notification`, {
					method: "POST",
					mode: "cors",
					body: JSON.stringify({
						notification: {
							title: "🔔 A new SOP has been created!",
							message: `${newSOP.title} has been created and added to the SOP Library.`,
							createdBy: userData.id,
							user: user.id,
						},
						type: "system",
					}),
				}).catch((error) => {
					SentryLogger(JSON.stringify(error, null, 2));
				});
			});
	};

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "New SOP" : "Edit SOP"}
			description={
				type === "create" ? "Initial SOP will be version 1.0.0 " : ""
			}
			closeRequest={() => closeRequest()}
			onSubmit={() => {
				let input = newSOP;
				if (type === "create") {
					//add in any necessary fields

					createSOP({ variables: { input: input } });
				} else {
					updateSOP({
						variables: {
							input: {
								id: newSOP.id,
								title: newSOP.title,
							},
						},
					});
				}
			}}
			buttonRightText={type === "create" ? "Create" : "Update"}
		>
			<Input
				type="text"
				class="w-input"
				required
				label="Title"
				placeholder={newSOP ? newSOP.title : ""}
				onChange={(e) => {
					setNewSOP({
						...newSOP,
						title: e,
					});
				}}
			/>
		</SlideOver>
	);
};
SopSlideOver.propTypes = {
	route: PropTypes.object,
	type: PropTypes.string,
	currentSOP: PropTypes.object,
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func,
	onComplete: PropTypes.func,
};
