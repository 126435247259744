import React, { useState } from "react";
import PropTypes from "prop-types";
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";

// Components
import { Badge } from "../../components/Badge";
import LoadingScreen from "../../components/LoadingScreen";
import { PageHeader } from "../../components/Header";
import Button from "../../components/Button";
import NoItem from "../../components/NoItems";
import User from "../../components/User";
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";

// GQL
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as notificationGQL from "../../graphql/notifications";

// Utils
import { getData } from "../../utils";

// Icons
import { faCalendar } from "@fortawesome/pro-duotone-svg-icons";

const NotificationList = ({ notifications, onSelected }) => {
	return (
		<ScrollView headers={["Notification"]} className="h-full">
			{[...notifications]
				.sort((a, b) => {
					if (DateTime.fromISO(b.date).ts > DateTime.fromISO(a.date).ts) {
						return 1;
					} else {
						return -1;
					}
				})
				.map((notification, index) => (
					<TouchableOpacity
						key={index}
						className="min-h-52 p-2 m-2 border-2 rounded-lg border-gray-50"
						onPress={() => {
							onSelected(notification);
						}}
					>
						<View className="flex flex-row justify-between">
							<Text className="font-bold text-gray-900 dark:text-white">
								{notification.title}
							</Text>
							<Badge
								text={notification.isRead ? "Read" : "Unread"}
								variant={notification.isRead ? "gray" : "indigo"}
							/>
						</View>

						<Text className="text-gray-900 dark:text-white">
							{notification.message}
						</Text>
						<Text className="mt-2 font-light text-gray-900 dark:text-white">
							{DateTime.fromISO(notification.date).toLocaleString(
								DateTime.DATETIME_MED
							)}
						</Text>
					</TouchableOpacity>
				))}
		</ScrollView>
	);
};

NotificationList.propTypes = {
	notifications: PropTypes.array.isRequired,
	onSelected: PropTypes.func.isRequired,
};

const NotificationDetail = ({ notification, onBack }) => {
	const navigation = useNavigation();
	const [updateNotification] = useMutation(
		gql(notificationGQL.updateNotification),
		{}
	);

	React.useEffect(() => {
		if (notification && !notification.isRead) {
			updateNotification({
				variables: {
					input: {
						id: notification.id,
						isRead: true,
						readDate: DateTime.now().toISO(),
					},
				},
			});
		}
	}, []);

	return (
		<View className="p-2">
			<View className="block md:hidden">
				{/* We have this for the small view only since it will need a page header */}
				<PageHeader
					title="Notification"
					goBack
					info={[
						{
							icon: faCalendar,
							text: DateTime.fromISO(
								notification && notification.date
							).toLocaleString(DateTime.DATETIME_MED),
						},
					]}
				/>
			</View>
			{/* We need this because it will be needed for the medium view */}
			{!notification ? (
				<NoItem
					surTitle="Select A Notification To View"
					title="No Notification"
					text="Select a notification to view and take action"
				/>
			) : (
				<View>
					{/* Make this your own and how you see fit! */}
					<Text className="font-bold text-gray-900 dark:text-white">
						{notification.title}
					</Text>
					<Text className="mt-2  text-gray-900 dark:text-white">
						{notification.message}
					</Text>

					{notification.createdBy && (
						<View className="mt-6">
							<Text className="font-light text-gray-900 dark:text-white">
								Notification Created By
							</Text>
							<User user={notification.createdBy} />
						</View>
					)}

					<View className="mt-6 flex flex-row justify-between">
						{notification.project && (
							<Button
								text="View Project"
								variant="indigo"
								onPress={() =>
									navigation.navigate("Project", {
										id: notification.project.id,
									})
								}
							/>
						)}

						<Button onPress={onBack} variant="gray" text="Back" />
					</View>
				</View>
			)}
		</View>
	);
};

NotificationDetail.propTypes = {
	notification: PropTypes.object,
	onBack: PropTypes.func.isRequired,
};

export const Notifications = () => {
	const [notifications, setNotifications] = useState([]);
	const [notification, setNotification] = useState(null);

	// get user
	React.useEffect(() => {
		const getUserInfo = async () => {
			await getData("@storage_Key").then((user) => {
				getNotifications({
					variables: {
						id: user.id,
					},
				});
			});
		};
		getUserInfo();
	}, []);

	// gql calls
	const [getNotifications, { loading }] = useLazyQuery(
		gql(notificationGQL.getUserNotifications),
		{
			onCompleted: (data) => {
				setNotifications(data.getUser.notifications);
			},
			fetchPolicy: "cache-and-network",
		}
	);

	if (loading) {
		return <LoadingScreen loadItem="Notifications" />;
	}

	// render
	return (
		<>
			<Breadcrumb>
				<Crumb name="Notifications" location="Notifications" />
			</Breadcrumb>
			{/* Small view */}
			<View className="block md:hidden">
				{!notification ? (
					<>
						<PageHeader
							title="Notifications"
							goBack={{
								location: "Projects",
							}}
						/>
						<NotificationList
							notifications={notifications}
							onSelected={setNotification}
						/>
					</>
				) : (
					<>
						<NotificationDetail
							notification={notification}
							onBack={() => setNotification(null)}
						/>
					</>
				)}
			</View>

			{/* Medium size and up view */}
			<View className="hidden md:flex">
				<PageHeader title="Notifications" goBack={{}} />
				<View className="flex flex-row items-start">
					<View className="w-1/3">
						<NotificationList
							notifications={notifications}
							onSelected={setNotification}
						/>
					</View>
					<View className="w-2/3">
						<NotificationDetail
							notification={notification}
							onBack={() => setNotification(null)}
						/>
					</View>
				</View>
			</View>
		</>
	);
};
