import React, {useState} from "react";
import { View, Text, TouchableOpacity } from "react-native";
import * as Updates from "expo-updates";
import  useInterval  from "use-interval";
  

  export default function UpdateNotification() {
    const [avaliableUpdate, setAvaliableUpdate] = useState(false);
    
    useInterval(() => {
      const checkForUpdate = async () => {
        try {
          const update = await Updates.checkForUpdateAsync();
          if (update.isAvailable) {
            setAvaliableUpdate(true);
          }
        } catch (e) {
            // HANDLE ERROR HERE
        }
      };
      checkForUpdate();
   }, 600000);

    if(avaliableUpdate) {
      return (
        <TouchableOpacity 
          onPress={() => {
            Updates.reloadAsync();
          }} 
          className="shadow mt-10 mx-2 p-8 bg-gray-200 dark:bg-gray-800 rounded-2xl opacity-95"
        >
            <View
                className="flex flex-row items-center justify-between"
               
            >
                <Text className="text-indigo-500 text-lg font-bold mr-1">New Update Available</Text>
            </View>
            <Text className="text-gray-900 dark:text-white">Tap here to update the app, or force the app and and open it again to install the new update</Text>
        </TouchableOpacity>
      );
    }

    return null;
  }
