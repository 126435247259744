import React from "react";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";

// Components

import { PageHeader } from "../../../components/Header";
import { List, ListItem } from "../../../components/List";
import Breadcrumbs, { Crumb } from "../../../components/Breadcrumb";
import User from "../../../components/User";
import Pagination from "../../../components/Pagination";

// GQL

import { gql, useQuery } from "@apollo/client";
import * as userOperations from "../../../graphql/admin/users";

//Utils
import { getData, SentryLogger } from "../../../utils";
import { TouchableOpacity, Text } from "react-native";
import View from "@expo/html-elements/build/primitives/View";

export function TimeAnalysis({ search }) {
	const navigation = useNavigation();
	let [teamMembers, setTeamMembers] = React.useState([]);

	const itemsPerPage = 30;
	let [currentPage, setCurrentPage] = React.useState(1);

	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end = teamMembers
		? currentPage * itemsPerPage >
		  teamMembers
				.filter((member) => member.isTeamMember || member.isContractor)
				.filter(
					(member) =>
						member.firstName.toLowerCase().includes(search.toLowerCase()) ||
						member.lastName.toLowerCase().includes(search.toLowerCase())
				).length
			? teamMembers
					.filter((member) => member.isTeamMember || member.isContractor)
					.filter(
						(member) =>
							member.firstName.toLowerCase().includes(search.toLowerCase()) ||
							member.lastName.toLowerCase().includes(search.toLowerCase())
					).length
			: currentPage * itemsPerPage
		: 0;

	React.useEffect(() => {
		const userInfo = async () => {
			await getData("@storage_Key")
				.then((user) => {
					if (!user.isTimeAdmin) {
						return navigation.goBack();
					}
				})

				.catch((err) => {
					SentryLogger(err);
				});
		};

		userInfo();
	}, []);

	useQuery(gql(userOperations.listUsers), {
		onCompleted: (data) => {
			setTeamMembers(data.listUsers.items);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	return (
		<>
			<Breadcrumbs>
				<Crumb
					name={"Time Clock"}
					location={"Time Clock"}
					//href={"/app/time-clock"}
				/>
				<Crumb
					name="Time Analysis Center"
					location={"Time Analysis Center"}
					//href={"/app/time-analysis"}
				/>
			</Breadcrumbs>
			<PageHeader title="Employees" />
			<List headers={["Name", "Role"]}>
				{teamMembers
					.filter((member) => member.isTeamMember || member.isContractor)
					.filter(
						(member) =>
							member.firstName.toLowerCase().includes(search.toLowerCase()) ||
							member.lastName.toLowerCase().includes(search.toLowerCase())
					)

					.sort((a, b) => {
						if (a.firstName > b.firstName) return 1;
						if (a.firstName < b.firstName) return -1;
						return 0;
					})
					.map((teamMember, index) => {
						if (index + 1 >= start && index < end) {
							return (
								<ListItem
									key={index}
									smallView={
										<>
											<TouchableOpacity
												className="w-full rounded-lg shadow-sm border-2 border-gray-200 dark:border-indigo-200 p-2"
												onPress={() => {
													navigation.navigate("User Time", {
														id: teamMember.id,
													});
												}}
											>
												<View>
													<Text className=" font-bold text-md">
														{teamMember.firstName} {teamMember.lastName}
													</Text>
													<Text className="font-medium">
														<Text className="text-indigo-500">
															{teamMember.role
																? teamMember.role
																: "No Project Role"}
														</Text>
													</Text>
												</View>
											</TouchableOpacity>
										</>
									}
									items={[
										{
											content: <User user={teamMember} />,
											onPress: () =>
												navigation.navigate("User Time", { id: teamMember.id }),
										},
										{
											content: teamMember.role ? teamMember.role : "No Role",
											onPress: () =>
												navigation.navigate("User Time", { id: teamMember.id }),
										},
									]}
								/>
							);
						}
					})}
			</List>

			<Pagination
				start={start}
				stop={end}
				total={
					teamMembers
						.filter((member) => member.isTeamMember || member.isContractor)
						.filter(
							(member) =>
								member.firstName.toLowerCase().includes(search.toLowerCase()) ||
								member.lastName.toLowerCase().includes(search.toLowerCase())
						).length
				}
				nextClicked={() => setCurrentPage(currentPage + 1)}
				previousClicked={() => setCurrentPage(currentPage - 1)}
			/>
		</>
	);
}

TimeAnalysis.propTypes = {
	history: PropTypes.shape({
		goBack: PropTypes.func.isRequired,
	}),
	search: PropTypes.string,
};
