import { Buffer } from "buffer";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import {
	Dimensions,
	Image,
	Platform,
	Text,
	TouchableOpacity,
	View,
} from "react-native";
import uuid from "react-native-uuid";

// Signature Components
// for native
import SignatureScreen from "react-native-signature-canvas";
// for web
import SignatureCanvas from "react-signature-canvas";

// Components

import { Alert } from "../components/Alert";
import SlideOver from "../components/Slideover";
// import { addNotification } from "../components/Notification";
import {
	Checkbox,
	FieldSet,
	Input,
	Select,
	Switch,
	TextArea,
} from "../components/Forms";

// GQL

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { listDailyDocumentsByDateAndUser } from "../graphql/timeEntry";
import * as timeEntryGQL from "../graphql/timeEntry.js";

// Utils
import { getData, SentryLogger, uploadFile } from "../utils";

const DailySheetSlideOver = ({
	isOpen,
	closeRequest = () => {},
	type,
	timeDailyItem,
	suppliedUser,
	currentDailySheet,
	onComplete,
	clerking,
	clerkingData,
	currentProject,
}) => {
	const [user, setUser] = React.useState();
	const [dailySheet, setNewDailySheet] = React.useState({});
	const [rentalCar, setRentalCar] = React.useState(false);
	const [hotel, setHotel] = React.useState(false);
	const [onSite, setOnSite] = React.useState(false);
	const [hasPerDiem, setHasPerDiem] = React.useState(true);
	const [onFlir, setOnFlir] = React.useState(false);
	const [onMMP, setOnMMP] = React.useState(false);
	const [onThermo, setOnThermo] = React.useState(false);
	const [signing, setSigning] = React.useState(false);
	const [billingPhases, setBillingPhases] = React.useState([]);

	// signature refs
	let ref = React.useRef(); // native
	let sigRef = React.useRef(); // web

	React.useEffect(() => {
		const getUserInfo = async () => {
			await getData("@storage_Key").then((data) => {
				setUser(data);
				if (data && data.id !== null) {
					getPerDiemInfo({
						variables: {
							user: data.id,
							date:
								type === "create"
									? DateTime.now().toISODate()
									: DateTime.fromISO(dailySheet.date).toISODate(),
						},
					});
				}
			});
		};
		getUserInfo();
	}, []);

	React.useEffect(() => {
		if (currentDailySheet) {
			setNewDailySheet({ ...currentDailySheet, signature: null });
		}
	}, [currentDailySheet]);

	React.useEffect(() => {
		if (currentProject) {
			setBillingPhases([...currentProject.billingPhases]);
		}
	}, [currentProject]);

	let [getPerDiemInfo] = useLazyQuery(gql(listDailyDocumentsByDateAndUser), {
		onCompleted: async (data) => {
			let perDiemDailySheets = data.listDailyDocumentsByDateAndUser.filter(
				(dd) => dd.perDiem
			); // => []
			if (perDiemDailySheets.length) {
				setHasPerDiem(true);
			} else {
				setHasPerDiem(false);
			}
		},
	});

	const [createDailyDocument] = useMutation(
		gql(timeEntryGQL.createDailyDocument),
		{
			onCompleted: async () => {
				onComplete();
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);

	const [updateDailyDocument] = useMutation(
		gql(timeEntryGQL.updateDailyDocument),
		{
			onCompleted: async () => {
				onComplete();
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);

	return (
		<>
			{!signing ? (
				<SlideOver
					isOpen={isOpen}
					name={type === "create" ? "New Daily Sheet" : "Update Daily Sheet"}
					description={
						type === "create"
							? "Fill out a daily form for each project you were on today"
							: "Update your daily sheet"
					}
					closeRequest={() => {
						setNewDailySheet({});
						closeRequest();
					}}
					onSubmit={async () => {
						// check for signature (if they are updating, they must resign)
						const signature = dailySheet.signature;

						if (
							(dailySheet.signature === null ||
								dailySheet.signature === undefined) &&
							!clerking
						) {
							if (type === "create") {
								return Alert(
									"Missing Signature",
									"You must sign when creating a daily sheet"
								);
							} else {
								return Alert(
									"Missing Signature",
									"You must resign when updating a daily sheet"
								);
							}
							return;
						}

						// check for activities listed
						if (!dailySheet.activities) {
							return Alert(
								"Missing Information",
								" You must describe your activities"
							);
						}

						// variables for signature
						const projectID =
							clerkingData && clerkingData.project
								? clerkingData.project
								: timeDailyItem.project.id;
						const date =
							clerkingData && clerkingData.date
								? clerkingData.date
								: DateTime.fromISO(timeDailyItem.timeIn, {
										setZone: true,
								  }).toISODate();
						const key = `project/${projectID}/dailySheets/${date}/signatures/${uuid.v4()}.png`;

						const buf = signature
							? Buffer.from(
									signature.replace(/^data:image\/\w+;base64,/, ""),
									"base64"
							  )
							: null;

						if (type === "create") {
							//create daily sheet
							// upload signature to s3
							if (!clerking && buf) {
								try {
									await uploadFile(buf, key, "image/png", "base64");
								} catch (error) {
									return Alert(
										"There was an error uploading your signature. Please try again"
									);
								}
							}
							dailySheet.signature = clerking ? null : key;
							dailySheet.date = date;
							dailySheet.project = projectID;
							dailySheet.user = suppliedUser ? suppliedUser.id : user.id;
							dailySheet.billingPhase = dailySheet.billingPhase;

							createDailyDocument({
								variables: {
									input: { ...dailySheet, locked: true },
								},
							});
						} else {
							// update daily sheet
							//clear any extra fields that are not needed or that will not be updated
							delete dailySheet.__typename;
							delete dailySheet.user;
							// upload signature to s3
							if (buf) {
								try {
									await uploadFile(buf, key, "image/png", "base64");
									dailySheet.signature = key;
								} catch (error) {
									return Alert(
										"There was an error uploading your signature. Please try again"
									);
								}
							}

							// update daily sheet
							updateDailyDocument({
								variables: {
									input: {
										...dailySheet,
										locked: true,
									},
								},
							});
						}
					}}
				>
					<FieldSet legend="Activity" description="Check all that apply">
						<Checkbox
							label="Moisture Mapping"
							onChange={(e) => {
								setNewDailySheet({
									...dailySheet,
									moistureMapping: e,
								});
							}}
							checkedValue={dailySheet.moistureMapping}
						/>
						<Checkbox
							label="Drydown"
							onChange={(e) => {
								setNewDailySheet({
									...dailySheet,
									dryDown: e,
								});
							}}
							checkedValue={dailySheet.dryDown}
						/>
						<Checkbox
							label="Sampling"
							onChange={(e) => {
								setNewDailySheet({
									...dailySheet,
									sampling: e,
								});
							}}
						/>
						<Checkbox
							label="Admin"
							onChange={(e) => {
								setNewDailySheet({ ...dailySheet, admin: e });
							}}
							checkedValue={dailySheet.admin}
						/>
						<Checkbox
							label="Report Generation"
							onChange={(e) => {
								setNewDailySheet({
									...dailySheet,
									reportGeneration: e,
								});
							}}
							checkedValue={dailySheet.reportGeneration}
						/>
						<TextArea
							label="Briefly Describe Your Activities"
							onChange={(e) => {
								setNewDailySheet({
									...dailySheet,
									activities: e,
								});
							}}
							value={dailySheet.activities ? dailySheet.activities : ""}
						/>
					</FieldSet>
					<View className="py-2"></View>
					<FieldSet legend="Hotel Information">
						<Switch
							onChange={(e) => {
								setHotel(e);
							}}
							value={hotel}
							label="Lodging"
							info="Are you staying at temporary lodging accommodations (hotel, rental home, camper, etc.)?"
						/>

						<>
							{hotel && (
								<>
									<Input
										name="Lodging Name"
										label="Lodging Name"
										placeholder="Marriott"
										onChange={(e) => {
											setNewDailySheet({
												...dailySheet,
												hotelName: e,
											});
										}}
										value={dailySheet.hotelName}
									/>
									<Input
										name="Room Number"
										label="Lodging Number"
										placeholder="910"
										onChange={(e) => {
											setNewDailySheet({
												...dailySheet,
												hotelNumber: e,
											});
										}}
										value={dailySheet.hotelNumber}
									/>
									<Select
										name="Check-In Status"
										options={[
											{ name: "Check-In", value: "Check-In" },
											{ name: "Continued", value: "Continued" },
											{ name: "Check-Out", value: "Check-Out" },
										]}
										onChange={(value) => {
											setNewDailySheet({
												...dailySheet,
												hotelStatus: value.value,
											});
										}}
										defaultValue={dailySheet.hotelStatus}
									/>
								</>
							)}
						</>
					</FieldSet>
					<View className="py-2"></View>
					<>
						{(clerking || !hasPerDiem) && (
							<FieldSet legend="Per Diem Information">
								<Switch
									onChange={(value) => {
										setOnSite(value);
									}}
									value={onSite}
									label="On-Site"
									info="Did you work on-site?"
								/>
								<>
									<Switch
										onChange={(value) => {
											setNewDailySheet({ ...dailySheet, perDiem: value });
										}}
										value={dailySheet.perDiem}
										label="Per Diem"
										info="Did you receive Per Diem?"
									/>
								</>
							</FieldSet>
						)}
					</>
					<View className="py-2"></View>
					{clerking && (
						<>
							{/* {timeEntry.billingPhase && ( */}
							{
								<Select
									name="Billing Phase"
									// defaultValue={getDefaultValue(dailySheet)}
									value={dailySheet.billingPhase}
									onChange={(value) => {
										setNewDailySheet({
											...dailySheet,
											billingPhase: value.value,
										});
									}}
									options={billingPhases.map((billingPhase) => {
										return {
											name: billingPhase.name,
											value: billingPhase.id,
										};
									})}
								/>
							}
							{/* )} */}
						</>
					)}

					<FieldSet legend="Vehicle Information">
						<Switch
							onChange={(e) => {
								setRentalCar(e);
							}}
							value={rentalCar}
							label="Rental Car"
							info="Are you using a rental car"
						/>

						<>
							{rentalCar ? (
								<>
									<Input
										name="Rental Company"
										label="Rental Company"
										placeholder="Avis"
										onChange={(e) => {
											setNewDailySheet({
												...dailySheet,
												rentalCarCompany: e,
											});
										}}
										value={dailySheet.rentalCarCompany}
									/>

									<Input
										name="Rental Car Make"
										label="Rental Car Make"
										placeholder="Jeep"
										onChange={(e) => {
											setNewDailySheet({
												...dailySheet,
												rentalCarMake: e,
											});
										}}
										value={dailySheet.rentalCarMake}
									/>
									<Input
										name="Rental Car Model"
										label="Rental Car Model"
										placeholder="Wrangler"
										onChange={(e) => {
											setNewDailySheet({
												...dailySheet,
												rentalCarModel: e,
											});
										}}
										value={dailySheet.rentalCarModel}
									/>
									<Select
										name="Rental Car Status"
										options={[
											{ name: "Initial Day", value: "Initial Day" },
											{ name: "Continued Day", value: "Continued Day" },
											{ name: "Returned", value: "Returned" },
										]}
										onChange={(value) => {
											setNewDailySheet({
												...dailySheet,
												rentalCarStatus: value.value,
											});
										}}
										defaultValue={dailySheet.rentalCarStatus}
									/>
								</>
							) : (
								<Input
									name="Personal or Company Mileage"
									label="Personal or Company Mileage"
									placeholder="20"
									type="number"
									onChange={(e) => {
										setNewDailySheet({
											...dailySheet,
											personalCompanyMilage: e,
										});
									}}
									helper="If you do not have any milage you can leave this field blank"
								/>
							)}
						</>

						<Checkbox
							label="Fuel"
							description="Did you purchase fuel today"
							onChange={(e) => {
								setNewDailySheet({ ...dailySheet, fuel: e });
							}}
							checkedValue={dailySheet.fuel}
						/>
					</FieldSet>
					<FieldSet legend="Other Billable Expense">
						<Checkbox
							label="Boroscope"
							onChange={(e) => {
								setNewDailySheet({
									...dailySheet,
									boroscope: e,
								});
							}}
							checkedValue={dailySheet.boroscope}
						/>
						<Checkbox
							label="Fall Protection (PFP)"
							onChange={(e) => {
								setNewDailySheet({ ...dailySheet, fallProtection: e });
							}}
							checkedValue={dailySheet.fallProtection}
						/>

						<Checkbox
							label="Matterport"
							onChange={(e) => {
								setNewDailySheet({
									...dailySheet,
									matterport: e,
								});
							}}
							checkedValue={dailySheet.matterport}
						/>
						<Checkbox
							label="Mobile Command Center"
							onChange={(e) => {
								setNewDailySheet({
									...dailySheet,
									mobileCommandCenter: e,
								});
							}}
							checkedValue={dailySheet.mobileCommandCenter}
						/>
						<Checkbox
							label="Moisture Meter"
							onChange={(e) => {
								setNewDailySheet({
									...dailySheet,
									moistureMeter: e,
								});
								setOnMMP(e);
							}}
							checkedValue={dailySheet.moistureMeter}
						/>
						<>
							{onMMP && user.isContractor && (
								<Switch
									onChange={(value) => {
										setNewDailySheet({ ...dailySheet, companyOwnedMMP: value });
									}}
									value={dailySheet.moistureMeter}
									label="Company Owned Moisture Meter"
									info="Did you use a Moisture Meter provided by SRP Environmental?"
								/>
							)}
						</>

						<Checkbox
							label="Respirator (PRP)"
							onChange={(e) => {
								setNewDailySheet({
									...dailySheet,
									respirator: e,
								});
							}}
							checkedValue={dailySheet.respirator}
							//check the value of the respirator and if its true show on the update daily sheet
						/>
						<Checkbox
							label="360 Spherical Camera"
							onChange={(e) => {
								setNewDailySheet({
									...dailySheet,
									sphericalCamera: e,
								});
							}}
							checkedValue={dailySheet.sphericalCamera}
						/>

						<Checkbox
							label="Thermal Imaging Camera"
							description="FLIR"
							onChange={(e) => {
								setNewDailySheet({
									...dailySheet,
									flir: e,
								});
								setOnFlir(e);
							}}
							checkedValue={dailySheet.flir}
						/>
						<>
							{onFlir && user.isContractor && (
								<Switch
									onChange={(value) => {
										setNewDailySheet({
											...dailySheet,
											companyOwnedFlir: value,
										});
									}}
									value={dailySheet.flir}
									label="Company Owned Flir"
									info="Did you use a Flir provided by SRP Environmental?"
								/>
							)}
						</>

						<Checkbox
							label="Thermohygrometer"
							onChange={(e) => {
								setNewDailySheet({ ...dailySheet, thermohygrometer: e }),
									setOnThermo(e);
							}}
							checkedValue={dailySheet.thermohygrometer}
						/>

						<>
							{onThermo && user.isContractor && (
								<Switch
									onChange={(value) => {
										setNewDailySheet({
											...dailySheet,
											companyOwnedHygrometer: value,
										});
									}}
									value={dailySheet.thermohygrometer}
									label="Company Owned Thermohygrometer"
									info="Did you use a Thermohygrometer provided by SRP Environmental?"
								/>
							)}
						</>

						<Checkbox
							label="Trailer Package"
							onChange={(e) => {
								setNewDailySheet({ ...dailySheet, trailerPackage: e });
							}}
							checkedValue={dailySheet.trailerPackage === true ? true : false}
						/>
						<Checkbox
							label="Vehicle"
							onChange={(e) => {
								setNewDailySheet({ ...dailySheet, vehicle: e });
							}}
							checkedValue={dailySheet.vehicle}
						/>
					</FieldSet>
					{!clerking ? (
						<>
							<View className="font-md text-lg text-gray-900 dark:text-white">
								<Text className="text-lg text-gray-900 dark:text-white">
									Signature
								</Text>
							</View>
							{Platform.OS !== "web" ? (
								<View className="flex flex-col justify-center">
									{dailySheet.signature ? (
										<>
											<Image
												source={{ uri: dailySheet.signature }}
												style={{
													height: 200,
												}}
											/>
											<TouchableOpacity onPress={() => setSigning(true)}>
												<Text className="text-indigo-500 font-medium text-lg">
													Resign
												</Text>
											</TouchableOpacity>
										</>
									) : (
										<TouchableOpacity
											onPress={() => {
												setSigning(true);
											}}
										>
											<Text className="text-indigo-500 font-medium text-lg">
												Tap to Sign
											</Text>
										</TouchableOpacity>
									)}
								</View>
							) : (
								<>
									<View className="flex justify-center items-center rounded-lg border-2 border-indigo-500">
										<SignatureCanvas
											ref={(ref) => {
												sigRef = ref;
											}}
											onEnd={() => {
												setNewDailySheet({
													...dailySheet,
													signature: sigRef.toDataURL(),
												});
											}}
											penColor="blue"
											canvasProps={{
												border: "black",

												height: 100,
												width: Dimensions.get("window").width * 0.9,
												className:
													"bg-white border-2 border-gray-500 rounded-lg",
											}}
										/>
									</View>

									<TouchableOpacity onPress={() => sigRef.clear()}>
										<Text className="text-red-500 cursor-pointer">
											Clear Signature
										</Text>
									</TouchableOpacity>
								</>
							)}

							<View className=" text-md text-gray-900 dark:text-white">
								<Text className="text-gray-500">
									A signature is required to create a daily document
								</Text>
							</View>
						</>
					) : null}
				</SlideOver>
			) : (
				<View className="z-50 h-full w-full flex items-center justify-center ">
					<SignatureScreen
						penColor="blue"
						ref={ref}
						onOK={(signature) => {
							setSigning(false);
							setNewDailySheet({ ...dailySheet, signature: signature });
						}}
						onEmpty={() => {
							Alert("Signature Required", "You must sign in the box");
						}}
						onClear={() => {
							setNewDailySheet({ ...dailySheet, signature: null });
						}}
						style={{
							flex: 1,
							height: Dimensions.get("window").height * 0.4,
							width: Dimensions.get("window").width * 0.9,
						}}
					/>
				</View>
			)}
		</>
	);
};

DailySheetSlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	timeDailyItem: PropTypes.object,
	currentDailySheet: PropTypes.object,
	suppliedUser: PropTypes.object,
	onComplete: PropTypes.func.isRequired,
	clerking: PropTypes.bool,
	clerkingData: PropTypes.object,
	currentProject: PropTypes.object,
};

export { DailySheetSlideOver };
