import React from "react";

import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { View } from "react-native";

// components

import { Alert } from "../components/Alert";
import { FileUpload, Input, Select } from "../components/Forms";
import { addNotification } from "../components/Notification";
import SlideOver from "../components/Slideover";

// gql

import { gql, useMutation } from "@apollo/client";
import * as expenseGQL from "../graphql/clerk/expense";

//utils

import { getData, s3 } from "../utils";

/**
 * ExpenseSlideOver
 * @param {object} props - props to be supplied to the slide over
 * @param {object} props.currentExpense - the currentExpense to be update (type should be set to update if this prop is passed)
 * @param {string} props.projectID - the current project
 * @param {function} props.onComplete - a function to be called when the slide over has completed the createRequest it will send the moisture point back to the parent component
 * @param {function} props.closeRequest - a function to be called when the slide over been closed by the user
 * @param {boolean} props.isClerkingPage - a boolean to determine if the data should be created or simply passed back to the parent component
 * @param {string} props.type - the type of the slide over (create or update)
 * @param {boolean} props.isOpen - a boolean to determine if the slide over should be shown
 * @returns {object} - JSX
 */

const ExpenseSlideOver = ({
	isOpen,
	closeRequest = () => {},
	type,
	onComplete,
	isClerkingPage,
	projectID,
	currentExpense,
	currentExpenseType,
	defaultValues = {},
	users,
	currentProject,
	expenseTypes = [],
}) => {
	const [expense, setExpense] = React.useState({});
	const [user, setUser] = React.useState(null);
	const [billingPhases, setBillingPhases] = React.useState([]);
	const [expenseType, setExpenseType] = React.useState(null);

	React.useEffect(() => {
		if (currentExpense) {
			setExpense(currentExpense);
		} else {
			setExpense({});
		}
	}, [currentExpense]);

	React.useEffect(() => {
		if (currentProject) {
			setBillingPhases([...currentProject.billingPhases]);
		}
	}, [currentProject]);

	React.useEffect(() => {
		if (currentExpenseType) {
			setExpenseType(currentExpenseType);
		}
	}, [currentExpenseType]);

	React.useEffect(() => {
		const getUser = async () => {
			getData("@storage_Key").then((userInfo) => setUser(userInfo));
		};
		getUser();
	}, []);

	const [createExpense] = useMutation(gql(expenseGQL.createExpense), {
		onCompleted: (data) => {
			onComplete(data.createExpense.id);
			setExpense({});
			setExpenseType(null);
			closeRequest();
			addNotification({
				title: "Expense Created",
				text: "The expense was successfully created",
				type: "success",
				time: 5000,
			});
		},
		onError: () => {
			addNotification({
				title: "Something Went Wrong",
				text: "There was an error creating the expense",
				type: "error",
				time: 5000,
			});
		},
	});

	const [updateExpense] = useMutation(gql(expenseGQL.updateExpense), {
		onCompleted: (data) => {
			onComplete(data.updateExpense);
			closeRequest();
			addNotification({
				title: "Expense Updated",
				text: "The expense was successfully updated",
				type: "success",
				time: 5000,
			});
		},
		onError: () => {
			Alert("Couldn't update expense please try again");
		},
	});

	const uploadFile = (file) => {
		return new Promise(async (resolve, reject) => {
			if (typeof file === "string") {
				resolve(file);
			} else if (!expenseType.requiresReceipt && !file) {
				resolve(null);
			} else {
				let key = `project/${projectID}/receipts/${DateTime.now().toISO()}-${
					file.name
				}`;

				let fileData = await fetch(file.uri);
				fileData = await fileData.blob();

				const params = {
					Bucket: "srp-in-field",
					Key: key,
					Body: fileData,
					ACL: "public-read",
				};
				s3.upload(params, function (err, data) {
					if (err) {
						reject(err);
					}
					resolve(data.Location);
				});
			}
		});
	};

	return (
		<SlideOver
			isOpen={isOpen}
			name="New Expense"
			description={"Add a new expense"}
			closeRequest={() => {
				closeRequest();
			}}
			onSubmit={async () => {
				if (!expense.amount && isNaN(expense.amount)) {
					return Alert("Please enter a valid amount");
				}

				if (expenseType && expenseType.requiresReceipt && !expense.receipt) {
					return Alert("Please upload a file");
				}

				if (type === "create") {
					// create expense
					uploadFile(expense.receipt)
						.then((s3Location) => {
							createExpense({
								variables: {
									input: {
										project: projectID,
										user: isClerkingPage ? expense.user : user.id,
										billingPhase: expense.billingPhase,
										expenseType: expense.expenseType.id,
										amount: expense.amount,
										description: expense.description,
										date: expense.date,
										receipt: s3Location,
									},
								},
							});
						})
						.catch(() => {
							Alert("Your receipt image could not be saved please try again!");
						});
				} else {
					uploadFile(expense.receipt).then((s3Location) => {
						updateExpense({
							variables: {
								input: {
									...expense,
									expenseType: expenseType.id,
									project: projectID,
									receipt: s3Location,
								},
							},
						});
					});
				}
			}}
		>
			<Input
				type="date"
				label="Date of Transaction"
				value={expense.date}
				onChange={(e) => setExpense({ ...expense, date: e })}
			/>
			<>
				{isClerkingPage ? (
					<Select
						name="User"
						defaultValue={defaultValues.user}
						options={
							users
								? users
										.filter((user) => user.isTeamMember || user.isContractor)
										.sort((a, b) => {
											if (a.firstName > b.firstName) return 1;
											if (a.firstName < b.firstName) return -1;
											return 0;
										})
										.map((user) => {
											return {
												name: `${user.firstName} ${user.lastName}`,
												value: user.id,
											};
										})
								: []
						}
						onChange={(value) => {
							setExpense({ ...expense, user: value.value });
						}}
					/>
				) : null}
			</>

			{/* {expense.billingPhase && ( */}
			{
				<Select
					name="Billing Phase"
					// defaultValue={getDefaultValue(dailySheet)}
					onChange={(value) => {
						setExpense({
							...expense,
							billingPhase: value.value,
						});
					}}
					options={billingPhases.map((billingPhase) => {
						return {
							name: billingPhase.name,
							value: billingPhase.id,
						};
					})}
				/>
			}
			{/* )} */}
			<Input
				label="Amount"
				required
				type="number"
				value={expense.amount}
				onChange={(e) => setExpense({ ...expense, amount: e })}
			/>
			<Input
				label="Description"
				value={expense.description}
				onChange={(e) => setExpense({ ...expense, description: e })}
			/>

			<Select
				name="Expense Type"
				defaultValue={defaultValues.expenseType}
				options={
					expenseTypes
						? expenseTypes
								.sort((a, b) => {
									if (a.name > b.name) return 1;
									if (a.name < b.name) return -1;
									return 0;
								})
								.map((expenseType) => {
									return {
										name: expenseType.name,
										value: expenseType,
									};
								})
						: null
				}
				onChange={(value) => {
					setExpense({ ...expense, expenseType: value.value });
					setExpenseType(value.value);
				}}
			/>

			<View className="mt-4">
				<FileUpload
					label="Upload Receipt"
					multiple={false}
					onFiles={(files) => {
						setExpense({ ...expense, receipt: files[0] });
					}}
				/>
				{expenseType
					? expenseType.requiresReceipt === true
						? "Receipt is required"
						: "Receipt is not required"
					: ""}{" "}
			</View>
		</SlideOver>
	);
};

ExpenseSlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	isClerkingPage: PropTypes.bool,
	projectID: PropTypes.string,
	currentExpense: PropTypes.object,
	defaultValues: PropTypes.object,
	currentProject: PropTypes.object,
	users: PropTypes.array,
	expenseTypes: PropTypes.array,
	currentExpenseType: PropTypes.object,
};

export { ExpenseSlideOver };
