import { Payroll } from "../views/FinanceHRCenter/Payroll";
import { PTOAnalysis, UserPTOView } from "../views/FinanceHRCenter/PTOAnalysis";
import { TimeAnalysis } from "../views/FinanceHRCenter/TimeAnalysis";
import { MultiTimeEntries } from "../views/FinanceHRCenter/MultiTimeEntries";
import { UserTime } from "../views/FinanceHRCenter/UserTime";

//Icons

import {
	faClock,
	faMoneyCheck,
	faSwimmingPool,
} from "@fortawesome/pro-duotone-svg-icons";

export const financeAndHrCenterNav = [
	{
		name: "Payroll",
		layout: "FinanceHRCenter",
		component: Payroll,
		path: "/payroll",
		icon: faMoneyCheck,
	},
	{
		name: "PTO Analysis",
		layout: "FinanceHRCenter",
		component: PTOAnalysis,
		path: "/pto-analysis",
		icon: faSwimmingPool,
	},

	{
		name: "Time Analysis",
		layout: "FinanceHRCenter",
		component: TimeAnalysis,
		path: "/time-analysis",
		icon: faClock,
	},
	{
		name: "Add Multiple Time Entries",
		layout: "FinanceHRCenter",
		component: MultiTimeEntries,
		path: "/multi-time-entries",
		icon: faClock,
		noShow: true,
	},
	{
		name: "User Time",
		layout: "FinanceHRCenter",
		component: UserTime,
		path: "/user-time",
		noShow: true,
	},

	{
		name: "User PTO",
		layout: "FinanceHRCenter",
		component: UserPTOView,
		path: "/user-pto/:id",
		noShow: true,
	},
];
