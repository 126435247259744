import { useNavigation } from "@react-navigation/native";
import React from "react";

// navigation
import { Navigation as NavigationContainer } from "../components/Navigation";
import { clerkNav } from "../routes/clerk";

// utils
import { getData } from "../utils";

export const ClerkLayout = () => {
	const navigation = useNavigation();
	React.useEffect(() => {
		// get user and redirect if logged in
		const getLocalUserDetails = async () => {
			await getData("@storage_Key").then((user) => {
				if (
					(user === "null" ||
						user === null ||
						user === undefined ||
						!user.token) &&
					!user.isClerk
				) {
					return navigation.navigate("App");
				}
			});
		};
		getLocalUserDetails();
	});
	return (
		<>
			<NavigationContainer routes={clerkNav} layoutName="Clerk and PC Center" />
		</>
	);
};
