import React from "react";
import PropTypes from "prop-types";
import { TouchableOpacity, Text } from "react-native";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";

export default function Button({
	text,
	type = "button",
	size = "md",
	variant = "indigo",
	onPress,
	disabled,
	testID,
	icon,
}) {
	const sizing = {
		tiny: { x: "px-1.5", y: "py-0.5", textSize: "text-[6px]", icon: 5 },
		xs: { x: "px-2.5", y: "py-1.5", textSize: "text-xs", icon: 10 },
		sm: { x: "px-3", y: "py-2", textSize: "text-sm leading-4", icon: 15 },
		md: { x: "px-4", y: "py-2", textSize: "text-sm", icon: 20 },
		lg: { x: "px-4", y: "py-2", textSize: "text-base", icon: 25 },
		xl: { x: "px-6", y: "py-3", textSize: "text-base", icon: 30 },
	};

	const colors = {
		gray: {
			background: "bg-gray-600",
			focusRing: "focus:ring-gray-500",
			hover: "hover:bg-gray-700",
		},
		red: {
			background: "bg-red-600",
			focusRing: "focus:ring-red-500",
			hover: "hover:bg-red-700",
		},
		yellow: {
			background: "bg-yellow-600",
			focusRing: "focus:ring-yellow-500",
			hover: "hover:bg-yellow-700",
		},
		green: {
			background: "bg-green-600",
			focusRing: "focus:ring-green-500",
			hover: "hover:bg-green-700",
		},
		blue: {
			background: "bg-blue-600",
			focusRing: "focus:ring-blue-500",
			hover: "hover:bg-blue-700",
		},
		indigo: {
			background: "bg-indigo-600",
			focusRing: "focus:ring-indigo-500",
			hover: "hover:bg-indigo-700",
		},
		purple: {
			background: "bg-purple-600",
			focusRing: "focus:ring-purple-500",
			hover: "hover:bg-purple-700",
		},
		pink: {
			background: "bg-pink-600",
			focusRing: "focus:ring-pink-500",
			hover: "hover:bg-pink-700",
		},
	};

	return (
		<TouchableOpacity
			testID={testID ? testID : "button"}
			onPress={() => onPress()}
			type={type}
			disabled={disabled}
			className={`flex flex-row items-center ${sizing[size].x} ${sizing[size].y} border border-transparent  font-medium rounded shadow-sm text-white ${colors[variant].background} ${colors[variant].hover} focus:outline-none focus:ring-2 focus:ring-offset-2 ${colors[variant].focusRing}`}
		>
			{icon && (
				<FontAwesomeIcon icon={icon} color="white" size={sizing[size].icon} />
			)}
			<Text
				testID="button-text"
				className={`${sizing[size].textSize} ${
					icon && "ml-2"
				} font-medium text-white`}
			>
				{text}
			</Text>
		</TouchableOpacity>
	);
}

Button.propTypes = {
	text: PropTypes.string,
	type: PropTypes.oneOf(["button", "submit", "reset"]),
	size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
	variant: PropTypes.oneOf([
		"gray",
		"red",
		"yellow",
		"green",
		"blue",
		"indigo",
		"purple",
		"pink",
	]),
	onPress: PropTypes.func,
	disabled: PropTypes.bool,
	icon: PropTypes.any,
	testID: PropTypes.string,
};
