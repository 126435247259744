export const getDailyDocumentSummary = /* GraphQL */ `
	query GetDailyDocumentSummary($project: ID!, $date: String!) {
		getDailyDocumentSummary(project: $project, date: $date) {
			date
			dailyDocuments {
				accountingPackage
				activities
				admin
				airfare
				airfareBaggageFees
				boroscope
				date
				demobilization
				dryDown
				fallProtection
				flir
				flirSoftware
				fuel
				hammerProbe
				hotelName
				hotelNumber
				hotelStatus
				id
				matterport
				mobileCommandCenter
				mobileHotspot
				mobilization
				moistureMapping
				moistureMappingPackage
				moistureMeter
				perDiem
				personalCompanyMilage
				ppe
				remoteOfficePackage
				rentalCarCompany
				rentalCarMake
				rentalCarModel
				rentalCarStatus
				respirator
				reportGeneration
				sampling
				signature
				sphericalCamera
				thermohygrometer
				timeIn
				timeOut
				trailerPackage
				updateExpiration
				companyOwnedMMP
				companyOwnedFlir
				companyOwnedHygrometer
				vehicle
				user {
					id
					firstName
					lastName
					fullName
				}
				locked
			}
			missingDailyDocuments {
				id
				firstName
				lastName
				fullName
				profilePicture
				role
			}
			project {
				id
				title
			}
		}
	}
`;

export const listDailyDocuments = /* GraphQL */ `
	query listDailyDocuments(
		$filter: TableDailyDocumentFilterInput!
		$limit: Int
		$nextToken: String
	) {
		listDailyDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
			}
		}
	}
`;

export const bulkUpdateDailyDocuments = /* GraphQL */ `
	mutation BulkUpdateDailyDocuments($input: [UpdateDailyDocumentInput!]!) {
		bulkUpdateDailyDocuments(input: $input) {
			id
		}
	}
`;

export const updateDailyDocument = /* GraphQL */ `
	mutation UpdateDailyDocument($input: UpdateDailyDocumentInput!) {
		updateDailyDocument(input: $input) {
			id
			user {
				fullName
			}
		}
	}
`;

export const deleteDailyDocument = /* GraphQL */ `
	mutation DeleteDailyDocument($input: DeleteDailyDocumentInput!) {
		deleteDailyDocument(input: $input) {
			id
		}
	}
`;

export const getDailyDocument = /* GraphQL */ `
	query GetDailyDocument($id: ID!) {
		getDailyDocument(id: $id) {
			accountingPackage
			activities
			admin
			airfare
			airfareBaggageFees
			boroscope
			date
			demobilization
			dryDown
			fallProtection
			flir
			flirSoftware
			fuel
			hammerProbe
			hotelName
			hotelNumber
			hotelStatus
			id
			matterport
			mobileCommandCenter
			mobileHotspot
			mobilization
			moistureMapping
			moistureMappingPackage
			moistureMeter
			perDiem
			personalCompanyMilage
			ppe
			remoteOfficePackage
			rentalCarCompany
			rentalCarMake
			rentalCarModel
			rentalCarStatus
			respirator
			reportGeneration
			sampling
			signature
			sphericalCamera
			thermohygrometer
			timeIn
			timeOut
			trailerPackage
			updateExpiration
			companyOwnedMMP
			companyOwnedFlir
			companyOwnedHygrometer
			vehicle
			user {
				id
				firstName
				lastName
				fullName
			}
			locked
			project {
				id
				title
			}
		}
	}
`;
