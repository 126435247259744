/* This example requires Tailwind CSS v2.0+ */

import React from "react";
import {
	View,
	Text,
	TouchableOpacity,
	Platform,
	ScrollView,
} from "react-native";
import PropTypes from "prop-types";
import { useNavigation } from "@react-navigation/native";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faChevronLeft, faBars } from "@fortawesome/pro-duotone-svg-icons";

// Lighting
import { useColorScheme } from "nativewind";

export function PageHeader({
	title,
	description,
	info,
	buttons,
	options,
	openMenu,
	goBack,
}) {
	const { colorScheme } = useColorScheme();
	const navigation = useNavigation();

	if (Platform.OS === "web") {
		return (
			<View className="w-full px-4 py-4  flex-row items-center justify-between dark:text-gray-900">
				<View className="flex-1 min-w-0">
					<View className="flex flex-row items-center">
						<TouchableOpacity
							className="mr-2"
							onPress={() => {
								navigation.openDrawer();
							}}
						>
							<FontAwesomeIcon
								size={20}
								aria-hidden="true"
								icon={faBars}
								color={colorScheme === "dark" ? "#fff" : "#000"}
							/>
						</TouchableOpacity>
						<View>
							<Text
								testID="title-text"
								className="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:text-3xl sm:truncate"
							>
								{title}
							</Text>
							{description && (
								<Text
									testID="description-text"
									className="font-normal text-gray-900 dark:text-white "
								>
									{description}
								</Text>
							)}
						</View>
					</View>

					<View className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
						{info &&
							info.map((item, index) => {
								return (
									<View key={index} className="mt-2 flex-row items-center mr-1">
										<FontAwesomeIcon
											aria-hidden="true"
											icon={item.icon}
											color={colorScheme === "light" ? "#111827" : "#fff"}
										/>
										<Text className="text-sm text-gray-500 ml-1">
											{item.text}
										</Text>
									</View>
								);
							})}
					</View>
				</View>
				<View className=" flex flex-row items-center lg:mt-0 lg:ml-4 justify-end ">
					{buttons &&
						buttons.map((button, index) => {
							if (!button.name || !button.onPress) {
								return null;
							}
							return (
								<View key={`${index}-full`}>
									<View className="hidden  md:flex">
										<TouchableOpacity
											testID="header-button"
											onPress={() => {
												button.onPress();
											}}
											type="button"
											className="flex-row items-center ml-2 px-4 py-3 border border-gray-300 rounded-md shadow-sm bg-white dark:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{button.icon && (
												<FontAwesomeIcon
													icon={button.icon}
													size={20}
													color={colorScheme === "light" ? "#111827" : "#fff"}
												/>
											)}
											<Text className="pl-2 text-sm font-medium text-gray-700 dark:text-white">
												{button.name}
											</Text>
										</TouchableOpacity>
									</View>

									<View className="block md:hidden ml-2">
										<TouchableOpacity
											testID="header-button"
											onPress={() => {
												button.onPress();
											}}
											type="button"
											className="flex-row items-center px-2 py-2 border border-gray-300 rounded-md shadow-sm bg-white dark:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{button.icon && (
												<FontAwesomeIcon
													icon={button.icon}
													size={20}
													color={colorScheme === "light" ? "#111827" : "#fff"}
												/>
											)}
										</TouchableOpacity>
									</View>
								</View>
							);
						})}

					{options}
				</View>
			</View>
		);
	}
	return (
		<View className="flex flex-col px-4 py-4 mt-8 md:mt-4 w-full  ">
			<View className="w-full  flex-row items-center align-middle justify-between dark:text-gray-900">
				{openMenu && (
					<TouchableOpacity
						className="mr-2"
						onPress={() => {
							navigation.openDrawer();
						}}
					>
						<FontAwesomeIcon
							size={20}
							aria-hidden="true"
							icon={faBars}
							color={colorScheme === "dark" ? "#fff" : "#000"}
						/>
					</TouchableOpacity>
				)}

				{goBack && (
					<TouchableOpacity
						className="mr-2"
						onPress={() => {
							navigation.navigate(goBack.location, goBack.params);
						}}
					>
						<FontAwesomeIcon
							size={20}
							aria-hidden="true"
							icon={faChevronLeft}
							color={colorScheme === "dark" ? "#fff" : "#000"}
						/>
					</TouchableOpacity>
				)}

				<View className=" flex-1 min-w-0">
					<Text
						testID="title-text"
						className="text-xl md:text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:text-3xl sm:truncate"
					>
						{title}
					</Text>
					{description && (
						<Text
							testID="description-text"
							className="font-normal text-gray-900 dark:text-white "
						>
							{description}
						</Text>
					)}
				</View>
				<View className=" flex flex-row items-center lg:mt-0 lg:ml-4 justify-end ">
					{buttons &&
						buttons.map((button, index) => {
							if (!button.name || !button.onPress) {
								return null;
							}
							return (
								<View key={index} className="ml-1">
									<View className="hidden  md:flex">
										<TouchableOpacity
											testID="header-button"
											onPress={() => {
												button.onPress();
											}}
											type="button"
											className="flex-row items-center px-4 py-3 border border-gray-300 rounded-md shadow-sm bg-white dark:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{button.icon && (
												<FontAwesomeIcon
													icon={button.icon}
													size={20}
													color={colorScheme === "light" ? "#111827" : "#fff"}
												/>
											)}
											<Text className="pl-2 text-sm font-medium text-gray-700 dark:text-white">
												{button.name}
											</Text>
										</TouchableOpacity>
									</View>

									<View className="block md:hidden">
										<TouchableOpacity
											testID="header-button"
											onPress={() => {
												button.onPress();
											}}
											type="button"
											className="flex-row items-center px-2 py-2 border border-gray-300 rounded-md shadow-sm bg-white dark:bg-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{button.icon && (
												<FontAwesomeIcon
													icon={button.icon}
													size={20}
													color={colorScheme === "light" ? "#111827" : "#fff"}
												/>
											)}
										</TouchableOpacity>
									</View>
								</View>
							);
						})}

					{options}
				</View>
			</View>
			<ScrollView
				horizontal
				className="md:flex md:flex-row mt-1  space-x-6 ml-2"
			>
				{info &&
					info.map((item, index) => {
						return (
							<View key={index} className="mt-2 flex-row items-center mr-1">
								<FontAwesomeIcon
									aria-hidden="true"
									icon={item.icon}
									color={colorScheme === "light" ? "#111827" : "#fff"}
								/>
								<Text className="text-sm text-gray-500 ml-1">{item.text}</Text>
							</View>
						);
					})}
			</ScrollView>
		</View>
	);
}

PageHeader.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	info: PropTypes.array,
	buttons: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.object.isRequired,
			name: PropTypes.string,
			onPress: PropTypes.func.isRequired,
		})
	),
	options: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	openMenu: PropTypes.bool,
	goBack: PropTypes.object,
};
