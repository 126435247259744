export const listSOPs = /* GraphQL */ `
	query ListSOPs(
		$filter: TableSOPFilterInput
		$limit: Int
		$nextToken: String
	) {
		listSOPs(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				title
				tags {
					id
					name
					sops {
						id
						title
					}
				}
				attachments {
					id
					name
					attachment
				}
				versions {
					id
					version
					created
					sop {
						id
						title
					}
					public
				}
				publicVersion {
					version
					created
					sop {
						id
						title
					}
				}
			}
		}
	}
`;

export const createSOP = /* GraphQL */ `
	mutation CreateSOP($input: CreateSOPInput!) {
		createSOP(input: $input) {
			id
		}
	}
`;

export const getSOP = /* GraphQL */ `
	query GetSOP($id: ID!) {
		getSOP(id: $id) {
			id
			title
			tags {
				id
				name
				sops {
					id
					title
				}
			}
			attachments {
				id
				name
				attachment
			}
			versions {
				id
				version
				created
				sop {
					id
					title
				}
				public
			}
			publicVersion {
				version
				created
				bodies {
					id
					body
					language
				}
			}
		}
	}
`;

export const updateSOP = /* GraphQL */ `
	mutation UpdateSOP($input: UpdateSOPInput!) {
		updateSOP(input: $input) {
			id
			title
			tags {
				id
				name
				sops {
					id
					name
				}
			}
			attachments {
				id
				name
				attachment
			}
			versions {
				id
				version
				created
				sop
				public
			}
		}
	}
`;

export const deleteSOP = /* GraphQL */ `
	mutation DeleteSOP($input: DeleteSOPInput!) {
		deleteSOP(input: $input) {
			id
		}
	}
`;

export const createSOPActivity = /* GraphQL */ `
	mutation createActivity($input: CreateActivityInput!) {
		createActivity(input: $input) {
			id
			actingUser {
				id
			}
			action
			timestamp
			sop {
				id
			}
		}
	}
`;

export const listSOPActivity = /* GraphQL */ `
	query ListActivities(
		$filter: TableActivityFilterInput
		$limit: Int
		$nextToken: String
	) {
		listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
			id
			actingUser{
				id
			}
			action
			timestamp
		}
	}
`;
