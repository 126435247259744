export const getUser = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			sageIntacct
			projects {
				id
				project {
					id
					title
					stage
					office {
						id
						sageIntacct
						name
					}
					isLargeLoss
				}
			}
			safetyQuestionnaire {
				id
			}
		}
	}
`;

export const bulkCreateTimeEntries = /* GraphQL */ `
	mutation BulkCreateTimeEntries($input: [CreateTimeEntryInput!]!) {
		bulkCreateTimeEntries(input: $input) {
			id
			timeIn
			timeOut
			project {
				id
				title
				isLargeLoss
			}
			approved
			approvedBy {
				id
				firstName
				lastName
			}
			approvedAt
			billingPhase {
				id
				name
				project {
					id
					title
				}
			}
			projectApproved
			projectApprovedBy {
				id
			}
			projectApprovedAt
			user {
				id
				firstName
				lastName
			}
		}
	}
`;

export const bulkUpdateTimeEntries = /* GraphQL */ `
	mutation BulkUpdateTimeEntries($input: [UpdateTimeEntryInput!]!) {
		bulkUpdateTimeEntries(input: $input) {
			id
		}
	}
`;

export const createTimeEntry = /* GraphQL */ `
	mutation CreateTimeEntry($input: CreateTimeEntryInput!) {
		createTimeEntry(input: $input) {
			id
			timeIn
			project {
				id
				title
				isLargeLoss
			}
			approved
			approvedBy {
				id
				firstName
				lastName
			}
			approvedAt
			billingPhase {
				id
				name
				project {
					id
					title
				}
			}
			projectApproved
			projectApprovedBy {
				id
			}
			projectApprovedAt
			user {
				id
				firstName
				lastName
			}
		}
	}
`;

export const updateTimeEntry = /* GraphQL */ `
	mutation UpdateTimeEntry($input: UpdateTimeEntryInput!) {
		updateTimeEntry(input: $input) {
			id
			timeIn
			timeOut
			user {
				id
				firstName
				lastName
			}
			project {
				id
				title
			}
			approved
			approvedBy {
				id
				firstName
				lastName
			}
			approvedAt
			billingPhase {
				id
				name
				project {
					id
					title
				}
			}
			projectApproved
			projectApprovedBy {
				id
			}
			projectApprovedAt
			dailyDocument {
				id
			}
			safetyQuestionnaire {
				id
			}
		}
	}
`;

export const listTimeEntriesBetweenDates = /* GraphQL */ `
	query ListTimeEntriesBetweenDates(
		$user: ID
		$startDate: String!
		$endDate: String!
	) {
		listTimeEntriesBetweenDates(
			user: $user
			startDate: $startDate
			endDate: $endDate
		) {
			items {
				id
				isMobilizing
				isDemobilizing
				isStandby
				timeIn
				timeOut
				approved
				approvedBy {
					id
					firstName
					lastName
				}
				approvedAt
				billingPhase {
					id
					name
					project {
						id
						title
					}
				}
				project {
					id
					title
					isLargeLoss
					sageIntacct
					office {
						sageIntacct
					}
				}
				user {
					id
					firstName
					lastName
					apsEmployeeID
					department
					isSalary
					location {
						name
						sageIntacct
					}
				}
				safetyQuestionnaire {
					id
				}
			}
		}
	}
`;

export const getTimeEntry = /* GraphQL */ `
	query GetTimeEntry($id: ID!) {
		getTimeEntry(id: $id) {
			id
			timeIn
			timeOut
			responded
			billingPhase {
				id
				name
				project {
					id
					title
				}
			}
			project {
				id
				title
			}
			user {
				id
			}
			safetyQuestionnaire {
				id
			}
			dailyDocument {
				id
				date
				boroscope
				timeIn
				timeOut
				perDiem
				signature
				updateExpiration
				moistureMapping
				admin
				dryDown
				sampling
				airfare
				activities
				mobilization
				demobilization
				hotelName
				hotelNumber
				hotelStatus
				personalCompanyMilage
				rentalCarCompany
				rentalCarModel
				rentalCarMake
				rentalCarStatus
				trailerPackage
				fallProtection
				fuel
				accountingPackage
				airfareBaggageFees
				hammerProbe
				flirSoftware
				matterport
				mobileCommandCenter
				mobileHotspot
				moistureMeter
				moistureMappingPackage
				matterport
				boroscope
				moistureMeter
				ppe
				respirator
				remoteOfficePackage
				thermohygrometer
				flir
				locked
			}
		}
	}
`;

export const createDailyDocument = /* GraphQL */ `
	mutation createDailyDocument($input: CreateDailyDocumentInput!) {
		createDailyDocument(input: $input) {
			id
			user {
				id
			}
			project {
				id
			}
		}
	}
`;

export const updateDailyDocument = /* GraphQL */ `
	mutation UpdateDailyDocument($input: UpdateDailyDocumentInput!) {
		updateDailyDocument(input: $input) {
			id
			user {
				id
			}
			project {
				id
			}
		}
	}
`;

export const listTimeEntries = /* GraphQL */ `
	query ListTimeEntries(
		$filter: TableTimeEntryFilterInput
		$limit: Int
		$nextToken: String
	) {
		listTimeEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				timeIn
				timeOut
				isMobilizing
				isDemobilizing
				isStandby
				responded
				approved
				approvedBy {
					id
					firstName
					lastName
				}
				approvedAt
				billingPhase {
					id
					name
					project {
						id
						title
					}
				}
				projectApproved
				projectApprovedBy {
					id
					firstName
					lastName
				}
				projectApprovedAt
				project {
					id
					title
					client {
						name
					}
				}
				user {
					id
				}
				updatedBy {
					firstName
					lastName
				}
				safetyQuestionnaire {
					id
				}
				dailyDocument {
					id
					date
					boroscope
					timeIn
					timeOut
					perDiem
					signature
					updateExpiration
					moistureMapping
					admin
					dryDown
					sampling
					airfare
					activities
					mobilization
					demobilization
					hotelName
					hotelNumber
					hotelStatus
					personalCompanyMilage
					rentalCarCompany
					rentalCarModel
					rentalCarMake
					rentalCarStatus
					trailerPackage
					fallProtection
					fuel
					accountingPackage
					airfareBaggageFees
					hammerProbe
					flirSoftware
					matterport
					mobileCommandCenter
					mobileHotspot
					moistureMeter
					moistureMappingPackage
					ppe
					respirator
					remoteOfficePackage
					thermohygrometer
					flir
				}
			}
			nextToken
		}
	}
`;

export const deleteTimeEntry = /* GraphQL */ `
	mutation DeleteTimeEntry($input: DeleteTimeEntryInput!) {
		deleteTimeEntry(input: $input) {
			id
			timeIn
			timeOut
			user {
				id
				firstName
				lastName
			}
			project {
				id
				title
			}
			approved
			approvedBy {
				id
				firstName
				lastName
			}
			approvedAt
			dailyDocument {
				id
			}
		}
	}
`;

export const deleteDailyDocument = /* GraphQL */ `
	mutation DeleteDailyDocument($input: DeleteDailyDocumentInput!) {
		deleteDailyDocument(input: $input) {
			id
		}
	}
`;

export const onCreateTimeEntry = /* GraphQL */ `
	subscription OnCreateTimeEntry {
		onCreateTimeEntry {
			project {
				id
			}
		}
	}
`;

export const onUpdateTimeEntry = /* GraphQL */ `
	subscription OnUpdateTimeEntry {
		onUpdateTimeEntry {
			id
			approved
			approvedBy {
				id
				firstName
				lastName
			}
			approvedAt
			project {
				id
			}
			projectApproved
			projectApprovedAt
		}
	}
`;

export const getUserAndTime = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			firstName
			lastName
			profilePicture
			email
			isAdmin
			isManager
			availablePTO
			currentPTOAllotment
			advancableAmount
			manager {
				id
			}
			projects {
				id
				project {
					id
					title
					stage
					workspace {
						id
						title
					}
					isLargeLoss
				}
				isActive
			}
			timeEntries {
				id
				timeIn
				timeOut
				approved
				approvedBy {
					id
					firstName
					lastName
				}
				approvedAt
				billingPhase {
					id
					name
					project {
						id
						title
					}
				}
				project {
					id
					title
					client {
						id
						name
					}
					isLargeLoss
				}
				user {
					id
				}
				updatedBy {
					id
					firstName
					lastName
				}
				dailyDocument {
					id
					date
					activities
				}
			}
		}
	}
`;

export const getUserProjectsAndTime = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			availablePTO
			advancableAmount
			id
			firstName
			lastName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isClerk
			isHR
			isTeamMember
			isContractor
			isLogistic
			isActive
			isSalary
			linkExpiration
			canReviewReports
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			currentYearMaxAllotment
			companyStartDate
			currentPTOAllotment
			isManager
			manager {
				id
				isTimeOverrider
			}
			projects {
				id
				project {
					id
					title
					stage
					workspace {
						id
						title
					}
					isLargeLoss
				}
				isActive
			}
			timeEntries {
				id
				isMobilizing
				isDemobilizing
				isStandby
				timeIn
				timeOut
				approved
				approvedBy {
					id
					firstName
					lastName
				}
				approvedAt
				billingPhase {
					id
					name
					project {
						id
						title
					}
				}
				project {
					id
					title
					client {
						id
						name
					}
					isLargeLoss
				}
				user {
					id
				}
				updatedBy {
					id
					firstName
					lastName
				}
				dailyDocument {
					id
					date
					activities
				}
			}
		}
	}
`;

export const getUserProjects = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			advancableAmount
			id
			fullName
			profilePicture
			email
			phone
			bio
			role
			isAdmin
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isEquipmentManager
			isClerk
			isHR
			isTeamMember
			isContractor
			isLogistic
			isActive
			isSalary
			linkExpiration
			canReviewReports
			emailGeneral
			emailProjects
			emailReports
			emailEquipment
			appNotificationGeneral
			appNotificationProjects
			appNotificationReports
			appNotificationEquipment
			currentYearMaxAllotment
			companyStartDate
			currentPTOAllotment
			isManager
			manager {
				id
			}
			projects {
				id
				project {
					id
					title
					stage
					workspace {
						id
						title
					}
					isLargeLoss
				}
				isActive
			}
		}
	}
`;

export const listDailyDocumentsByDateAndUser = /* GraphQL */ `
	query ListDailyDocumentsByDateAndUser($user: ID, $date: String) {
		listDailyDocumentsByDateAndUser(user: $user, date: $date) {
			id
			perDiem
		}
	}
`;

export const getSingleDayTimeEntry = /* GraphQL */ `
	query getSingleDayTimeEntry($user: ID!, $date: String!) {
		getSingleDayTimeEntry(user: $user, date: $date) {
			id
			timeIn
			timeOut
			dailyDocument {
				id
				activities
			}
			project {
				id
				title
				client {
					id
					name
				}
			}
		}
	}
`;

export const getSingleMonthTimeEntry = /* GraphQL */ `
	query getSingleMonthTimeEntry($user: ID!, $date: String!) {
		getSingleMonthTimeEntry(user: $user, date: $date) {
			id
			timeIn
			timeOut
			dailyDocument {
				id
				activities
			}
			project {
				id
				title
				client {
					id
					name
				}
			}
		}
	}
`;
