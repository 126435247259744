import React from "react";
import { View } from "react-native";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

// Components

import { Alert } from "../../../components/Alert";
import { PageHeader } from "../../../components/Header";
import LoadingScreen from "../../../components/LoadingScreen";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import { addNotification } from "../../../components/Notification";
import SlideOver from "../../../components/Slideover";
import {
	Input,
	TextArea,
	Checkbox,
	FieldSet,
	Switch,
} from "../../../components/Forms";
import DailySheetView from "../../../components/DailySheetView";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import * as projectOperations from "../../../graphql/project";
import { updateDailyDocument } from "../../../graphql/project";

// Utils
import { getData, SentryLogger } from "../../../utils";

//Icons
import {
	faBriefcase,
	faHashtag,
	faMapPin,
	faPencil,
	faRocket,
	faTasks,
} from "@fortawesome/pro-duotone-svg-icons";
// import { current } from "tailwindcss/colors";

export default function SingleDailySheet({ route }) {
	const { id } = route.params;
	let user = getData("@storage_Key").then((user) => user);
	let [dailyDocument, setDailyDocument] = React.useState(null);
	let [newItem, setNewItem] = React.useState(null);
	let [rentalCar, setRentalCar] = React.useState(true);
	let [updatedDailySheet, setUpdatedDailySheet] = React.useState(null);
	let [onFlir, setOnFlir] = React.useState(false);
	let [onMMP, setOnMMP] = React.useState(false);

	const [updateDailySheet] = useMutation(gql(updateDailyDocument), {
		onCompleted: (data) => {
			setDailyDocument(data.updateDailyDocument);
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("There was an error updating the Daily Sheet.");
		},
	});

	const { loading: dailyDocumentLoading } = useQuery(
		gql(projectOperations.getSingleDailySheet),
		{
			variables: {
				id: id,
			},
			onCompleted: (data) => {
				const getCurrentUser = async (data) => {
					const userInfo = await getData("@storage_Key").then((user) => user);
					let team;
					if (user.isClerk) return;
					if (data && data.project.workspace) {
						team = data.project.workspace.team;
					} else {
						team = data.project.team;
					}

					let projectUser = team.find(
						(tm) => tm.teamMember && tm.teamMember.id === userInfo.id
					);

					if (projectUser.length) {
						if (
							!projectUser[0].teamMember.id === user.id ||
							!projectUser[0].isAdmin ||
							!projectUser[0].isSuperAdmin
						) {
							addNotification({
								title: "Unauthorized Access",
								text: "Sorry you must be an admin or super admin to access this project page.",
								type: "error",
								time: 5000,
							});
						}
					} else {
						navigation.navigate("Projects");
						addNotification({
							title: "Unauthorized Access",
							text: "Sorry you are not authorized to access this project. You must be added to the project team before you can access it",
							type: "error",
							time: 5000,
						});
					}
				};

				setDailyDocument(data.getDailyDocument);
				getCurrentUser(data.project);
			},
			onError: (error) => {
				SentryLogger(JSON.stringify(error, null, 2));
			},
		}
	);

	if (dailyDocumentLoading) {
		return <LoadingScreen loadItem={"Daily Sheet"} />;
	}

	const getButtons = () => {
		let button = [];
		if (
			dailyDocument.updateExpiration &&
			DateTime.fromISO(dailyDocument.updateExpiration).ts > DateTime.now().ts
		) {
			button.push({
				name: "Update Daily Sheet",
				onClick: () => {
					setNewItem("updatedDailySheet");
				},
				icon: faPencil,
			});
		}

		return button;
	};
	return (
		<>
			<Breadcrumb className="mt-2">
				<>
					{dailyDocument.project.workspace && (
						<Crumb
							name={dailyDocument.project.workspace.title}
							location="Project"
							params={{ id: dailyDocument.project.workspace.id }}
							//href={`/app/project/${dailyDocument.project.workspace.id}`}
						/>
					)}
				</>

				<Crumb
					name={dailyDocument.project.title}
					location="Project"
					params={{ id: dailyDocument.project.id }}
					//href={`/app/project/${dailyDocument.project.id}`}
				/>
				<Crumb
					name="Project Daily Documents"
					location="Single Time Entry"
					params={{ id: dailyDocument.id }}

					//href={`/app/project/all-daily-documents/${dailyDocument.id}`}
				/>
			</Breadcrumb>
			<PageHeader
				title="Daily Document"
				buttons={getButtons()}
				info={[
					{
						text: dailyDocument.project.title,
						icon: faRocket,
					},
					{
						text: dailyDocument.project.workspace
							? dailyDocument.project.workspace.client &&
							  dailyDocument.project.workspace.client.name
								? dailyDocument.project.workspace.client.name
								: "No Client Name"
							: dailyDocument.project.client &&
							  dailyDocument.project.client.name
							? dailyDocument.project.client.name
							: "No Client Name",
						icon: faBriefcase,
					},
					{
						text:
							dailyDocument.project.location &&
							dailyDocument.project.location.name
								? dailyDocument.project.location.name
								: "No Location Name",
						icon: faMapPin,
					},
					{
						text: dailyDocument.project.stage
							? dailyDocument.project.stage
							: "No Stage Set",
						icon: faTasks,
					},
					{
						text: dailyDocument.project.number
							? dailyDocument.project.number
							: "No Project Number",
						icon: faHashtag,
					},
				]}
				goBack={{
					location: "Project",
					params: { id: dailyDocument.project.id },
				}}
			/>

			<DailySheetView
				timeEntry={{ dailyDocument: dailyDocument }}
				showTimeEntryDetails={false}
			/>

			<View className="m-1 w-40">
				<SlideOver
					isOpen={newItem === "updatedDailySheet"}
					name={"Edit Daily Sheet"}
					description={
						"Fill out a daily form for each project you were on today"
					}
					closeRequest={() => setNewItem(null)}
					onSubmit={(e) => {
						e.preventDefault();
						updatedDailySheet.id = id;
						updatedDailySheet.updateExpiration = null;
						updateDailySheet({
							variables: {
								input: updatedDailySheet,
							},
						});
					}}
				>
					{/* <Input
						className="ml-1 w-full"
						required
						disabled
						type="date"
						// value={DateTime.now().format("dd/LL/yyyy")}
						name="date"
						label="Date"
						icon={"calendar-day"}
						helper="This date cannot be changed. If you need to create a sheet for a different day you must contact an admin"
					/> */}

					<FieldSet legend="Activity" description="Check all that apply">
						<Checkbox
							label="Moisture Mapping"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									moistureMapping: e,
								});
							}}
						/>
						<Checkbox
							label="Drydown"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									dryDown: e,
								});
							}}
						/>
						<Checkbox
							label="Sampling"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									sampling: e,
								});
							}}
						/>
						<Checkbox
							label="Admin"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									admin: e,
								});
							}}
						/>
						<TextArea
							label="Briefly Describe Your Activities"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									activities: e.target.value,
								});
							}}
						/>
					</FieldSet>
					<FieldSet legend="Travel">
						<Switch
							label="Mobilization"
							info="Did you mobilize to this project today"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									mobilization: e,
								});
							}}
						/>
						<Switch
							label="Demobilization"
							info="Did you demobilize from this project today"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									demobilization: e,
								});
							}}
						/>
					</FieldSet>
					<FieldSet legend="Hotel">
						<Input
							name="Hotel Name"
							label="Hotel Name"
							placeholder="Marriott"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									hotelName: e,
								});
							}}
						/>
						<Input
							name="Room Number"
							label="Hotel Number"
							placeholder="910"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									hotelNumber: e,
								});
							}}
						/>
					</FieldSet>
					<FieldSet legend="Vehicle Information">
						<Switch
							onChange={(e) => {
								setRentalCar(e);
							}}
							value={rentalCar}
							label="Rental Car"
							info="Are you using a rental car"
						/>

						{rentalCar ? (
							<>
								<Input
									name="Rental Company"
									label="Rental Company"
									placeholder="Avis"
									onChange={(e) => {
										setUpdatedDailySheet({
											...updatedDailySheet,
											rentalCarCompany: e,
										});
									}}
								/>
								<Input
									name="Rental Car Make"
									label="Rental Car Make"
									placeholder="Jeep"
									onChange={(e) => {
										setUpdatedDailySheet({
											...updatedDailySheet,
											rentalCarMake: e,
										});
									}}
								/>
								<Input
									name="Rental Car Model"
									label="Rental Car Model"
									placeholder="Wrangler"
									onChange={(e) => {
										setUpdatedDailySheet({
											...updatedDailySheet,
											rentalCarModel: e,
										});
									}}
								/>
							</>
						) : (
							<Input
								name="Personal or Company Mileage"
								label="Personal or Company Mileage"
								placeholder="20"
								type="number"
								onChange={(e) => {
									setUpdatedDailySheet({
										...updatedDailySheet,
										personalCompanyMilage: e,
									});
								}}
								helper="If you do not have any milage you can leave this field blank"
							/>
						)}

						<Checkbox
							label="Fuel"
							description="Did you purchase fuel today"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									fuel: e,
								});
							}}
						/>
					</FieldSet>
					<FieldSet legend="Other Billable Expense">
						<Checkbox
							label="Moisture Mapping Package"
							description="Protimeter, Hygrometer, Concrete Meter"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									moistureMappingPackage: e,
								});
								setOnMMP(e);
							}}
						/>
						{onMMP && user.isContractor && (
							<>
								<Switch
									onChange={(value) => {
										setUpdatedDailySheet({
											...updatedDailySheet,
											companyOwnedMMP: value,
										});
									}}
									value={updatedDailySheet.moistureMappingPackage}
									label="Company Owned Moisture Meter"
									info="Did you use a Moisture Meter provided by SRP Environmental?"
								/>
							</>
						)}

						<Checkbox
							label="Per Diem"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									perDiem: e,
								});
							}}
						/>

						<Checkbox
							label="Thermal Imaging Camera"
							description="FLIR"
							onChange={(e) => {
								setUpdatedDailySheet({
									...updatedDailySheet,
									flir: e,
								});
								setOnFlir(e);
							}}
						/>
						{onFlir && user.isContractor && (
							<>
								<Switch
									onChange={(value) => {
										setUpdatedDailySheet({
											...updatedDailySheet,
											companyOwnedFlir: value,
										});
									}}
									value={updatedDailySheet.flir}
									label="Company Owned Flir"
									info="Did you use a Flir provided by SRP Environmental?"
								/>
							</>
						)}
					</FieldSet>

					{/* <FieldSet legend="Signature">
						<p className="text-xs dark:text-white text-gray-900">
						Sign Inside The Red Box
						</p>

						<div
						className="rounded border-gray-500 bg-red-300"
						style={{ width: "100%", height: "auto" }}
						>
						<SignaturePad
							options={{
							penColor: "rgb(0, 0, 0)",
							}}
							redrawOnResize={true}
							ref={sigRef}
						/>
						</div>

						<button
						onClick={() => {
							sigRef.current.clear();
						}}
						>
						Clear Signature
						</button>
					</FieldSet> */}
				</SlideOver>
			</View>
		</>
	);
}

SingleDailySheet.propTypes = {
	route: PropTypes.object,
};
