import React from "react";
import { View, Text } from "react-native";
import { DateTime } from "luxon";

export const AppFooter = () => {
	return (
		<View className="flex flex-col md:flex-row mt-12 pt-8 px-4 md:flex md:items-center justify-start md:justify-between lg:mt-16">
			<Text
				testID="srp-infield-text"
				className="text-base text-gray-400 dark:text-gray-300"
			>
				SRP in Field
				{process.env.REACT_APP_VERSION && process.env.REACT_APP_VERSION}{" "}
				{process.env.REACT_APP_COMMIT && (
					<Text className="text-sm">({process.env.REACT_APP_COMMIT})</Text>
				)}
			</Text>
			<Text
				testID="copyright-text"
				className="text-base text-gray-400 md:mt-0 md:order-1"
			>
				&copy; {DateTime.now().toFormat("yyyy")} SRP Environmental, LLC. All
				rights reserved.
			</Text>
		</View>
	);
};
