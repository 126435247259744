import { useNavigation } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { View, Text } from "react-native";
import Toast from "react-native-toast-message";
import axios from "axios";

// components

import { Alert } from "../../components/Alert";
import Button from "../../components/Button";
import Breadcrumbs, { Crumb } from "../../components/Breadcrumb";
import DailySheetView from "../../components/DailySheetView";
import { Checkbox, Input } from "../../components/Forms";
import { PageHeader } from "../../components/Header";
import { List, ListItem } from "../../components/List";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
import OptionsMenu from "../../components/OptionsMenu";
import SlideOver from "../../components/Slideover";
import {
	API_URL,
	calculateTimeDuration,
	getData,
	sendUpdateEmail,
} from "../../utils";

// Container

import { TimeEntrySlideover } from "../../containers/TimeEntrySlideOver";

//icons

import {
	faEye,
	faPlus,
	faFileExcel,
	faPen,
	faTrash,
	faRectangleHistoryCirclePlus,
} from "@fortawesome/pro-duotone-svg-icons";

// GQL

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as timeEntryGQL from "../../graphql/timeEntry";

//Utils
import { SentryLogger } from "../../utils";

export function UserTime({ route }) {
	const navigation = useNavigation();
	const [user, setUser] = React.useState({});
	const { id } = route.params;
	const [timeUser, setTimeUser] = React.useState({});
	const [newItem, setNewItem] = React.useState("");
	const [updatedTimeEntry, setUpdatedTimeEntry] = React.useState({});
	const [viewTimeEntry, setViewTimeEntry] = React.useState(false);
	const [timeEntries, setTimeEntries] = React.useState([]);
	const [deleteTimeEntryData, setDeleteTimeEntryData] = React.useState(null);
	const [openItem, setOpenItem] = React.useState(null);
	const [timeReportData, setTimeReportData] = React.useState({});
	const [submitting, setSubmitting] = React.useState(false);
	const [nextToken, setNextToken] = React.useState(null);

	const getUserProjects = `
		query getUserProjects($id: ID!) {
			getUser(id: $id) {
				id
				firstName
				lastName
				manager {
					id
				}
				projects {
				 	project {
						id
						title
						stage
						}
					}
				}
			}
	`;
	const listTimeEntries = `
		query listTimeEntries($filter: TableTimeEntryFilterInput, $limit: Int, $nextToken: String, $sort: TimeEntrySortInput) {
			listTimeEntries(filter: $filter, limit: $limit, nextToken: $nextToken, sort: $sort) {
				items {
					id
					approved
					timeIn
					timeOut
					project {
						id
						title
					}
					updatedBy {
						id
						firstName
						lastName
					}
					dailyDocument {
						id
						activities
					}
				}
				nextToken
			}
		}
	`;

	const [updateTimeEntry] = useMutation(gql(timeEntryGQL.updateTimeEntry), {
		onCompleted: (data) => {
			setUpdatedTimeEntry({});
			// find the timeEntry in the list and update it
			const timeEntryIndex = timeEntries.findIndex(
				(te) => te.id === data.updateTimeEntry.id
			);
			if (timeEntryIndex > -1) {
				timeEntries[timeEntryIndex] = data.updateTimeEntry;
				setTimeEntries(timeEntries);
			}
		},
		onError: (error) => {
			SentryLogger(JSON.stringify(error, null, 2));
			Alert("Couldn't update time entry please try again");
		},
	});
	const [getUserCMD, { loading }] = useLazyQuery(gql(getUserProjects), {
		onCompleted: (data) => {
			setTimeUser(data.getUser);
		},
		onError: (error) => {
			Alert("Couldn't load time entries please try again");
			SentryLogger(JSON.stringify(error, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	const [listTimeEntriesCMD] = useLazyQuery(gql(listTimeEntries), {
		onCompleted: (data) => {
			setTimeEntries((timeEntries) => [
				...timeEntries,
				...data.listTimeEntries.items,
			]);

			setNextToken(data.listTimeEntries.nextToken);
		},
		onError: (error) => {
			Alert("Couldn't load time entries please try again");
			SentryLogger(JSON.stringify(error, null, 2));
		},
		fetchPolicy: "cache-and-network",
	});

	// check to make sure user is payroll admin, if not redirect back to last page
	React.useEffect(() => {
		const userInfo = async () => {
			await getData("@storage_Key")
				.then((user) => {
					if (!user || !user.isTimeAdmin) {
						return navigation.goBack();
					}
					setUser(user);
				})

				.catch((err) => {
					SentryLogger(err);
				});
		};
		userInfo();
		setTimeEntries([]);
		setNextToken(null);
		getUserCMD({
			variables: {
				id: id,
			},
		});
		listTimeEntriesCMD({
			variables: {
				filter: {
					user: {
						eq: id,
					},
				},
				sort: {
					field: "timeIn",
					direction: "DESC",
				},
				limit: 50,
			},
		});
	}, [id]);

	/**
	 * getSortedProjects
	 * sorts the projects by title, removes completed projects, and add SRP Environmental to the top of the list
	 * @param {array} projects - the projects to sort
	 * @returns {array} - the sorted projects
	 */

	const getSortedProjects = (projects) => {
		let sortedProjects = [];
		if (projects) {
			sortedProjects = projects
				.filter((pc) => pc.project !== null)
				.filter((pc) => pc.project.stage !== "Complete")
				.sort((a, b) => {
					if (a.project.title > b.project.title) return 1;
					if (a.project.title < b.project.title) return -1;
					return 0;
				});
		}

		sortedProjects.unshift({
			project: {
				id: "0",
				title: "SRP Environmental",
			},
		});

		return sortedProjects.map((pc) => {
			if (pc.project) {
				return {
					title: pc.project.title,
					id: pc.project.id,
					disabled: false,
				};
			}
		});
	};

	const [deleteTimeEntry] = useMutation(gql(timeEntryGQL.deleteTimeEntry), {
		onCompleted: (data) => {
			setDeleteTimeEntryData(null);
			// find the timeEntry in the list and remove it
			const timeEntryIndex = timeEntries.findIndex(
				(te) => te.id === data.deleteTimeEntry.id
			);
			if (timeEntryIndex > -1) {
				timeEntries.splice(timeEntryIndex, 1);
				setTimeEntries(timeEntries);
			}

			if (data.deleteTimeEntry.approved) {
				const previousTimeIn = DateTime.fromISO(
					data.deleteTimeEntry.timeIn
				).toFormat("h:mm a LL/dd/yyyy");
				const previousTimeOut = DateTime.fromISO(
					data.deleteTimeEntry.timeOut
				).toFormat("h:mm a LL/dd/yyyy");
				const updatedTimeIn = "Deleted";
				const updatedTimeOut = "Deleted";
				sendUpdateEmail(
					data.deleteTimeEntry.project.title,
					data.deleteTimeEntry.user,
					"Deleted by authorized Time Overrider",
					user,
					previousTimeIn,
					previousTimeOut,
					updatedTimeIn,
					updatedTimeOut
				);
			}
		},
		onError: (error) => {
			Alert("Error deleting time entry");
			SentryLogger(JSON.stringify(error, null, 2));
		},
	});

	const getTimeEntryOptions = (timeEntry) => {
		let options = [
			{
				name: "View",
				icon: faEye,

				onPress: () => {
					navigation.navigate("Single Time Entry", {
						id: timeEntry.id,
					});
				},
			},
		];

		if (!timeEntry.approved || user.isTimeOverrider) {
			options.push({
				name: "Edit Time Entry",
				icon: faPen,
				onPress: () => {
					setNewItem("edit");
					setUpdatedTimeEntry({
						id: timeEntry.id,
						approved: timeEntry.approved,
						project: timeEntry.project,
						timeIn: timeEntry.timeIn,
						timeOut: timeEntry.timeOut,
						isMobilizing: timeEntry.isMobilizing,
						isDemobilizing: timeEntry.isDemobilizing,
						isStandby: timeEntry.isStandby,
					});
				},
			});
		}

		if (!timeEntry.approved || user.isTimeOverrider) {
			options.push({
				name: "Delete Time Entry",
				icon: faTrash,
				onPress: () => {
					setDeleteTimeEntryData(timeEntry);
				},
			});
		}
		return options;
	};

	if (loading || !timeUser) {
		return <LoadingScreen loadItem={"User"} />;
	}

	return (
		<>
			<Breadcrumbs baseLocation={"Payroll"}>
				<Crumb name="Time Analysis" location={"Time Analysis"} />
				<Crumb
					name={timeUser.firstName + " " + timeUser.lastName}
					location={"User Time"}
					params={{ id: timeUser.id }}
				/>
			</Breadcrumbs>

			<PageHeader
				title={
					timeUser.isContractor
						? "Contractor Time Entries"
						: "Employee Time Entries"
				}
				buttons={
					user.isPayrollAdmin
						? [
								{
									name: "Add Time Entry",
									icon: faPlus,
									onPress: () => setNewItem("newTimeEntry"),
								},
								{
									name: "Add Multiple Time Entries",
									icon: faRectangleHistoryCirclePlus,
									onPress: () =>
										navigation.navigate("Add Multiple Time Entries", {
											id: id,
										}),
								},
						  ]
						: null
				}
				options={
					<OptionsMenu
						options={[
							{
								icon: faFileExcel,
								name: "Time Report",
								onPress: () => {
									setOpenItem("timeReport");
								},
							},
						]}
					/>
				}
				goBack={{ location: "Time Analysis" }}
			/>
			<View className="w-full">
				<List
					headers={[
						"Time In",
						"Time Out",
						"Duration",
						"Project",
						"Updated By",
						"Description",
						"Approved",
					]}
					usesOptions
				>
					{timeEntries
						.sort((a, b) => {
							if (!a.timeIn) return 1;
							if (!b.timeIn) return -1;
							if (a.timeIn > b.timeIn) return -1;
							if (a.timeIn < b.timeIn) return 1;
							return 0;
						})
						.map((timeEntry, index) => {
							return (
								<ListItem
									key={index}
									options={
										<OptionsMenu options={getTimeEntryOptions(timeEntry)} />
									}
									items={[
										{
											content: DateTime.fromISO(timeEntry.timeIn).toFormat(
												"EEEE, MMMM dd, yyyy h:mm a ZZZZ"
											),
										},

										{
											content: DateTime.fromISO(timeEntry.timeOut).toFormat(
												"EEEE, MMMM dd, yyyy h:mm a ZZZZ"
											),
										},
										{
											content: timeEntry.timeOut
												? calculateTimeDuration(
														timeEntry.timeIn,
														timeEntry.timeOut
												  )
												: "Clocked In",
										},
										{
											content: timeEntry.project ? timeEntry.project.title : "",
										},
										{
											content: timeEntry.updatedBy
												? `${timeEntry.updatedBy.firstName} ${timeEntry.updatedBy.lastName}`
												: "",
										},
										{
											content: timeEntry.dailyDocument
												? timeEntry.dailyDocument.activities
													? timeEntry.dailyDocument.activities
													: "No Description"
												: "No Daily Sheet",
										},
										{
											content: (
												<Checkbox
													checkedValue={timeEntry.approved}
													disabled={timeEntry.approved && !user.isTimeOverrider}
													onChange={() => {
														updateTimeEntry({
															variables: {
																input: {
																	id: timeEntry && timeEntry.id,
																	approved: !timeEntry.approved,

																	approvedBy: user.id,

																	approvedAt: DateTime.now().toISO(),
																},
															},
														});
													}}
												/>
											),
										},
									]}
								/>
							);
						})}
				</List>

				{nextToken && (
					<View className="flex justify-center m-4">
						<Button
							text="Load More"
							className="mt-4"
							onPress={() => {
								listTimeEntriesCMD({
									variables: {
										filter: {
											user: {
												eq: id,
											},
										},
										sort: {
											field: "timeIn",
											direction: "DESC",
										},
										limit: 50,
										nextToken: nextToken,
									},
								});
							}}
						/>
					</View>
				)}
			</View>

			<Modal
				title="Delete Time Entry"
				description="Are you sure you want to delete this time entry? This is permanent and cannot be undone and will also delete any Daily Sheet associated with this time entry."
				isOpen={!!deleteTimeEntryData}
				onClose={() => setNewItem(null)}
				rightButton={{
					text: "Delete Time Entry",
					onPress: () => {
						deleteTimeEntry({
							variables: {
								input: {
									id: deleteTimeEntryData.id,
								},
							},
						});
					},
				}}
			></Modal>

			<Modal
				isOpen={viewTimeEntry !== false}
				onCloseRequest={() => {
					setViewTimeEntry(false);
				}}
				title="Time Entry and Daily Sheet"
			>
				<DailySheetView timeEntry={viewTimeEntry} />
			</Modal>

			<TimeEntrySlideover
				isOpen={newItem === "newTimeEntry"}
				closeRequest={() => setNewItem(true)}
				onComplete={(data) => {
					// find the timeEntry in the list and update it
					const timeEntryIndex = timeEntries.findIndex(
						(te) => te.id === data.id
					);
					if (timeEntryIndex > -1) {
						timeEntries[timeEntryIndex] = data;
						setTimeEntries(timeEntries);
					}
					setNewItem(null);
				}}
				currentTimeEntry={{
					user: id,
				}}
				type="create"
				projects={getSortedProjects(timeUser.projects)}
			/>
			<TimeEntrySlideover
				isOpen={newItem === "edit"}
				closeRequest={() => setNewItem(true)}
				onComplete={(data) => {
					const timeEntryIndex = timeEntries.findIndex(
						(te) => te.id === data.id
					);
					if (timeEntryIndex > -1) {
						timeEntries[timeEntryIndex] = data;
						setTimeEntries(timeEntries);
					}
					setNewItem(null);
				}}
				type="update"
				projects={getSortedProjects(timeUser.projects)}
				currentTimeEntry={{
					id: updatedTimeEntry.id,
					project: updatedTimeEntry.project,
					approved: updatedTimeEntry.approved,
					timeOut: updatedTimeEntry.timeOut,
					timeIn: updatedTimeEntry.timeIn,
					isMobilizing: updatedTimeEntry.isMobilizing,
					isDemobilizing: updatedTimeEntry.isDemobilizing,
					isStandby: updatedTimeEntry.isStandby,
				}}
			/>

			{/*	Time Report Fields */}
			<SlideOver
				name={"Time Report"}
				description={"This will generate a report that will be downloaded"}
				isOpen={openItem === "timeReport"}
				closeRequest={() => setOpenItem(null)}
				buttonRight="Generate Report"
				buttonRightDisabled={submitting}
				onSubmit={() => {
					setSubmitting(true);
					if (!timeReportData.startDate || !timeReportData.startDate) {
						return Toast.show({
							type: "error",
							text1: "Missing Required Fields",
							text2: "You must have the start and end date selected",
						});
					}

					axios
						.post(`${API_URL}/time/time-by-keys`, {
							user: id,
							startDate: timeReportData.startDate,
							endDate: timeReportData.startDate,
							jsonOnly: false,
						})
						.then((data) => {
							if (data.url) {
								// create clickable link to download the item in a new window
								Linking.openURL(data.url);
							}
							setSubmitting(false);
							setOpenItem(null);
						})
						.catch((error) => {
							setSubmitting(false);
							return Toast.show({
								type: "error",
								text1: "Something went wrong",
								text2: error.message,
							});
						});
				}}
			>
				<Input
					label="Start Date"
					type="date"
					onChange={(e) => {
						setTimeReportData({ ...timeReportData, startDate: e });
					}}
				/>
				<Input
					label="End Date"
					type="date"
					onChange={(e) => {
						setTimeReportData({ ...timeReportData, endDate: e });
					}}
				/>

				{submitting && (
					<Text className="dark:text-white">
						Report is Generating. Do not close the window
					</Text>
				)}
			</SlideOver>
		</>
	);
}

UserTime.propTypes = {
	route: PropTypes.object,
	history: PropTypes.shape({
		goBack: PropTypes.func.isRequired,
		push: PropTypes.func.isRequired,
	}),
};
