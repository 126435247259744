import React from "react";
import PropTypes from "prop-types";
import { ScrollView, View } from "react-native";
import { useNavigation } from "@react-navigation/native";

// components
import { Alert } from "../../components/Alert";
import { List, ListItem } from "../../components/List";
import { PageHeader } from "../../components/Header";
import LoadingScreen from "../../components/LoadingScreen";

// GQL
import { useQuery, gql } from "@apollo/client";
import * as reportGQL from "../../graphql/reports";

// Icons
import { faPlus, faPen } from "@fortawesome/pro-solid-svg-icons";
import NoItems from "../../components/NoItems";
import DataContainer, { DataItem } from "../../components/DataDisplay";

export const ReportTypes = () => {
	const navigation = useNavigation();
	const [reportTypes, setReportTypes] = React.useState([]);

	// GQL
	const { loading } = useQuery(gql(reportGQL.listReportTypes), {
		variables: {
			filter: {},
		},
		onCompleted: (data) => {
			setReportTypes([...data.listReportTypes.items]);
		},
	});

	if (loading) {
		return <LoadingScreen loadItem="Report Types" />;
	}

	return (
		<View className="flex-1">
			<PageHeader
				openMenu
				title="Report Types"
				buttons={[
					{
						icon: faPlus,
						onPress: () =>
							Alert("Add Report Type", "This Feature Is Coming Soon"),
						name: "Add Report Type",
					},
				]}
			/>
			{reportTypes.length > 0 ? (
				<List title="Report Types" headers={["Report Name"]}>
					{reportTypes.map((reportType) => (
						<ListItem
							key={reportType.id}
							items={[
								{
									onPress: () => {
										navigation.navigate("Report Type", {
											id: reportType.id,
											name: reportType.name,
										});
									},
									content: reportType.name,
								},
							]}
						/>
					))}
				</List>
			) : (
				<NoItems
					surTitle={"Report Types"}
					title="No Report Types"
					text="You can create a report type by tapping the plus button in the top right corner."
				/>
			)}
		</View>
	);
};

export const ReportType = ({ route }) => {
	const navigation = useNavigation();
	const { id, name } = route.params;
	const [reportType, setReportType] = React.useState({});

	// GQL
	const { loading } = useQuery(gql(reportGQL.getReportTypes), {
		variables: { id: id },
		onCompleted: (data) => {
			setReportType(data.getReportTypes);
		},
	});

	if (loading) {
		return <LoadingScreen loadItem={name} />;
	}

	return (
		<View>
			<View className="flex-1">
				<PageHeader
					goBack={{
						location: "Admin Projects",
					}}
					title={reportType.name}
					buttons={[
						{
							icon: faPen,
							onPress: () =>
								navigation.navigate("ReportType", { reportType: {} }),
							name: "Edit Report Type",
						},
					]}
				/>
			</View>

			<ScrollView>
				<DataContainer>
					<DataItem name="Name" value={reportType.name} />
					<DataItem name="Description" value={reportType.description} />
					<DataItem name="Category" value={reportType.category} />
				</DataContainer>
			</ScrollView>
		</View>
	);
};

ReportType.propTypes = {
	route: PropTypes.object,
};
