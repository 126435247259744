export const createRateField = /* GraphQL */ `
	mutation CreateRateField($input: CreateRateFieldInput!) {
		createRateField(input: $input) {
			id
			projectRole {
				id
				name
			}
			price
			type
		}
	}
`;
export const listRateField = /* GraphQL */ `
	query ListRateFields(
		$filter: TableRateFieldFilterInput
		$limit: Int
		$nextToken: String
	) {
		listRateFields(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				projectRole {
					id
					name
				}
				expenseType {
					id
					name
				}
				price
				type
			}
		}
	}
`;
export const listRateSheets = /* GraphQL */ `
	query ListRateSheets(
		$filter: TableRateSheetFilterInput
		$limit: Int
		$nextToken: String
	) {
		listRateSheets(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				equipment
				subsistence
				rentedItems
				purchasedSAOE
				labAndSub
				active
				client {
					id
					name
				}
			}
		}
	}
`;
export const getRateSheet = /* GraphQL */ `
	query GetRateSheet($id: ID!) {
		getRateSheet(id: $id) {
			id
			name
			active
			date
			usesNewFields
			fields {
				id
				projectRole {
					id
					name
				}
				expenseType {
					id
					name
				}
				price
				type
			}
			dailyDocumentFields {
				id
				name
				type
				amount
			}
			categories {
				id
				name
				description
				markupPercentage
				isPerDiemCategory
				isDailySheetCategory
			}
			expenseTypes {
				id
				name
				sageItemID
				categoryType
				category {
					id
					name
					description
				}
				active
				requiresReceipt
			}

			subcontractor {
				name
			}
			flir
			moistureMeter
			thermohygrometer
			boroscope
			matterport
			sphericalCamera
			fallProtection
			respirator
			trailerPackage
			mobileCommandCenter
			vehicle
		}
	}
`;
export const createRateSheet = /* GraphQL */ `
	mutation CreateRateSheet($input: CreateRateSheetInput!) {
		createRateSheet(input: $input) {
			id
			name
			active
			date
		}
	}
`;

export const listProjectRoles = /* GraphQL */ `
	query ListProjectRoles(
		$filter: TableProjectRoleFilterInput
		$limit: Int
		$nextToken: String
	) {
		listProjectRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
			}
			nextToken
		}
	}
`;

export const listExpenseTypes = /* GraphQL */ `
	query ListExpenseTypes(
		$filter: TableExpenseTypeFilterInput
		$limit: Int
		$nextToken: String
	) {
		listExpenseTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
			}
			nextToken
		}
	}
`;

export const updateRateSheet = /* GraphQL */ `
	mutation UpdateRateSheet($input: UpdateRateSheetInput!) {
		updateRateSheet(input: $input) {
			id
			name
		}
	}
`;

export const updateRateField = /* GraphQL */ `
	mutation UpdateRateField($input: UpdateRateFieldInput!) {
		updateRateField(input: $input) {
			id
		}
	}
`;

export const deleteRateSheet = /* GraphQL */ `
	mutation DeleteRateSheet($input: DeleteRateSheetInput!) {
		deleteRateSheet(input: $input) {
			id
		}
	}
`;

export const deleteRateField = /* GraphQL */ `
	mutation DeleteRateField($input: DeleteRateFieldInput!) {
		deleteRateField(input: $input) {
			id
		}
	}
`;

export const deleteCategory = /* GraphQL */ `
	mutation DeleteCategory($input: CategoryInput!) {
		deleteCategory(input: $input) {
			id
		}
	}
`;

export const deleteExpenseType = /* GraphQL */ `
	mutation DeleteExpenseType($input: DeleteExpenseTypeInput!) {
		deleteExpenseType(input: $input) {
			id
		}
	}
`;

export const createCategory = /* GraphQL */ `
	mutation CreateCategory($input: CreateCategoryInput!) {
		createCategory(input: $input) {
			id
			name
			markupPercentage
			isPerDiemCategory
			isDailySheetCategory
			description
			rateSheet {
				id
			}
		}
	}
`;

export const listCategories = /* GraphQL */ `
	query ListCategories(
		$filter: TableCategoryFilterInput
		$limit: Int
		$nextToken: String
	) {
		listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
			}
			nextToken
		}
	}
`;
