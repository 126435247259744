/* This example requires Tailwind CSS v2.0+ */

import React from "react";
import PropTypes from "prop-types";
import { View, Text } from "react-native";

export default function NoItems({ surTitle, title, text }) {
	return (
		<>
			<View className="bg-white dark:bg-gray-800 h-full flex flex-col justify-center">
				<View className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
					<View className="flex items-center justify-center">
						<Text
							testID="surTitle"
							className="text-base text-center font-semibold text-indigo-600 tracking-wide uppercase"
						>
							{surTitle}
						</Text>
						<Text
							testID="title"
							className="mt-1 text-4xl text-center font-extrabold text-gray-900 dark:text-white sm:text-5xl sm:tracking-tight lg:text-6xl"
						>
							{title}
						</Text>
						<Text
							testID="text"
							className="max-w-xl mt-5 mx-auto text-xl text-gray-500 dark:text-gray-100 text-center"
						>
							{text}
						</Text>
					</View>
				</View>
			</View>
		</>
	);
}

NoItems.propTypes = {
	surTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	text: PropTypes.string,
};
