import React from "react";
import { Text, View } from "react-native";
import * as Linking from "expo-linking";
import Toast from "react-native-toast-message";
import axios from "axios";

// components
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import { PageHeader } from "../../components/Header";
import Modal from "../../components/Modal";
import { Input, Checkbox } from "../../components/Forms";

// icons
import { faFileExcel } from "@fortawesome/pro-duotone-svg-icons";
import { API_URL, SentryLogger } from "../../utils";

export const Payroll = () => {
	const [payrollReportModalOpen, setPayrollReportModalOpen] =
		React.useState(false);
	const [payrollData, setPayrollData] = React.useState({
		start: "",
		end: "",
		allowUnapproved: true,
	});
	const [generating, setGenerating] = React.useState(false);
	const [payrollError, setPayrollError] = React.useState(null);

	const generatePayrollReport = async () => {
		setPayrollError(null);
		setGenerating(true);
		axios
			.post(`${API_URL}/time/payroll-analysis`, {
				start: payrollData.start,
				end: payrollData.end,
				allowUnapproved: payrollData.allowUnapproved,
			})
			.then(function (res) {
				setPayrollReportModalOpen(false);
				Toast.show({
					type: "success",
					text1: "Payroll Report Generated",
					text2:
						"It will download in a few seconds, please check for popup blocker",
				});
				// expo linking open res.url
				Linking.openURL(res.data.url);
			})
			.catch(function (err) {
				SentryLogger(err);
				if (err.status === 400) {
					setPayrollError(
						"Please provide a start and end date that spans a 14 day period"
					);
				} else {
					setPayrollError(err.message);
				}
			})
			.finally(function () {
				setGenerating(false);
			});
	};

	return (
		<>
			<Breadcrumb baseLocation={"Payroll"}>
				<Crumb
					name={"Finance and HR Center"}
					location={"Finance and HR Center"}
				/>
				<Crumb name={"Payroll"} location={"Payroll"} />
			</Breadcrumb>
			<PageHeader
				title={"Payroll"}
				openMenu
				buttons={[
					{
						icon: faFileExcel,
						name: "Generate Payroll Report",
						onPress: () => setPayrollReportModalOpen(true),
					},
				]}
			/>

			<View>
				{/* Pinned Items That Will Show This weeks pto compared to last weeks PTO  */}
			</View>

			<Modal
				isOpen={payrollReportModalOpen}
				closeRequest={() => setPayrollReportModalOpen(false)}
				title={"Generate Payroll Report"}
				description={"Generate a payroll report for the current pay period"}
				closeButton={"Cancel"}
				rightButton={{
					text: "Generate",
					onPress: () => {
						generatePayrollReport();
					},
				}}
			>
				<View className="flex flex-row">
					<View className="flex flex-col">
						<Text className="text-sm text-gray-500">Start Date</Text>
						<Input
							className="mt-1"
							placeholder="Start Date"
							onChange={(e) => setPayrollData({ ...payrollData, start: e })}
							value={payrollData.start}
							type={"date"}
						/>
					</View>
					<View className="flex flex-col ml-2">
						<Text className="text-sm text-gray-500">End Date</Text>
						<Input
							className="mt-1"
							placeholder="End Date"
							onChange={(e) => setPayrollData({ ...payrollData, end: e })}
							value={payrollData.end}
							type={"date"}
						/>
					</View>
				</View>
				<Checkbox
					label="Allow Unapproved Time"
					checkedValue={payrollData.allowUnapproved}
					onChange={(e) =>
						setPayrollData({
							...payrollData,
							allowUnapproved: e,
						})
					}
				/>
				<Text className="text-sm text-indigo-500">
					{generating
						? "Generating Payroll Report. Do not refresh or close the app"
						: ""}
				</Text>
				<Text className="text-sm text-red-500">
					{payrollError ? payrollError : ""}
				</Text>
			</Modal>
		</>
	);
};
