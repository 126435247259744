import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { View } from "react-native";

import * as Linking from "expo-linking";

// Components
import { Alert } from "../components/Alert";
import { Input, Select } from "../components/Forms";
import SlideOver from "../components/Slideover";

// Utils

import { API_URL } from "../utils";

// GQL

import { gql, useQuery } from "@apollo/client";
import * as userGQL from "../graphql/user";

// create a slide over component for PTO Reports
const PTOReportSlideOver = ({
	isOpen,
	closeRequest = () => {},
	onComplete,
}) => {
	const [users, setUsers] = React.useState([]);

	const [ptoReport, setPtoReport] = useState({
		start: DateTime.now().toISO(),
		end: DateTime.now().toISO(),
	});

	//query for all users
	useQuery(gql(userGQL.listUsers), {
		onCompleted: (data) => {
			setUsers([...data.listUsers.items]);
		},
	});

	/**
	 * downloadReport
	 * makes a callout to S3 to download the report for the given dates and users
	 * @param {string} link
	 */

	const downloadReport = async () => {
		if (!ptoReport.start || !ptoReport.end) {
			return Alert("You must select a start and end date");
		}

		await fetch(`${API_URL}/time/pto-analysis`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(ptoReport),
		})
			.then((response) => response.json())
			.then((data) => {
				// setLink with the url and show the webpage

				Linking.openURL(data.url);
				onComplete();
			})
			.catch(() => {
				Alert.alert(
					"Error Generating Report",
					"There was an error generating the report. Try again. If the error persist please contact support at help.srpinfield.com`"
				);
			});
	};

	return (
		<SlideOver
			isOpen={isOpen}
			name={"Create PTO Report"}
			description={"Create a PTO Report"}
			closeRequest={() => {
				closeRequest();
				setPtoReport({});
			}}
			buttonRight="Generate Report"
			onSubmit={async () => {
				// make a call to the API to generate the report time/pto-analysis
				downloadReport();
			}}
		>
			<>
				<View
					// key={index}
					className="m-1 p-1 flex items-center w-full"
				>
					<Input
						label="Start Date"
						type="date"
						value={ptoReport.start}
						onChange={(e) => {
							//set the start date using pto.startDate
							setPtoReport({ ...ptoReport, start: e });
						}}
					/>
					<Input
						label="End Date"
						value={ptoReport.end}
						type="date"
						onChange={(e) => {
							//set the end date using pto.endDate
							setPtoReport({ ...ptoReport, end: e });
						}}
					/>
					<>
						{users && (
							<Select
								name={"Select Users"}
								//select a user from the list of users
								options={
									users &&
									users
										.filter((user) => user.isTeamMember || user.isContractor)
										.map((user, index) => {
											return {
												key: index,
												name: `${user.firstName} ${user.lastName}`,
												id: user.id,
											};
										})
								}
								onChange={(values) => {
									setPtoReport({
										...ptoReport,
										users: values.map((value) => value.id),
									});
								}}
								multiple
							/>
						)}
					</>
				</View>
			</>
		</SlideOver>
	);
};

PTOReportSlideOver.propTypes = {
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func,
	onComplete: PropTypes.func,
};

export { PTOReportSlideOver };
