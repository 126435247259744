export const getProjectReports = /* GraphQL */ `
	query GetProject($id: ID!) {
		getProject(id: $id) {
			id

			title
			workspace {
				id
				title
			}
			reports {
				id
				status
				date
				reportType {
					id
					name
					category
				}
				author {
					firstName
					lastName
				}
				reviewer {
					firstName
					lastName
				}
			}
		}
	}
`;
