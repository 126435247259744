export const createUser = /* GraphQL */ `
	mutation CreateUser($input: CreateUserInput!) {
		createUser(input: $input) {
			id
			firstName
			lastName
			email
		}
	}
`;

export const getUser = /* GraphQL */ `
	query GetUser($id: ID!) {
		getUser(id: $id) {
			id
			apsEmployeeID
			firstName
			lastName
			email
			department
			role
			bio
			phone
			profilePicture
			isTeamMember
			isManager
			isPayrollAdmin
			payrollExempt
			isTimeAdmin
			isTimeOverrider
			isContractor
			isSalary
			isClerk
			isSuperClerk
			employmentType
			isEquipmentManager
			canReviewReports
			isHR
			isAdmin
			subAdmin
			currentYearMaxAllotment
			companyStartDate
			manager {
				id
				firstName
				lastName
				profilePicture
				role
				fullName
			}
			subcontractor {
				id
				name
			}
			client {
				id
				name
			}
			location {
				id
				name
			}
			isActive
			isRequesting
			isManager
			manager {
				id
				firstName
				lastName
			}
			sageIntacct
			apsEmployeeID
		}
	}
`;

export const listUsers = /* GraphQL */ `
	query ListUsers(
		$filter: TableUserFilterInput
		$limit: Int
		$nextToken: String
	) {
		listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				firstName
				lastName
				fullName
				email
				role
				isTimeOverrider
				isSalary
				profilePicture
				isPayrollAdmin
				isTimeAdmin
				isTeamMember
				isManager
				subAdmin
				isContractor
				currentYearMaxAllotment
				companyStartDate

				manager {
					id
					firstName
					lastName
				}
				client {
					id
					name
				}
				location {
					id
					name
				}
				isActive
				isRequesting
			}
			nextToken
		}
	}
`;

export const deleteUser = /* GraphQL */ `
	mutation DeleteUser($input: DeleteUserInput!) {
		deleteUser(input: $input) {
			id
			firstName
			lastName
			email
		}
	}
`;

export const updateUser = /* GraphQL */ `
	mutation UpdateUser($input: UpdateUserInput!) {
		updateUser(input: $input) {
			id
			firstName
			lastName
			email
			department
			apsEmployeeID
			role
			profilePicture
			isPayrollAdmin
			isTimeAdmin
			isTimeOverrider
			isSalary
			isTeamMember
			isManager
			isContractor
			currentYearMaxAllotment
			companyStartDate
			subcontractor {
				id
				name
			}
			isClerk
			isHR
			isAdmin
			subAdmin
			manager {
				id
				firstName
				lastName
			}
			client {
				id
				name
			}
			location {
				id
				name
			}
			isActive
			isRequesting
			isManager
			manager {
				id
				firstName
				lastName
			}
			sageIntacct
			apsEmployeeID
		}
	}
`;
