export const listExpenseTypes = /* GraphQL */ `
	query ListExpenseTypes(
		$filter: TableExpenseTypeFilterInput
		$limit: Int
		$nextToken: String
	) {
		listExpenseTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				sageItemID
				active
			}
		}
	}
`;

export const createExpenseType = /* GraphQL */ `
	mutation CreateExpenseType($input: CreateExpenseTypeInput!) {
		createExpenseType(input: $input) {
			id
			name
			sageItemID
			active
			description
			requiresReceipt
			category {
				id
			}
		}
	}
`;

export const updateExpenseType = /* GraphQL */ `
	mutation UpdateExpenseType($input: UpdateExpenseTypeInput!) {
		updateExpenseType(input: $input) {
			id
			name
			sageItemID
			active
		}
	}
`;

export const deleteExpenseType = /* GraphQL */ `
	mutation DeleteExpenseType($input: DeleteExpenseTypeInput!) {
		deleteExpenseType(input: $input) {
			id
			name
			sageItemID
			active
		}
	}
`;
