import { useNavigation } from "@react-navigation/native";
import React from "react";
import {
	Image,
	KeyboardAvoidingView,
	Text,
	TouchableOpacity,
	View,
} from "react-native";

// Components
import { Alert } from "../../components/Alert";
import Button from "../../components/Button";
import { Input } from "../../components/Forms";

// GQL

import { gql, useMutation } from "@apollo/client";
import * as queries from "../../graphql/user";

// utils

import { API_URL, storeData } from "../../utils";

export const ForgotPassword = () => {
	const navigation = useNavigation();
	let [view, setView] = React.useState("email");
	let [email, setEmail] = React.useState("");
	let [password, setPassword] = React.useState("");
	let [code, setCode] = React.useState("");
	let [error, setError] = React.useState(false);

	let lowerCaseLetters = /[a-z]/g;
	let upperCaseLetters = /[A-Z]/g;
	let numbers = /[0-9]/g;
	let special = /[=+-^$*.{}()?"!@#%&/,><':;|_~`]/g;

	let [generatePasswordResetToken] = useMutation(
		gql(queries.generatePasswordResetToken),
		{
			onCompleted: (data) => {
				let { email, passwordResetToken } = data.generatePasswordResetToken;

				sendVerificationCode(email, passwordResetToken);
			},
			onError: (error) => {
				setError(error.message);
			},
		}
	);

	let [resetPassword] = useMutation(gql(queries.resetPassword), {
		onCompleted: (data) => {
			storeData("@storage_Key", data.resetPassword);
			setView("login");
			navigation.navigate("App");
		},
		onError: (error) => {
			setError(error.message);
		},
	});

	const sendVerificationCode = (email, code) => {
		fetch(`${API_URL}/emails/send-code`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				email,
				code,
			}),
		})
			.then((res) => res.json())
			.then(() => {
				setView("reset");
			})
			.catch(() => {
				Alert(
					"You must reset your password, but we were unable to send the code to your email. Please go to the forgot password page to reset your password ",
					"error"
				);
			});
	};

	return (
		<KeyboardAvoidingView
			behavior="padding"
			className="h-full flex items-center justify-center bg-gray-50 dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8 w-full"
		>
			<View className="max-w-md w-full space-y-8 flex items-center justify-center">
				<View className="flex items-center justify-center ">
					<Image
						className="h-12 w-12"
						source={require("../../assets/srp-icon.png")}
						alt="SRP inField"
					/>
					<Text className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">
						Forgot Your Password?
					</Text>
				</View>

				{view === "email" ? (
					<>
						<Text className="mt-3 text-center  font-normal text-gray-900 dark:text-white">
							Enter your email below. If your account exists we will send a
							verification code that you can enter to reset your password.
						</Text>
						{error && <Text className="text-red-500">{error}</Text>}
						<View className="mt-8 space-y-6 w-full">
							<Input
								label="Email"
								placeholder="user@example.com"
								required
								value={email}
								onChange={(e) => {
									setEmail(e);
								}}
							/>

							<View className="mt-6">
								<Button
									type="submit"
									text="Get Verification Code"
									onPress={() => {
										generatePasswordResetToken({
											variables: {
												email,
											},
										});
									}}
								/>
							</View>
						</View>
					</>
				) : (
					<>
						<Text className="mt-3 text-center  font-normal text-gray-900 dark:text-white">
							Enter your the code that was just sent to your email and the new
							password. If you account exist we will reset your password and log
							you in.
						</Text>
						<>{error && <Text className="text-red-500">{error}</Text>}</>
						<View className="mt-8 space-y-6">
							<Input
								label="Code"
								type="text"
								placeholder="verification Code"
								required
								value={code}
								onChange={(e) => {
									setCode(e);
								}}
							/>
							<Input
								label="New Password"
								placeholder="New Password"
								type="password"
								required
								value={password}
								onChange={(e) => {
									setPassword(e);
								}}
								secureTextEntry
							/>

							<View className="mt-6">
								<Button
									type="submit"
									className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									text="Reset Password"
									onPress={() => {
										if (
											!password.length >= 8 ||
											!password.match(upperCaseLetters) ||
											!password.match(lowerCaseLetters) ||
											!password.match(numbers) ||
											!password.match(special)
										) {
											setError(
												"Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number and one special character"
											);
											return;
										}

										resetPassword({
											variables: {
												email: email,
												password: password,
												code: code,
											},
										});
									}}
								/>
							</View>
						</View>
					</>
				)}
			</View>
			<TouchableOpacity
				onPress={() => {
					navigation.navigate("SignIn");
				}}
			>
				<Text className="mt-6 text-center text-sm font-medium text-indigo-500">
					Back to Login
				</Text>
			</TouchableOpacity>
		</KeyboardAvoidingView>
	);
};
