import { useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ScrollView, Text, View } from "react-native";
import Toast from "react-native-toast-message";

// Components

import { Alert } from "../../components/Alert";
import { Checkbox } from "../../components/Forms";
import { PageHeader } from "../../components/Header";
import { PinnedItem, PinnedList } from "../../components/List";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
import NoItems from "../../components/NoItems";
import OptionsMenu from "../../components/OptionsMenu";
import Pagination from "../../components/Pagination";
import { Table } from "../../components/Table";
import { ProjectSlideOver } from "./../../containers/ProjectSlideOver";

// Utils
import { SentryLogger, getData, projectStages, storeData } from "../../utils";

// gql

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import * as projectsData from "../../graphql/projectsScreen.js";

// Icons

import {
	faEye,
	faFilter,
	faPlus,
	faThumbTack,
	faTimes,
} from "@fortawesome/pro-duotone-svg-icons";
import Button from "../../components/Button";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Home({ search }) {
	const navigation = useNavigation();
	let itemsPerPage = 20;
	const [userData, setUserData] = React.useState({});
	const [projects, setProjects] = React.useState([]);
	const [newItem, setNewItem] = React.useState(null);
	let [currentPage, setCurrentPage] = useState(1);
	let [filter, setFilter] = useState(
		[...projectStages, { name: "No Stage" }].map((stage) => {
			if (stage.name === "Complete") {
				return { name: stage.name, isActive: false };
			}
			return {
				name: stage.name,
				isActive: true,
			};
		})
	);

	let start = currentPage * itemsPerPage - (itemsPerPage - 1);
	let end =
		currentPage * itemsPerPage > projects.length
			? projects.length
			: currentPage * itemsPerPage;

	React.useEffect(() => {
		const userInfo = getData("@storage_Key")
			.then((user) => {
				if (user) {
					setUserData(user);
					getProjects({
						variables: {
							id: user.id,
						},
					});
				} else {
					storeData("@storage_Key", null);
					navigation.navigate("Auth");
				}
			})

			.catch((err) => {
				SentryLogger(err);
			});
		userInfo;
	}, []);

	// queries

	let [getProjects, { loading }] = useLazyQuery(gql(projectsData.getUser), {
		onCompleted: async (data) => {
			setProjects([...data.getUser.projects]);
		},
		onError: (error) => {
			let consolidatedErrors = error.graphQLErrors.map(
				(error) => error.message
			);

			if (consolidatedErrors.includes("Not Found")) {
				Toast.show({
					title: "Account Session Expired",
					description: "Please log in again",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
				storeData("@storage_Key", null);
				return navigation.navigate("Auth");
			} else {
				return SentryLogger(error);
			}
		},
		fetchPolicy: "cache-and-network",
	});

	// mutations
	const [updatePinnedProject] = useMutation(gql(projectsData.updatedPinned), {
		onCompleted: (data) => {
			setProjects(data.updateProjectTeamMember.teamMember.projects);
		},
		onError: (error) => {
			SentryLogger(error);
			Alert("Error Pinning Project. Please try again");
		},
	});

	const navigateToProjectScreen = (id) => {
		return navigation.navigate("Project", {
			id: id,
		});
	};

	const getFilteredProjects = () => {
		return projects

			.filter(
				(project) =>
					project.project !== null &&
					project.project.title.toLowerCase().includes(search)
			)
			.filter((project) => {
				let projectStage = project.project.stage
					? project.project.stage
					: "No Stage";
				let isActiveStage = filter.find((stage) => stage.name === projectStage);
				if (isActiveStage && isActiveStage.isActive) {
					return project;
				}
			})
			.map((project) => {
				let projectStage = project.project.stage
					? project.project.stage
					: "No Stage";
				let color =
					projectStages.find((stage) => stage.value === projectStage)?.color ||
					projectStages[0].color;
				return {
					project: project.project,
					pinned: project.pinned,
					id: project.id,
					color: color,
				};
			});
	};

	if (loading) return <LoadingScreen loadItem="Projects" />;

	const pinnedProjects = projects.filter((project) => project.pinned);
	return (
		<View className="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-white dark:bg-gray-900">
			{/* Page title & actions */}
			<PageHeader
				openMenu
				title="Home"
				buttons={
					userData.isTeamMember
						? [
								{
									name: "New Project",
									icon: faPlus,
									onPress: () => {
										setNewItem("project");
									},
								},
						  ]
						: []
				}
				options={
					<OptionsMenu
						options={[
							{
								name: "Filter Projects",
								icon: faFilter,
								onPress: () => {
									setNewItem("filter");
								},
							},
						]}
					/>
				}
			/>

			<View className="border-b border-gray-200  flex items-center justify-start px-6 lg:px-8"></View>

			{/* Pinned projects */}
			<ScrollView>
				<>
					{pinnedProjects.length ? (
						<PinnedList title={"Projects"}>
							{pinnedProjects.map((project) => {
								return (
									<PinnedItem
										item={project.project}
										key={project.id}
										onPress={() => {
											navigateToProjectScreen(project.project.id);
										}}
										href={`/app/project/${project.project.id}`}
										removedClicked={() => {
											updatePinnedProject({
												variables: {
													input: { id: project.id, pinned: false },
												},
											});
										}}
									/>
								);
							})}
						</PinnedList>
					) : null}

					{/* Project List */}

					{projects.length ? (
						<>
							<Table
								title={"Projects"}
								headers={["Project", "Client", "Stage"]}
								usesOptions
								data={getFilteredProjects().map((projectData) => {
									return {
										onPress: () => {
											navigateToProjectScreen(projectData.project.id);
										},
										title: "Project Information",
										cells: [
											{
												content: (
													<View className="flex flex-row items-center truncate  bg-white dark:bg-gray-900">
														<View
															className={classNames(
																projectData.color
																	? projectData.color
																	: "bg-indigo-500",
																"w-2.5 h-2.5 flex-shrink-0 rounded-full mr-3"
															)}
															aria-hidden="true"
														/>
														<Text className="font-medium truncate text-sm leading-6  text-gray-900 dark:text-white">
															{projectData.project.title}
														</Text>
													</View>
												),
											},
											{
												content:
													projectData.project.client &&
													projectData.project.client.name
														? projectData.project.client.name
														: "No Client Name",
											},

											{
												content: projectData.project.stage
													? projectData.project.stage
													: "No Stage",
											},
										],
										options: [
											{
												icon: faEye,
												name: "Open Project",
												onPress: () => {
													navigateToProjectScreen(projectData.project.id);
												},
											},
											{
												icon: projectData.pinned ? faTimes : faThumbTack,
												name: projectData.pinned
													? "Unpin Project"
													: "Pin Project",
												onPress: () => {
													updatePinnedProject({
														variables: {
															input: {
																id: projectData.id,
																pinned: projectData.pinned ? false : true,
															},
														},
													});
												},
											},
										],
									};
								})}
							/>

							<Pagination
								start={start}
								stop={end}
								total={projects.length}
								nextClicked={() => setCurrentPage(currentPage + 1)}
								previousClicked={() => setCurrentPage(currentPage - 1)}
							/>
						</>
					) : (
						<View className="flex flex-col">
							<View className="flex-2">
								<NoItems
									surTitle="No Projects"
									title="You Have No Projects"
									text={
										userData.isTeamMember
											? "Click the create button above to start a new project"
											: "You must be added to projects by an SRP representative"
									}
								/>
							</View>
							<View className="mx-4">
								<Button
									text="Refresh"
									onPress={() => {
										if (userData) {
											getProjects({
												variables: {
													id: userData.id,
												},
											});
										} else {
											Toast.show({
												title: "Account Session Expired",
												description: "Please log in again",
												status: "error",
												duration: 5000,
												isClosable: true,
											});

											navigation.navigate("Auth");
										}
									}}
								/>
							</View>
						</View>
					)}
				</>
			</ScrollView>

			<ProjectSlideOver
				closeRequest={() => {
					setNewItem(null);
				}}
				type="create"
				isOpen={newItem === "project"}
				onComplete={(id) => {
					setNewItem(null);
					navigation.navigate("Project", { id: id });
				}}
			/>

			<Modal
				isOpen={newItem === "filter"}
				onClose={() => {
					setNewItem(null);
				}}
				title="Filter Projects"
				description="Filter projects by stage"
				size="small"
				icon="filter"
				rightButton={{
					text: "Filter",
					onPress: () => {
						setNewItem(null);
					},
				}}
			>
				{/* Map Project Stages to check boxes */}
				{filter.map((stage) => {
					return (
						<Checkbox
							key={stage.name}
							label={stage.name}
							checkedValue={
								filter.find((filterItem) => filterItem.name === stage.name)
									.isActive
							}
							onChange={() => {
								const filterItem = filter.find(
									(filterItem) => filterItem.name === stage.name
								);
								filterItem.isActive = !filterItem.isActive;

								setFilter(filter);

								// update end value to reflect new filter
							}}
						/>
					);
				})}
			</Modal>
		</View>
	);
}

Home.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
	search: PropTypes.string,
};
