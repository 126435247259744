import React from "react";
import { View, Image } from "react-native";
import PropTypes from "prop-types";

// Components
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import { PageHeader } from "../../components/Header";
import LoadingScreen from "../../components/LoadingScreen";
import User from "../../components/User";

//Containers
import { NoteSlideOver } from "../../containers/NoteSlideOver";

// GraphQL
import { gql, useQuery } from "@apollo/client";
import * as projectGQL from "../../graphql/project";

//Utils
import { SentryLogger, getData, getSignedUrl } from "../../utils";

// Icons
import { faPen } from "@fortawesome/pro-duotone-svg-icons";
import DataContainer, { DataItem } from "../../components/DataDisplay";

export const SafetySingleNote = ({ route, safetyQuestionnaire }) => {
	const { id } = route.params;
	const [note, setNote] = React.useState(null);
	const [noteDate, setNoteDate] = React.useState(null);
	const [newItem, setNewItem] = React.useState(false);
	const [userData, setUserData] = React.useState({});

	React.useEffect(() => {
		const userInfo = getData("@storage_Key")
			.then((user) => {
				setUserData(user);
			})

			.catch((err) => {
				SentryLogger(err);
			});
		userInfo;
	}, []);

	const { loading, refetch } = useQuery(gql(projectGQL.getNote), {
		variables: { id },
		onCompleted: (data) => {
			setNote(data.getNote);
			let newDate = new Date(data.getNote.date).toLocaleString();
			setNoteDate(newDate);
		},
		onError: (error) => {
			SentryLogger(error, "Error in SingleNote");
		},
		fetchPolicy: "cache-and-network",
	});

	if (loading || !note) return <LoadingScreen loadItem={"Single Note"} />;
	return (
		<View>
			<Breadcrumb baseLocation="Dashboard">
				<Crumb name="Reports" location="Safety Reports" />

				<Crumb
					name={note.title}
					location="Single Safety Note"
					params={{ id: note.id }}
				/>
			</Breadcrumb>
			<PageHeader
				title={note.title}
				buttons={
					(userData && userData.id === note.user.id) ||
					(userData && userData.isAdmin === true)
						? [
								{
									name: "Edit Note",
									icon: faPen,
									onPress: () => {
										setNewItem("edit note");
									},
								},
						  ]
						: []
				}
				goBack={{
					location: "Safety Reports",
				}}
			/>

			<View className="flex flex-row">
				<DataContainer
					title={note.title}
					description={`Date Created: ${noteDate ? noteDate : "No Date"}`}
					className="flex flex-row"
				>
					<DataItem name="Description" value={note.description} />
					<DataItem name="Created By" value={<User user={note.user} />} />
					<View>
						<>
							{note.media.map((media, index) => {
								return (
									<Image
										key={index}
										style={{ width: 200, height: 200 }}
										source={{ uri: getSignedUrl(media.key) }}
									/>
								);
							})}
						</>
					</View>
					<Image
						style={{ width: 200, height: 200 }}
						source={{ uri: note.image }}
					/>
				</DataContainer>
			</View>
			<NoteSlideOver
				isOpen={newItem === "edit note"}
				type="update"
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
					refetch();
				}}
				currentNote={note}
				safetyID={safetyQuestionnaire && safetyQuestionnaire.id}
				safetyQuestionnaire={safetyQuestionnaire}
			/>
		</View>
	);
};

SafetySingleNote.propTypes = {
	route: PropTypes.object,
	safetyQuestionnaire: PropTypes.object,
};
