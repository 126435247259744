import React from "react";
import PropTypes from "prop-types";

// SVG

import { Path } from "react-native-svg";
import Badge from "./Badge";

const CustomPath = ({
	symbol,
	middleX,
	middleY,
	dashed,
	points,
	stroke,
	strokeWidth,
	onPress = () => {},
}) => {
	return (
		<>
			<Path

				// Reduce the points to be a string

				d={points
					.map((point, index) => {
						if (index === 0) {
							return `M ${point.x} ${point.y}`;
						}
						return `L${point.x} ${point.y}`;
					})
					.join(" ")}
				fill="none"
				stroke={stroke}
				strokeDasharray={dashed ? "10,10" : "none"}
				strokeWidth={strokeWidth}
				onPress={() => {
					onPress();
				}}
			/>
			{symbol && middleX && middleY ? (
				<Badge symbol={symbol} middleX={middleX} middleY={middleY} />
			) : null}
		</>
	);
};

CustomPath.propTypes = {
	symbol: PropTypes.string,
	middleX: PropTypes.number,
	middleY: PropTypes.number,
	dashed: PropTypes.bool,
	points: PropTypes.array,
	stroke: PropTypes.string,
	strokeWidth: PropTypes.number,
	onPress: PropTypes.func,
};

export default CustomPath;
