import React from "react";
import { ScrollView, View, Text } from "react-native";
import PropTypes from "prop-types";
import Toast from "react-native-toast-message";
import { DateTime } from "luxon";

// components
import Breadcrumb, { Crumb } from "../../components/Breadcrumb";
import { PageHeader } from "../../components/Header";
import { ListItem, List } from "../../components/List";
import Button from "../../components/Button";
import { Table } from "../../components/Table";

// containers
import { PTOAdditionSlideOver } from "../../containers/PTOAdditionSlideOver";
import { PTORequestSlideOver } from "../../containers/PTORequestSlideOver";

//Icons
import {
	faPlus,
	faTrash,
	faCheck,
	faTimes,
	faHashtag,
	faPen,
} from "@fortawesome/pro-duotone-svg-icons";

// GQL
import { gql, useMutation, useQuery } from "@apollo/client";

//SlideOver
import { PTOReportSlideOver } from "../../containers/PTOReportSlideOver";
import { useNavigation } from "@react-navigation/native";
import { faCalendarPlus } from "@fortawesome/pro-thin-svg-icons";

const listUsers = /* GraphQL */ `
	query ListUsers(
		$filter: TableUserFilterInput
		$sort: UserSortInput
		$limit: Int
		$nextToken: String
	) {
		listUsers(
			filter: $filter
			limit: $limit
			nextToken: $nextToken
			sort: $sort
		) {
			items {
				id
				fullName
				profilePicture
				role
				companyStartDate
				isTeamMember
				availablePTO
				mostRecentPTOAddition {
					date
					amount
				}
			}
			nextToken
		}
	}
`;

export function PTOAnalysis() {
	const navigation = useNavigation();
	const [newItem, setNewItem] = React.useState(null);
	const [view, setView] = React.useState("");

	const { data, refetch, error } = useQuery(gql(listUsers), {
		variables: {
			limit: 100,
			sort: { field: "firstName", direction: "ASC" },
			filter: { isTeamMember: true, isActive: true },
		},
	});

	React.useEffect(() => {
		if (error) {
			Toast.show({
				type: "error",
				text1: "There was an error loading the users",
				text2: error.message,
			});
		}
	}, [error]);

	return (
		<View>
			<Breadcrumb>
				<Crumb
					name={"Finance and HR Center"}
					location={"Finance and HR Center"}
				/>
				<Crumb name={"PTO Analysis"} location={"TO Analysis"} />
			</Breadcrumb>
			<PageHeader
				title={"PTO Analysis"}
				buttons={[
					{
						name: "Create PTO Report",
						icon: faPlus,
						onPress: () => {
							setNewItem("Create PTO Report");
						},
					},
					{
						name: "Bulk Add PTO",
						icon: faPlus,
						onPress: () => {
							setView("addPTO");
						},
					},
				]}
				openMenu
			/>

			{view === "addPTO" ? (
				<MultiAddPTO
					onCompleted={() => {
						setView("");
					}}
				/>
			) : (
				<View>
					<List
						headers={["User", "Available PTO", "Last PTO Addition"]}
						onSearch={(e) => {
							if (e) {
								refetch({
									filter: { fullName: { beginsWith: e } },
								});
							} else {
								refetch();
							}
						}}
					>
						{data?.listUsers?.items?.map((user) => {
							const ptoDate = user.mostRecentPTOAddition?.date || null;
							return (
								<ListItem
									key={user.id}
									items={[
										{
											content: user.fullName,
											onPress: () => {
												navigation.navigate("User PTO", {
													id: user.id,
												});
											},
										},
										{
											content: `${user.availablePTO} hour(s)`,
										},
										{
											content: user.mostRecentPTOAddition ? (
												<View>
													<Text className="dark:text-white">
														Amount:{" "}
														{user.mostRecentPTOAddition?.amount ||
															"No Amount Given Yet"}
													</Text>{" "}
													<Text className="dark:text-white">
														Date:{" "}
														{ptoDate
															? DateTime.fromISO(ptoDate).toFormat(
																	"MMMM dd, yyyy"
															  )
															: "No Date"}
													</Text>
												</View>
											) : (
												<Text className="dark:text-white">
													No PTO Additions
												</Text>
											),
										},
									]}
								/>
							);
						})}
					</List>
				</View>
			)}

			<PTOReportSlideOver
				isOpen={newItem === "Create PTO Report"}
				closeRequest={() => {
					setNewItem(null);
				}}
				onComplete={() => {
					setNewItem(null);
				}}
			/>
		</View>

		//SlideOver
	);
}

const MultiAddPTO = ({ onCompleted }) => {
	// get list of users who are team members

	const [bulkPTO, setBulkPTO] = React.useState([]); // [{userId: "123", pto: 5}

	const { data, error } = useQuery(gql(listUsers), {
		variables: {
			filter: { isTeamMember: true },
			sort: { field: "firstName", direction: "ASC" },
		},
	});

	React.useEffect(() => {
		if (error) {
			Toast.show({
				type: "error",
				text1: "There was an error loading the users",
				text2: error.message,
			});
		}
	}, [error]);

	React.useEffect(() => {
		if (data?.listUsers?.items?.length > 0) {
			setBulkPTO(
				data.listUsers.items.map((user) => {
					// try to determine the number of hours to add for each user based on their start date

					const { years, months } = getNumberOfYears(user.companyStartDate);
					let amount = 0;
					if (years === 0 && months >= 6) {
						amount = 56 / 52; // 1.07692307692
					}

					if (years >= 1 && years <= 5) {
						amount = 120 / 52; // 3.07692307692
					}
					if (years >= 6 && years <= 10) {
						amount = 160 / 52;
					}
					if (years >= 11) {
						amount = 200 / 52;
					}
					return {
						user: user,
						amount: amount,
					};
				})
			);
		}
	}, [data]);

	const getNumberOfYears = (date) => {
		// if the date is null, return "Start Date Not Set"
		if (!date) {
			return {
				years: 0,
				months: 0,
				text: "Start Date Not Set",
			};
		}

		const companyStartYear = new Date(date).getFullYear();
		const currentYear = new Date().getFullYear();

		// if the current year is less than the company start year, return "Start Date Not Set"

		if (currentYear < companyStartYear) {
			return {
				years: 0,
				months: 0,
				text: "Start Date Not Set",
			};
		}

		if (currentYear === companyStartYear) {
			// return the number of months at the company
			return {
				years: 0,
				months: new Date().getMonth() - new Date(date).getMonth(),
				text: `${new Date().getMonth() - new Date(date).getMonth()} months`,
			};
		}

		// return the number of years at the company

		const years = currentYear - companyStartYear;
		return {
			years: years,
			months: 0,
			text: `${years} year${years > 1 ? "s" : ""}`,
		};
	};

	//  bulk create PTO additions

	const _bulkCreatePTOAdditions = `
		mutation bulkCreatePTOAdditions($input: [CreatePTOAdditionInput!]!) {
			bulkCreatePTOAdditions(input: $input) {
				id
			}
		}
	`;

	const [bulkCreatePTOAdditions] = useMutation(gql(_bulkCreatePTOAdditions));

	const handleMutationSuccess = () => {
		Toast.show({
			type: "success",
			text1: "PTO Additions Created",
		});
		onCompleted();
	};

	const handleMutationError = (error) => {
		Toast.show({
			type: "error",
			text1: "There was an error creating the PTO Additions",
			text2: error.message,
		});
	};

	return (
		<ScrollView>
			{data?.listUsers?.items?.length > 0 && (
				<Table
					title={"Add PTO"}
					headers={["Name", "Years Worked", "PTO Addition"]}
					data={bulkPTO?.map(({ user, amount }) => {
						return {
							cells: [
								{
									content: user.fullName,
								},
								{
									content: getNumberOfYears(user.companyStartDate).text,
								},
								{
									content: (
										<input
											type="number"
											value={amount}
											onChange={(e) => {
												setBulkPTO(
													bulkPTO.map((item) => {
														if (item.user.id === user.id) {
															return {
																user: user,
																amount: e.target.value,
															};
														}
														return item;
													})
												);
											}}
										/>
									),
								},
							],
						};
					})}
				/>
			)}
			<Button
				text={"Create PTO Additions"}
				onPress={() => {
					bulkCreatePTOAdditions({
						variables: {
							input: bulkPTO
								.filter((item) => item.amount)
								.map(({ user, amount }) => {
									return {
										user: user.id,
										date: new Date().toISOString(),
										amount: amount.toString(),
									};
								}),
						},
					})
						.then(() => handleMutationSuccess())
						.catch((error) => handleMutationError(error));
				}}
			/>
		</ScrollView>
	);
};

MultiAddPTO.propTypes = {
	onCompleted: PropTypes.func,
};

export const UserPTOView = ({ route }) => {
	const HRView = route.name ? route.name === "User PTO" : false;
	const { id } = route.params;
	const [buttonAction, setButtonAction] = React.useState(null);
	const [userData, setUserData] = React.useState({});

	const getButtons = () => {
		let buttons = [
			{
				name: "Create PTO and Approve PTO",
				icon: faPlus,
				onPress: () => {
					setButtonAction("createPTO");
				},
			},
		];

		if (HRView) {
			buttons.push({
				name: "Add PTO Additions",
				icon: faCalendarPlus,
				onPress: () => {
					setButtonAction("createPTOAddition");
				},
			});
		}

		return buttons;
	};

	return (
		<>
			<PageHeader
				title={
					userData && userData.fullName
						? `PTO Data for ${userData.fullName}`
						: "PTO Data"
				}
				buttons={getButtons()}
				info={[
					{
						text: `Current PTO ${userData?.availablePTO || "0"} hour(s)`,
						icon: faHashtag,
					},
					{
						text: `Available PTO For Advancement ${
							userData?.advancableAmount || "0"
						} hour(s)`,
						icon: faHashtag,
					},
				]}
			/>

			<PTODataForUser
				buttonAction={buttonAction}
				onClearButtonAction={() => {
					setButtonAction(null);
				}}
				onUserDataRetrieved={(data) => {
					setUserData(data);
				}}
				HRView={HRView}
				userID={id}
			/>
		</>
	);
};

UserPTOView.propTypes = {
	user: PropTypes.object,
	route: PropTypes.object,
};

export const PTODataForUser = ({
	userID,
	HRView,
	buttonAction,
	onClearButtonAction,
	onUserDataRetrieved,
	readOnlyView = false,
}) => {
	// state variable to hold the user's pto additions
	const [ptoData, setPTOData] = React.useState([]);
	const [ptoNextToken, setPTONextToken] = React.useState(null);
	const [additionsNextToken, setAdditionsNextToken] = React.useState(null);
	const [userData, setUserData] = React.useState({});
	// const [total, setTotal] = React.useState(0);

	const [openItem, setOpenItem] = React.useState(null);
	const [currentItem, setCurrentItem] = React.useState(null);

	// get user pto and pto additions and display them in a table
	const LIST_PTO_AND_ADDITIONS = gql`
		query ListPTOsAndAdditions(
			$userID: ID!
			$filterPTO: TablePTOFilterInput
			$filterAddition: TablePTOAdditionFilterInput
			$limit: Int
			$ptoNextToken: String
			$additionsNextToken: String
		) {
			getUser(id: $userID) {
				id
				fullName
				availablePTO
				advancableAmount
			}
			listPTOs(filter: $filterPTO, limit: $limit, nextToken: $ptoNextToken) {
				items {
					id
					hours
					approved
					startDate
					reason
					bereavementPerson
				}
				total
				nextToken
			}
			listPTOAdditions(
				filter: $filterAddition
				limit: $limit
				nextToken: $additionsNextToken
			) {
				items {
					id
					amount
					type
					date
				}
				total
				nextToken
			}
		}
	`;

	const DELETE_PTO = gql`
		mutation DeletePTO($input: DeletePTOInput!) {
			deletePTO(input: $input) {
				id
			}
		}
	`;

	const DELETE_PTO_ADDITION = gql`
		mutation DeletePTOAddition($input: DeletePTOAdditionInput!) {
			deletePTOAddition(input: $input) {
				id
			}
		}
	`;

	const UPDATE_PTO = gql`
		mutation UpdatePTO($input: UpdatePTOInput!) {
			updatePTO(input: $input) {
				id
			}
		}
	`;

	const handleSuccess = (data) => {
		const { listPTOAdditions, listPTOs } = data;

		// must combine the pto and pto additions into one array
		const pto = listPTOs?.items || [];
		const ptoAdditions = listPTOAdditions?.items || [];

		// set data
		setUserData(data.getUser);
		onUserDataRetrieved(data.getUser);
		setPTONextToken(listPTOs.nextToken);
		setAdditionsNextToken(listPTOAdditions.nextToken);
		setPTOData(() => [...pto, ...ptoAdditions]);
		// setTotal(listPTOs.total + listPTOAdditions.total);
	};

	const handleMutationSuccess = (type, action) => {
		Toast.show({
			type: "success",
			text1: `${type} was successfully ${action}`,
		});
		refetch({
			userID: userID,
			filterPTO: { user: { eq: userID } },
			filterAddition: { user: { eq: userID } },
			limit: 50,
			ptoNextToken: ptoNextToken,
			additionsNextToken: additionsNextToken,
		})
			.then((data) => handleSuccess(data))
			.catch((error) => {
				Toast({
					type: "error",
					text1: "There was an error refetching the PTO",
					text2: error.message,
				});
			});
	};

	const handleMutationError = (type, action, error) => {
		Toast.show({
			type: "error",
			text1: `There was an error ${action} the ${type}`,
			text2: error.message,
		});
	};

	const [deletePTO] = useMutation(DELETE_PTO);
	const [deletePTOAddition] = useMutation(DELETE_PTO_ADDITION);
	const [updatePTO] = useMutation(UPDATE_PTO);
	const { error, refetch } = useQuery(LIST_PTO_AND_ADDITIONS, {
		variables: {
			userID: userID,
			filterPTO: { user: { eq: userID } },
			filterAddition: { user: { eq: userID } },
			limit: 50,
		},
		onCompleted: (data) => handleSuccess(data),
	});

	React.useEffect(() => {
		if (error) {
			Toast.show({
				type: "error",
				text1: "There was an error loading the user info",
				text2: error.message,
			});
		}
	}, [error]);

	React.useEffect(() => {
		if (buttonAction === "createPTO") {
			setOpenItem("createPTO");
			onClearButtonAction();
		}
		if (buttonAction === "createPTOAddition") {
			setOpenItem("createPTOAddition");
			onClearButtonAction();
		}
	}, [buttonAction]);

	const getOptions = (item) => {
		if (readOnlyView) return null;

		if (item.__typename === "PTOAddition" && HRView) {
			return [
				{
					name: "Edit",
					icon: faPen,
					onPress: () => {
						setCurrentItem(item);
						setOpenItem("updatePTOAddition");
					},
				},
				{
					name: "Delete",
					icon: faTrash,
					onPress: () => {
						deletePTOAddition({
							variables: {
								input: {
									id: item.id,
								},
							},
						})
							.then(() => handleMutationSuccess("PTO Addition", "deleted"))
							.catch((error) =>
								handleMutationError("PTO Addition", "deleting", error)
							);
					},
				},
			];
		}

		if (item.__typename === "PTO" && HRView) {
			return [
				{
					name: "Delete",
					icon: faTrash,
					onPress: () => {
						deletePTO({
							variables: {
								input: {
									id: item.id,
								},
							},
						})
							.then(() => handleMutationSuccess("PTO", "deleted"))
							.catch((error) => handleMutationError("PTO", "deleting", error));
					},
				},
				{
					name: "Approve",
					icon: faCheck,
					onPress: () => {
						// approve the pto
						updatePTO({
							variables: {
								input: {
									id: item.id,
									approved: true,
								},
							},
						})
							.then(() => handleMutationSuccess("PTO", "approved"))
							.catch((error) => handleMutationError("PTO", "approving", error));
					},
				},
				{
					name: "Deny",
					icon: faTimes,
					onPress: () => {
						// deny the pto
						updatePTO({
							variables: {
								input: {
									id: item.id,
									approved: false,
								},
							},
						})
							.then(() => handleMutationSuccess("PTO", "denied"))
							.catch((error) => handleMutationError("PTO", "denying", error));
					},
				},
			];
		}

		if (item.__typename === "PTO" && !HRView && item.approved === null) {
			return [
				{
					name: "Approve",
					icon: faCheck,
					onPress: () => {
						// approve the pto
						updatePTO({
							variables: {
								input: {
									id: item.id,
									approved: true,
								},
							},
						})
							.then(() => handleMutationSuccess("PTO", "approved"))
							.catch((error) => handleMutationError("PTO", "approving", error));
					},
				},
				{
					name: "Deny",
					icon: faTimes,
					onPress: () => {
						// deny the pto
						updatePTO({
							variables: {
								input: {
									id: item.id,
									approved: false,
								},
							},
						})
							.then(() => handleMutationSuccess("PTO", "denied"))
							.catch((error) => handleMutationError("PTO", "denying", error));
					},
				},
			];
		}
	};

	return (
		<>
			<Table
				title={"PTO Requests and Additions"}
				headers={["Type", "Date", "Amount", "Approved", "Reason"]}
				data={ptoData
					.sort(
						(a, b) =>
							// if a.date is greater than b.date, return -1
							// if a.date is less than b.date, return 1
							// if a.date is equal to b.date, return 0
							new Date(b.date || b.startDate) - new Date(a.date || a.startDate)
					)
					.map((item) => {
						if (item.__typename === "PTOAddition") {
							return {
								options: getOptions(item, "PTOAddition"),
								cells: [
									{
										content: "Addition",
									},
									{
										content: item.date
											? DateTime.fromISO(item.date).toFormat("MMMM dd, yyyy")
											: "Date Not Set",
									},
									{
										content: `+ ${item.amount}`,
									},
									{
										content: "-",
									},
									{
										content: item.type === "Sick" ? "Sick" : "PTO",
									},
								],
							};
						} else {
							return {
								options: getOptions(item, "PTO"),
								cells: [
									{
										content: "PTO",
									},
									{
										content: item.startDate
											? DateTime.fromISO(item.startDate).toFormat(
													"MMMM dd, yyyy"
											  )
											: "Date Not Set",
									},
									{
										content: `- ${item.hours}`,
									},
									{
										content:
											item.approved === null
												? "Awaiting Manager's Response"
												: item.approved
												? "Approved"
												: "Not Approved",
									},

									{
										content: (
											<View>
												<Text className="dark:text-white">{item.reason}</Text>
												{item.bereavementPerson && (
													<Text className="dark:text-white">{`Bereavement Person: ${item.bereavementPerson}`}</Text>
												)}
											</View>
										),
									},
								],
							};
						}
					})}
			/>

			<PTOAdditionSlideOver
				closeRequest={() => {
					setOpenItem("");
					setCurrentItem(null);
				}}
				type={openItem === "createPTOAddition" ? "create" : "update"}
				open={
					openItem === "createPTOAddition" || openItem === "updatePTOAddition"
				}
				onComplete={() => {
					setOpenItem("");
					setCurrentItem(null);
					refetch({
						userID: userID,
						filterPTO: { user: { eq: userID } },
						filterAddition: { user: { eq: userID } },
						limit: 50,
						ptoNextToken: ptoNextToken,
						additionsNextToken: additionsNextToken,
					})
						.then((data) => handleSuccess(data))
						.catch((error) => {
							Toast({
								type: "error",
								text1: "There was an error refetching the PTO",
								text2: error.message,
							});
						});
				}}
				user={userData}
				currentAddition={openItem === "updatePTOAddition" ? currentItem : null}
			/>
			<PTORequestSlideOver
				closeRequest={() => {
					setOpenItem("");
					setCurrentItem(null);
				}}
				type={openItem === "createPTO" ? "create" : "update"}
				user={userData}
				creatingViaManager={true}
				isOpen={openItem === "createPTO" || openItem === "updatePTO"}
				onComplete={() => {
					setOpenItem("");
					setCurrentItem(null);
					refetch({
						userID: userID,
						filterPTO: { user: { eq: userID } },
						filterAddition: { user: { eq: userID } },
						limit: 50,
						ptoNextToken: ptoNextToken,
						additionsNextToken: additionsNextToken,
					})
						.then((data) => handleSuccess(data))
						.catch((error) => {
							Toast({
								type: "error",
								text1: "There was an error refetching the PTO",
								text2: error.message,
							});
						});
				}}
				currentPTO={openItem === "updatePTO" ? currentItem : null}
			/>
		</>
	);
};

PTODataForUser.propTypes = {
	userID: PropTypes.string,
	HRView: PropTypes.bool,
	buttonAction: PropTypes.string,
	onClearButtonAction: PropTypes.func,
	onUserDataRetrieved: PropTypes.func,
	readOnlyView: PropTypes.bool,
};
