import PropTypes from "prop-types";
import React, { useState } from "react";

// Components
import { Alert } from "../components/Alert";
import { Input, Select } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation } from "@apollo/client";
import * as billingPeriodGQL from "../graphql/clerk/billingPeriod";

const statusTypes = ["In Progress", "In Review", "Completed"];

const BillingPeriodSlideOver = ({
	isOpen,
	closeRequest = () => {},
	type,
	onComplete,
	currentBillingPeriod,
}) => {
	const [billingPeriod, setBillingPeriod] = useState({});

	// sets the current expense type if there is one

	React.useEffect(() => {
		if (currentBillingPeriod) {
			setBillingPeriod(currentBillingPeriod);
		}
	}, [currentBillingPeriod]);

	const [createBillingPeriod] = useMutation(
		gql(billingPeriodGQL.createBillingPeriod),
		{
			onCompleted: (data) => {
				onComplete(data.createBillingPeriod);
			},
			onError: () => {
				Alert(
					"Something Went Wrong",
					"There was an error creating the billing period"
				);
			},
		}
	);
	const [updateBillingPeriod] = useMutation(
		gql(billingPeriodGQL.updateBillingPeriod),
		{
			onCompleted: (data) => {
				onComplete(data.updateBillingPeriod);
			},
			onError: () => {
				Alert(
					"Something Went Wrong",
					"There was an error updating the billing period"
				);
			},
		}
	);

	return (
		<SlideOver
			isOpen={isOpen}
			name="New Billing Period "
			description={"Create a new billing period "}
			closeRequest={() => {
				closeRequest();
			}}
			onSubmit={() => {
				if (billingPeriod.status == undefined) {
					Alert("Status must not be blank");
				} else if (billingPeriod.startDate > billingPeriod.endDate) {
					Alert("End Date must be greater than Start Date");
				} else {
					if (type === "create") {
						createBillingPeriod({
							variables: {
								input: billingPeriod,
							},
						});
					} else {
						updateBillingPeriod({
							variables: {
								input: billingPeriod,
							},
						});
					}
				}
			}}
		>
			<Input
				label="Name"
				required
				helper="Enter Billing Period Name"
				value={billingPeriod.name}
				onChange={(e) =>
					setBillingPeriod({
						...billingPeriod,
						name: e,
					})
				}
			/>
			<Input
				label="Start Date"
				type="date"
				value={billingPeriod.startDate}
				onChange={(e) => {
					setBillingPeriod({
						...billingPeriod,
						startDate: e,
					});
				}}
				required
			/>
			<Input
				label="End Date"
				type="date"
				value={billingPeriod.endDate}
				onChange={(e) => {
					setBillingPeriod({
						...billingPeriod,
						endDate: e,
					});
				}}
				required
			/>
			<Select
				name="Status Category"
				defaultValue={billingPeriod.status}
				options={statusTypes.map((type) => ({
					name: type,
					value: type,
				}))}
				onChange={(value) => {
					setBillingPeriod({ ...billingPeriod, status: value.value });
				}}
				required
			/>
		</SlideOver>
	);
};

BillingPeriodSlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	currentBillingPeriod: PropTypes.object,
};

export { BillingPeriodSlideOver, statusTypes };
