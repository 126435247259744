import PropTypes from "prop-types";
import { View, Text } from "react-native";
import User from "./User";

/**
 *
 * @param {object} props the props sent to the component
 * @param { string } props.text the text to display in the badge
 * @param { string } props.variant the color of the badge (gray, red, yellow, green, blue, indigo, purple, pink)
 * @param { string } props.size the size of the badge (xs, sm, md, lg, xl)
 * @param { string } props.className the class name of the badge
 * @param { object } props.user the user object
 * @returns react-native component
 */
export const Badge = ({
	text,
	variant = "gray",
	size = "sm",
	className,
	user,
}) => {
	const colors = {
		gray: {
			background: "bg-gray-300",
			focusRing: "focus:ring-gray-500",
			hover: "hover:bg-gray-500",
			text: "text-gray-800",
		},
		red: {
			background: "bg-red-600",
			focusRing: "focus:ring-red-500",
			hover: "hover:bg-red-700",
			text: "text-red-800",
		},
		yellow: {
			background: "bg-yellow-600",
			focusRing: "focus:ring-yellow-500",
			hover: "hover:bg-yellow-700",
			text: "text-yellow-800",
		},
		green: {
			background: "bg-green-600",
			focusRing: "focus:ring-green-500",
			hover: "hover:bg-green-700",
			text: "text-green-800",
		},
		blue: {
			background: "bg-blue-600",
			focusRing: "focus:ring-blue-500",
			hover: "hover:bg-blue-700",
			text: "text-blue-800",
		},
		indigo: {
			background: "bg-indigo-600",
			focusRing: "focus:ring-indigo-500",
			hover: "hover:bg-indigo-700",
			text: "text-indigo-800",
		},
		purple: {
			background: "bg-purple-600",
			focusRing: "focus:ring-purple-500",
			hover: "hover:bg-purple-700",
			text: "text-purple-800",
		},
		pink: {
			background: "bg-pink-600",
			focusRing: "focus:ring-pink-500",
			hover: "hover:bg-pink-700",
			text: "text-pink-800",
		},
	};

	const sizes = {
		xs: {
			padding: "px-1 py-0.5",
			text: "text-xs",
			font: "font-light",
		},
		sm: {
			padding: user ? "px-1 pr-2 py-1" : "px-2 py-1",
			text: "text-sm",
			font: "font-normal",
		},
		md: {
			padding: "px-3 py-1.5",
			text: "text-base",
			font: "font-medium",
		},
		lg: {
			padding: "px-4 py-2",
			text: "text-lg",
			font: "font-medium",
		},
		xl: {
			padding: "px-5 py-2.5",
			text: "text-xl",
			font: "font-medium",
		},
	};

	return (
		<View
			className={`flex-row items-center ${sizes[size].padding} rounded-full ${colors[variant].background} ${colors[variant].hover} ${className}`}
		>
			{user && (
				<View className="mr-2">
					<User user={user} size="xs" photoOnly />
				</View>
			)}
			<Text
				testID="badge-text"
				className={`${colors[variant].text} ${sizes[size].text} ${sizes[size].font} `}
			>
				{text}
			</Text>
		</View>
	);
};

Badge.propTypes = {
	text: PropTypes.string,
	variant: PropTypes.oneOf([
		"gray",
		"red",
		"yellow",
		"green",
		"blue",
		"indigo",
		"purple",
		"pink",
	]),
	className: PropTypes.string,
	size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
	user: PropTypes.object,
};
export default Badge;
