export const getSampleCollection = /* GraphQL */ `
	query getSampleCollection($id: ID!) {
		getSampleCollection(id: $id) {
			id
			title
			homogeneousAreas {
				id
				name
				material
				squareFootage
				positive
				overallPotential
				samples {
					id
					number
					title
				}
			}
			project {
				id
				title
				isViewable
				team {
					isSuperAdmin
					isAdmin
					isActive
					isGeneral
					isReadOnly
					projectRole {
						id
						name
					}
					teamMember {
						id
					}
				}
				client {
					name
				}
				workspace {
					isViewable
					id
					title
					team {
						projectRole {
							id
							name
						}
						isSuperAdmin
						isAdmin
						isActive
						isGeneral
						isReadOnly
						teamMember {
							id
						}
					}
					client {
						name
					}
				}
			}
			type
			lab {
				id
				name
				types {
					id
					name
					code
					collectionType
				}
				mediums {
					id
					name
					code
					collectionType
				}
			}
			labLocation {
				id
				name
			}
			samples {
				id
				title
				number
				description
				date
				positive
				friable
				result
				media {
					id
					order
					key
				}
				user {
					firstName
					lastName
					profilePicture
					role
				}
				type {
					id
					name
					code
				}
				medium {
					id
					name
				}
			}
		}
	}
`;

export const createSample = /* GraphQL */ `
	mutation CreateSample($input: CreateSampleInput!) {
		createSample(input: $input) {
			id
			homogeneousArea {
				id
				name
				material
				squareFootage
				positive
				overallPotential
			}
			sampleCollection {
				id
				title
				samples {
					number
					positive
					friable
					result
					homogeneousArea {
						id
						name
						material
						squareFootage
						positive
						overallPotential
					}
					lab {
						id
						name
					}
					type {
						id
						name
						code
					}
					medium {
						id
						name
					}
					date
					user {
						id
						firstName
						lastName
						profilePicture
						role
					}
					media {
						id
						key
						order
					}
					title
					description
					volumeOrArea
					location
				}

				labLocation {
					id
					name
				}
				project {
					id
					title
					isViewable
					team {
						isSuperAdmin
						isAdmin
						isActive
						isGeneral
						isReadOnly
						projectRole {
							id
							name
						}
						teamMember {
							id
						}
					}
					client {
						name
					}
					workspace {
						isViewable
						id
						title
						team {
							projectRole {
								id
								name
							}
							isSuperAdmin
							isAdmin
							isActive
							isGeneral
							isReadOnly
							teamMember {
								id
							}
						}
						client {
							name
						}
					}
				}
				lab {
					id
					name
					types {
						id
						name
						code
						collectionType
					}
					mediums {
						id
						name
						code
						collectionType
					}
				}
			}
		}
	}
`;

export const updateSample = /* GraphQL */ `
	mutation UpdateSample($input: UpdateSampleInput!) {
		updateSample(input: $input) {
			id
			title
			description
			location
			number
			date
			positive
			friable
			result
			homogeneousArea {
				id
				name
				material
				squareFootage
				positive
				overallPotential
			}
			user {
				id
				lastName
				firstName
				role
				profilePicture
				signature
			}
			medium {
				id
				name
			}
			type {
				id
				name
			}
			media {
				id
				order
				key
			}
			sampleCollection {
				id
				type
				title
				homogeneousAreas {
					id
					name
					material
					squareFootage
					positive
					overallPotential
					samples {
						id
						number
						title
					}
				}
				project {
					id
					title
					isViewable
					team {
						isSuperAdmin
						isAdmin
						isActive
						isGeneral
						isReadOnly
						projectRole {
							id
							name
						}
						teamMember {
							id
						}
					}
					client {
						name
					}
					workspace {
						isViewable
						id
						title
						team {
							projectRole {
								id
								name
							}
							isSuperAdmin
							isAdmin
							isActive
							isGeneral
							isReadOnly
							teamMember {
								id
							}
						}
						client {
							name
						}
					}
				}
				lab {
					id
					name
					types {
						id
						name
						code
						collectionType
					}
					mediums {
						id
						name
						code
						collectionType
					}
				}
			}
		}
	}
`;

export const createMedia = /* GraphQL */ `
	mutation CreateMedia($input: CreateMediaInput!) {
		createMedia(input: $input) {
			id
			type
			order
			key
			sample {
				id
			}
		}
	}
`;

export const deleteMedia = /* GraphQL */ `
	mutation CreateMedia($input: DeleteMediaInput!) {
		deleteMedia(input: $input) {
			id
		}
	}
`;

export const deleteSample = /* GraphQL */ `
	mutation DeleteSample($input: DeleteSampleInput!) {
		deleteSample(input: $input) {
			id
		}
	}
`;
export const updateSampleCollection = /* GraphQL */ `
	mutation UpdateSampleCollection($input: UpdateSampleCollectionInput!) {
		updateSampleCollection(input: $input) {
			id
			title
			homogeneousAreas {
				id
				name
				material
				squareFootage
				positive
				overallPotential
				samples {
					id
					number
					title
				}
			}
			project {
				id
				title
				isViewable
				team {
					isSuperAdmin
					isAdmin
					isActive
					isGeneral
					isReadOnly
					projectRole {
						id
						name
					}
					teamMember {
						id
					}
				}
				client {
					name
				}
				workspace {
					isViewable
					id
					title
					team {
						projectRole {
							id
							name
						}
						isSuperAdmin
						isAdmin
						isActive
						isGeneral
						isReadOnly
						teamMember {
							id
						}
					}
					client {
						name
					}
				}
			}
			type
			lab {
				id
				name
				types {
					id
					name
					code
					collectionType
				}
				mediums {
					id
					name
					code
					collectionType
				}
			}
			labLocation {
				id
				name
			}
			samples {
				id
				title
				number
				positive
				description
				media {
					id
					order
					key
				}
				user {
					firstName
					lastName
					profilePicture
					role
				}
				type {
					id
					name
					code
				}
				medium {
					id
					name
				}
			}
		}
	}
`;

export const getSample = /* GraphQL */ `
	query getSample($id: ID!) {
		getSample(id: $id) {
			id
			title
			description
			location
			number
			date
			positive
			friable
			result
			homogeneousArea {
				id
				name
				material
				squareFootage
				positive
				overallPotential
			}
			user {
				id
				lastName
				firstName
				role
				profilePicture
				signature
			}
			medium {
				id
				name
			}
			type {
				id
				name
			}
			media {
				id
				order
				key
			}
			sampleCollection {
				id
				type
				title
				homogeneousAreas {
					id
					name
					material
					squareFootage
					positive
					overallPotential
					samples {
						id
						number
						title
					}
				}
				project {
					id
					title
					isViewable
					team {
						isSuperAdmin
						isAdmin
						isActive
						isGeneral
						isReadOnly
						projectRole {
							id
							name
						}
						teamMember {
							id
						}
					}
					client {
						name
					}
					workspace {
						isViewable
						id
						title
						team {
							projectRole {
								id
								name
							}
							isSuperAdmin
							isAdmin
							isActive
							isGeneral
							isReadOnly
							teamMember {
								id
							}
						}
						client {
							name
						}
					}
				}
				lab {
					id
					name
					types {
						id
						name
						code
						collectionType
					}
					mediums {
						id
						name
						code
						collectionType
					}
				}
			}
		}
	}
`;
