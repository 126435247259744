export const dataStudioLiveFeed = /* GraphQL */ `
	subscription OnCreateSpaceByProject($projectSub: String) {
		onCreateSpaceByProject(projectSub: $projectSub) {
			id
			name
			date
			project {
				id
			}
			createBy {
				id
				firstName
				lastName
				profilePicture
			}
		}
	}
`;

export const createSpace = /* GraphQL */ `
	mutation CreateSpace($input: CreateSpaceInput!) {
		createSpace(input: $input) {
			id
			name
			date
			projectSub
			project {
				id
				title
			}
			parent {
				id
				name
			}
			createBy {
				id
				firstName
				lastName
			}
		}
	}
`;

export const getProjectSpaces = /*GraphQL */ `
	query getProject($id: ID!) {
		getProject(id: $id) {
    id
    title
    client {
      id
      name
    }
    workspace {
      client {
        id
        name
      }
    }
    spaces {
      id
      name
      isDamage
      noAccess
      spaces {
        id
        isDamage
        noAccess
      }
      parent {
        id 
        name
      }  
    }
  }
}
`;

export const getProject = /* GraphQL */ `
	query GetProject($id: ID!) {
		getProject(id: $id) {
			id
			title
			stage
			number
			sageIntacct
			isViewable
			isWorkspace
			isLargeLoss
			startDate
			endDate
			workspace {
				id
				title
				team {
					id
					isSuperAdmin
					isAdmin
					isGeneral
					isReadOnly
					projectRole {
						id
						name
						sageItem
						sageOvertimeItem
					}
					isActive
					added
					teamMember {
						id
						firstName
						lastName
						profilePicture
						role
						isTeamMember
						isTempWorker
						isContractor
						client {
							id
						}
						subcontractor {
							id
						}
					}
				}
				client {
					id
					name
					sageIntacct
				}
				office {
					id
					name
					sageIntacct
				}
			}
			reports {
				id
				status
				reportType {
					id
					name
					category
				}
				documents {
					isOfficial
					name
					date
					link
				}
			}
			notes {
				id
				title
				description
				date
				user {
					id
					firstName
					lastName
					profilePicture
					role
				}
			}
			client {
				id
				name
				locations {
					id
					name
				}
				sageIntacct
			}
			location {
				id
				name
				lineOne
				lineTwo
				city
				state
				zip
			}
			team {
				id
				isSuperAdmin
				isAdmin
				isGeneral
				isReadOnly
				projectRole {
					id
					name
					sageItem
					sageOvertimeItem
				}
				isActive
				added
				teamMember {
					id
					firstName
					lastName
					profilePicture
					role
					isTeamMember
					isTempWorker
					isContractor
					sageIntacct
					client {
						id
					}
					subcontractor {
						id
					}
				}
			}
			spaces {
				id
				name
				spaces {
					id
					name
					inherentParentDryStandard
					projectSub
					isFungalGrowth
					noAccess
					isDamage
					isWallDamage
					isCeilingDamage
					isFloorDamage
					damgage
					date
				}
				parent {
					id
					name
				}
			}
			surveys {
				id
				title
				date
				items {
					id
					date
					title
					description
					order
					location
				}
			}
			samples {
				id
				title
				lab {
					id
					name
				}
				labLocation {
					id
					isBranchLocation
					name
					lineOne
					lineTwo
					city
					state
					zip
					latitude
					longitude
				}
				type
				clearance
				samples {
					id
					number
					date
					title
					description
					volumeOrArea
					location
					projectSub
				}
				createdBy {
					id
					firstName
					lastName
					email
					phone
					role
					profilePicture
					bio
					title
				}
				date
			}
			office {
				id
				name
				sageIntacct
			}
			projects {
				id
				title
				stage
				number
				isViewable
				isWorkspace

				location {
					id
					name
				}
				team {
					id
					added
					teamMember {
						id
						firstName
						lastName
						profilePicture
						role
						isTeamMember
						isTempWorker
						isContractor
					}
				}
				office {
					id
					name
					sageIntacct
				}
			}
			dailyDocuments {
				id
				user {
					id
					firstName
					lastName
					subcontractor {
						id
					}
				}
				date
				reportGeneration
				signature
			}
			timeEntries {
				id
				timeIn
				timeOut
				projectApproved
				projectApprovedBy {
					id
					firstName
					lastName
				}
				projectApprovedAt
				approved
				approvedBy {
					id
					firstName
					lastName
				}
				approvedAt
				user {
					id
					firstName
					lastName
					subcontractor {
						id
					}
				}
				dailyDocument {
					signature
				}
			}
		}
	}
`;

export const listLocations = /* GraphQL */ `
	query ListLocations(
		$filter: TableLocationFilterInput
		$limit: Int
		$nextToken: String
	) {
		listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
			}
		}
	}
`;

export const createClient = /* GraphQL */ `
	mutation CreateClient($input: CreateClientInput!) {
		createClient(input: $input) {
			id
			name
			locations {
				id
				name
				sageIntacct
			}
		}
	}
`;

export const listLabs = /* GraphQL */ `
	query ListLabs(
		$filter: TableLabFilterInput
		$limit: Int
		$nextToken: String
	) {
		listLabs(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				locations {
					id
					name
					city
					state
					zip
				}
			}
		}
	}
`;

export const createProject = /* GraphQL */ `
	mutation CreateProject($input: CreateProjectInput!) {
		createProject(input: $input) {
			id
		}
	}
`;

export const createNote = /* GraphQL */ `
	mutation CreateNote($input: CreateNoteInput!) {
		createNote(input: $input) {
			id
			title
			date
			equipment {
				id
			}
		}
	}
`;

export const createMedia = /* GraphQL */ `
	mutation CreateMedia($input: CreateMediaInput!) {
		createMedia(input: $input) {
			id
			type
			order
			key
			note {
				id
			}
		}
	}
`;

export const createAudit = /* GraphQL */ `
	mutation CreateSurvey($input: CreateSurveyInput!) {
		createSurvey(input: $input) {
			id
			title
			date
		}
	}
`;

export const getNote = /* GraphQL */ `
	query GetNote($id: ID!) {
		getNote(id: $id) {
			id
			title
			description
			date
			media {
				id
				key
			}
			user {
				id
				firstName
				lastName
				profilePicture
				role
			}
			project {
				id
				title
				workspace {
					id
					title
				}
			}
		}
	}
`;

export const deleteNote = /* GraphQL */ `
	mutation DeleteNote($input: DeleteNoteInput!) {
		deleteNote(input: $input) {
			id
		}
	}
`;

export const deleteMedia = /* GraphQL */ `
	mutation DeleteMedia($input: DeleteMediaInput!) {
		deleteMedia(input: $input) {
			id
		}
	}
`;

export const deleteTeamMember = /* GraphQL */ `
	mutation DeleteProjectTeamMember($input: DeleteProjectTeamMemberInput!) {
		deleteProjectTeamMember(input: $input) {
			id
		}
	}
`;

export const updateNote = /* GraphQL */ `
	mutation UpdateNote($input: UpdateNoteInput!) {
		updateNote(input: $input) {
			id
			date
			description
			title
			user {
				id
				firstName
				lastName
				profilePicture
				role
			}
		}
	}
`;
export const updateMedia = /* GraphQL */ `
	mutation UpdateMedia($input: UpdateMediaInput!) {
		updateMedia(input: $input) {
			id
			type
			order
			key
			note {
				id
			}
		}
	}
`;

export const updateProject = /* GraphQL */ `
	mutation UpdateProject($input: UpdateProjectInput!) {
		updateProject(input: $input) {
			id
			title
			stage
			number
			isViewable
			isWorkspace
			workspace {
				id
				title
				team {
					id
					isSuperAdmin
					isAdmin
					isGeneral
					isReadOnly
					projectRole {
						id
						name
						sageItem
						sageOvertimeItem
					}
					isActive
					added
					teamMember {
						id
						firstName
						lastName
						profilePicture
						role
						isTeamMember
						isTempWorker
						isContractor
						client {
							id
						}
					}
				}

				client {
					id
					name
					sageIntacct
				}
				office {
					id
					name
					sageIntacct
				}
			}
			reports {
				id
				status
				reportType {
					id
					name
					category
				}
				documents {
					isOfficial
					name
					date
					link
				}
			}
			notes {
				id
				title
				description
				date
				user {
					id
					firstName
					lastName
					profilePicture
					role
				}
			}
			client {
				id
				name
				locations {
					id
					name
				}
				sageIntacct
			}
			location {
				id
				name
				lineOne
				lineTwo
				city
				state
				zip
			}
			team {
				id
				isSuperAdmin
				isAdmin
				isGeneral
				isReadOnly
				projectRole {
					id
					name
					sageItem
					sageOvertimeItem
				}
				isActive
				added
				teamMember {
					id
					firstName
					lastName
					profilePicture
					role
					isTeamMember
					isTempWorker
					isContractor
					client {
						id
					}
				}
			}
			spaces {
				id
				name
				spaces {
					id
					name
					inherentParentDryStandard
					projectSub
					isFungalGrowth
					noAccess
					isDamage
					isWallDamage
					isCeilingDamage
					isFloorDamage
					damgage
					date
				}
				parent {
					id
					name
				}
			}
			surveys {
				id
				title
				date
				items {
					id
					date
					title
					description
					order
					location
				}
			}
			samples {
				id
				title
				lab {
					id
					name
				}
				labLocation {
					id
					isBranchLocation
					name
					lineOne
					lineTwo
					city
					state
					zip
					latitude
					longitude
				}
				type
				clearance
				samples {
					id
					number
					date
					title
					description
					volumeOrArea
					location
					projectSub
				}
				createdBy {
					id
					firstName
					lastName
					email
					phone
					role
					profilePicture
					bio
					title
				}
				date
			}
			office {
				id
				name
				sageIntacct
			}
			projects {
				id
				title
				stage
				number
				isViewable
				isWorkspace
				location {
					id
					name
				}
				team {
					id
					added
					teamMember {
						id
						firstName
						lastName
						profilePicture
						role
						isTeamMember
						isTempWorker
						isContractor
						client {
							id
						}
					}
				}
			}
		}
	}
`;

export const createDailyDocument = /* GraphQL */ `
	mutation createDailyDocument($input: CreateDailyDocumentInput!) {
		createDailyDocument(input: $input) {
			id
			user {
				id
			}
			project {
				id
			}
		}
	}
`;

export const getProjectDailyDocuments = /* GraphQL */ `
	query GetProject($id: ID!) {
		getProject(id: $id) {
			id
			title
			stage
			number
			isViewable
			isWorkspace
			workspace {
				id
				title
				team {
					id
					isSuperAdmin
					isAdmin
					isGeneral
					isReadOnly
					projectRole {
						id
						name
						sageItem
						sageOvertimeItem
					}
					isActive
					added
					teamMember {
						id
						firstName
						lastName
						profilePicture
						role
						isTeamMember
						isTempWorker
						isContractor
						client {
							id
						}
					}
				}
				client {
					id
					name
					sageIntacct
				}
				office {
					id
					name
					sageIntacct
				}
			}
			location {
				id
				name
				lineOne
				lineTwo
				city
				state
				zip
			}
			team {
				id
				isSuperAdmin
				isAdmin
				isGeneral
				isReadOnly
				projectRole {
					id
					name
					sageItem
					sageOvertimeItem
				}
				isActive
				added
				teamMember {
					id
					firstName
					lastName
					profilePicture
					role
					isTeamMember
					isTempWorker
					isContractor
					client {
						id
					}
				}
			}
			office {
				id
				name
				sageIntacct
			}
			client {
				id
				name
				sageIntacct
			}
			dailyDocuments {
				id
				signature
				user {
					id
					isContractor
				}
				reportGeneration
				date
				timeIn
				timeOut
				perDiem
			}
		}
	}
`;

export const listUsers = /* GraphQL */ `
	query ListUsers($limit: Int, $nextToken: String) {
		listUsers(limit: $limit, nextToken: $nextToken) {
			items {
				id
				firstName
				lastName
				isTimeOverrider
				isTeamMember
				isSafetyUser
				isContractor
				isActive
				isSalary
				isRequesting
			}
		}
	}
`;

export const getSingleDailySheet = /* GraphQL */ `
	query GetDailyDocument($id: ID!) {
		getDailyDocument(id: $id) {
			id
			user {
				id
				isContractor
			}
			date
			timeIn
			timeOut
			perDiem
			signature
			updateExpiration
			moistureMapping
			admin
			dryDown
			sampling
			airfare
			activities
			boroscope
			mobilization
			demobilization
			fallProtection
			hotelStatus
			hotelName
			hotelNumber
			personalCompanyMilage
			rentalCarCompany
			rentalCarModel
			rentalCarMake
			rentalCarStatus
			respirator
			trailerPackage
			thermohygrometer
			fuel
			accountingPackage
			airfareBaggageFees
			hammerProbe
			flirSoftware
			matterport
			mobileCommandCenter
			mobileHotspot
			moistureMappingPackage
			ppe
			remoteOfficePackage
			sphericalCamera
			flir
			vehicle
			project {
				id
				title
				stage
				number
				isViewable
				isWorkspace
				workspace {
					id
					title
					team {
						id
						isSuperAdmin
						isAdmin
						isGeneral
						isReadOnly
						projectRole {
							id
							name
							sageItem
							sageOvertimeItem
						}
						isActive
						added
						teamMember {
							id
							firstName
							lastName
							profilePicture
							role
							isTeamMember
							isTempWorker
							isContractor
							client {
								id
							}
						}
					}
					client {
						id
						name
						sageIntacct
					}
					office {
						id
						name
						sageIntacct
					}
				}
				location {
					id
					name
					lineOne
					lineTwo
					city
					state
					zip
				}
				team {
					id
					isSuperAdmin
					isAdmin
					isGeneral
					isReadOnly
					projectRole {
						id
						name
						sageItem
						sageOvertimeItem
					}
					isActive
					added
					teamMember {
						id
						firstName
						lastName
						profilePicture
						role
						isTeamMember
						isTempWorker
						isContractor
						client {
							id
						}
					}
				}
				office {
					id
					name
					sageIntacct
				}
				client {
					id
					name
					sageIntacct
				}
			}
		}
	}
`;

export const createProjectTeamMember = /* GraphQL */ `
	mutation CreateProjectTeamMember($input: CreateProjectTeamMemberInput!) {
		createProjectTeamMember(input: $input) {
			id
			teamMember {
				id
			}
		}
	}
`;

export const updateProjectTeamMember = /* GraphQL */ `
	mutation UpdateProjectTeamMember($input: UpdateProjectTeamMemberInput!) {
		updateProjectTeamMember(input: $input) {
			id
		}
	}
`;

export const listClients = /* GraphQL */ `
	query ListClients(
		$filter: TableClientFilterInput
		$limit: Int
		$nextToken: String
	) {
		listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
				locations {
					id
					name
				}
			}
		}
	}
`;

export const createDryStandard = /* GraphQL */ `
	mutation CreateDryStandard($input: CreateDryStandardInput!) {
		createDryStandard(input: $input) {
			id
		}
	}
`;
export const createDryStandardItem = /* GraphQL */ `
	mutation CreateDryStandardItem($input: CreateDryStandardItemInput!) {
		createDryStandardItem(input: $input) {
			id
		}
	}
`;
export const updateDryStandardItem = /* GraphQL */ `
	mutation UpdateDryStandardItem($input: UpdateDryStandardItemInput!) {
		updateDryStandardItem(input: $input) {
			id
		}
	}
`;
export const getDryStandard = /* GraphQL */ `
	query GetDryStandard($id: ID!) {
		getDryStandard(id: $id) {
			id
			title
			items {
				id
				material
				penatrative
				relative
				dryOnly
			}
			project {
				id
				title
				stage
				number
				isViewable
				isWorkspace
				workspace {
					id
					title
					team {
						id
						isSuperAdmin
						isAdmin
						isGeneral
						isReadOnly
						projectRole {
							id
							name
							sageItem
							sageOvertimeItem
						}
						isActive
						added
						teamMember {
							id
							firstName
							lastName
							profilePicture
							role
							isTeamMember
							isTempWorker
							isContractor
							client {
								id
							}
						}
					}
					client {
						id
						name
						sageIntacct
					}
					office {
						id
						name
						sageIntacct
					}
				}
				location {
					id
					name
					lineOne
					lineTwo
					city
					state
					zip
				}
				team {
					id
					isSuperAdmin
					isAdmin
					isGeneral
					isReadOnly
					projectRole {
						id
						name
						sageItem
						sageOvertimeItem
					}
					isActive
					added
					teamMember {
						id
						firstName
						lastName
						profilePicture
						role
						isTeamMember
						isTempWorker
						isContractor
						client {
							id
						}
					}
				}
				office {
					id
					name
					sageIntacct
				}
				client {
					id
					name
					sageIntacct
				}
			}
		}
	}
`;

export const getProjectDryStandards = /* GraphQL */ `
	query GetProject($id: ID!) {
		getProject(id: $id) {
			id
			title
			stage
			number
			isViewable
			isWorkspace
			startDate
			endDate
			workspace {
				id
				title
				team {
					id
					isSuperAdmin
					isAdmin
					isGeneral
					isReadOnly
					projectRole {
						id
						name
						sageItem
						sageOvertimeItem
					}
					isActive
					added
					teamMember {
						id
						firstName
						lastName
						profilePicture
						role
						isTeamMember
						isTempWorker
						isContractor
						client {
							id
						}
					}
				}
				client {
					id
					name
					sageIntacct
				}
				office {
					id
					name
					sageIntacct
				}
			}
			location {
				id
				name
				lineOne
				lineTwo
				city
				state
				zip
			}
			team {
				id
				isSuperAdmin
				isAdmin
				isGeneral
				isReadOnly
				projectRole {
					id
					name
					sageItem
					sageOvertimeItem
				}
				isActive
				added
				teamMember {
					id
					firstName
					lastName
					profilePicture
					role
					isTeamMember
					isTempWorker
					isContractor
					client {
						id
					}
				}
			}
			office {
				id
				name
				sageIntacct
			}
			client {
				id
				name
				sageIntacct
			}
			dryStandards {
				id
				title
			}
		}
	}
`;

export const updateDailyDocument = /* GraphQL */ `
	mutation updateDailyDocument($input: UpdateDailyDocumentInput!) {
		updateDailyDocument(input: $input) {
			id
		}
	}
`;

export const listProjectRoles = /* GraphQL */ `
	query ListProjectRoles {
		listProjectRoles {
			items {
				id
				name
			}
		}
	}
`;

export const createSampleCollection = /* GraphQL */ `
	mutation createSampleCollection($input: CreateSampleCollectionInput!) {
		createSampleCollection(input: $input) {
			id
		}
	}
`;
export const updateSampleCollection = /* GraphQL */ `
	mutation updateSampleCollection($input: UpdateSampleCollectionInput!) {
		updateSampleCollection(input: $input) {
			id
		}
	}
`;
export const getSampleCollection = /* GraphQL */ `
	query getSampleCollection($id: ID!) {
		getSampleCollection(id: $id) {
			id
			title
			homogeneousAreas {
				id
				name
				material
				squareFootage
				positive
				overallPotential
				samples {
					id
					number
					title
				}
			}
			project {
				id
				title
				isViewable
				team {
					isSuperAdmin
					isAdmin
					isActive
					isGeneral
					isReadOnly
					projectRole {
						id
						name
					}
					teamMember {
						id
					}
				}
				client {
					name
				}
				workspace {
					isViewable
					id
					title
					team {
						projectRole {
							id
							name
						}
						isSuperAdmin
						isAdmin
						isActive
						isGeneral
						isReadOnly
						teamMember {
							id
						}
					}
					client {
						name
					}
				}
			}
			type
			lab {
				id
				name
				types {
					id
					name
					code
					collectionType
				}
				mediums {
					id
					name
					code
					collectionType
				}
			}
			labLocation {
				id
				name
			}
			samples {
				id
				title
				number
				description
				date
				positive
				friable
				result
				media {
					id
					order
					key
				}
				user {
					firstName
					lastName
					profilePicture
					role
				}
				type {
					id
					name
					code
				}
				medium {
					id
					name
				}
			}
		}
	}
`;
