export const listVersions = /* GraphQL */ `
	query ListVersions(
		$filter: TableVersionFilterInput
		$limit: Int
		$nextToken: String
	) {
		listVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				version
				created
				sop {
					id
					title
				}
				public
				comments {
					id
					body
					timestamp
				}
				views {
					id
				}
				bodies {
					id
					body
					language
				}
			}
		}
	}
`;

export const createVersion = /* GraphQL */ `
	mutation CreateVersion($input: CreateVersionInput!) {
		createVersion(input: $input) {
			id
			version
			bodies {
				id
			}
			sop {
				id
			}
		}
	}
`;

export const getVersion = /* GraphQL */ `
	query GetVersion($id: ID!) {
		getVersion(id: $id) {
			id
			version
			created
			sop {
				id
				title
			}
			public
			comments {
				id
				body
				timestamp
			}
			views {
				id
			}
			bodies {
				id
				body
				language
			}
		}
	}
`;

export const updateVersion = /* GraphQL */ `
	mutation UpdateVersion($input: UpdateVersionInput!) {
		updateVersion(input: $input) {
			id
			version
			created
			sop {
				id
				title
			}
			public
			comments {
				id
				body
				timestamp
			}
			views {
				id
			}
			bodies {
				id
				body
				language
			}
		}
	}
`;

export const deleteVersion = /* GraphQL */ `
	mutation DeleteVersion($input: DeleteVersionInput!) {
		deleteVersion(input: $input) {
			id
		}
	}
`;
