export const listClients = /* GraphQL */ `
	query ListClients(
		$filter: TableClientFilterInput
		$limit: Int
		$nextToken: String
	) {
		listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				id
				name
			}
			nextToken
		}
	}
`;

export const getClient = /* GraphQL */ `
	query GetClient($id: ID!) {
		getClient(id: $id) {
			id
			name
			sageIntacct
			location {
				id
				name
				lineOne
				lineTwo
				city
				state
				zip
			}
			locations {
				id
				name
				lineOne
				lineTwo
				city
				state
				zip
			}
			projects {
				id
				title
				stage
				isWorkspace
				number
				location {
					id
					name
					lineOne
					lineTwo
					city
					state
					zip
				}
			}
			users {
				id
				firstName
				lastName
				role
				email
				phone
			}
		}
	}
`;

// updateClient

export const updateClient = /* GraphQL */ `
	mutation UpdateClient($input: UpdateClientInput!) {
		updateClient(input: $input) {
			id
			name
			sageIntacct
			location {
				id
				name
				lineOne
				lineTwo
				city
				state
				zip
			}
		}
	}
`;

// updateLocation

export const updateLocation = /* GraphQL */ `
	mutation UpdateLocation($input: UpdateLocationInput!) {
		updateLocation(input: $input) {
			id
			name
			lineOne
			lineTwo
			city
			state
			zip
		}
	}
`;

// createClient

export const createClient = /* GraphQL */ `
	mutation CreateClient($input: CreateClientInput!) {
		createClient(input: $input) {
			id
			name
			sageIntacct
			location {
				id
				name
				lineOne
				lineTwo
				city
				state
				zip
			}
		}
	}
`;

// createLocation

export const createLocation = /* GraphQL */ `
	mutation CreateLocation($input: CreateLocationInput!) {
		createLocation(input: $input) {
			id
			name
			lineOne
			lineTwo
			city
			state
			zip
		}
	}
`;
