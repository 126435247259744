import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React from "react";
import { ScrollView, View } from "react-native";

// Components
import { FileUpload, Input, TextArea } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL
import { gql, useMutation } from "@apollo/client";
import * as projectGQL from "../graphql/project";

// Utils
import { getData } from "../utils";
import { getFileAttributes, uploadFile } from "../utils/files";

export const NoteSlideOver = ({
	type = "create",
	currentNote,
	isOpen,
	closeRequest,
	onComplete,
	project,
	equipment,
	safetyID,
	safetyQuestionnaire,
}) => {
	const [user, setUser] = React.useState(null);
	const [note, setNote] = React.useState(null);
	const [files, setFiles] = React.useState([]);

	React.useEffect(() => {
		if (currentNote) {
			setNote(currentNote);
		}
	}, [currentNote]);

	React.useEffect(() => {
		getData("@storage_Key").then((user) => {
			setUser(user);
		});
	}, []);

	const [createNewNote] = useMutation(gql(projectGQL.createNote), {
		onCompleted: (data) => {
			files.map(async (file) => {
				//upload files

				let key = `safetyCenter/notes/${safetyID}/${DateTime.now().toISO()}-${
					file.name
				}`;
				await uploadFile(file, key).then(({ key }) => {
					//create media outlet
					createMediaItem({
						variables: { input: { key: key, note: data.createNote.id } },
					});
				});
			});
			onComplete(data.createNote);
		},
	});

	const [createMediaItem] = useMutation(gql(projectGQL.createMedia), {
		onCompleted: () => {},
	});

	const [editNote] = useMutation(gql(projectGQL.updateNote), {
		onCompleted: (data) => {
			onComplete(data.updateNote);
		},
		onError: () => {
			alert("Couldn't update your note please try again");
		},
	});

	const getDescription = () => {
		if (type === "create") {
			if (project) {
				return `Add a new note to ${project.title}`;
			} else {
				return "Add a new note to the safety report";
			}
		}

		if (type !== "create") {
			if (project) {
				return `Edit note for ${project.title}`;
			} else {
				return "Edit safety report note";
			}
		}
		if (type === "create") {
			if (equipment) {
				return `Add a new note to ${equipment.title}`;
			} else {
				return "Add a new note to equipment";
			}
		}

		if (type !== "create") {
			if (equipment) {
				return `Edit note for ${equipment.title}`;
			} else {
				return "Edit equipment note";
			}
		}
	};

	return (
		<SlideOver
			isOpen={isOpen}
			name={type === "create" ? "New note" : "Edit note"}
			description={getDescription()}
			closeRequest={() => closeRequest()}
			onSubmit={async () => {
				if (type === "create") {
					createNewNote({
						variables: {
							input: {
								safetyQuestionnaire: safetyQuestionnaire
									? safetyQuestionnaire.id
									: null,
								project: project ? project.id : null,
								date: DateTime.now().toISO(),
								user: user.id,
								title: note.title,
								description: note.description,
								equipment: equipment ? equipment.id : null,
							},
						},
					});
				} else if (type === "update") {
					delete note.__typename;
					await editNote({
						variables: {
							input: {
								...note,
								user: note.user.id,
								title: note.title,
								description: note.description,
							},
						},
					});
				}
			}}
			buttonRightText={type === "create" ? "Create" : "Update"}
		>
			<ScrollView horizontal>
				{note &&
					note.media &&
					note.media.map(async (file) => {
						// const signedURL = getSignedUrl(file.key);
						await getFileAttributes(file.key).then(() => {});
						//determine if this file is an image or video or file that can be downloaded
						//return <Image source={{ uri: file }} />;
					})}
			</ScrollView>

			<Input
				type="text"
				class="w-input"
				required
				label="Title"
				placeholder={note ? note.title : ""}
				onChange={(e) => {
					setNote({
						...note,
						title: e,
					});
				}}
			/>

			<TextArea
				rows="10"
				required
				label="Description"
				defaultValue={note ? note.description : ""}
				onChange={(e) => {
					setNote({
						...note,
						description: e,
					});
				}}
			/>

			<View>
				<FileUpload
					label="Upload an image"
					multiple={true}
					onFiles={(fileData) => {
						setFiles(fileData);
					}}
				/>
			</View>
		</SlideOver>
	);
};
NoteSlideOver.propTypes = {
	route: PropTypes.object,
	type: PropTypes.string,
	currentNote: PropTypes.object,
	isOpen: PropTypes.bool,
	closeRequest: PropTypes.func,
	onComplete: PropTypes.func,
	project: PropTypes.object,
	equipment: PropTypes.object,
	safetyID: PropTypes.string,
	safetyQuestionnaire: PropTypes.object,
};
