import React from "react";
import PropTypes from "prop-types";

// SVG

import { Circle, Path, Line, Pattern, Text, G } from "react-native-svg";
import Badge from "./Badge";

const CustomLine = ({
	points,
	stroke,
	strokeWidth,
	adjustWidth,
	onPress = () => {},
	firstPointTouched = () => {},
	lastPointTouched = () => {},
	dashed = false,
	text,
	symbol,
	middleX,
	middleY,
}) => {
	if (!points) {
		return;
	}
	return (
		<>
			{onPress ? (
				<G>
					{adjustWidth && (
						<Circle
							stroke="white"
							strokeWidth="5"
							cx={points[0].x}
							cy={points[0].y}
							r="10"
							fill="blue"
							onPress={() => {
								firstPointTouched();
							}}
							onResponderStart={() => {
								firstPointTouched();
							}}
							draggable="true"
						/>
					)}

					{dashed ? (
						// path dashed line

						<Path
							d={`M${points[0].x} ${points[0].y} L${
								points[points.length - 1].x
							} ${points[points.length - 1].y}`}
							stroke={stroke}
							strokeWidth={strokeWidth}
							strokeDasharray="10,10"
							onPress={() => {
								onPress();
							}}
						/>
					) : (
						<Line
							id="line"
							style={{
								zIndex: 2,
							}}
							x1={points[0].x}
							y1={points[0].y}
							x2={points[points.length - 1].x}
							y2={points[points.length - 1].y}
							stroke={stroke}
							strokeWidth={strokeWidth}
							onPress={() => {
								onPress();
							}}
						/>
					)}

					{adjustWidth && (
						<Circle
							stroke="white"
							strokeWidth="5"
							cx={points[points.length - 1].x}
							cy={points[points.length - 1].y}
							r="10"
							fill="blue"
							onPress={() => {
								lastPointTouched();
							}}
							draggable="true"
						/>
					)}

					{text && (
						<>
							<Line
								style={{
									zIndex: 2,
								}}
								x1={points[0].x}
								y1={points[0].y}
								x2={points[points.length - 1].x}
								y2={points[points.length - 1].y}
								fill="url(#text-for-line)"
								onPress={() => {
									onPress();
								}}
							/>
							<Pattern id="text-for-line">
								<Text
									x={points[0].x}
									y={points[0].y}
									fill="black"
									fontSize="20"
									patternUnits="userSpaceOnUse"
								>
									{text}
								</Text>
							</Pattern>
						</>
					)}
				</G>
			) : (
				<G>
					{adjustWidth && (
						<Circle
							stroke="white"
							strokeWidth="5"
							cx={points[0].x}
							cy={points[0].y}
							r="10"
							fill="blue"
							draggable="true"
						/>
					)}
					<Line
						id="line"
						style={{
							zIndex: 2,
						}}
						x1={points[0].x}
						y1={points[0].y}
						x2={points[points.length - 1].x}
						y2={points[points.length - 1].y}
						stroke={stroke}
						strokeWidth={strokeWidth}
						onPress={() => {
							onPress();
						}}
					/>

					{adjustWidth && (
						<Circle
							stroke="white"
							strokeWidth="5"
							cx={points[points.length - 1].x}
							cy={points[points.length - 1].y}
							r="10"
							fill="blue"
							draggable="true"
							onPress={() => {
								lastPointTouched();
							}}
							onResponderStart={() => {
								lastPointTouched();
							}}
						/>
					)}

					{text && (
						<>
							<Line
								style={{
									zIndex: 2,
								}}
								x1={points[0].x}
								y1={points[0].y}
								x2={points[points.length - 1].x}
								y2={points[points.length - 1].y}
								fill="url(#text-for-line)"
							/>
							<Pattern id="text-for-line">
								<Text
									x={points[0].x}
									y={points[0].y}
									fill="black"
									fontSize="20"
									patternUnits="userSpaceOnUse"
								>
									{text}
								</Text>
							</Pattern>
						</>
					)}
				</G>
			)}

			{symbol && middleX && middleY ? (
				<Badge symbol={symbol} middleX={middleX} middleY={middleY} />
			) : null}
		</>
	);
};

CustomLine.propTypes = {
	points: PropTypes.array,
	stroke: PropTypes.string,
	strokeWidth: PropTypes.number,
	adjustWidth: PropTypes.bool,
	onPress: PropTypes.func,
	firstPointTouched: PropTypes.func,
	lastPointTouched: PropTypes.func,
	dashed: PropTypes.bool,
	text: PropTypes.string,
	symbol: PropTypes.string,
	middleX: PropTypes.number,
	middleY: PropTypes.number,
};

export default CustomLine;
