import PropTypes from "prop-types";
import React, { useState } from "react";

import { Alert } from "../components/Alert";
import { FieldSet, Input } from "../components/Forms";
import SlideOver from "../components/Slideover";

// GQL

import { gql, useMutation } from "@apollo/client";
import * as clientGQL from "../graphql/clients";

// Icons

import { faBriefcase, faHashtag } from "@fortawesome/pro-duotone-svg-icons";

const ClientSlideOver = ({
	isOpen,
	closeRequest = () => {},
	type,
	onComplete,
	currentClient,
	currentLocation,
}) => {
	// let [reload, setReload] = useState(false);

	let [client, setClient] = useState({});
	let [location, setLocation] = useState({});

	React.useEffect(() => {
		if (currentClient) {
			setClient(currentClient);
		}
	}, [currentClient]);

	React.useEffect(() => {
		if (currentLocation) {
			setLocation(currentLocation);
		}
	}, [currentLocation]);

	const [createClient] = useMutation(gql(clientGQL.createClient), {
		onCompleted: (data) => {
			onComplete(data.createLocation);
			setClient({});
			setLocation({});
		},
		onError: () => {
			Alert("Couldn't create office please try again");
		},
	});

	const [createLocation] = useMutation(gql(clientGQL.createLocation), {
		onCompleted: (data) => {
			onComplete(data.createLocation);
			createClient({
				variables: {
					input: {
						name: client.name,
						sageIntacct: client.sageIntacct,
						location: data.createLocation.id,
					},
				},
			});
		},
		onError: () => {
			Alert("Couldn't create office please try again");
		},
	});

	const [updateClient] = useMutation(gql(clientGQL.updateClient), {
		onCompleted: (data) => {
			setClient({});
			setLocation({});
			onComplete(data.updateLocation);
		},
		onError: () => {
			Alert("Couldn't update office please try again");
		},
	});

	const [updateLocation] = useMutation(gql(clientGQL.updateLocation), {
		onCompleted: () => {
			updateClient({
				variables: {
					input: client,
				},
			});
		},
		onError: () => {
			Alert("Couldn't update office please try again");
		},
	});

	return (
		<SlideOver
			isOpen={isOpen}
			name="New Location"
			description={"Create a new office client"}
			closeRequest={() => {
				closeRequest();
			}}
			onSubmit={() => {
				if (type === "create") {
					createLocation({
						variables: {
							input: location,
						},
					});
				} else {
					updateLocation({
						variables: {
							input: location,
						},
					});
				}
			}}
		>
			<Input
				required
				name="project"
				label={"Client Name"}
				value={client.name}
				onChange={(e) => setClient({ ...client, name: e })}
				icon={faBriefcase}
				placeholder={"Client Name"}
			/>
			<Input
				required
				name="project"
				label={"Sage Intacct"}
				value={client.sageIntacct}
				onChange={(e) => setClient({ ...client, sageIntacct: e })}
				icon={faHashtag}
				placeholder={"Sage Intacct"}
			/>
			<FieldSet
				legend="Address"
				description="Enter the client's address information"
			>
				<Input
					required
					name="lineOne"
					label="Line One"
					value={location.lineOne}
					onChange={(e) =>
						setLocation({
							...location,
							lineOne: e,
						})
					}
					placeholder={"Line One"}
				/>
				<Input
					name="lineTwo"
					label="Line Two"
					value={location.lineTwo}
					onChange={(e) =>
						setLocation({
							...location,
							lineTwo: e,
						})
					}
					placeholder={"Line Two"}
				/>
				<Input
					required
					name="city"
					label="City"
					value={location.city}
					onChange={(e) =>
						setLocation({
							...location,
							city: e,
						})
					}
					placeholder={"City"}
				/>
				<Input
					required
					name="state"
					label="State"
					value={location.state}
					onChange={(e) =>
						setLocation({
							...location,
							state: e,
						})
					}
					placeholder={"State"}
				/>
				<Input
					required
					name="zip"
					label="Zip"
					value={location.zip}
					onChange={(e) =>
						setLocation({
							...location,
							zip: e,
						})
					}
					placeholder={"Zip"}
				/>
				<Input
					name="SageIntacct"
					label="SageIntacct"
					value={location.sageIntacct}
					onChange={(e) =>
						setLocation({
							...client,
							location: e,
						})
					}
					placeholder={"SageIntacct"}
				/>
			</FieldSet>
		</SlideOver>
	);
};

ClientSlideOver.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeRequest: PropTypes.func,
	type: PropTypes.string,
	onComplete: PropTypes.func.isRequired,
	currentClient: PropTypes.object,
	currentLocation: PropTypes.object,
};

export { ClientSlideOver };
