import { useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Dimensions, Pressable, Text, View } from "react-native";
import Carousel from "react-native-reanimated-carousel";

// Components
import { Badge } from "../../../components/Badge";
import Breadcrumb, { Crumb } from "../../../components/Breadcrumb";
import DataContainer, { DataItem } from "../../../components/DataDisplay";
import { PageHeader } from "../../../components/Header.js";
import { List, ListItem } from "../../../components/List.js";
import LoadingScreen from "../../../components/LoadingScreen";
import OptionsMenu from "../../../components/OptionsMenu";

// GQL
import { gql, useQuery } from "@apollo/client";
import * as sopGQL from "../../../graphql/admin/sops";

// Icons
import {
	faCalendar,
	faEye,
	faHashtag,
} from "@fortawesome/pro-duotone-svg-icons";

// Utils
import { SentryLogger } from "../../../utils";

export const PublicSOPs = ({ search }) => {
	const navigation = useNavigation();
	const [sops, setSops] = React.useState(null);

	const { loading: sopLoading } = useQuery(gql(sopGQL.listSOPs), {
		limit: 1000,
		onCompleted: (data) => {
			setSops(data.listSOPs.items);
		},
		onError: (error) => {
			SentryLogger(error, "Error in SOPs");
		},
		fetchPolicy: "cache-and-network",
	});

	const navigateToPublicSop = (id) => {
		navigation.navigate("PublicSingleSop", { id });
	};

	if (sopLoading || !sops) return <LoadingScreen loadItem="SOPs" />;

	return (
		<>
			<Breadcrumb baseLocation="Dashboard">
				<Crumb name={"Current Public SOP"} location="PublicSOPs" />
			</Breadcrumb>
			<PageHeader openMenu title="SOPs" />

			<List headers={["Title", "Version", "Created"]} usesOptions>
				{sops &&
					sops
						.filter((sop) => {
							return (
								sop.publicVersion && sop.title.toLowerCase().includes(search)
							);
						})
						.sort((a, b) => {
							if (a.title > b.title) return 1;
							if (a.title < b.title) return -1;
							return 0;
						})
						.map((sop, index) => {
							return (
								<ListItem
									key={index}
									items={[
										{
											content: sop.title,
											onPress: () => navigateToPublicSop(sop.id),
										},
										{
											content: sop.publicVersion.version,
											onPress: () => navigateToPublicSop(sop.id),
										},
										{
											content: DateTime.fromISO(
												sop.publicVersion.created
											).toLocaleString(),

											onPress: () => navigateToPublicSop(sop.id),
										},
									]}
									options={
										<OptionsMenu
											options={[
												{
													name: "View",
													icon: faEye,
													onPress: () => navigateToPublicSop(sop.id),
												},
											]}
										/>
									}
									smallView={
										<Pressable onPress={() => navigateToPublicSop(sop.id)}>
											<View>
												<Text className="font-medium dark:text-white">
													{sop.title}
												</Text>
												<Text className=" font-bold text-md dark:text-white ">
													Version: {sop.publicVersion.version}
												</Text>
												<Text className=" font-bold text-md dark:text-white ">
													Created:{" "}
													{DateTime.fromISO(
														sop.publicVersion.created
													).toLocaleString()}
												</Text>
											</View>
										</Pressable>
									}
								/>
							);
						})}
			</List>
		</>
	);
};

PublicSOPs.propTypes = {
	route: PropTypes.object,
	search: PropTypes.string,
	smallView: PropTypes.oneOf([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node),
	]),
};

export const PublicSingleSop = ({ route }) => {
	const { id } = route.params;
	const navigation = useNavigation();
	const [sop, setSop] = useState(null);
	const width = Dimensions.get("window").width;

	const { loading } = useQuery(gql(sopGQL.getSOP), {
		variables: { id: id },

		onCompleted: (data) => {
			setSop(data.getSOP);
		},
		onError: (error) => {
			SentryLogger(error, "Error in SOP");
		},
		fetchPolicy: "cache-and-network",
	});

	const navigateToPublicBody = (id) => {
		navigation.navigate("PublicBody", { id });
	};

	if (loading || !sop) {
		return <LoadingScreen loadItem={"Single Sop"} />;
	}

	return (
		<>
			<Breadcrumb baseLocation="Dashboard">
				<Crumb name="SOPs" location="PublicSOPs" />
				<Crumb
					name={`${sop.title}`}
					location="PublicSingleSop"
					params={{
						id: sop.id,
					}}
				/>
			</Breadcrumb>

			<PageHeader
				goBack={{
					location: "SOPs",
				}}
				title={sop.title}
				info={[
					{
						icon: faHashtag,
						text: sop.publicVersion.version || "No Public Version",
					},
					{
						icon: faCalendar,
						text:
							sop.publicVersion && sop.publicVersion.created
								? DateTime.fromISO(sop.publicVersion.created).toLocaleString()
								: "No Date",
					},
				]}
			/>

			<DataContainer
				title={"Translations"}
				description={"Swipe to see more translations"}
			>
				<View className="flex-1">
					<Carousel
						width={width * 0.9}
						height={width / 3}
						data={sop.publicVersion.bodies}
						scrollAnimationDuration={1000}
						renderItem={({ item }) => {
							return (
								<Pressable
									onPress={() => navigateToPublicBody(item.id)}
									className="flex-1 mx-2 rounded-lg shadow"
								>
									<DataContainer title={item.language}>
										<Text>{item.body}</Text>
									</DataContainer>
								</Pressable>
							);
						}}
					/>
				</View>

				<DataItem
					name="Tags"
					value={
						<View className={"pb-1 pt-1 flex flex-row"}>
							{sop.tags.map((tag, index) => {
								return (
									<View key={index} className="mr-2">
										<Badge text={tag.name} />
									</View>
								);
							})}
						</View>
					}
				/>

				{/* attachments will possibly need to show full image here */}
				<View className="flex flex-row space-between items-center">
					<DataItem
						name="Attachments"
						value={sop.attachments.map((attachment, index) => {
							return (
								<View key={`attachment-${index}`} className="mr-2">
									<Badge text={attachment.name} />
								</View>
							);
						})}
					/>
				</View>
			</DataContainer>
		</>
	);
};

PublicSingleSop.propTypes = {
	route: PropTypes.object,
};
