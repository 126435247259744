import { useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, View } from "react-native";

// components

import { Alert } from "../../components/Alert";
import { PageHeader } from "../../components/Header";
import LoadingScreen from "../../components/LoadingScreen";

import { Select } from "../../components/Forms";
import Modal from "../../components/Modal";
import { addNotification } from "../../components/Notification";
import SlideOver from "../../components/Slideover";

// GQL

import { gql, useMutation, useQuery } from "@apollo/client";
import { Input, TextArea } from "../../components/Forms";
import * as projectOperations from "../../graphql/admin/projects";

// util

import {
	faTrash,
	faUserPlus,
	faUsersClass,
} from "@fortawesome/pro-duotone-svg-icons";
import { API_URL, getData } from "../../utils";
import { Table } from "../../components/Table";
import { ProjectTeamMember } from "../../containers/ProjectTeamMember";
import Toast from "react-native-toast-message";

export default function AdminProjects() {
	const navigation = useNavigation();
	const [user, setUser] = React.useState(null);
	const [selectedProject, setSelectedProject] = useState({});
	const [role, setRole] = useState("");
	const [projects, setProjects] = useState([]);
	const [nextToken, setNextToken] = useState(null);
	const [newItem, setNewItem] = useState(null);
	const [projectRoles, setProjectRoles] = useState([]);
	const [deletionItem, setDeletionItem] = useState(null);
	const [confirmation, setConfirmation] = useState("");
	const [reason, setReason] = useState("");
	const [search, setSearch] = useState("");

	useQuery(gql(projectOperations.listProjectRoles), {
		onCompleted: (data) => {
			setProjectRoles([...data.listProjectRoles.items]);
		},
	});

	const { loading: projectsLoading } = useQuery(
		gql(projectOperations.listProjects),
		{
			onCompleted: (data) => {
				setProjects((prev) => [...prev, ...data.listProjects.items]);
				setNextToken(data.listProjects.nextToken);
			},
		}
	);

	const [createProjectTeamMember] = useMutation(
		gql(projectOperations.createProjectTeamMember),
		{
			onCompleted: () => {
				addNotification({
					title: "Added To Project",
					text: "Successfully added to the project",
					type: "success",
					time: 5000,
				});
				navigation.navigate("Project", {
					id: selectedProject.id,
				});
			},
			onError: () => {
				Alert("There was an error adding yourself to the project");
			},
		}
	);

	React.useEffect(() => {
		const getUser = async () => {
			await getData("@storage_Key").then((userData) => {
				setUser(userData);
			});
		};
		getUser();
	}, []);

	const addSelfToProject = async () => {
		createProjectTeamMember({
			variables: {
				input: {
					project: selectedProject.id,
					teamMember: user.id,
					projectRole: role,
					isSuperAdmin: true,
					added: DateTime.local().toISO(),
				},
			},
		});
	};

	const [deleteAllProjectData] = useMutation(
		gql(projectOperations.deleteAllProjectData),
		{
			onCompleted: () => {
				addNotification({
					title: "Deleted Project",
					text: "Successfully deleted project",
					type: "success",
					time: 5000,
				});
				setDeletionItem(null);
				sendDeletionEmail(deletionItem, user, reason, [
					"csampson@srpenvironmental.net",
				]);
			},
			onError: () => {
				Alert("There was an error deleting the project");
			},
		}
	);

	const sendDeletionEmail = async (project, user, reason, emails) => {
		let input = {
			project: project,
			user: user,
			reason: reason,
			emails: emails,
		};
		await fetch(`${API_URL}/emails/project-deletion`, {
			method: "POST",
			body: JSON.stringify(input),
			headers: {
				"Content-Type": "application/json",
			},
		}).then(() => {
			navigation.navigate("Projects");
		});
	};

	if (projectsLoading) {
		return <LoadingScreen loadItem="Projects" />;
	}

	return (
		<>
			<PageHeader
				openMenu
				title={"Projects"}
				description={"All projects in the SRP inField database"}
			/>

			<Input
				label="Search Projects"
				onChange={(e) => {
					setSearch(e);
				}}
			/>

			<Table
				headers={["Title", "Workspace", "Client", "Number", "Stage"]}
				title={"Projects"}
				data={projects
					.filter((_project) => {
						return _project.title.toLowerCase().includes(search.toLowerCase());
					})
					.map((project) => {
						return {
							cells: [
								{ content: project.title },

								{ content: project.workspace && project.workspace.title },
								{
									content: project.client
										? project.client.name
										: "No Client Name",
								},
								{ content: project.number },
								{
									content: project.stage ? project.stage : "No Project Stage",
								},
							],
							options: [
								{
									name: "Add Self To Project",
									icon: faUserPlus,
									onPress: () => {
										setNewItem("role");
										setSelectedProject(project);
									},
								},
								{
									name: "Add User To Project",
									icon: faUsersClass,
									onPress: () => {
										setNewItem("addUser");
										setSelectedProject(project);
									},
								},
								{
									name: "Delete Project",
									icon: faTrash,
									onPress: () => {
										setDeletionItem(project);
									},
								},
							],
						};
					})}
			/>

			<SlideOver
				isOpen={newItem === "role"}
				name="Project Role"
				description="Select your project role"
				closeRequest={() => setNewItem(null)}
				onSubmit={(e) => {
					addSelfToProject(e);
				}}
			>
				<Select
					name="Project Role"
					onChange={(value) => {
						setRole(value.value);
					}}
					options={projectRoles
						.sort((a, b) => {
							if (a.name > b.name) return 1;
							if (a.name < b.name) return -1;
							return 0;
						})
						.map((projectRoles) => {
							return { name: projectRoles.name, value: projectRoles.id };
						})}
				/>
			</SlideOver>

			{nextToken && (
				<Button
					type="button"
					className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					onPress={() => {}}
					title={"Load More Projects"}
				/>
			)}

			<Modal
				isOpen={deletionItem !== null}
				title="Delete Expense Item"
				description={`Are you sure want to delete ${
					deletionItem && deletionItem.title
				}? This action cannot be undone. It will delete all data associated with this project. It should only ever be done when a project was accidentally created and is no longer needed. An email will be sent to the admins of SRP inField letting them know of the deletion.`}
				rightButton={{
					text: "Delete Item",
					onPress: () => {
						if (confirmation !== deletionItem.title) {
							return Alert(
								"You must enter the title of the project to continue with deletion"
							);
						}
						if (!reason) {
							return Alert("You must enter the reason for deletion");
						}
						deleteAllProjectData({
							variables: {
								input: {
									id: deletionItem.id,
								},
							},
						});
					},
				}}
			>
				<View className="text-left space-y-3">
					<Input
						label="Enter Project Name"
						helper={`Enter ${deletionItem && deletionItem.title} to continue`}
						onChange={(e) => {
							setConfirmation(e);
						}}
					/>

					<TextArea
						label="Reason for deletion"
						helper={`Enter ${deletionItem && deletionItem.title} to continue`}
						onChange={(e) => {
							setReason(e);
						}}
					/>
				</View>
			</Modal>

			<ProjectTeamMember
				isOpen={newItem === "addUser"}
				onComplete={() => {
					setNewItem(null);

					Toast.show({
						type: "success",
						text1: "Added User To Project",
						text2: `Successfully added user to ${selectedProject.title}`,
						position: "top",
						bottomOffset: 60,
					});
					selectedProject({});
				}}
				closeRequest={() => {
					setNewItem(null);
					selectedProject({});
				}}
				project={selectedProject}
				type={"create"}
			/>
		</>
	);
}

AdminProjects.propTypes = {
	search: PropTypes.string,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
};
